import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      // routes & menus
      Dashboard: 'Dashboard',
      'Space Data': 'Space',
      'Equipment Data': 'Equipment',
      'Meter Data': 'Meter',
      'Tenant Data': 'Tenant',
      'Store Data': 'Store',
      'Shopfloor Data': 'Shopfloor',
      'Combined Equipment Data': 'Combined Equipment',
      'Auxiliary System': 'Auxiliary System',
      Microgrid: 'Microgrid',
      'Energy Storage Power Station': 'Energy Storage Power Station',
      'Photovoltaic Power Station': 'Power Station',
      'Fault Alarm': 'Fault Alarm',
      Monitoring: 'Monitoring',
      'Advanced Reporting': 'Advanced Reporting',
      'Knowledge Base': 'Knowledge Base',
      'Energy Category Data': 'Energy Category',
      'Energy Item Data': 'Energy Item',
      Carbon: 'CO2 Emissions',
      Cost: 'Cost',
      Output: 'Output',
      Income: 'Income',
      Efficiency: 'Efficiency',
      Load: 'Load',
      Statistics: 'Statistics',
      'Batch Analysis': 'Batch Analysis',
      Saving: 'Saving',
      Plan: 'Plan',
      'Production': 'Production',
      'Space Production': 'Space Production',
      'Equipment Tracking': 'Equipment Tracking',
      'Environment Monitor': 'Environment Monitor',
      'Meter Energy': 'Meter Energy',
      'Meter Carbon': 'Meter CO2 Emissions',
      'Meter Cost': 'Meter Cost',
      'Meter Trend': 'Meter Trend',
      'Meter Realtime': 'Meter Realtime',
      'Meter Saving': 'Meter Saving',
      'Meter Plan': 'Meter Plan',
      'Master Meter Submeters Balance': 'Master Meter Submeters Balance',
      'Meter Batch Analysis': 'Meter Batch Analysis',
      'Meter Comparison': 'Meter Comparison',
      'Meter Tracking': 'Meter Tracking',
      'Virtual Meter Saving': 'Virtual Meter Saving',
      'Virtual Meter Plan': 'Virtual Meter Plan',
      'Virtual Meter Energy': 'Virtual Meter Energy',
      'Virtual Meter Carbon': 'Virtual Meter CO2 Emissions',
      'Virtual Meter Cost': 'Virtual Meter Cost',
      'Virtual Meter Batch Analysis': 'Virtual Meter Batch Analysis',
      'Offline Meter Energy': 'Offline Meter Energy',
      'Offline Meter Carbon': 'Offline Meter CO2 Emissions',
      'Offline Meter Cost': 'Offline Meter Cost',
      'Offline Meter Batch Analysis': 'Offline Meter Batch Analysis',
      'Offline Meter Saving': 'Offline Meter Saving',
      'Offline Meter Plan': 'Offline Meter Plan',
      'Offline Meter Input': 'Offline Meter Input',
      'Tenant Bill': 'Tenant Bill',
      'Energy Flow Diagram': 'Energy Flow Diagram',
      'Distribution System': 'Distribution System',
      'SVG System': 'SCADA Visualization System',
      'Faults Data': 'Faults',
      'Space Equipments': 'Space Equipments',
      'Combined Equipments': 'Combined Equipments',
      'Energy Storage Power Station List': 'List',
      'Energy Storage Power Station Details': 'Details',
      'Energy Storage Power Station Reporting': 'Reporting',
      'Energy Storage Power Station Alarm': 'Alarm',
      'Energy Storage Power Station Maintenance': 'Maintenance',
      'Enter Production': 'Enter Production',
      'Multiple Energy Storage Power Stations': 'Multiple Power Stations',
      'Multiple Energy Storage Power Stations Dashboard': 'Dashboard',
      'Multiple Energy Storage Power Stations List': 'List',
      'Single Energy Storage Power Station': 'Single Power Station',
      'Single Energy Storage Power Station Dashboard': 'Dashboard',
      'Single Energy Storage Power Station Details': 'Details',
      'Single Energy Storage Power Station Fault Alarm': 'Fault Alarm',
      'Energy Storage Power Station Reports': 'Power Station Reports',
      'Energy Storage Power Station Reports Energy': 'Energy',
      'Energy Storage Power Station Reports Revenue': 'Revenue',
      'Energy Storage Power Station Reports Parameters': 'Parameters',
      'Photovoltaic Power Station List': 'Power Station List',
      'Photovoltaic Power Station Details': 'Details',
      'Photovoltaic Power Station Reporting': 'Reporting',
      'Photovoltaic Power Station Alarm': 'Alarm',
      'Photovoltaic Power Station Maintenance': 'Maintenance',
      'Multiple Photovoltaic Power Stations': 'Multiple Power Stations',
      'Multiple Photovoltaic Power Stations Dashboard': 'Dashboard',
      'Multiple Photovoltaic Power Stations List': 'List',
      'Single Photovoltaic Power Station': 'Single Power Station',
      'Single Photovoltaic Power Station Dashboard': 'Dashboard',
      'Single Photovoltaic Power Station Details': 'Details',
      'Single Photovoltaic Power Station Fault Alarm': 'Fault Alarm',
      'Photovoltaic Power Station Reports': 'Power Station Reports',
      'Photovoltaic Power Station Reports Energy': 'Energy',
      'Photovoltaic Power Station Reports Revenue': 'Revenue',
      'Photovoltaic Power Station Reports Parameters': 'Parameters',
      'Work Order': 'Work Order',
      'Work Order Installation': 'Installation',
      'Work Order Repair': ' Repair',
      'Work Order Inspection': 'Inspection',
      'Total Number of Work Orders': 'Total Number of Work Orders',
      'Number of New Work Orders': 'Number of New Work Orders',
      'Number of Inprogress Work Orders': 'Number of Inprogress Work Orders',
      'Number of Done Work Orders': 'Number of Done Work Orders',
      'Charge Energy Indicator': 'Charge Energy',
      'Discharge Energy Indicator': 'Disharge Energy',
      'Charge Cost Indicator': 'Charge Cost',
      'Discharge Revenue Indicator': 'Discharge Revenue',
      'HVAC': 'HVAC',
      'Fire Control': 'Fire Control',

      // Dashboard
      'Welcome to DoraEOS': 'Welcome to DoraEOS',
      'An Industry Leading Open Source Energy Management System':
        'An Industry Leading Open Source Energy Management System',
      "This Year's Consumption CATEGORY VALUE UNIT": "This Year's Consumption {{CATEGORY}} {{VALUE}} {{UNIT}}",
      "This Year's Costs CATEGORY VALUE UNIT": "This Year's Costs {{CATEGORY}} {{VALUE}} {{UNIT}}",
      "This Month's Consumption CATEGORY VALUE UNIT": "This Month's Consumption {{CATEGORY}} {{VALUE}} {{UNIT}}",
      "This Month's Costs CATEGORY VALUE UNIT": "This Month's Costs {{CATEGORY}} {{VALUE}} {{UNIT}}",
      'Consumption CATEGORY VALUE UNIT in The Same Period Last Year':
        'Consumption {{CATEGORY}} {{VALUE}} {{UNIT}}  in The Same Period Last Year',
      'Costs CATEGORY VALUE UNIT in The Same Period Last Year':
        'Costs {{CATEGORY}} {{VALUE}} {{UNIT}} in The Same Period Last Year',
      'CATEGORY Consumption UNIT': '{{CATEGORY}} Consumption {{UNIT}}',
      'CATEGORY Costs UNIT': '{{CATEGORY}} Costs {{UNIT}}',
      // Entities
      Space: 'Space',
      Equipment: 'Equipment',
      Meter: 'Meter',
      'Offline Meter': 'Offline Meter',
      'Virtual Meter': 'Virtual Meter',
      Tenant: 'Tenant',
      Store: 'Store',
      Shopfloor: 'Shopfloor',
      'Combined Equipment': 'Combined Equipment',
      'Cost Center': 'Cost Center',
      Name: 'Name',
      DisplayName: 'Display Name',
      UserName: 'User Name',
      Description: 'Description',
      'Energy Category': 'Energy Category',
      'Child Spaces': 'Child Spaces',
      'Associated Equipment': 'Associated Equipment',
      //Energy Categories
      Electricity: 'Electricity',
      Water: 'Water',
      'Natural Gas': 'Natural Gas',
      // SidePanelModal
      Settings: 'Settings',
      'Set your own customized style': 'Set your own customized style',
      'Color Scheme': 'Color Scheme',
      'Choose the perfect color mode for your app': 'Choose the perfect color mode for your app',
      Light: 'Light',
      Dark: 'Dark',
      'RTL Mode': 'RTL Mode',
      'Switch your language direction': 'Switch your language direction',
      'Fluid Layout': 'Fluid Layout',
      'Toggle container layout system': 'Toggle container layout system',
      'Navigation Position': 'Navigation Position',
      'Select a suitable navigation system for your web application':
        'Select a suitable navigation system for your web application',
      'Vertical Nav': 'Vertical Nav',
      'Top Nav': 'Top Nav',
      'Vertical Navbar Style': 'Vertical Navbar Style',
      'Switch between styles for your vertical navbar': 'Switch between styles for your vertical navbar',
      Language: 'Language',
      'Switch between languages': 'Switch between languages',
      'language-zh_CN': '简体中文',
      'language-en': 'English',
      'language-de': 'Deutsch',
      'language-fr': 'Français',
      'language-es': 'Español',
      'language-ru': 'Русский',
      'language-ar': 'العربية',
      'language-vi': 'Tiếng Việt',
      'language-th': 'ภาษาไทย',
      'language-tr': 'Türkçe',
      'language-ms': 'Bahasa Melayu',
      'language-id': 'Bahasa Indonesia',
      'language-zh_TW': '繁體中文',
      'Like What You See?': 'Like What You See?',
      'Get DoraEOS now': 'Get DoraEOS now',
      Purchase: 'Purchase',
      // Query Panel
      'Base Period Begins': 'Base Period Begins',
      'Base Period Ends': 'Base Period Ends',
      'Comparison Types': 'Comparison Types',
      'Year-Over-Year': 'Year-Over-Year',
      'Month-On-Month': 'Month-On-Month',
      'Free Comparison': 'Free Comparison',
      'None Comparison': 'None Comparison',
      'Reporting Period Begins': 'Reporting Period Begins',
      'Reporting Period Ends': 'Reporting Period Ends',
      '(Optional)': '(Optional)',
      'Period Types': 'Period Types',
      Yearly: 'Yearly',
      Monthly: 'Monthly',
      Weekly: 'Weekly',
      Daily: 'Daily',
      Hourly: 'Hourly',
      Submit: 'Submit',
      'Input Energy Category': 'Input Energy Category',
      'Output Energy Category': 'Output Energy Category',
      'Fraction Parameter': 'Fraction Parameter',
      Search: 'Search',
      //DateRangePicker
      sunday: 'Su',
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      ok: 'OK',
      today: 'Today',
      yesterday: 'Yesterday',
      hours: 'Hours',
      minutes: 'Minutes',
      seconds: 'Seconds',
      last7Days: 'Last 7 Days',
      'Select Date Range': 'Select Date Range',
      //Card Summaries and Line Charts
      'Base Period': 'Base Period',
      'Reporting Period': 'Reporting Period',
      'Per Unit Area': 'Per Unit Area',
      'Per Capita': 'Per Capita',
      'Per Unit Production': 'Per Unit Production',
      Baseline: 'Baseline',
      Actual: 'Actual',
      'Baseline Value - Actual Value': 'Baseline - Actual',
      'Average Load': 'Average Load',
      'Maximum Load': 'Maximum Load',
      'Load Factor': 'Load Factor',
      'Ratio of Average Load to Maximum Load': 'Ratio of Average Load to Maximum Load',
      'Carbon Dioxide Emissions by Energy Category': 'Carbon Dioxide Emissions by Energy Category',
      'Costs by Energy Category': 'Costs by Energy Category',
      'Incomes by Energy Category': 'Incomes by Energy Category',
      'Electricity Consumption by Time-Of-Use': 'Electricity Consumption by Time-Of-Use',
      'Electricity Carbon Dioxide Emissions by Time-Of-Use': 'Electricity Carbon Dioxide Emissions by Time-Of-Use',
      'Electricity Cost by Time-Of-Use': 'Electricity Cost by Time-Of-Use',
      'CATEGORY UNIT Consumption by Energy Items': '{{CATEGORY}} {{UNIT}} Comsumption by Energy Items',
      'Ton of Standard Coal by Energy Category': 'Ton of Standard Coal(TCE) by Energy Category',
      'Ton of Carbon Dioxide Emissions by Energy Category': 'Ton of Carbon Dioxide Emissions(TCO2E) by Energy Category',
      'Reporting Period Consumption CATEGORY UNIT': 'Reporting Period Consumption {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{EQUIPMENT}} Reporting Period Consumption {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Reporting Period Consumption {{CATEGORY}} {{UNIT}}',
      'CATEGORY VALUE UNIT': '{{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption CATEGORY VALUE UNIT':
        'Reporting Period Consumption {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Production PRODUCT VALUE UNIT': 'Reporting Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Production PRODUCT VALUE UNIT': 'Base Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption CATEGORY VALUE UNIT': 'Base Period Consumption {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY UNIT': 'Reporting Period Consumption {{ITEM}} {{CATEGORY}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY VALUE UNIT':
        'Reporting Period Consumption {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption ITEM CATEGORY VALUE UNIT':
        'Base Period Consumption {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Child Space Proportion CATEGORY UNIT': 'Child Space Proportion by Energy Category {{CATEGORY}} {{UNIT}}',
      'Child Space Total Proportion': 'Child Space Total Proportion',
      'Reporting Period Carbon Dioxide Emissions CATEGORY UNIT':
        'Reporting Period Carbon Dioxide Emissions {{CATEGORY}} {{UNIT}}',
      'Reporting Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Reporting Period Carbon Dioxide Emissions {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Base Period Carbon Dioxide Emissions {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Costs CATEGORY UNIT': 'Reporting Period Costs {{CATEGORY}} {{UNIT}}',
      'Reporting Period Costs CATEGORY VALUE UNIT': 'Reporting Period Costs {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Costs CATEGORY VALUE UNIT': 'Base Period Costs {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Output CATEGORY UNIT': 'Reporting Period Output {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Output CATEGORY UNIT': '{{EQUIPMENT}} Reporting Period Output {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Reporting Period Output {{CATEGORY}} {{UNIT}}',
      'Reporting Period Output CATEGORY VALUE UNIT': 'Reporting Period Output {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Output CATEGORY VALUE UNIT': 'Base Period Output {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Income CATEGORY UNIT': 'Reporting Period Income {{CATEGORY}} {{UNIT}}',
      'Reporting Period Income CATEGORY VALUE UNIT': 'Reporting Period Income {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Income CATEGORY VALUE UNIT': 'Base Period Income {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Total Income UNIT': 'Reporting Period Total Income {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME UNIT': 'Reporting Period Cumulative Efficiency {{NAME}} {{UNIT}}',
      'EQUIPMENT Reporting Period Cumulative Efficiency UNIT':
        '{{EQUIPMENT}} Reporting Period Cumulative Efficiency {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Reporting Period Cumulative Comprehensive Efficiency {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Reporting Period Cumulative Comprehensive Efficiency {{VALUE}} {{UNIT}}',
      'COMBINED_EQUIPMENT Base Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Base Period Cumulative Comprehensive Efficiency {{VALUE}} {{UNIT}}',
      'Instantaneous Efficiency UNIT': 'Instantaneous Efficiency {{UNIT}}',
      'EQUIPMENT Instantaneous Efficiency UNIT': '{{EQUIPMENT}} Instantaneous Efficiency {{UNIT}}',
      'COMBINED_EQUIPMENT Instantaneous Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Instantaneous Comprehensive Efficiency {{UNIT}}',
      'Reporting Period Cumulative Efficiency VALUE UNIT': 'Reporting Period Cumulative Efficiency {{VALUE}} {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME VALUE UNIT':
        'Reporting Period Cumulative Efficiency {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency VALUE UNIT': 'Base Period Cumulative Efficiency {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency NAME VALUE UNIT':
        'Base Period Cumulative Efficiency {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period CATEGORY Maximum Load UNIT': 'Base Period {{CATEGORY}} Maximum Load {{UNIT}}',
      'Reporting Period CATEGORY Maximum Load UNIT': 'Reporting Period {{CATEGORY}} Maximum Load {{UNIT}}',
      'Reporting Period CATEGORY Average Load UNIT': 'Reporting Period {{CATEGORY}} Average Load {{UNIT}}',
      'Reporting Period CATEGORY Load Factor': 'Reporting Period {{CATEGORY}} Load Factor',
      'Base Period CATEGORY Average Load VALUE UNIT': 'Base Period {{CATEGORY}} Average Load {{VALUE}} {{UNIT}}',
      'Reporting Period CATEGORY Average UNIT': 'Reporting Period {{CATEGORY}} Average Value {{UNIT}}',
      'Reporting Period CATEGORY Maximum UNIT': 'Reporting Period {{CATEGORY}} Maximum Value {{UNIT}}',
      'Reporting Period CATEGORY Minimum UNIT': 'Reporting Period {{CATEGORY}} Minimum Value {{UNIT}}',
      'Reporting Period CATEGORY Mean UNIT': 'Reporting Period {{CATEGORY}} Arithmetic Mean {{UNIT}}',
      'Reporting Period CATEGORY Median UNIT': 'Reporting Period {{CATEGORY}} Median (Middle Value) {{UNIT}}',
      'Reporting Period CATEGORY Stdev UNIT': 'Reporting Period {{CATEGORY}} Sample Standard Deviation {{UNIT}}',
      'Reporting Period CATEGORY Variance UNIT': 'Reporting Period {{CATEGORY}} Sample Variance {{UNIT}}',
      'Reporting Period Saving CATEGORY (Baseline - Actual) UNIT':
        'Reporting Period Saving {{CATEGORY}} (Baseline - Actual) {{UNIT}}',
      'Reporting Period Decreased CATEGORY (Baseline - Actual) UNIT':
        'Reporting Period Decreased {{CATEGORY}} (Baseline - Actual) {{UNIT}}',
      'Reporting Period Saving CATEGORY VALUE UNIT': 'Reporting Period Saving {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Saving CATEGORY VALUE UNIT': 'Base Period Saving {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY UNIT':
        'Reporting Period Master Meter Consumption {{CATEGORY}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY VALUE UNIT':
        'Reporting Period Master Meter Consumption {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY UNIT':
        'Reporting Period Submeters Consumption {{CATEGORY}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY VALUE UNIT':
        'Reporting Period Submeters Consumption {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Difference CATEGORY UNIT': 'Reporting Period Difference {{CATEGORY}} {{UNIT}}',
      'Reporting Period Percentage Difference': 'Reporting Period Percentage Difference',
      'Reporting Period Difference CATEGORY VALUE UNIT': 'Reporting Period Difference {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Trend Values': 'Trend Values',
      'METER CATEGORY VALUE UNIT': '{{METER}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      //FDD
      'Fault List': 'Fault List',
      'Reporting Period Number of Fault VALUE': 'Reporting Period Number of Fault {{VALUE}}',
      'All Faults': 'All Faults',
      'Space Faults': 'Space Faults',
      'Number of All Faults': 'Number of All Faults',
      'Number of Space Faults': 'Number of Space Faults',
      'Total Number of Faults': 'Total Number of Faults',
      'Number of New Faults': 'Number of New Faults',
      'Number of Inprogress Faults': 'Number of Inprogress Faults',
      'Number of Done Faults': 'Number of Done Faults',
      //Data Panels
      'Ton of Standard Coal': 'Ton of Standard Coal',
      'Ton of Carbon Dioxide Emissions': 'Ton of Carbon Dioxide Emissions',
      'Operating Characteristic Curve': 'Operating Characteristic Curve',
      Tariff: 'Tariff',
      'Detailed Data': 'Detailed Data',
      'Child Spaces Data': 'Child Spaces Data',
      'Associated Equipment Data': 'Associated Equipment Data',
      'This Year': 'This Year',
      'The Same Period Last Year': 'The Same Period Last Year',
      'This Month': 'This Month',
      'This Day': 'This Day',
      Total: 'Total',
      'No data found': 'No data found',
      Export: 'Export',
      Datetime: 'Datetime',
      'Time-Of-Use Type': 'Time-Of-Use Type',
      'Top-Peak': 'Top-Peak',
      'On-Peak': 'On-Peak',
      'Mid-Peak': 'Mid-Peak',
      'Off-Peak': 'Off-Peak',
      Percentage: 'Percentage',
      //Realtime Chart
      'Trend in the last hour of Energy Value Point': 'Trend in the last hour of Energy Value Point',
      Circuit: 'Circuit',
      Point: 'Point',
      'Realtime Value': 'Realtime Value',
      //Meter Tracking
      'Meter List': 'Meter List',
      'Edit Meter': 'Edit',
      'Start Value': 'Start Value',
      'End Value': 'End Value',
      'Difference Value': 'Difference Value',
      'Start Integrity Rate': 'Start Integrity Rate(%)',
      'End Integrity Rate': 'End Integrity Rate(%)',
      'Full Integrity Rate': 'Full Integrity Rate(%)',
      //Equipment Tracking
      'Equipment List': 'Equipment List',
      'Edit Equipment': 'Edit',
      //Profile Dropdown
      Feedback: 'Feedback',
      'Account Settings': 'Account Settings',
      Logout: 'Logout',
      //Authentication
      'Log in': 'Log in',
      'Email address': 'Email address',
      Password: 'Password',
      CaptchaCode: 'Captcha',
      Refresh: 'Refresh',
      'Remember me': 'Remember me',
      'Logged in as ': 'Logged in as ',
      'Forgot Password?': 'Forgot Password?',
      'Forgot your password?': 'Forgot your password?',
      "Enter your email and we'll send you a reset link": "Enter your email and we'll send you a reset link",
      "Enter your email and we'll send you a registration link":
        "Enter your email and we'll send you a registration link",
      'Reset Password': 'Reset Password',
      'Thanks for using DoraEOS!': 'Thanks for using DoraEOS!',
      'You are now successfully signed out': 'You are now successfully signed out',
      'Return to Login': 'Return to Login',
      'Please check your email!': 'Please check your email!',
      'An email has been sent to ': 'An email has been sent to ',
      'Please click on the included link to reset your password':
        'Please click on the included link to reset your password',
      'Please click on the included link to register your account':
        'Please click on the included link to register your account',
      'An email with password reset link is sent to ': 'An email with password reset link is sent to ',
      'Change Password': 'Change Password',
      'Old Password': 'Old Password',
      'New Password': 'New Password',
      'Confirm Password': 'Confirm Password',
      'Update Password': 'Update Password',
      'Password has been changed!': 'Password has been changed!',
      'Verification code has been sent to your inbox. \
      Please copy it to the input box below..':
        'Verification code has been sent to your inbox. \
      Please copy it to the input box below.',
      'New to DoraEOS': 'New to DoraEOS',
      'Create an account': 'Create an account',
      'EMAIL Account registration successful': '{{EMAIL}} Account registration successful',
      'Thanks for verifying your account!': 'Thanks for verifying your account!',
      'Your code is': 'Your code is',
      'Send verification code': 'Send verification code',
      'Please wait for NUMBER seconds': 'Please wait for {{NUMBER}} seconds',
      'Password reset': 'Password reset',
      'Please wait for approval': 'Please wait for approval',
      //notification
      Notifications: 'Notifications',
      'Mark all as read': 'Mark all as read',
      'View all': 'All',
      'Notification New': 'NEW',
      'Notification Earlier': 'EARLIER',
      'Notification List': 'Notification List',
      'Bulk actions': 'Bulk actions',
      'Notification Subject': 'Subject',
      'Notification Created Datetime': 'Created Datetime',
      'Notification Priority': 'Priority',
      'Notification Message': 'Message',
      'Notification Status': 'Status',
      'Notification Mark As Read': 'Mark As Read',
      'Notification Mark As Acknowledged': 'Acknowledge',
      'Notification Low': 'Low',
      'Notification Medium': 'Medium',
      'Notification High': 'High',
      'Notification Critical': 'Critical',
      'Notification Unread': 'Unread',
      'Notification Read': 'Read',
      'Notification Acknowledged': 'Acknowledged',
      'Notification Archive': 'Archive',
      'Notification Delete': 'Delete',
      'Notification Apply': 'Apply',
      'Notification Start Datetime': 'Start Datetime',
      'Notification End Datetime': 'End Datetime',
      'Notification Update Datetime': 'Update Datetime',
      //Monitoring
      'Run Commands': 'Run Commands',
      'Fault Alarms': 'Fault Alarms',
      'Instantaneous Efficiency VALUE UNIT': 'Instantaneous Efficiency {{VALUE}} {{UNIT}}',
      'Communication Online': 'Online',
      'Communication Offline': 'Offline',
      'Equipment Status': 'Equipment Status',
      'Equipment Running': 'Running',
      'Equipment Stopped': 'Stopped',
      'Show Up to': 'Show Up to',
      All: 'All',
      'FROM - TO of TOTAL': 'The current display of {{FROM}} - {{TO}} total {{TOTAL}}',
      //Microgrid
      'PCS Run State': 'PCS Run State',
      'PCS Unknown': 'Unknown',
      'PCS Initializing': 'Initializing',
      'PCS Standby': 'Standby',
      'PCS Shutdown': 'Shutdown',
      'PCS Fault': 'Fault',
      'PCS Running': 'Running',
      'Battery Power': 'Battery Power',
      'Photovoltaic Power': 'Photovoltaic Power',
      'Grid Power': 'Grid Power',
      'Load Power': 'Load Power',
      Reporting: 'Reporting',
      Maintenance: 'Maintenance',
      'Serial Number': 'Serial Number',
      Address: 'Address',
      'Postal Code': 'Postal Code',
      'Rated Capacity': 'Rated Capacity',
      'Rated Power': 'Rated Power',
      Latitude: 'Latitude',
      Longitude: 'Longitude',
      'Strategy Management': 'Strategy Management',
      'Charging Schedule': 'Charging Schedule',
      'Charge Start Time': 'Charge Start Time',
      'Charge End Time': 'Charge End Time',
      'Discharge Start Time': 'Discharge Start Time',
      'Discharge End Time': 'Discharge End Time',
      'Total Rated Power': 'Total Rated Power',
      'Total Rated Capacity': 'Total Rated Capacity',
      "Today's Charge": "Today's Charge",
      "Today's Discharge": "Today's Discharge",
      "Today's Generation": "Today's Generation",
      'Total Charge': 'Total Charge',
      'Total Discharge': 'Total Discharge',
      'Total Generation': 'Total Generation',
      'Total Revenue': 'Total Revenue',
      'Total Efficiency': 'Total Efficiency',
      'Discharge Achievement Rate': 'Discharge Achievement Rate',
      'Revenue Ranking': 'Revenue Ranking',
      'Efficiency Ranking': 'Efficiency Ranking',
      'Charge Ranking': 'Charge Ranking',
      'Discharge Ranking': 'Discharge Ranking',
      'Number of Microgrids': 'Number of Microgrids',
      'Number of Power Stations': 'Number of Power Stations',
      'Microgrid List': 'Microgrid List',
      'Previous Page': 'Previous Page',
      'Next Page': 'Next Page',
      '7 Days': '7 Days',
      'Charge UNIT': 'Charge {{UNIT}}',
      'Discharge UNIT': 'Discharge {{UNIT}}',
      'Generation UNIT': 'Generation {{UNIT}}',
      'Energy Indicator': 'Energy',
      'Revenue Indicator': 'Revenue',
      'Carbon Indicator': 'Carbon',

      // Energy Storage Power Station
      'Battery Operating State': 'Battery Operating State',
      'Battery State': 'Battery State',
      'Battery Unknown': 'Unknown',
      'Battery Stopped': 'Stopped',
      'Battery Reserved': 'Reserved', // 0
      'Battery Fault': 'Fault', // 1
      'Battery Warning': 'Warning', // 2
      'Battery Standby': 'Standby', // 3
      'Battery Prohibit DisCharging': 'Prohibit DisCharging', //4
      'Battery Prohibit Charging': 'Prohibit Charging',// 5
      'Battery Normal': 'Normal', // 6
      'Battery Charging': 'Charging', // 7
      'Battery Discharging': 'Discharging', // 8
      'Battery Idle': 'Idle', // 9
      'Phase of Lifecycle': 'Phase of Lifecycle',
      'Use Phase': 'Use Phase',
      'Commissioning Phase': 'Commissioning Phase',
      'Installation Phase': 'Installation Phase',
      'Device Status': 'Device Status',
      'Basic Information': 'Basic Information',
      'Gateway': 'Gateway',
      'Grid Meter': 'Grid Meter',
      'Load Meter': 'Load Meter',
      'Total Active Power': 'Total Active Power',
      'Active Power A': 'Active Power A',
      'Active Power B': 'Active Power B',
      'Active Power C': 'Active Power C',
      'Total Reactive Power': 'Total Reactive Power',
      'Reactive Power A': 'Reactive Power A',
      'Reactive Power B': 'Reactive Power B',
      'Reactive Power C': 'Reactive Power C',
      'Total Apparent Power': 'Total Apparent Power',
      'Apparent Power A': 'Apparent Power A',
      'Apparent Power B': 'Apparent Power B',
      'Apparent Power C': 'Apparent Power C',
      'Total Power Factor': 'Total Power Factor',
      'Active Energy Import': 'Active Energy Import',
      'Active Energy Export': 'Active Energy Export',
      'Active Energy Net': 'Active Energy Net',

      // Photovoltaic Power Station
      'Invertor': 'Invertor',
      'Invertor Run State': 'Invertor Run State',
      'Invertor Unknown': 'Unknown',
      'Invertor Initializing': 'Initializing',
      'Invertor Standby': 'Standby',
      'Invertor Shutdown': 'Shutdown',
      'Invertor Fault': 'Fault',
      'Invertor Running': 'Running',

      //Advanced Reporting & Knowledge base
      'Created Datetime': 'Created Datetime',
      'File Format': 'File Format',
      'File Size': 'File Size',
      Uploader: 'Uploader',
      //Error
      "The page you're looking for is not found": "The page you're looking for is not found",
      "Make sure the address is correct and that the page hasn't moved. ":
        "Make sure the address is correct and that the page hasn't moved. ",
      'If you think this is a mistake,': 'If you think this is a mistake,',
      'contact us': 'contact us',
      'Take me home': 'Take me home',
      'Whoops, something went wrong!': 'Whoops, something went wrong!',
      'Try refreshing the page, or going back and attempting the action again. ':
        'Try refreshing the page, or going back and attempting the action again. ',
      'If this problem persists,': 'If this problem persists,',
      'Captcha Error': 'Captcha Error',
      'Confirm Password Error': 'The confirm password does not match',
      'It looks like you clicked on an invalid password reset link. Please tryagain.':
        'It looks like you clicked \
      on an invalid password reset link. Please tryagain.',
      'It looks like you clicked on an invalid registration account link. Please tryagain.':
        'It looks like you clicked \
      on an invalid registration account link. Please tryagain.',
      //Tenant Bill
      'Lease Contract Number': 'Lease Contract Number',
      Download: 'Download',
      Print: 'Print',
      'Payment Notice': 'Payment Notice',
      'Bill To': 'Bill To',
      'Bill Number': 'Bill Number',
      'Bill Date': 'Bill Date',
      'Payment Due Date': 'Payment Due Date',
      'Amount Payable': 'Amount Payable',
      'Billing Period Start': 'Billing Period Start',
      'Billing Period End': 'Billing Period End',
      Quantity: 'Quantity',
      Price: 'Price',
      Unit: 'Unit',
      Amount: 'Amount',
      Subtotal: 'Subtotal',
      'VAT Output Tax': 'VAT Output Tax',
      'Total Amount Payable': 'Total Amount Payable',
      'Please make sure to pay on or before the payment due date above':
        'Please make sure to pay on or before the payment due date above',
      'Send money to the following account': 'Send money to the following account',
      'Account Name': 'Account Name',
      'Bank Name': 'Bank Name',
      'Bank Address': 'Bank Address',
      'RMB Account': 'RMB Account',
      // button
      'Toggle Navigation': 'Toggle Navigation',
      // working calendar
      'Working Days': 'Working Days',
      'Non Working Days': 'Non Working Days',
      'Select Row': 'Please Select Data Row',
      // Offline Meter Input
      'Daily Value': 'Daily Value',
      Date: 'Date',
      'Successfully Saved': 'Successfully Saved',
      'Previous Data Is Empty': 'Previous Data Is Empty',
      // API
      'API.ACCOUNT_UNLOCK_FAILED': 'Account unlock failed',
      'API.ADMINISTRATOR_SESSION_NOT_FOUND': 'Administrator session not found',
      'API.ADMINISTRATOR_SESSION_TIMEOUT': 'Administrator session timeout',
      'API.ADVANCED_REPORT_NOT_FOUND': 'Advanced report not found',
      'API.API_KEY_HAS_EXPIRED': 'Key has expired',
      'API.API_KEY_NAME_IS_ALREADY_IN_USE': 'Key name is already in use',
      'API.API_KEY_NOT_FOUND': 'Key not found',
      'API.BAD_REQUEST': 'Bad request',
      'API.BALANCING_PRICE_POINT_NOT_FOUND': 'Balancing price point not found',
      'API.BATTERY_STATE_POINT_NOT_FOUND': 'Battery state point not found',
      'API.BUY_METER_NOT_FOUND': 'Buy meter not found',
      'API.CANNOT_SET_EXISTING_SUBMETER_AS_MASTER_METER': 'Cannot set existing submeter as master meter',
      'API.CHARGE_METER_NOT_FOUND': 'Charge meter not found',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_EXISTS': 'Combined equipment command relation exists',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Combined equipment command relation not found',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_EXISTS': 'Combined equipment equipment relation exists',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_NOT_FOUND': 'Combined equipment equipment relation not found',
      'API.COMBINED_EQUIPMENT_METER_RELATION_EXISTS': 'Combined equipment meter relation exists',
      'API.COMBINED_EQUIPMENT_METER_RELATION_NOT_FOUND': 'Combined equipment meter relation not found',
      'API.COMBINED_EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Combined equipment name is already in use',
      'API.COMBINED_EQUIPMENT_NOT_FOUND': 'Combined equipment not found',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Combined equipment offline meter relation exists',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Combined equipment offline meter relation not found',
      'API.COMBINED_EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Combined equipment parameter name is already in use',
      'API.COMBINED_EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Combined equipment parameter not found or not match',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Combined equipment virtual meter relation exists',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Combined equipment virtual meter relation not found',
      'API.COMMAND_NAME_IS_ALREADY_IN_USE': 'Command name is already in use',
      'API.COMMAND_NOT_FOUND': 'Command not  found',
      'API.CONTACT_NAME_IS_ALREADY_IN_USE': 'Contact name is already in use',
      'API.CONTACT_NOT_FOUND': 'Contact not found',
      'API.COOLING_METER_NOT_FOUND': 'Cooling meter not found',
      'API.COST_CENTER_EXTERNAL_ID_EXISTS': 'Cost center external id exists',
      'API.COST_CENTER_NAME_EXISTS': 'Cost center name exists',
      'API.COST_CENTER_NOT_FOUND': 'Cost center not found',
      'API.COST_FILE_NOT_FOUND': 'Cost file not found',
      'API.DATA_REPAIR_FILE_NOT_FOUND': 'Data repair file not found',
      'API.DATA_SOURCE_NAME_IS_ALREADY_IN_USE': 'Data source name is already in use',
      'API.DATA_SOURCE_NOT_FOUND': 'Data source not found',
      'API.DATABASE_ERROR': 'Database error',
      'API.DATE_IS_ALREADY_IN_WORKING_CALENDAR': 'Date Is Already In Working Calendar',
      'API.DATE_LOCAL_NOT_FOUND': 'Date local not found',
      'API.DISCHARGE_METER_NOT_FOUND': 'Discharge meter not found',
      'API.DISTRIBUTION_CIRCUIT_NAME_IS_ALREADY_IN_USE': 'Distribution circuit name is already in use',
      'API.DISTRIBUTION_CIRCUIT_NOT_FOUND': 'Distribution circuit not found',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_EXISTS': 'Distribution circuit point relation exists',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_NOT_FOUND': 'Distribution circuit point relation not found',
      'API.DISTRIBUTION_SYSTEM_NAME_IS_ALREADY_IN_USE': 'Distribution system name is already in use',
      'API.DISTRIBUTION_SYSTEM_NOT_FOUND': 'Distribution system not found',
      'API.ELECTRICITY_METER_NOT_FOUND': 'Electricity meter not found',
      'API.EMAIL_IS_ALREADY_IN_USE': 'Email is already in use',
      'API.EMAIL_MESSAGE_NOT_FOUND': 'Email message not found',
      'API.EMAIL_NOT_FOUND': 'Email not found',
      'API.EMAIL_SERVER_HOST_IS_ALREADY_IN_USE': 'Email server host is already in use',
      'API.EMAIL_SERVER_NOT_FOUND': 'Email server not found',
      'API.EMPTY_VARIABLES_ARRAY': 'Empty variables array',
      'API.ENERGY_CATEGORY_NAME_IS_ALREADY_IN_USE': 'Energy category name is already in use',
      'API.ENERGY_CATEGORY_NOT_FOUND': 'Energy category not found',
      'API.ENERGY_CATEGORY_USED_IN_ENERGY_ITEMS': 'Energy category used in energy items',
      'API.ENERGY_CATEGORY_USED_IN_METER': 'Energy category used in meter',
      'API.ENERGY_CATEGORY_USED_IN_OFFLINE_METER': 'Energy category used in offline meter',
      'API.ENERGY_CATEGORY_USED_IN_TARIFFS': 'Energy category used in tariffs',
      'API.ENERGY_CATEGORY_USED_IN_VIRTUAL_METER': 'Energy category used in virtual meter',
      'API.ENERGY_FLOW_DIAGRAM_LINK_IS_ALREADY_IN_USE': 'Energy flow diagram link is already in use',
      'API.ENERGY_FLOW_DIAGRAM_LINK_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram link not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram node name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram node not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NOT_FOUND': 'Energy flow diagram not found',
      'API.ENERGY_ITEM_DOES_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item does not belong to energy category',
      'API.ENERGY_ITEM_IS_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item is not belong to energy category',
      'API.ENERGY_ITEM_NAME_IS_ALREADY_IN_USE': 'Energy item name is already in use',
      'API.ENERGY_ITEM_NOT_FOUND': 'Energy item not found',
      'API.ENERGY_ITEM_USED_IN_METER': 'Energy item used in meter',
      'API.ENERGY_ITEM_USED_IN_OFFLINE_METER': 'Energy item used in offline meter',
      'API.ENERGY_ITEM_USED_IN_VIRTUAL_METER': 'Energy item used in virtual meter',
      'API.ENERGY_STORAGE_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Energy storage power station name is already in use',
      'API.ENERGY_STORAGE_POWER_STATION_NOT_FOUND': 'Energy storage power station not found',
      'API.EQUIPMENT_COMMAND_RELATION_EXISTS': 'Equipment command relation exists',
      'API.EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Equipment command relation not found',
      'API.EQUIPMENT_METER_RELATION_EXISTS': 'Equipment meter relation exists',
      'API.EQUIPMENT_METER_RELATION_NOT_FOUND': 'Equipment meter relation not found',
      'API.EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Equipment name is already in use',
      'API.EQUIPMENT_NOT_FOUND': 'Equipment not found',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Equipment offline meter relation exists',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Equipment offline meter relation not found',
      'API.EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Equipment parameter name is already in use',
      'API.EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Equipment parameter not found or not match',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Equipment virtual meter relation exists',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Equipment virtual meter relation not found',
      'API.ERROR': 'Error',
      'API.EXCEPTION': 'Exception',
      'API.FAILED_TO_READ_REQUEST_STREAM': 'Failed to read request stream',
      'API.FAILED_TO_RESTORE_COST_FILE': 'Failed to restore cost file',
      'API.FAILED_TO_RESTORE_DATA_REPAIR_FILE': 'Failed to restore data repair file',
      'API.FAILED_TO_RESTORE_KNOWLEDGE_FILE': 'Failed to restore knowledge file',
      'API.FAILED_TO_RESTORE_OFFLINE_METER_FILE': 'Failed to restore offline meter file',
      'API.FAILED_TO_SAVE_OFFLINE_METER_FILE': 'Failed to save offline meter file',
      'API.FAILED_TO_UPLOAD_ATTACHMENT_FILE': 'Failed to upload attachment file',
      'API.FAILED_TO_UPLOAD_COST_FILE': 'Failed to upload cost file',
      'API.FAILED_TO_UPLOAD_DATA_REPAIR_FILE': 'Failed to upload data repair file',
      'API.FAILED_TO_UPLOAD_KNOWLEDGE_FILE': 'Failed to upload knowledge file',
      'API.FAILED_TO_UPLOAD_OFFLINE_METER_FILE': 'Failed to upload offline meter file',
      'API.GATEWAY_NAME_IS_ALREADY_IN_USE': 'Gateway name is already in use',
      'API.GATEWAY_NOT_FOUND': 'Gateway not found',
      'API.HEAT_METER_NOT_FOUND': 'Heat meter not found',
      'API.INVALID_': 'Invalid ',
      'API.INVALID_ACKNOWLEDGE_CODE': 'Invalid acknowledge code',
      'API.INVALID_ADDRESS': 'Invalid address',
      'API.INVALID_ADDRESS_VALUE': 'Invalid address value',
      'API.INVALID_ADVANCED_REPORT_ID': 'Invalid advanced report id',
      'API.INVALID_API_KEY_ID': 'Invalid api key id',
      'API.INVALID_API_KEY_NAME': 'Invalid api key name',
      'API.INVALID_AREA_VALUE': 'Invalid area value',
      'API.INVALID_BALANCING_PRICE_POINT_ID': 'Invalid balancing price point ID',
      'API.INVALID_BASE_PERIOD_END_DATETIME': 'Invalid base period end datetime',
      'API.INVALID_BASE_PERIOD_START_DATETIME': 'Invalid base period start datetime',
      'API.INVALID_BATTERY_STATE_POINT_ID': 'Invalid battery state point ID',
      'API.INVALID_BAUD_RATE': 'Invalid baud rate',
      'API.INVALID_BUILDINGS_VALUE': 'Invalid buildings value',
      'API.INVALID_BUY_METER_ID': 'Invalid buy meter id',
      'API.INVALID_CAPACITY': 'Invalid capacity',
      'API.INVALID_CATEGORY': 'Invalid category',
      'API.INVALID_CHANNEL': 'Invalid channel',
      'API.INVALID_CHARGE_METER_ID': 'Invalid charge meter id',
      'API.INVALID_COMBINED_EQUIPMENT_ID': 'Invalid combined equipment id',
      'API.INVALID_COMBINED_EQUIPMENT_NAME': 'Invalid combined equipment name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_ID': 'Invalid combined equipment parameter id',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_NAME': 'Invalid combined equipment parameter name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_TYPE': 'Invalid combined equipment parameter type',
      'API.INVALID_COMBINED_EQUIPMENT_UUID': 'Invalid combined equipment uuid',
      'API.INVALID_COMMAND_ID': 'Invalid command id',
      'API.INVALID_COMMAND_NAME': 'Invalid command name',
      'API.INVALID_CONNECTION': 'Invalid connection',
      'API.INVALID_CONSTANT_VALUE': 'Invalid constant value',
      'API.INVALID_CONTACT_ID': 'Invalid contact id',
      'API.INVALID_CONTACT_NAME': 'Invalid contact name',
      'API.INVALID_COOLING_METER_ID': 'Invalid cooling meter id',
      'API.INVALID_COST_CENTER_ID': 'Invalid cost center id',
      'API.INVALID_COST_FILE_ID': 'Invalid cost file id',
      'API.INVALID_CREATED_DATETIME': 'Invalid created datetime',
      'API.INVALID_DATA_REPAIR_FILE_ID': 'Invalid data repair file id',
      'API.INVALID_DATA_SOURCE_ID': 'Invalid data source id',
      'API.INVALID_DATA_SOURCE_NAME': 'Invalid data source name',
      'API.INVALID_DATA_SOURCE_PROTOCOL': 'Invalid data source protocol',
      'API.INVALID_DATE_LOCAL': 'Invalid date local',
      'API.INVALID_DENOMINATOR_METER_UUID': 'Invalid denominator meter uuid',
      'API.INVALID_DISCHARGE_METER_ID': 'Invalid discharge meter id',
      'API.INVALID_DISPLAY_NAME': 'Invalid display name',
      'API.INVALID_DISTRIBUTION_CIRCUIT_ID': 'Invalid distribution circuit id',
      'API.INVALID_DISTRIBUTION_CIRCUIT_NAME': 'Invalid distribution circuit name',
      'API.INVALID_DISTRIBUTION_ROOM': 'Invalid distribution room',
      'API.INVALID_DISTRIBUTION_SYSTEM_ID': 'Invalid distribution system id',
      'API.INVALID_DISTRIBUTION_SYSTEM_NAME': 'Invalid distribution system name',
      'API.INVALID_ELECTRICITY_METER_ID': 'Invalid Electricity meter id',
      'API.INVALID_EMAIL': 'Invalid email',
      'API.INVALID_EMAIL_MESSAGE_ID': 'Invalid email message id',
      'API.INVALID_EMAIL_SERVER_HOST': 'Invalid email server host',
      'API.INVALID_EMAIL_SERVER_ID': 'Invalid email server id',
      'API.INVALID_END_DATE_FORMAT': 'Invalid end date format',
      'API.INVALID_END_DATETIME': 'Invalid end datetime',
      'API.INVALID_ENERGY_CATEGORY_ID': 'Invalid energy category id',
      'API.INVALID_ENERGY_CATEGORY_NAME': 'Invalid energy category name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_ID': 'Invalid energy flow diagram id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_LINK_ID': 'Invalid energy flow diagram link id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NAME': 'Invalid energy flow diagram name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_ID': 'Invalid energy flow diagram node id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_NAME': 'Invalid energy flow diagram node name',
      'API.INVALID_ENERGY_ITEM_ID': 'Invalid energy item id',
      'API.INVALID_ENERGY_ITEM_NAME': 'Invalid energy item name',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_ID': 'Invalid energy storage power station ID',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_NAME': 'Invalid energy storage power station name',
      'API.INVALID_EQUATION_IN_EXPRESSION': 'Invalid equation in expression',
      'API.INVALID_EQUIPMENT_ID': 'Invalid equipment id',
      'API.INVALID_EQUIPMENT_NAME': 'Invalid equipment name',
      'API.INVALID_EQUIPMENT_PARAMETER_ID': 'Invalid equipment parameter id',
      'API.INVALID_EQUIPMENT_PARAMETER_NAME': 'Invalid equipment parameter name',
      'API.INVALID_EQUIPMENT_PARAMETER_TYPE': 'Invalid equipment parameter type',
      'API.INVALID_EQUIPMENT_UUID': 'Invalid equipment uuid',
      'API.INVALID_EXPIRES_DATETIME': 'Invalid expires datetime',
      'API.INVALID_EXPRESSION': 'Invalid expression',
      'API.INVALID_EXPRESSION_OBJECT': 'Invalid expression object',
      'API.INVALID_FDD_CODE': 'Invalid fdd code',
      'API.INVALID_FLOORS_VALUE': 'Invalid floors value',
      'API.INVALID_FROM_ADDR': 'Invalid from addr',
      'API.INVALID_GATEWAY_ID': 'Invalid gateway id',
      'API.INVALID_GATEWAY_NAME': 'Invalid gateway name',
      'API.INVALID_HEAT_METER_ID': 'Invalid heat meter id',
      'API.INVALID_HIGH_LIMIT_VALUE': 'Invalid high limit value',
      'API.INVALID_HOURLY_HIGH_LIMIT_VALUE': 'Invalid hourly high limit value',
      'API.INVALID_HOURLY_LOW_LIMIT_VALUE': 'Invalid hourly low limit value',
      'API.INVALID_Id': 'Invalid id',
      'API.INVALID_IS_ADMIN_VALUE': 'Invalid is admin value',
      'API.INVALID_IS_COST_DATA_DISPLAYED': 'Invalid is cost data displayed',
      'API.INVALID_IS_COUNTED_VALUE': 'Invalid is counted value',
      'API.INVALID_IS_ENABLED': 'Invalid is enabled',
      'API.INVALID_IS_HIDDEN': 'Invalid is hidden',
      'API.INVALID_IS_IN_LEASE_VALUE': 'Invalid is in lease value',
      'API.INVALID_IS_INPUT_COUNTED_VALUE': 'Invalid is input counted value',
      'API.INVALID_IS_KEY_TENANT_VALUE': 'Invalid is key tenant value',
      'API.INVALID_IS_OUTPUT_COUNTED_VALUE': 'Invalid is output counted value',
      'API.INVALID_IS_OUTPUT_VALUE': 'Invalid is output value',
      'API.INVALID_IS_READ_ONLY_VALUE': 'Invalid is read only value',
      'API.INVALID_IS_TREND_VALUE': 'Invalid is trend value',
      'API.INVALID_IS_VIRTUAL_VALUE': 'Invalid is virtual value',
      'API.INVALID_KGCE': 'Invalid kgce',
      'API.INVALID_KGCO2E': 'Invalid kgco2e',
      'API.INVALID_KNOWLEDGE_FILE_ID': 'Invalid knowledge file id',
      'API.INVALID_LATITUDE_VALUE': 'Invalid latitude value',
      'API.INVALID_LEASE_NUMBER_VALUE': 'Invalid lease number value',
      'API.INVALID_LONGITUDE_VALUE': 'Invalid longitude value',
      'API.INVALID_LOW_LIMIT_VALUE': 'Invalid low limit value',
      'API.INVALID_MASTER_METER_ID': 'Invalid master meter id',
      'API.INVALID_MENU_ID': 'Invalid menu id',
      'API.INVALID_MESSAGE_DATA': 'Invalid message data',
      'API.INVALID_MESSAGE_TEMPLATE': 'Invalid message template',
      'API.INVALID_MESSAGE_TEMPLATE_ID': 'Invalid message template id',
      'API.INVALID_MESSAGE_VALUE': 'Invalid message value',
      'API.INVALID_METER_ID': 'Invalid meter id',
      'API.INVALID_METER_NAME': 'Invalid meter name',
      'API.INVALID_METER_UUID': 'Invalid meter uuid',
      'API.INVALID_MICROGRID_BATTERY_ID': 'Invalid microgrid battery id',
      'API.INVALID_MICROGRID_BATTERY_NAME': 'Invalid microgrid battery name',
      'API.INVALID_MICROGRID_EVCHARGER_ID': 'Invalid microgrid evcharger id',
      'API.INVALID_MICROGRID_EVCHARGER_NAME': 'Invalid microgrid evcharger name',
      'API.INVALID_MICROGRID_GENERATOR_ID': 'Invalid microgrid generator id',
      'API.INVALID_MICROGRID_GENERATOR_NAME': 'Invalid microgrid generator name',
      'API.INVALID_MICROGRID_GRID_ID': 'Invalid microgrid grid id',
      'API.INVALID_MICROGRID_GRID_NAME': 'Invalid microgrid grid name',
      'API.INVALID_MICROGRID_HEATPUMP_ID': 'Invalid microgrid heatpump id',
      'API.INVALID_MICROGRID_HEATPUMP_NAME': 'Invalid microgrid heatpump name',
      'API.INVALID_MICROGRID_ID': 'Invalid microgrid id',
      'API.INVALID_MICROGRID_LOAD_ID': 'Invalid microgrid load id',
      'API.INVALID_MICROGRID_LOAD_NAME': 'Invalid microgrid load name',
      'API.INVALID_MICROGRID_NAME': 'Invalid microgrid name',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_ID': 'Invalid microgrid photovoltaic ID',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_NAME': 'Invalid microgrid photovoltaic name',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_ID': 'Invalid microgrid power conversion system ID',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_NAME': 'Invalid microgrid microgrid power conversion system name',
      'API.INVALID_MICROGRID_UUID': 'Invalid microgrid UUID',
      'API.INVALID_NAME_VALUE': 'Invalid name value',
      'API.INVALID_NEW_PASSWORD': 'Invalid new password',
      'API.INVALID_NON_WORKING_DAY_ID': 'Invalid non working day ID',
      'API.INVALID_NOTIFICATION_ID': 'Invalid notification id',
      'API.INVALID_NUMERATOR_METER_UUID': 'Invalid numerator meter uuid',
      'API.INVALID_OBJECT_TYPE': 'Invalid object type',
      'API.INVALID_OFFLINE_METER_DATA': 'Invalid offline meter data',
      'API.INVALID_OFFLINE_METER_FILE_ID': 'Invalid offline meter file id',
      'API.INVALID_OFFLINE_METER_ID': 'Invalid offline meter id',
      'API.INVALID_OFFLINE_METER_NAME': 'Invalid offline meter name',
      'API.INVALID_OFFLINE_METER_UUID': 'Invalid offline meter uuid',
      'API.INVALID_OFFSET_CONSTANT_VALUE': 'Invalid offset constant value',
      'API.INVALID_OLD_PASSWORD': 'Invalid old password',
      'API.INVALID_OPENID': 'Invalid openid',
      'API.INVALID_PARENT_SPACE_ID': 'Invalid parent space id',
      'API.INVALID_PASSWORD': 'Invalid password',
      'API.INVALID_PAYLOAD': 'Invalid payload',
      'API.INVALID_PEAK_CURRENT': 'Invalid peak current',
      'API.INVALID_PEAK_LOAD': 'Invalid peak load',
      'API.INVALID_PERIOD_TYPE': 'Invalid period type',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_ID': 'Invalid photovoltaic power station ID',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_NAME': 'Invalid photovoltaic power station name',
      'API.INVALID_POINT_ID': 'Invalid point id',
      'API.INVALID_POINT_NAME': 'Invalid point name',
      'API.INVALID_PORT': 'Invalid port',
      'API.INVALID_POSTAL_CODE_VALUE': 'Invalid postal code value',
      'API.INVALID_POWER_POINT_ID': 'Invalid power point id',
      'API.INVALID_PRIORITY': 'Invalid priority',
      'API.INVALID_PRIVILEGE': 'Invalid privilege',
      'API.INVALID_PRIVILEGE_DATA': 'Invalid privilege data',
      'API.INVALID_PRIVILEGE_ID': 'Invalid privilege id',
      'API.INVALID_PRIVILEGE_NAME': 'Invalid privilege name',
      'API.INVALID_RATIO_VALUE': 'Invalid ratio value',
      'API.INVALID_RECIPIENT_EMAIL': 'Invalid recipient email',
      'API.INVALID_RECIPIENT_MOBILE': 'Invalid recipient mobile',
      'API.INVALID_RECIPIENT_NAME': 'Invalid recipient name',
      'API.INVALID_RECIPIENT_OPENID': 'Invalid recipient openid',
      'API.INVALID_REPLY': 'Invalid reply',
      'API.INVALID_REPORTING_PERIOD_END_DATETIME': 'Invalid reporting period end datetime',
      'API.INVALID_REPORTING_PERIOD_START_DATETIME': 'Invalid reporting period start datetime',
      'API.INVALID_REQUIRES_AUTHENTICATION': 'Invalid requires authentication',
      'API.INVALID_ROOMS_VALUE': 'Invalid rooms value',
      'API.INVALID_RULE_ID': 'Invalid rule id',
      'API.INVALID_RULE_NAME': 'Invalid rule name',
      'API.INVALID_RUN_STATE_POINT_ID': 'Invalid run state point ID',
      'API.INVALID_SCHEDULED_DATETIME': 'Invalid scheduled datetime',
      'API.INVALID_SELL_METER_ID': 'Invalid sell meter id',
      'API.INVALID_SENSOR_ID': 'Invalid sensor id',
      'API.INVALID_SENSOR_NAME': 'Invalid sensor name',
      'API.INVALID_SENSOR_UUID': 'Invalid sensor uuid',
      'API.INVALID_SERIAL_NUMBER': 'Invalid serial number',
      'API.INVALID_SERIAL_PORT': 'Invalid serial port',
      'API.INVALID_SESSION_PLEASE_RE_LOGIN': 'Invalid session please re-login',
      'API.INVALID_SET_VALUE': 'Invalid set value',
      'API.INVALID_SHOPFLOOR_ID': 'Invalid shopfloor id',
      'API.INVALID_SHOPFLOOR_NAME': 'Invalid shopfloor name',
      'API.INVALID_SHOPFLOOR_UUID': 'Invalid shopfloor uuid',
      'API.INVALID_SOURCE_NODE_ID': 'Invalid source node id',
      'API.INVALID_SPACE_ID': 'Invalid space id',
      'API.INVALID_SPACE_NAME': 'Invalid space name',
      'API.INVALID_SPACE_UUID': 'Invalid space uuid',
      'API.INVALID_START_DATE_FORMAT': 'Invalid start date format',
      'API.INVALID_START_DATETIME': 'Invalid start datetime',
      'API.INVALID_STATUS': 'Invalid status',
      'API.INVALID_STORE_ID': 'Invalid store id',
      'API.INVALID_STORE_NAME': 'Invalid store name',
      'API.INVALID_STORE_TYPE_DESCRIPTION': 'Invalid store type description',
      'API.INVALID_STORE_TYPE_ID': 'Invalid store type id',
      'API.INVALID_STORE_TYPE_NAME': 'Invalid store type name',
      'API.INVALID_STORE_TYPE_SIMPLIFIED_CODE': 'Invalid store type simplified code',
      'API.INVALID_STORE_UUID': 'Invalid store uuid',
      'API.INVALID_SUBJECT_VALUE': 'Invalid subject value',
      'API.INVALID_SVG': 'Invalid SVG',
      'API.INVALID_SWITCHGEAR': 'Invalid switchgear',
      'API.INVALID_TARGET_NODE_ID': 'Invalid target node id',
      'API.INVALID_TARIFF_BLOCK_PRICING': 'Invalid tariff block pricing',
      'API.INVALID_TARIFF_ID': 'Invalid tariff id',
      'API.INVALID_TARIFF_TIME_OF_USE_PRICING': 'Invalid tariff time of use pricing',
      'API.INVALID_TARIFF_TYPE': 'Invalid tariff type',
      'API.INVALID_TENANT_ID': 'Invalid tenant id',
      'API.INVALID_TENANT_NAME': 'Invalid tenant name',
      'API.INVALID_TENANT_TYPE_DESCRIPTION': 'Invalid tenant type description',
      'API.INVALID_TENANT_TYPE_ID': 'Invalid tenant type id',
      'API.INVALID_TENANT_TYPE_NAME': 'Invalid tenant type name',
      'API.INVALID_TENANT_TYPE_SIMPLIFIED_CODE': 'Invalid tenant type simplified code',
      'API.INVALID_TENANT_UUID': 'Invalid tenant uuid',
      'API.INVALID_TEXT_MESSAGE_ID': 'Invalid text message id',
      'API.INVALID_TIMEZONE_ID': 'Invalid timezone id',
      'API.INVALID_TOKEN': 'Invalid token',
      'API.INVALID_TOPIC': 'Invalid topic',
      'API.INVALID_UNIT_OF_MEASURE': 'Invalid unit of measure',
      'API.INVALID_UNIT_OF_PRICE': 'Invalid unit of price',
      'API.INVALID_UNITS': 'Invalid units',
      'API.INVALID_USER_ID': 'Invalid user id',
      'API.INVALID_USER_NAME': 'Invalid user name',
      'API.INVALID_USER_NAME_OR_EMAIL': 'Invalid user name or email',
      'API.INVALID_USER_PHONE': 'Invalid user phone',
      'API.INVALID_USER_PLEASE_RE_LOGIN': 'Invalid user please re-login',
      'API.INVALID_USER_UUID': 'Invalid user uuid',
      'API.INVALID_USERNAME': 'Invalid userName',
      'API.INVALID_VARIABLE_METER_ID': 'Invalid variable meter id',
      'API.INVALID_VARIABLE_METER_TYPE': 'Invalid variable meter type',
      'API.INVALID_VARIABLE_NAME': 'Invalid variable name',
      'API.INVALID_VERIFICATION_CODE': 'Invalid verification code',
      'API.INVALID_VIRTUAL_METER_ID': 'Invalid virtual meter id',
      'API.INVALID_VIRTUAL_METER_NAME': 'Invalid virtual meter name',
      'API.INVALID_VIRTUAL_METER_UUID': 'Invalid virtual meter uuid',
      'API.INVALID_WEB_MESSAGE_ID': 'Invalid web message id',
      'API.INVALID_WECHAT_MESSAGE_ID': 'Invalid wechat message id',
      'API.INVALID_WIND_FARM_ID': 'Invalid wind farm ID',
      'API.INVALID_WIND_FARM_NAME': 'Invalid wind farm name',
      'API.INVALID_WORKING_CALENDAR_ID': 'Invalid working calendar id ',
      'API.INVALID_WORKING_CALENDAR_NAME': 'Invalid working calendar name',
      'API.KNOWLEDGE_FILE_CANNOT_BE_REMOVED_FROM_DISK': 'Knowledge File Cannot be Removed from Disk',
      'API.KNOWLEDGE_FILE_NOT_FOUND': 'Knowledge file not found',
      'API.MASTER_METER_DOES_NOT_BELONG_TO_SAME_ENERGY_CATEGORY':
        'Master meter does not belong to same energy category',
      'API.MASTER_METER_NOT_FOUND': 'Master meter not found',
      'API.MENU_NOT_FOUND': 'Menu not found',
      'API.METER_CANNOT_HAVE_MORE_THAN_ONE_ENERGY_VALUE_POINTS': 'Meter cannot have more than one energy value points',
      'API.METER_COMMAND_RELATION_EXISTS': 'Meter command relation exists',
      'API.METER_COMMAND_RELATION_NOT_FOUND': 'Meter command relation not found',
      'API.METER_NAME_IS_ALREADY_IN_USE': 'Meter name is already in use',
      'API.METER_NOT_FOUND': 'Meter not found',
      'API.METER_OF_VARIABLE_NOT_FOUND': 'Meter of variable not found',
      'API.METER_POINT_RELATION_EXISTS': 'Meter point relation exists',
      'API.METER_POINT_RELATION_NOT_FOUND': 'Meter point relation not found',
      'API.MICROGRID_BATTERY_NAME_IS_ALREADY_IN_USE': 'Microgrid battery name is already in use',
      'API.MICROGRID_BATTERY_NOT_FOUND': 'Microgrid battery not found',
      'API.MICROGRID_EVCHARGER_NAME_IS_ALREADY_IN_USE': 'Microgrid evcharger name is already in use',
      'API.MICROGRID_EVCHARGER_NOT_FOUND': 'Microgrid evcharger not fund',
      'API.MICROGRID_GENERATOR_NAME_IS_ALREADY_IN_USE': 'Microgrid generator name is already in use',
      'API.MICROGRID_GENERATOR_NOT_FOUND': 'Microgrid generator not found',
      'API.MICROGRID_GRID_NAME_IS_ALREADY_IN_USE': 'Microgrid grid name is already in use',
      'API.MICROGRID_GRID_NOT_FOUND': 'Microgrid grid not found',
      'API.MICROGRID_HEATPUMP_NAME_IS_ALREADY_IN_USE': 'Microgrid heatpump name is already in use',
      'API.MICROGRID_HEATPUMP_NOT_FOUND': 'Microgrid heatpump not found',
      'API.MICROGRID_LOAD_NAME_IS_ALREADY_IN_USE': 'Microgrid load name is already',
      'API.MICROGRID_LOAD_NOT_FOUND': 'Microgrid load not found',
      'API.MICROGRID_NAME_IS_ALREADY_IN_USE': 'Microgrid name is already in use',
      'API.MICROGRID_NOT_FOUND': 'Microgrid not found',
      'API.MICROGRID_PHOTOVOLTAIC_NAME_IS_ALREADY_IN_USE': 'Microgrid photovoltaic name is already in use',
      'API.MICROGRID_PHOTOVOLTAIC_NOT_FOUND': 'Microgrid photovoltaic not found',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NAME_IS_ALREADY_IN_USE':
        'Microgrid power conversion system name is already in use',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NOT_FOUND': 'Microgrid power conversion system not found',
      'API.MICROGRID_SENSOR_RELATION_EXISTS': 'Microgrid sensor relation exists',
      'API.MICROGRID_SENSOR_RELATION_NOT_FOUND': 'Microgrid sensor not found',
      'API.MQTT_CONNECTION_ERROR': 'Mqtt connection error',
      'API.MQTT_PUBLISH_ERROR': 'Mqtt publish error',
      'API.NEW_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'New password length cannot exceed 100 characters',
      'API.NEW_USER_SESSION_NOT_FOUND': 'New user session not found',
      'API.NEW_USER_SESSION_TIMEOUT': 'New user session timeout',
      'API.NON_WORKING_DAY_NOT_FOUND': 'Non working day non found',
      'API.NOT_FOUND': 'Not found',
      'API.NOTIFICATION_NOT_FOUND': 'Notification not found',
      'API.OFFLINE_METER_FILE_NOT_FOUND': 'Offline meter file not found',
      'API.OFFLINE_METER_NAME_IS_ALREADY_IN_USE': 'Offline meter name is already in use',
      'API.OFFLINE_METER_NOT_FOUND': 'Offline meter not found',
      'API.OFFLINE_METER_OF_VARIABLE_NOT_FOUND': 'Offline meter of variable not found',
      'API.OLD_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Old password length cannot exceed 100 characters',
      'API.PARENT_SPACE_NOT_FOUND': 'Parent space not found',
      'API.PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Password length cannot exceed 100 characters',
      'API.PASSWORDS_MATCH': 'New password is the same as the old password',
      'API.PHOTOVOLTAIC_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Photovoltaic power station name is already in use',
      'API.PHOTOVOLTAIC_POWER_STATION_NOT_FOUND': 'Photovoltaic power station not found',
      'API.POINT_NAME_IS_ALREADY_IN_USE': 'Point name is already in use',
      'API.POINT_NOT_FOUND': 'Point not found',
      'API.POWER_POINT_NOT_FOUND': 'Power point not found',
      'API.PRIVILEGE_NAME_IS_ALREADY_IN_USE': 'Privilege name is already in use',
      'API.PRIVILEGE_NOT_FOUND': 'Privilege not found',
      'API.RULE_NAME_IS_ALREADY_IN_USE': 'Rule name is already in use',
      'API.RULE_NOT_FOUND': 'Rule not found',
      'API.SELL_METER_NOT_FOUND': 'Sell meter not found',
      'API.SENSOR_NAME_IS_ALREADY_IN_USE': 'Sensor name is already in use',
      'API.SENSOR_NOT_FOUND': 'Sensor not found',
      'API.SENSOR_POINT_RELATION_EXISTS': 'Sensor point relation exists',
      'API.SENSOR_POINT_RELATION_NOT_FOUND': 'Sensor point relation not found',
      'API.SHOPFLOOR_COMMAND_RELATION_EXISTS': 'Shopfloor command relation exists',
      'API.SHOPFLOOR_COMMAND_RELATION_NOT_FOUND': 'Shopfloor command relation not found',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_EXISTS': 'Shopfloor equipment relation exists',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_NOT_FOUND': 'Shopfloor equipment relation not found',
      'API.SHOPFLOOR_METER_RELATION_EXISTS': 'Shopfloor meter relation exists',
      'API.SHOPFLOOR_METER_RELATION_NOT_FOUND': 'Shopfloor meter relation not found',
      'API.SHOPFLOOR_NAME_IS_ALREADY_IN_USE': 'Shopfloor name is already in use',
      'API.SHOPFLOOR_NOT_FOUND': 'Shopfloor not found',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_EXISTS': 'Shopfloor offline meter relation exists',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_NOT_FOUND': 'Shopfloor offline meter relation not found',
      'API.SHOPFLOOR_POINT_RELATION_EXISTS': 'Shopfloor point relation exists',
      'API.SHOPFLOOR_POINT_RELATION_NOT_FOUND': 'Shopfloor point relation not found',
      'API.SHOPFLOOR_SENSOR_RELATION_EXISTS': 'Shopfloor sensor relation exists',
      'API.SHOPFLOOR_SENSOR_RELATION_NOT_FOUND': 'Shopfloor sensor relation not found',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_EXISTS': 'Shopfloor virtual meter relation exists',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_NOT_FOUND': 'Shopfloor virtual meter relation not found',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_EXISTS': 'Shopfloor working calendar relation exists',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Shopfloor working calendar relation not found',
      'API.SOC_POINT_NOT_FOUND': 'SOC point not found',
      'API.SOURCE_NODE_NOT_FOUND': 'Source node not found',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_EXISTS': 'Space combined equipment relation exists',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_NOT_FOUND': 'Space combined equipment relation not found',
      'API.SPACE_COMMAND_RELATION_EXISTS': 'Space command relation exists',
      'API.SPACE_COMMAND_RELATION_NOT_FOUND': 'Space command relation not found',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_EXISTS': 'Space Energy Storage Power Station Relation Exists',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_NOT_FOUND': 'Space Energy Storage Power Station Relation Not Found',
      'API.SPACE_EQUIPMENT_RELATION_EXISTS': 'Space equipment relation exists',
      'API.SPACE_EQUIPMENT_RELATION_NOT_FOUND': 'Space equipment relation not found',
      'API.SPACE_METER_RELATION_EXISTS': 'Space meter relation exists',
      'API.SPACE_METER_RELATION_NOT_FOUND': 'Space meter relation not found',
      'API.SPACE_NAME_IS_ALREADY_IN_USE': 'Space name is already in use',
      'API.SPACE_NOT_FOUND': 'Space not found',
      'API.SPACE_NOT_FOUND_IN_PRIVILEGE': 'Space not found in privilege',
      'API.SPACE_OFFLINE_METER_RELATION_EXISTS': 'Space offline meter relation exists',
      'API.SPACE_OFFLINE_METER_RELATION_NOT_FOUND': 'Space offline meter relation not found',
      'API.SPACE_POINT_RELATION_EXISTS': 'Space point relation exists',
      'API.SPACE_POINT_RELATION_NOT_FOUND': 'Space point relation not found',
      'API.SPACE_SENSOR_RELATION_EXISTS': 'Space sensor relation exists',
      'API.SPACE_SENSOR_RELATION_NOT_FOUND': 'Space sensor relation not found',
      'API.SPACE_SHOPFLOOR_RELATION_EXISTS': 'Space shopfloor relation exists',
      'API.SPACE_SHOPFLOOR_RELATION_NOT_FOUND': 'Space shopfloor relation not found',
      'API.SPACE_STORE_RELATION_EXISTS': 'Space store relation exists',
      'API.SPACE_STORE_RELATION_NOT_FOUND': 'Space store relation not found',
      'API.SPACE_TENANT_RELATION_EXISTS': 'Space tenant relation exists',
      'API.SPACE_TENANT_RELATION_NOT_FOUND': 'Space tenant relation not found',
      'API.SPACE_VIRTUAL_METER_RELATION_EXISTS': 'Space virtual meter relation exists',
      'API.SPACE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Space virtual meter relation not found',
      'API.SPACE_WORKING_CALENDAR_RELATION_EXISTS': 'Space working calendar relation exists',
      'API.SPACE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Space working calendar relation not found',
      'API.START_DATETIME_MUST_BE_EARLIER_THAN_END_DATETIME': 'Start datetime must be early than end datetime',
      'API.STORE_COMMAND_RELATION_EXISTS': 'Store command relation exists',
      'API.STORE_COMMAND_RELATION_NOT_FOUND': 'Store command relation not found',
      'API.STORE_METER_RELATION_EXISTS': 'Store meter relation exists',
      'API.STORE_METER_RELATION_NOT_FOUND': 'Store meter relation not found',
      'API.STORE_NAME_IS_ALREADY_IN_USE': 'Store name is already in use',
      'API.STORE_NOT_FOUND': 'Store not found',
      'API.STORE_OFFLINE_METER_RELATION_EXISTS': 'Store offline meter relation exists',
      'API.STORE_OFFLINE_METER_RELATION_NOT_FOUND': 'Store offline meter relation not found',
      'API.STORE_POINT_RELATION_EXISTS': 'Store point relation exists',
      'API.STORE_POINT_RELATION_NOT_FOUND': 'Store point relation not found',
      'API.STORE_SENSOR_RELATION_EXISTS': 'Store sensor relation exists',
      'API.STORE_SENSOR_RELATION_NOT_FOUND': 'Store sensor relation not found',
      'API.STORE_TYPE_NAME_IS_ALREADY_IN_USE': 'Store type name is already in use',
      'API.STORE_TYPE_NOT_FOUND': 'Store type not found',
      'API.STORE_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Store type simplified code is already in use',
      'API.STORE_TYPE_USED_IN_STORE': 'Store type used in store',
      'API.STORE_VIRTUAL_METER_RELATION_EXISTS': 'Store virtual meter relation exists',
      'API.STORE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Store virtual meter relation not found',
      'API.STORE_WORKING_CALENDAR_RELATION_EXISTS': 'Store working calendar relation exists',
      'API.STORE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Store working calendar relation not found',
      'API.TARGET_NODE_NOT_FOUND': 'Target node not found',
      'API.TARIFF_IN_USE': 'Tariff in use',
      'API.TARIFF_IS_ALREADY_ASSOCIATED_WITH_COST_CENTER': 'Tariff is already associated with cost center',
      'API.TARIFF_IS_NOT_ASSOCIATED_WITH_COST_CENTER': 'Tariff is not associated with cost center',
      'API.TARIFF_NAME_IS_ALREADY_IN_USE': 'Tariff name is already in use',
      'API.TARIFF_NOT_FOUND': 'Tariff not found',
      'API.TENANT_COMMAND_RELATION_EXISTS': 'Tenant command relation exists',
      'API.TENANT_COMMAND_RELATION_NOT_FOUND': 'Tenant command relation  not found',
      'API.TENANT_METER_RELATION_EXISTS': 'Tenant meter relation exists',
      'API.TENANT_METER_RELATION_NOT_FOUND': 'Tenant meter relation not found',
      'API.TENANT_NAME_IS_ALREADY_IN_USE': 'Tenant name is already in use',
      'API.TENANT_NOT_FOUND': 'Tenant not found',
      'API.TENANT_OFFLINE_METER_RELATION_EXISTS': 'Tenant offline meter relation exists',
      'API.TENANT_OFFLINE_METER_RELATION_NOT_FOUND': 'Tenant offline meter relation not found',
      'API.TENANT_POINT_RELATION_EXISTS': 'Tenant point relation exists',
      'API.TENANT_POINT_RELATION_NOT_FOUND': 'Tenant point relation not found',
      'API.TENANT_SENSOR_RELATION_EXISTS': 'Tenant sensor relation exists',
      'API.TENANT_SENSOR_RELATION_NOT_FOUND': 'Tenant sensor relation not found',
      'API.TENANT_TYPE_NAME_IS_ALREADY_IN_USE': 'Tenant type name is already in use',
      'API.TENANT_TYPE_NOT_FOUND': 'Tenant type not found',
      'API.TENANT_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Tenant type simplified code is already in use',
      'API.TENANT_TYPE_USED_IN_TENANT': 'Tenant type used in tenant',
      'API.TENANT_VIRTUAL_METER_RELATION_EXISTS': 'Tenant virtual meter relation exists',
      'API.TENANT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Tenant virtual meter relation not found',
      'API.TENANT_WORKING_CALENDAR_RELATION_EXISTS': 'Tenant working calendar relation exists',
      'API.TENANT_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Tenant working calendar relation not found',
      'API.TEXT_MESSAGE_NOT_FOUND': 'Text message not found',
      'API.THE_REPORTING_PERIOD_MUST_BE_LONGER_THAN_15_MINUTES': 'The reporting period must be longer than 15 minutes',
      'API.THERE_IS_RELATION_WITH_CHILD_METERS': 'There is relation with child meters',
      'API.THERE_IS_RELATION_WITH_CHILDREN_SPACES': 'There is relation with children spaces',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENT_PARAMETERS':
        'There is relation with combined equipment parameters',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENTS': 'There is relation with combined equipments',
      'API.THERE_IS_RELATION_WITH_DATA_SOURCES': 'There is relation with data sources',
      'API.THERE_IS_RELATION_WITH_ENERGY_FLOW_DIAGRAM_LINKS': 'There is relation with energy flow diagram links',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_BATTERIES': 'There Is Relation With Energy Storage Containers Batteries',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_GRIDS': 'There Is Relation With Energy Storage Containers Grids',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_LOADS': 'There Is Relation With Energy Storage Containers Loads',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_POWER_CONVERSION_SYSTEMS': 'There Is Relation With Energy Storage Containers Power Conversion Systems',
      'API.THERE_IS_RELATION_WITH_EQUIPMENT_PARAMETERS': 'There is relation with equipment parameters',
      'API.THERE_IS_RELATION_WITH_EQUIPMENTS': 'There is relation with equipments',
      'API.THERE_IS_RELATION_WITH_METER': 'There is relation with meter',
      'API.THERE_IS_RELATION_WITH_METERS': 'There is relation with meters',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METER': 'There is relation with offline meter',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METERS': 'There is relation with offline meters',
      'API.THERE_IS_RELATION_WITH_OTHER_VIRTUAL_METERS': 'There is relation with other virtual meters',
      'API.THERE_IS_RELATION_WITH_SENSORS': 'There is relation with sensors',
      'API.THERE_IS_RELATION_WITH_SHOPFLOORS': 'There is relation with shopfloors',
      'API.THERE_IS_RELATION_WITH_SPACES': 'There is relation with spaces',
      'API.THERE_IS_RELATION_WITH_STORES': 'There is relation with stores',
      'API.THERE_IS_RELATION_WITH_TENANTS': 'There is relation with tenants',
      'API.THERE_IS_RELATION_WITH_USERS': 'There is relation with users',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_METER': 'There is relation with virtual meter',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_POWER_PLANTS': 'There is relation with virtual power plants',
      'API.THIS_DATA_SOURCE_IS_BEING_USED_BY_A_METER': 'This data source is being used by a meter',
      'API.THIS_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This meter is being used by a virtual meter',
      'API.THIS_OFFLINE_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This offline meter is being used by a virtual meter',
      'API.THIS_SPACE_CANNOT_BE_CLONED': 'This space cannot be cloned',
      'API.THIS_SPACE_CANNOT_BE_DELETED': 'This space cannot be deleted',
      'API.TIMEZONE_NOT_FOUND': 'Timezone not found',
      'API.TOKEN_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'Token not found in headers please login',
      'API.USER_ACCOUNT_HAS_BEEN_LOCKED': 'User Account Has Been Locked',
      'API.USER_ACCOUNT_HAS_EXPIRED': 'User Account Has Expired',
      'API.USER_ACCOUNT_IS_NOT_LOCKED': 'User account is not locked',
      'API.USER_NAME_IS_ALREADY_IN_USE': 'User name is already in use',
      'API.USER_NOT_FOUND': 'User not found',
      'API.USER_PASSWORD_HAS_EXPIRED': 'User Password Has Expired',
      'API.USER_PRIVILEGE_NOT_FOUND': 'User privilege not found',
      'API.USER_SESSION_NOT_FOUND': 'User session not found',
      'API.USER_SESSION_TIMEOUT': 'User session timeout',
      'API.USER_UUID_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'User uuid not found in headers please login',
      'API.VIRTUAL_METER_NAME_IS_ALREADY_IN_USE': 'Virtual meter name is already in use',
      'API.VIRTUAL_METER_NOT_FOUND': 'Virtual meter not found',
      'API.VIRTUAL_METER_OF_VARIABLE_NOT_FOUND': 'Virtual meter of variable not found',
      'API.VIRTUAL_POINT_SHOULD_BE_ANALOG_VALUE': 'Virtual point should be analog value',
      'API.WEB_MESSAGE_NOT_FOUND': 'Web message not found',
      'API.WECHAT_MESSAGE_NOT_FOUND': 'Wechat message not found',
      'API.WIND_FARM_NAME_IS_ALREADY_IN_USE': 'Wind farm name is already in use',
      'API.WIND_FARM_NOT_FOUND': 'Wind farm not found',
      'API.WORKING_CALENDAR_NAME_IS_ALREADY_IN_USE': 'Working calendar name is already in use',
      'API.WORKING_CALENDAR_NOT_FOUND': 'Working calendar not found',

      MasterMeter: 'Master Meter',
      SubMeter: 'Submeter'
    }
  },
  zh_CN: {
    translation: {
      // routes & menus
      Dashboard: '总览',
      'Space Data': '空间数据',
      'Equipment Data': '设备数据',
      'Meter Data': '计量表数据',
      'Tenant Data': '租户数据',
      'Store Data': '门店数据',
      'Shopfloor Data': '车间数据',
      'Combined Equipment Data': '组合设备数据',
      'Auxiliary System': '辅助系统',
      Microgrid: '微电网',
      'Energy Storage Power Station': '储能电站',
      'Photovoltaic Power Station': '电站',
      'Fault Alarm': '故障报警',
      Monitoring: '设备监控',
      'Advanced Reporting': '高级报表',
      'Knowledge Base': '知识库',
      'Energy Category Data': '能耗分类分析',
      'Energy Item Data': '能耗分项分析',
      Carbon: '碳排放分析',
      Cost: '成本分析',
      Output: '产出分析',
      Income: '收入分析',
      Efficiency: '效率分析',
      Load: '负荷分析',
      Statistics: '统计分析',
      'Batch Analysis': '批量分析',
      Saving: '节能分析',
      Plan: '计划分析',
      'Production': '产量',
      'Space Production': '产量能耗分析',
      'Equipment Tracking': '设备台账',
      'Environment Monitor': '环境监测',
      'Meter Energy': '计量表能耗分析',
      'Meter Carbon': '计量表碳排放分析',
      'Meter Cost': '计量表成本分析',
      'Meter Trend': '计量表趋势分析',
      'Meter Realtime': '计量表实时分析',
      'Meter Saving': '计量表节能分析',
      'Meter Plan': '计量表计划分析',
      'Master Meter Submeters Balance': '总分表平衡分析',
      'Meter Batch Analysis': '计量表批量分析',
      'Meter Comparison': '计量表对比分析',
      'Meter Tracking': '计量表台账',
      'Virtual Meter Saving': '虚拟表节能分析',
      'Virtual Meter Plan': '虚拟表计划分析',
      'Virtual Meter Energy': '虚拟表能耗分析',
      'Virtual Meter Carbon': '虚拟表碳排放分析',
      'Virtual Meter Cost': '虚拟表成本分析',
      'Virtual Meter Batch Analysis': '虚拟表批量分析',
      'Offline Meter Energy': '离线表能耗分析',
      'Offline Meter Carbon': '离线表碳排放分析',
      'Offline Meter Cost': '离线表成本分析',
      'Offline Meter Batch Analysis': '离线表批量分析',
      'Offline Meter Saving': '离线表节能分析',
      'Offline Meter Plan': '离线表计划分析',
      'Offline Meter Input': '离线表数据录入',
      'Tenant Bill': '租户账单',
      'Energy Flow Diagram': '能流图',
      'Distribution System': '配电系统',
      'SVG System': '组态可视化系统',
      'Faults Data': '故障分析',
      'Space Equipments': '空间设备',
      'Combined Equipments': '组合设备',
      'Energy Storage Power Station List': '电站列表',
      'Energy Storage Power Station Details': '设备监控',
      'Energy Storage Power Station Reporting': '数据报表',
      'Energy Storage Power Station Alarm': '故障报警',
      'Energy Storage Power Station Maintenance': '维护保养',
      'Enter Production': '产量录入',
      'Multiple Energy Storage Power Stations': '多站数据',
      'Multiple Energy Storage Power Stations Dashboard': '多站总览',
      'Multiple Energy Storage Power Stations List': '电站列表',
      'Single Energy Storage Power Station': '单站数据',
      'Single Energy Storage Power Station Dashboard': '单站总览',
      'Single Energy Storage Power Station Details': '设备数据',
      'Single Energy Storage Power Station Fault Alarm': '故障告警',
      'Energy Storage Power Station Reports': '统计报表',
      'Energy Storage Power Station Reports Energy': '电量报表',
      'Energy Storage Power Station Reports Revenue': '收益报表',
      'Energy Storage Power Station Reports Parameters': '数据查询',
      'Photovoltaic Power Station List': '电站列表',
      'Photovoltaic Power Station Details': '设备监控',
      'Photovoltaic Power Station Reporting': '数据报表',
      'Photovoltaic Power Station Alarm': '故障报警',
      'Photovoltaic Power Station Maintenance': '维护保养',
      'Multiple Photovoltaic Power Stations': '多站数据',
      'Multiple Photovoltaic Power Stations Dashboard': '多站总览',
      'Multiple Photovoltaic Power Stations List': '电站列表',
      'Single Photovoltaic Power Station': '单站数据',
      'Single Photovoltaic Power Station Dashboard': '单站总览',
      'Single Photovoltaic Power Station Details': '设备数据',
      'Single Photovoltaic Power Station Fault Alarm': '故障告警',
      'Photovoltaic Power Station Reports': '统计报表',
      'Photovoltaic Power Station Reports Energy': '电量报表',
      'Photovoltaic Power Station Reports Revenue': '收益报表',
      'Photovoltaic Power Station Reports Parameters': '数据查询',
      'Work Order': '工单管理',
      'Work Order Installation': '安装记录',
      'Work Order Repair': '维修记录',
      'Work Order Inspection': '巡检记录',
      'Total Number of Work Orders': '总工单数量',
      'Number of New Work Orders': '新发工单数量',
      'Number of Inprogress Work Orders': '进行中工单数量',
      'Number of Done Work Orders': '已完成工单数量',
      'Charge Energy Indicator': '充电能量',
      'Discharge Energy Indicator': '放电能量',
      'Charge Cost Indicator': '充电成本',
      'Discharge Revenue Indicator': '放电收益',
      'HVAC': '空调',
      'Fire Control': '消防',

      // Dashboard
      'Welcome to DoraEOS': '欢迎使用DoraEOS',
      'An Industry Leading Open Source Energy Management System': '行业领先的开源能源管理系统',
      "This Year's Consumption CATEGORY VALUE UNIT": '本年消耗 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Year's Costs CATEGORY VALUE UNIT": '本年成本 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Consumption CATEGORY VALUE UNIT": '本月消耗 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Costs CATEGORY VALUE UNIT": '本月成本 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Consumption CATEGORY VALUE UNIT in The Same Period Last Year': '去年同期消耗 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Costs CATEGORY VALUE UNIT in The Same Period Last Year': '去年同期成本 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'CATEGORY Consumption UNIT': '{{CATEGORY}} 消耗 {{UNIT}}',
      'CATEGORY Costs UNIT': '{{CATEGORY}} 成本 {{UNIT}}',
      // Entities
      Space: '空间',
      Equipment: '设备',
      Meter: '计量表',
      'Offline Meter': '离线表',
      'Virtual Meter': '虚拟表',
      Tenant: '租户',
      Store: '门店',
      Shopfloor: '车间',
      'Combined Equipment': '组合设备',
      'Cost Center': '成本中心',
      Name: '名称',
      DisplayName: '显示名',
      UserName: '用户名',
      Description: '描述',
      'Energy Category': '能源分类',
      'Child Spaces': '子空间',
      'Associated Equipment': '相关设备',
      //Energy Categories
      Electricity: '电',
      Water: '自来水',
      'Natural Gas': '天然气',
      // SidePanelModal
      Settings: '设置',
      'Set your own customized style': '设置您的自定义样式',
      'Color Scheme': '配色方案',
      'Choose the perfect color mode for your app': '为您的应用选择理想的色彩模式',
      Light: '亮色',
      Dark: '暗色',
      'RTL Mode': 'RTL模式',
      'Switch your language direction': '改变你的语言方向',
      'Fluid Layout': '流动布局',
      'Toggle container layout system': '切换容器布局系统',
      'Navigation Position': '导航栏位置',
      'Select a suitable navigation system for your web application': '为您的web应用程序选择合适的导航栏系统',
      'Vertical Nav': '垂直导航栏',
      'Top Nav': '顶部导航栏',
      'Vertical Navbar Style': '垂直导航栏样式',
      'Switch between styles for your vertical navbar': '切换垂直导航栏的样式',
      Language: '语言',
      'Switch between languages': '切换语言',
      'language-zh_CN': '简体中文',
      'language-en': 'English',
      'language-de': 'Deutsch',
      'language-fr': 'Français',
      'language-es': 'Español',
      'language-ru': 'Русский',
      'language-ar': 'العربية',
      'language-vi': 'Tiếng Việt',
      'language-th': 'ภาษาไทย',
      'language-tr': 'Türkçe',
      'language-ms': 'Bahasa Melayu',
      'language-id': 'Bahasa Indonesia',
      'language-zh_TW': '繁體中文',
      'Like What You See?': '喜欢这个系统?',
      'Get DoraEOS now': '立即获取DoraEOS',
      Purchase: '购买',
      // Query Panels
      'Base Period Begins': '基准期开始',
      'Base Period Ends': '基准期结束',
      'Comparison Types': '比较类型',
      'Year-Over-Year': '去年同比',
      'Month-On-Month': '上月环比',
      'Free Comparison': '自由比',
      'None Comparison': '不比',
      'Reporting Period Begins': '报告期开始',
      'Reporting Period Ends': '报告期结束',
      '(Optional)': '(可选)',
      'Period Types': '时间尺度',
      Yearly: '年',
      Monthly: '月',
      Weekly: '周',
      Daily: '日',
      Hourly: '时',
      Submit: '提交',
      'Input Energy Category': '消耗能源分类',
      'Output Energy Category': '产出能源分类',
      'Fraction Parameter': '分式参数',
      Search: '搜索',
      //DateRangePicker
      sunday: '日',
      monday: '一',
      tuesday: '二',
      wednesday: '三',
      thursday: '四',
      friday: '五',
      saturday: '六',
      ok: '确定',
      today: '今天',
      yesterday: '昨天',
      hours: '时',
      minutes: '分',
      seconds: '秒',
      last7Days: '最近七天',
      'Select Date Range': '选择日期范围',
      //Card Summaries and Line Charts
      'Base Period': '基准期',
      'Reporting Period': '报告期',
      'Per Unit Area': '单位面积值',
      'Per Capita': '人均值',
      'Per Unit Production': '单位产品值',
      Baseline: '基线值',
      Actual: '实际值',
      'Baseline Value - Actual Value': '基线值-实际值',
      'Average Load': '平均负荷',
      'Maximum Load': '最大负荷',
      'Load Factor': '负荷系数',
      'Ratio of Average Load to Maximum Load': '平均负荷与最大负荷的比值',
      'Carbon Dioxide Emissions by Energy Category': '二氧化碳排放占比',
      'Costs by Energy Category': '成本占比',
      'Incomes by Energy Category': '收入占比',
      'Electricity Consumption by Time-Of-Use': '分时消耗电量',
      'Electricity Carbon Dioxide Emissions by Time-Of-Use': '分时用电排放二氧化碳',
      'Electricity Cost by Time-Of-Use': '分时用电成本',
      'CATEGORY UNIT Consumption by Energy Items': '{{CATEGORY}} {{UNIT}} 分项消耗占比',
      'Ton of Standard Coal by Energy Category': '吨标准煤(TCE)占比',
      'Ton of Carbon Dioxide Emissions by Energy Category': '吨二氧化碳排放(TCO2E)占比',
      'Reporting Period Consumption CATEGORY UNIT': '报告期消耗 {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Consumption CATEGORY UNIT': '{{EQUIPMENT}} 报告期消耗 {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} 报告期消耗 {{CATEGORY}} {{UNIT}}',
      'CATEGORY VALUE UNIT': '{{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Production PRODUCT VALUE UNIT': '报告期产量 {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Production PRODUCT VALUE UNIT': '基准期产量 {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption CATEGORY VALUE UNIT': '报告期消耗 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption CATEGORY VALUE UNIT': '基准期消耗 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY UNIT': '报告期消耗 {{ITEM}} {{CATEGORY}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY VALUE UNIT': '报告期消耗 {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption ITEM CATEGORY VALUE UNIT': '基准期消耗 {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Child Space Proportion CATEGORY UNIT': '子空间分类占比 {{CATEGORY}} {{UNIT}}',
      'Child Space Total Proportion': '子空间总计占比',
      'Reporting Period Carbon Dioxide Emissions CATEGORY UNIT': '报告期二氧化碳排放 {{CATEGORY}} {{UNIT}}',
      'Reporting Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        '报告期二氧化碳排放 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Carbon Dioxide Emissions CATEGORY VALUE UNIT': '基准期二氧化碳排放 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Costs CATEGORY UNIT': '报告期成本 {{CATEGORY}} {{UNIT}}',
      'Reporting Period Costs CATEGORY VALUE UNIT': '报告期成本 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Costs CATEGORY VALUE UNIT': '基准期成本 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Output CATEGORY UNIT': '报告期产出 {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Output CATEGORY UNIT': '{{EQUIPMENT}} 报告期产出 {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} 报告期产出 {{CATEGORY}} {{UNIT}}',
      'Reporting Period Output CATEGORY VALUE UNIT': '报告期产出 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Output CATEGORY VALUE UNIT': '基准期产出 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Income CATEGORY UNIT': '报告期收入 {{CATEGORY}} {{UNIT}}',
      'Reporting Period Income CATEGORY VALUE UNIT': '报告期收入 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Income CATEGORY VALUE UNIT': '基准期收入 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Total Income UNIT': '报告期总收入 {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME UNIT': '报告期累积效率 {{NAME}} {{UNIT}}',
      'EQUIPMENT Reporting Period Cumulative Efficiency UNIT': '{{EQUIPMENT}} 报告期累积效率 {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} 报告期累积综合效率 {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} 报告期累积综合效率 {{VALUE}} {{UNIT}}',
      'COMBINED_EQUIPMENT Base Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} 基准期累积综合效率 {{VALUE}} {{UNIT}}',
      'Instantaneous Efficiency UNIT': '瞬时效率 {{UNIT}}',
      'EQUIPMENT Instantaneous Efficiency UNIT': '{{EQUIPMENT}} 瞬时效率 {{UNIT}}',
      'COMBINED_EQUIPMENT Instantaneous Comprehensive Efficiency UNIT': '{{COMBINED_EQUIPMENT}} 瞬时综合效率 {{UNIT}}',
      'Reporting Period Cumulative Efficiency VALUE UNIT': '报告期累积效率 {{VALUE}} {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME VALUE UNIT': '报告期累积效率 {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency VALUE UNIT': '基准期累积效率 {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency NAME VALUE UNIT': '基准期累积效率 {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period CATEGORY Maximum Load UNIT': '基准期 {{CATEGORY}} 最大负荷 {{UNIT}}',
      'Reporting Period CATEGORY Maximum Load UNIT': '报告期 {{CATEGORY}} 最大负荷 {{UNIT}}',
      'Reporting Period CATEGORY Average Load UNIT': '报告期 {{CATEGORY}} 平均负荷 {{UNIT}}',
      'Reporting Period CATEGORY Load Factor': '报告期 {{CATEGORY}} 负荷系数',
      'Base Period CATEGORY Average Load VALUE UNIT': '基准期 {{CATEGORY}} 平均负荷 {{VALUE}} {{UNIT}}',
      'Reporting Period CATEGORY Average UNIT': '报告期 {{CATEGORY}} 平均值 {{UNIT}}',
      'Reporting Period CATEGORY Maximum UNIT': '报告期 {{CATEGORY}} 最大值 {{UNIT}}',
      'Reporting Period CATEGORY Minimum UNIT': '报告期 {{CATEGORY}} 最小值 {{UNIT}}',
      'Reporting Period CATEGORY Mean UNIT': '报告期 {{CATEGORY}}  算术平均数 {{UNIT}}',
      'Reporting Period CATEGORY Median UNIT': '报告期 {{CATEGORY}} 中位数 {{UNIT}}',
      'Reporting Period CATEGORY Stdev UNIT': '报告期 {{CATEGORY}} 样本标准差 {{UNIT}}',
      'Reporting Period CATEGORY Variance UNIT': '报告期 {{CATEGORY}} 样本方差 {{UNIT}}',
      'Reporting Period Saving CATEGORY (Baseline - Actual) UNIT': '报告期节约 {{CATEGORY}} (基线-实际) {{UNIT}}',
      'Reporting Period Decreased CATEGORY (Baseline - Actual) UNIT': '报告期减少 {{CATEGORY}} (基线-实际) {{UNIT}}',
      'Reporting Period Saving CATEGORY VALUE UNIT': '报告期节约 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Saving CATEGORY VALUE UNIT': '基准期节约 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY UNIT': '报告期总表消耗 {{CATEGORY}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY VALUE UNIT': '报告期总表消耗 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY UNIT': '报告期分表消耗 {{CATEGORY}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY VALUE UNIT': '报告期分表消耗 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Difference CATEGORY UNIT': '报告期差值 {{CATEGORY}} {{UNIT}}',
      'Reporting Period Percentage Difference': '报告期差值百分比',
      'Reporting Period Difference CATEGORY VALUE UNIT': '报告期差值 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Trend Values': '趋势值',
      'METER CATEGORY VALUE UNIT': '{{METER}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      //FDD
      'Fault List': '故障列表',
      'Reporting Period Number of Fault VALUE': '报告期故障数: {{VALUE}}',
      'All Faults': '全部故障',
      'Space Faults': '空间故障',
      'Number of All Faults': '全部故障数',
      'Number of Space Faults': '空间故障数',
      'Total Number of Faults': '总故障数量',
      'Number of New Faults': '新发故障数量',
      'Number of Inprogress Faults': '进行中故障数量',
      'Number of Done Faults': '已完成故障数量',
      //Data Panels
      'Ton of Standard Coal': '吨标准煤',
      'Ton of Carbon Dioxide Emissions': '吨二氧化碳排放',
      'Operating Characteristic Curve': '运行曲线',
      Tariff: '费率',
      'Detailed Data': '详细数据',
      'Child Spaces Data': '子空间数据',
      'Associated Equipment Data': '相关设备数据',
      'This Year': '本年',
      'The Same Period Last Year': '去年同期',
      'This Month': '本月',
      'This Day': '本日',
      Total: '总计',
      'No data found': '没有数据',
      Export: '导出',
      Datetime: '日期时间',
      'Time-Of-Use Type': '分时类型',
      'Top-Peak': '尖',
      'On-Peak': '峰',
      'Mid-Peak': '平',
      'Off-Peak': '谷',
      Percentage: '百分比',
      //Realtime Chart
      'Trend in the last hour of Energy Value Point': '能耗值点最近一小时趋势',
      Circuit: '回路',
      Point: '数据点',
      'Realtime Value': '实时值',
      //Meter Tracking
      'Meter List': '计量表列表',
      'Edit Meter': '编辑',
      'Start Value': '起始值',
      'End Value': '结束值',
      'Difference Value': '差值',
      'Start Integrity Rate': '起始数据完整率(%)',
      'End Integrity Rate': '结束数据完整率(%)',
      'Full Integrity Rate': '整体数据完整率(%)',
      //Equipment Tracking
      'Equipment List': '设备列表',
      'Edit Equipment': '编辑',
      //Profile Dropdown
      Feedback: '反馈',
      'Account Settings': '账号设置',
      Logout: '退出',
      //Authentication
      'Log in': '登录',
      'Email address': '电子邮件地址',
      Password: '密码',
      CaptchaCode: '验证码',
      Refresh: '刷新',
      'Remember me': '记住我',
      'Logged in as ': '已登录 ',
      'Forgot Password?': '忘记密码?',
      'Forgot your password?': '忘记你的密码?',
      "Enter your email and we'll send you a reset link": '输入电子邮件地址，我们会把重置链接发送给您',
      "Enter your email and we'll send you a registration link": '输入电子邮件地址，我们会把注册链接发送给您',
      'Reset Password': '重置密码',
      'Thanks for using DoraEOS!': '感谢您使用DoraEOS!',
      'You are now successfully signed out': '您已成功退出',
      'Return to Login': '返回登录',
      'Please check your email!': '请查收您的电子邮件!',
      'An email has been sent to ': '一封电子邮件已经发送至 ',
      'Please click on the included link to reset your password': '请点击里面的链接重置您的密码',
      'Please click on the included link to register your account': '请点击里面的链接注册您的账户',
      'An email with password reset link is sent to ': '一封包含密码重置链接的电子邮件已经发送至 ',
      'Change Password': '修改密码',
      'Old Password': '原密码',
      'New Password': '新密码',
      'Confirm Password': '重复新密码',
      'Update Password': '更新密码',
      'Password has been changed!': '密码已修改!',
      'Verification code has been sent to your inbox. \
      Please copy it to the input box below.':
        '验证码已发送到您的收件箱，请将其复制到下面的输入框中。',
      'New to DoraEOS': '初次使用DoraEOS',
      'Create an account': '注册账户',
      'EMAIL Account registration successful': '{{EMAIL}}账号注册成功',
      'Thanks for verifying your account!': '感谢您验证您的账户！',
      'Your code is': '您的代码是',
      'Send verification code': '发送验证码',
      'Please wait for NUMBER seconds': '请等待{{NUMBER}}秒',
      'Password reset': '密码重置',
      'Please wait for approval': '请等待审核',
      //notification
      Notifications: '通知',
      'Mark all as read': '全部设为已读',
      'View all': '全部',
      'Notification New': '新的',
      'Notification Earlier': '更早的',
      'Notification List': '通知列表',
      'Bulk actions': '批量操作',
      'Notification Subject': '主题',
      'Notification Created Datetime': '创建时间',
      'Notification Message': '内容',
      'Notification Priority': '优先级',
      'Notification Status': '状态',
      'Notification Mark As Read': '标记为已读',
      'Notification Mark As Acknowledged': '确认',
      'Notification Low': '低',
      'Notification Medium': '中',
      'Notification High': '高',
      'Notification Critical': '严重',
      'Notification Unread': '未读',
      'Notification Read': '已读',
      'Notification Acknowledged': '已确认',
      'Notification Archive': '存档',
      'Notification Delete': '删除',
      'Notification Apply': '应用',
      'Notification Start Datetime': '开始时间',
      'Notification End Datetime': '结束时间',
      'Notification Update Datetime': '更新时间',
      //Monitoring
      'Run Commands': '运行指令',
      'Fault Alarms': '故障报警',
      'Instantaneous Efficiency VALUE UNIT': '瞬时效率 {{VALUE}} {{UNIT}}',
      'Communication Status': '通信状态',
      'Communication Online': '在线',
      'Communication Offline': '离线',
      'Equipment Status': '设备状态',
      'Equipment Running': '运行',
      'Equipment Stopped': '停机',
      'Show Up to': '每页显示数量',
      All: '全部',
      'FROM - TO of TOTAL': '当前显示{{FROM}} - {{TO}} 总计{{TOTAL}}',
      //Microgrid
      'PCS Run State': 'PCS运行状态',
      'PCS Unknown': '未知',
      'PCS Initializing': '初始上电',
      'PCS Standby': '待机',
      'PCS Shutdown': '关机',
      'PCS Fault': '故障',
      'PCS Running': '运行',
      'Battery Power': '电池功率',
      'Photovoltaic Power': '光伏功率',
      'Grid Power': '电网功率',
      'Load Power': '负载功率',
      Reporting: '数据报表',
      Maintenance: '维护保养',
      'General Information': '基本信息',
      'Serial Number': '序列号',
      Address: '地址',
      'Postal Code': '邮编',
      'Rated Capacity': '额定容量',
      'Rated Power': '额定功率',
      Latitude: '纬度',
      Longitude: '经度',
      'Strategy Management': '策略管理',
      'Charging Schedule': '充电时间表',
      'Charge Start Time': '充电开始时间',
      'Charge End Time': '充电结束时间',
      'Discharge Start Time': '放电开始时间',
      'Discharge End Time': '放电结束时间',
      'Total Rated Power': '总额定功率',
      'Total Rated Capacity': '总额定容量',
      // Energy Indicator
      "Today's Charge": "今日充电量",
      "Today's Discharge": "今日放电量",
      "Today's Generation": "今日发电量",
      'Total Charge': '总充电',
      'Total Discharge': '总放电',
      'Total Generation': '总发电量',
      'Total Revenue': '总收益',
      'Total Efficiency': '综合效率',
      'Discharge Achievement Rate': '放电达成率',
      // Revenue Indicator
      "Today's Cost": "今日成本",
      "Today's Income": "今日收入",
      "Total Cost": "累计成本",
      "Total Income": "累计收入",
      "Today's Revenue": "今日盈利",

      'Revenue Ranking': '收益排名',
      'Efficiency Ranking': '效率排名',
      'Charge Ranking': '充电排名',
      'Discharge Ranking': '放电排名',
      'Number of Microgrids': '微电网数',
      'Number of Power Stations': '电站数',
      'Microgrid List': '微电网列表',
      'Previous Page': '上一页',
      'Next Page': '下一页',
      '7 Days': '7日',
      'Charge UNIT': '充 {{UNIT}}',
      'Discharge UNIT': '放 {{UNIT}}',
      'Generation UNIT': '发电 {{UNIT}}',
      'Energy Indicator': '能量',
      'Revenue Indicator': '收益',
      'Carbon Indicator': '碳',

      // Energy Storage Power Station
      'Battery Operating State': '电池运行状态',
      'Battery State': '电池状态',
      'Battery Unknown': '未知',
      'Battery Stopped': '停止',
      'Battery Reserved': 'Reserved', // 0
      'Battery Fault': '故障', // 1
      'Battery Warning': '预警', // 2
      'Battery Standby': '待机', // 3
      'Battery Prohibit DisCharging': '禁放', // 4
      'Battery Prohibit Charging': '禁充', // 5
      'Battery Normal': '正常', // 6
      'Battery Charging': '充电', // 7
      'Battery Discharging': '放电', // 8
      'Battery Idle': '空闲', // 9
      'Phase of Lifecycle': '阶段',
      'Use Phase': '投用',
      'Commissioning Phase': '调试',
      'Installation Phase': '在建',
      'Device Status': '设备状态',
      'Basic Information': '基本信息',
      'Gateway': '网关',
      'Grid Meter': '电网电表',
      'Load Meter': '负载电表',
      'Total Active Power': '总有功功率',
      'Active Power A': 'A相有功功率',
      'Active Power B': 'B相有功功率',
      'Active Power C': 'C相有功功率',
      'Total Reactive Power': '总无功功率',
      'Reactive Power A': 'A相无功功率',
      'Reactive Power B': 'B相无功功率',
      'Reactive Power C': 'C相无功功率',
      'Total Apparent Power': '总视在功率',
      'Apparent Power A': 'A相视在功率',
      'Apparent Power B': 'B相视在功率',
      'Apparent Power C': 'C相视在功率',
      'Total Power Factor': '总功率因数',
      'Active Energy Import': '正向有功电能',
      'Active Energy Export': '反向有功电能',
      'Active Energy Net': '净有功电能',

      // Photovoltaic Power Station
      'Invertor': '逆变器',
      'Invertor Run State': '逆变器运行状态',
      'Invertor Unknown': '未知',
      'Invertor Initializing': '初始上电',
      'Invertor Standby': '待机',
      'Invertor Shutdown': '关机',
      'Invertor Fault': '故障',
      'Invertor Running': '运行',

      //Advanced Reporting & Knowledge Base
      'Created Datetime': '生成时间',
      'File Format': '文件格式',
      'File Size': '文件大小',
      Uploader: '上传者',
      'Upload Datetime': '上传时间',
      //Error
      "The page you're looking for is not found": '您要找的页面不存在',
      "Make sure the address is correct and that the page hasn't moved": '请确认地址正确且页面没有被移动',
      'If you think this is a mistake,': '如果您认为这是一个错误,',
      'contact us': '联系我们',
      'Take me home': '带我回首页',
      'Whoops, something went wrong!': '哦, 出错了！!',
      'Try refreshing the page, or going back and attempting the action again':
        '请尝试刷新页面, 或回退并再次尝试执行这个操作',
      'If this problem persists,': '如果问题依然存在,',
      'Captcha Error': '验证码错误',
      'Confirm Password Error': '两次密码不一致',
      'It looks like you clicked on an invalid password reset link. Please tryagain.':
        '看起来你点击了一个无效的重置密码链接。请再试一次。',
      'It looks like you clicked on an invalid registration account link. Please tryagain.':
        '看起来你点击了一个无效的注册账号链接。请再试一次。',
      //Tenant Bill
      'Lease Contract Number': '租赁合同号码',
      Download: '下载',
      Print: '打印',
      'Payment Notice': '付款通知书',
      'Bill To': '致',
      'Bill Number': '账单号码',
      'Bill Date': '账单日期',
      'Payment Due Date': '付款到期日',
      'Amount Payable': '应付款金额',
      'Billing Period Start': '结算期起始日期',
      'Billing Period End': '结算期结束日期',
      Quantity: '数量',
      Price: '价格',
      Unit: '单位',
      Amount: '金额',
      Subtotal: '小计',
      'VAT Output Tax': '增值税销项税金',
      'Total Amount Payable': '应付金额合计',
      'Please make sure to pay on or before the payment due date above': '请确保在上述付款到期日或之前付款',
      'Send money to the following account': '汇款至以下账户',
      'Account Name': '账户名称',
      'Bank Name': '开户银行',
      'Bank Address': '银行地址',
      'RMB Account': '人民币账户',
      // button
      'Toggle Navigation': '切换导航',
      // working calendar
      'Working Days': '工作日',
      'Non Working Days': '非工作日',
      'Select Row': '请选择要操作的数据行',
      // Offline Meter Input
      'Daily Value': '日数据',
      Date: '日期',
      'Successfully Saved': '保存成功',
      'Previous Data Is Empty': '之前的数据为空',
      // API
      'API.ACCOUNT_UNLOCK_FAILED': 'Account unlock failed',
      'API.ADMINISTRATOR_SESSION_NOT_FOUND': '管理员会话不存在',
      'API.ADMINISTRATOR_SESSION_TIMEOUT': '管理员会话已超时',
      'API.ADVANCED_REPORT_NOT_FOUND': '高级报表不存在',
      'API.API_KEY_HAS_EXPIRED': 'Key has expired',
      'API.API_KEY_NAME_IS_ALREADY_IN_USE': 'Key name is already in use',
      'API.API_KEY_NOT_FOUND': 'Key not found',
      'API.BAD_REQUEST': '错误请求',
      'API.BALANCING_PRICE_POINT_NOT_FOUND': 'Balancing price point not found',
      'API.BATTERY_STATE_POINT_NOT_FOUND': 'Battery state point not found',
      'API.BUY_METER_NOT_FOUND': 'Buy meter not found',
      'API.CANNOT_SET_EXISTING_SUBMETER_AS_MASTER_METER': '不能将已经设为分表的计量表设置为总表',
      'API.CHARGE_METER_NOT_FOUND': 'Charge meter not found',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_EXISTS': 'Combined equipment command relation exists',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Combined equipment command relation not found',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_EXISTS': '组合设备与设备关系已存在',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_NOT_FOUND': '组合设备与设备关系不存在',
      'API.COMBINED_EQUIPMENT_METER_RELATION_EXISTS': '组合设备与计量表关系已存在',
      'API.COMBINED_EQUIPMENT_METER_RELATION_NOT_FOUND': '组合设备与计量表关系不存在',
      'API.COMBINED_EQUIPMENT_NAME_IS_ALREADY_IN_USE': '组合设备名称已使用',
      'API.COMBINED_EQUIPMENT_NOT_FOUND': '组合设备不存在',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': '组合设备与离线表关系已存在',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': '组合设备与离线表关系不存在',
      'API.COMBINED_EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': '组合设备相关参数名称已使用',
      'API.COMBINED_EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': '组合设备相关参数不存在或不匹配',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': '组合设备与虚拟表关系已存在',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': '组合设备与虚拟表关系不存在',
      'API.COMMAND_NAME_IS_ALREADY_IN_USE': 'Command name is already in use',
      'API.COMMAND_NOT_FOUND': 'Command not  found',
      'API.CONTACT_NAME_IS_ALREADY_IN_USE': '联系人姓名已使用',
      'API.CONTACT_NOT_FOUND': '联系人不存在',
      'API.COOLING_METER_NOT_FOUND': 'Cooling meter not found',
      'API.COST_CENTER_EXTERNAL_ID_EXISTS': '成本中心外部ID已存在',
      'API.COST_CENTER_NAME_EXISTS': '成本中心名称已存在',
      'API.COST_CENTER_NOT_FOUND': '成本中心不存在',
      'API.COST_FILE_NOT_FOUND': '成本文件不存在',
      'API.DATA_REPAIR_FILE_NOT_FOUND': 'Data repair file not found',
      'API.DATA_SOURCE_NAME_IS_ALREADY_IN_USE': '数据源名称已使用',
      'API.DATA_SOURCE_NOT_FOUND': '数据源不存在',
      'API.DATABASE_ERROR': '数据库错误',
      'API.DATE_IS_ALREADY_IN_WORKING_CALENDAR': '日期已在工作日历中',
      'API.DATE_LOCAL_NOT_FOUND': '未找到本地日期',
      'API.DISCHARGE_METER_NOT_FOUND': 'Discharge meter not found',
      'API.DISTRIBUTION_CIRCUIT_NAME_IS_ALREADY_IN_USE': '配电回路名称已使用',
      'API.DISTRIBUTION_CIRCUIT_NOT_FOUND': '配电回路不存在',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_EXISTS': '配电回路与点位关系已存在',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_NOT_FOUND': '配电回路与点位关系不存在',
      'API.DISTRIBUTION_SYSTEM_NAME_IS_ALREADY_IN_USE': '配电系统名称已使用',
      'API.DISTRIBUTION_SYSTEM_NOT_FOUND': '配电系统不存在',
      'API.ELECTRICITY_METER_NOT_FOUND': 'Electricity meter not found',
      'API.EMAIL_IS_ALREADY_IN_USE': '电子邮箱已使用',
      'API.EMAIL_MESSAGE_NOT_FOUND': '电子邮件消息不存在',
      'API.EMAIL_NOT_FOUND': '电子邮箱不存在',
      'API.EMAIL_SERVER_HOST_IS_ALREADY_IN_USE': '邮件服务器主机已使用',
      'API.EMAIL_SERVER_NOT_FOUND': '邮件服务器主机不存在',
      'API.EMPTY_VARIABLES_ARRAY': '空变量数组',
      'API.ENERGY_CATEGORY_NAME_IS_ALREADY_IN_USE': '能源分类名称已使用',
      'API.ENERGY_CATEGORY_NOT_FOUND': '能源分类不存在',
      'API.ENERGY_CATEGORY_USED_IN_ENERGY_ITEMS': '能源分类在能源分项中已使用',
      'API.ENERGY_CATEGORY_USED_IN_METER': '能源分类在计量表中已使用',
      'API.ENERGY_CATEGORY_USED_IN_OFFLINE_METER': '能源分类在离线表中已使用',
      'API.ENERGY_CATEGORY_USED_IN_TARIFFS': '能源分类在费率中已使用',
      'API.ENERGY_CATEGORY_USED_IN_VIRTUAL_METER': '能源分类在虚拟表中已使用',
      'API.ENERGY_FLOW_DIAGRAM_LINK_IS_ALREADY_IN_USE': '能流图链接已使用',
      'API.ENERGY_FLOW_DIAGRAM_LINK_NOT_FOUND_OR_NOT_MATCH': '能流图链接不存在或不匹配',
      'API.ENERGY_FLOW_DIAGRAM_NAME_IS_ALREADY_IN_USE': '能流图名称已使用',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NAME_IS_ALREADY_IN_USE': '能流图节点已使用',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NOT_FOUND_OR_NOT_MATCH': '能流图节点不存在或不匹配',
      'API.ENERGY_FLOW_DIAGRAM_NOT_FOUND': '能流图不存在',
      'API.ENERGY_ITEM_DOES_NOT_BELONG_TO_ENERGY_CATEGORY': '能源分项不属于能源分类',
      'API.ENERGY_ITEM_IS_NOT_BELONG_TO_ENERGY_CATEGORY': '能源分项不属于能源分类',
      'API.ENERGY_ITEM_NAME_IS_ALREADY_IN_USE': '能源分项已使用',
      'API.ENERGY_ITEM_NOT_FOUND': '能源分项不存在',
      'API.ENERGY_ITEM_USED_IN_METER': '能源分项在计量表中已使用',
      'API.ENERGY_ITEM_USED_IN_OFFLINE_METER': '能源分项在离线表中已使用',
      'API.ENERGY_ITEM_USED_IN_VIRTUAL_METER': '能源分项在虚拟表中已使用',
      'API.ENERGY_STORAGE_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Energy storage power station name is already in use',
      'API.ENERGY_STORAGE_POWER_STATION_NOT_FOUND': 'Energy storage power station not found',
      'API.EQUIPMENT_COMMAND_RELATION_EXISTS': 'Equipment command relation exists',
      'API.EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Equipment command relation not found',
      'API.EQUIPMENT_METER_RELATION_EXISTS': '设备与计量表关系已存在',
      'API.EQUIPMENT_METER_RELATION_NOT_FOUND': '设备与计量表关系不存在',
      'API.EQUIPMENT_NAME_IS_ALREADY_IN_USE': '设备名称已使用',
      'API.EQUIPMENT_NOT_FOUND': '设备不存在',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': '设备与离线表关系已存在',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': '设备与离线表关系不存在',
      'API.EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': '设备相关参数名称已使用',
      'API.EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': '设备相关参数不存在或不匹配',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': '设备与虚拟表关系已存在',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': '设备与虚拟表关系不存在',
      'API.ERROR': '错误',
      'API.EXCEPTION': '异常',
      'API.FAILED_TO_READ_REQUEST_STREAM': 'Failed to read request stream',
      'API.FAILED_TO_RESTORE_COST_FILE': '恢复成本文件失败',
      'API.FAILED_TO_RESTORE_DATA_REPAIR_FILE': 'Failed to restore data repair file',
      'API.FAILED_TO_RESTORE_KNOWLEDGE_FILE': '恢复知识文件失败',
      'API.FAILED_TO_RESTORE_OFFLINE_METER_FILE': '恢复离线表文件失败',
      'API.FAILED_TO_SAVE_OFFLINE_METER_FILE': '保存离线表文件失败',
      'API.FAILED_TO_UPLOAD_ATTACHMENT_FILE': 'Failed to upload attachment file',
      'API.FAILED_TO_UPLOAD_COST_FILE': '上传成本文件失败',
      'API.FAILED_TO_UPLOAD_DATA_REPAIR_FILE': 'Failed to upload data repair file',
      'API.FAILED_TO_UPLOAD_KNOWLEDGE_FILE': '上传知识文件失败',
      'API.FAILED_TO_UPLOAD_OFFLINE_METER_FILE': '上传离线表文件失败',
      'API.GATEWAY_NAME_IS_ALREADY_IN_USE': '网关名称已使用',
      'API.GATEWAY_NOT_FOUND': '网关不存在',
      'API.HEAT_METER_NOT_FOUND': 'Heat meter not found',
      'API.INVALID_': '无效的 ',
      'API.INVALID_ACKNOWLEDGE_CODE': 'Invalid acknowledge code',
      'API.INVALID_ADDRESS': '无效地址',
      'API.INVALID_ADDRESS_VALUE': '无效地址值',
      'API.INVALID_ADVANCED_REPORT_ID': '无效的高级报表ID',
      'API.INVALID_API_KEY_ID': 'Invalid api key id',
      'API.INVALID_API_KEY_NAME': 'Invalid api key name',
      'API.INVALID_AREA_VALUE': '无效面积值',
      'API.INVALID_BALANCING_PRICE_POINT_ID': 'Invalid balancing price point ID',
      'API.INVALID_BASE_PERIOD_END_DATETIME': '无效的基准期结束时间',
      'API.INVALID_BASE_PERIOD_START_DATETIME': '无效的基准期开始时间',
      'API.INVALID_BATTERY_STATE_POINT_ID': 'Invalid battery state point ID',
      'API.INVALID_BAUD_RATE': '无效波特率',
      'API.INVALID_BUILDINGS_VALUE': '无效的建筑值',
      'API.INVALID_BUY_METER_ID': 'Invalid buy meter id',
      'API.INVALID_CAPACITY': 'Invalid capacity',
      'API.INVALID_CATEGORY': '无效类型',
      'API.INVALID_CHANNEL': '无效通道',
      'API.INVALID_CHARGE_METER_ID': 'Invalid charge meter id',
      'API.INVALID_COMBINED_EQUIPMENT_ID': '无效的组合设备ID',
      'API.INVALID_COMBINED_EQUIPMENT_NAME': '无效的组合设备名称',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_ID': '无效的组合设备相关参数ID',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_NAME': '无效的组合设备相关参数名称',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_TYPE': '无效的组合设备相关参数类型',
      'API.INVALID_COMBINED_EQUIPMENT_UUID': '无效的组合设备UUIDs',
      'API.INVALID_COMMAND_ID': 'Invalid command id',
      'API.INVALID_COMMAND_NAME': 'Invalid command name',
      'API.INVALID_CONNECTION': '无效连接',
      'API.INVALID_CONSTANT_VALUE': '无效常量值',
      'API.INVALID_CONTACT_ID': '无效的联络人ID',
      'API.INVALID_CONTACT_NAME': '无效的联络人姓名',
      'API.INVALID_COOLING_METER_ID': 'Invalid cooling meter id',
      'API.INVALID_COST_CENTER_ID': '无效的成本中心ID',
      'API.INVALID_COST_FILE_ID': '无效的成本文件ID',
      'API.INVALID_CREATED_DATETIME': 'Invalid created datetime',
      'API.INVALID_DATA_REPAIR_FILE_ID': 'Invalid data repair file id',
      'API.INVALID_DATA_SOURCE_ID': '无效的数据源ID',
      'API.INVALID_DATA_SOURCE_NAME': '无效的数据源名称',
      'API.INVALID_DATA_SOURCE_PROTOCOL': '无效的数据源协议',
      'API.INVALID_DATE_LOCAL': '无效的本地日期',
      'API.INVALID_DENOMINATOR_METER_UUID': '无效的标准计量表UUID',
      'API.INVALID_DISCHARGE_METER_ID': 'Invalid discharge meter id',
      'API.INVALID_DISPLAY_NAME': '无效的显示名称',
      'API.INVALID_DISTRIBUTION_CIRCUIT_ID': '无效的配电回路ID',
      'API.INVALID_DISTRIBUTION_CIRCUIT_NAME': '无效的配电回路名称',
      'API.INVALID_DISTRIBUTION_ROOM': '无效的配电室',
      'API.INVALID_DISTRIBUTION_SYSTEM_ID': '无效的配电系统ID',
      'API.INVALID_DISTRIBUTION_SYSTEM_NAME': '无效的配电系统名称',
      'API.INVALID_ELECTRICITY_METER_ID': 'Invalid Electricity meter id',
      'API.INVALID_EMAIL': '无效的邮箱',
      'API.INVALID_EMAIL_MESSAGE_ID': '无效的电子邮件ID',
      'API.INVALID_EMAIL_SERVER_HOST': '无效的电子邮箱服务器主机',
      'API.INVALID_EMAIL_SERVER_ID': '无效的电子邮箱服务器ID',
      'API.INVALID_END_DATE_FORMAT': '无效的结束日期格式',
      'API.INVALID_END_DATETIME': '无效的结束日期',
      'API.INVALID_ENERGY_CATEGORY_ID': '无效的能源分类ID',
      'API.INVALID_ENERGY_CATEGORY_NAME': '无效的能源分类名称',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_ID': '无效的能流图ID',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_LINK_ID': '无效的能流图链接ID',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NAME': '无效的能流图名称',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_ID': '无效的能流图节点ID',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_NAME': '无效的能流图节点名称',
      'API.INVALID_ENERGY_ITEM_ID': '无效的能源分项ID',
      'API.INVALID_ENERGY_ITEM_NAME': '无效的能源分项名称',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_ID': 'Invalid energy storage power station ID',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_NAME': 'Invalid energy storage power station name',
      'API.INVALID_EQUATION_IN_EXPRESSION': '表达式中无效的表达式',
      'API.INVALID_EQUIPMENT_ID': '无效的设备ID',
      'API.INVALID_EQUIPMENT_NAME': '无效的设备名称',
      'API.INVALID_EQUIPMENT_PARAMETER_ID': '无效的设备相关参数ID',
      'API.INVALID_EQUIPMENT_PARAMETER_NAME': '无效的设备相关参数名称',
      'API.INVALID_EQUIPMENT_PARAMETER_TYPE': '无效的设备相关参数类型',
      'API.INVALID_EQUIPMENT_UUID': '无效的设备UUID',
      'API.INVALID_EXPIRES_DATETIME': 'Invalid expires datetime',
      'API.INVALID_EXPRESSION': '无效表达式',
      'API.INVALID_EXPRESSION_OBJECT': '无效的表达式对象',
      'API.INVALID_FDD_CODE': '无效的故障错误码',
      'API.INVALID_FLOORS_VALUE': '无效的楼层值',
      'API.INVALID_FROM_ADDR': '无效地址',
      'API.INVALID_GATEWAY_ID': '无效的网关ID',
      'API.INVALID_GATEWAY_NAME': '无效的网关名称',
      'API.INVALID_HEAT_METER_ID': 'Invalid heat meter id',
      'API.INVALID_HIGH_LIMIT_VALUE': 'Invalid high limit value',
      'API.INVALID_HOURLY_HIGH_LIMIT_VALUE': '无效的小时上限值',
      'API.INVALID_HOURLY_LOW_LIMIT_VALUE': '无效的小时下限值',
      'API.INVALID_Id': 'Invalid id',
      'API.INVALID_IS_ADMIN_VALUE': '无效的管理值',
      'API.INVALID_IS_COST_DATA_DISPLAYED': '无效的是否显示成本数据',
      'API.INVALID_IS_COUNTED_VALUE': '无效的计数值',
      'API.INVALID_IS_ENABLED': '无效的是否启用',
      'API.INVALID_IS_HIDDEN': '无效的是否隐藏',
      'API.INVALID_IS_IN_LEASE_VALUE': '无效的是否在租值',
      'API.INVALID_IS_INPUT_COUNTED_VALUE': '无效的输入是否汇总值',
      'API.INVALID_IS_KEY_TENANT_VALUE': '无效的是否主力租户值',
      'API.INVALID_IS_OUTPUT_COUNTED_VALUE': '无效的输出是否汇总值',
      'API.INVALID_IS_OUTPUT_VALUE': '无效的输出是否汇总值',
      'API.INVALID_IS_READ_ONLY_VALUE': 'Invalid is read only value',
      'API.INVALID_IS_TREND_VALUE': '无效的是否保存趋势值',
      'API.INVALID_IS_VIRTUAL_VALUE': '无效的是否虚拟点值',
      'API.INVALID_KGCE': '无效的千克标准煤系数',
      'API.INVALID_KGCO2E': '无效的千克二氧化碳排放系数',
      'API.INVALID_KNOWLEDGE_FILE_ID': '无效的知识文件ID',
      'API.INVALID_LATITUDE_VALUE': '无效的纬度值',
      'API.INVALID_LEASE_NUMBER_VALUE': '无效的租约编号',
      'API.INVALID_LONGITUDE_VALUE': '无效的经度值',
      'API.INVALID_LOW_LIMIT_VALUE': '无效的下限值',
      'API.INVALID_MASTER_METER_ID': '无效的总计量表ID',
      'API.INVALID_MENU_ID': '无效的菜单ID',
      'API.INVALID_MESSAGE_DATA': 'Invalid message data',
      'API.INVALID_MESSAGE_TEMPLATE': '无效的消息模板',
      'API.INVALID_MESSAGE_TEMPLATE_ID': 'Invalid message template id',
      'API.INVALID_MESSAGE_VALUE': 'Invalid message value',
      'API.INVALID_METER_ID': '无效的计量表ID',
      'API.INVALID_METER_NAME': '无效的计量表名称',
      'API.INVALID_METER_UUID': '无效的计量表UUID',
      'API.INVALID_MICROGRID_BATTERY_ID': 'Invalid microgrid battery id',
      'API.INVALID_MICROGRID_BATTERY_NAME': 'Invalid microgrid battery name',
      'API.INVALID_MICROGRID_EVCHARGER_ID': 'Invalid microgrid evcharger id',
      'API.INVALID_MICROGRID_EVCHARGER_NAME': 'Invalid microgrid evcharger name',
      'API.INVALID_MICROGRID_GENERATOR_ID': 'Invalid microgrid generator id',
      'API.INVALID_MICROGRID_GENERATOR_NAME': 'Invalid microgrid generator name',
      'API.INVALID_MICROGRID_GRID_ID': 'Invalid microgrid grid id',
      'API.INVALID_MICROGRID_GRID_NAME': 'Invalid microgrid grid name',
      'API.INVALID_MICROGRID_HEATPUMP_ID': 'Invalid microgrid heatpump id',
      'API.INVALID_MICROGRID_HEATPUMP_NAME': 'Invalid microgrid heatpump name',
      'API.INVALID_MICROGRID_ID': 'Invalid microgrid id',
      'API.INVALID_MICROGRID_LOAD_ID': 'Invalid microgrid load id',
      'API.INVALID_MICROGRID_LOAD_NAME': 'Invalid microgrid load name',
      'API.INVALID_MICROGRID_NAME': 'Invalid microgrid name',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_ID': 'Invalid microgrid photovoltaic id',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_NAME': 'Invalid microgrid photovoltaic name',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_ID': 'Invalid microgrid power conversion system ID',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_NAME': 'Invalid microgrid microgrid power conversion system name',
      'API.INVALID_MICROGRID_UUID': 'Invalid microgrid UUID',
      'API.INVALID_NAME_VALUE': '无效的名称值',
      'API.INVALID_NEW_PASSWORD': '无效的新密码',
      'API.INVALID_NON_WORKING_DAY_ID': '无效的非工作日ID',
      'API.INVALID_NOTIFICATION_ID': '无效的通知ID',
      'API.INVALID_NUMERATOR_METER_UUID': '无效的分子表UUID',
      'API.INVALID_OBJECT_TYPE': '无效的对象类型',
      'API.INVALID_OFFLINE_METER_DATA': '无效离线表数据',
      'API.INVALID_OFFLINE_METER_FILE_ID': '无效的离线表文件ID',
      'API.INVALID_OFFLINE_METER_ID': '无效的离线表ID',
      'API.INVALID_OFFLINE_METER_NAME': '无效的离线表名称',
      'API.INVALID_OFFLINE_METER_UUID': '无效的离线表UUID',
      'API.INVALID_OFFSET_CONSTANT_VALUE': '无效的偏移常量',
      'API.INVALID_OLD_PASSWORD': '无效的旧密码',
      'API.INVALID_OPENID': 'Invalid openid',
      'API.INVALID_PARENT_SPACE_ID': '无效的父空间ID',
      'API.INVALID_PASSWORD': '无效的密码',
      'API.INVALID_PAYLOAD': 'Invalid payload',
      'API.INVALID_PEAK_CURRENT': '无效峰值电流',
      'API.INVALID_PEAK_LOAD': '无效峰值负载',
      'API.INVALID_PERIOD_TYPE': '无效周期类型',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_ID': 'Invalid photovoltaic power station ID',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_NAME': 'Invalid photovoltaic power station name',
      'API.INVALID_POINT_ID': '无效的数据点ID',
      'API.INVALID_POINT_NAME': '无效的数据点名称',
      'API.INVALID_PORT': '无效端口',
      'API.INVALID_POSTAL_CODE_VALUE': 'Invalid postal code value',
      'API.INVALID_POWER_POINT_ID': 'Invalid power point id',
      'API.INVALID_PRIORITY': '无效的优先级',
      'API.INVALID_PRIVILEGE': '无效的权限',
      'API.INVALID_PRIVILEGE_DATA': '无效的权限数据',
      'API.INVALID_PRIVILEGE_ID': '无效的权限ID',
      'API.INVALID_PRIVILEGE_NAME': '无效的权限名称',
      'API.INVALID_RATIO_VALUE': '无效的变比值',
      'API.INVALID_RECIPIENT_EMAIL': 'Invalid recipient email',
      'API.INVALID_RECIPIENT_MOBILE': 'Invalid recipient mobile',
      'API.INVALID_RECIPIENT_NAME': 'Invalid recipient name',
      'API.INVALID_RECIPIENT_OPENID': 'Invalid recipient openid',
      'API.INVALID_REPLY': '无效回复',
      'API.INVALID_REPORTING_PERIOD_END_DATETIME': '无效的报告期结束日期时间',
      'API.INVALID_REPORTING_PERIOD_START_DATETIME': '无效的报告期开始日期时间',
      'API.INVALID_REQUIRES_AUTHENTICATION': '无效的需要身份验证',
      'API.INVALID_ROOMS_VALUE': '无效的房间值',
      'API.INVALID_RULE_ID': '无效的规则ID',
      'API.INVALID_RULE_NAME': '无效的规则名称',
      'API.INVALID_RUN_STATE_POINT_ID': 'Invalid run state point ID',
      'API.INVALID_SCHEDULED_DATETIME': 'Invalid scheduled datetime',
      'API.INVALID_SELL_METER_ID': 'Invalid sell meter id',
      'API.INVALID_SENSOR_ID': '无效的传感器ID',
      'API.INVALID_SENSOR_NAME': '无效的传感器名称',
      'API.INVALID_SENSOR_UUID': 'Invalid sensor uuid',
      'API.INVALID_SERIAL_NUMBER': 'Invalid serial number',
      'API.INVALID_SERIAL_PORT': '无效的串口号',
      'API.INVALID_SESSION_PLEASE_RE_LOGIN': '会话无效，请重新登陆',
      'API.INVALID_SET_VALUE': 'Invalid set value',
      'API.INVALID_SHOPFLOOR_ID': '无效的车间ID',
      'API.INVALID_SHOPFLOOR_NAME': '无效的车间名称',
      'API.INVALID_SHOPFLOOR_UUID': '无效的车间UUID',
      'API.INVALID_SOURCE_NODE_ID': '无效的源节点ID',
      'API.INVALID_SPACE_ID': '无效的空间ID',
      'API.INVALID_SPACE_NAME': '无效的空间名称',
      'API.INVALID_SPACE_UUID': '无效的空间UUID',
      'API.INVALID_START_DATE_FORMAT': '无效的开始时间格式',
      'API.INVALID_START_DATETIME': '无效的开始时间',
      'API.INVALID_STATUS': '无效状态',
      'API.INVALID_STORE_ID': '无效门店ID',
      'API.INVALID_STORE_NAME': '无效门店名称',
      'API.INVALID_STORE_TYPE_DESCRIPTION': '无效的门店类型描述',
      'API.INVALID_STORE_TYPE_ID': '无效的门店类型ID',
      'API.INVALID_STORE_TYPE_NAME': '无效的门店类型名称',
      'API.INVALID_STORE_TYPE_SIMPLIFIED_CODE': '无效的门店类型简化代码',
      'API.INVALID_STORE_UUID': '无效门店UUID',
      'API.INVALID_SUBJECT_VALUE': 'Invalid subject value',
      'API.INVALID_SVG': '无效SVG',
      'API.INVALID_SWITCHGEAR': '无效的开关设备',
      'API.INVALID_TARGET_NODE_ID': '无效的目标节点ID',
      'API.INVALID_TARIFF_BLOCK_PRICING': '无效的阶梯费率定价',
      'API.INVALID_TARIFF_ID': '无效的费率ID',
      'API.INVALID_TARIFF_TIME_OF_USE_PRICING': '无效的分时费率定价',
      'API.INVALID_TARIFF_TYPE': '无效的费率类型',
      'API.INVALID_TENANT_ID': '无效的租户ID',
      'API.INVALID_TENANT_NAME': '无效的租户名称',
      'API.INVALID_TENANT_TYPE_DESCRIPTION': '无效的租户类型描述',
      'API.INVALID_TENANT_TYPE_ID': '无效的租户类型ID',
      'API.INVALID_TENANT_TYPE_NAME': '无效的租户类型名称',
      'API.INVALID_TENANT_TYPE_SIMPLIFIED_CODE': '无效的租户类型简化代码',
      'API.INVALID_TENANT_UUID': '无效的租户UUID',
      'API.INVALID_TEXT_MESSAGE_ID': '无效的短信ID',
      'API.INVALID_TIMEZONE_ID': '无效的时区ID',
      'API.INVALID_TOKEN': '无效令牌',
      'API.INVALID_TOPIC': 'Invalid topic',
      'API.INVALID_UNIT_OF_MEASURE': '无效的计量单位',
      'API.INVALID_UNIT_OF_PRICE': '无效的价格单位',
      'API.INVALID_UNITS': '无效单位',
      'API.INVALID_USER_ID': '无效的用户ID',
      'API.INVALID_USER_NAME': '无效的用户名称',
      'API.INVALID_USER_NAME_OR_EMAIL': '无效的用户名称或邮箱',
      'API.INVALID_USER_PHONE': '无效的用户手机号',
      'API.INVALID_USER_PLEASE_RE_LOGIN': '无效的用户请重新登录',
      'API.INVALID_USER_UUID': '无效的用户UUID',
      'API.INVALID_USERNAME': 'Invalid userName',
      'API.INVALID_VARIABLE_METER_ID': '无效的变量计量表ID',
      'API.INVALID_VARIABLE_METER_TYPE': '无效的变量计量表类型',
      'API.INVALID_VARIABLE_NAME': '无效的变量名称',
      'API.INVALID_VERIFICATION_CODE': '无效的验证码',
      'API.INVALID_VIRTUAL_METER_ID': '无效的虚拟表ID',
      'API.INVALID_VIRTUAL_METER_NAME': '无效的虚拟表名称',
      'API.INVALID_VIRTUAL_METER_UUID': '无效的虚拟表UUID',
      'API.INVALID_WEB_MESSAGE_ID': '无效的web消息ID',
      'API.INVALID_WECHAT_MESSAGE_ID': '无效的微信消息ID',
      'API.INVALID_WIND_FARM_ID': 'Invalid wind farm ID',
      'API.INVALID_WIND_FARM_NAME': 'Invalid wind farm name',
      'API.INVALID_WORKING_CALENDAR_ID': '无效的工作日历ID',
      'API.INVALID_WORKING_CALENDAR_NAME': '无效的工作日历名称',
      'API.KNOWLEDGE_FILE_CANNOT_BE_REMOVED_FROM_DISK': '无法从磁盘中删除知识文件',
      'API.KNOWLEDGE_FILE_NOT_FOUND': '知识库文件不存在',
      'API.MASTER_METER_DOES_NOT_BELONG_TO_SAME_ENERGY_CATEGORY': '总表不属于同一能源分类',
      'API.MASTER_METER_NOT_FOUND': '总表不存在',
      'API.MENU_NOT_FOUND': '菜单不存在',
      'API.METER_CANNOT_HAVE_MORE_THAN_ONE_ENERGY_VALUE_POINTS': 'Meter cannot have more than one energy value points',
      'API.METER_COMMAND_RELATION_EXISTS': 'Meter command relation exists',
      'API.METER_COMMAND_RELATION_NOT_FOUND': 'Meter command relation not found',
      'API.METER_NAME_IS_ALREADY_IN_USE': '计量表名称已使用',
      'API.METER_NOT_FOUND': '计量表不存在',
      'API.METER_OF_VARIABLE_NOT_FOUND': '计量表变量不存在',
      'API.METER_POINT_RELATION_EXISTS': '计量表与数据点关系已存在',
      'API.METER_POINT_RELATION_NOT_FOUND': '计量表与数据点关系不存在',
      'API.MICROGRID_BATTERY_NAME_IS_ALREADY_IN_USE': 'Microgrid battery name is already in use',
      'API.MICROGRID_BATTERY_NOT_FOUND': 'Microgrid battery not found',
      'API.MICROGRID_EVCHARGER_NAME_IS_ALREADY_IN_USE': 'Microgrid evcharger name is already in use',
      'API.MICROGRID_EVCHARGER_NOT_FOUND': 'Microgrid evcharger not fund',
      'API.MICROGRID_GENERATOR_NAME_IS_ALREADY_IN_USE': 'Microgrid generator name is already in use',
      'API.MICROGRID_GENERATOR_NOT_FOUND': 'Microgrid generator not found',
      'API.MICROGRID_GRID_NAME_IS_ALREADY_IN_USE': 'Microgrid grid name is already in use',
      'API.MICROGRID_GRID_NOT_FOUND': 'Microgrid grid not found',
      'API.MICROGRID_HEATPUMP_NAME_IS_ALREADY_IN_USE': 'Microgrid heatpump name is already in use',
      'API.MICROGRID_HEATPUMP_NOT_FOUND': 'Microgrid heatpump not found',
      'API.MICROGRID_LOAD_NAME_IS_ALREADY_IN_USE': 'Microgrid load name is already',
      'API.MICROGRID_LOAD_NOT_FOUND': 'Microgrid load not found',
      'API.MICROGRID_NAME_IS_ALREADY_IN_USE': 'Microgrid name is already in use',
      'API.MICROGRID_NOT_FOUND': 'Microgrid not found',
      'API.MICROGRID_PHOTOVOLTAIC_NAME_IS_ALREADY_IN_USE': 'Microgrid photovoltaic name is already in use',
      'API.MICROGRID_PHOTOVOLTAIC_NOT_FOUND': 'Microgrid photovoltaic not found',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NAME_IS_ALREADY_IN_USE':
        'Microgrid power conversion system name is already in use',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NOT_FOUND': 'Microgrid power conversion system not found',
      'API.MICROGRID_SENSOR_RELATION_EXISTS': 'Microgrid sensor relation exists',
      'API.MICROGRID_SENSOR_RELATION_NOT_FOUND': 'Microgrid sensor not found',
      'API.MQTT_CONNECTION_ERROR': 'Mqtt connection error',
      'API.MQTT_PUBLISH_ERROR': 'Mqtt publish error',
      'API.NEW_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': '新密码长度不能超过100个字符',
      'API.NEW_USER_SESSION_NOT_FOUND': 'New user session not found',
      'API.NEW_USER_SESSION_TIMEOUT': 'New user session timeout',
      'API.NON_WORKING_DAY_NOT_FOUND': '非工作日不存在',
      'API.NOT_FOUND': '不存在',
      'API.NOTIFICATION_NOT_FOUND': '通知不存在',
      'API.OFFLINE_METER_FILE_NOT_FOUND': '离线表文件不存在',
      'API.OFFLINE_METER_NAME_IS_ALREADY_IN_USE': '离线表名称已使用',
      'API.OFFLINE_METER_NOT_FOUND': '离线表不存在',
      'API.OFFLINE_METER_OF_VARIABLE_NOT_FOUND': '离线表变量不存在',
      'API.OLD_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': '旧密码长度不能超过100个字符',
      'API.PARENT_SPACE_NOT_FOUND': '父空间不存在',
      'API.PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': '密码长度不能超过100个字符',
      'API.PASSWORDS_MATCH': '新密码与旧密码一致',
      'API.PHOTOVOLTAIC_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Photovoltaic power station name is already in use',
      'API.PHOTOVOLTAIC_POWER_STATION_NOT_FOUND': 'Photovoltaic power station not found',
      'API.POINT_NAME_IS_ALREADY_IN_USE': '数据点名称已使用',
      'API.POINT_NOT_FOUND': '数据点不存在',
      'API.POWER_POINT_NOT_FOUND': 'Power point not found',
      'API.PRIVILEGE_NAME_IS_ALREADY_IN_USE': '权限名称已使用',
      'API.PRIVILEGE_NOT_FOUND': '权限不存在',
      'API.RULE_NAME_IS_ALREADY_IN_USE': '规则名称已使用',
      'API.RULE_NOT_FOUND': '规则不存在',
      'API.SELL_METER_NOT_FOUND': 'Sell meter not found',
      'API.SENSOR_NAME_IS_ALREADY_IN_USE': '传感器名称已使用',
      'API.SENSOR_NOT_FOUND': '传感器不存在',
      'API.SENSOR_POINT_RELATION_EXISTS': '传感器与数据点关系已存在',
      'API.SENSOR_POINT_RELATION_NOT_FOUND': '传感器与数据点关系不存在',
      'API.SHOPFLOOR_COMMAND_RELATION_EXISTS': 'Shopfloor command relation exists',
      'API.SHOPFLOOR_COMMAND_RELATION_NOT_FOUND': 'Shopfloor command relation not found',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_EXISTS': '车间与设备关系已存在',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_NOT_FOUND': '车间与设备关系不存在',
      'API.SHOPFLOOR_METER_RELATION_EXISTS': '车间与计量表关系已存在',
      'API.SHOPFLOOR_METER_RELATION_NOT_FOUND': '车间与计量表关系不存在',
      'API.SHOPFLOOR_NAME_IS_ALREADY_IN_USE': '车间名称已使用',
      'API.SHOPFLOOR_NOT_FOUND': '车间不存在',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_EXISTS': '车间与离线表关系已存在',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_NOT_FOUND': '车间与离线表关系不存在',
      'API.SHOPFLOOR_POINT_RELATION_EXISTS': '车间与数据点关系已存在',
      'API.SHOPFLOOR_POINT_RELATION_NOT_FOUND': '车间与数据点关系不存在',
      'API.SHOPFLOOR_SENSOR_RELATION_EXISTS': '车间与传感器关系已存在',
      'API.SHOPFLOOR_SENSOR_RELATION_NOT_FOUND': '车间与传感器关系不存在',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_EXISTS': '车间与虚拟表关系已存在',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_NOT_FOUND': '车间与虚拟表关系不存在',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_EXISTS': '车间工作日历关系已存在',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_NOT_FOUND': '车间与工作日历关系不存在',
      'API.SOC_POINT_NOT_FOUND': 'SOC point not found',
      'API.SOURCE_NODE_NOT_FOUND': '源节点不存在',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_EXISTS': '空间与组合设备关系已存在',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_NOT_FOUND': '空间与组合设备关系不存在',
      'API.SPACE_COMMAND_RELATION_EXISTS': 'Space command relation exists',
      'API.SPACE_COMMAND_RELATION_NOT_FOUND': 'Space command relation not found',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_EXISTS': 'Space Energy Storage Power Station Relation Exists',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_NOT_FOUND': 'Space Energy Storage Power Station Relation Not Found',
      'API.SPACE_EQUIPMENT_RELATION_EXISTS': '空间与设备关系已存在',
      'API.SPACE_EQUIPMENT_RELATION_NOT_FOUND': '空间与设备关系不存在',
      'API.SPACE_METER_RELATION_EXISTS': '空间与计量表关系已存在',
      'API.SPACE_METER_RELATION_NOT_FOUND': '空间与计量表关系不存在',
      'API.SPACE_NAME_IS_ALREADY_IN_USE': '空间名称已使用',
      'API.SPACE_NOT_FOUND': '空间不存在',
      'API.SPACE_NOT_FOUND_IN_PRIVILEGE': '空间在权限中不存在',
      'API.SPACE_OFFLINE_METER_RELATION_EXISTS': '空间与离线表关系已存在',
      'API.SPACE_OFFLINE_METER_RELATION_NOT_FOUND': '空间与离线表关系不存在',
      'API.SPACE_POINT_RELATION_EXISTS': '空间与数据点关系已存在',
      'API.SPACE_POINT_RELATION_NOT_FOUND': '空间与数据点关系不存在',
      'API.SPACE_SENSOR_RELATION_EXISTS': '空间与传感器关系已存在',
      'API.SPACE_SENSOR_RELATION_NOT_FOUND': '空间与传感器关系不存在',
      'API.SPACE_SHOPFLOOR_RELATION_EXISTS': '空间与车间关系已存在',
      'API.SPACE_SHOPFLOOR_RELATION_NOT_FOUND': '空间与车间关系不存在',
      'API.SPACE_STORE_RELATION_EXISTS': '空间与门店关系已存在',
      'API.SPACE_STORE_RELATION_NOT_FOUND': '空间与门店关系不存在',
      'API.SPACE_TENANT_RELATION_EXISTS': '空间与租户关系已存在',
      'API.SPACE_TENANT_RELATION_NOT_FOUND': '空间与租户关系不存在',
      'API.SPACE_VIRTUAL_METER_RELATION_EXISTS': '空间与虚拟表关系已存在',
      'API.SPACE_VIRTUAL_METER_RELATION_NOT_FOUND': '空间与虚拟表关系不存在',
      'API.SPACE_WORKING_CALENDAR_RELATION_EXISTS': '空间与工作日历关系已存在',
      'API.SPACE_WORKING_CALENDAR_RELATION_NOT_FOUND': '空间与工作日历关系不存在',
      'API.START_DATETIME_MUST_BE_EARLIER_THAN_END_DATETIME': '开始日期时间必须早于结束日期时间',
      'API.STORE_COMMAND_RELATION_EXISTS': 'Store command relation exists',
      'API.STORE_COMMAND_RELATION_NOT_FOUND': 'Store command relation not found',
      'API.STORE_METER_RELATION_EXISTS': '门店与计量表关系已存在',
      'API.STORE_METER_RELATION_NOT_FOUND': '门店与计量表关系不存在',
      'API.STORE_NAME_IS_ALREADY_IN_USE': '门店名称已使用',
      'API.STORE_NOT_FOUND': '门店不存在',
      'API.STORE_OFFLINE_METER_RELATION_EXISTS': '门店与离线表关系已存在',
      'API.STORE_OFFLINE_METER_RELATION_NOT_FOUND': '门店与离线表关系不存在',
      'API.STORE_POINT_RELATION_EXISTS': '门店与数据点关系已存在',
      'API.STORE_POINT_RELATION_NOT_FOUND': '门店与数据点关系不存在',
      'API.STORE_SENSOR_RELATION_EXISTS': '门店与传感器关系已存在',
      'API.STORE_SENSOR_RELATION_NOT_FOUND': '门店传感器关系不存在',
      'API.STORE_TYPE_NAME_IS_ALREADY_IN_USE': '门店类型名称已使用',
      'API.STORE_TYPE_NOT_FOUND': '门店类型不存在',
      'API.STORE_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': '门店类型简化代码已使用',
      'API.STORE_TYPE_USED_IN_STORE': '门店类型在门店中已使用',
      'API.STORE_VIRTUAL_METER_RELATION_EXISTS': '门店与虚拟表关系已存在',
      'API.STORE_VIRTUAL_METER_RELATION_NOT_FOUND': '门店与虚拟表关系不存在',
      'API.STORE_WORKING_CALENDAR_RELATION_EXISTS': '门店与工作日历关系已存在',
      'API.STORE_WORKING_CALENDAR_RELATION_NOT_FOUND': '门店与工作日历关系不存在',
      'API.TARGET_NODE_NOT_FOUND': '目标节点不存在',
      'API.TARIFF_IN_USE': '费率正在使用',
      'API.TARIFF_IS_ALREADY_ASSOCIATED_WITH_COST_CENTER': '费率和成本中心已关联',
      'API.TARIFF_IS_NOT_ASSOCIATED_WITH_COST_CENTER': '费率和成本中心未关联',
      'API.TARIFF_NAME_IS_ALREADY_IN_USE': '费率名称已使用',
      'API.TARIFF_NOT_FOUND': '费率不存在',
      'API.TENANT_COMMAND_RELATION_EXISTS': 'Tenant command relation exists',
      'API.TENANT_COMMAND_RELATION_NOT_FOUND': 'Tenant command relation  not found',
      'API.TENANT_METER_RELATION_EXISTS': '租户与计量表关系已存在',
      'API.TENANT_METER_RELATION_NOT_FOUND': '租户与计量表关系不存在',
      'API.TENANT_NAME_IS_ALREADY_IN_USE': '租户名称已使用',
      'API.TENANT_NOT_FOUND': '租户不存在',
      'API.TENANT_OFFLINE_METER_RELATION_EXISTS': '租户与离线表关系已存在',
      'API.TENANT_OFFLINE_METER_RELATION_NOT_FOUND': '租户与离线表关系不存在',
      'API.TENANT_POINT_RELATION_EXISTS': '租户与数据点关系已存在',
      'API.TENANT_POINT_RELATION_NOT_FOUND': '租户与数据点关系不存在',
      'API.TENANT_SENSOR_RELATION_EXISTS': '租户与传感器关系已存在',
      'API.TENANT_SENSOR_RELATION_NOT_FOUND': '租户与传感器关系不存在',
      'API.TENANT_TYPE_NAME_IS_ALREADY_IN_USE': '租户与类型名称已使用',
      'API.TENANT_TYPE_NOT_FOUND': '租户类型不存在',
      'API.TENANT_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': '租户类型简化代码已使用',
      'API.TENANT_TYPE_USED_IN_TENANT': '租户类型在租户中已使用',
      'API.TENANT_VIRTUAL_METER_RELATION_EXISTS': '租户与虚拟表关系已存在',
      'API.TENANT_VIRTUAL_METER_RELATION_NOT_FOUND': '租户与虚拟表关系不存在',
      'API.TENANT_WORKING_CALENDAR_RELATION_EXISTS': '租户与工作日历关系已存在',
      'API.TENANT_WORKING_CALENDAR_RELATION_NOT_FOUND': '租户与工作日历关系不存在',
      'API.TEXT_MESSAGE_NOT_FOUND': '未找到短信',
      'API.THE_REPORTING_PERIOD_MUST_BE_LONGER_THAN_15_MINUTES': '报告期必须大于15分钟',
      'API.THERE_IS_RELATION_WITH_CHILD_METERS': '与子表有关系',
      'API.THERE_IS_RELATION_WITH_CHILDREN_SPACES': '与子空间有关系',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENT_PARAMETERS': '与组合设备相关参数有关系',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENTS': '与组合设备有关系',
      'API.THERE_IS_RELATION_WITH_DATA_SOURCES': '与数据源有关系',
      'API.THERE_IS_RELATION_WITH_ENERGY_FLOW_DIAGRAM_LINKS': '与能流图有关系',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_BATTERIES': '与储能集装箱电池有关系',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_GRIDS': '与储能集装箱电网有关系',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_LOADS': '与储能集装箱负载有关系',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_POWER_CONVERSION_SYSTEMS': '与储能集装箱功率转换系统(PCS)有关系',
      'API.THERE_IS_RELATION_WITH_EQUIPMENT_PARAMETERS': '与设备相关参数有关系',
      'API.THERE_IS_RELATION_WITH_EQUIPMENTS': '与设备有关系',
      'API.THERE_IS_RELATION_WITH_METER': '与计量表有关系',
      'API.THERE_IS_RELATION_WITH_METERS': '与多个记量表有关系',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METER': '与离线表有关系',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METERS': '与多个离线表有关系',
      'API.THERE_IS_RELATION_WITH_OTHER_VIRTUAL_METERS': '与虚拟表有关系',
      'API.THERE_IS_RELATION_WITH_SENSORS': '与传感器有关系',
      'API.THERE_IS_RELATION_WITH_SHOPFLOORS': '与车间有关系',
      'API.THERE_IS_RELATION_WITH_SPACES': '与空间有关系',
      'API.THERE_IS_RELATION_WITH_STORES': '与门店有关系',
      'API.THERE_IS_RELATION_WITH_TENANTS': '与租户有关系',
      'API.THERE_IS_RELATION_WITH_USERS': '与用户有关系',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_METER': '与虚拟表有关系',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_POWER_PLANTS': '与虚拟电厂有关系',
      'API.THIS_DATA_SOURCE_IS_BEING_USED_BY_A_METER': '这个数据源正在被一个计量表使用',
      'API.THIS_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': '这个计量表正在被一个虚拟表使用',
      'API.THIS_OFFLINE_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': '这个离线表正在被一个虚拟表使用',
      'API.THIS_SPACE_CANNOT_BE_CLONED': '空间不能被克隆',
      'API.THIS_SPACE_CANNOT_BE_DELETED': '空间不能被删除',
      'API.TIMEZONE_NOT_FOUND': '时区不存在',
      'API.TOKEN_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': '未找到令牌，请登录',
      'API.USER_ACCOUNT_HAS_BEEN_LOCKED': '用户帐号已锁定',
      'API.USER_ACCOUNT_HAS_EXPIRED': '用户帐号已过期',
      'API.USER_ACCOUNT_IS_NOT_LOCKED': 'User account is not locked',
      'API.USER_NAME_IS_ALREADY_IN_USE': '用户名已使用',
      'API.USER_NOT_FOUND': '用户不存在',
      'API.USER_PASSWORD_HAS_EXPIRED': '用户密码已过期',
      'API.USER_PRIVILEGE_NOT_FOUND': '未发现用户权限',
      'API.USER_SESSION_NOT_FOUND': '未发现用户会话',
      'API.USER_SESSION_TIMEOUT': '用户会话已过期',
      'API.USER_UUID_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': '用户UUID不存在，请登录',
      'API.VIRTUAL_METER_NAME_IS_ALREADY_IN_USE': '虚拟表名称已使用',
      'API.VIRTUAL_METER_NOT_FOUND': '虚拟表不存在',
      'API.VIRTUAL_METER_OF_VARIABLE_NOT_FOUND': '变量的虚拟表不存在',
      'API.VIRTUAL_POINT_SHOULD_BE_ANALOG_VALUE': '虚拟数据点应该是模拟量',
      'API.WEB_MESSAGE_NOT_FOUND': 'web信息不存在',
      'API.WECHAT_MESSAGE_NOT_FOUND': '微信信息不存在',
      'API.WIND_FARM_NAME_IS_ALREADY_IN_USE': 'Wind farm name is already in use',
      'API.WIND_FARM_NOT_FOUND': 'Wind farm not found',
      'API.WORKING_CALENDAR_NAME_IS_ALREADY_IN_USE': '工作日历名称已使用',
      'API.WORKING_CALENDAR_NOT_FOUND': '工作日历不存在',

      MasterMeter: '总表',
      SubMeter: '分表'
    }
  },
  de: {
    translation: {
      // routes & menus
      Dashboard: 'Instrumententafel',
      'Space Data': 'Platz',
      'Equipment Data': 'Ausrüstung',
      'Meter Data': 'Meter',
      'Tenant Data': 'Mieter',
      'Store Data': 'Geschäft',
      'Shopfloor Data': 'Werkstatt',
      'Combined Equipment Data': 'Kombinierte Ausrüstung',
      'Auxiliary System': 'Hilfssystem',
      Microgrid: 'Mikronetz',
      'Energy Storage Power Station': 'Energiespeicherkraftwerk',
      'Photovoltaic Power Station': 'Power Station',
      'Fault Alarm': 'Fehleralarm',
      Monitoring: 'Überwachung',
      'Advanced Reporting': 'Erweiterte Berichterstellung',
      'Knowledge Base': 'Wissensbasis',
      'Energy Category Data': 'Energiekategorie',
      'Energy Item Data': 'Unterpunkt Energieverbrauch',
      Carbon: 'CO2 Emissionen',
      Cost: 'Kosten',
      Output: 'Ausgang',
      Income: 'Einkommen',
      Efficiency: 'Effizienz',
      Load: 'Stromlast',
      Statistics: 'Statistiken',
      'Batch Analysis': 'Charge Analyse',
      Saving: 'Sparen',
      Plan: 'Planen',
      'Production': 'Production',
      'Space Production': 'Space Production',
      'Equipment Tracking': 'Ausrüstung Datei',
      'Environment Monitor': 'Umgebungsmonitor',
      'Meter Energy': 'Meter Energie ',
      'Meter Carbon': 'Meter CO2 Emissionen',
      'Meter Cost': 'Meter Kosten',
      'Meter Trend': 'Meter Trend',
      'Meter Realtime': 'Meter Echtzeit',
      'Meter Saving': 'Meter Sparen',
      'Meter Plan': 'Meterplan',
      'Master Meter Submeters Balance': 'Master Meter Submeters Balance',
      'Meter Batch Analysis': 'Meter Charge Analyse',
      'Meter Comparison': 'Meter Vergleich',
      'Meter Tracking': 'Meterverfolgung',
      'Virtual Meter Saving': 'Virtual Meter Sparen',
      'Virtual Meter Plan': 'Virtueller Zählerplan',
      'Virtual Meter Energy': 'Virtuelles Meter Energie',
      'Virtual Meter Carbon': 'Virtuelles Meter CO2 Emissionen',
      'Virtual Meter Cost': 'Virtuelles Meter Kosten',
      'Virtual Meter Batch Analysis': 'Virtuelles Meter Charge Analyse',
      'Offline Meter Energy': 'Offline Meter Energie',
      'Offline Meter Carbon': 'Offline Meter CO2 Emissionen',
      'Offline Meter Cost': 'Offline Meter Kosten',
      'Offline Meter Batch Analysis': 'Offline Meter Charge Analyse',
      'Offline Meter Saving': 'Offline Meter Sparen',
      'Offline Meter Plan': 'Offline-Zählerplan',
      'Offline Meter Input': 'Offline-Zählereinstellung',
      'Tenant Bill': 'Mieter Rechnung',
      'Energy Flow Diagram': 'Energieflussdiagramm',
      'Distribution System': 'Vertriebssystem',
      'SVG System': 'SCADA-Visualisierungssystem',
      'Faults Data': 'Fehler',
      'Space Equipments': 'Platz Ausrüstung',
      'Combined Equipments': 'Kombinierte Ausrüstung',
      'Energy Storage Power Station List': 'List',
      'Energy Storage Power Station Details': 'Details',
      'Energy Storage Power Station Reporting': 'Reporting',
      'Energy Storage Power Station Alarm': 'Alarm',
      'Energy Storage Power Station Maintenance': 'Maintenance',
      'Enter Production': 'Enter Production',
      'Multiple Energy Storage Power Stations': 'Multiple Power Stations',
      'Multiple Energy Storage Power Stations Dashboard': 'Dashboard',
      'Multiple Energy Storage Power Stations List': 'List',
      'Single Energy Storage Power Station': 'Single Power Station',
      'Single Energy Storage Power Station Dashboard': 'Dashboard',
      'Single Energy Storage Power Station Details': 'Details',
      'Single Energy Storage Power Station Fault Alarm': 'Fault Alarm',
      'Energy Storage Power Station Reports': 'Power Station Reports',
      'Energy Storage Power Station Reports Energy': 'Energy',
      'Energy Storage Power Station Reports Revenue': 'Revenue',
      'Energy Storage Power Station Reports Parameters': 'Parameters',
      'Photovoltaic Power Station List': 'List',
      'Photovoltaic Power Station Details': 'Details',
      'Photovoltaic Power Station Reporting': 'Reporting',
      'Photovoltaic Power Station Alarm': 'Alarm',
      'Photovoltaic Power Station Maintenance': 'Maintenance',
      'Enter Production': 'Enter Production',
      'Multiple Photovoltaic Power Stations': 'Multiple Power Stations',
      'Multiple Photovoltaic Power Stations Dashboard': 'Dashboard',
      'Multiple Photovoltaic Power Stations List': 'List',
      'Single Photovoltaic Power Station': 'Single Power Station',
      'Single Photovoltaic Power Station Dashboard': 'Dashboard',
      'Single Photovoltaic Power Station Details': 'Details',
      'Single Photovoltaic Power Station Fault Alarm': 'Fault Alarm',
      'Photovoltaic Power Station Reports': 'Power Station Reports',
      'Photovoltaic Power Station Reports Energy': 'Energy',
      'Photovoltaic Power Station Reports Revenue': 'Revenue',
      'Photovoltaic Power Station Reports Parameters': 'Parameters',
      'Work Order': 'Work Order',
      'Work Order Installation': 'Installation',
      'Work Order Repair': ' Repair',
      'Work Order Inspection': 'Inspection',
      'Total Number of Work Orders': 'Gesamtzahl der Arbeitsaufträge',
      'Number of New Work Orders': 'Anzahl der neuen Arbeitsaufträge',
      'Number of Inprogress Work Orders': 'Anzahl der laufenden Arbeitsaufträge',
      'Number of Done Work Orders': 'Anzahl der erledigten Arbeitsaufträge',
      'Charge Energy Indicator': 'Ladeenergie',
      'Discharge Energy Indicator': 'Entladeenergie',
      'Charge Cost Indicator': 'Ladekosten',
      'Discharge Revenue Indicator': 'Entladeeinnahmen',
      'HVAC': 'HVAC',
      'Fire Control': 'Brandschutz',

      // Dashboard
      'Welcome to DoraEOS': 'Willkommen bei DoraEOS',
      'An Industry Leading Open Source Energy Management System':
        'Ein branchenführendes Open Source Energie Management System',
      "This Year's Consumption CATEGORY VALUE UNIT": 'Der Verbrauch Dieses Jahr {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Year's Costs CATEGORY VALUE UNIT": 'Der Kosten Dieses Jahr {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Consumption CATEGORY VALUE UNIT": 'Der Verbrauch Dieses Monat {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Costs CATEGORY VALUE UNIT": 'Der Kosten Dieses Monat {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Consumption CATEGORY VALUE UNIT in The Same Period Last Year':
        'Der Verbrauch {{CATEGORY}} {{VALUE}} {{UNIT}} im gleichen Zeitraum im vergangenen Jahr',
      'Costs CATEGORY VALUE UNIT in The Same Period Last Year':
        'Der Kosten {{CATEGORY}} {{VALUE}} {{UNIT}} im gleichen Zeitraum im vergangenen Jahr',
      'CATEGORY Consumption UNIT': '{{CATEGORY}} Verbrauch {{UNIT}}',
      'CATEGORY Costs UNIT': '{{CATEGORY}} Kosten {{UNIT}}',
      // Entities
      Space: 'Platz',
      Equipment: 'Ausrüstung',
      Meter: 'Meter',
      'Offline Meter': 'Offline Meter',
      'Virtual Meter': 'Virtuelles Meter',
      Tenant: 'Mieter',
      Store: 'Geschäft',
      Shopfloor: 'Werkstatt',
      'Combined Equipment': 'Kombinierte Ausrüstung',
      'Cost Center': 'Kostenstelle',
      Name: 'Name',
      DisplayName: 'Anzeigename',
      UserName: 'Nutzername',
      Description: 'Beschreibung',
      'Energy Category': 'Energiekategorie',
      'Child Spaces': 'Teil Platz',
      'Associated Equipment': 'Dazugehörige Geräte',
      //Energy Categories
      Electricity: 'Elektrizität',
      Water: 'Wasser',
      'Natural Gas': 'Erdgas',
      // SidePanelModal
      Settings: 'Einstellungen',
      'Set your own customized style': 'Stellen Sie Ihren eigenen Stil ein',
      'Color Scheme': 'Farbschema',
      'Choose the perfect color mode for your app': 'Wählen Sie den perfekten Farbmodus für Ihre App',
      Light: 'Helle',
      Dark: 'Dunkle',
      'RTL Mode': 'RTL-Modus',
      'Switch your language direction': 'Wechseln Sie Ihre Sprachrichtung',
      'Fluid Layout': 'Flüssigkeitsanordnung',
      'Toggle container layout system': 'Container-Layout-System umschalten',
      'Navigation Position': 'Navigationsposition',
      'Select a suitable navigation system for your web application':
        'Wählen Sie ein geeignetes Navigationssystem für Ihre Webanwendung',
      'Vertical Nav': 'Vertikale Navigation',
      'Top Nav': 'Top Nav',
      'Vertical Navbar Style': 'Vertikaler Navbar-Stil',
      'Switch between styles for your vertical navbar':
        'Wechseln Sie zwischen den Stilen für Ihre vertikale Navigationsleiste',
      Language: 'Sprache',
      'Switch between languages': 'Zwischen den Sprachen wechseln',
      'language-zh_CN': '简体中文',
      'language-en': 'English',
      'language-de': 'Deutsch',
      'language-fr': 'Français',
      'language-es': 'Español',
      'language-ru': 'Русский',
      'language-ar': 'العربية',
      'language-vi': 'Tiếng Việt',
      'language-th': 'ภาษาไทย',
      'language-tr': 'Türkçe',
      'language-ms': 'Bahasa Melayu',
      'language-id': 'Bahasa Indonesia',
      'language-zh_TW': '繁體中文',
      'Like What You See?': 'Wie was du siehst?',
      'Get DoraEOS now': 'Holen Sie sich jetzt DoraEOS',
      Purchase: 'Kauf',
      // Query Panels
      'Base Period Begins': 'Basisperiode beginnt',
      'Base Period Ends': 'Basisperiode endet',
      'Comparison Types': 'Vergleichstypen',
      'Year-Over-Year': 'Jahr für Jahr',
      'Month-On-Month': 'Monat für Monat',
      'Free Comparison': 'Freier Vergleich',
      'None Comparison': 'Kein Vergleich',
      'Reporting Period Begins': 'Berichtszeitraum beginnt',
      'Reporting Period Ends': 'Berichtszeitraum endet',
      '(Optional)': '(Optional)',
      'Period Types': 'Periodentypen',
      Yearly: 'Jährlich',
      Monthly: 'Monatlich',
      Weekly: 'Wöchentlich',
      Daily: 'Täglich',
      Hourly: 'Stündlich',
      Submit: 'einreichen',
      'Input Energy Category': 'Eingangsenergiekategorie',
      'Output Energy Category': 'Ausgangsenergiekategorie',
      'Fraction Parameter': 'Fraktion Parameter',
      Search: 'Suche',
      //DateRangePicker
      sunday: 'So',
      monday: 'Mo',
      tuesday: 'Di',
      wednesday: 'Mi',
      thursday: 'Do',
      friday: 'Fr',
      saturday: 'Sa',
      ok: 'OKAY',
      today: 'Heute',
      yesterday: 'Gestern',
      hours: 'Stunden',
      minutes: 'Protokoll',
      seconds: 'Nachschlag',
      last7Days: 'Letzte 7 Tage',
      'Select Date Range': 'Datumsbereich auswählen',
      //Card Summaries and Line Charts
      'Base Period': 'Basiszeitraum',
      'Reporting Period': 'Berichtszeitraum',
      'Per Unit Area': 'Pro Flächeneinheit',
      'Per Capita': 'Per Capita',
      'Per Unit Production': 'Produktion pro Einheit',
      Baseline: 'Basiswert',
      Actual: 'Istwert',
      'Baseline Value - Actual Value': 'Basiswert - Istwert',
      'Average Load': 'Durchschnittliche Belastung',
      'Maximum Load': 'Maximale Last',
      'Load Factor': 'Ladefaktor',
      'Ratio of Average Load to Maximum Load': 'Verhältnis von durchschnittlicher Last zu maximaler Last',
      'Carbon Dioxide Emissions by Energy Category': 'Kohlendioxidemissionen nach Energiekategorien',
      'Costs by Energy Category': 'Kosten nach Energiekategorie',
      'Incomes by Energy Category': 'Einkommen nach Energiekategorie',
      'Electricity Consumption by Time-Of-Use': 'Stromverbrauch nach Nutzungsdauer',
      'Electricity Carbon Dioxide Emissions by Time-Of-Use': 'Emission von Kohlendioxid bei Zeitaufteilung',
      'Electricity Cost by Time-Of-Use': 'Kosten des Stromverbrauchs nach Zeiteinteilung',
      'CATEGORY UNIT Consumption by Energy Items': '{{CATEGORY}} {{UNIT}} nach Unterpunkt Energieverbrauch',
      'Ton of Standard Coal by Energy Category': 'Tonne Standardkohle(TCE) nach Energiekategorie',
      'Ton of Carbon Dioxide Emissions by Energy Category': 'Tonne Kohlendioxidemissionen(TCO2E) nach Energiekategorie',
      'Reporting Period Consumption CATEGORY UNIT': 'Verbrauch des Berichtszeitraums {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{EQUIPMENT}} Verbrauch des Berichtszeitraums {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Verbrauch des Berichtszeitraums {{CATEGORY}} {{UNIT}}',
      'CATEGORY VALUE UNIT': '{{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption CATEGORY VALUE UNIT':
        'Verbrauch des Berichtszeitraums {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Production PRODUCT VALUE UNIT': 'Reporting Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Production PRODUCT VALUE UNIT': 'Base Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption CATEGORY VALUE UNIT': 'Verbrauch des Basiszeitraums {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY UNIT':
        'Verbrauch des Berichtszeitraums {{ITEM}} {{CATEGORY}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY VALUE UNIT':
        'Verbrauch des Berichtszeitraums {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption ITEM CATEGORY VALUE UNIT':
        'Verbrauch des Basiszeitraums {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Child Space Proportion CATEGORY UNIT': 'Anteil des Teil Platz nach Energiekategorie {{CATEGORY}} {{UNIT}}',
      'Child Space Total Proportion': 'Anteil des Teil Platz Gesamtanteil',
      'Reporting Period Carbon Dioxide Emissions CATEGORY UNIT':
        'Berichtszeitraum Kohlendioxidemissionen {{CATEGORY}} {{UNIT}}',
      'Reporting Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Berichtszeitraum Kohlendioxidemissionen {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Basiszeitraum Kohlendioxidemissionen {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Costs CATEGORY UNIT': 'Berichtszeitraumkosten {{CATEGORY}} {{UNIT}}',
      'Reporting Period Costs CATEGORY VALUE UNIT': 'Berichtszeitraumkosten {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Costs CATEGORY VALUE UNIT': 'Kosten des Basiszeitraums {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Output CATEGORY UNIT': 'Ausgabe des Berichtszeitraums {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{EQUIPMENT}} Ausgabe des Berichtszeitraums {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Ausgabe des Berichtszeitraums {{CATEGORY}} {{UNIT}}',
      'Reporting Period Output CATEGORY VALUE UNIT': 'Ausgabe des Berichtszeitraums {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Output CATEGORY VALUE UNIT': 'Basisperiodenausgabe {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Income CATEGORY UNIT': 'Berichtsperiodeneinkommen {{CATEGORY}} {{UNIT}}',
      'Reporting Period Income CATEGORY VALUE UNIT': 'Berichtsperiodeneinkommen {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Income CATEGORY VALUE UNIT': 'Basisperiodeneinkommen {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Total Income UNIT': 'Berichtszeitraum Gesamteinkommen {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME UNIT': 'Berichtszeitraum Kumulative Effizienz {{NAME}} {{UNIT}}',
      'EQUIPMENT Reporting Period Cumulative Efficiency UNIT':
        '{{EQUIPMENT}} Berichtszeitraum Kumulative Effizienz {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Berichtszeitraum Kumulative Gesamteffizienz {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Berichtszeitraum Kumulative Gesamteffizienz {{VALUE}} {{UNIT}}',
      'COMBINED_EQUIPMENT Base Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Basisperiode Kumulative Gesamteffizienz {{VALUE}} {{UNIT}}',
      'Instantaneous Efficiency UNIT': 'Sofortige Effizienz {{UNIT}}',
      'EQUIPMENT Instantaneous Efficiency UNIT': '{{EQUIPMENT}} Sofortige Effizienz {{UNIT}}',
      'COMBINED_EQUIPMENT Instantaneous Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Sofortige umfassende Effizienz {{UNIT}}',
      'Reporting Period Cumulative Efficiency VALUE UNIT': 'Berichtszeitraum Kumulative Effizienz {{VALUE}} {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME VALUE UNIT':
        'Berichtszeitraum Kumulative Effizienz {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency VALUE UNIT': 'Kumulative Effizienz der Basisperiode {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency NAME VALUE UNIT':
        'Kumulative Effizienz der Basisperiode {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period CATEGORY Maximum Load UNIT': 'Basiszeitraum {{CATEGORY}} Maximale Last {{UNIT}}',
      'Reporting Period CATEGORY Maximum Load UNIT': 'Berichtszeitraum {{CATEGORY}} Maximale Last {{UNIT}}',
      'Reporting Period CATEGORY Average Load UNIT':
        'Berichtszeitraum {{CATEGORY}} Durchschnittliche Belastung {{UNIT}}',
      'Reporting Period CATEGORY Load Factor': 'Berichtszeitraum {{CATEGORY}} Ladefaktor',
      'Base Period CATEGORY Average Load VALUE UNIT':
        'Basiszeitraum {{CATEGORY}} Durchschnittliche Belastung {{VALUE}} {{UNIT}}',
      'Reporting Period CATEGORY Average UNIT': 'Berichtszeitraum {{CATEGORY}} Durchschnittswert {{UNIT}}',
      'Reporting Period CATEGORY Maximum UNIT': 'Berichtszeitraum {{CATEGORY}} Höchster Wert {{UNIT}}',
      'Reporting Period CATEGORY Minimum UNIT': 'Berichtszeitraum {{CATEGORY}} Mindestwert {{UNIT}}',
      'Reporting Period CATEGORY Mean UNIT': 'Berichtszeitraum {{CATEGORY}} Arithmetisches Mittel {{UNIT}}',
      'Reporting Period CATEGORY Median UNIT': 'Berichtszeitraum {{CATEGORY}} Median (Mittlerer Wert) {{UNIT}}',
      'Reporting Period CATEGORY Stdev UNIT': 'Berichtszeitraum {{CATEGORY}} Standardabweichung der Probe {{UNIT}}',
      'Reporting Period CATEGORY Variance UNIT': 'Berichtszeitraum {{CATEGORY}} Stichprobenvarianz {{UNIT}}',
      'Reporting Period Saving CATEGORY (Baseline - Actual) UNIT':
        'Einsparungen im Berichtszeitraum {{CATEGORY}} (Grundlinie - Ist) {{UNIT}}',
      'Reporting Period Decreased CATEGORY (Baseline - Actual) UNIT':
        'Berichtszeitraum verkürzt {{CATEGORY}} (Grundlinie - Ist) {{UNIT}}',
      'Reporting Period Saving CATEGORY VALUE UNIT': 'Einsparungen im Berichtszeitraum {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Saving CATEGORY VALUE UNIT': 'Einsparungen in der Basisperiode {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY UNIT':
        'Berichtszeitraum Master Meter Verbrauch {{CATEGORY}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY VALUE UNIT':
        'Berichtszeitraum Master Meter Verbrauch {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY UNIT':
        'Berichtszeitraum Submeters Verbrauch {{CATEGORY}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY VALUE UNIT':
        'Berichtszeitraum Submeters Verbrauch {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Difference CATEGORY UNIT': 'Unterschied im Berichtszeitraum {{CATEGORY}} {{UNIT}}',
      'Reporting Period Percentage Difference': 'Unterschied im Prozentsatz Berichtszeitraum',
      'Reporting Period Difference CATEGORY VALUE UNIT':
        'Unterschied im Berichtszeitraum {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Trend Values': 'Trendwerte',
      'METER CATEGORY VALUE UNIT': '{{METER}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      //FDD
      'Fault List': 'Fehlerliste',
      'Reporting Period Number of Fault VALUE': 'Berichtszeitraum Anzahl der Fehler: {{VALUE}}',
      'All Faults': 'Alle Fehler',
      'Space Faults': 'Platz Fehler',
      'Number of All Faults': 'Anzahl aller Fehler',
      'Number of Space Faults': 'Anzahl der Platz Fehler',
      'Total Number of Faults': 'Total Number of Faults',
      'Number of New Faults': 'Number of New Faults',
      'Number of Inprogress Faults': 'Number of Inprogress Faults',
      'Number of Done Faults': 'Number of Done Faults',
      //Data Panels
      'Ton of Standard Coal': 'Tonne Standardkohle',
      'Ton of Carbon Dioxide Emissions': 'Tonne Kohlendioxidemissionen',
      'Operating Characteristic Curve': 'Betriebscharakteristikkurve',
      Tariff: 'Tarif',
      'Detailed Data': 'Detaillierte Daten',
      'Child Spaces Data': 'Teil Platz Daten',
      'Associated Equipment Data': 'Dazugehörige Geräte Daten',
      'This Year': 'Dieses Jahr',
      'The Same Period Last Year': 'gleichen Zeitraum im vergangenen Jahr',
      'This Month': 'Diesen Monat',
      'This Day': 'Dieser Tag',
      Total: 'Gesamt',
      'No data found': 'Keine Daten gefunden',
      Export: 'Export',
      Datetime: 'Terminzeit',
      'Time-Of-Use Type': 'Art der Zeitteilung',
      'Top-Peak': 'Top-Peak',
      'On-Peak': 'On-Peak',
      'Mid-Peak': 'Mid-Peak',
      'Off-Peak': 'Off-Peak',
      Percentage: 'Prozentsatz',
      //Realtime Chart
      'Trend in the last hour of Energy Value Point': 'Trend in der letzten Stunde des Energiewertpunkts',
      Circuit: 'Schaltkreis',
      Point: 'Punkt',
      'Realtime Value': 'Echtzeitwert',
      //Meter Tracking
      'Meter List': 'Zählerliste',
      'Edit Meter': 'bearbeiten',
      'Start Value': 'Wert des Starts',
      'End Value': 'Wert des Ends',
      'Difference Value': 'Differenzwert',
      'Start Integrity Rate': 'Start Integritätsrate(%)',
      'End Integrity Rate': 'End Integritätsrate(%)',
      'Full Integrity Rate': 'Vollständige Integritätsrate(%)',
      //Equipment Tracking
      'Equipment List': 'Ausrüstungsliste',
      'Edit Equipment': 'bearbeiten',
      //Profile Dropdown
      Feedback: 'Feedback',
      'Account Settings': 'Kontoeinstellungen',
      Logout: 'Ausloggen',
      //Authentication
      'Log in': 'Anmeldung',
      'Email address': 'E-Mail-Addresse',
      Password: 'Passwort',
      CaptchaCode: 'Captcha',
      Refresh: 'Auffrischen',
      'Remember me': 'Behalte mich in Erinnerung',
      'Logged in as ': 'Angemeldet als ',
      'Forgot Password?': 'Passwort vergessen?',
      'Forgot your password?': 'Passwort vergessen?',
      "Enter your email and we'll send you a reset link":
        'Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link zum Zurücksetzen',
      "Enter your email and we'll send you a registration link":
        'Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen den Registrierungslink zu',
      'Reset Password': 'Passwort zurücksetzen',
      'Thanks for using DoraEOS!': 'Vielen Dank, dass Sie DoraEOS verwenden!',
      'You are now successfully signed out': 'Sie sind jetzt erfolgreich abgemeldet',
      'Return to Login': 'Zurück zum Login',
      'Please check your email!': 'Bitte überprüfen Sie Ihre E-Mails!',
      'An email has been sent to ': 'Eine email wurde gesendet an ',
      'Please click on the included link to reset your password':
        'Bitte klicken Sie auf den enthaltenen Link, um Ihr Passwort zurückzusetzen',
      'Please click on the included link to register your account':
        'Bitte klicken Sie auf den beigefügten Link, um Ihr Konto zu registrieren.',
      'An email with password reset link is sent to ':
        'Eine E-Mail mit einem Link zum Zurücksetzen des Passworts wird an gesendet ',
      'Change Password': 'Ändere das Passwort',
      'Old Password': 'Altes Passwort',
      'New Password': 'Neues Kennwort',
      'Confirm Password': 'Bestätige das Passwort',
      'Update Password': 'Kennwort aktualisieren',
      'Password has been changed!': 'Das Passwort wurde geändert!',
      'Verification code has been sent to your inbox. \
      Please copy it to the input box below.':
        'Der Bestätigungscode wurde an Ihr Postfach gesendet. \
      Bitte kopieren Sie ihn in das Eingabefeld unten.',
      'New to DoraEOS': 'Neu bei DoraEOS',
      'Create an account': 'Ein Konto erstellen',
      'EMAIL Account registration successful': '{{EMAIL}} Konto erfolgreich registriert',
      'Thanks for verifying your account!': 'Vielen Dank, dass Sie Ihr Konto verifiziert haben!',
      'Your code is': 'Ihr Code lautet',
      'Send verification code': 'Verifizierungscode gesendet',
      'Please wait for NUMBER seconds': 'Bitte warten Sie {{NUMBER}} Sekunden',
      'Password reset': 'Passwort zurücksetzen',
      'Please wait for approval': 'Bitte warten Sie auf Genehmigung',
      //notification
      Notifications: 'Benachrichtigungen',
      'Mark all as read': 'Markiere alle als gelesen',
      'View all': 'Alle',
      'Notification New': 'Neu',
      'Notification Earlier': 'Vorhin',
      'Notification List': 'Benachrichtigungsliste',
      'Bulk actions': 'Massenaktionen',
      'Notification Subject': 'Betreff',
      'Notification Created Datetime': 'Datetime erstellt',
      'Notification Priority': 'Priorität',
      'Notification Message': 'Nachricht',
      'Notification Status': 'Status',
      'Notification Mark As Read': 'Mark as read',
      'Notification Mark As Acknowledged': 'Bestätigen',
      'Notification Low': 'Niedrig',
      'Notification Medium': 'Mittelmaß',
      'Notification High': 'Hoch',
      'Notification Critical': 'Kritisch',
      'Notification Unread': 'Ungelesene',
      'Notification Read': 'Lesen',
      'Notification Acknowledged': 'Anerkannt',
      'Notification Archive': 'Archiv',
      'Notification Delete': 'Löschen',
      'Notification Apply': 'Anwenden',
      'Notification Start Datetime': 'Starten Sie Datetime',
      'Notification End Datetime': 'Datum/Uhrzeit beenden',
      'Notification Update Datetime': 'Datum/Uhrzeit aktualisieren',
      //Monitoring
      'Run Commands': 'Befehle ausführen',
      'Fault Alarms': 'Fehleralarme',
      'Instantaneous Efficiency VALUE UNIT': 'Sofortige Effizienz {{VALUE}} {{UNIT}}',
      'Communication Status': 'Kommunikationsstatus',
      'Communication Online': 'Online',
      'Communication Offline': 'Offline',
      'Equipment Status': 'Ausrüstung Status',
      'Equipment Running': 'Läuft',
      'Equipment Stopped': 'Gestoppt',
      'Show Up to': 'Zeigen Sie bis zu',
      All: 'Alles',
      'FROM - TO of TOTAL': 'Die aktuelle Anzeige von {{FROM}} und {{TO}} total {{TOTAL}}',
      //Microgrid
      'PCS Run State': 'PCS-Ausführungsstatus',
      'PCS Unknown': 'Unbekannt',
      'PCS Initializing': 'Initialisieren',
      'PCS Standby': 'Standby',
      'PCS Shutdown': 'Herunterfahren',
      'PCS Fault': 'Fehler',
      'PCS Running': 'Laufend',
      'Battery Power': 'Batterieleistung',
      'Photovoltaic Power': 'Photovoltaik',
      'Grid Power': 'Netzleistung',
      'Load Power': 'Lastleistung',
      Reporting: 'Berichterstattung',
      Maintenance: 'Wartung',
      'Serial Number': 'Seriennummer',
      Address: 'Adresse',
      'Postal Code': 'Postleitzahl',
      'Rated Capacity': 'Nennleistung',
      'Rated Power': 'Nennleistung',
      Latitude: 'Breitengrad',
      Longitude: 'Längengrad',
      'Strategy Management': 'Strategiemanagement',
      'Charging Schedule': 'Ladeplan',
      'Charge Start Time': 'Ladestartzeit',
      'Charge End Time': 'Endzeit der Ladung',
      'Discharge Start Time': 'Startzeit der Entladung',
      'Discharge End Time': 'Endzeit der Entladung',
      'Total Rated Power': 'Nennleistung insgesamt',
      'Total Rated Capacity': 'Gesamtnennkapazität',
      // Energy Indicator
      "Today's Charge": "Das heutige Ladevolumen",
      "Today's Discharge": "Die heutige Entladungskapazität",
      "Today's Generation": "Die heutige Stromerzeugung",
      'Total Charge': 'Gesamtbetrag',
      'Total Discharge': 'totale Entladung',
      'Total Generation': 'Gesamtgeneration',
      'Total Revenue': 'Gesamtumsatz',
      'Total Efficiency': 'Gesamtwirkungsgrad',
      'Discharge Achievement Rate': 'Entladungsleistungsrate',
      // Revenue Indicator
      "Today's Cost": "Today's Cost",
      "Today's Income": "Today's Income",
      "Total Cost": "Total Cost",
      "Total Income": "Total Income",
      "Today's Revenue": "Today's Revenue",

      'Revenue Ranking': 'Umsatzranking',
      'Efficiency Ranking': 'Effizienzranking',
      'Charge Ranking': 'Rangliste der Ladekapazitäten',
      'Discharge Ranking': 'Entlassungsrangliste',
      'Number of Microgrids': 'Anzahl der Mikronetze',
      'Number of Power Stations': 'Anzahl der Kraftwerke',
      'Microgrid List': 'Microgrid-Liste',
      'Previous Page': 'Vorherige Seite',
      'Next Page': 'Nächste Seite',
      '7 Days': '7 Tage',
      'Charge UNIT': 'Aufladung {{UNIT}}',
      'Discharge UNIT': 'Entladung {{UNIT}}',
      'Generation UNIT': 'Strom erzeugen {{UNIT}}',
      'Energy Indicator': 'Energieanzeige',
      'Revenue Indicator': 'Umsatzindikator',
      'Carbon Indicator': 'Kohlenstoffindikator',

      // Energy Storage Power Station
      'Battery Operating State': 'Batteriebetriebszustand',
      'Battery State': 'Batteriestatus',
      'Battery Unknown': 'Unbekannt',
      'Battery Stopped': 'Angehalten',
      'Battery Reserved': 'Reserved', // 0
      'Battery Fault': 'Fehler', // 1
      'Battery Warning': 'Warnung', // 2
      'Battery Standby': 'Standby', // 3
      'Battery Prohibit DisCharging': 'Deaktivieren verbieten', // 4
      'Battery Prohibit Charging': 'Gebühren verbieten', // 5
      'Battery Normal': 'Normal', // 6
      'Battery Charging': 'Aufladung', // 7
      'Battery Discharging': 'Selama pembebasan', // 8
      'Battery Idle': 'Idle', // 9
      'Phase of Lifecycle': 'Phase des Lebenszyklus',
      'Use Phase': 'Anwendungsphase',
      'Commissioning Phase': 'Phase der Inbetriebnahme',
      'Installation Phase': 'Installationsphase',
      'Device Status': 'Gerätestatus',
      'Basic Information': 'Grundlegende Informationen',
      'Gateway': 'Gateway',
      'Grid Meter': 'Grid Meter',
      'Load Meter': 'Load Meter',
      'Total Active Power': 'Total Active Power',
      'Active Power A': 'Active Power A',
      'Active Power B': 'Active Power B',
      'Active Power C': 'Active Power C',
      'Total Reactive Power': 'Total Reactive Power',
      'Reactive Power A': 'Reactive Power A',
      'Reactive Power B': 'Reactive Power B',
      'Reactive Power C': 'Reactive Power C',
      'Total Apparent Power': 'Total Apparent Power',
      'Apparent Power A': 'Apparent Power A',
      'Apparent Power B': 'Apparent Power B',
      'Apparent Power C': 'Apparent Power C',
      'Total Power Factor': 'Total Power Factor',
      'Active Energy Import': 'Active Energy Import',
      'Active Energy Export': 'Active Energy Export',
      'Active Energy Net': 'Active Energy Net',

      // Photovoltaic Power Station
      'Invertor': 'Wechselrichter',
      'Invertor Run State': 'Ausführungsstatus',
      'Invertor Unknown': 'Unbekannt',
      'Invertor Initializing': 'Initialisieren',
      'Invertor Standby': 'Standby',
      'Invertor Shutdown': 'Herunterfahren',
      'Invertor Fault': 'Fehler',
      'Invertor Running': 'Laufend',

      //Advanced Reporting & Knowledge Base
      'Created Datetime': 'Datetime erstellt',
      'File Format': 'Datei Format',
      'File Size': 'Dateigröße',
      Uploader: 'Uploader',
      'Upload Datetime': 'Datetime hochladen',
      //Error
      "The page you're looking for is not found": 'Die gesuchte Seite wurde nicht gefunden',
      "Make sure the address is correct and that the page hasn't moved. ":
        'Stellen Sie sicher, dass die Adresse korrekt ist und die Seite nicht verschoben wurde',
      'If you think this is a mistake,': 'Wenn Sie denken, dass dies ein Fehler ist,',
      'contact us': 'kontaktiere uns',
      'Take me home': 'Bring mich zurück zur Homepage',
      'Whoops, something went wrong!': 'Oh, etwas ist schief gelaufen!',
      'Try refreshing the page, or going back and attempting the action again. ':
        'Versuchen Sie, die Seite zu aktualisieren, oder gehen Sie zurück und versuchen Sie die Aktion erneut. ',
      'If this problem persists,': 'Wenn dieses Problem weiterhin besteht,',
      'Captcha Error': 'Captcha-Fehler',
      'Confirm Password Error': 'Das Bestätigungspasswort stimmt nicht überein',
      'It looks like you clicked on an invalid password reset link. Please tryagain.':
        'Es scheint, als hättest \
      du auf einen ungültigen Link zur Passwortrücksetzung geklickt. Bitte versuche es erneut.',
      'It looks like you clicked on an invalid registration account link. Please tryagain.':
        'Es sieht so aus, als hätten \
      Sie auf einen ungültigen Registrierungslink geklickt. Bitte versuchen Sie es erneut.',
      //Tenant Bill
      'Lease Contract Number': 'Mietvertragsnummer',
      Download: 'Herunterladen',
      Print: 'Drucken',
      'Payment Notice': 'Zahlungshinweis',
      'Bill To': 'Zu',
      'Bill Number': 'Rechnungsnummer',
      'Bill Date': 'Rechnungsdatum',
      'Payment Due Date': 'Fälligkeitsdatum',
      'Amount Payable': 'Bezahlbarer Betrag',
      'Billing Period Start': 'Beginn des Abrechnungszeitraums',
      'Billing Period End': 'Ende des Abrechnungszeitraums',
      Quantity: 'Menge',
      Price: 'Preis',
      Unit: 'Maßeinheit',
      Amount: 'Geldbetrag',
      Subtotal: 'Zwischensumme',
      'VAT Output Tax': 'Mehrwertsteuer Ausgangssteuer',
      'Total Amount Payable': 'Gesamtbetrag zahlbar',
      'Please make sure to pay on or before the payment due date above':
        'Bitte stellen Sie sicher, dass Sie am oder vor dem oben genannten Fälligkeitsdatum bezahlen',
      'Send money to the following account': 'Senden Sie Geld auf das folgende Konto',
      'Account Name': 'Kontobezeichnung',
      'Bank Name': 'Bank Name',
      'Bank Address': 'Bankadresse',
      'RMB Account': 'RMB-Konto',
      // button
      'Toggle Navigation': 'Navigation umschalten',
      // working calendar
      'Working Days': 'Arbeitstage',
      'Non Working Days': 'Arbeitsfreie Tage',
      'Select Row': 'Bitte wählen Sie die zu bearbeitende Zeile aus',
      // Offline Meter Input
      'Daily Value': 'Tageswert',
      Date: 'Datum',
      'Successfully Saved': 'Betrieb erfolgreich',
      'Previous Data Is Empty': 'Vorherige Daten sind leer',
      // API
      'API.ACCOUNT_UNLOCK_FAILED': 'Account unlock failed',
      'API.ADMINISTRATOR_SESSION_NOT_FOUND': 'Administratorsitzung nicht gefunden',
      'API.ADMINISTRATOR_SESSION_TIMEOUT': 'Zeitüberschreitung für Administratorsitzungen',
      'API.ADVANCED_REPORT_NOT_FOUND': 'Erweiterter Bericht nicht gefunden',
      'API.API_KEY_HAS_EXPIRED': 'Key has expired',
      'API.API_KEY_NAME_IS_ALREADY_IN_USE': 'Key name is already in use',
      'API.API_KEY_NOT_FOUND': 'Key not found',
      'API.BAD_REQUEST': 'Ungültige Anfrage',
      'API.BALANCING_PRICE_POINT_NOT_FOUND': 'Balancing price point not found',
      'API.BATTERY_STATE_POINT_NOT_FOUND': 'Battery state point not found',
      'API.BUY_METER_NOT_FOUND': 'Buy meter not found',
      'API.CANNOT_SET_EXISTING_SUBMETER_AS_MASTER_METER':
        'Vorhandenes Submeter kann nicht als Mastermeter festgelegt werden',
      'API.CHARGE_METER_NOT_FOUND': 'Charge meter not found',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_EXISTS': 'Combined equipment command relation exists',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Combined equipment command relation not found',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_EXISTS': 'Kombinierte Ausrüstungsausrüstung besteht',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_NOT_FOUND': 'Kombinierte Ausrüstungsausrüstung nicht gefunden',
      'API.COMBINED_EQUIPMENT_METER_RELATION_EXISTS': 'Kombinierte Gerätezählerbeziehung besteht',
      'API.COMBINED_EQUIPMENT_METER_RELATION_NOT_FOUND': 'Kombinierte Gerätezählerbeziehung nicht gefunden',
      'API.COMBINED_EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Kombinierter Gerätename wird bereits verwendet',
      'API.COMBINED_EQUIPMENT_NOT_FOUND': 'Kombinierte Ausrüstung nicht gefunden',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Kombinierte Geräte-Offline-Zählerbeziehung besteht',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND':
        'Kombinierte Geräte-Offline-Zählerbeziehung nicht gefunden',
      'API.COMBINED_EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE':
        'Der Name des kombinierten Geräteparameters wird bereits verwendet',
      'API.COMBINED_EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH':
        'Parameter für kombinierte Ausrüstung nicht gefunden oder stimmt nicht überein',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS':
        'Kombinierte Ausrüstung virtuelle Zählerbeziehung existiert',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND':
        'Kombinierte Ausrüstung virtuelle Zählerbeziehung nicht gefunden',
      'API.COMMAND_NAME_IS_ALREADY_IN_USE': 'Command name is already in use',
      'API.COMMAND_NOT_FOUND': 'Command not  found',
      'API.CONTACT_NAME_IS_ALREADY_IN_USE': 'Der Kontaktname wird bereits verwendet',
      'API.CONTACT_NOT_FOUND': 'Kontakt nicht gefunden',
      'API.COOLING_METER_NOT_FOUND': 'Cooling meter not found',
      'API.COST_CENTER_EXTERNAL_ID_EXISTS': 'Externe Kostenstellen-ID vorhanden',
      'API.COST_CENTER_NAME_EXISTS': 'Der Name der Kostenstelle ist vorhanden',
      'API.COST_CENTER_NOT_FOUND': 'Kostenstelle nicht gefunden',
      'API.COST_FILE_NOT_FOUND': 'Kostendatei nicht gefunden',
      'API.DATA_REPAIR_FILE_NOT_FOUND': 'Data repair file not found',
      'API.DATA_SOURCE_NAME_IS_ALREADY_IN_USE': 'Der Name der Datenquelle wird bereits verwendet',
      'API.DATA_SOURCE_NOT_FOUND': 'Datenquelle nicht gefunden',
      'API.DATABASE_ERROR': 'Datenbankfehler',
      'API.DATE_IS_ALREADY_IN_WORKING_CALENDAR': 'Datum ist bereits im Arbeitskalender',
      'API.DATE_LOCAL_NOT_FOUND': 'Datum lokal nicht gefunden',
      'API.DISCHARGE_METER_NOT_FOUND': 'Discharge meter not found',
      'API.DISTRIBUTION_CIRCUIT_NAME_IS_ALREADY_IN_USE': 'Der Name der Verteilungsschaltung wird bereits verwendet',
      'API.DISTRIBUTION_CIRCUIT_NOT_FOUND': 'Verteilungskreis nicht gefunden',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_EXISTS': 'Verteilungskreispunktbeziehung existiert',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_NOT_FOUND': 'Verteilungskreispunktbeziehung nicht gefunden',
      'API.DISTRIBUTION_SYSTEM_NAME_IS_ALREADY_IN_USE': 'Der Name des Verteilungssystems wird bereits verwendet',
      'API.DISTRIBUTION_SYSTEM_NOT_FOUND': 'Verteilungssystem nicht gefunden',
      'API.ELECTRICITY_METER_NOT_FOUND': 'Electricity meter not found',
      'API.EMAIL_IS_ALREADY_IN_USE': 'E-Mail wird bereits verwendet',
      'API.EMAIL_MESSAGE_NOT_FOUND': 'E-Mail-Nachricht nicht gefunden',
      'API.EMAIL_NOT_FOUND': 'Die E-Mail-Adresse existiert nicht',
      'API.EMAIL_SERVER_HOST_IS_ALREADY_IN_USE': 'E-Mail-Server-Host wird bereits verwendet',
      'API.EMAIL_SERVER_NOT_FOUND': 'E-Mail-Server nicht gefunden',
      'API.EMPTY_VARIABLES_ARRAY': 'Leeres Variablen-Array',
      'API.ENERGY_CATEGORY_NAME_IS_ALREADY_IN_USE': 'Name der Energiekategorie wird bereits verwendet',
      'API.ENERGY_CATEGORY_NOT_FOUND': 'Energiekategorie nicht gefunden',
      'API.ENERGY_CATEGORY_USED_IN_ENERGY_ITEMS': 'Energiekategorie, die in Energieerzeugnissen verwendet wird',
      'API.ENERGY_CATEGORY_USED_IN_METER': 'Energiekategorie im Zähler',
      'API.ENERGY_CATEGORY_USED_IN_OFFLINE_METER': 'Energiekategorie, die in Offline-Zählern verwendet wird',
      'API.ENERGY_CATEGORY_USED_IN_TARIFFS': 'In Tarifen verwendete Energiekategorie',
      'API.ENERGY_CATEGORY_USED_IN_VIRTUAL_METER': 'Energiekategorie, die im virtuellen Zähler verwendet wird',
      'API.ENERGY_FLOW_DIAGRAM_LINK_IS_ALREADY_IN_USE': 'Energieflussdiagramm-Link wird bereits verwendet',
      'API.ENERGY_FLOW_DIAGRAM_LINK_NOT_FOUND_OR_NOT_MATCH':
        'Energieflussdiagramm-Link nicht gefunden oder stimmt nicht überein',
      'API.ENERGY_FLOW_DIAGRAM_NAME_IS_ALREADY_IN_USE': 'Der Name des Energieflussdiagramms wird bereits verwendet',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NAME_IS_ALREADY_IN_USE':
        'Der Name des Energieflussdiagramm-Knotens wird bereits verwendet',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NOT_FOUND_OR_NOT_MATCH':
        'Energieflussdiagrammknoten nicht gefunden oder nicht übereinstimmend',
      'API.ENERGY_FLOW_DIAGRAM_NOT_FOUND': 'Energieflussdiagramm nicht gefunden',
      'API.ENERGY_ITEM_DOES_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energieartikel gehört nicht zur Energiekategorie',
      'API.ENERGY_ITEM_IS_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energieartikel gehört nicht zur Energiekategorie',
      'API.ENERGY_ITEM_NAME_IS_ALREADY_IN_USE': 'Name des Energieelements wird bereits verwendet',
      'API.ENERGY_ITEM_NOT_FOUND': 'Energieartikel nicht gefunden',
      'API.ENERGY_ITEM_USED_IN_METER': 'Energieelement, das im Zähler verwendet wird',
      'API.ENERGY_ITEM_USED_IN_OFFLINE_METER': 'Energieelement, das im Offline-Zähler verwendet wird',
      'API.ENERGY_ITEM_USED_IN_VIRTUAL_METER': 'Energieelement, das im virtuellen Zähler verwendet wird',
      'API.ENERGY_STORAGE_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Energy storage power station name is already in use',
      'API.ENERGY_STORAGE_POWER_STATION_NOT_FOUND': 'Energy storage power station not found',
      'API.EQUIPMENT_COMMAND_RELATION_EXISTS': 'Equipment command relation exists',
      'API.EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Equipment command relation not found',
      'API.EQUIPMENT_METER_RELATION_EXISTS': 'Gerätezählerbeziehung besteht',
      'API.EQUIPMENT_METER_RELATION_NOT_FOUND': 'Gerätezählerbeziehung nicht gefunden',
      'API.EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Gerätename wird bereits verwendet',
      'API.EQUIPMENT_NOT_FOUND': 'Ausrüstung nicht gefunden',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Geräte-Offline-Zählerbeziehung existiert',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Geräte-Offline-Zählerbeziehung nicht gefunden',
      'API.EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Der Name des Geräteparameters wird bereits verwendet',
      'API.EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Equipment-Parameter nicht gefunden oder stimmt nicht überein',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Virtuelle Zählerbeziehung der Ausrüstung existiert',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Virtuelle Gerätezählerbeziehung nicht gefunden',
      'API.ERROR': 'Fehler',
      'API.EXCEPTION': 'Ausnahme',
      'API.FAILED_TO_READ_REQUEST_STREAM': 'Failed to read request stream',
      'API.FAILED_TO_RESTORE_COST_FILE': 'Kostendatei konnte nicht wiederhergestellt werden',
      'API.FAILED_TO_RESTORE_DATA_REPAIR_FILE': 'Failed to restore data repair file',
      'API.FAILED_TO_RESTORE_KNOWLEDGE_FILE': 'Fehler beim Wiederherstellen der Wissensdatei',
      'API.FAILED_TO_RESTORE_OFFLINE_METER_FILE': 'Offline-Zählerdatei konnte nicht wiederhergestellt werden',
      'API.FAILED_TO_SAVE_OFFLINE_METER_FILE': 'Offline-Zählerdatei konnte nicht gespeichert werden',
      'API.FAILED_TO_UPLOAD_ATTACHMENT_FILE': 'Failed to upload attachment file',
      'API.FAILED_TO_UPLOAD_COST_FILE': 'Kostendatei konnte nicht hochgeladen werden',
      'API.FAILED_TO_UPLOAD_DATA_REPAIR_FILE': 'Failed to upload data repair file',
      'API.FAILED_TO_UPLOAD_KNOWLEDGE_FILE': 'Wissensdatei konnte nicht hochgeladen werden',
      'API.FAILED_TO_UPLOAD_OFFLINE_METER_FILE': 'Offline-Zählerdatei konnte nicht hochgeladen werden',
      'API.GATEWAY_NAME_IS_ALREADY_IN_USE': 'Gateway-Name wird bereits verwendet',
      'API.GATEWAY_NOT_FOUND': 'Gateway nicht gefunden',
      'API.HEAT_METER_NOT_FOUND': 'Heat meter not found',
      'API.INVALID_': 'Ungültig ',
      'API.INVALID_ACKNOWLEDGE_CODE': 'Invalid acknowledge code',
      'API.INVALID_ADDRESS': 'Ungültige Adresse',
      'API.INVALID_ADDRESS_VALUE': 'Ungültiger Adresswert',
      'API.INVALID_ADVANCED_REPORT_ID': 'Ungültige erweiterte Berichts-ID',
      'API.INVALID_API_KEY_ID': 'Invalid api key id',
      'API.INVALID_API_KEY_NAME': 'Invalid api key name',
      'API.INVALID_AREA_VALUE': 'Ungültiger Flächenwert',
      'API.INVALID_BALANCING_PRICE_POINT_ID': 'Invalid balancing price point ID',
      'API.INVALID_BASE_PERIOD_END_DATETIME': 'Ungültige Basisperiode EnddatumUhrzeit',
      'API.INVALID_BASE_PERIOD_START_DATETIME': 'Startdatum ungültiger BasiszeitraumUhrzeit',
      'API.INVALID_BATTERY_STATE_POINT_ID': 'Invalid battery state point ID',
      'API.INVALID_BAUD_RATE': 'Ungültige Baudrate',
      'API.INVALID_BUILDINGS_VALUE': 'Ungültiger Gebäudewert',
      'API.INVALID_BUY_METER_ID': 'Invalid buy meter id',
      'API.INVALID_CAPACITY': 'Invalid capacity',
      'API.INVALID_CATEGORY': 'Ungültige Kategorie',
      'API.INVALID_CHANNEL': 'Ungültiger Kanal',
      'API.INVALID_CHARGE_METER_ID': 'Invalid charge meter id',
      'API.INVALID_COMBINED_EQUIPMENT_ID': 'Ungültige kombinierte Geräte-ID',
      'API.INVALID_COMBINED_EQUIPMENT_NAME': 'Ungültiger kombinierter Gerätename',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_ID': 'Ungültige kombinierte Geräteparameter-ID',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_NAME': 'Ungültiger kombinierter Geräteparametername',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_TYPE': 'Ungültiger kombinierter Geräteparametertyp',
      'API.INVALID_COMBINED_EQUIPMENT_UUID': 'Ungültige kombinierte Geräte-uuid',
      'API.INVALID_COMMAND_ID': 'Invalid command id',
      'API.INVALID_COMMAND_NAME': 'Invalid command name',
      'API.INVALID_CONNECTION': 'Ungültige Verbindung',
      'API.INVALID_CONSTANT_VALUE': 'Ungültiger konstanter Wert',
      'API.INVALID_CONTACT_ID': 'Ungültige Kontakt-ID',
      'API.INVALID_CONTACT_NAME': 'Ungültiger Kontaktname',
      'API.INVALID_COOLING_METER_ID': 'Invalid cooling meter id',
      'API.INVALID_COST_CENTER_ID': 'Ungültige Kostenstellen-ID',
      'API.INVALID_COST_FILE_ID': 'Ungültige Kostendatei-ID',
      'API.INVALID_CREATED_DATETIME': 'Invalid created datetime',
      'API.INVALID_DATA_REPAIR_FILE_ID': 'Invalid data repair file id',
      'API.INVALID_DATA_SOURCE_ID': 'Ungültige Datenquellen-ID',
      'API.INVALID_DATA_SOURCE_NAME': 'Ungültiger Datenquellenname',
      'API.INVALID_DATA_SOURCE_PROTOCOL': 'Ungültiges Datenquellenprotokoll',
      'API.INVALID_DATE_LOCAL': 'Ungültiges lokales Datum',
      'API.INVALID_DENOMINATOR_METER_UUID': 'Ungültiger Nenner Meter uuid',
      'API.INVALID_DISCHARGE_METER_ID': 'Invalid discharge meter id',
      'API.INVALID_DISPLAY_NAME': 'Ungültiger Anzeigename',
      'API.INVALID_DISTRIBUTION_CIRCUIT_ID': 'Ungültige Verteilungskreis-ID',
      'API.INVALID_DISTRIBUTION_CIRCUIT_NAME': 'Ungültiger Verteilungskreisname',
      'API.INVALID_DISTRIBUTION_ROOM': 'Ungültiger Verteilungsraum',
      'API.INVALID_DISTRIBUTION_SYSTEM_ID': 'Ungültige Verteilungssystem-ID',
      'API.INVALID_DISTRIBUTION_SYSTEM_NAME': 'Ungültiger Verteilungssystemname',
      'API.INVALID_ELECTRICITY_METER_ID': 'Invalid Electricity meter id',
      'API.INVALID_EMAIL': 'Ungültige E-Mail-Adresse',
      'API.INVALID_EMAIL_MESSAGE_ID': 'Ungültige E-Mail-Nachrichten-ID',
      'API.INVALID_EMAIL_SERVER_HOST': 'Ungültiger E-Mail-Server-Host',
      'API.INVALID_EMAIL_SERVER_ID': 'Ungültige E-Mail-Server-ID',
      'API.INVALID_END_DATE_FORMAT': 'Ungültiges Enddatumsformat',
      'API.INVALID_END_DATETIME': 'Ungültige Enddatumszeit',
      'API.INVALID_ENERGY_CATEGORY_ID': 'Ungültige Energiekategorie-ID',
      'API.INVALID_ENERGY_CATEGORY_NAME': 'Ungültiger Energiekategoriename',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_ID': 'Ungültige Energieflussdiagramm-ID',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_LINK_ID': 'Ungültige Energieflussdiagramm-Link-ID',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NAME': 'Ungültiger Name des Energieflussdiagramms',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_ID': 'Ungültige Energieflussdiagramm-Knoten-ID',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_NAME': 'Ungültiger Energieflussdiagramm-Knotenname',
      'API.INVALID_ENERGY_ITEM_ID': 'Ungültige Energieelement-ID',
      'API.INVALID_ENERGY_ITEM_NAME': 'Ungültiger Energieelementname',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_ID': 'Invalid energy storage power station ID',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_NAME': 'Invalid energy storage power station name',
      'API.INVALID_EQUATION_IN_EXPRESSION': 'Ungültige Gleichung im Ausdruck',
      'API.INVALID_EQUIPMENT_ID': 'Ungültige Geräte-ID',
      'API.INVALID_EQUIPMENT_NAME': 'Ungültiger Gerätename',
      'API.INVALID_EQUIPMENT_PARAMETER_ID': 'Ungültige Geräteparameter-ID',
      'API.INVALID_EQUIPMENT_PARAMETER_NAME': 'Ungültiger Geräteparametername',
      'API.INVALID_EQUIPMENT_PARAMETER_TYPE': 'Ungültiger Geräteparametertyp',
      'API.INVALID_EQUIPMENT_UUID': 'Ungültige Geräteuuid',
      'API.INVALID_EXPIRES_DATETIME': 'Invalid expires datetime',
      'API.INVALID_EXPRESSION': 'Ungültiger Ausdruck',
      'API.INVALID_EXPRESSION_OBJECT': 'Ungültiges Ausdrucksobjekt',
      'API.INVALID_FDD_CODE': 'Ungültiger FDD-Code',
      'API.INVALID_FLOORS_VALUE': 'Ungültiger Wert für Böden',
      'API.INVALID_FROM_ADDR': 'Ungültig von addr',
      'API.INVALID_GATEWAY_ID': 'Ungültige Gateway-ID',
      'API.INVALID_GATEWAY_NAME': 'Ungültiger Gatewayname',
      'API.INVALID_HEAT_METER_ID': 'Invalid heat meter id',
      'API.INVALID_HIGH_LIMIT_VALUE': 'Invalid high limit value',
      'API.INVALID_HOURLY_HIGH_LIMIT_VALUE': 'Ungültiger stündlich hoher Grenzwert',
      'API.INVALID_HOURLY_LOW_LIMIT_VALUE': 'Ungültiger stündlicher niedriger Grenzwert',
      'API.INVALID_Id': 'Invalid id',
      'API.INVALID_IS_ADMIN_VALUE': 'Ungültig ist admin-Wert',
      'API.INVALID_IS_COST_DATA_DISPLAYED': 'Invalid is cost data displayed',
      'API.INVALID_IS_COUNTED_VALUE': 'Ungültiger Wert wird gezählt',
      'API.INVALID_IS_ENABLED': 'Ungültig ist aktiviert',
      'API.INVALID_IS_HIDDEN': 'Ungültig ist ausgeblendet',
      'API.INVALID_IS_IN_LEASE_VALUE': 'Ungültig ist im Leasingwert',
      'API.INVALID_IS_INPUT_COUNTED_VALUE': 'Ungültig ist der gezählte Eingabewert',
      'API.INVALID_IS_KEY_TENANT_VALUE': 'Ungültig ist Schlüsselmandantenwert',
      'API.INVALID_IS_OUTPUT_COUNTED_VALUE': 'Ungültig ist der gezählte Ausgabewert',
      'API.INVALID_IS_OUTPUT_VALUE': 'Ungültig ist Ausgabewert',
      'API.INVALID_IS_READ_ONLY_VALUE': 'Invalid is read only value',
      'API.INVALID_IS_TREND_VALUE': 'Ungültig ist Trendwert',
      'API.INVALID_IS_VIRTUAL_VALUE': 'Ungültig ist virtueller Wert',
      'API.INVALID_KGCE': 'Ungültige kgce',
      'API.INVALID_KGCO2E': 'Ungültiges kgco2e',
      'API.INVALID_KNOWLEDGE_FILE_ID': 'Ungültige Wissensdatei-ID',
      'API.INVALID_LATITUDE_VALUE': 'Ungültiger Breitengradwert',
      'API.INVALID_LEASE_NUMBER_VALUE': 'Ungültiger Wert der Leasenummer',
      'API.INVALID_LONGITUDE_VALUE': 'Ungültiger Längengrad',
      'API.INVALID_LOW_LIMIT_VALUE': 'Ungültiger niedriger Grenzwert',
      'API.INVALID_MASTER_METER_ID': 'Ungültige Masterzähler-ID',
      'API.INVALID_MENU_ID': 'Ungültige Menü-ID',
      'API.INVALID_MESSAGE_DATA': 'Invalid message data',
      'API.INVALID_MESSAGE_TEMPLATE': 'Ungültige Nachrichtenvorlage',
      'API.INVALID_MESSAGE_TEMPLATE_ID': 'Invalid message template id',
      'API.INVALID_MESSAGE_VALUE': 'Invalid message value',
      'API.INVALID_METER_ID': 'Ungültige Zähler-ID',
      'API.INVALID_METER_NAME': 'Ungültiger Zählername',
      'API.INVALID_METER_UUID': 'ungültiger Meter UUID',
      'API.INVALID_MICROGRID_BATTERY_ID': 'Invalid microgrid battery id',
      'API.INVALID_MICROGRID_BATTERY_NAME': 'Invalid microgrid battery name',
      'API.INVALID_MICROGRID_EVCHARGER_ID': 'Invalid microgrid evcharger id',
      'API.INVALID_MICROGRID_EVCHARGER_NAME': 'Invalid microgrid evcharger name',
      'API.INVALID_MICROGRID_GENERATOR_ID': 'Invalid microgrid generator id',
      'API.INVALID_MICROGRID_GENERATOR_NAME': 'Invalid microgrid generator name',
      'API.INVALID_MICROGRID_GRID_ID': 'Invalid microgrid grid id',
      'API.INVALID_MICROGRID_GRID_NAME': 'Invalid microgrid grid name',
      'API.INVALID_MICROGRID_HEATPUMP_ID': 'Invalid microgrid heatpump id',
      'API.INVALID_MICROGRID_HEATPUMP_NAME': 'Invalid microgrid heatpump name',
      'API.INVALID_MICROGRID_ID': 'Invalid microgrid id',
      'API.INVALID_MICROGRID_LOAD_ID': 'Invalid microgrid load id',
      'API.INVALID_MICROGRID_LOAD_NAME': 'Invalid microgrid load name',
      'API.INVALID_MICROGRID_NAME': 'Invalid microgrid name',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_ID': 'Invalid microgrid photovoltaic id',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_NAME': 'Invalid microgrid photovoltaic name',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_ID': 'Invalid microgrid power conversion system ID',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_NAME': 'Invalid microgrid microgrid power conversion system name',
      'API.INVALID_MICROGRID_UUID': 'Invalid microgrid UUID',
      'API.INVALID_NAME_VALUE': 'Ungültiger Namenswert',
      'API.INVALID_NEW_PASSWORD': 'Ungültiges neues Kennwort',
      'API.INVALID_NON_WORKING_DAY_ID': 'Ungültige arbeitsfreie ID',
      'API.INVALID_NOTIFICATION_ID': 'Ungültige Benachrichtigungs-ID',
      'API.INVALID_NUMERATOR_METER_UUID': 'Ungültige Zählerzählerzähler-UUID',
      'API.INVALID_OBJECT_TYPE': 'Ungültiger Objekttyp',
      'API.INVALID_OFFLINE_METER_DATA': 'Ungültige Offline-Zählerdaten',
      'API.INVALID_OFFLINE_METER_FILE_ID': 'Ungültige Offline-Zählerdatei-ID',
      'API.INVALID_OFFLINE_METER_ID': 'Ungültige Offline-Zähler-ID',
      'API.INVALID_OFFLINE_METER_NAME': 'Ungültiger Offline-Zählername',
      'API.INVALID_OFFLINE_METER_UUID': 'Ungültige Offline-Zähler-uuid',
      'API.INVALID_OFFSET_CONSTANT_VALUE': 'Ungültiger Wert der Offset-Konstante',
      'API.INVALID_OLD_PASSWORD': 'Ungültiges altes Passwort',
      'API.INVALID_OPENID': 'Invalid openid',
      'API.INVALID_PARENT_SPACE_ID': 'Ungültige ID des übergeordneten Bereichs',
      'API.INVALID_PASSWORD': 'Ungültiges Kennwort',
      'API.INVALID_PAYLOAD': 'Invalid payload',
      'API.INVALID_PEAK_CURRENT': 'Ungültiger Spitzenstrom',
      'API.INVALID_PEAK_LOAD': 'Ungültige Spitzenlast',
      'API.INVALID_PERIOD_TYPE': 'Ungültiger Periodentyp',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_ID': 'Invalid photovoltaic power station ID',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_NAME': 'Invalid photovoltaic power station name',
      'API.INVALID_POINT_ID': 'Ungültige Punkt-ID',
      'API.INVALID_POINT_NAME': 'Ungültiger Punktname',
      'API.INVALID_PORT': 'Ungültiger Port',
      'API.INVALID_POSTAL_CODE_VALUE': 'Invalid postal code value',
      'API.INVALID_POWER_POINT_ID': 'Invalid power point id',
      'API.INVALID_PRIORITY': 'Ungültige Priorität',
      'API.INVALID_PRIVILEGE': 'Ungültige Berechtigung',
      'API.INVALID_PRIVILEGE_DATA': 'Ungültige Berechtigungsdaten',
      'API.INVALID_PRIVILEGE_ID': 'Ungültige Berechtigungs-ID',
      'API.INVALID_PRIVILEGE_NAME': 'Ungültiger Berechtigungsname',
      'API.INVALID_RATIO_VALUE': 'Ungültiger Verhältniswert',
      'API.INVALID_RECIPIENT_EMAIL': 'Invalid recipient email',
      'API.INVALID_RECIPIENT_MOBILE': 'Invalid recipient mobile',
      'API.INVALID_RECIPIENT_NAME': 'Invalid recipient name',
      'API.INVALID_RECIPIENT_OPENID': 'Invalid recipient openid',
      'API.INVALID_REPLY': 'Ungültige Antwort',
      'API.INVALID_REPORTING_PERIOD_END_DATETIME': 'Ungültige Berichtsperiode EnddatumUhrzeit',
      'API.INVALID_REPORTING_PERIOD_START_DATETIME': 'Startdatum des ungültigen Berichtszeitraums',
      'API.INVALID_REQUIRES_AUTHENTICATION': 'Ungültig erfordert Authentifizierung',
      'API.INVALID_ROOMS_VALUE': 'Ungültiger Zimmerwert',
      'API.INVALID_RULE_ID': 'Ungültige Regel-ID',
      'API.INVALID_RULE_NAME': 'Ungültiger Regelname',
      'API.INVALID_RUN_STATE_POINT_ID': 'Invalid run state point ID',
      'API.INVALID_SCHEDULED_DATETIME': 'Invalid scheduled datetime',
      'API.INVALID_SELL_METER_ID': 'Invalid sell meter id',
      'API.INVALID_SENSOR_ID': 'Ungültige Sensor-ID',
      'API.INVALID_SENSOR_NAME': 'Ungültiger Sensorname',
      'API.INVALID_SENSOR_UUID': 'Invalid sensor uuid',
      'API.INVALID_SERIAL_PORT': 'Ungültiger serieller Port',
      'API.INVALID_SESSION_PLEASE_RE_LOGIN': 'Ungültige Sitzung, bitte melden Sie sich erneut an',
      'API.INVALID_SET_VALUE': 'Invalid set value',
      'API.INVALID_SHOPFLOOR_ID': 'Ungültige Shopfloor-ID',
      'API.INVALID_SHOPFLOOR_NAME': 'Ungültiger Shopfloor-Name',
      'API.INVALID_SHOPFLOOR_UUID': 'Ungültige Shopfloor-uuid',
      'API.INVALID_SOURCE_NODE_ID': 'Ungültige Quellknoten-ID',
      'API.INVALID_SPACE_ID': 'Ungültige Speicherplatz-ID',
      'API.INVALID_SPACE_NAME': 'Ungültiger Leerzeichenname',
      'API.INVALID_SPACE_UUID': 'Ungültiges Leerzeichen uuid',
      'API.INVALID_START_DATE_FORMAT': 'Ungültiges Startdatumsformat',
      'API.INVALID_START_DATETIME': 'Ungültiges StartdatumUhrzeit',
      'API.INVALID_STATUS': 'Ungültiger Status',
      'API.INVALID_STORE_ID': 'Ungültige Store-ID',
      'API.INVALID_STORE_NAME': 'Ungültiger Store-Name',
      'API.INVALID_STORE_TYPE_DESCRIPTION': 'Beschreibung des ungültigen Shop-Typs',
      'API.INVALID_STORE_TYPE_ID': 'Ungültige Speichertyp-ID',
      'API.INVALID_STORE_TYPE_NAME': 'Ungültiger Name des Speichertyps',
      'API.INVALID_STORE_TYPE_SIMPLIFIED_CODE': 'Ungültiger vereinfachter Code für den Shop-Typ',
      'API.INVALID_STORE_UUID': 'Ungültige Speicher-uuid',
      'API.INVALID_SERIAL_NUMBER': 'Invalid serial number',
      'API.INVALID_SUBJECT_VALUE': 'Invalid subject value',
      'API.INVALID_SVG': 'Ungültiges SVG',
      'API.INVALID_SWITCHGEAR': 'Ungültige Schaltanlage',
      'API.INVALID_TARGET_NODE_ID': 'Ungültige Zielknoten-ID',
      'API.INVALID_TARIFF_BLOCK_PRICING': 'Ungültige Tarifpauschale',
      'API.INVALID_TARIFF_ID': 'Ungültige Tarif-ID',
      'API.INVALID_TARIFF_TIME_OF_USE_PRICING': 'Ungültiger Tarifpreis für Nutzungszeit',
      'API.INVALID_TARIFF_TYPE': 'Ungültiger Tariftyp',
      'API.INVALID_TENANT_ID': 'Ungültige Mandanten-ID',
      'API.INVALID_TENANT_NAME': 'Ungültiger Mandantenname',
      'API.INVALID_TENANT_TYPE_DESCRIPTION': 'Beschreibung des ungültigen Mandantentyps',
      'API.INVALID_TENANT_TYPE_ID': 'Ungültige Mandantentyp-ID',
      'API.INVALID_TENANT_TYPE_NAME': 'Ungültiger Mandantentypname',
      'API.INVALID_TENANT_TYPE_SIMPLIFIED_CODE': 'Ungültiger vereinfachter Mandantentyp',
      'API.INVALID_TENANT_UUID': 'Ungültige Mandanten-UUID',
      'API.INVALID_TEXT_MESSAGE_ID': 'Ungültige SMS-ID',
      'API.INVALID_TIMEZONE_ID': 'Ungültige Zeitzonen-ID',
      'API.INVALID_TOKEN': 'Ungültiges Token',
      'API.INVALID_TOPIC': 'Invalid topic',
      'API.INVALID_UNIT_OF_MEASURE': 'Ungültige Mengeneinheit',
      'API.INVALID_UNIT_OF_PRICE': 'Ungültige Preiseinheit',
      'API.INVALID_UNITS': 'Ungültige Einheiten',
      'API.INVALID_USER_ID': 'Ungültige Benutzer-ID',
      'API.INVALID_USER_NAME': 'Ungültiger Benutzername',
      'API.INVALID_USER_NAME_OR_EMAIL': 'Ungültiger Benutzername oder ungültige E-Mail-Adresse',
      'API.INVALID_USER_PHONE': 'Ungültiges Benutzertelefon',
      'API.INVALID_USER_PLEASE_RE_LOGIN': 'Ungültiger Benutzer, bitte erneut anmelden',
      'API.INVALID_USER_UUID': 'Ungültige Benutzer-UUID',
      'API.INVALID_USERNAME': 'Invalid userName',
      'API.INVALID_VARIABLE_METER_ID': 'Ungültige variable Zähler-ID',
      'API.INVALID_VARIABLE_METER_TYPE': 'Ungültiger variabler Zählertyp',
      'API.INVALID_VARIABLE_NAME': 'Ungültiger Variablenname',
      'API.INVALID_VERIFICATION_CODE': 'Ungültiger Bestätigungscode',
      'API.INVALID_VIRTUAL_METER_ID': 'Ungültige ID des virtuellen Zählers',
      'API.INVALID_VIRTUAL_METER_NAME': 'Ungültiger Name des virtuellen Zählers',
      'API.INVALID_VIRTUAL_METER_UUID': 'Ungültige uuid des virtuellen Zählers',
      'API.INVALID_WEB_MESSAGE_ID': 'Ungültige Webnachrichten-ID',
      'API.INVALID_WECHAT_MESSAGE_ID': 'Ungültige wechat-Nachrichten-ID',
      'API.INVALID_WIND_FARM_ID': 'Invalid wind farm ID',
      'API.INVALID_WIND_FARM_NAME': 'Invalid wind farm name',
      'API.INVALID_WORKING_CALENDAR_ID': 'Ungültige Arbeitskalender-ID ',
      'API.INVALID_WORKING_CALENDAR_NAME': 'Ungültiger Arbeitskalender Name',
      'API.KNOWLEDGE_FILE_CANNOT_BE_REMOVED_FROM_DISK': 'Wissensdatei kann nicht vom Datenträger entfernt werden',
      'API.KNOWLEDGE_FILE_NOT_FOUND': 'Wissensdatei nicht gefunden',
      'API.MASTER_METER_DOES_NOT_BELONG_TO_SAME_ENERGY_CATEGORY':
        'Masterzähler gehört nicht zur gleichen Energiekategorie',
      'API.MASTER_METER_NOT_FOUND': 'Mastermeter nicht gefunden',
      'API.MENU_NOT_FOUND': 'Menü nicht gefunden',
      'API.METER_CANNOT_HAVE_MORE_THAN_ONE_ENERGY_VALUE_POINTS': 'Meter cannot have more than one energy value points',
      'API.METER_COMMAND_RELATION_EXISTS': 'Meter command relation exists',
      'API.METER_COMMAND_RELATION_NOT_FOUND': 'Meter command relation not found',
      'API.METER_NAME_IS_ALREADY_IN_USE': 'Zählername wird bereits verwendet',
      'API.METER_NOT_FOUND': 'Meter nicht gefunden',
      'API.METER_OF_VARIABLE_NOT_FOUND': 'Meter der Variablen nicht gefunden',
      'API.METER_POINT_RELATION_EXISTS': 'Meterpunktbeziehung existiert',
      'API.METER_POINT_RELATION_NOT_FOUND': 'Meterpunktbeziehung nicht gefunden',
      'API.MICROGRID_BATTERY_NAME_IS_ALREADY_IN_USE': 'Microgrid battery name is already in use',
      'API.MICROGRID_BATTERY_NOT_FOUND': 'Microgrid battery not found',
      'API.MICROGRID_EVCHARGER_NAME_IS_ALREADY_IN_USE': 'Microgrid evcharger name is already in use',
      'API.MICROGRID_EVCHARGER_NOT_FOUND': 'Microgrid evcharger not fund',
      'API.MICROGRID_GENERATOR_NAME_IS_ALREADY_IN_USE': 'Microgrid generator name is already in use',
      'API.MICROGRID_GENERATOR_NOT_FOUND': 'Microgrid generator not found',
      'API.MICROGRID_GRID_NAME_IS_ALREADY_IN_USE': 'Microgrid grid name is already in use',
      'API.MICROGRID_GRID_NOT_FOUND': 'Microgrid grid not found',
      'API.MICROGRID_HEATPUMP_NAME_IS_ALREADY_IN_USE': 'Microgrid heatpump name is already in use',
      'API.MICROGRID_HEATPUMP_NOT_FOUND': 'Microgrid heatpump not found',
      'API.MICROGRID_LOAD_NAME_IS_ALREADY_IN_USE': 'Microgrid load name is already',
      'API.MICROGRID_LOAD_NOT_FOUND': 'Microgrid load not found',
      'API.MICROGRID_NAME_IS_ALREADY_IN_USE': 'Microgrid name is already in use',
      'API.MICROGRID_NOT_FOUND': 'Microgrid not found',
      'API.MICROGRID_PHOTOVOLTAIC_NAME_IS_ALREADY_IN_USE': 'Microgrid photovoltaic name is already in use',
      'API.MICROGRID_PHOTOVOLTAIC_NOT_FOUND': 'Microgrid photovoltaic not found',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NAME_IS_ALREADY_IN_USE':
        'Microgrid power conversion system name is already in use',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NOT_FOUND': 'Microgrid power conversion system not found',
      'API.MICROGRID_SENSOR_RELATION_EXISTS': 'Microgrid sensor relation exists',
      'API.MICROGRID_SENSOR_RELATION_NOT_FOUND': 'Microgrid sensor not found',
      'API.MQTT_CONNECTION_ERROR': 'Mqtt connection error',
      'API.MQTT_PUBLISH_ERROR': 'Mqtt publish error',
      'API.NEW_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS':
        'Die länge des neuen codes darf nicht mehr als 100 zeichen betragen',
      'API.NEW_USER_SESSION_NOT_FOUND': 'New user session not found',
      'API.NEW_USER_SESSION_TIMEOUT': 'New user session timeout',
      'API.NON_WORKING_DAY_NOT_FOUND': 'arbeitsfreier Tag nicht gefunden',
      'API.NOT_FOUND': 'Nicht gefunden',
      'API.NOTIFICATION_NOT_FOUND': 'Benachrichtigung nicht gefunden',
      'API.OFFLINE_METER_FILE_NOT_FOUND': 'Offline-Zählerdatei nicht gefunden',
      'API.OFFLINE_METER_NAME_IS_ALREADY_IN_USE': 'Der Name des Offline-Zählers wird bereits verwendet',
      'API.OFFLINE_METER_NOT_FOUND': 'Offline-Messgerät nicht gefunden',
      'API.OFFLINE_METER_OF_VARIABLE_NOT_FOUND': 'Offline-Zähler der Variablen nicht gefunden',
      'API.OLD_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS':
        'Die länge des alten codes darf nicht mehr als 100 zeichen betragen',
      'API.PARENT_SPACE_NOT_FOUND': 'Übergeordneter Speicherplatz nicht gefunden',
      'API.PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Die länge des codes darf keine 100 zeichen überschreiten',
      'API.PASSWORDS_MATCH': 'Das neue Passwort ist das gleiche wie das alte Passwort',
      'API.PHOTOVOLTAIC_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Photovoltaic power station name is already in use',
      'API.PHOTOVOLTAIC_POWER_STATION_NOT_FOUND': 'Photovoltaic power station not found',
      'API.POINT_NAME_IS_ALREADY_IN_USE': 'Punktname wird bereits verwendet',
      'API.POINT_NOT_FOUND': 'Punkt nicht gefunden',
      'API.POWER_POINT_NOT_FOUND': 'Power point not found',
      'API.PRIVILEGE_NAME_IS_ALREADY_IN_USE': 'Berechtigungsname wird bereits verwendet',
      'API.PRIVILEGE_NOT_FOUND': 'Berechtigung nicht gefunden',
      'API.RULE_NAME_IS_ALREADY_IN_USE': 'Regelname wird bereits verwendet',
      'API.RULE_NOT_FOUND': 'Regel nicht gefunden',
      'API.SELL_METER_NOT_FOUND': 'Sell meter not found',
      'API.SENSOR_NAME_IS_ALREADY_IN_USE': 'Sensorname wird bereits verwendet',
      'API.SENSOR_NOT_FOUND': 'Sensor nicht gefunden',
      'API.SENSOR_POINT_RELATION_EXISTS': 'Sensorpunktbeziehung existiert',
      'API.SENSOR_POINT_RELATION_NOT_FOUND': 'Sensorpunktbeziehung nicht gefunden',
      'API.SHOPFLOOR_COMMAND_RELATION_EXISTS': 'Shopfloor command relation exists',
      'API.SHOPFLOOR_COMMAND_RELATION_NOT_FOUND': 'Shopfloor command relation not found',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_EXISTS': 'Shopfloor Equipment Beziehung besteht',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_NOT_FOUND': 'Shopfloor Equipment Beziehung nicht gefunden',
      'API.SHOPFLOOR_METER_RELATION_EXISTS': 'Shopfloor Meter Beziehung besteht',
      'API.SHOPFLOOR_METER_RELATION_NOT_FOUND': 'Shopfloor Meter Beziehung nicht gefunden',
      'API.SHOPFLOOR_NAME_IS_ALREADY_IN_USE': 'Shopfloor-Name wird bereits verwendet',
      'API.SHOPFLOOR_NOT_FOUND': 'Shopfloor nicht gefunden',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_EXISTS': 'Shopfloor Offline-Zählerbeziehung existiert',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_NOT_FOUND': 'Shopfloor Offline-Zählerbeziehung nicht gefunden',
      'API.SHOPFLOOR_POINT_RELATION_EXISTS': 'Shopfloor-Punktbeziehung existiert',
      'API.SHOPFLOOR_POINT_RELATION_NOT_FOUND': 'Shopfloor-Punktbeziehung nicht gefunden',
      'API.SHOPFLOOR_SENSOR_RELATION_EXISTS': 'Shopfloor-Sensorbeziehung besteht',
      'API.SHOPFLOOR_SENSOR_RELATION_NOT_FOUND': 'Shopfloor-Sensorbeziehung nicht gefunden',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_EXISTS': 'Shopfloor virtuelle Zählerbeziehung existiert',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_NOT_FOUND': 'Shopfloor virtuelle Zählerbeziehung nicht gefunden',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_EXISTS': 'Es besteht eine Arbeitskalender-Shop-Beziehung',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Arbeitskalenderbeziehung für Shopfloor nicht gefunden',
      'API.SOC_POINT_NOT_FOUND': 'SOC point not found',
      'API.SOURCE_NODE_NOT_FOUND': 'Quellknoten nicht gefunden',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_EXISTS': 'Raumkombinierte Ausrüstungsbeziehung besteht',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_NOT_FOUND': 'Raumkombinierte Ausrüstungsbeziehung nicht gefunden',
      'API.SPACE_COMMAND_RELATION_EXISTS': 'Space command relation exists',
      'API.SPACE_COMMAND_RELATION_NOT_FOUND': 'Space command relation not found',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_EXISTS': 'Space Energy Storage Power Station Relation Exists',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_NOT_FOUND': 'Space Energy Storage Power Station Relation Not Found',
      'API.SPACE_EQUIPMENT_RELATION_EXISTS': 'Raumausrüstungsbeziehung besteht',
      'API.SPACE_EQUIPMENT_RELATION_NOT_FOUND': 'Beziehung zwischen Weltraumausrüstung nicht gefunden',
      'API.SPACE_METER_RELATION_EXISTS': 'Raummeterbeziehung existiert',
      'API.SPACE_METER_RELATION_NOT_FOUND': 'Space-Meter-Beziehung nicht gefunden',
      'API.SPACE_NAME_IS_ALREADY_IN_USE': 'Space-Name wird bereits verwendet',
      'API.SPACE_NOT_FOUND': 'Speicherplatz nicht gefunden',
      'API.SPACE_NOT_FOUND_IN_PRIVILEGE': 'Speicherplatz nicht im Privileg gefunden',
      'API.SPACE_OFFLINE_METER_RELATION_EXISTS': 'Space offline Meter Beziehung existiert',
      'API.SPACE_OFFLINE_METER_RELATION_NOT_FOUND': 'Speicherplatz-Offline-Zählerbeziehung nicht gefunden',
      'API.SPACE_POINT_RELATION_EXISTS': 'Raumpunktbeziehung existiert',
      'API.SPACE_POINT_RELATION_NOT_FOUND': 'Raumpunktbeziehung nicht gefunden',
      'API.SPACE_SENSOR_RELATION_EXISTS': 'Raumsensorbeziehung besteht',
      'API.SPACE_SENSOR_RELATION_NOT_FOUND': 'Raumsensorbeziehung nicht gefunden',
      'API.SPACE_SHOPFLOOR_RELATION_EXISTS': 'Raum-Shopfloor-Beziehung besteht',
      'API.SPACE_SHOPFLOOR_RELATION_NOT_FOUND': 'Raum-Shopfloor-Beziehung nicht gefunden',
      'API.SPACE_STORE_RELATION_EXISTS': 'Raumspeicherbeziehung existiert',
      'API.SPACE_STORE_RELATION_NOT_FOUND': 'Raumspeicherbeziehung nicht gefunden',
      'API.SPACE_TENANT_RELATION_EXISTS': 'Space-Mandantenbeziehung ist vorhanden',
      'API.SPACE_TENANT_RELATION_NOT_FOUND': 'Raummieterbeziehung nicht gefunden',
      'API.SPACE_VIRTUAL_METER_RELATION_EXISTS': 'Raumbezogene virtuelle Zählerbeziehung existiert',
      'API.SPACE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Virtuelle Zählerbeziehung für den Weltraum nicht gefunden',
      'API.SPACE_WORKING_CALENDAR_RELATION_EXISTS': 'Arbeitskalender-Space-Beziehung vorhanden',
      'API.SPACE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Arbeitskalenderbeziehung für Speicherplatz nicht gefunden',
      'API.START_DATETIME_MUST_BE_EARLIER_THAN_END_DATETIME': 'StartdatumUhrzeit muss früher als Enddatumszeit sein',
      'API.STORE_COMMAND_RELATION_EXISTS': 'Store command relation exists',
      'API.STORE_COMMAND_RELATION_NOT_FOUND': 'Store command relation not found',
      'API.STORE_METER_RELATION_EXISTS': 'Speicherzählerbeziehung vorhanden',
      'API.STORE_METER_RELATION_NOT_FOUND': 'Speicherzählerbeziehung nicht gefunden',
      'API.STORE_NAME_IS_ALREADY_IN_USE': 'Der Store-Name wird bereits verwendet',
      'API.STORE_NOT_FOUND': 'Shop nicht gefunden',
      'API.STORE_OFFLINE_METER_RELATION_EXISTS': 'Offline-Zählerbeziehung speichern ist vorhanden',
      'API.STORE_OFFLINE_METER_RELATION_NOT_FOUND': 'Offline-Zählerbeziehung speichern nicht gefunden',
      'API.STORE_POINT_RELATION_EXISTS': 'Speicherpunktbeziehung vorhanden',
      'API.STORE_POINT_RELATION_NOT_FOUND': 'Speicherpunktbeziehung nicht gefunden',
      'API.STORE_SENSOR_RELATION_EXISTS': 'Speichersensorbeziehung ist vorhanden',
      'API.STORE_SENSOR_RELATION_NOT_FOUND': 'Sensorbeziehung nicht gefunden',
      'API.STORE_TYPE_NAME_IS_ALREADY_IN_USE': 'Der Name des Geschäftstyps wird bereits verwendet',
      'API.STORE_TYPE_NOT_FOUND': 'Shop-Typ nicht gefunden',
      'API.STORE_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE':
        'Der vereinfachte Code für den Shop-Typ wird bereits verwendet',
      'API.STORE_TYPE_USED_IN_STORE': 'Im Store verwendeter Shop-Typ',
      'API.STORE_VIRTUAL_METER_RELATION_EXISTS': 'Virtuelle Zählerbeziehung speichern ist vorhanden',
      'API.STORE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Virtuelle Zählerbeziehung nicht gefunden',
      'API.STORE_WORKING_CALENDAR_RELATION_EXISTS': 'Es besteht eine Beziehung zum Arbeitskalenderspeicher',
      'API.STORE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Arbeitskalenderbeziehung für Speicher nicht gefunden',
      'API.TARGET_NODE_NOT_FOUND': 'Zielknoten nicht gefunden',
      'API.TARIFF_IN_USE': 'Tarif im Einsatz',
      'API.TARIFF_IS_ALREADY_ASSOCIATED_WITH_COST_CENTER': 'Tarif ist bereits mit Kostenstelle verknüpft',
      'API.TARIFF_IS_NOT_ASSOCIATED_WITH_COST_CENTER': 'Tarif ist nicht mit Kostenstelle verknüpft',
      'API.TARIFF_NAME_IS_ALREADY_IN_USE': 'Tarifname wird bereits verwendet',
      'API.TARIFF_NOT_FOUND': 'Tarif nicht gefunden',
      'API.TENANT_COMMAND_RELATION_EXISTS': 'Tenant command relation exists',
      'API.TENANT_COMMAND_RELATION_NOT_FOUND': 'Tenant command relation  not found',
      'API.TENANT_METER_RELATION_EXISTS': 'Mandantenzählerbeziehung vorhanden',
      'API.TENANT_METER_RELATION_NOT_FOUND': 'Mieterzählerbeziehung nicht gefunden',
      'API.TENANT_NAME_IS_ALREADY_IN_USE': 'Mandantenname wird bereits verwendet',
      'API.TENANT_NOT_FOUND': 'Mieter nicht gefunden',
      'API.TENANT_OFFLINE_METER_RELATION_EXISTS': 'Mandanten-Offlinezählerbeziehung ist vorhanden',
      'API.TENANT_OFFLINE_METER_RELATION_NOT_FOUND': 'Mandanten-Offlinezählerbeziehung nicht gefunden',
      'API.TENANT_POINT_RELATION_EXISTS': 'Mandantenpunktbeziehung ist vorhanden',
      'API.TENANT_POINT_RELATION_NOT_FOUND': 'Mandantenpunktbeziehung nicht gefunden',
      'API.TENANT_SENSOR_RELATION_EXISTS': 'Mandantensensorbeziehung ist vorhanden',
      'API.TENANT_SENSOR_RELATION_NOT_FOUND': 'Mandantensensorbeziehung nicht gefunden',
      'API.TENANT_TYPE_NAME_IS_ALREADY_IN_USE': 'Der Name des Mandantentyps wird bereits verwendet',
      'API.TENANT_TYPE_NOT_FOUND': 'Mandantentyp nicht gefunden',
      'API.TENANT_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Vereinfachter Mandantentyp wird bereits verwendet',
      'API.TENANT_TYPE_USED_IN_TENANT': 'Im Mandanten verwendeter Mandantentyp',
      'API.TENANT_VIRTUAL_METER_RELATION_EXISTS': 'Virtuelle Mandantenzählerbeziehung ist vorhanden',
      'API.TENANT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Virtuelle Mandantenzählerbeziehung nicht gefunden',
      'API.TENANT_WORKING_CALENDAR_RELATION_EXISTS': 'Es besteht eine funktionierende Kalendermandantenbeziehung',
      'API.TENANT_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Arbeitskalenderbeziehung für Mandant nicht gefunden',
      'API.TEXT_MESSAGE_NOT_FOUND': 'SMS nicht gefunden',
      'API.THE_REPORTING_PERIOD_MUST_BE_LONGER_THAN_15_MINUTES': 'Der Berichtszeitraum muss länger als 15 Minuten sein',
      'API.THERE_IS_RELATION_WITH_CHILD_METERS': 'Es besteht ein Zusammenhang mit Kinderzählern',
      'API.THERE_IS_RELATION_WITH_CHILDREN_SPACES': 'Es gibt eine Beziehung zu Kinderräumen',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENT_PARAMETERS':
        'Es besteht ein Zusammenhang mit kombinierten Ausrüstungsparametern',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENTS': 'Es besteht ein Zusammenhang mit kombinierten Ausrüstungen',
      'API.THERE_IS_RELATION_WITH_DATA_SOURCES': 'Es besteht ein Zusammenhang mit Datenquellen',
      'API.THERE_IS_RELATION_WITH_ENERGY_FLOW_DIAGRAM_LINKS':
        'Es besteht ein Zusammenhang mit Energieflussdiagramm-Links',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_BATTERIES': 'Es gibt einen Zusammenhang mit Energiespeicherbehältern Batterien',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_GRIDS': 'Es besteht ein Zusammenhang mit Energiespeichercontainern, Netzen',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_LOADS': 'Es besteht ein Zusammenhang mit den Lasten von Energiespeichercontainern',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_POWER_CONVERSION_SYSTEMS': 'Es besteht ein Zusammenhang mit Energiespeichercontainern Energieumwandlungssystemen',
      'API.THERE_IS_RELATION_WITH_EQUIPMENT_PARAMETERS': 'Es besteht ein Zusammenhang mit Geräteparametern',
      'API.THERE_IS_RELATION_WITH_EQUIPMENTS': 'Es besteht ein Zusammenhang mit Ausrüstungen',
      'API.THERE_IS_RELATION_WITH_METER': 'Es besteht ein Zusammenhang mit dem Zähler',
      'API.THERE_IS_RELATION_WITH_METERS': 'Es besteht ein Zusammenhang mit Zählern',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METER': 'Es besteht ein Zusammenhang mit dem Offline-Zähler',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METERS': 'Es besteht ein Zusammenhang mit Offline-Zählern',
      'API.THERE_IS_RELATION_WITH_OTHER_VIRTUAL_METERS': 'Es besteht ein Bezug zu anderen virtuellen Zählern',
      'API.THERE_IS_RELATION_WITH_SENSORS': 'Es besteht ein Zusammenhang mit Sensoren',
      'API.THERE_IS_RELATION_WITH_SHOPFLOORS': 'Es besteht ein Zusammenhang mit Shopfloors',
      'API.THERE_IS_RELATION_WITH_SPACES': 'Es gibt einen Bezug zu Räumen',
      'API.THERE_IS_RELATION_WITH_STORES': 'Es besteht eine Beziehung zu Geschäften',
      'API.THERE_IS_RELATION_WITH_TENANTS': 'Es besteht eine Beziehung zu den Mietern',
      'API.THERE_IS_RELATION_WITH_USERS': 'Es besteht eine Beziehung zu den Benutzern',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_METER': 'Es besteht ein Zusammenhang mit dem virtuellen Zähler',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_POWER_PLANTS': 'Es gibt Beziehungen zu virtuellen Kraftwerken',
      'API.THIS_DATA_SOURCE_IS_BEING_USED_BY_A_METER': 'Diese Datenquelle wird von einem Messgerät verwendet',
      'API.THIS_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'Dieser Zähler wird von einem virtuellen Zähler verwendet',
      'API.THIS_OFFLINE_METER_IS_BEING_USED_BY_A_VIRTUAL_METER':
        'Dieser Offline-Zähler wird von einem virtuellen Zähler verwendet',
      'API.THIS_SPACE_CANNOT_BE_CLONED': 'This space cannot be cloned',
      'API.THIS_SPACE_CANNOT_BE_DELETED': 'Dieser Speicherplatz kann nicht gelöscht werden',
      'API.TIMEZONE_NOT_FOUND': 'Zeitzone nicht gefunden',
      'API.TOKEN_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'Token nicht in Headern gefunden, bitte melden Sie sich an',
      'API.USER_ACCOUNT_HAS_BEEN_LOCKED': 'Benutzerkonto wurde gesperrt',
      'API.USER_ACCOUNT_HAS_EXPIRED': 'Benutzerkonto ist abgelaufen',
      'API.USER_ACCOUNT_IS_NOT_LOCKED': 'User account is not locked',
      'API.USER_NAME_IS_ALREADY_IN_USE': 'Der Benutzername wird bereits verwendet',
      'API.USER_NOT_FOUND': 'Benutzer nicht gefunden',
      'API.USER_PASSWORD_HAS_EXPIRED': 'Benutzerkennwort ist abgelaufen',
      'API.USER_PRIVILEGE_NOT_FOUND': 'Benutzerberechtigung nicht gefunden',
      'API.USER_SESSION_NOT_FOUND': 'Benutzersitzung nicht gefunden',
      'API.USER_SESSION_TIMEOUT': 'Zeitüberschreitung der Benutzersitzung',
      'API.USER_UUID_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN':
        'Benutzer uuid nicht in Headern gefunden Bitte melden Sie sich an',
      'API.VIRTUAL_METER_NAME_IS_ALREADY_IN_USE': 'Der Name des virtuellen Zählers wird bereits verwendet',
      'API.VIRTUAL_METER_NOT_FOUND': 'Virtueller Zähler nicht gefunden',
      'API.VIRTUAL_METER_OF_VARIABLE_NOT_FOUND': 'Virtueller Zähler der Variablen nicht gefunden',
      'API.VIRTUAL_POINT_SHOULD_BE_ANALOG_VALUE': 'Virtueller Punkt sollte analoger Wert sein',
      'API.WEB_MESSAGE_NOT_FOUND': 'Webnachricht nicht gefunden',
      'API.WECHAT_MESSAGE_NOT_FOUND': 'Wechat-Nachricht nicht gefunden',
      'API.WIND_FARM_NAME_IS_ALREADY_IN_USE': 'Wind farm name is already in use',
      'API.WIND_FARM_NOT_FOUND': 'Wind farm not found',
      'API.WORKING_CALENDAR_NAME_IS_ALREADY_IN_USE': 'Der Name des Arbeitskalenders wird bereits verwendet',
      'API.WORKING_CALENDAR_NOT_FOUND': 'Arbeitskalender nicht gefunden',

      MasterMeter: 'Übersichtstabelle',
      SubMeter: 'Subtable'
    }
  },
  fr: {
    translation: {
      // routes & menus
      Dashboard: 'Tableau de bord',
      'Space Data': 'Espace',
      'Equipment Data': 'Équipement',
      'Meter Data': 'Mètre',
      'Tenant Data': 'Locataire',
      'Store Data': 'Boutique',
      'Shopfloor Data': 'Atelier',
      'Combined Equipment Data': 'Équipement combiné',
      'Auxiliary System': 'Système auxiliaire',
      Microgrid: 'Micro-réseau',
      'Energy Storage Power Station': 'Centrale de stockage d’énergie',
      'Photovoltaic Power Station': 'Power Station',
      'Fault Alarm': 'Alarme de panne',
      Monitoring: 'Surveillance',
      'Advanced Reporting': 'Rapports avancés',
      'Knowledge Base': 'Base de connaissances',
      'Energy Category Data': 'Catégorie d’énergie',
      'Energy Item Data': 'Élément d’énergie',
      Carbon: 'Émissions de CO2',
      Cost: 'Coût',
      Output: 'Sortie',
      Income: 'Revenu',
      Efficiency: 'Efficacité',
      Load: 'Charger',
      Statistics: 'Statistiques',
      'Batch Analysis': 'Analyse des lots',
      Saving: 'Épargne',
      Plan: 'Plan',
      'Production': 'Production',
      'Space Production': 'Space Production',
      'Equipment Tracking': 'Suivi de l’équipement',
      'Environment Monitor': 'Moniteur d’environnement',
      'Meter Energy': 'Compteur d’énergie',
      'Meter Carbon': 'Computer Émissions de CO2',
      'Meter Cost': 'Compteur Coût',
      'Meter Trend': 'Compteur Tendance',
      'Meter Realtime': 'Compteur en temps réel',
      'Meter Saving': 'Compteur Économie',
      'Meter Plan': 'plan de compteur',
      'Master Meter Submeters Balance': 'Balance des sous-compteurs du compteur maître',
      'Meter Batch Analysis': 'Compteur Analyse des lots ',
      'Meter Comparison': 'Compteurs Comparaison',
      'Meter Tracking': 'Compteurs Suivi',
      'Virtual Meter Saving': 'Compteur Virtuel Économie',
      'Virtual Meter Plan': 'Plan de compteur virtuel',
      'Virtual Meter Energy': 'Compteur Virtuel d’énergie',
      'Virtual Meter Carbon': 'Compteur Virtuel Émissions de CO2',
      'Virtual Meter Cost': 'Compteur Virtuel Coût',
      'Virtual Meter Batch Analysis': 'Analyse par lots de tables virtuelles',
      'Offline Meter Energy': 'Compteur Hors Ligne d’énergie',
      'Offline Meter Carbon': 'Compteur Hors Ligne Émissions de CO2',
      'Offline Meter Cost': 'Compteur Hors Ligne Coût',
      'Offline Meter Batch Analysis': 'Analyse des lots de compteurs hors ligne',
      'Offline Meter Saving': 'Compteur Hors Ligne Économie',
      'Offline Meter Plan': 'Plan de compteur hors ligne',
      'Offline Meter Input': 'Compteur Hors Ligne Entrée',
      'Tenant Bill': 'Locataire Facture',
      'Energy Flow Diagram': 'Diagramme de flux d’énergie',
      'Distribution System': 'Réseau de distribution',
      'SVG System': 'Systèmes de visualisation SCADA Ⓔ',
      'Faults Data': 'Défauts',
      'Space Equipments': 'Espace Équipements',
      'Combined Equipments': 'Combinés Équipements',
      'Energy Storage Power Station List': 'List',
      'Energy Storage Power Station Details': 'Details',
      'Energy Storage Power Station Reporting': 'Reporting',
      'Energy Storage Power Station Alarm': 'Alarm',
      'Energy Storage Power Station Maintenance': 'Maintenance',
      'Enter Production': 'Enter Production',
      'Multiple Energy Storage Power Stations': 'Multiple Power Stations',
      'Multiple Energy Storage Power Stations Dashboard': 'Dashboard',
      'Multiple Energy Storage Power Stations List': 'List',
      'Single Energy Storage Power Station': 'Single Power Station',
      'Single Energy Storage Power Station Dashboard': 'Dashboard',
      'Single Energy Storage Power Station Details': 'Details',
      'Single Energy Storage Power Station Fault Alarm': 'Fault Alarm',
      'Energy Storage Power Station Reports': 'Power Station Reports',
      'Energy Storage Power Station Reports Energy': 'Energy',
      'Energy Storage Power Station Reports Revenue': 'Revenue',
      'Energy Storage Power Station Reports Parameters': 'Parameters',
      'Photovoltaic Power Station List': 'List',
      'Photovoltaic Power Station Details': 'Details',
      'Photovoltaic Power Station Reporting': 'Reporting',
      'Photovoltaic Power Station Alarm': 'Alarm',
      'Photovoltaic Power Station Maintenance': 'Maintenance',
      'Enter Production': 'Enter Production',
      'Multiple Photovoltaic Power Stations': 'Multiple Power Stations',
      'Multiple Photovoltaic Power Stations Dashboard': 'Dashboard',
      'Multiple Photovoltaic Power Stations List': 'List',
      'Single Photovoltaic Power Station': 'Single Power Station',
      'Single Photovoltaic Power Station Dashboard': 'Dashboard',
      'Single Photovoltaic Power Station Details': 'Details',
      'Single Photovoltaic Power Station Fault Alarm': 'Fault Alarm',
      'Photovoltaic Power Station Reports': 'Power Station Reports',
      'Photovoltaic Power Station Reports Energy': 'Energy',
      'Photovoltaic Power Station Reports Revenue': 'Revenue',
      'Photovoltaic Power Station Reports Parameters': 'Parameters',
      'Work Order': 'Work Order',
      'Work Order Installation': 'Installation',
      'Work Order Repair': ' Repair',
      'Work Order Inspection': 'Inspection',
      'Total Number of Work Orders': 'Nombre total de bons de travail',
      'Number of New Work Orders': 'Nombre de nouveaux bons de travail',
      'Number of Inprogress Work Orders': 'Nombre de bons de travail en cours',
      'Number of Done Work Orders': 'Nombre de bons de travail terminés',
      'Charge Energy Indicator': 'Énergie de charge',
      'Discharge Energy Indicator': 'Énergie de décharge',
      'Charge Cost Indicator': 'Coût de charge',
      'Discharge Revenue Indicator': 'Revenu de décharge',
      'HVAC': 'HVAC',
      'Fire Control': 'Lutte contre les incendies',

      // Dashboard
      'Welcome to DoraEOS': 'Bienvenue chez DoraEOS',
      'An Industry Leading Open Source Energy Management System':
        'Un système de gestion de l’énergie open source leader de l’industrie',
      "This Year's Consumption CATEGORY VALUE UNIT": 'La consommation de cette année {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Year's Costs CATEGORY VALUE UNIT": 'Les coûts de cette année {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Consumption CATEGORY VALUE UNIT": 'Ce mois-ci Consumption {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Costs CATEGORY VALUE UNIT": 'Coûts de ce mois-ci {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Consumption CATEGORY VALUE UNIT in The Same Period Last Year':
        'Consommation {{CATEGORY}} {{VALUE}} {{UNIT}} à la même période l’an dernier',
      'Costs CATEGORY VALUE UNIT in The Same Period Last Year':
        'Coûts {{CATEGORY}} {{VALUE}} {{UNIT}} à la même période l’an dernier',
      'CATEGORY Consumption UNIT': '{{CATEGORY}} Consommation {{UNIT}}',
      'CATEGORY Costs UNIT': '{{CATEGORY}} Coûts {{UNIT}}',
      // Entities
      Space: 'Espace',
      Equipment: 'Équipements',
      Meter: 'Compteur',
      'Offline Meter': 'Compteur Hors Ligne',
      'Virtual Meter': 'Compteur Virtuel',
      Tenant: 'Locataire',
      Store: 'Boutique',
      Shopfloor: 'Atelier',
      'Combined Equipment': 'Équipement combiné',
      'Cost Center': 'Centre de coûts',
      Name: 'Nom',
      DisplayName: 'Montrer Nom',
      UserName: 'Utilisateur Nom',
      Description: 'Description',
      'Energy Category': 'Catégorie d’énergie',
      'Child Spaces': 'Espaces enfants',
      'Associated Equipment': 'Équipement connexe',
      //Energy Categories
      Electricity: 'Électricité',
      Water: 'Eau',
      'Natural Gas': 'Naturel Gaz',
      // SidePanelModal
      Settings: 'Paramètres',
      'Set your own customized style': 'Définissez votre propre style personnalisé',
      'Color Scheme': 'Couleur Schéma',
      'Choose the perfect color mode for your app': 'Choisissez le mode de couleur parfait pour votre application',
      Light: 'Lumière',
      Dark: 'Sombre',
      'RTL Mode': 'RTL Mode',
      'Switch your language direction': 'Changez de langue',
      'Fluid Layout': 'Disposition fluide',
      'Toggle container layout system': 'Système de disposition de conteneur bascule',
      'Navigation Position': 'Navigation Position',
      'Select a suitable navigation system for your web application':
        'Sélectionnez un système de navigation adapté à votre application Web',
      'Vertical Nav': 'Navigation verticale',
      'Top Nav': 'Navigation supérieure',
      'Vertical Navbar Style': 'Style de barre de navigation verticale',
      'Switch between styles for your vertical navbar':
        'Basculer entre les styles pour votre barre de navigation verticale',
      Language: 'Langue',
      'Switch between languages': 'Basculer entre les langues',
      'language-zh_CN': '简体中文',
      'language-en': 'English',
      'language-de': 'Deutsch',
      'language-fr': 'Français',
      'language-es': 'Español',
      'language-ru': 'Русский',
      'language-ar': 'العربية',
      'language-vi': 'Tiếng Việt',
      'language-th': 'ภาษาไทย',
      'language-tr': 'Türkçe',
      'language-ms': 'Bahasa Melayu',
      'language-id': 'Bahasa Indonesia',
      'language-zh_TW': '繁體中文',
      'Like What You See?': 'Vous aimez ce que vous voyez ?',
      'Get DoraEOS now': 'Avoir DoraEOS Maintenant',
      Purchase: 'Achat',
      // Query Panel
      'Base Period Begins': 'Base Période Commence',
      'Base Period Ends': 'Base Période Finit',
      'Comparison Types': 'Comparaison Types',
      'Year-Over-Year': 'D’une année à l’autre',
      'Month-On-Month': 'Mois sur mois',
      'Free Comparison': 'Libre Comparaison',
      'None Comparison': 'Aucun Comparaison',
      'Reporting Period Begins': 'Rapports Période Commence',
      'Reporting Period Ends': 'Rapports Période Finit',
      '(Optional)': '(Optionnel)',
      'Period Types': 'Période Types',
      Yearly: 'Annuel',
      Monthly: 'Mensuel',
      Weekly: 'Hebdomadaire',
      Daily: 'Quotidien',
      Hourly: 'Horaire',
      Submit: 'Envoyer',
      'Input Energy Category': 'Entrée Énergie Catégorie',
      'Output Energy Category': 'Sortie Énergie Catégorie',
      'Fraction Parameter': 'Fraction Paramètre',
      Search: 'Rechercher',
      //DateRangePicker
      sunday: 'Su',
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      ok: 'D’ACCORD',
      today: 'Aujourd’hui',
      yesterday: 'Hier',
      hours: 'Heures',
      minutes: 'Compte-rendu',
      seconds: 'Secondes',
      last7Days: '7 derniers jours',
      'Select Date Range': 'Sélectionnez une plage de dates',
      //Card Summaries and Line Charts
      'Base Period': 'Base Période',
      'Reporting Period': 'Rapports Période',
      'Per Unit Area': 'Par unité de surface',
      'Per Capita': 'Per Capita',
      'Per Unit Production': 'Production unitaire',
      Baseline: 'Ligne de base',
      Actual: 'Effective',
      'Baseline Value - Actual Value': 'Ligne de base - Effective',
      'Average Load': 'Moyen Charger',
      'Maximum Load': 'Maximum Charger',
      'Load Factor': 'Charger Facteur',
      'Ratio of Average Load to Maximum Load': 'Rapport entre la charge moyenne et la charge maximale',
      'Carbon Dioxide Emissions by Energy Category': 'Émissions de dioxyde de carbone par catégorie d’énergie',
      'Costs by Energy Category': 'Coûts par catégorie d’énergie',
      'Incomes by Energy Category': 'Revenus par catégorie d’énergie',
      'Electricity Consumption by Time-Of-Use': 'Consommation d’électricité selon l’heure de consommation',
      'Electricity Carbon Dioxide Emissions by Time-Of-Use':
        'Électricité Émissions de dioxyde de carbone selon l’heure de consommation',
      'Electricity Cost by Time-Of-Use': 'Coût de l’électricité selon l’heure de consommation',
      'CATEGORY UNIT Consumption by Energy Items': '{{CATEGORY}} {{UNIT}} Consommation par éléments énergétiques',
      'Ton of Standard Coal by Energy Category': 'Tonne de charbon standard (TCE) par catégorie d’énergie',
      'Ton of Carbon Dioxide Emissions by Energy Category':
        'Tonnes d’émissions de dioxyde de carbone (TCO2E) par catégorie d’énergie',
      'Reporting Period Consumption CATEGORY UNIT': 'Rapports Période Consommation {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{EQUIPMENT}} Rapports Période Consommation {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Rapports Période Consommation {{CATEGORY}} {{UNIT}}',
      'CATEGORY VALUE UNIT': '{{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption CATEGORY VALUE UNIT':
        'Rapports Période Consommation {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Production PRODUCT VALUE UNIT': 'Reporting Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Production PRODUCT VALUE UNIT': 'Base Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption CATEGORY VALUE UNIT': 'Base Période Consommation {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY UNIT': 'Rapports Période Consommation {{ITEM}} {{CATEGORY}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY VALUE UNIT':
        'Rapports Période Consommation {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption ITEM CATEGORY VALUE UNIT':
        'Base Période Consommation {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Child Space Proportion CATEGORY UNIT': 'Espace enfant Proportion par catégorie d’énergie {{CATEGORY}} {{UNIT}}',
      'Child Space Total Proportion': 'Espace enfant Total Proportion',
      'Reporting Period Carbon Dioxide Emissions CATEGORY UNIT':
        'Rapports Période Émissions de dioxyde de carbone {{CATEGORY}} {{UNIT}}',
      'Reporting Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Rapports Période Émissions de dioxyde de carbone {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Base Période Émissions de dioxyde de carbone {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Costs CATEGORY UNIT': 'Rapports Période Coûts {{CATEGORY}} {{UNIT}}',
      'Reporting Period Costs CATEGORY VALUE UNIT': 'Rapports Période Coûts {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Costs CATEGORY VALUE UNIT': 'Base Période Coûts {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Output CATEGORY UNIT': 'Rapports Période Sortie {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Output CATEGORY UNIT': '{{EQUIPMENT}} Rapports Période Sortie {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Rapports Période Sortie {{CATEGORY}} {{UNIT}}',
      'Reporting Period Output CATEGORY VALUE UNIT': 'Rapports Période Sortie {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Output CATEGORY VALUE UNIT': 'Base Période Sortie {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Income CATEGORY UNIT': 'Rapports Période Revenu {{CATEGORY}} {{UNIT}}',
      'Reporting Period Income CATEGORY VALUE UNIT': 'Rapports Période Revenu {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Income CATEGORY VALUE UNIT': 'Base Période Revenu {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Total Income UNIT': 'Rapports Période Total Revenu {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME UNIT': 'Rapports Période Efficacité Cumulative {{NAME}} {{UNIT}}',
      'EQUIPMENT Reporting Period Cumulative Efficiency UNIT':
        '{{EQUIPMENT}} Rapports Période Efficacité Cumulative {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Rapports Période Efficacité Globale Cumulée {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Rapports Période Efficacité Globale Cumulée {{VALUE}} {{UNIT}}',
      'COMBINED_EQUIPMENT Base Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Base Période Efficacité Globale Cumulée {{VALUE}} {{UNIT}}',
      'Instantaneous Efficiency UNIT': 'Instantané Efficacité {{UNIT}}',
      'EQUIPMENT Instantaneous Efficiency UNIT': '{{EQUIPMENT}} Instantané Efficacité {{UNIT}}',
      'COMBINED_EQUIPMENT Instantaneous Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Instantané Complet Efficacité {{UNIT}}',
      'Reporting Period Cumulative Efficiency VALUE UNIT': 'Rapports Période Cumulatif Efficacité {{VALUE}} {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME VALUE UNIT':
        'Rapports Période Cumulatif Efficacité {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency VALUE UNIT': 'Base Période Cumulatif Efficacité {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency NAME VALUE UNIT':
        'Base Période Cumulatif Efficacité {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period CATEGORY Maximum Load UNIT': 'Base Période {{CATEGORY}} Maximum Charger {{UNIT}}',
      'Reporting Period CATEGORY Maximum Load UNIT': 'Rapports Période {{CATEGORY}} Maximum Charger {{UNIT}}',
      'Reporting Period CATEGORY Average Load UNIT': 'Rapports Période {{CATEGORY}} Moyen Charger {{UNIT}}',
      'Reporting Period CATEGORY Load Factor': 'Rapports Période{{CATEGORY}} Charger Facteur',
      'Base Period CATEGORY Average Load VALUE UNIT': 'Base Période {{CATEGORY}} Moyen Charger {{VALUE}} {{UNIT}}',
      'Reporting Period CATEGORY Average UNIT': 'Rapports Période {{CATEGORY}} Moyen Valeur {{UNIT}}',
      'Reporting Period CATEGORY Maximum UNIT': 'Rapports Période {{CATEGORY}} Maximum Valeur {{UNIT}}',
      'Reporting Period CATEGORY Minimum UNIT': 'Rapports Période {{CATEGORY}} Minimum Valeur {{UNIT}}',
      'Reporting Period CATEGORY Mean UNIT': 'Rapports Période {{CATEGORY}} Arithmétique Méchant {{UNIT}}',
      'Reporting Period CATEGORY Median UNIT': 'Rapports Période {{CATEGORY}} Médian (Milieu Valeur) {{UNIT}}',
      'Reporting Period CATEGORY Stdev UNIT': 'Rapports Période {{CATEGORY}} Échantillon Standard Déviation {{UNIT}}',
      'Reporting Period CATEGORY Variance UNIT': 'Rapports Période {{CATEGORY}} Échantillon Variance {{UNIT}}',
      'Reporting Period Saving CATEGORY (Baseline - Actual) UNIT':
        'Rapports Période Épargne {{CATEGORY}} (Ligne de base - Effective) {{UNIT}}',
      'Reporting Period Decreased CATEGORY (Baseline - Actual) UNIT':
        'Rapports Période Diminué {{CATEGORY}} (Ligne de base - Effective) {{UNIT}}',
      'Reporting Period Saving CATEGORY VALUE UNIT': 'Rapports Période Épargne {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Saving CATEGORY VALUE UNIT': 'Base Période Épargne {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY UNIT':
        'Rapports Période Maître Compteur Consommation {{CATEGORY}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY VALUE UNIT':
        'Rapports Période Maître Compteur Consommation {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY UNIT':
        'Rapports Période Sous-Compteur Consommation {{CATEGORY}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY VALUE UNIT':
        'Rapports Période Sous-Compteur Consommation {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Difference CATEGORY UNIT': 'Rapports Période Difference {{CATEGORY}} {{UNIT}}',
      'Reporting Period Percentage Difference': 'Rapports Période Pourcentage Difference',
      'Reporting Period Difference CATEGORY VALUE UNIT': 'Rapports Période Difference {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Trend Values': 'Tendance Valeurs',
      'METER CATEGORY VALUE UNIT': '{{METER}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      //FDD
      'Fault List': 'Faute Liste',
      'Reporting Period Number of Fault VALUE': 'Rapports Période Nombre de Faute {{VALUE}}',
      'All Faults': 'Tout Fautes',
      'Space Faults': 'Espace Fautes',
      'Number of All Faults': 'Nombre de Tout Fautes',
      'Number of Space Faults': 'Nombre de Espace Fautes',
      'Total Number of Faults': 'Total Number of Faults',
      'Number of New Faults': 'Number of New Faults',
      'Number of Inprogress Faults': 'Number of Inprogress Faults',
      'Number of Done Faults': 'Number of Done Faults',
      //Data Panels
      'Ton of Standard Coal': 'Tonne de Charbon standard',
      'Ton of Carbon Dioxide Emissions': 'Tonne de Émissions de dioxyde de carbone',
      'Operating Characteristic Curve': 'Courbe des caractéristiques de travail',
      Tariff: 'Tarif',
      'Detailed Data': 'Détaillé Données',
      'Child Spaces Data': 'Enfant Espaces Données',
      'Associated Equipment Data': 'Associé Équipement Données',
      'This Year': 'Cette année',
      'The Same Period Last Year': 'La même période l’an dernier',
      'This Month': 'Ce mois-ci',
      'This Day': 'Ce jour',
      Total: 'Total',
      'No data found': 'Non Données fonder',
      Export: 'Exportation',
      Datetime: 'Datetime',
      'Time-Of-Use Type': 'Heure d’utilisation Type',
      'Top-Peak': 'Top-Peak',
      'On-Peak': 'On-Peak',
      'Mid-Peak': 'Mid-Peak',
      'Off-Peak': 'Off-Peak',
      Percentage: 'Pourcentage',
      //Realtime Chart
      'Trend in the last hour of Energy Value Point': 'Tendance dans la dernière heure d’Energy Value Point',
      Circuit: 'Circuit',
      Point: 'Point',
      'Realtime Value': 'Temps réel Valeur',
      //Meter Tracking
      'Meter List': 'Compteur Liste',
      'Edit Meter': 'Éditer',
      'Start Value': 'Commencer Valeur',
      'End Value': 'Fin Valeur',
      'Difference Value': 'Différence Valeur',
      'Start Integrity Rate': 'Commencer Intégrité Taux(%)',
      'End Integrity Rate': 'Fin Intégrité Taux(%)',
      'Full Integrity Rate': 'Plein Intégrité Taux(%)',
      //Equipment Tracking
      'Equipment List': 'Équipement Liste',
      'Edit Equipment': 'Éditer',
      //Profile Dropdown
      Feedback: 'Rétroaction',
      'Account Settings': 'Compte Paramètres',
      Logout: 'Déconnexion',
      //Authentication
      'Log in': "S'identifier",
      'Email address': 'Messagerie électronique adresse',
      Password: 'Mot de passe',
      CaptchaCode: 'Captcha',
      Refresh: 'Rafraîchir',
      'Remember me': 'Se souvenir me',
      'Logged in as ': 'Connecté dans comme ',
      'Forgot Password?': 'Oublié Mot de passe?',
      'Forgot your password?': 'Oublié votre Mot de passe?',
      "Enter your email and we'll send you a reset link":
        'Entrez votre adresse e-mail et nous vous enverrons un lien de réinitialisation',
      "Enter your email and we'll send you a registration link":
        'Entrez votre adresse e-mail et nous vous enverrons un lien d’inscription',
      'Reset Password': 'Réinitialisation Mot de passe',
      'Thanks for using DoraEOS!': 'Merci d’utiliser DoraEOS!',
      'You are now successfully signed out': 'Vous êtes maintenant déconnecté avec succès',
      'Return to Login': 'Rendre À Connectez-vous',
      'Please check your email!': 'S’il vous plaît vérifier votre e-mail!',
      'An email has been sent to ': 'Un e-mail a été envoyé à',
      'Please click on the included link to reset your password':
        'Veuillez cliquer sur le lien inclus pour réinitialiser votre mot de passe',
      'Please click on the included link to register your account':
        'Veuillez cliquer sur le lien inclus pour enregistrer votre compte',
      'An email with password reset link is sent to ':
        'Un e-mail avec un lien de réinitialisation du mot de passe est envoyé à ',
      'Change Password': 'Changement Mot de passe',
      'Old Password': 'Vieux Mot de passe',
      'New Password': 'Nouveau Mot de passe',
      'Confirm Password': 'Confirmer Mot de passe',
      'Update Password': 'Mettre à jour Mot de passe',
      'Password has been changed!': 'Le mot de passe a été changé!',
      'Verification code has been sent to your inbox. \
      Please copy it to the input box below..':
        'Le code de vérification a été envoyé dans votre boîte de réception. \
      Veuillez le copier dans la zone de saisie ci-dessous.',
      'New to DoraEOS': 'Nouveau sur DoraEOS',
      'Create an account': 'Créer un compte',
      'EMAIL Account registration successful': '{{EMAIL}} Compte inscription réussi',
      'Thanks for verifying your account!': 'Merci d’avoir vérifié votre compte!',
      'Your code is': 'Votre code est',
      'Send verification code': 'Envoyer le code de vérification',
      'Please wait for NUMBER seconds': 'Veuillez patienter {{NUMBER}} Secondes',
      'Password reset': 'Mot de passe réinitialisation',
      'Please wait for approval': 'Veuillez attendre l’approbation',
      //notification
      Notifications: 'Notifications',
      'Mark all as read': 'Marquer tout comme lu',
      'View all': 'Tout',
      'Notification New': 'NOUVEAU',
      'Notification Earlier': 'PLUS TÔT',
      'Notification List': 'Liste des notifications',
      'Bulk actions': 'Actions groupées',
      'Notification Subject': 'Subject',
      'Notification Created Datetime': 'Date/heure de création',
      'Notification Priority': 'Priorité',
      'Notification Message': 'Message',
      'Notification Status': 'Statut',
      'Notification Mark As Read': 'Marquer comme lu',
      'Notification Mark As Acknowledged': 'Reconnaître',
      'Notification Low': 'Bas',
      'Notification Medium': 'Douleur moyenne',
      'Notification High': 'Haut',
      'Notification Critical': 'Critique',
      'Notification Unread': 'Non lu',
      'Notification Read': 'Lire',
      'Notification Acknowledged': 'Reconnu',
      'Notification Archive': 'Archiver',
      'Notification Delete': 'Supprimer',
      'Notification Apply': 'Appliquer',
      'Notification Start Datetime': 'Dateheure de début',
      'Notification End Datetime': 'Dateheure de fin',
      'Notification Update Datetime': 'Mettre à jour la date et l\'heure',
      //Monitoring
      'Run Commands': 'Exécuter des commandes',
      'Fault Alarms': 'Alarmes de panne',
      'Instantaneous Efficiency VALUE UNIT': 'Instantané Efficacité {{VALUE}} {{UNIT}}',
      'Communication Online': 'En ligne',
      'Communication Offline': 'Hors-ligne',
      'Equipment Status': 'État de l’équipement',
      'Equipment Running': 'Course',
      'Equipment Stopped': 'Arrêté',
      'Show Up to': 'Présentez-vous à',
      All: 'Tout',
      'FROM - TO of TOTAL': 'Affichage actuel {{FROM}} - {{TO}} total {{TOTAL}}',
      //Microgrid
      'PCS Run State': 'État de fonctionnement du PCS',
      'PCS Unknown': 'Inconnu',
      'PCS Initializing': 'Initialisation en cours',
      'PCS Standby': 'Articles de rechange',
      'PCS Shutdown': 'Temps d’arrêt',
      'PCS Fault': 'La faute',
      'PCS Running': 'Courir',
      'Battery Power': 'Puissance de la batterie',
      'Photovoltaic Power': 'Puissance photovoltaïque',
      'Grid Power': 'Puissance du réseau électrique',
      'Load Power': 'Puissance de charge',
      Reporting: 'Rapport',
      Maintenance: 'Réparation',
      'Serial Number': 'Numéro de série',
      Address: 'Adresse',
      'Postal Code': 'Code Postal',
      'Rated Capacity': 'Capacité nominale',
      'Rated Power': 'Puissance nominale',
      Latitude: 'Latitude',
      Longitude: 'Longitude',
      'Strategy Management': 'Gestion des politiques',
      'Charging Schedule': 'Charging Schedule',
      'Charge Start Time': 'Charge début temps',
      'Charge End Time': 'Charge end Time',
      'Discharge Start Time': 'Le Discharge start time',
      'Discharge End Time': 'Le Discharge end Time',
      'Total Rated Power': 'Puissance totale',
      'Total Rated Capacity': 'Capacité nominale totale',
      "Today's Charge": "Volume de charge aujourd'hui",
      "Today's Discharge": "Décharge aujourd'hui",
      "Today's Generation": "La production d’électricité d’aujourd’hui",
      'Total Charge': 'Charge totale',
      'Total Discharge': 'décharge totale',
      'Total Generation': 'Génération totale',
      'Total Revenue': 'Revenu total',
      'Total Efficiency': 'Efficacité totale',
      'Discharge Achievement Rate': 'Taux de réussite de la décharge',
      // Revenue Indicator
      "Today's Cost": "Today's Cost",
      "Today's Income": "Today's Income",
      "Total Cost": "Total Cost",
      "Total Income": "Total Income",
      "Today's Revenue": "Today's Revenue",

      'Revenue Ranking': 'Classement des revenus',
      'Efficiency Ranking': 'Classement d\'efficacité',
      'Charge Ranking': 'Classement de la capacité de charge',
      'Discharge Ranking': 'Classement de décharge',
      'Number of Microgrids': 'Nombre de micro-réseaux',
      'Number of Power Stations': 'Nombre de centrales électriques',
      'Microgrid List': 'Liste des microréseaux',
      'Previous Page': 'Page précédente',
      'Next Page': 'Page suivante',
      '7 Days': '7 jours',
      'Charge UNIT': 'Charge {{UNIT}}',
      'Discharge UNIT': 'décharge {{UNIT}}',
      'Generation UNIT': 'produire de l\'électricité {{UNIT}}',
      'Energy Indicator': 'énergétique',
      'Revenue Indicator': 'rendement',
      'Carbon Indicator': 'carbone',

      // Energy Storage Power Station
      'Battery Operating State': 'État de fonctionnement de la batterie',
      'Battery State': 'État de la batterie',
      'Battery Unknown': 'Inconnu',
      'Battery Stopped': 'A cessé',
      'Battery Reserved': 'Reserved', // 0
      'Battery Fault': 'La faute', // 1
      'Battery Warning': 'Avertissement', // 2
      'Battery Standby': 'Articles de rechange', // 3
      'Battery Prohibit DisCharging': 'Interdiction de désinstaller', // 4
      'Battery Prohibit Charging': 'Interdiction de charger', // 5
      'Battery Normal': 'Typiquement', // 6
      'Battery Charging': 'En charge', // 7
      'Battery Discharging': 'Dans la décharge', // 8
      'Battery Idle': 'Idle', // 9
      'Phase of Lifecycle': 'Phases du cycle de vie',
      'Use Phase': 'Phase d\'utilisation',
      'Commissioning Phase': 'Phase de mise en service',
      'Installation Phase': 'Phase d\'installation',
      'Device Status': 'État de l\'appareil',
      'Basic Information': 'Informations de base',
      'Gateway': 'Gateway',
      'Grid Meter': 'Grid Meter',
      'Load Meter': 'Load Meter',
      'Total Active Power': 'Total Active Power',
      'Active Power A': 'Active Power A',
      'Active Power B': 'Active Power B',
      'Active Power C': 'Active Power C',
      'Total Reactive Power': 'Total Reactive Power',
      'Reactive Power A': 'Reactive Power A',
      'Reactive Power B': 'Reactive Power B',
      'Reactive Power C': 'Reactive Power C',
      'Total Apparent Power': 'Total Apparent Power',
      'Apparent Power A': 'Apparent Power A',
      'Apparent Power B': 'Apparent Power B',
      'Apparent Power C': 'Apparent Power C',
      'Total Power Factor': 'Total Power Factor',
      'Active Energy Import': 'Active Energy Import',
      'Active Energy Export': 'Active Energy Export',
      'Active Energy Net': 'Active Energy Net',

      // Photovoltaic Power Station
      'Invertor': 'onduleurs',
      'Invertor Run State': 'État de fonctionnement',
      'Invertor Unknown': 'Inconnu',
      'Invertor Initializing': 'Initialisation en cours',
      'Invertor Standby': 'Articles de rechange',
      'Invertor Shutdown': 'Temps d’arrêt',
      'Invertor Fault': 'La faute',
      'Invertor Running': 'Courir',

      //Advanced Reporting & Knowledge base
      'Created Datetime': 'Créé Datetime',
      'File Format': 'Lime Format',
      'File Size': 'Lime Taille',
      Uploader: 'Téléchargeur',
      //Error
      "The page you're looking for is not found": 'La page que vous recherchez est introuvable',
      "Make sure the address is correct and that the page hasn't moved. ":
        'Assurez-vous que l’adresse est correcte et que la page n’a pas été déplacée. ',
      'If you think this is a mistake,': 'Si vous pensez que c’est une erreur,',
      'contact us': 'Contactez-nous',
      'Take me home': 'Ramenez-moi à la maison',
      'Whoops, something went wrong!': 'Whoops, quelque chose s’est mal passé!',
      'Try refreshing the page, or going back and attempting the action again. ':
        'Essayez d’actualiser la page, ou revenir en arrière et tenter à nouveau l’action. ',
      'If this problem persists,': 'Si ce problème persiste,',
      'Captcha Error': 'Captcha Erreur',
      'Confirm Password Error': 'Le mot de passe de confirmation ne correspond pas',
      'It looks like you clicked on an invalid password reset link. Please tryagain.':
        'Il semble que vous ayez cliqué \
      sur un lien de réinitialisation de mot de passe non valide. Veuillez réessayer.',
      'It looks like you clicked on an invalid registration account link. Please tryagain.':
        'Il semble que vous ayez \
      cliqué sur un lien de compte d’inscription non valide. Veuillez réessayer..',
      //Tenant Bill
      'Lease Contract Number': 'Bail Contrat Nombre',
      Download: 'Télécharger',
      Print: 'Imprimer',
      'Payment Notice': 'Paiement Remarquer',
      'Bill To': 'Facture À',
      'Bill Number': 'Facture Nombre',
      'Bill Date': 'Facture Date',
      'Payment Due Date': 'Paiement Due Date',
      'Amount Payable': 'Quantité Payable',
      'Billing Period Start': 'Facturation Période Commencer',
      'Billing Period End': 'Facturation Période Fin',
      Quantity: 'Quantité',
      Price: 'Prix',
      Unit: 'Unité',
      Amount: 'Quantité',
      Subtotal: 'Sous-total',
      'VAT Output Tax': 'TVA Sortie Taxe',
      'Total Amount Payable': 'Total Quantité Payable',
      'Please make sure to pay on or before the payment due date above':
        'Veuillez vous assurer de payer au plus tard à la date d’échéance du paiement ci-dessus',
      'Send money to the following account': 'Envoyer de l’argent sur le compte suivant',
      'Account Name': 'Compte Nom',
      'Bank Name': 'Banque Nom',
      'Bank Address': 'Banque Adresse',
      'RMB Account': 'RMB Compte',
      // button
      'Toggle Navigation': 'Cabillot Navigation',
      // working calendar
      'Working Days': 'Travaillant Jours',
      'Non Working Days': 'Non Travaillant Jours',
      'Select Row': 'Veuillez sélectionner la ligne de données',
      // Offline Meter Input
      'Daily Value': 'Quotidien Valeur',
      Date: 'Date',
      'Successfully Saved': 'Avec succès Sauvé',
      'Previous Data Is Empty': 'Précédent Les données sont vides',
      // API
      'API.ACCOUNT_UNLOCK_FAILED': 'Account unlock failed',
      'API.ADMINISTRATOR_SESSION_NOT_FOUND': 'Administrator session not found',
      'API.ADMINISTRATOR_SESSION_TIMEOUT': 'Administrator session timeout',
      'API.ADVANCED_REPORT_NOT_FOUND': 'Advanced report not found',
      'API.API_KEY_HAS_EXPIRED': 'Key has expired',
      'API.API_KEY_NAME_IS_ALREADY_IN_USE': 'Key name is already in use',
      'API.API_KEY_NOT_FOUND': 'Key not found',
      'API.BAD_REQUEST': 'Bad request',
      'API.BALANCING_PRICE_POINT_NOT_FOUND': 'Balancing price point not found',
      'API.BATTERY_STATE_POINT_NOT_FOUND': 'Battery state point not found',
      'API.BUY_METER_NOT_FOUND': 'Buy meter not found',
      'API.CANNOT_SET_EXISTING_SUBMETER_AS_MASTER_METER': 'Cannot set existing submeter as master meter',
      'API.CHARGE_METER_NOT_FOUND': 'Charge meter not found',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_EXISTS': 'Combined equipment command relation exists',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Combined equipment command relation not found',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_EXISTS': 'Combined equipment equipment relation exists',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_NOT_FOUND': 'Combined equipment equipment relation not found',
      'API.COMBINED_EQUIPMENT_METER_RELATION_EXISTS': 'Combined equipment meter relation exists',
      'API.COMBINED_EQUIPMENT_METER_RELATION_NOT_FOUND': 'Combined equipment meter relation not found',
      'API.COMBINED_EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Combined equipment name is already in use',
      'API.COMBINED_EQUIPMENT_NOT_FOUND': 'Combined equipment not found',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Combined equipment offline meter relation exists',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Combined equipment offline meter relation not found',
      'API.COMBINED_EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Combined equipment parameter name is already in use',
      'API.COMBINED_EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Combined equipment parameter not found or not match',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Combined equipment virtual meter relation exists',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Combined equipment virtual meter relation not found',
      'API.COMMAND_NAME_IS_ALREADY_IN_USE': 'Command name is already in use',
      'API.COMMAND_NOT_FOUND': 'Command not  found',
      'API.CONTACT_NAME_IS_ALREADY_IN_USE': 'Contact name is already in use',
      'API.CONTACT_NOT_FOUND': 'Contact not found',
      'API.COOLING_METER_NOT_FOUND': 'Cooling meter not found',
      'API.COST_CENTER_EXTERNAL_ID_EXISTS': 'Cost center external id exists',
      'API.COST_CENTER_NAME_EXISTS': 'Cost center name exists',
      'API.COST_CENTER_NOT_FOUND': 'Cost center not found',
      'API.COST_FILE_NOT_FOUND': 'Cost file not found',
      'API.DATA_REPAIR_FILE_NOT_FOUND': 'Data repair file not found',
      'API.DATA_SOURCE_NAME_IS_ALREADY_IN_USE': 'Data source name is already in use',
      'API.DATA_SOURCE_NOT_FOUND': 'Data source not found',
      'API.DATABASE_ERROR': 'Database error',
      'API.DATE_IS_ALREADY_IN_WORKING_CALENDAR': 'Date Is Already In Working Calendar',
      'API.DATE_LOCAL_NOT_FOUND': 'Date local not found',
      'API.DISCHARGE_METER_NOT_FOUND': 'Discharge meter not found',
      'API.DISTRIBUTION_CIRCUIT_NAME_IS_ALREADY_IN_USE': 'Distribution circuit name is already in use',
      'API.DISTRIBUTION_CIRCUIT_NOT_FOUND': 'Distribution circuit not found',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_EXISTS': 'Distribution circuit point relation exists',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_NOT_FOUND': 'Distribution circuit point relation not found',
      'API.DISTRIBUTION_SYSTEM_NAME_IS_ALREADY_IN_USE': 'Distribution system name is already in use',
      'API.DISTRIBUTION_SYSTEM_NOT_FOUND': 'Distribution system not found',
      'API.ELECTRICITY_METER_NOT_FOUND': 'Electricity meter not found',
      'API.EMAIL_IS_ALREADY_IN_USE': 'Email is already in use',
      'API.EMAIL_MESSAGE_NOT_FOUND': 'Email message not found',
      'API.EMAIL_NOT_FOUND': 'Email not found',
      'API.EMAIL_SERVER_HOST_IS_ALREADY_IN_USE': 'Email server host is already in use',
      'API.EMAIL_SERVER_NOT_FOUND': 'Email server not found',
      'API.EMPTY_VARIABLES_ARRAY': 'Empty variables array',
      'API.ENERGY_CATEGORY_NAME_IS_ALREADY_IN_USE': 'Energy category name is already in use',
      'API.ENERGY_CATEGORY_NOT_FOUND': 'Energy category not found',
      'API.ENERGY_CATEGORY_USED_IN_ENERGY_ITEMS': 'Energy category used in energy items',
      'API.ENERGY_CATEGORY_USED_IN_METER': 'Energy category used in meter',
      'API.ENERGY_CATEGORY_USED_IN_OFFLINE_METER': 'Energy category used in offline meter',
      'API.ENERGY_CATEGORY_USED_IN_TARIFFS': 'Energy category used in tariffs',
      'API.ENERGY_CATEGORY_USED_IN_VIRTUAL_METER': 'Energy category used in virtual meter',
      'API.ENERGY_FLOW_DIAGRAM_LINK_IS_ALREADY_IN_USE': 'Energy flow diagram link is already in use',
      'API.ENERGY_FLOW_DIAGRAM_LINK_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram link not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram node name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram node not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NOT_FOUND': 'Energy flow diagram not found',
      'API.ENERGY_ITEM_DOES_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item does not belong to energy category',
      'API.ENERGY_ITEM_IS_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item is not belong to energy category',
      'API.ENERGY_ITEM_NAME_IS_ALREADY_IN_USE': 'Energy item name is already in use',
      'API.ENERGY_ITEM_NOT_FOUND': 'Energy item not found',
      'API.ENERGY_ITEM_USED_IN_METER': 'Energy item used in meter',
      'API.ENERGY_ITEM_USED_IN_OFFLINE_METER': 'Energy item used in offline meter',
      'API.ENERGY_ITEM_USED_IN_VIRTUAL_METER': 'Energy item used in virtual meter',
      'API.ENERGY_STORAGE_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Energy storage power station name is already in use',
      'API.ENERGY_STORAGE_POWER_STATION_NOT_FOUND': 'Energy storage power station not found',
      'API.EQUIPMENT_COMMAND_RELATION_EXISTS': 'Equipment command relation exists',
      'API.EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Equipment command relation not found',
      'API.EQUIPMENT_METER_RELATION_EXISTS': 'Equipment meter relation exists',
      'API.EQUIPMENT_METER_RELATION_NOT_FOUND': 'Equipment meter relation not found',
      'API.EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Equipment name is already in use',
      'API.EQUIPMENT_NOT_FOUND': 'Equipment not found',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Equipment offline meter relation exists',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Equipment offline meter relation not found',
      'API.EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Equipment parameter name is already in use',
      'API.EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Equipment parameter not found or not match',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Equipment virtual meter relation exists',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Equipment virtual meter relation not found',
      'API.ERROR': 'Error',
      'API.EXCEPTION': 'Exception',
      'API.FAILED_TO_READ_REQUEST_STREAM': 'Failed to read request stream',
      'API.FAILED_TO_RESTORE_COST_FILE': 'Failed to restore cost file',
      'API.FAILED_TO_RESTORE_DATA_REPAIR_FILE': 'Failed to restore data repair file',
      'API.FAILED_TO_RESTORE_KNOWLEDGE_FILE': 'Failed to restore knowledge file',
      'API.FAILED_TO_RESTORE_OFFLINE_METER_FILE': 'Failed to restore offline meter file',
      'API.FAILED_TO_SAVE_OFFLINE_METER_FILE': 'Failed to save offline meter file',
      'API.FAILED_TO_UPLOAD_ATTACHMENT_FILE': 'Failed to upload attachment file',
      'API.FAILED_TO_UPLOAD_COST_FILE': 'Failed to upload cost file',
      'API.FAILED_TO_UPLOAD_DATA_REPAIR_FILE': 'Failed to upload data repair file',
      'API.FAILED_TO_UPLOAD_KNOWLEDGE_FILE': 'Failed to upload knowledge file',
      'API.FAILED_TO_UPLOAD_OFFLINE_METER_FILE': 'Failed to upload offline meter file',
      'API.GATEWAY_NAME_IS_ALREADY_IN_USE': 'Gateway name is already in use',
      'API.GATEWAY_NOT_FOUND': 'Gateway not found',
      'API.HEAT_METER_NOT_FOUND': 'Heat meter not found',
      'API.INVALID_': 'Invalid ',
      'API.INVALID_ACKNOWLEDGE_CODE': 'Invalid acknowledge code',
      'API.INVALID_ADDRESS': 'Invalid address',
      'API.INVALID_ADDRESS_VALUE': 'Invalid address value',
      'API.INVALID_ADVANCED_REPORT_ID': 'Invalid advanced report id',
      'API.INVALID_API_KEY_ID': 'Invalid api key id',
      'API.INVALID_API_KEY_NAME': 'Invalid api key name',
      'API.INVALID_AREA_VALUE': 'Invalid area value',
      'API.INVALID_BALANCING_PRICE_POINT_ID': 'Invalid balancing price point ID',
      'API.INVALID_BASE_PERIOD_END_DATETIME': 'Invalid base period end datetime',
      'API.INVALID_BASE_PERIOD_START_DATETIME': 'Invalid base period start datetime',
      'API.INVALID_BATTERY_STATE_POINT_ID': 'Invalid battery state point ID',
      'API.INVALID_BAUD_RATE': 'Invalid baud rate',
      'API.INVALID_BUILDINGS_VALUE': 'Invalid buildings value',
      'API.INVALID_BUY_METER_ID': 'Invalid buy meter id',
      'API.INVALID_CAPACITY': 'Invalid capacity',
      'API.INVALID_CATEGORY': 'Invalid category',
      'API.INVALID_CHANNEL': 'Invalid channel',
      'API.INVALID_CHARGE_METER_ID': 'Invalid charge meter id',
      'API.INVALID_COMBINED_EQUIPMENT_ID': 'Invalid combined equipment id',
      'API.INVALID_COMBINED_EQUIPMENT_NAME': 'Invalid combined equipment name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_ID': 'Invalid combined equipment parameter id',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_NAME': 'Invalid combined equipment parameter name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_TYPE': 'Invalid combined equipment parameter type',
      'API.INVALID_COMBINED_EQUIPMENT_UUID': 'Invalid combined equipment uuid',
      'API.INVALID_COMMAND_ID': 'Invalid command id',
      'API.INVALID_COMMAND_NAME': 'Invalid command name',
      'API.INVALID_CONNECTION': 'Invalid connection',
      'API.INVALID_CONSTANT_VALUE': 'Invalid constant value',
      'API.INVALID_CONTACT_ID': 'Invalid contact id',
      'API.INVALID_CONTACT_NAME': 'Invalid contact name',
      'API.INVALID_COOLING_METER_ID': 'Invalid cooling meter id',
      'API.INVALID_COST_CENTER_ID': 'Invalid cost center id',
      'API.INVALID_COST_FILE_ID': 'Invalid cost file id',
      'API.INVALID_CREATED_DATETIME': 'Invalid created datetime',
      'API.INVALID_DATA_REPAIR_FILE_ID': 'Invalid data repair file id',
      'API.INVALID_DATA_SOURCE_ID': 'Invalid data source id',
      'API.INVALID_DATA_SOURCE_NAME': 'Invalid data source name',
      'API.INVALID_DATA_SOURCE_PROTOCOL': 'Invalid data source protocol',
      'API.INVALID_DATE_LOCAL': 'Invalid date local',
      'API.INVALID_DENOMINATOR_METER_UUID': 'Invalid denominator meter uuid',
      'API.INVALID_DISCHARGE_METER_ID': 'Invalid discharge meter id',
      'API.INVALID_DISPLAY_NAME': 'Invalid display name',
      'API.INVALID_DISTRIBUTION_CIRCUIT_ID': 'Invalid distribution circuit id',
      'API.INVALID_DISTRIBUTION_CIRCUIT_NAME': 'Invalid distribution circuit name',
      'API.INVALID_DISTRIBUTION_ROOM': 'Invalid distribution room',
      'API.INVALID_DISTRIBUTION_SYSTEM_ID': 'Invalid distribution system id',
      'API.INVALID_DISTRIBUTION_SYSTEM_NAME': 'Invalid distribution system name',
      'API.INVALID_ELECTRICITY_METER_ID': 'Invalid Electricity meter id',
      'API.INVALID_EMAIL': 'Invalid email',
      'API.INVALID_EMAIL_MESSAGE_ID': 'Invalid email message id',
      'API.INVALID_EMAIL_SERVER_HOST': 'Invalid email server host',
      'API.INVALID_EMAIL_SERVER_ID': 'Invalid email server id',
      'API.INVALID_END_DATE_FORMAT': 'Invalid end date format',
      'API.INVALID_END_DATETIME': 'Invalid end datetime',
      'API.INVALID_ENERGY_CATEGORY_ID': 'Invalid energy category id',
      'API.INVALID_ENERGY_CATEGORY_NAME': 'Invalid energy category name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_ID': 'Invalid energy flow diagram id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_LINK_ID': 'Invalid energy flow diagram link id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NAME': 'Invalid energy flow diagram name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_ID': 'Invalid energy flow diagram node id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_NAME': 'Invalid energy flow diagram node name',
      'API.INVALID_ENERGY_ITEM_ID': 'Invalid energy item id',
      'API.INVALID_ENERGY_ITEM_NAME': 'Invalid energy item name',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_ID': 'Invalid energy storage power station ID',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_NAME': 'Invalid energy storage power station name',
      'API.INVALID_EQUATION_IN_EXPRESSION': 'Invalid equation in expression',
      'API.INVALID_EQUIPMENT_ID': 'Invalid equipment id',
      'API.INVALID_EQUIPMENT_NAME': 'Invalid equipment name',
      'API.INVALID_EQUIPMENT_PARAMETER_ID': 'Invalid equipment parameter id',
      'API.INVALID_EQUIPMENT_PARAMETER_NAME': 'Invalid equipment parameter name',
      'API.INVALID_EQUIPMENT_PARAMETER_TYPE': 'Invalid equipment parameter type',
      'API.INVALID_EQUIPMENT_UUID': 'Invalid equipment uuid',
      'API.INVALID_EXPIRES_DATETIME': 'Invalid expires datetime',
      'API.INVALID_EXPRESSION': 'Invalid expression',
      'API.INVALID_EXPRESSION_OBJECT': 'Invalid expression object',
      'API.INVALID_FDD_CODE': 'Invalid fdd code',
      'API.INVALID_FLOORS_VALUE': 'Invalid floors value',
      'API.INVALID_FROM_ADDR': 'Invalid from addr',
      'API.INVALID_GATEWAY_ID': 'Invalid gateway id',
      'API.INVALID_GATEWAY_NAME': 'Invalid gateway name',
      'API.INVALID_HEAT_METER_ID': 'Invalid heat meter id',
      'API.INVALID_HIGH_LIMIT_VALUE': 'Invalid high limit value',
      'API.INVALID_HOURLY_HIGH_LIMIT_VALUE': 'Invalid hourly high limit value',
      'API.INVALID_HOURLY_LOW_LIMIT_VALUE': 'Invalid hourly low limit value',
      'API.INVALID_Id': 'Invalid id',
      'API.INVALID_IS_ADMIN_VALUE': 'Invalid is admin value',
      'API.INVALID_IS_COST_DATA_DISPLAYED': 'Invalid is cost data displayed',
      'API.INVALID_IS_COUNTED_VALUE': 'Invalid is counted value',
      'API.INVALID_IS_ENABLED': 'Invalid is enabled',
      'API.INVALID_IS_HIDDEN': 'Invalid is hidden',
      'API.INVALID_IS_IN_LEASE_VALUE': 'Invalid is in lease value',
      'API.INVALID_IS_INPUT_COUNTED_VALUE': 'Invalid is input counted value',
      'API.INVALID_IS_KEY_TENANT_VALUE': 'Invalid is key tenant value',
      'API.INVALID_IS_OUTPUT_COUNTED_VALUE': 'Invalid is output counted value',
      'API.INVALID_IS_OUTPUT_VALUE': 'Invalid is output value',
      'API.INVALID_IS_READ_ONLY_VALUE': 'Invalid is read only value',
      'API.INVALID_IS_TREND_VALUE': 'Invalid is trend value',
      'API.INVALID_IS_VIRTUAL_VALUE': 'Invalid is virtual value',
      'API.INVALID_KGCE': 'Invalid kgce',
      'API.INVALID_KGCO2E': 'Invalid kgco2e',
      'API.INVALID_KNOWLEDGE_FILE_ID': 'Invalid knowledge file id',
      'API.INVALID_LATITUDE_VALUE': 'Invalid latitude value',
      'API.INVALID_LEASE_NUMBER_VALUE': 'Invalid lease number value',
      'API.INVALID_LONGITUDE_VALUE': 'Invalid longitude value',
      'API.INVALID_LOW_LIMIT_VALUE': 'Invalid low limit value',
      'API.INVALID_MASTER_METER_ID': 'Invalid master meter id',
      'API.INVALID_MENU_ID': 'Invalid menu id',
      'API.INVALID_MESSAGE_DATA': 'Invalid message data',
      'API.INVALID_MESSAGE_TEMPLATE': 'Invalid message template',
      'API.INVALID_MESSAGE_TEMPLATE_ID': 'Invalid message template id',
      'API.INVALID_MESSAGE_VALUE': 'Invalid message value',
      'API.INVALID_METER_ID': 'Invalid meter id',
      'API.INVALID_METER_NAME': 'Invalid meter name',
      'API.INVALID_METER_UUID': 'Invalid meter uuid',
      'API.INVALID_MICROGRID_BATTERY_ID': 'Invalid microgrid battery id',
      'API.INVALID_MICROGRID_BATTERY_NAME': 'Invalid microgrid battery name',
      'API.INVALID_MICROGRID_EVCHARGER_ID': 'Invalid microgrid evcharger id',
      'API.INVALID_MICROGRID_EVCHARGER_NAME': 'Invalid microgrid evcharger name',
      'API.INVALID_MICROGRID_GENERATOR_ID': 'Invalid microgrid generator id',
      'API.INVALID_MICROGRID_GENERATOR_NAME': 'Invalid microgrid generator name',
      'API.INVALID_MICROGRID_GRID_ID': 'Invalid microgrid grid id',
      'API.INVALID_MICROGRID_GRID_NAME': 'Invalid microgrid grid name',
      'API.INVALID_MICROGRID_HEATPUMP_ID': 'Invalid microgrid heatpump id',
      'API.INVALID_MICROGRID_HEATPUMP_NAME': 'Invalid microgrid heatpump name',
      'API.INVALID_MICROGRID_ID': 'Invalid microgrid id',
      'API.INVALID_MICROGRID_LOAD_ID': 'Invalid microgrid load id',
      'API.INVALID_MICROGRID_LOAD_NAME': 'Invalid microgrid load name',
      'API.INVALID_MICROGRID_NAME': 'Invalid microgrid name',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_ID': 'Invalid microgrid photovoltaic id',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_NAME': 'Invalid microgrid photovoltaic name',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_ID': 'Invalid microgrid power conversion system ID',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_NAME': 'Invalid microgrid microgrid power conversion system name',
      'API.INVALID_MICROGRID_UUID': 'Invalid microgrid UUID',
      'API.INVALID_NAME_VALUE': 'Invalid name value',
      'API.INVALID_NEW_PASSWORD': 'Invalid new password',
      'API.INVALID_NON_WORKING_DAY_ID': 'Invalid non working day ID',
      'API.INVALID_NOTIFICATION_ID': 'Invalid notification id',
      'API.INVALID_NUMERATOR_METER_UUID': 'Invalid numerator meter uuid',
      'API.INVALID_OBJECT_TYPE': 'Invalid object type',
      'API.INVALID_OFFLINE_METER_DATA': 'Invalid offline meter data',
      'API.INVALID_OFFLINE_METER_FILE_ID': 'Invalid offline meter file id',
      'API.INVALID_OFFLINE_METER_ID': 'Invalid offline meter id',
      'API.INVALID_OFFLINE_METER_NAME': 'Invalid offline meter name',
      'API.INVALID_OFFLINE_METER_UUID': 'Invalid offline meter uuid',
      'API.INVALID_OFFSET_CONSTANT_VALUE': 'Invalid offset constant value',
      'API.INVALID_OLD_PASSWORD': 'Invalid old password',
      'API.INVALID_OPENID': 'Invalid openid',
      'API.INVALID_PARENT_SPACE_ID': 'Invalid parent space id',
      'API.INVALID_PASSWORD': 'Invalid password',
      'API.INVALID_PAYLOAD': 'Invalid payload',
      'API.INVALID_PEAK_CURRENT': 'Invalid peak current',
      'API.INVALID_PEAK_LOAD': 'Invalid peak load',
      'API.INVALID_PERIOD_TYPE': 'Invalid period type',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_ID': 'Invalid photovoltaic power station ID',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_NAME': 'Invalid photovoltaic power station name',
      'API.INVALID_POINT_ID': 'Invalid point id',
      'API.INVALID_POINT_NAME': 'Invalid point name',
      'API.INVALID_PORT': 'Invalid port',
      'API.INVALID_POSTAL_CODE_VALUE': 'Invalid postal code value',
      'API.INVALID_POWER_POINT_ID': 'Invalid power point id',
      'API.INVALID_PRIORITY': 'Invalid priority',
      'API.INVALID_PRIVILEGE': 'Invalid privilege',
      'API.INVALID_PRIVILEGE_DATA': 'Invalid privilege data',
      'API.INVALID_PRIVILEGE_ID': 'Invalid privilege id',
      'API.INVALID_PRIVILEGE_NAME': 'Invalid privilege name',
      'API.INVALID_RATIO_VALUE': 'Invalid ratio value',
      'API.INVALID_RECIPIENT_EMAIL': 'Invalid recipient email',
      'API.INVALID_RECIPIENT_MOBILE': 'Invalid recipient mobile',
      'API.INVALID_RECIPIENT_NAME': 'Invalid recipient name',
      'API.INVALID_RECIPIENT_OPENID': 'Invalid recipient openid',
      'API.INVALID_REPLY': 'Invalid reply',
      'API.INVALID_REPORTING_PERIOD_END_DATETIME': 'Invalid reporting period end datetime',
      'API.INVALID_REPORTING_PERIOD_START_DATETIME': 'Invalid reporting period start datetime',
      'API.INVALID_REQUIRES_AUTHENTICATION': 'Invalid requires authentication',
      'API.INVALID_ROOMS_VALUE': 'Invalid rooms value',
      'API.INVALID_RULE_ID': 'Invalid rule id',
      'API.INVALID_RULE_NAME': 'Invalid rule name',
      'API.INVALID_RUN_STATE_POINT_ID': 'Invalid run state point ID',
      'API.INVALID_SCHEDULED_DATETIME': 'Invalid scheduled datetime',
      'API.INVALID_SELL_METER_ID': 'Invalid sell meter id',
      'API.INVALID_SENSOR_ID': 'Invalid sensor id',
      'API.INVALID_SENSOR_NAME': 'Invalid sensor name',
      'API.INVALID_SENSOR_UUID': 'Invalid sensor uuid',
      'API.INVALID_SERIAL_NUMBER': 'Invalid serial number',
      'API.INVALID_SERIAL_PORT': 'Invalid serial port',
      'API.INVALID_SESSION_PLEASE_RE_LOGIN': 'Invalid session please re-login',
      'API.INVALID_SET_VALUE': 'Invalid set value',
      'API.INVALID_SHOPFLOOR_ID': 'Invalid shopfloor id',
      'API.INVALID_SHOPFLOOR_NAME': 'Invalid shopfloor name',
      'API.INVALID_SHOPFLOOR_UUID': 'Invalid shopfloor uuid',
      'API.INVALID_SOURCE_NODE_ID': 'Invalid source node id',
      'API.INVALID_SPACE_ID': 'Invalid space id',
      'API.INVALID_SPACE_NAME': 'Invalid space name',
      'API.INVALID_SPACE_UUID': 'Invalid space uuid',
      'API.INVALID_START_DATE_FORMAT': 'Invalid start date format',
      'API.INVALID_START_DATETIME': 'Invalid start datetime',
      'API.INVALID_STATUS': 'Invalid status',
      'API.INVALID_STORE_ID': 'Invalid store id',
      'API.INVALID_STORE_NAME': 'Invalid store name',
      'API.INVALID_STORE_TYPE_DESCRIPTION': 'Invalid store type description',
      'API.INVALID_STORE_TYPE_ID': 'Invalid store type id',
      'API.INVALID_STORE_TYPE_NAME': 'Invalid store type name',
      'API.INVALID_STORE_TYPE_SIMPLIFIED_CODE': 'Invalid store type simplified code',
      'API.INVALID_STORE_UUID': 'Invalid store uuid',
      'API.INVALID_SUBJECT_VALUE': 'Invalid subject value',
      'API.INVALID_SVG': 'Invalid SVG',
      'API.INVALID_SWITCHGEAR': 'Invalid switchgear',
      'API.INVALID_TARGET_NODE_ID': 'Invalid target node id',
      'API.INVALID_TARIFF_BLOCK_PRICING': 'Invalid tariff block pricing',
      'API.INVALID_TARIFF_ID': 'Invalid tariff id',
      'API.INVALID_TARIFF_TIME_OF_USE_PRICING': 'Invalid tariff time of use pricing',
      'API.INVALID_TARIFF_TYPE': 'Invalid tariff type',
      'API.INVALID_TENANT_ID': 'Invalid tenant id',
      'API.INVALID_TENANT_NAME': 'Invalid tenant name',
      'API.INVALID_TENANT_TYPE_DESCRIPTION': 'Invalid tenant type description',
      'API.INVALID_TENANT_TYPE_ID': 'Invalid tenant type id',
      'API.INVALID_TENANT_TYPE_NAME': 'Invalid tenant type name',
      'API.INVALID_TENANT_TYPE_SIMPLIFIED_CODE': 'Invalid tenant type simplified code',
      'API.INVALID_TENANT_UUID': 'Invalid tenant uuid',
      'API.INVALID_TEXT_MESSAGE_ID': 'Invalid text message id',
      'API.INVALID_TIMEZONE_ID': 'Invalid timezone id',
      'API.INVALID_TOKEN': 'Invalid token',
      'API.INVALID_TOPIC': 'Invalid topic',
      'API.INVALID_UNIT_OF_MEASURE': 'Invalid unit of measure',
      'API.INVALID_UNIT_OF_PRICE': 'Invalid unit of price',
      'API.INVALID_UNITS': 'Invalid units',
      'API.INVALID_USER_ID': 'Invalid user id',
      'API.INVALID_USER_NAME': 'Invalid user name',
      'API.INVALID_USER_NAME_OR_EMAIL': 'Invalid user name or email',
      'API.INVALID_USER_PHONE': 'Invalid user phone',
      'API.INVALID_USER_PLEASE_RE_LOGIN': 'Invalid user please re-login',
      'API.INVALID_USER_UUID': 'Invalid user uuid',
      'API.INVALID_USERNAME': 'Invalid userName',
      'API.INVALID_VARIABLE_METER_ID': 'Invalid variable meter id',
      'API.INVALID_VARIABLE_METER_TYPE': 'Invalid variable meter type',
      'API.INVALID_VARIABLE_NAME': 'Invalid variable name',
      'API.INVALID_VERIFICATION_CODE': 'Invalid verification code',
      'API.INVALID_VIRTUAL_METER_ID': 'Invalid virtual meter id',
      'API.INVALID_VIRTUAL_METER_NAME': 'Invalid virtual meter name',
      'API.INVALID_VIRTUAL_METER_UUID': 'Invalid virtual meter uuid',
      'API.INVALID_WEB_MESSAGE_ID': 'Invalid web message id',
      'API.INVALID_WECHAT_MESSAGE_ID': 'Invalid wechat message id',
      'API.INVALID_WIND_FARM_ID': 'Invalid wind farm ID',
      'API.INVALID_WIND_FARM_NAME': 'Invalid wind farm name',
      'API.INVALID_WORKING_CALENDAR_ID': 'Invalid working calendar id ',
      'API.INVALID_WORKING_CALENDAR_NAME': 'Invalid working calendar name',
      'API.KNOWLEDGE_FILE_CANNOT_BE_REMOVED_FROM_DISK': 'Knowledge File Cannot be Removed from Disk',
      'API.KNOWLEDGE_FILE_NOT_FOUND': 'Knowledge file not found',
      'API.MASTER_METER_DOES_NOT_BELONG_TO_SAME_ENERGY_CATEGORY':
        'Master meter does not belong to same energy category',
      'API.MASTER_METER_NOT_FOUND': 'Master meter not found',
      'API.MENU_NOT_FOUND': 'Menu not found',
      'API.METER_CANNOT_HAVE_MORE_THAN_ONE_ENERGY_VALUE_POINTS': 'Meter cannot have more than one energy value points',
      'API.METER_COMMAND_RELATION_EXISTS': 'Meter command relation exists',
      'API.METER_COMMAND_RELATION_NOT_FOUND': 'Meter command relation not found',
      'API.METER_NAME_IS_ALREADY_IN_USE': 'Meter name is already in use',
      'API.METER_NOT_FOUND': 'Meter not found',
      'API.METER_OF_VARIABLE_NOT_FOUND': 'Meter of variable not found',
      'API.METER_POINT_RELATION_EXISTS': 'Meter point relation exists',
      'API.METER_POINT_RELATION_NOT_FOUND': 'Meter point relation not found',
      'API.MICROGRID_BATTERY_NAME_IS_ALREADY_IN_USE': 'Microgrid battery name is already in use',
      'API.MICROGRID_BATTERY_NOT_FOUND': 'Microgrid battery not found',
      'API.MICROGRID_EVCHARGER_NAME_IS_ALREADY_IN_USE': 'Microgrid evcharger name is already in use',
      'API.MICROGRID_EVCHARGER_NOT_FOUND': 'Microgrid evcharger not fund',
      'API.MICROGRID_GENERATOR_NAME_IS_ALREADY_IN_USE': 'Microgrid generator name is already in use',
      'API.MICROGRID_GENERATOR_NOT_FOUND': 'Microgrid generator not found',
      'API.MICROGRID_GRID_NAME_IS_ALREADY_IN_USE': 'Microgrid grid name is already in use',
      'API.MICROGRID_GRID_NOT_FOUND': 'Microgrid grid not found',
      'API.MICROGRID_HEATPUMP_NAME_IS_ALREADY_IN_USE': 'Microgrid heatpump name is already in use',
      'API.MICROGRID_HEATPUMP_NOT_FOUND': 'Microgrid heatpump not found',
      'API.MICROGRID_LOAD_NAME_IS_ALREADY_IN_USE': 'Microgrid load name is already',
      'API.MICROGRID_LOAD_NOT_FOUND': 'Microgrid load not found',
      'API.MICROGRID_NAME_IS_ALREADY_IN_USE': 'Microgrid name is already in use',
      'API.MICROGRID_NOT_FOUND': 'Microgrid not found',
      'API.MICROGRID_PHOTOVOLTAIC_NAME_IS_ALREADY_IN_USE': 'Microgrid photovoltaic name is already in use',
      'API.MICROGRID_PHOTOVOLTAIC_NOT_FOUND': 'Microgrid photovoltaic not found',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NAME_IS_ALREADY_IN_USE':
        'Microgrid power conversion system name is already in use',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NOT_FOUND': 'Microgrid power conversion system not found',
      'API.MICROGRID_SENSOR_RELATION_EXISTS': 'Microgrid sensor relation exists',
      'API.MICROGRID_SENSOR_RELATION_NOT_FOUND': 'Microgrid sensor not found',
      'API.MQTT_CONNECTION_ERROR': 'Mqtt connection error',
      'API.MQTT_PUBLISH_ERROR': 'Mqtt publish error',
      'API.NEW_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'New password length cannot exceed 100 characters',
      'API.NEW_USER_SESSION_NOT_FOUND': 'New user session not found',
      'API.NEW_USER_SESSION_TIMEOUT': 'New user session timeout',
      'API.NON_WORKING_DAY_NOT_FOUND': 'Non working day non found',
      'API.NOT_FOUND': 'Not found',
      'API.NOTIFICATION_NOT_FOUND': 'Notification not found',
      'API.OFFLINE_METER_FILE_NOT_FOUND': 'Offline meter file not found',
      'API.OFFLINE_METER_NAME_IS_ALREADY_IN_USE': 'Offline meter name is already in use',
      'API.OFFLINE_METER_NOT_FOUND': 'Offline meter not found',
      'API.OFFLINE_METER_OF_VARIABLE_NOT_FOUND': 'Offline meter of variable not found',
      'API.OLD_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Old password length cannot exceed 100 characters',
      'API.PARENT_SPACE_NOT_FOUND': 'Parent space not found',
      'API.PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Password length cannot exceed 100 characters',
      'API.PASSWORDS_MATCH': 'New password is the same as the old password',
      'API.PHOTOVOLTAIC_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Photovoltaic power station name is already in use',
      'API.PHOTOVOLTAIC_POWER_STATION_NOT_FOUND': 'Photovoltaic power station not found',
      'API.POINT_NAME_IS_ALREADY_IN_USE': 'Point name is already in use',
      'API.POINT_NOT_FOUND': 'Point not found',
      'API.POWER_POINT_NOT_FOUND': 'Power point not found',
      'API.PRIVILEGE_NAME_IS_ALREADY_IN_USE': 'Privilege name is already in use',
      'API.PRIVILEGE_NOT_FOUND': 'Privilege not found',
      'API.RULE_NAME_IS_ALREADY_IN_USE': 'Rule name is already in use',
      'API.RULE_NOT_FOUND': 'Rule not found',
      'API.SELL_METER_NOT_FOUND': 'Sell meter not found',
      'API.SENSOR_NAME_IS_ALREADY_IN_USE': 'Sensor name is already in use',
      'API.SENSOR_NOT_FOUND': 'Sensor not found',
      'API.SENSOR_POINT_RELATION_EXISTS': 'Sensor point relation exists',
      'API.SENSOR_POINT_RELATION_NOT_FOUND': 'Sensor point relation not found',
      'API.SHOPFLOOR_COMMAND_RELATION_EXISTS': 'Shopfloor command relation exists',
      'API.SHOPFLOOR_COMMAND_RELATION_NOT_FOUND': 'Shopfloor command relation not found',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_EXISTS': 'Shopfloor equipment relation exists',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_NOT_FOUND': 'Shopfloor equipment relation not found',
      'API.SHOPFLOOR_METER_RELATION_EXISTS': 'Shopfloor meter relation exists',
      'API.SHOPFLOOR_METER_RELATION_NOT_FOUND': 'Shopfloor meter relation not found',
      'API.SHOPFLOOR_NAME_IS_ALREADY_IN_USE': 'Shopfloor name is already in use',
      'API.SHOPFLOOR_NOT_FOUND': 'Shopfloor not found',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_EXISTS': 'Shopfloor offline meter relation exists',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_NOT_FOUND': 'Shopfloor offline meter relation not found',
      'API.SHOPFLOOR_POINT_RELATION_EXISTS': 'Shopfloor point relation exists',
      'API.SHOPFLOOR_POINT_RELATION_NOT_FOUND': 'Shopfloor point relation not found',
      'API.SHOPFLOOR_SENSOR_RELATION_EXISTS': 'Shopfloor sensor relation exists',
      'API.SHOPFLOOR_SENSOR_RELATION_NOT_FOUND': 'Shopfloor sensor relation not found',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_EXISTS': 'Shopfloor virtual meter relation exists',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_NOT_FOUND': 'Shopfloor virtual meter relation not found',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_EXISTS': 'Shopfloor working calendar relation exists',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Shopfloor working calendar relation not found',
      'API.SOC_POINT_NOT_FOUND': 'SOC point not found',
      'API.SOURCE_NODE_NOT_FOUND': 'Source node not found',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_EXISTS': 'Space combined equipment relation exists',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_NOT_FOUND': 'Space combined equipment relation not found',
      'API.SPACE_COMMAND_RELATION_EXISTS': 'Space command relation exists',
      'API.SPACE_COMMAND_RELATION_NOT_FOUND': 'Space command relation not found',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_EXISTS': 'Space Energy Storage Power Station Relation Exists',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_NOT_FOUND': 'Space Energy Storage Power Station Relation Not Found',
      'API.SPACE_EQUIPMENT_RELATION_EXISTS': 'Space equipment relation exists',
      'API.SPACE_EQUIPMENT_RELATION_NOT_FOUND': 'Space equipment relation not found',
      'API.SPACE_METER_RELATION_EXISTS': 'Space meter relation exists',
      'API.SPACE_METER_RELATION_NOT_FOUND': 'Space meter relation not found',
      'API.SPACE_NAME_IS_ALREADY_IN_USE': 'Space name is already in use',
      'API.SPACE_NOT_FOUND': 'Space not found',
      'API.SPACE_NOT_FOUND_IN_PRIVILEGE': 'Space not found in privilege',
      'API.SPACE_OFFLINE_METER_RELATION_EXISTS': 'Space offline meter relation exists',
      'API.SPACE_OFFLINE_METER_RELATION_NOT_FOUND': 'Space offline meter relation not found',
      'API.SPACE_POINT_RELATION_EXISTS': 'Space point relation exists',
      'API.SPACE_POINT_RELATION_NOT_FOUND': 'Space point relation not found',
      'API.SPACE_SENSOR_RELATION_EXISTS': 'Space sensor relation exists',
      'API.SPACE_SENSOR_RELATION_NOT_FOUND': 'Space sensor relation not found',
      'API.SPACE_SHOPFLOOR_RELATION_EXISTS': 'Space shopfloor relation exists',
      'API.SPACE_SHOPFLOOR_RELATION_NOT_FOUND': 'Space shopfloor relation not found',
      'API.SPACE_STORE_RELATION_EXISTS': 'Space store relation exists',
      'API.SPACE_STORE_RELATION_NOT_FOUND': 'Space store relation not found',
      'API.SPACE_TENANT_RELATION_EXISTS': 'Space tenant relation exists',
      'API.SPACE_TENANT_RELATION_NOT_FOUND': 'Space tenant relation not found',
      'API.SPACE_VIRTUAL_METER_RELATION_EXISTS': 'Space virtual meter relation exists',
      'API.SPACE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Space virtual meter relation not found',
      'API.SPACE_WORKING_CALENDAR_RELATION_EXISTS': 'Space working calendar relation exists',
      'API.SPACE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Space working calendar relation not found',
      'API.START_DATETIME_MUST_BE_EARLIER_THAN_END_DATETIME': 'Start datetime must be early than end datetime',
      'API.STORE_COMMAND_RELATION_EXISTS': 'Store command relation exists',
      'API.STORE_COMMAND_RELATION_NOT_FOUND': 'Store command relation not found',
      'API.STORE_METER_RELATION_EXISTS': 'Store meter relation exists',
      'API.STORE_METER_RELATION_NOT_FOUND': 'Store meter relation not found',
      'API.STORE_NAME_IS_ALREADY_IN_USE': 'Store name is already in use',
      'API.STORE_NOT_FOUND': 'Store not found',
      'API.STORE_OFFLINE_METER_RELATION_EXISTS': 'Store offline meter relation exists',
      'API.STORE_OFFLINE_METER_RELATION_NOT_FOUND': 'Store offline meter relation not found',
      'API.STORE_POINT_RELATION_EXISTS': 'Store point relation exists',
      'API.STORE_POINT_RELATION_NOT_FOUND': 'Store point relation not found',
      'API.STORE_SENSOR_RELATION_EXISTS': 'Store sensor relation exists',
      'API.STORE_SENSOR_RELATION_NOT_FOUND': 'Store sensor relation not found',
      'API.STORE_TYPE_NAME_IS_ALREADY_IN_USE': 'Store type name is already in use',
      'API.STORE_TYPE_NOT_FOUND': 'Store type not found',
      'API.STORE_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Store type simplified code is already in use',
      'API.STORE_TYPE_USED_IN_STORE': 'Store type used in store',
      'API.STORE_VIRTUAL_METER_RELATION_EXISTS': 'Store virtual meter relation exists',
      'API.STORE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Store virtual meter relation not found',
      'API.STORE_WORKING_CALENDAR_RELATION_EXISTS': 'Store working calendar relation exists',
      'API.STORE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Store working calendar relation not found',
      'API.TARGET_NODE_NOT_FOUND': 'Target node not found',
      'API.TARIFF_IN_USE': 'Tariff in use',
      'API.TARIFF_IS_ALREADY_ASSOCIATED_WITH_COST_CENTER': 'Tariff is already associated with cost center',
      'API.TARIFF_IS_NOT_ASSOCIATED_WITH_COST_CENTER': 'Tariff is not associated with cost center',
      'API.TARIFF_NAME_IS_ALREADY_IN_USE': 'Tariff name is already in use',
      'API.TARIFF_NOT_FOUND': 'Tariff not found',
      'API.TENANT_COMMAND_RELATION_EXISTS': 'Tenant command relation exists',
      'API.TENANT_COMMAND_RELATION_NOT_FOUND': 'Tenant command relation  not found',
      'API.TENANT_METER_RELATION_EXISTS': 'Tenant meter relation exists',
      'API.TENANT_METER_RELATION_NOT_FOUND': 'Tenant meter relation not found',
      'API.TENANT_NAME_IS_ALREADY_IN_USE': 'Tenant name is already in use',
      'API.TENANT_NOT_FOUND': 'Tenant not found',
      'API.TENANT_OFFLINE_METER_RELATION_EXISTS': 'Tenant offline meter relation exists',
      'API.TENANT_OFFLINE_METER_RELATION_NOT_FOUND': 'Tenant offline meter relation not found',
      'API.TENANT_POINT_RELATION_EXISTS': 'Tenant point relation exists',
      'API.TENANT_POINT_RELATION_NOT_FOUND': 'Tenant point relation not found',
      'API.TENANT_SENSOR_RELATION_EXISTS': 'Tenant sensor relation exists',
      'API.TENANT_SENSOR_RELATION_NOT_FOUND': 'Tenant sensor relation not found',
      'API.TENANT_TYPE_NAME_IS_ALREADY_IN_USE': 'Tenant type name is already in use',
      'API.TENANT_TYPE_NOT_FOUND': 'Tenant type not found',
      'API.TENANT_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Tenant type simplified code is already in use',
      'API.TENANT_TYPE_USED_IN_TENANT': 'Tenant type used in tenant',
      'API.TENANT_VIRTUAL_METER_RELATION_EXISTS': 'Tenant virtual meter relation exists',
      'API.TENANT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Tenant virtual meter relation not found',
      'API.TENANT_WORKING_CALENDAR_RELATION_EXISTS': 'Tenant working calendar relation exists',
      'API.TENANT_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Tenant working calendar relation not found',
      'API.TEXT_MESSAGE_NOT_FOUND': 'Text message not found',
      'API.THE_REPORTING_PERIOD_MUST_BE_LONGER_THAN_15_MINUTES': 'The reporting period must be longer than 15 minutes',
      'API.THERE_IS_RELATION_WITH_CHILD_METERS': 'There is relation with child meters',
      'API.THERE_IS_RELATION_WITH_CHILDREN_SPACES': 'There is relation with children spaces',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENT_PARAMETERS':
        'There is relation with combined equipment parameters',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENTS': 'There is relation with combined equipments',
      'API.THERE_IS_RELATION_WITH_DATA_SOURCES': 'There is relation with data sources',
      'API.THERE_IS_RELATION_WITH_ENERGY_FLOW_DIAGRAM_LINKS': 'There is relation with energy flow diagram links',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_BATTERIES': 'There Is Relation With Energy Storage Containers Batteries',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_GRIDS': 'There Is Relation With Energy Storage Containers Grids',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_LOADS': 'There Is Relation With Energy Storage Containers Loads',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_POWER_CONVERSION_SYSTEMS': 'There Is Relation With Energy Storage Containers Power Conversion Systems',
      'API.THERE_IS_RELATION_WITH_EQUIPMENT_PARAMETERS': 'There is relation with equipment parameters',
      'API.THERE_IS_RELATION_WITH_EQUIPMENTS': 'There is relation with equipments',
      'API.THERE_IS_RELATION_WITH_METER': 'There is relation with meter',
      'API.THERE_IS_RELATION_WITH_METERS': 'There is relation with meters',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METER': 'There is relation with offline meter',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METERS': 'There is relation with offline meters',
      'API.THERE_IS_RELATION_WITH_OTHER_VIRTUAL_METERS': 'There is relation with other virtual meters',
      'API.THERE_IS_RELATION_WITH_SENSORS': 'There is relation with sensors',
      'API.THERE_IS_RELATION_WITH_SHOPFLOORS': 'There is relation with shopfloors',
      'API.THERE_IS_RELATION_WITH_SPACES': 'There is relation with spaces',
      'API.THERE_IS_RELATION_WITH_STORES': 'There is relation with stores',
      'API.THERE_IS_RELATION_WITH_TENANTS': 'There is relation with tenants',
      'API.THERE_IS_RELATION_WITH_USERS': 'There is relation with users',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_METER': 'There is relation with virtual meter',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_POWER_PLANTS': 'Relation avec la centrale électrique virtuelle',
      'API.THIS_DATA_SOURCE_IS_BEING_USED_BY_A_METER': 'This data source is being used by a meter',
      'API.THIS_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This meter is being used by a virtual meter',
      'API.THIS_OFFLINE_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This offline meter is being used by a virtual meter',
      'API.THIS_SPACE_CANNOT_BE_CLONED': 'This space cannot be cloned',
      'API.THIS_SPACE_CANNOT_BE_DELETED': 'This space cannot be deleted',
      'API.TIMEZONE_NOT_FOUND': 'Timezone not found',
      'API.TOKEN_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'Token not found in headers please login',
      'API.USER_ACCOUNT_HAS_BEEN_LOCKED': 'User Account Has Been Locked',
      'API.USER_ACCOUNT_HAS_EXPIRED': 'User Account Has Expired',
      'API.USER_ACCOUNT_IS_NOT_LOCKED': 'User account is not locked',
      'API.USER_NAME_IS_ALREADY_IN_USE': 'User name is already in use',
      'API.USER_NOT_FOUND': 'User not found',
      'API.USER_PASSWORD_HAS_EXPIRED': 'User Password Has Expired',
      'API.USER_PRIVILEGE_NOT_FOUND': 'User privilege not found',
      'API.USER_SESSION_NOT_FOUND': 'User session not found',
      'API.USER_SESSION_TIMEOUT': 'User session timeout',
      'API.USER_UUID_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'User uuid not found in headers please login',
      'API.VIRTUAL_METER_NAME_IS_ALREADY_IN_USE': 'Virtual meter name is already in use',
      'API.VIRTUAL_METER_NOT_FOUND': 'Virtual meter not found',
      'API.VIRTUAL_METER_OF_VARIABLE_NOT_FOUND': 'Virtual meter of variable not found',
      'API.VIRTUAL_POINT_SHOULD_BE_ANALOG_VALUE': 'Virtual point should be analog value',
      'API.WEB_MESSAGE_NOT_FOUND': 'Web message not found',
      'API.WECHAT_MESSAGE_NOT_FOUND': 'Wechat message not found',
      'API.WIND_FARM_NAME_IS_ALREADY_IN_USE': 'Wind farm name is already in use',
      'API.WIND_FARM_NOT_FOUND': 'Wind farm not found',
      'API.WORKING_CALENDAR_NAME_IS_ALREADY_IN_USE': 'Working calendar name is already in use',
      'API.WORKING_CALENDAR_NOT_FOUND': 'Working calendar not found',

      MasterMeter: 'Tableau récapitulatif',
      SubMeter: 'Sous - table'
    }
  },
  es: {
    translation: {
      // routes & menus
      Dashboard: 'Salpicadero',
      'Space Data': 'Espacio',
      'Equipment Data': 'Equipo',
      'Meter Data': 'Metro',
      'Tenant Data': 'Arrendatario',
      'Store Data': 'Tienda',
      'Shopfloor Data': 'Taller',
      'Combined Equipment Data': 'Equipos combinados',
      'Auxiliary System': 'Sistema auxiliar',
      Microgrid: 'Microrred',
      'Energy Storage Power Station': 'Central eléctrica de almacenamiento de energía',
      'Photovoltaic Power Station': 'Power Station',
      'Fault Alarm': 'Alarma de falla',
      Monitoring: 'Monitorización',
      'Advanced Reporting': 'Informes avanzados',
      'Knowledge Base': 'Knowledge base',
      'Energy Category Data': 'Categoría de energía',
      'Energy Item Data': 'Artículo de energía',
      Carbon: 'Emisiones de CO2',
      Cost: 'Costar',
      Output: 'Salida',
      Income: 'Renta',
      Efficiency: 'Eficacia',
      Load: 'Carga',
      Statistics: 'Estadística',
      'Batch Analysis': 'Análisis de lotes',
      Saving: 'Ahorro',
      Plan: 'Plan',
      'Production': 'Production',
      'Space Production': 'Space Production',
      'Equipment Tracking': 'Seguimiento de equipos',
      'Environment Monitor': 'Monitor de entorno',
      'Meter Energy': 'Energía del medidor',
      'Meter Carbon': 'Medir las Emisiones de CO2',
      'Meter Cost': 'Costo del medidor',
      'Meter Trend': 'Tendencia del medidor',
      'Meter Realtime': 'Medidor en tiempo real',
      'Meter Saving': 'Ahorro de medidores',
      'Meter Plan': 'plano del medidor',
      'Master Meter Submeters Balance': 'Master Meter Submeters Balance',
      'Meter Batch Analysis': 'Análisis de lotes de medidores',
      'Meter Comparison': 'Comparación de medidores',
      'Meter Tracking': 'Seguimiento del medidor',
      'Virtual Meter Saving': 'Ahorro de medidores virtuales',
      'Virtual Meter Plan': 'Plan de contadores virtuales',
      'Virtual Meter Energy': 'Medidor virtual de energía',
      'Virtual Meter Carbon': 'Medidor virtual de Emisiones de CO2',
      'Virtual Meter Cost': 'Costo del medidor virtual',
      'Virtual Meter Batch Analysis': 'Análisis por lotes de medidores virtuales',
      'Offline Meter Energy': 'Energía del medidor fuera de línea',
      'Offline Meter Carbon': 'Medición fuera de línea de Emisiones de CO2',
      'Offline Meter Cost': 'Costo del medidor fuera de línea',
      'Offline Meter Batch Analysis': 'Análisis de lotes de medidores fuera de línea',
      'Offline Meter Saving': 'Ahorro de medidores fuera de línea',
      'Offline Meter Plan': 'Plan de medidor sin conexión',
      'Offline Meter Input': 'Entrada de medidor fuera de línea',
      'Tenant Bill': 'Proyecto de ley del inquilino',
      'Energy Flow Diagram': 'Diagrama de flujo de energía',
      'Distribution System': 'Sistema de distribución',
      'SVG System': 'Sistema de visualización SCADA',
      'Faults Data': 'Fallas',
      'Space Equipments': 'Equipos espaciales',
      'Combined Equipments': 'Equipos combinados',
      'Energy Storage Power Station List': 'List',
      'Energy Storage Power Station Details': 'Details',
      'Energy Storage Power Station Reporting': 'Reporting',
      'Energy Storage Power Station Alarm': 'Alarm',
      'Energy Storage Power Station Maintenance': 'Maintenance',
      'Enter Production': 'Enter Production',
      'Multiple Energy Storage Power Stations': 'Multiple Power Stations',
      'Multiple Energy Storage Power Stations Dashboard': 'Dashboard',
      'Multiple Energy Storage Power Stations List': 'List',
      'Single Energy Storage Power Station': 'Single Power Station',
      'Single Energy Storage Power Station Dashboard': 'Dashboard',
      'Single Energy Storage Power Station Details': 'Details',
      'Single Energy Storage Power Station Fault Alarm': 'Fault Alarm',
      'Energy Storage Power Station Reports': 'Power Station Reports',
      'Energy Storage Power Station Reports Energy': 'Energy',
      'Energy Storage Power Station Reports Revenue': 'Revenue',
      'Energy Storage Power Station Reports Parameters': 'Parameters',
      'Photovoltaic Power Station List': 'Power Station List',
      'Photovoltaic Power Station Details': 'Details',
      'Photovoltaic Power Station Reporting': 'Reporting',
      'Photovoltaic Power Station Alarm': 'Alarm',
      'Photovoltaic Power Station Maintenance': 'Maintenance',
      'Enter Production': 'Enter Production',
      'Multiple Photovoltaic Power Stations': 'Multiple Power Stations',
      'Multiple Photovoltaic Power Stations Dashboard': 'Dashboard',
      'Multiple Photovoltaic Power Stations List': 'List',
      'Single Photovoltaic Power Station': 'Single Power Station',
      'Single Photovoltaic Power Station Dashboard': 'Dashboard',
      'Single Photovoltaic Power Station Details': 'Details',
      'Single Photovoltaic Power Station Fault Alarm': 'Fault Alarm',
      'Photovoltaic Power Station Reports': 'Power Station Reports',
      'Photovoltaic Power Station Reports Energy': 'Energy',
      'Photovoltaic Power Station Reports Revenue': 'Revenue',
      'Photovoltaic Power Station Reports Parameters': 'Parameters',
      'Work Order': 'Work Order',
      'Work Order Installation': 'Installation',
      'Work Order Repair': ' Repair',
      'Work Order Inspection': 'Inspection',
      'Total Number of Work Orders': 'Número total de órdenes de trabajo',
      'Number of New Work Orders': 'Número de órdenes de trabajo nuevas',
      'Number of Inprogress Work Orders': 'Número de órdenes de trabajo en curso',
      'Number of Done Work Orders': 'Número de órdenes de trabajo finalizadas',
      'Charge Energy Indicator': 'Energía de carga',
      'Discharge Energy Indicator': 'Energía de descarga',
      'Charge Cost Indicator': 'Costo de carga',
      'Discharge Revenue Indicator': 'Ingresos de descarga',
      'HVAC': 'HVAC',
      'Fire Control': 'Control de incendios',

      // Dashboard
      'Welcome to DoraEOS': 'Bienvenido a DoraEOS',
      'An Industry Leading Open Source Energy Management System':
        'Un sistema de gestión de energía de código abierto líder en la industria',
      "This Year's Consumption CATEGORY VALUE UNIT": 'El consumo de este año {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Year's Costs CATEGORY VALUE UNIT": 'Los costos de este año {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Consumption CATEGORY VALUE UNIT": 'El consumo de este mes {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Costs CATEGORY VALUE UNIT": 'Los costos de este mes {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Consumption CATEGORY VALUE UNIT in The Same Period Last Year':
        'Consumo {{CATEGORY}} {{VALUE}} {{UNIT}}  en el mismo período del año pasado',
      'Costs CATEGORY VALUE UNIT in The Same Period Last Year':
        'Costos {{CATEGORY}} {{VALUE}} {{UNIT}} en el mismo período del año pasado',
      'CATEGORY Consumption UNIT': '{{CATEGORY}} Consumo {{UNIT}}',
      'CATEGORY Costs UNIT': '{{CATEGORY}} Costos {{UNIT}}',
      // Entities
      Space: 'Espacio',
      Equipment: 'Equipo',
      Meter: 'Metro',
      'Offline Meter': 'Medidor fuera de línea',
      'Virtual Meter': 'Medidor virtual',
      Tenant: 'Arrendatario',
      Store: 'Tienda',
      Shopfloor: 'Taller',
      'Combined Equipment': 'Equipos combinados',
      'Cost Center': 'Centro de Costos',
      Name: 'Nombre',
      DisplayName: 'Nombre para mostrar',
      UserName: 'Nombre de usuario',
      Description: 'Descripción',
      'Energy Category': 'Categoría de energía',
      'Child Spaces': 'Espacios infantiles',
      'Associated Equipment': 'Equipos asociados',
      //Energy Categories
      Electricity: 'Electricidad',
      Water: 'Agua',
      'Natural Gas': 'Gas natural',
      // SidePanelModal
      Settings: 'Configuración',
      'Set your own customized style': 'Establece tu propio estilo personalizado',
      'Color Scheme': 'Esquema de color',
      'Choose the perfect color mode for your app': 'Elige el modo de color perfecto para tu aplicación',
      Light: 'Luz',
      Dark: 'Oscuro',
      'RTL Mode': 'Modo RTL',
      'Switch your language direction': 'Cambiar la dirección del idioma',
      'Fluid Layout': 'Diseño fluido',
      'Toggle container layout system': 'Alternar sistema de diseño de contenedores',
      'Navigation Position': 'Posición de navegación',
      'Select a suitable navigation system for your web application':
        'Seleccione un sistema de navegación adecuado para su aplicación web',
      'Vertical Nav': 'Navegación vertical',
      'Top Nav': 'Navegación superior',
      'Vertical Navbar Style': 'Estilo de barra de navegación vertical',
      'Switch between styles for your vertical navbar': 'Cambiar entre estilos para la barra de navegación vertical',
      Language: 'Idioma',
      'Switch between languages': 'Cambiar entre idiomas',
      'language-zh_CN': '简体中文',
      'language-en': 'English',
      'language-de': 'Deutsch',
      'language-fr': 'Français',
      'language-es': 'Español',
      'language-ru': 'Русский',
      'language-ar': 'العربية',
      'language-vi': 'Tiếng Việt',
      'language-th': 'ภาษาไทย',
      'language-tr': 'Türkçe',
      'language-ms': 'Bahasa Melayu',
      'language-id': 'Bahasa Indonesia',
      'language-zh_TW': '繁體中文',
      'Like What You See?': 'Me gusta lo que ves?',
      'Get DoraEOS now': 'Obtenga DoraEOS ahora',
      Purchase: 'Compra',
      // Query Panel
      'Base Period Begins': 'Comienza el período base',
      'Base Period Ends': 'Finaliza el período base',
      'Comparison Types': 'Tipos de comparación',
      'Year-Over-Year': 'Año tras año',
      'Month-On-Month': 'Mes a mes',
      'Free Comparison': 'Comparación gratuita',
      'None Comparison': 'Ninguna Comparación',
      'Reporting Period Begins': 'Comienza el período del informe',
      'Reporting Period Ends': 'Finaliza el período de presentación de informes',
      '(Optional)': '(Opcional)',
      'Period Types': 'Tipos de período',
      Yearly: 'Anual',
      Monthly: 'Mensual',
      Weekly: 'Semanal',
      Daily: 'Diario',
      Hourly: 'Cada hora',
      Submit: 'Enviar',
      'Input Energy Category': 'Categoría de energía de entrada',
      'Output Energy Category': 'Categoría de energía de salida',
      'Fraction Parameter': 'Parámetro Fraction',
      Search: 'Buscar',
      //DateRangePicker
      sunday: 'Su',
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      ok: 'De acuerdo',
      today: 'Hoy',
      yesterday: 'Ayer',
      hours: 'Horas',
      minutes: 'Acta',
      seconds: 'Sobras',
      last7Days: 'Últimos 7 días',
      'Select Date Range': 'Seleccionar intervalo de fechas',
      //Card Summaries and Line Charts
      'Base Period': 'Período base',
      'Reporting Period': 'Período',
      'Per Unit Area': 'Por unidad de área',
      'Per Capita': 'Per Capita',
      'Per Unit Production': 'Por unidad de producción',
      Baseline: 'Referencia',
      Actual: 'Actual',
      'Baseline Value - Actual Value': 'Línea base - Actual',
      'Average Load': 'Carga media',
      'Maximum Load': 'Carga máxima',
      'Load Factor': 'Factor de carga',
      'Ratio of Average Load to Maximum Load': 'Relación entre la carga media y la carga máxima',
      'Carbon Dioxide Emissions by Energy Category': 'Emisiones de dióxido de carbono por categoría de energía',
      'Costs by Energy Category': 'Costos por categoría de energía',
      'Incomes by Energy Category': 'Ingresos por categoría de energía',
      'Electricity Consumption by Time-Of-Use': 'Consumo de electricidad por tiempo de uso',
      'Electricity Carbon Dioxide Emissions by Time-Of-Use':
        'Emisiones de dióxido de carbono de electricidad por tiempo de uso',
      'Electricity Cost by Time-Of-Use': 'Costo de electricidad por tiempo de uso',
      'CATEGORY UNIT Consumption by Energy Items': '{{CATEGORY}} {{UNIT}} Consumo por partidas energéticas',
      'Ton of Standard Coal by Energy Category': 'Tonelada de carbón estándar(TCE) por categoría de energía',
      'Ton of Carbon Dioxide Emissions by Energy Category':
        'Toneladas de emisiones de dióxido de carbono(TCO2E) por categoría de energía',
      'Reporting Period Consumption CATEGORY UNIT': 'Consumo del período de informe {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{EQUIPMENT}} Consumo del período de informe {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Consumo del período de informe {{CATEGORY}} {{UNIT}}',
      'CATEGORY VALUE UNIT': '{{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption CATEGORY VALUE UNIT':
        'Consumo del período de informe {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Production PRODUCT VALUE UNIT': 'Reporting Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Production PRODUCT VALUE UNIT': 'Base Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption CATEGORY VALUE UNIT': 'Consumo del período base {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY UNIT':
        'Consumo del período de informe {{ITEM}} {{CATEGORY}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY VALUE UNIT':
        'Consumo del período de informe {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption ITEM CATEGORY VALUE UNIT':
        'Consumo del período base {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Child Space Proportion CATEGORY UNIT':
        'Proporción del espacio infantil por categoría de energía {{CATEGORY}} {{UNIT}}',
      'Child Space Total Proportion': 'Espacio infantil Proporción total',
      'Reporting Period Carbon Dioxide Emissions CATEGORY UNIT':
        'Período de presentación de informes Emisiones de dióxido de carbono {{CATEGORY}} {{UNIT}}',
      'Reporting Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Período de presentación de informes Emisiones de dióxido de carbono {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Emisiones de dióxido de carbono del período base {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Costs CATEGORY UNIT': 'Costos del período de informe {{CATEGORY}} {{UNIT}}',
      'Reporting Period Costs CATEGORY VALUE UNIT': 'Costos del período de informe {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Costs CATEGORY VALUE UNIT': 'Costos del período base {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Output CATEGORY UNIT': 'Resultados del período del informe {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{EQUIPMENT}} Resultados del período del informe {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Resultados del período del informe {{CATEGORY}} {{UNIT}}',
      'Reporting Period Output CATEGORY VALUE UNIT':
        'Resultados del período del informe {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Output CATEGORY VALUE UNIT': 'Resultado del período base {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Income CATEGORY UNIT': 'Ingresos del período del informe {{CATEGORY}} {{UNIT}}',
      'Reporting Period Income CATEGORY VALUE UNIT': 'Ingresos del período del informe {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Income CATEGORY VALUE UNIT': 'Ingresos del período base {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Total Income UNIT': 'Ingresos totales del período del informe {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME UNIT':
        'Eficiencia acumulada del período de presentación de informes {{NAME}} {{UNIT}}',
      'EQUIPMENT Reporting Period Cumulative Efficiency UNIT':
        '{{EQUIPMENT}} Eficiencia acumulada del período de presentación de informes {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Período de presentación de informes Eficiencia integral acumulada {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Período de presentación de informes Eficiencia integral acumulada {{VALUE}} {{UNIT}}',
      'COMBINED_EQUIPMENT Base Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Período base Eficiencia integral acumulada {{VALUE}} {{UNIT}}',
      'Instantaneous Efficiency UNIT': 'Eficiencia instantánea {{UNIT}}',
      'EQUIPMENT Instantaneous Efficiency UNIT': '{{EQUIPMENT}} Eficiencia instantánea {{UNIT}}',
      'COMBINED_EQUIPMENT Instantaneous Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Eficiencia integral instantánea {{UNIT}}',
      'Reporting Period Cumulative Efficiency VALUE UNIT':
        'Eficiencia acumulada del período de presentación de informes {{VALUE}} {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME VALUE UNIT':
        'Eficiencia acumulada del período de presentación de informes {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency VALUE UNIT': 'Eficiencia acumulada del período base {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency NAME VALUE UNIT':
        'Eficiencia acumulada del período base {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period CATEGORY Maximum Load UNIT': 'Período base {{CATEGORY}} Carga máxima {{UNIT}}',
      'Reporting Period CATEGORY Maximum Load UNIT': 'Período {{CATEGORY}} Carga máxima {{UNIT}}',
      'Reporting Period CATEGORY Average Load UNIT': 'Período {{CATEGORY}} Carga media {{UNIT}}',
      'Reporting Period CATEGORY Load Factor': 'Período {{CATEGORY}} Factor de carga',
      'Base Period CATEGORY Average Load VALUE UNIT': 'Período base {{CATEGORY}} Carga media {{VALUE}} {{UNIT}}',
      'Reporting Period CATEGORY Average UNIT': 'Período {{CATEGORY}} Valor medio {{UNIT}}',
      'Reporting Period CATEGORY Maximum UNIT': 'Período {{CATEGORY}} Valor máximo {{UNIT}}',
      'Reporting Period CATEGORY Minimum UNIT': 'Período {{CATEGORY}} Valor mínimo {{UNIT}}',
      'Reporting Period CATEGORY Mean UNIT': 'Período {{CATEGORY}} Media aritmética {{UNIT}}',
      'Reporting Period CATEGORY Median UNIT': 'Período {{CATEGORY}} Mediana (Valor medio) {{UNIT}}',
      'Reporting Period CATEGORY Stdev UNIT': 'Período {{CATEGORY}} Desviación estándar de la muestra {{UNIT}}',
      'Reporting Period CATEGORY Variance UNIT': 'Período {{CATEGORY}} Varianza de la muestra {{UNIT}}',
      'Reporting Period Saving CATEGORY (Baseline - Actual) UNIT':
        'Ahorro del período de informe {{CATEGORY}} (Línea base - Actual) {{UNIT}}',
      'Reporting Period Decreased CATEGORY (Baseline - Actual) UNIT':
        'Período de informe disminuido {{CATEGORY}} (Línea base - Actual) {{UNIT}}',
      'Reporting Period Saving CATEGORY VALUE UNIT': 'Ahorro del período de informe {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Saving CATEGORY VALUE UNIT': 'Ahorro del período base {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY UNIT':
        'Período de informe Consumo del medidor maestro {{CATEGORY}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY VALUE UNIT':
        'Período de informe Consumo del medidor maestro {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY UNIT':
        'Período del informe Consumo de submedidores {{CATEGORY}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY VALUE UNIT':
        'Período del informe Consumo de submedidores {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Difference CATEGORY UNIT': 'Diferencia en el período del informe {{CATEGORY}} {{UNIT}}',
      'Reporting Period Percentage Difference': 'Diferencia porcentual del período del informe',
      'Reporting Period Difference CATEGORY VALUE UNIT':
        'Diferencia en el período del informe {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Trend Values': 'Valores de tendencia',
      'METER CATEGORY VALUE UNIT': '{{METER}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      //FDD
      'Fault List': 'Lista de fallos',
      'Reporting Period Number of Fault VALUE': 'Período de informe Número de falla {{VALUE}}',
      'All Faults': 'Todas las fallas',
      'Space Faults': 'Fallas de espacio',
      'Number of All Faults': 'Número de todas las fallas',
      'Number of Space Faults': 'Número de fallos de espacio',
      'Total Number of Faults': 'Total Number of Faults',
      'Number of New Faults': 'Number of New Faults',
      'Number of Inprogress Faults': 'Number of Inprogress Faults',
      'Number of Done Faults': 'Number of Done Faults',
      //Data Panels
      'Ton of Standard Coal': 'Tonelada de carbón estándar',
      'Ton of Carbon Dioxide Emissions': 'Toneladas de emisiones de dióxido de carbono',
      'Operating Characteristic Curve': 'Curva característica de trabajo',
      Tariff: 'Arancel',
      'Detailed Data': 'Datos detallados',
      'Child Spaces Data': 'Datos de espacios secundarios',
      'Associated Equipment Data': 'Datos de equipos asociados',
      'This Year': 'Este año',
      'The Same Period Last Year': 'El mismo período del año pasado',
      'This Month': 'Este mes',
      'This Day': 'Este día',
      Total: 'Total',
      'No data found': 'No se han encontrado datos',
      Export: 'Exportar',
      Datetime: 'Fecha y hora',
      'Time-Of-Use Type': 'Tipo de tiempo de uso',
      'Top-Peak': 'Pico superior',
      'On-Peak': 'En horas pico',
      'Mid-Peak': 'Pico medio',
      'Off-Peak': 'Fuera de las horas pico',
      Percentage: 'Porcentaje',
      //Realtime Chart
      'Trend in the last hour of Energy Value Point': 'Tendencia en la última hora de Energy Value Point',
      Circuit: 'Circuito',
      Point: 'Punto',
      'Realtime Value': 'Valor en tiempo real',
      //Meter Tracking
      'Meter List': 'Lista de medidores',
      'Edit Meter': 'Editar',
      'Start Value': 'Valor inicial',
      'End Value': 'Valor final',
      'Difference Value': 'Valor de diferencia',
      'Start Integrity Rate': 'Tasa de integridad de inicio(%)',
      'End Integrity Rate': 'Tasa de integridad final(%)',
      'Full Integrity Rate': 'Tasa de integridad total(%)',
      //Equipment Tracking
      'Equipment List': 'Lista de equipos',
      'Edit Equipment': 'Editar',
      //Profile Dropdown
      Feedback: 'Retroalimentación',
      'Account Settings': 'Configuración de la cuenta',
      Logout: 'Cerrar sesión',
      //Authentication
      'Log in': 'Inicia sesión',
      'Email address': 'Dirección de correo electrónico',
      Password: 'Contraseña',
      CaptchaCode: 'Captcha',
      Refresh: 'Actualizar',
      'Remember me': 'Recuérdame',
      'Logged in as ': 'Conectado como ',
      'Forgot Password?': 'Olvidé mi contraseña?',
      'Forgot your password?': 'Olvidó su contraseña?',
      "Enter your email and we'll send you a reset link":
        'Introduce tu correo electrónico y te enviaremos un enlace de restablecimiento',
      "Enter your email and we'll send you a registration link":
        'Introduce tu email y te enviaremos un enlace de registro',
      'Reset Password': 'Restablecer contraseña',
      'Thanks for using DoraEOS!': 'Gracias por usar DoraEOS!',
      'You are now successfully signed out': 'Ahora ha cerrado sesión correctamente',
      'Return to Login': 'Volver al inicio de sesión',
      'Please check your email!': 'Por favor, revise su correo electrónico!',
      'An email has been sent to ': 'Se ha enviado un correo electrónico a ',
      'Please click on the included link to reset your password':
        'Haga clic en el enlace incluido para restablecer su contraseña',
      'Please click on the included link to register your account':
        'Haga clic en el enlace incluido para registrar su cuenta',
      'An email with password reset link is sent to ':
        'Se envía un correo electrónico con el enlace de restablecimiento de contraseña a ',
      'Change Password': 'Cambiar contraseña',
      'Old Password': 'Contraseña antigua',
      'New Password': 'Nueva contraseña',
      'Confirm Password': 'Confirmar contraseña',
      'Update Password': 'Actualizar contraseña',
      'Password has been changed!': 'Se ha cambiado la contraseña!',
      'Verification code has been sent to your inbox. \
      Please copy it to the input box below..':
        'Se ha enviado un código de verificación a tu bandeja de entrada. \
      Cópielo en el cuadro de entrada a continuación.',
      'New to DoraEOS': 'Nuevo en DoraEOS',
      'Create an account': 'Crear una cuenta',
      'EMAIL Account registration successful': '{{EMAIL}} Registro de cuenta exitoso',
      'Thanks for verifying your account!': 'Gracias por verificar tu cuenta!',
      'Your code is': 'Su código es',
      'Send verification code': 'Enviar código de verificación',
      'Please wait for NUMBER seconds': 'Por favor, espere a {{NUMBER}} sobras',
      'Password reset': 'Restablecimiento de contraseña',
      'Please wait for approval': 'Por favor, espere la aprobación',
      //notification
      Notifications: 'Notificaciones',
      'Mark all as read': 'Marcar todo como leído',
      'View all': 'Todo',
      'Notification New': 'NUEVO',
      'Notification Earlier': 'ANTERIOR',
      'Notification List': 'Lista de notificaciones',
      'Bulk actions': 'Acciones masivas',
      'Notification Subject': 'Asunto',
      'Notification Created Datetime': 'Fecha y hora de creación',
      'Notification Priority': 'Prioridad',
      'Notification Message': 'Mensaje',
      'Notification Status': 'Estado',
      'Notification Mark As Read': 'Marcar como leído',
      'Notification Mark As Acknowledged': 'Reconocer',
      'Notification Low': 'Bajo',
      'Notification Medium': 'Medio',
      'Notification High': 'Alto',
      'Notification Critical': 'Crítico',
      'Notification Unread': 'No leído',
      'Notification Read': 'Leer',
      'Notification Acknowledged': 'Reconocido',
      'Notification Archive': 'Archivo',
      'Notification Delete': 'Borrar',
      'Notification Apply': 'Aplicar',
      'Notification Start Datetime': 'Fecha de inicio y hora',
      'Notification End Datetime': 'Fecha y hora de finalización',
      'Notification Update Datetime': 'Actualizar fecha y hora',
      //Monitoring
      'Run Commands': 'Ejecutar comandos',
      'Fault Alarms': 'Alarmas de avería',
      'Instantaneous Efficiency VALUE UNIT': 'Eficiencia instantánea {{VALUE}} {{UNIT}}',
      'Communication Online': 'En línea',
      'Communication Offline': 'Sin conexión',
      'Equipment Status': 'Estado del dispositivo',
      'Equipment Running': 'Corriente',
      'Equipment Stopped': 'Detenido',
      'Show Up to': 'Presentarse a',
      All: 'Todo',
      'FROM - TO of TOTAL': 'Actualmente se muestra {{FROM}} - {{TO}} total {{TOTAL}}',
      //Microgrid
      'PCS Run State': 'Estado de funcionamiento del PCs',
      'PCS Unknown': 'Desconocido',
      'PCS Initializing': 'Se está iniciando',
      'PCS Standby': 'Artículos de repuesto',
      'PCS Shutdown': 'Parada',
      'PCS Fault': 'Culpa',
      'PCS Running': 'Correr',
      'Battery Power': 'Potencia de la batería',
      'Photovoltaic Power': 'Фотоэлектрическая мощность',
      'Grid Power': 'Potencia de la red eléctrica',
      'Load Power': 'Potencia de carga',
      Reporting: 'Informe',
      Maintenance: 'Mantenimiento',
      'Serial Number': 'Numéro de série',
      Address: 'Adresse de résidence',
      'Postal Code': 'Code Postal',
      'Rated Capacity': 'Capacidad nominal',
      'Rated Power': 'Potencia nominal',
      Latitude: 'Latitude',
      Longitude: 'Longitude',
      'Strategy Management': 'Gestión estratégica',
      'Charging Schedule': 'Calendrier de charge',
      'Charge Start Time': 'Heure de début de charge',
      'Charge End Time': 'Temps de fin de charge',
      'Discharge Start Time': 'Heure de début de décharge',
      'Discharge End Time': 'Temps de fin de décharge',
      'Total Rated Power': 'Potencia nominal total',
      'Total Rated Capacity': 'Capacidad nominal total',
      "Today's Charge": "Carga hoy",
      "Today's Discharge": "Descarga de electricidad hoy",
      "Today's Generation": "La generación de energía actual",
      'Total Charge': 'Carga total',
      'Total Discharge': 'descarga total',
      'Total Generation': 'Generación total',
      'Total Revenue': 'Los ingresos totales',
      'Total Efficiency': 'Eficiencia total',
      'Discharge Achievement Rate': 'Tasa de logro de descarga',
      // Revenue Indicator
      "Today's Cost": "Today's Cost",
      "Today's Income": "Today's Income",
      "Total Cost": "Total Cost",
      "Total Income": "Total Income",
      "Today's Revenue": "Today's Revenue",

      'Revenue Ranking': 'Clasificación de ingresos',
      'Efficiency Ranking': 'Clasificación de eficiencia',
      'Charge Ranking': 'Clasificación de capacidad de carga',
      'Discharge Ranking': 'Clasificación de alta',
      'Number of Microgrids': 'Número de microrredes',
      'Number of Power Stations': 'Número de centrales eléctricas',
      'Microgrid List': 'Lista de microrredes',
      'Previous Page': 'Pagina anterior',
      'Next Page': 'Siguiente página',
      '7 Days': '7 días',
      'Charge UNIT': 'Cargar {{UNIT}}',
      'Discharge UNIT': 'descargar {{UNIT}}',
      'Generation UNIT': 'producir electricidad {{UNIT}}',
      'Energy Indicator': 'energía',
      'Revenue Indicator': 'rendimiento',
      'Carbon Indicator': 'carbono',

      // Energy Storage Power Station
      'Battery Operating State': 'Estado de funcionamiento de la batería',
      'Battery State': 'Estado de la batería',
      'Battery Unknown': 'Desconocido',
      'Battery Stopped': 'Se ha detenido',
      'Battery Reserved': 'Reserved', // 0
      'Battery Fault': 'Culpa', // 1
      'Battery Warning': 'Aviso', // 2
      'Battery Standby': 'Artículos de repuesto', // 3
      'Battery Prohibit DisCharging': 'Prohibición de descargar', // 4
      'Battery Prohibit Charging': 'Está prohibido cargar', // 5
      'Battery Normal': 'Típico', // 6
      'Battery Charging': 'En carga', // 7
      'Battery Discharging': 'En descarga', // 8
      'Battery Idle': 'Idle', // 9
      'Phase of Lifecycle': 'Fase del ciclo de vida',
      'Use Phase': 'Fase de uso',
      'Commissioning Phase': 'Fase de puesta en marcha',
      'Installation Phase': 'Fase de instalación',
      'Device Status': 'Estado del dispositivo',
      'Basic Information': 'Información básica',
      'Gateway': 'Gateway',
      'Grid Meter': 'Grid Meter',
      'Load Meter': 'Load Meter',
      'Total Active Power': 'Total Active Power',
      'Active Power A': 'Active Power A',
      'Active Power B': 'Active Power B',
      'Active Power C': 'Active Power C',
      'Total Reactive Power': 'Total Reactive Power',
      'Reactive Power A': 'Reactive Power A',
      'Reactive Power B': 'Reactive Power B',
      'Reactive Power C': 'Reactive Power C',
      'Total Apparent Power': 'Total Apparent Power',
      'Apparent Power A': 'Apparent Power A',
      'Apparent Power B': 'Apparent Power B',
      'Apparent Power C': 'Apparent Power C',
      'Total Power Factor': 'Total Power Factor',
      'Active Energy Import': 'Active Energy Import',
      'Active Energy Export': 'Active Energy Export',
      'Active Energy Net': 'Active Energy Net',

      // Photovoltaic Power Station
      'Invertor': 'inversores',
      'Invertor Run State': 'Estado de funcionamiento',
      'Invertor Unknown': 'Desconocido',
      'Invertor Initializing': 'Se está iniciando',
      'Invertor Standby': 'Artículos de repuesto',
      'Invertor Shutdown': 'Parada',
      'Invertor Fault': 'Culpa',
      'Invertor Running': 'Correr',

      //Advanced Reporting & Knowledge base
      'Created Datetime': 'Fecha y hora creada',
      'File Format': 'Formato de archivo',
      'File Size': 'Tamaño del archivo',
      Uploader: 'Cargador',
      //Error
      "The page you're looking for is not found": 'La página que estás buscando no se encuentra',
      "Make sure the address is correct and that the page hasn't moved. ":
        'Asegúrese de que la dirección sea correcta y que la página no se haya movido. ',
      'If you think this is a mistake,': 'Si crees que esto es un error,',
      'contact us': 'contáctenos',
      'Take me home': 'Llévame a casa',
      'Whoops, something went wrong!': 'Vaya, algo salió mal!',
      'Try refreshing the page, or going back and attempting the action again. ':
        'Intente actualizar la página o retroceda e intente la acción nuevamente. ',
      'If this problem persists,': 'Si este problema persiste,',
      'Captcha Error': 'Error de captcha',
      'Confirm Password Error': 'La contraseña de confirmación no coincide',
      'It looks like you clicked on an invalid password reset link. Please tryagain.':
        'Parece que hiciste clic \
      en un vínculo de restablecimiento de contraseña no válido. Inténtalo de nuevo.',
      'It looks like you clicked on an invalid registration account link. Please tryagain.':
        'Parece que hiciste clic \
      en un vínculo de cuenta de registro no válido. Inténtalo de nuevo.',
      //Tenant Bill
      'Lease Contract Number': 'Número de contrato de arrendamiento',
      Download: 'Descargar',
      Print: 'Impresión',
      'Payment Notice': 'Aviso de pago',
      'Bill To': 'Facturar a',
      'Bill Number': 'Número de factura',
      'Bill Date': 'Fecha de facturación',
      'Payment Due Date': 'Fecha de vencimiento del pago',
      'Amount Payable': 'Importe a pagar',
      'Billing Period Start': 'Inicio del período de facturación',
      'Billing Period End': 'Fin del período de facturación',
      Quantity: 'Cantidad',
      Price: 'Precio',
      Unit: 'Unidad',
      Amount: 'Importe',
      Subtotal: 'Subtotal',
      'VAT Output Tax': 'IVA repercutido',
      'Total Amount Payable': 'Importe total a pagar',
      'Please make sure to pay on or before the payment due date above':
        'Asegúrese de pagar en o antes de la fecha de vencimiento del pago anterior',
      'Send money to the following account': 'Enviar dinero a la siguiente cuenta',
      'Account Name': 'Nombre de la cuenta',
      'Bank Name': 'Nombre del banco',
      'Bank Address': 'Dirección del banco',
      'RMB Account': 'Cuenta en RMB',
      // button
      'Toggle Navigation': 'Alternar navegación',
      // working calendar
      'Working Days': 'Días laborables',
      'Non Working Days': 'Días no laborables',
      'Select Row': 'Seleccione Fila de datos',
      // Offline Meter Input
      'Daily Value': 'Valor diario',
      Date: 'Fecha',
      'Successfully Saved': 'Guardado correctamente',
      'Previous Data Is Empty': 'Los datos anteriores están vacíos',
      // API
      'API.ACCOUNT_UNLOCK_FAILED': 'Account unlock failed',
      'API.ADMINISTRATOR_SESSION_NOT_FOUND': 'Administrator session not found',
      'API.ADMINISTRATOR_SESSION_TIMEOUT': 'Administrator session timeout',
      'API.ADVANCED_REPORT_NOT_FOUND': 'Advanced report not found',
      'API.API_KEY_HAS_EXPIRED': 'Key has expired',
      'API.API_KEY_NAME_IS_ALREADY_IN_USE': 'Key name is already in use',
      'API.API_KEY_NOT_FOUND': 'Key not found',
      'API.BAD_REQUEST': 'Bad request',
      'API.BALANCING_PRICE_POINT_NOT_FOUND': 'Balancing price point not found',
      'API.BATTERY_STATE_POINT_NOT_FOUND': 'Battery state point not found',
      'API.BUY_METER_NOT_FOUND': 'Buy meter not found',
      'API.CANNOT_SET_EXISTING_SUBMETER_AS_MASTER_METER': 'Cannot set existing submeter as master meter',
      'API.CHARGE_METER_NOT_FOUND': 'Charge meter not found',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_EXISTS': 'Combined equipment command relation exists',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Combined equipment command relation not found',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_EXISTS': 'Combined equipment equipment relation exists',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_NOT_FOUND': 'Combined equipment equipment relation not found',
      'API.COMBINED_EQUIPMENT_METER_RELATION_EXISTS': 'Combined equipment meter relation exists',
      'API.COMBINED_EQUIPMENT_METER_RELATION_NOT_FOUND': 'Combined equipment meter relation not found',
      'API.COMBINED_EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Combined equipment name is already in use',
      'API.COMBINED_EQUIPMENT_NOT_FOUND': 'Combined equipment not found',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Combined equipment offline meter relation exists',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Combined equipment offline meter relation not found',
      'API.COMBINED_EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Combined equipment parameter name is already in use',
      'API.COMBINED_EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Combined equipment parameter not found or not match',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Combined equipment virtual meter relation exists',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Combined equipment virtual meter relation not found',
      'API.COMMAND_NAME_IS_ALREADY_IN_USE': 'Command name is already in use',
      'API.COMMAND_NOT_FOUND': 'Command not  found',
      'API.CONTACT_NAME_IS_ALREADY_IN_USE': 'Contact name is already in use',
      'API.CONTACT_NOT_FOUND': 'Contact not found',
      'API.COOLING_METER_NOT_FOUND': 'Cooling meter not found',
      'API.COST_CENTER_EXTERNAL_ID_EXISTS': 'Cost center external id exists',
      'API.COST_CENTER_NAME_EXISTS': 'Cost center name exists',
      'API.COST_CENTER_NOT_FOUND': 'Cost center not found',
      'API.COST_FILE_NOT_FOUND': 'Cost file not found',
      'API.DATA_REPAIR_FILE_NOT_FOUND': 'Data repair file not found',
      'API.DATA_SOURCE_NAME_IS_ALREADY_IN_USE': 'Data source name is already in use',
      'API.DATA_SOURCE_NOT_FOUND': 'Data source not found',
      'API.DATABASE_ERROR': 'Database error',
      'API.DATE_IS_ALREADY_IN_WORKING_CALENDAR': 'Date Is Already In Working Calendar',
      'API.DATE_LOCAL_NOT_FOUND': 'Date local not found',
      'API.DISCHARGE_METER_NOT_FOUND': 'Discharge meter not found',
      'API.DISTRIBUTION_CIRCUIT_NAME_IS_ALREADY_IN_USE': 'Distribution circuit name is already in use',
      'API.DISTRIBUTION_CIRCUIT_NOT_FOUND': 'Distribution circuit not found',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_EXISTS': 'Distribution circuit point relation exists',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_NOT_FOUND': 'Distribution circuit point relation not found',
      'API.DISTRIBUTION_SYSTEM_NAME_IS_ALREADY_IN_USE': 'Distribution system name is already in use',
      'API.DISTRIBUTION_SYSTEM_NOT_FOUND': 'Distribution system not found',
      'API.ELECTRICITY_METER_NOT_FOUND': 'Electricity meter not found',
      'API.EMAIL_IS_ALREADY_IN_USE': 'Email is already in use',
      'API.EMAIL_MESSAGE_NOT_FOUND': 'Email message not found',
      'API.EMAIL_NOT_FOUND': 'Email not found',
      'API.EMAIL_SERVER_HOST_IS_ALREADY_IN_USE': 'Email server host is already in use',
      'API.EMAIL_SERVER_NOT_FOUND': 'Email server not found',
      'API.EMPTY_VARIABLES_ARRAY': 'Empty variables array',
      'API.ENERGY_CATEGORY_NAME_IS_ALREADY_IN_USE': 'Energy category name is already in use',
      'API.ENERGY_CATEGORY_NOT_FOUND': 'Energy category not found',
      'API.ENERGY_CATEGORY_USED_IN_ENERGY_ITEMS': 'Energy category used in energy items',
      'API.ENERGY_CATEGORY_USED_IN_METER': 'Energy category used in meter',
      'API.ENERGY_CATEGORY_USED_IN_OFFLINE_METER': 'Energy category used in offline meter',
      'API.ENERGY_CATEGORY_USED_IN_TARIFFS': 'Energy category used in tariffs',
      'API.ENERGY_CATEGORY_USED_IN_VIRTUAL_METER': 'Energy category used in virtual meter',
      'API.ENERGY_FLOW_DIAGRAM_LINK_IS_ALREADY_IN_USE': 'Energy flow diagram link is already in use',
      'API.ENERGY_FLOW_DIAGRAM_LINK_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram link not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram node name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram node not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NOT_FOUND': 'Energy flow diagram not found',
      'API.ENERGY_ITEM_DOES_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item does not belong to energy category',
      'API.ENERGY_ITEM_IS_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item is not belong to energy category',
      'API.ENERGY_ITEM_NAME_IS_ALREADY_IN_USE': 'Energy item name is already in use',
      'API.ENERGY_ITEM_NOT_FOUND': 'Energy item not found',
      'API.ENERGY_ITEM_USED_IN_METER': 'Energy item used in meter',
      'API.ENERGY_ITEM_USED_IN_OFFLINE_METER': 'Energy item used in offline meter',
      'API.ENERGY_ITEM_USED_IN_VIRTUAL_METER': 'Energy item used in virtual meter',
      'API.ENERGY_STORAGE_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Energy storage power station name is already in use',
      'API.ENERGY_STORAGE_POWER_STATION_NOT_FOUND': 'Energy storage power station not found',
      'API.EQUIPMENT_COMMAND_RELATION_EXISTS': 'Equipment command relation exists',
      'API.EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Equipment command relation not found',
      'API.EQUIPMENT_METER_RELATION_EXISTS': 'Equipment meter relation exists',
      'API.EQUIPMENT_METER_RELATION_NOT_FOUND': 'Equipment meter relation not found',
      'API.EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Equipment name is already in use',
      'API.EQUIPMENT_NOT_FOUND': 'Equipment not found',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Equipment offline meter relation exists',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Equipment offline meter relation not found',
      'API.EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Equipment parameter name is already in use',
      'API.EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Equipment parameter not found or not match',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Equipment virtual meter relation exists',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Equipment virtual meter relation not found',
      'API.ERROR': 'Error',
      'API.EXCEPTION': 'Exception',
      'API.FAILED_TO_READ_REQUEST_STREAM': 'Failed to read request stream',
      'API.FAILED_TO_RESTORE_COST_FILE': 'Failed to restore cost file',
      'API.FAILED_TO_RESTORE_DATA_REPAIR_FILE': 'Failed to restore data repair file',
      'API.FAILED_TO_RESTORE_KNOWLEDGE_FILE': 'Failed to restore knowledge file',
      'API.FAILED_TO_RESTORE_OFFLINE_METER_FILE': 'Failed to restore offline meter file',
      'API.FAILED_TO_SAVE_OFFLINE_METER_FILE': 'Failed to save offline meter file',
      'API.FAILED_TO_UPLOAD_ATTACHMENT_FILE': 'Failed to upload attachment file',
      'API.FAILED_TO_UPLOAD_COST_FILE': 'Failed to upload cost file',
      'API.FAILED_TO_UPLOAD_DATA_REPAIR_FILE': 'Failed to upload data repair file',
      'API.FAILED_TO_UPLOAD_KNOWLEDGE_FILE': 'Failed to upload knowledge file',
      'API.FAILED_TO_UPLOAD_OFFLINE_METER_FILE': 'Failed to upload offline meter file',
      'API.GATEWAY_NAME_IS_ALREADY_IN_USE': 'Gateway name is already in use',
      'API.GATEWAY_NOT_FOUND': 'Gateway not found',
      'API.HEAT_METER_NOT_FOUND': 'Heat meter not found',
      'API.INVALID_': 'Invalid ',
      'API.INVALID_ACKNOWLEDGE_CODE': 'Invalid acknowledge code',
      'API.INVALID_ADDRESS': 'Invalid address',
      'API.INVALID_ADDRESS_VALUE': 'Invalid address value',
      'API.INVALID_ADVANCED_REPORT_ID': 'Invalid advanced report id',
      'API.INVALID_API_KEY_ID': 'Invalid api key id',
      'API.INVALID_API_KEY_NAME': 'Invalid api key name',
      'API.INVALID_AREA_VALUE': 'Invalid area value',
      'API.INVALID_BALANCING_PRICE_POINT_ID': 'Invalid balancing price point ID',
      'API.INVALID_BASE_PERIOD_END_DATETIME': 'Invalid base period end datetime',
      'API.INVALID_BASE_PERIOD_START_DATETIME': 'Invalid base period start datetime',
      'API.INVALID_BATTERY_STATE_POINT_ID': 'Invalid battery state point ID',
      'API.INVALID_BAUD_RATE': 'Invalid baud rate',
      'API.INVALID_BUILDINGS_VALUE': 'Invalid buildings value',
      'API.INVALID_BUY_METER_ID': 'Invalid buy meter id',
      'API.INVALID_CAPACITY': 'Invalid capacity',
      'API.INVALID_CATEGORY': 'Invalid category',
      'API.INVALID_CHANNEL': 'Invalid channel',
      'API.INVALID_CHARGE_METER_ID': 'Invalid charge meter id',
      'API.INVALID_COMBINED_EQUIPMENT_ID': 'Invalid combined equipment id',
      'API.INVALID_COMBINED_EQUIPMENT_NAME': 'Invalid combined equipment name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_ID': 'Invalid combined equipment parameter id',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_NAME': 'Invalid combined equipment parameter name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_TYPE': 'Invalid combined equipment parameter type',
      'API.INVALID_COMBINED_EQUIPMENT_UUID': 'Invalid combined equipment uuid',
      'API.INVALID_COMMAND_ID': 'Invalid command id',
      'API.INVALID_COMMAND_NAME': 'Invalid command name',
      'API.INVALID_CONNECTION': 'Invalid connection',
      'API.INVALID_CONSTANT_VALUE': 'Invalid constant value',
      'API.INVALID_CONTACT_ID': 'Invalid contact id',
      'API.INVALID_CONTACT_NAME': 'Invalid contact name',
      'API.INVALID_COOLING_METER_ID': 'Invalid cooling meter id',
      'API.INVALID_COST_CENTER_ID': 'Invalid cost center id',
      'API.INVALID_COST_FILE_ID': 'Invalid cost file id',
      'API.INVALID_CREATED_DATETIME': 'Invalid created datetime',
      'API.INVALID_DATA_REPAIR_FILE_ID': 'Invalid data repair file id',
      'API.INVALID_DATA_SOURCE_ID': 'Invalid data source id',
      'API.INVALID_DATA_SOURCE_NAME': 'Invalid data source name',
      'API.INVALID_DATA_SOURCE_PROTOCOL': 'Invalid data source protocol',
      'API.INVALID_DATE_LOCAL': 'Invalid date local',
      'API.INVALID_DENOMINATOR_METER_UUID': 'Invalid denominator meter uuid',
      'API.INVALID_DISCHARGE_METER_ID': 'Invalid discharge meter id',
      'API.INVALID_DISPLAY_NAME': 'Invalid display name',
      'API.INVALID_DISTRIBUTION_CIRCUIT_ID': 'Invalid distribution circuit id',
      'API.INVALID_DISTRIBUTION_CIRCUIT_NAME': 'Invalid distribution circuit name',
      'API.INVALID_DISTRIBUTION_ROOM': 'Invalid distribution room',
      'API.INVALID_DISTRIBUTION_SYSTEM_ID': 'Invalid distribution system id',
      'API.INVALID_DISTRIBUTION_SYSTEM_NAME': 'Invalid distribution system name',
      'API.INVALID_ELECTRICITY_METER_ID': 'Invalid Electricity meter id',
      'API.INVALID_EMAIL': 'Invalid email',
      'API.INVALID_EMAIL_MESSAGE_ID': 'Invalid email message id',
      'API.INVALID_EMAIL_SERVER_HOST': 'Invalid email server host',
      'API.INVALID_EMAIL_SERVER_ID': 'Invalid email server id',
      'API.INVALID_END_DATE_FORMAT': 'Invalid end date format',
      'API.INVALID_END_DATETIME': 'Invalid end datetime',
      'API.INVALID_ENERGY_CATEGORY_ID': 'Invalid energy category id',
      'API.INVALID_ENERGY_CATEGORY_NAME': 'Invalid energy category name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_ID': 'Invalid energy flow diagram id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_LINK_ID': 'Invalid energy flow diagram link id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NAME': 'Invalid energy flow diagram name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_ID': 'Invalid energy flow diagram node id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_NAME': 'Invalid energy flow diagram node name',
      'API.INVALID_ENERGY_ITEM_ID': 'Invalid energy item id',
      'API.INVALID_ENERGY_ITEM_NAME': 'Invalid energy item name',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_ID': 'Invalid energy storage power station ID',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_NAME': 'Invalid energy storage power station name',
      'API.INVALID_EQUATION_IN_EXPRESSION': 'Invalid equation in expression',
      'API.INVALID_EQUIPMENT_ID': 'Invalid equipment id',
      'API.INVALID_EQUIPMENT_NAME': 'Invalid equipment name',
      'API.INVALID_EQUIPMENT_PARAMETER_ID': 'Invalid equipment parameter id',
      'API.INVALID_EQUIPMENT_PARAMETER_NAME': 'Invalid equipment parameter name',
      'API.INVALID_EQUIPMENT_PARAMETER_TYPE': 'Invalid equipment parameter type',
      'API.INVALID_EQUIPMENT_UUID': 'Invalid equipment uuid',
      'API.INVALID_EXPIRES_DATETIME': 'Invalid expires datetime',
      'API.INVALID_EXPRESSION': 'Invalid expression',
      'API.INVALID_EXPRESSION_OBJECT': 'Invalid expression object',
      'API.INVALID_FDD_CODE': 'Invalid fdd code',
      'API.INVALID_FLOORS_VALUE': 'Invalid floors value',
      'API.INVALID_FROM_ADDR': 'Invalid from addr',
      'API.INVALID_GATEWAY_ID': 'Invalid gateway id',
      'API.INVALID_GATEWAY_NAME': 'Invalid gateway name',
      'API.INVALID_HEAT_METER_ID': 'Invalid heat meter id',
      'API.INVALID_HIGH_LIMIT_VALUE': 'Invalid high limit value',
      'API.INVALID_HOURLY_HIGH_LIMIT_VALUE': 'Invalid hourly high limit value',
      'API.INVALID_HOURLY_LOW_LIMIT_VALUE': 'Invalid hourly low limit value',
      'API.INVALID_Id': 'Invalid id',
      'API.INVALID_IS_ADMIN_VALUE': 'Invalid is admin value',
      'API.INVALID_IS_COST_DATA_DISPLAYED': 'Invalid is cost data displayed',
      'API.INVALID_IS_COUNTED_VALUE': 'Invalid is counted value',
      'API.INVALID_IS_ENABLED': 'Invalid is enabled',
      'API.INVALID_IS_HIDDEN': 'Invalid is hidden',
      'API.INVALID_IS_IN_LEASE_VALUE': 'Invalid is in lease value',
      'API.INVALID_IS_INPUT_COUNTED_VALUE': 'Invalid is input counted value',
      'API.INVALID_IS_KEY_TENANT_VALUE': 'Invalid is key tenant value',
      'API.INVALID_IS_OUTPUT_COUNTED_VALUE': 'Invalid is output counted value',
      'API.INVALID_IS_OUTPUT_VALUE': 'Invalid is output value',
      'API.INVALID_IS_READ_ONLY_VALUE': 'Invalid is read only value',
      'API.INVALID_IS_TREND_VALUE': 'Invalid is trend value',
      'API.INVALID_IS_VIRTUAL_VALUE': 'Invalid is virtual value',
      'API.INVALID_KGCE': 'Invalid kgce',
      'API.INVALID_KGCO2E': 'Invalid kgco2e',
      'API.INVALID_KNOWLEDGE_FILE_ID': 'Invalid knowledge file id',
      'API.INVALID_LATITUDE_VALUE': 'Invalid latitude value',
      'API.INVALID_LEASE_NUMBER_VALUE': 'Invalid lease number value',
      'API.INVALID_LONGITUDE_VALUE': 'Invalid longitude value',
      'API.INVALID_LOW_LIMIT_VALUE': 'Invalid low limit value',
      'API.INVALID_MASTER_METER_ID': 'Invalid master meter id',
      'API.INVALID_MENU_ID': 'Invalid menu id',
      'API.INVALID_MESSAGE_DATA': 'Invalid message data',
      'API.INVALID_MESSAGE_TEMPLATE': 'Invalid message template',
      'API.INVALID_MESSAGE_TEMPLATE_ID': 'Invalid message template id',
      'API.INVALID_MESSAGE_VALUE': 'Invalid message value',
      'API.INVALID_METER_ID': 'Invalid meter id',
      'API.INVALID_METER_NAME': 'Invalid meter name',
      'API.INVALID_METER_UUID': 'Invalid meter uuid',
      'API.INVALID_MICROGRID_BATTERY_ID': 'Invalid microgrid battery id',
      'API.INVALID_MICROGRID_BATTERY_NAME': 'Invalid microgrid battery name',
      'API.INVALID_MICROGRID_EVCHARGER_ID': 'Invalid microgrid evcharger id',
      'API.INVALID_MICROGRID_EVCHARGER_NAME': 'Invalid microgrid evcharger name',
      'API.INVALID_MICROGRID_GENERATOR_ID': 'Invalid microgrid generator id',
      'API.INVALID_MICROGRID_GENERATOR_NAME': 'Invalid microgrid generator name',
      'API.INVALID_MICROGRID_GRID_ID': 'Invalid microgrid grid id',
      'API.INVALID_MICROGRID_GRID_NAME': 'Invalid microgrid grid name',
      'API.INVALID_MICROGRID_HEATPUMP_ID': 'Invalid microgrid heatpump id',
      'API.INVALID_MICROGRID_HEATPUMP_NAME': 'Invalid microgrid heatpump name',
      'API.INVALID_MICROGRID_ID': 'Invalid microgrid id',
      'API.INVALID_MICROGRID_LOAD_ID': 'Invalid microgrid load id',
      'API.INVALID_MICROGRID_LOAD_NAME': 'Invalid microgrid load name',
      'API.INVALID_MICROGRID_NAME': 'Invalid microgrid name',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_ID': 'Invalid microgrid photovoltaic id',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_NAME': 'Invalid microgrid photovoltaic name',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_ID': 'Invalid microgrid power conversion system ID',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_NAME': 'Invalid microgrid microgrid power conversion system name',
      'API.INVALID_MICROGRID_UUID': 'Invalid microgrid UUID',
      'API.INVALID_NAME_VALUE': 'Invalid name value',
      'API.INVALID_NEW_PASSWORD': 'Invalid new password',
      'API.INVALID_NON_WORKING_DAY_ID': 'Invalid non working day ID',
      'API.INVALID_NOTIFICATION_ID': 'Invalid notification id',
      'API.INVALID_NUMERATOR_METER_UUID': 'Invalid numerator meter uuid',
      'API.INVALID_OBJECT_TYPE': 'Invalid object type',
      'API.INVALID_OFFLINE_METER_DATA': 'Invalid offline meter data',
      'API.INVALID_OFFLINE_METER_FILE_ID': 'Invalid offline meter file id',
      'API.INVALID_OFFLINE_METER_ID': 'Invalid offline meter id',
      'API.INVALID_OFFLINE_METER_NAME': 'Invalid offline meter name',
      'API.INVALID_OFFLINE_METER_UUID': 'Invalid offline meter uuid',
      'API.INVALID_OFFSET_CONSTANT_VALUE': 'Invalid offset constant value',
      'API.INVALID_OLD_PASSWORD': 'Invalid old password',
      'API.INVALID_OPENID': 'Invalid openid',
      'API.INVALID_PARENT_SPACE_ID': 'Invalid parent space id',
      'API.INVALID_PASSWORD': 'Invalid password',
      'API.INVALID_PAYLOAD': 'Invalid payload',
      'API.INVALID_PEAK_CURRENT': 'Invalid peak current',
      'API.INVALID_PEAK_LOAD': 'Invalid peak load',
      'API.INVALID_PERIOD_TYPE': 'Invalid period type',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_ID': 'Invalid photovoltaic power station ID',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_NAME': 'Invalid photovoltaic power station name',
      'API.INVALID_POINT_ID': 'Invalid point id',
      'API.INVALID_POINT_NAME': 'Invalid point name',
      'API.INVALID_PORT': 'Invalid port',
      'API.INVALID_POSTAL_CODE_VALUE': 'Invalid postal code value',
      'API.INVALID_POWER_POINT_ID': 'Invalid power point id',
      'API.INVALID_PRIORITY': 'Invalid priority',
      'API.INVALID_PRIVILEGE': 'Invalid privilege',
      'API.INVALID_PRIVILEGE_DATA': 'Invalid privilege data',
      'API.INVALID_PRIVILEGE_ID': 'Invalid privilege id',
      'API.INVALID_PRIVILEGE_NAME': 'Invalid privilege name',
      'API.INVALID_RATIO_VALUE': 'Invalid ratio value',
      'API.INVALID_RECIPIENT_EMAIL': 'Invalid recipient email',
      'API.INVALID_RECIPIENT_MOBILE': 'Invalid recipient mobile',
      'API.INVALID_RECIPIENT_NAME': 'Invalid recipient name',
      'API.INVALID_RECIPIENT_OPENID': 'Invalid recipient openid',
      'API.INVALID_REPLY': 'Invalid reply',
      'API.INVALID_REPORTING_PERIOD_END_DATETIME': 'Invalid reporting period end datetime',
      'API.INVALID_REPORTING_PERIOD_START_DATETIME': 'Invalid reporting period start datetime',
      'API.INVALID_REQUIRES_AUTHENTICATION': 'Invalid requires authentication',
      'API.INVALID_ROOMS_VALUE': 'Invalid rooms value',
      'API.INVALID_RULE_ID': 'Invalid rule id',
      'API.INVALID_RULE_NAME': 'Invalid rule name',
      'API.INVALID_RUN_STATE_POINT_ID': 'Invalid run state point ID',
      'API.INVALID_SCHEDULED_DATETIME': 'Invalid scheduled datetime',
      'API.INVALID_SELL_METER_ID': 'Invalid sell meter id',
      'API.INVALID_SENSOR_ID': 'Invalid sensor id',
      'API.INVALID_SENSOR_NAME': 'Invalid sensor name',
      'API.INVALID_SENSOR_UUID': 'Invalid sensor uuid',
      'API.INVALID_SERIAL_NUMBER': 'Invalid serial number',
      'API.INVALID_SERIAL_PORT': 'Invalid serial port',
      'API.INVALID_SESSION_PLEASE_RE_LOGIN': 'Invalid session please re-login',
      'API.INVALID_SET_VALUE': 'Invalid set value',
      'API.INVALID_SHOPFLOOR_ID': 'Invalid shopfloor id',
      'API.INVALID_SHOPFLOOR_NAME': 'Invalid shopfloor name',
      'API.INVALID_SHOPFLOOR_UUID': 'Invalid shopfloor uuid',
      'API.INVALID_SOURCE_NODE_ID': 'Invalid source node id',
      'API.INVALID_SPACE_ID': 'Invalid space id',
      'API.INVALID_SPACE_NAME': 'Invalid space name',
      'API.INVALID_SPACE_UUID': 'Invalid space uuid',
      'API.INVALID_START_DATE_FORMAT': 'Invalid start date format',
      'API.INVALID_START_DATETIME': 'Invalid start datetime',
      'API.INVALID_STATUS': 'Invalid status',
      'API.INVALID_STORE_ID': 'Invalid store id',
      'API.INVALID_STORE_NAME': 'Invalid store name',
      'API.INVALID_STORE_TYPE_DESCRIPTION': 'Invalid store type description',
      'API.INVALID_STORE_TYPE_ID': 'Invalid store type id',
      'API.INVALID_STORE_TYPE_NAME': 'Invalid store type name',
      'API.INVALID_STORE_TYPE_SIMPLIFIED_CODE': 'Invalid store type simplified code',
      'API.INVALID_STORE_UUID': 'Invalid store uuid',
      'API.INVALID_SUBJECT_VALUE': 'Invalid subject value',
      'API.INVALID_SVG': 'Invalid SVG',
      'API.INVALID_SWITCHGEAR': 'Invalid switchgear',
      'API.INVALID_TARGET_NODE_ID': 'Invalid target node id',
      'API.INVALID_TARIFF_BLOCK_PRICING': 'Invalid tariff block pricing',
      'API.INVALID_TARIFF_ID': 'Invalid tariff id',
      'API.INVALID_TARIFF_TIME_OF_USE_PRICING': 'Invalid tariff time of use pricing',
      'API.INVALID_TARIFF_TYPE': 'Invalid tariff type',
      'API.INVALID_TENANT_ID': 'Invalid tenant id',
      'API.INVALID_TENANT_NAME': 'Invalid tenant name',
      'API.INVALID_TENANT_TYPE_DESCRIPTION': 'Invalid tenant type description',
      'API.INVALID_TENANT_TYPE_ID': 'Invalid tenant type id',
      'API.INVALID_TENANT_TYPE_NAME': 'Invalid tenant type name',
      'API.INVALID_TENANT_TYPE_SIMPLIFIED_CODE': 'Invalid tenant type simplified code',
      'API.INVALID_TENANT_UUID': 'Invalid tenant uuid',
      'API.INVALID_TEXT_MESSAGE_ID': 'Invalid text message id',
      'API.INVALID_TIMEZONE_ID': 'Invalid timezone id',
      'API.INVALID_TOKEN': 'Invalid token',
      'API.INVALID_TOPIC': 'Invalid topic',
      'API.INVALID_UNIT_OF_MEASURE': 'Invalid unit of measure',
      'API.INVALID_UNIT_OF_PRICE': 'Invalid unit of price',
      'API.INVALID_UNITS': 'Invalid units',
      'API.INVALID_USER_ID': 'Invalid user id',
      'API.INVALID_USER_NAME': 'Invalid user name',
      'API.INVALID_USER_NAME_OR_EMAIL': 'Invalid user name or email',
      'API.INVALID_USER_PHONE': 'Invalid user phone',
      'API.INVALID_USER_PLEASE_RE_LOGIN': 'Invalid user please re-login',
      'API.INVALID_USER_UUID': 'Invalid user uuid',
      'API.INVALID_USERNAME': 'Invalid userName',
      'API.INVALID_VARIABLE_METER_ID': 'Invalid variable meter id',
      'API.INVALID_VARIABLE_METER_TYPE': 'Invalid variable meter type',
      'API.INVALID_VARIABLE_NAME': 'Invalid variable name',
      'API.INVALID_VERIFICATION_CODE': 'Invalid verification code',
      'API.INVALID_VIRTUAL_METER_ID': 'Invalid virtual meter id',
      'API.INVALID_VIRTUAL_METER_NAME': 'Invalid virtual meter name',
      'API.INVALID_VIRTUAL_METER_UUID': 'Invalid virtual meter uuid',
      'API.INVALID_WEB_MESSAGE_ID': 'Invalid web message id',
      'API.INVALID_WECHAT_MESSAGE_ID': 'Invalid wechat message id',
      'API.INVALID_WIND_FARM_ID': 'Invalid wind farm ID',
      'API.INVALID_WIND_FARM_NAME': 'Invalid wind farm name',
      'API.INVALID_WORKING_CALENDAR_ID': 'Invalid working calendar id ',
      'API.INVALID_WORKING_CALENDAR_NAME': 'Invalid working calendar name',
      'API.KNOWLEDGE_FILE_CANNOT_BE_REMOVED_FROM_DISK': 'Knowledge File Cannot be Removed from Disk',
      'API.KNOWLEDGE_FILE_NOT_FOUND': 'Knowledge file not found',
      'API.MASTER_METER_DOES_NOT_BELONG_TO_SAME_ENERGY_CATEGORY':
        'Master meter does not belong to same energy category',
      'API.MASTER_METER_NOT_FOUND': 'Master meter not found',
      'API.MENU_NOT_FOUND': 'Menu not found',
      'API.METER_CANNOT_HAVE_MORE_THAN_ONE_ENERGY_VALUE_POINTS': 'Meter cannot have more than one energy value points',
      'API.METER_COMMAND_RELATION_EXISTS': 'Meter command relation exists',
      'API.METER_COMMAND_RELATION_NOT_FOUND': 'Meter command relation not found',
      'API.METER_NAME_IS_ALREADY_IN_USE': 'Meter name is already in use',
      'API.METER_NOT_FOUND': 'Meter not found',
      'API.METER_OF_VARIABLE_NOT_FOUND': 'Meter of variable not found',
      'API.METER_POINT_RELATION_EXISTS': 'Meter point relation exists',
      'API.METER_POINT_RELATION_NOT_FOUND': 'Meter point relation not found',
      'API.MICROGRID_BATTERY_NAME_IS_ALREADY_IN_USE': 'Microgrid battery name is already in use',
      'API.MICROGRID_BATTERY_NOT_FOUND': 'Microgrid battery not found',
      'API.MICROGRID_EVCHARGER_NAME_IS_ALREADY_IN_USE': 'Microgrid evcharger name is already in use',
      'API.MICROGRID_EVCHARGER_NOT_FOUND': 'Microgrid evcharger not fund',
      'API.MICROGRID_GENERATOR_NAME_IS_ALREADY_IN_USE': 'Microgrid generator name is already in use',
      'API.MICROGRID_GENERATOR_NOT_FOUND': 'Microgrid generator not found',
      'API.MICROGRID_GRID_NAME_IS_ALREADY_IN_USE': 'Microgrid grid name is already in use',
      'API.MICROGRID_GRID_NOT_FOUND': 'Microgrid grid not found',
      'API.MICROGRID_HEATPUMP_NAME_IS_ALREADY_IN_USE': 'Microgrid heatpump name is already in use',
      'API.MICROGRID_HEATPUMP_NOT_FOUND': 'Microgrid heatpump not found',
      'API.MICROGRID_LOAD_NAME_IS_ALREADY_IN_USE': 'Microgrid load name is already',
      'API.MICROGRID_LOAD_NOT_FOUND': 'Microgrid load not found',
      'API.MICROGRID_NAME_IS_ALREADY_IN_USE': 'Microgrid name is already in use',
      'API.MICROGRID_NOT_FOUND': 'Microgrid not found',
      'API.MICROGRID_PHOTOVOLTAIC_NAME_IS_ALREADY_IN_USE': 'Microgrid photovoltaic name is already in use',
      'API.MICROGRID_PHOTOVOLTAIC_NOT_FOUND': 'Microgrid photovoltaic not found',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NAME_IS_ALREADY_IN_USE':
        'Microgrid power conversion system name is already in use',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NOT_FOUND': 'Microgrid power conversion system not found',
      'API.MICROGRID_SENSOR_RELATION_EXISTS': 'Microgrid sensor relation exists',
      'API.MICROGRID_SENSOR_RELATION_NOT_FOUND': 'Microgrid sensor not found',
      'API.MQTT_CONNECTION_ERROR': 'Mqtt connection error',
      'API.MQTT_PUBLISH_ERROR': 'Mqtt publish error',
      'API.NEW_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'New password length cannot exceed 100 characters',
      'API.NEW_USER_SESSION_NOT_FOUND': 'New user session not found',
      'API.NEW_USER_SESSION_TIMEOUT': 'New user session timeout',
      'API.NON_WORKING_DAY_NOT_FOUND': 'Non working day non found',
      'API.NOT_FOUND': 'Not found',
      'API.NOTIFICATION_NOT_FOUND': 'Notification not found',
      'API.OFFLINE_METER_FILE_NOT_FOUND': 'Offline meter file not found',
      'API.OFFLINE_METER_NAME_IS_ALREADY_IN_USE': 'Offline meter name is already in use',
      'API.OFFLINE_METER_NOT_FOUND': 'Offline meter not found',
      'API.OFFLINE_METER_OF_VARIABLE_NOT_FOUND': 'Offline meter of variable not found',
      'API.OLD_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Old password length cannot exceed 100 characters',
      'API.PARENT_SPACE_NOT_FOUND': 'Parent space not found',
      'API.PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Password length cannot exceed 100 characters',
      'API.PASSWORDS_MATCH': 'New password is the same as the old password',
      'API.PHOTOVOLTAIC_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Photovoltaic power station name is already in use',
      'API.PHOTOVOLTAIC_POWER_STATION_NOT_FOUND': 'Photovoltaic power station not found',
      'API.POINT_NAME_IS_ALREADY_IN_USE': 'Point name is already in use',
      'API.POINT_NOT_FOUND': 'Point not found',
      'API.POWER_POINT_NOT_FOUND': 'Power point not found',
      'API.PRIVILEGE_NAME_IS_ALREADY_IN_USE': 'Privilege name is already in use',
      'API.PRIVILEGE_NOT_FOUND': 'Privilege not found',
      'API.RULE_NAME_IS_ALREADY_IN_USE': 'Rule name is already in use',
      'API.RULE_NOT_FOUND': 'Rule not found',
      'API.SELL_METER_NOT_FOUND': 'Sell meter not found',
      'API.SENSOR_NAME_IS_ALREADY_IN_USE': 'Sensor name is already in use',
      'API.SENSOR_NOT_FOUND': 'Sensor not found',
      'API.SENSOR_POINT_RELATION_EXISTS': 'Sensor point relation exists',
      'API.SENSOR_POINT_RELATION_NOT_FOUND': 'Sensor point relation not found',
      'API.SHOPFLOOR_COMMAND_RELATION_EXISTS': 'Shopfloor command relation exists',
      'API.SHOPFLOOR_COMMAND_RELATION_NOT_FOUND': 'Shopfloor command relation not found',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_EXISTS': 'Shopfloor equipment relation exists',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_NOT_FOUND': 'Shopfloor equipment relation not found',
      'API.SHOPFLOOR_METER_RELATION_EXISTS': 'Shopfloor meter relation exists',
      'API.SHOPFLOOR_METER_RELATION_NOT_FOUND': 'Shopfloor meter relation not found',
      'API.SHOPFLOOR_NAME_IS_ALREADY_IN_USE': 'Shopfloor name is already in use',
      'API.SHOPFLOOR_NOT_FOUND': 'Shopfloor not found',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_EXISTS': 'Shopfloor offline meter relation exists',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_NOT_FOUND': 'Shopfloor offline meter relation not found',
      'API.SHOPFLOOR_POINT_RELATION_EXISTS': 'Shopfloor point relation exists',
      'API.SHOPFLOOR_POINT_RELATION_NOT_FOUND': 'Shopfloor point relation not found',
      'API.SHOPFLOOR_SENSOR_RELATION_EXISTS': 'Shopfloor sensor relation exists',
      'API.SHOPFLOOR_SENSOR_RELATION_NOT_FOUND': 'Shopfloor sensor relation not found',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_EXISTS': 'Shopfloor virtual meter relation exists',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_NOT_FOUND': 'Shopfloor virtual meter relation not found',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_EXISTS': 'Shopfloor working calendar relation exists',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Shopfloor working calendar relation not found',
      'API.SOC_POINT_NOT_FOUND': 'SOC point not found',
      'API.SOURCE_NODE_NOT_FOUND': 'Source node not found',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_EXISTS': 'Space combined equipment relation exists',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_NOT_FOUND': 'Space combined equipment relation not found',
      'API.SPACE_COMMAND_RELATION_EXISTS': 'Space command relation exists',
      'API.SPACE_COMMAND_RELATION_NOT_FOUND': 'Space command relation not found',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_EXISTS': 'Space Energy Storage Power Station Relation Exists',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_NOT_FOUND': 'Space Energy Storage Power Station Relation Not Found',
      'API.SPACE_EQUIPMENT_RELATION_EXISTS': 'Space equipment relation exists',
      'API.SPACE_EQUIPMENT_RELATION_NOT_FOUND': 'Space equipment relation not found',
      'API.SPACE_METER_RELATION_EXISTS': 'Space meter relation exists',
      'API.SPACE_METER_RELATION_NOT_FOUND': 'Space meter relation not found',
      'API.SPACE_NAME_IS_ALREADY_IN_USE': 'Space name is already in use',
      'API.SPACE_NOT_FOUND': 'Space not found',
      'API.SPACE_NOT_FOUND_IN_PRIVILEGE': 'Space not found in privilege',
      'API.SPACE_OFFLINE_METER_RELATION_EXISTS': 'Space offline meter relation exists',
      'API.SPACE_OFFLINE_METER_RELATION_NOT_FOUND': 'Space offline meter relation not found',
      'API.SPACE_POINT_RELATION_EXISTS': 'Space point relation exists',
      'API.SPACE_POINT_RELATION_NOT_FOUND': 'Space point relation not found',
      'API.SPACE_SENSOR_RELATION_EXISTS': 'Space sensor relation exists',
      'API.SPACE_SENSOR_RELATION_NOT_FOUND': 'Space sensor relation not found',
      'API.SPACE_SHOPFLOOR_RELATION_EXISTS': 'Space shopfloor relation exists',
      'API.SPACE_SHOPFLOOR_RELATION_NOT_FOUND': 'Space shopfloor relation not found',
      'API.SPACE_STORE_RELATION_EXISTS': 'Space store relation exists',
      'API.SPACE_STORE_RELATION_NOT_FOUND': 'Space store relation not found',
      'API.SPACE_TENANT_RELATION_EXISTS': 'Space tenant relation exists',
      'API.SPACE_TENANT_RELATION_NOT_FOUND': 'Space tenant relation not found',
      'API.SPACE_VIRTUAL_METER_RELATION_EXISTS': 'Space virtual meter relation exists',
      'API.SPACE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Space virtual meter relation not found',
      'API.SPACE_WORKING_CALENDAR_RELATION_EXISTS': 'Space working calendar relation exists',
      'API.SPACE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Space working calendar relation not found',
      'API.START_DATETIME_MUST_BE_EARLIER_THAN_END_DATETIME': 'Start datetime must be early than end datetime',
      'API.STORE_COMMAND_RELATION_EXISTS': 'Store command relation exists',
      'API.STORE_COMMAND_RELATION_NOT_FOUND': 'Store command relation not found',
      'API.STORE_METER_RELATION_EXISTS': 'Store meter relation exists',
      'API.STORE_METER_RELATION_NOT_FOUND': 'Store meter relation not found',
      'API.STORE_NAME_IS_ALREADY_IN_USE': 'Store name is already in use',
      'API.STORE_NOT_FOUND': 'Store not found',
      'API.STORE_OFFLINE_METER_RELATION_EXISTS': 'Store offline meter relation exists',
      'API.STORE_OFFLINE_METER_RELATION_NOT_FOUND': 'Store offline meter relation not found',
      'API.STORE_POINT_RELATION_EXISTS': 'Store point relation exists',
      'API.STORE_POINT_RELATION_NOT_FOUND': 'Store point relation not found',
      'API.STORE_SENSOR_RELATION_EXISTS': 'Store sensor relation exists',
      'API.STORE_SENSOR_RELATION_NOT_FOUND': 'Store sensor relation not found',
      'API.STORE_TYPE_NAME_IS_ALREADY_IN_USE': 'Store type name is already in use',
      'API.STORE_TYPE_NOT_FOUND': 'Store type not found',
      'API.STORE_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Store type simplified code is already in use',
      'API.STORE_TYPE_USED_IN_STORE': 'Store type used in store',
      'API.STORE_VIRTUAL_METER_RELATION_EXISTS': 'Store virtual meter relation exists',
      'API.STORE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Store virtual meter relation not found',
      'API.STORE_WORKING_CALENDAR_RELATION_EXISTS': 'Store working calendar relation exists',
      'API.STORE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Store working calendar relation not found',
      'API.TARGET_NODE_NOT_FOUND': 'Target node not found',
      'API.TARIFF_IN_USE': 'Tariff in use',
      'API.TARIFF_IS_ALREADY_ASSOCIATED_WITH_COST_CENTER': 'Tariff is already associated with cost center',
      'API.TARIFF_IS_NOT_ASSOCIATED_WITH_COST_CENTER': 'Tariff is not associated with cost center',
      'API.TARIFF_NAME_IS_ALREADY_IN_USE': 'Tariff name is already in use',
      'API.TARIFF_NOT_FOUND': 'Tariff not found',
      'API.TENANT_COMMAND_RELATION_EXISTS': 'Tenant command relation exists',
      'API.TENANT_COMMAND_RELATION_NOT_FOUND': 'Tenant command relation  not found',
      'API.TENANT_METER_RELATION_EXISTS': 'Tenant meter relation exists',
      'API.TENANT_METER_RELATION_NOT_FOUND': 'Tenant meter relation not found',
      'API.TENANT_NAME_IS_ALREADY_IN_USE': 'Tenant name is already in use',
      'API.TENANT_NOT_FOUND': 'Tenant not found',
      'API.TENANT_OFFLINE_METER_RELATION_EXISTS': 'Tenant offline meter relation exists',
      'API.TENANT_OFFLINE_METER_RELATION_NOT_FOUND': 'Tenant offline meter relation not found',
      'API.TENANT_POINT_RELATION_EXISTS': 'Tenant point relation exists',
      'API.TENANT_POINT_RELATION_NOT_FOUND': 'Tenant point relation not found',
      'API.TENANT_SENSOR_RELATION_EXISTS': 'Tenant sensor relation exists',
      'API.TENANT_SENSOR_RELATION_NOT_FOUND': 'Tenant sensor relation not found',
      'API.TENANT_TYPE_NAME_IS_ALREADY_IN_USE': 'Tenant type name is already in use',
      'API.TENANT_TYPE_NOT_FOUND': 'Tenant type not found',
      'API.TENANT_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Tenant type simplified code is already in use',
      'API.TENANT_TYPE_USED_IN_TENANT': 'Tenant type used in tenant',
      'API.TENANT_VIRTUAL_METER_RELATION_EXISTS': 'Tenant virtual meter relation exists',
      'API.TENANT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Tenant virtual meter relation not found',
      'API.TENANT_WORKING_CALENDAR_RELATION_EXISTS': 'Tenant working calendar relation exists',
      'API.TENANT_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Tenant working calendar relation not found',
      'API.TEXT_MESSAGE_NOT_FOUND': 'Text message not found',
      'API.THE_REPORTING_PERIOD_MUST_BE_LONGER_THAN_15_MINUTES': 'The reporting period must be longer than 15 minutes',
      'API.THERE_IS_RELATION_WITH_CHILD_METERS': 'There is relation with child meters',
      'API.THERE_IS_RELATION_WITH_CHILDREN_SPACES': 'There is relation with children spaces',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENT_PARAMETERS':
        'There is relation with combined equipment parameters',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENTS': 'There is relation with combined equipments',
      'API.THERE_IS_RELATION_WITH_DATA_SOURCES': 'There is relation with data sources',
      'API.THERE_IS_RELATION_WITH_ENERGY_FLOW_DIAGRAM_LINKS': 'There is relation with energy flow diagram links',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_BATTERIES': 'There Is Relation With Energy Storage Containers Batteries',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_GRIDS': 'There Is Relation With Energy Storage Containers Grids',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_LOADS': 'There Is Relation With Energy Storage Containers Loads',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_POWER_CONVERSION_SYSTEMS': 'There Is Relation With Energy Storage Containers Power Conversion Systems',
      'API.THERE_IS_RELATION_WITH_EQUIPMENT_PARAMETERS': 'There is relation with equipment parameters',
      'API.THERE_IS_RELATION_WITH_EQUIPMENTS': 'There is relation with equipments',
      'API.THERE_IS_RELATION_WITH_METER': 'There is relation with meter',
      'API.THERE_IS_RELATION_WITH_METERS': 'There is relation with meters',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METER': 'There is relation with offline meter',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METERS': 'There is relation with offline meters',
      'API.THERE_IS_RELATION_WITH_OTHER_VIRTUAL_METERS': 'There is relation with other virtual meters',
      'API.THERE_IS_RELATION_WITH_SENSORS': 'There is relation with sensors',
      'API.THERE_IS_RELATION_WITH_SHOPFLOORS': 'There is relation with shopfloors',
      'API.THERE_IS_RELATION_WITH_SPACES': 'There is relation with spaces',
      'API.THERE_IS_RELATION_WITH_STORES': 'There is relation with stores',
      'API.THERE_IS_RELATION_WITH_TENANTS': 'There is relation with tenants',
      'API.THERE_IS_RELATION_WITH_USERS': 'There is relation with users',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_METER': 'There is relation with virtual meter',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_POWER_PLANTS': 'Relacionado con centrales virtuales',
      'API.THIS_DATA_SOURCE_IS_BEING_USED_BY_A_METER': 'This data source is being used by a meter',
      'API.THIS_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This meter is being used by a virtual meter',
      'API.THIS_OFFLINE_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This offline meter is being used by a virtual meter',
      'API.THIS_SPACE_CANNOT_BE_CLONED': 'This space cannot be cloned',
      'API.THIS_SPACE_CANNOT_BE_DELETED': 'This space cannot be deleted',
      'API.TIMEZONE_NOT_FOUND': 'Timezone not found',
      'API.TOKEN_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'Token not found in headers please login',
      'API.USER_ACCOUNT_HAS_BEEN_LOCKED': 'User Account Has Been Locked',
      'API.USER_ACCOUNT_HAS_EXPIRED': 'User Account Has Expired',
      'API.USER_ACCOUNT_IS_NOT_LOCKED': 'User account is not locked',
      'API.USER_NAME_IS_ALREADY_IN_USE': 'User name is already in use',
      'API.USER_NOT_FOUND': 'User not found',
      'API.USER_PASSWORD_HAS_EXPIRED': 'User Password Has Expired',
      'API.USER_PRIVILEGE_NOT_FOUND': 'User privilege not found',
      'API.USER_SESSION_NOT_FOUND': 'User session not found',
      'API.USER_SESSION_TIMEOUT': 'User session timeout',
      'API.USER_UUID_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'User uuid not found in headers please login',
      'API.VIRTUAL_METER_NAME_IS_ALREADY_IN_USE': 'Virtual meter name is already in use',
      'API.VIRTUAL_METER_NOT_FOUND': 'Virtual meter not found',
      'API.VIRTUAL_METER_OF_VARIABLE_NOT_FOUND': 'Virtual meter of variable not found',
      'API.VIRTUAL_POINT_SHOULD_BE_ANALOG_VALUE': 'Virtual point should be analog value',
      'API.WEB_MESSAGE_NOT_FOUND': 'Web message not found',
      'API.WECHAT_MESSAGE_NOT_FOUND': 'Wechat message not found',
      'API.WIND_FARM_NAME_IS_ALREADY_IN_USE': 'Wind farm name is already in use',
      'API.WIND_FARM_NOT_FOUND': 'Wind farm not found',
      'API.WORKING_CALENDAR_NAME_IS_ALREADY_IN_USE': 'Working calendar name is already in use',
      'API.WORKING_CALENDAR_NOT_FOUND': 'Working calendar not found',

      MasterMeter: 'Tabla general',
      SubMeter: 'Tabla dividida'
    }
  },
  ru: {
    translation: {
      // routes & menus
      Dashboard: 'Щиток',
      'Space Data': 'Пространство',
      'Equipment Data': 'Оборудование',
      'Meter Data': 'Метр',
      'Tenant Data': 'Съёмщик',
      'Store Data': 'Магазин',
      'Shopfloor Data': 'Торговый зал',
      'Combined Equipment Data': 'Комбинированное оборудование',
      'Auxiliary System': 'Вспомогательная система',
      Microgrid: 'Микросеть',
      'Energy Storage Power Station': 'Резервная электростанция',
      'Photovoltaic Power Station': 'Power Station',
      'Fault Alarm': 'Сигнализация неисправности',
      Monitoring: 'Контроль',
      'Advanced Reporting': 'Расширенная отчетность',
      'Knowledge Base': 'База знаний',
      'Energy Category Data': 'Категория «Энергия»',
      'Energy Item Data': 'Энергетический элемент',
      Carbon: 'Выбросы CO2',
      Cost: 'Стоить',
      Output: 'Выпуск',
      Income: 'Доход',
      Efficiency: 'Эффективность',
      Load: 'Груз',
      Statistics: 'Статистика',
      'Batch Analysis': 'Пакетный анализ',
      Saving: 'Спасительный',
      Plan: 'План',
      'Production': 'Production',
      'Space Production': 'Space Production',
      'Equipment Tracking': 'Отслеживание оборудования',
      'Environment Monitor': 'Монитор окружающей среды',
      'Meter Energy': 'Счетчик энергии',
      'Meter Carbon': 'Выбросы CO2 по приборам',
      'Meter Cost': 'Стоимость счетчика',
      'Meter Trend': 'Тренд счетчика',
      'Meter Realtime': 'Счетчик в реальном времени',
      'Meter Saving': 'Экономия счетчиков',
      'Meter Plan': 'Метрический план',
      'Master Meter Submeters Balance': 'Весы счетчиков Master Meter',
      'Meter Batch Analysis': 'Пакетный анализ счетчиков',
      'Meter Comparison': 'Сравнение счетчиков',
      'Meter Tracking': 'Слежение за счетчиком',
      'Virtual Meter Saving': 'Экономия виртуальных счетчиков',
      'Virtual Meter Plan': 'План виртуального счетчика',
      'Virtual Meter Energy': 'Виртуальный счетчик энергии',
      'Virtual Meter Carbon': 'Выбросы CO2 виртуальными приборами',
      'Virtual Meter Cost': 'Стоимость виртуального счетчика',
      'Virtual Meter Batch Analysis': 'Анализ партии виртуальных счетчиков',
      'Offline Meter Energy': 'Автономный счетчик энергии',
      'Offline Meter Carbon': 'Выбросы CO2 из автономных приборов',
      'Offline Meter Cost': 'Стоимость автономного счетчика',
      'Offline Meter Batch Analysis': 'Автономный пакетный анализ счетчиков',
      'Offline Meter Saving': 'Автономное сохранение счетчиков',
      'Offline Meter Plan': 'План автономного счетчика',
      'Offline Meter Input': 'Автономный вход счетчика',
      'Tenant Bill': 'Счет арендатора',
      'Energy Flow Diagram': 'Диаграмма потоков энергии',
      'Distribution System': 'Система дистрибуции',
      'SVG System': 'Система визуализации SCADA',
      'Faults Data': 'Ошибки',
      'Space Equipments': 'Космическое оборудование',
      'Combined Equipments': 'Комбинированное оборудование',
      'Energy Storage Power Station List': 'List',
      'Energy Storage Power Station Details': 'Details',
      'Energy Storage Power Station Reporting': 'Reporting',
      'Energy Storage Power Station Alarm': 'Alarm',
      'Energy Storage Power Station Maintenance': 'Maintenance',
      'Enter Production': 'Enter Production',
      'Multiple Energy Storage Power Stations': 'Multiple Power Stations',
      'Multiple Energy Storage Power Stations Dashboard': 'Dashboard',
      'Multiple Energy Storage Power Stations List': 'List',
      'Single Energy Storage Power Station': 'Single Power Station',
      'Single Energy Storage Power Station Dashboard': 'Dashboard',
      'Single Energy Storage Power Station Details': 'Details',
      'Single Energy Storage Power Station Fault Alarm': 'Fault Alarm',
      'Energy Storage Power Station Reports': 'Power Station Reports',
      'Energy Storage Power Station Reports Energy': 'Energy',
      'Energy Storage Power Station Reports Revenue': 'Revenue',
      'Energy Storage Power Station Reports Parameters': 'Parameters',
      'Photovoltaic Power Station List': 'Power Station List',
      'Photovoltaic Power Station Details': 'Details',
      'Photovoltaic Power Station Reporting': 'Reporting',
      'Photovoltaic Power Station Alarm': 'Alarm',
      'Photovoltaic Power Station Maintenance': 'Maintenance',
      'Multiple Photovoltaic Power Stations': 'Multiple Power Stations',
      'Multiple Photovoltaic Power Stations Dashboard': 'Dashboard',
      'Multiple Photovoltaic Power Stations List': 'List',
      'Single Photovoltaic Power Station': 'Single Power Station',
      'Single Photovoltaic Power Station Dashboard': 'Dashboard',
      'Single Photovoltaic Power Station Details': 'Details',
      'Single Photovoltaic Power Station Fault Alarm': 'Fault Alarm',
      'Photovoltaic Power Station Reports': 'Power Station Reports',
      'Photovoltaic Power Station Reports Energy': 'Energy',
      'Photovoltaic Power Station Reports Revenue': 'Revenue',
      'Photovoltaic Power Station Reports Parameters': 'Parameters',
      'Work Order': 'Work Order',
      'Work Order Installation': 'Installation',
      'Work Order Repair': ' Repair',
      'Work Order Inspection': 'Inspection',
      'Total Number of Work Orders': 'Общее количество заказов на работу',
      'Number of New Work Orders': 'Количество новых заказов на работу',
      'Number of Inprogress Work Orders': 'Количество незавершенных заказов на работу',
      'Number of Done Work Orders': 'Количество выполненных заказов на работу',
      'Charge Energy Indicator': 'Зарядка энергии',
      'Discharge Energy Indicator': 'Разрядка энергии',
      'Charge Cost Indicator': 'Стоимость зарядки',
      'Discharge Revenue Indicator': 'Доход от разрядки',
      'HVAC': 'HVAC',
      'Fire Control': 'Пожарная безопасность',

      // Dashboard
      'Welcome to DoraEOS': 'Добро пожаловать в DoraEOS',
      'An Industry Leading Open Source Energy Management System':
        'Ведущая в отрасли система управления энергопотреблением с открытым исходным кодом',
      "This Year's Consumption CATEGORY VALUE UNIT": 'В этом году Consumption {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Year's Costs CATEGORY VALUE UNIT": 'Расходы этого года {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Consumption CATEGORY VALUE UNIT": 'Потребление в этом месяце {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Costs CATEGORY VALUE UNIT": 'Расходы за этот месяц {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Consumption CATEGORY VALUE UNIT in The Same Period Last Year':
        'Потребление {{CATEGORY}} {{VALUE}} {{UNIT}} за аналогичный период прошлого года',
      'Costs CATEGORY VALUE UNIT in The Same Period Last Year':
        'Издержки {{CATEGORY}} {{VALUE}} {{UNIT}} за аналогичный период прошлого года',
      'CATEGORY Consumption UNIT': '{{CATEGORY}} Потребление {{UNIT}}',
      'CATEGORY Costs UNIT': '{{CATEGORY}} Издержки {{UNIT}}',
      // Entities
      Space: 'Пространство',
      Equipment: 'Оборудование',
      Meter: 'Метр',
      'Offline Meter': 'Автономный счетчик',
      'Virtual Meter': 'Виртуальный счетчик',
      Tenant: 'Съёмщик',
      Store: 'Магазин',
      Shopfloor: 'Торговый зал',
      'Combined Equipment': 'Комбинированное оборудование',
      'Cost Center': 'Центр затрат',
      Name: 'Имя',
      DisplayName: 'Отображаемое имя',
      UserName: 'Имя пользователя',
      Description: 'Описание',
      'Energy Category': 'Категория «Энергия»',
      'Child Spaces': 'Детские пространства',
      'Associated Equipment': 'Сопутствующее оборудование',
      //Energy Categories
      Electricity: 'Электричество',
      Water: 'Вода',
      'Natural Gas': 'Природный газ',
      // SidePanelModal
      Settings: 'Параметры',
      'Set your own customized style': 'Установите свой собственный стиль',
      'Color Scheme': 'Цветовая схема',
      'Choose the perfect color mode for your app': 'Выберите идеальный цветовой режим для вашего приложения',
      Light: 'Свет',
      Dark: 'Темный',
      'RTL Mode': 'Режим RTL',
      'Switch your language direction': 'Переключение языкового направления',
      'Fluid Layout': 'Плавная компоновка',
      'Toggle container layout system': 'Переключить систему компоновки контейнеров',
      'Navigation Position': 'Навигационное положение',
      'Select a suitable navigation system for your web application':
        'Выберите подходящую навигационную систему для вашего веб-приложения',
      'Vertical Nav': 'Вертикальная навигация',
      'Top Nav': 'Наверх Навигатор',
      'Vertical Navbar Style': 'Вертикальный стиль навигационной панели',
      'Switch between styles for your vertical navbar': 'Переключение между стилями для вертикальной панели навигации',
      Language: 'Язык',
      'Switch between languages': 'Переключение между языками',
      'language-zh_CN': '简体中文',
      'language-en': 'English',
      'language-de': 'Deutsch',
      'language-fr': 'Français',
      'language-es': 'Español',
      'language-ru': 'Русский',
      'language-ar': 'العربية',
      'language-vi': 'Tiếng Việt',
      'language-th': 'ภาษาไทย',
      'language-tr': 'Türkçe',
      'language-ms': 'Bahasa Melayu',
      'language-id': 'Bahasa Indonesia',
      'language-zh_TW': '繁體中文',
      'Like What You See?': 'Нравится то, что вы видите?',
      'Get DoraEOS now': 'Получить DoraEOS прямо сейчас',
      Purchase: 'Покупать',
      // Query Panel
      'Base Period Begins': 'Начинается базовый период',
      'Base Period Ends': 'Базовый период заканчивается',
      'Comparison Types': 'Типы сравнения',
      'Year-Over-Year': 'Год к году',
      'Month-On-Month': 'Месяц за месяцем',
      'Free Comparison': 'Бесплатное сравнение',
      'None Comparison': 'Нет сравнения',
      'Reporting Period Begins': 'Начало отчетного периода',
      'Reporting Period Ends': 'Окончание отчетного периода',
      '(Optional)': '(Необязательный)',
      'Period Types': 'Типы периодов',
      Yearly: 'Ежегодный',
      Monthly: 'Ежемесячный',
      Weekly: 'Еженедельный',
      Daily: 'Ежедневный',
      Hourly: 'Ежечасный',
      Submit: 'Отправить',
      'Input Energy Category': 'Категория входной энергии',
      'Output Energy Category': 'Категория выходной энергии',
      'Fraction Parameter': 'Параметр фракции',
      Search: 'Искать',
      //DateRangePicker
      sunday: 'Su',
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      ok: 'ХОРОШО',
      today: 'Сегодня',
      yesterday: 'Вчера',
      hours: 'Часов',
      minutes: 'Протокол',
      seconds: 'Товары второго сорта',
      last7Days: 'Последние 7 дней',
      'Select Date Range': 'Выберите диапазон дат',
      //Card Summaries and Line Charts
      'Base Period': 'Базовый период',
      'Reporting Period': 'Отчетный период',
      'Per Unit Area': 'На единицу площади',
      'Per Capita': 'Per Capita',
      'Per Unit Production': 'На единицу продукции',
      Baseline: 'Базис',
      Actual: 'Действительный',
      'Baseline Value - Actual Value': 'Базовый уровень - Фактический',
      'Average Load': 'Средняя нагрузка',
      'Maximum Load': 'Максимальная нагрузка',
      'Load Factor': 'Коэффициент загрузки',
      'Ratio of Average Load to Maximum Load': 'Отношение средней нагрузки к максимальной нагрузке',
      'Carbon Dioxide Emissions by Energy Category': 'Выбросы углекислого газа по энергетическим категориям',
      'Costs by Energy Category': 'Затраты по энергетическим категориям',
      'Incomes by Energy Category': 'Доходы по энергетическим категориям',
      'Electricity Consumption by Time-Of-Use': 'Потребление электроэнергии по времени использования',
      'Electricity Carbon Dioxide Emissions by Time-Of-Use':
        'Выбросы углекислого газа в электроэнергетике по времени использования',
      'Electricity Cost by Time-Of-Use': 'Стоимость электроэнергии по времени использования',
      'CATEGORY UNIT Consumption by Energy Items': '{{CATEGORY}} {{UNIT}} Потребление по энергетическим объектам',
      'Ton of Standard Coal by Energy Category': 'Тонна стандартного угля (ТВК) по энергетическим категориям',
      'Ton of Carbon Dioxide Emissions by Energy Category':
        'Тонн выбросов углекислого газа (TCO2E) по энергетическим категориям',
      'Reporting Period Consumption CATEGORY UNIT': 'Потребление за отчетный период {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{EQUIPMENT}} Потребление за отчетный период {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Потребление за отчетный период{{CATEGORY}} {{UNIT}}',
      'CATEGORY VALUE UNIT': '{{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption CATEGORY VALUE UNIT':
        'Потребление за отчетный период {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Production PRODUCT VALUE UNIT': 'Reporting Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Production PRODUCT VALUE UNIT': 'Base Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption CATEGORY VALUE UNIT': 'Потребление за базовый период {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY UNIT':
        'Потребление за отчетный период {{ITEM}} {{CATEGORY}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY VALUE UNIT':
        'Потребление за отчетный период {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption ITEM CATEGORY VALUE UNIT':
        'Потребление за базовый период {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Child Space Proportion CATEGORY UNIT':
        'Доля дочернего пространства по энергетическим категориям {{CATEGORY}} {{UNIT}}',
      'Child Space Total Proportion': 'Общая доля дочернего пространства',
      'Reporting Period Carbon Dioxide Emissions CATEGORY UNIT':
        'Выбросы двуокиси углерода за отчетный период {{CATEGORY}} {{UNIT}}',
      'Reporting Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Выбросы двуокиси углерода за отчетный период {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Выбросы углекислого газа за базовый период {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Costs CATEGORY UNIT': 'Затраты за отчетный период {{CATEGORY}} {{UNIT}}',
      'Reporting Period Costs CATEGORY VALUE UNIT': 'Затраты за отчетный период {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Costs CATEGORY VALUE UNIT': 'Затраты на базовый период {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Output CATEGORY UNIT': 'Результаты работы за отчетный период {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{EQUIPMENT}} Результаты работы за отчетный период {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Результаты работы за отчетный период {{CATEGORY}} {{UNIT}}',
      'Reporting Period Output CATEGORY VALUE UNIT':
        'Результаты работы за отчетный период {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Output CATEGORY VALUE UNIT': 'Выходные данные за базовый период {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Income CATEGORY UNIT': 'Доходы за отчетный период {{CATEGORY}} {{UNIT}}',
      'Reporting Period Income CATEGORY VALUE UNIT': 'Доходы за отчетный период {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Income CATEGORY VALUE UNIT': 'Доход за базовый период {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Total Income UNIT': 'Совокупный доход за отчетный период {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME UNIT':
        'Совокупная эффективность отчетного периода {{NAME}} {{UNIT}}',
      'EQUIPMENT Reporting Period Cumulative Efficiency UNIT':
        '{{EQUIPMENT}} Совокупная эффективность отчетного периода {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Совокупная комплексная эффективность отчетного периода {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Совокупная комплексная эффективность отчетного периода {{VALUE}} {{UNIT}}',
      'COMBINED_EQUIPMENT Base Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Базисный период Кумулятивная комплексная эффективность {{VALUE}} {{UNIT}}',
      'Instantaneous Efficiency UNIT': 'Мгновенная эффективность {{UNIT}}',
      'EQUIPMENT Instantaneous Efficiency UNIT': '{{EQUIPMENT}} Мгновенная эффективность {{UNIT}}',
      'COMBINED_EQUIPMENT Instantaneous Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Мгновенная комплексная эффективность {{UNIT}}',
      'Reporting Period Cumulative Efficiency VALUE UNIT':
        'Совокупная эффективность отчетного периода {{VALUE}} {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME VALUE UNIT':
        'Совокупная эффективность отчетного периода {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency VALUE UNIT': 'Кумулятивная эффективность базисного периода {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency NAME VALUE UNIT':
        'Кумулятивная эффективность базисного периода {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period CATEGORY Maximum Load UNIT': 'Базовый период {{CATEGORY}} Максимальная нагрузка {{UNIT}}',
      'Reporting Period CATEGORY Maximum Load UNIT': 'Отчетный период {{CATEGORY}} Максимальная нагрузка {{UNIT}}',
      'Reporting Period CATEGORY Average Load UNIT': 'Отчетный период {{CATEGORY}} Средняя нагрузка {{UNIT}}',
      'Reporting Period CATEGORY Load Factor': 'Отчетный период {{CATEGORY}} Коэффициент загрузки',
      'Base Period CATEGORY Average Load VALUE UNIT': 'Базовый период {{CATEGORY}} Средняя нагрузка {{VALUE}} {{UNIT}}',
      'Reporting Period CATEGORY Average UNIT': 'Отчетный период {{CATEGORY}} Среднее значение {{UNIT}}',
      'Reporting Period CATEGORY Maximum UNIT': 'Отчетный период {{CATEGORY}} Максимальное значение {{UNIT}}',
      'Reporting Period CATEGORY Minimum UNIT': 'Отчетный период {{CATEGORY}} Минимальное значение {{UNIT}}',
      'Reporting Period CATEGORY Mean UNIT': 'Отчетный период {{CATEGORY}} Среднее арифметическое {{UNIT}}',
      'Reporting Period CATEGORY Median UNIT': 'Отчетный период {{CATEGORY}} Медиана (Среднее значение) {{UNIT}}',
      'Reporting Period CATEGORY Stdev UNIT': 'Отчетный период {{CATEGORY}} Стандартное отклонение образца {{UNIT}}',
      'Reporting Period CATEGORY Variance UNIT': 'Отчетный период {{CATEGORY}} Дисперсия выборки {{UNIT}}',
      'Reporting Period Saving CATEGORY (Baseline - Actual) UNIT':
        'Сохранение отчетного периода {{CATEGORY}} (Базовый уровень - Фактический) {{UNIT}}',
      'Reporting Period Decreased CATEGORY (Baseline - Actual) UNIT':
        'Отчетный период сократился {{CATEGORY}} (Базовый уровень - Фактический) {{UNIT}}',
      'Reporting Period Saving CATEGORY VALUE UNIT': 'Сохранение отчетного периода {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Saving CATEGORY VALUE UNIT': 'Экономия за базовый период {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY UNIT':
        'Потребление эталонного счетчика за отчетный период {{CATEGORY}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY VALUE UNIT':
        'Потребление эталонного счетчика за отчетный период {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY UNIT':
        'Потребление субсчетчиков за отчетный период {{CATEGORY}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY VALUE UNIT':
        'Потребление субсчетчиков за отчетный период {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Difference CATEGORY UNIT': 'Разница в отчетных периодах {{CATEGORY}} {{UNIT}}',
      'Reporting Period Percentage Difference': 'Разница в процентах за отчетный период',
      'Reporting Period Difference CATEGORY VALUE UNIT': 'Разница в отчетных периодах {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Trend Values': 'Значения тренда',
      'METER CATEGORY VALUE UNIT': '{{METER}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      //FDD
      'Fault List': 'Список неисправностей',
      'Reporting Period Number of Fault VALUE': 'Номер неисправности за отчетный период {{VALUE}}',
      'All Faults': 'Все неисправности',
      'Space Faults': 'Космические разломы',
      'Number of All Faults': 'Количество всех неисправностей',
      'Number of Space Faults': 'Количество пробелов',
      'Total Number of Faults': 'Total Number of Faults',
      'Number of New Faults': 'Number of New Faults',
      'Number of Inprogress Faults': 'Number of Inprogress Faults',
      'Number of Done Faults': 'Number of Done Faults',
      //Data Panels
      'Ton of Standard Coal': 'Тонна стандартного угля',
      'Ton of Carbon Dioxide Emissions': 'тонн выбросов углекислого газа',
      'Operating Characteristic Curve': 'рабочая характеристика',
      Tariff: 'Тариф',
      'Detailed Data': 'Подробные данные',
      'Child Spaces Data': 'Данные о дочерних пространствах',
      'Associated Equipment Data': 'Связанные данные об оборудовании',
      'This Year': 'В этом году',
      'The Same Period Last Year': 'Аналогичный период прошлого года',
      'This Month': 'В этом месяце',
      'This Day': 'Сегодня',
      Total: 'Итог',
      'No data found': 'Данные не найдены',
      Export: 'Экспорт',
      Datetime: 'Дата и время',
      'Time-Of-Use Type': 'Тип времени использования',
      'Top-Peak': 'Топ-Пик',
      'On-Peak': 'На пике',
      'Mid-Peak': 'Мид-Пик',
      'Off-Peak': 'Вне пика',
      Percentage: 'Процент',
      //Realtime Chart
      'Trend in the last hour of Energy Value Point': 'Тренд в последний час Energy Value Point',
      Circuit: 'Контур',
      Point: 'Точка',
      'Realtime Value': 'Значение в реальном времени',
      //Meter Tracking
      'Meter List': 'Список счетчиков',
      'Edit Meter': 'Редактировать',
      'Start Value': 'Начальное значение',
      'End Value': 'Конечная стоимость',
      'Difference Value': 'Значение разницы',
      'Start Integrity Rate': 'Начальный уровень целостности(%)',
      'End Integrity Rate': 'Конечная степень целостности(%)',
      'Full Integrity Rate': 'Полная степень целостности(%)',
      //Equipment Tracking
      'Equipment List': 'Список оборудования',
      'Edit Equipment': 'Редактировать',
      //Profile Dropdown
      Feedback: 'Обратная связь',
      'Account Settings': 'Настройки учетной записи',
      Logout: 'Выход',
      //Authentication
      'Log in': 'Войти',
      'Email address': 'Адрес электронной почты',
      Password: 'Пароль',
      CaptchaCode: 'Капча',
      Refresh: 'Освежать',
      'Remember me': 'Помни обо мне',
      'Logged in as ': 'Авторизовался как ',
      'Forgot Password?': 'Забыли пароль?',
      'Forgot your password?': 'Забыли пароль?',
      "Enter your email and we'll send you a reset link":
        'Введите свой адрес электронной почты, и мы вышлем вам ссылку для сброса',
      "Enter your email and we'll send you a registration link":
        'Введите свой адрес электронной почты, и мы вышлем вам ссылку для регистрации',
      'Reset Password': 'Сброс пароля',
      'Thanks for using DoraEOS!': 'Спасибо за использование DoraEOS!',
      'You are now successfully signed out': 'Теперь вы успешно вышли из системы',
      'Return to Login': 'Вернуться к входу в систему',
      'Please check your email!': 'Пожалуйста, проверьте свою электронную почту!',
      'An email has been sent to ': 'Электронное письмо было отправлено по адресу ',
      'Please click on the included link to reset your password':
        'Пожалуйста, нажмите на прилагаемую ссылку, чтобы сбросить пароль',
      'Please click on the included link to register your account':
        'Пожалуйста, нажмите на прилагаемую ссылку, чтобы зарегистрировать свою учетную запись',
      'An email with password reset link is sent to ':
        'Электронное письмо со ссылкой для сброса пароля отправляется на адрес ',
      'Change Password': 'Смена пароля',
      'Old Password': 'Старый пароль',
      'New Password': 'Новый пароль',
      'Confirm Password': 'Подтвердите пароль',
      'Update Password': 'Обновить пароль',
      'Password has been changed!': 'Пароль был изменен!',
      'Verification code has been sent to your inbox. \
      Please copy it to the input box below..':
        'Код подтверждения был отправлен на ваш почтовый ящик. \
      Скопируйте его в поле ввода ниже.',
      'New to DoraEOS': 'Новичок в DoraEOS',
      'Create an account': 'Создать аккаунт',
      'EMAIL Account registration successful': '{{EMAIL}} Регистрация аккаунта прошла успешно',
      'Thanks for verifying your account!': 'Спасибо за подтверждение вашей учетной записи!',
      'Your code is': 'Ваш код:',
      'Send verification code': 'Отправить проверочный код',
      'Please wait for NUMBER seconds': 'Пожалуйста, подождите {{NUMBER}} товары второго сорта',
      'Password reset': 'Сброс пароля',
      'Please wait for approval': 'Пожалуйста, дождитесь одобрения',
      //notification
      Notifications: 'Уведомления',
      'Mark all as read': 'Пометить все как прочитанное',
      'View all': 'Bce',
      'Notification New': 'Новые функции',
      'Notification Earlier': 'РАНЬШЕ',
      'Notification List': 'Список уведомлений',
      'Bulk actions': 'Массовые действия',
      'Notification Subject': 'Тема',
      'Notification Created Datetime': 'Дата и время создания',
      'Notification Priority': 'Приоритет',
      'Notification Message': 'Сообщение',
      'Notification Status': 'Статус',
      'Notification Mark As Read': 'Отметить как прочитанное',
      'Notification Mark As Acknowledged': 'Признавать',
      'Notification Low': 'Низкий',
      'Notification Medium': 'Терпимая',
      'Notification High': 'Высокий',
      'Notification Critical': 'Критический',
      'Notification Unread': 'Непрочитанный',
      'Notification Read': 'Читать',
      'Notification Acknowledged': 'Признанный',
      'Notification Archive': 'Архив',
      'Notification Delete': 'Удалить',
      'Notification Apply': 'Применять',
      'Notification Start Datetime': 'Дата и время начала',
      'Notification End Datetime': 'Конец даты и времени',
      'Notification Update Datetime': 'Обновить дату и время',
      //Monitoring
      'Run Commands': 'Выполнение команд',
      'Fault Alarms': 'Аварийные сигналы о неисправностях',
      'Instantaneous Efficiency VALUE UNIT': 'Мгновенная эффективность {{VALUE}} {{UNIT}}',
      'Communication Online': 'Онлайн',
      'Communication Offline': 'Автономный',
      'Equipment Status': 'Состояние оборудования',
      'Equipment Running': 'Бег',
      'Equipment Stopped': 'Остановленный',
      'Show Up to': 'Показать до',
      All: 'Все',
      'FROM - TO of TOTAL': '{{FROM}} - {{TO}} из {{TOTAL}} Оборудования',
      //Microgrid
      'PCS Run State': 'Режим работы PCS',
      'PCS Unknown': 'Неизвестный',
      'PCS Initializing': 'Начинается',
      'PCS Standby': 'Запасные материалы',
      'PCS Shutdown': 'Остановка',
      'PCS Fault': 'Ошибка',
      'PCS Running': 'Бег',
      'Battery Power': 'Мощность батареи',
      'Photovoltaic Power': 'Фотоэлектрическая мощность',
      'Grid Power': 'Мощность сети',
      'Load Power': 'мощность нагрузки',
      Reporting: 'Доклады',
      Maintenance: 'Техническое обслуживание',
      'Serial Number': 'Серийный номер',
      Address: 'Адрес',
      'Postal Code': 'Почтовый индекс',
      'Rated Capacity': 'Номинальная емкость',
      'Rated Power': 'Номинальная мощность',
      Latitude: 'Широта',
      Longitude: 'Долгота',
      'Strategy Management': 'Стратегическое управление',
      'Charging Schedule': 'График зарядки',
      'Charge Start Time': 'Время начала зарядки',
      'Charge End Time': 'Время окончания зарядки',
      'Discharge Start Time': 'Время начала разряда',
      'Discharge End Time': 'Время окончания разряда',
      'Total Rated Power': 'Общая номинальная мощность',
      'Total Rated Capacity': 'Общая номинальная мощность',
      "Today's Charge": "Сегодняшняя сумма начисления",
      "Today's Discharge": "Выписка сегодня",
      "Today's Generation": "Современная энергетика",
      'Total Charge': 'Полный заряд',
      'Total Discharge': 'общий сброс',
      'Total Generation': 'Общая генерация',
      'Total Revenue': 'Общий доход',
      'Total Efficiency': 'Общая эффективность',
      'Discharge Achievement Rate': 'Скорость достижения разряда',
      // Revenue Indicator
      "Today's Cost": "Today's Cost",
      "Today's Income": "Today's Income",
      "Total Cost": "Total Cost",
      "Total Income": "Total Income",
      "Today's Revenue": "Today's Revenue",

      'Revenue Ranking': 'Рейтинг доходов',
      'Efficiency Ranking': 'Рейтинг эффективности',
      'Charge Ranking': 'Рейтинг зарядной емкости',
      'Discharge Ranking': 'Рейтинг разряда',
      'Number of Microgrids': 'Количество микросетей',
      'Number of Power Stations': 'Количество электростанций',
      'Microgrid List': 'Список микросетей',
      'Previous Page': 'Предыдущая страница',
      'Next Page': 'Следущая страница',
      '7 Days': '7 дней',
      'Charge UNIT': 'Заряжать {{UNIT}}',
      'Discharge UNIT': 'увольнять {{UNIT}}',
      'Generation UNIT': 'генерировать электричество {{UNIT}}',
      'Energy Indicator': 'Энергетика',
      'Revenue Indicator': 'Поступления',
      'Carbon Indicator': 'Углерод',

      // Energy Storage Power Station
      'Battery Operating State': 'режим работы батареи',
      'Battery State': 'Состояние батареи',
      'Battery Unknown': 'Неизвестный',
      'Battery Stopped': 'Прекращено',
      'Battery Reserved': 'Reserved', // 0
      'Battery Fault': 'Ошибка', // 1
      'Battery Warning': 'предупреждение', // 2
      'Battery Standby': 'Запасные материалы', // 3
      'Battery Prohibit DisCharging': 'Запрещается выгрузка', // 4
      'Battery Prohibit Charging': 'Зарядка запрещена.', // 5
      'Battery Normal': 'Типичный', // 6
      'Battery Charging': 'Заряжается.', // 7
      'Battery Discharging': 'В разряде.', // 8
      'Battery Idle': 'Idle', // 9
      'Phase of Lifecycle': 'Этапы жизненного цикла',
      'Use Phase': 'Этап использования',
      'Commissioning Phase': 'Этап отладки',
      'Installation Phase': 'Этап установки',
      'Device Status': 'Состояние устройства',
      'Basic Information': 'Основная информация',
      'Gateway': 'Gateway',
      'Grid Meter': 'Grid Meter',
      'Load Meter': 'Load Meter',
      'Total Active Power': 'Total Active Power',
      'Active Power A': 'Active Power A',
      'Active Power B': 'Active Power B',
      'Active Power C': 'Active Power C',
      'Total Reactive Power': 'Total Reactive Power',
      'Reactive Power A': 'Reactive Power A',
      'Reactive Power B': 'Reactive Power B',
      'Reactive Power C': 'Reactive Power C',
      'Total Apparent Power': 'Total Apparent Power',
      'Apparent Power A': 'Apparent Power A',
      'Apparent Power B': 'Apparent Power B',
      'Apparent Power C': 'Apparent Power C',
      'Total Power Factor': 'Total Power Factor',
      'Active Energy Import': 'Active Energy Import',
      'Active Energy Export': 'Active Energy Export',
      'Active Energy Net': 'Active Energy Net',

      // Photovoltaic Power Station
      'Invertor': 'инверторы',
      'Invertor Run State': 'Режим работы',
      'Invertor Unknown': 'Неизвестный',
      'Invertor Initializing': 'Начинается',
      'Invertor Standby': 'Запасные материалы',
      'Invertor Shutdown': 'Остановка',
      'Invertor Fault': 'Ошибка',
      'Invertor Running': 'Бег',

      //Advanced Reporting & Knowledge base
      'Created Datetime': 'Дата и время создания',
      'File Format': 'Формат файла',
      'File Size': 'Размер файла',
      Uploader: 'Разместил(а)',
      //Error
      "The page you're looking for is not found": 'Страница, которую вы ищете, не найдена',
      "Make sure the address is correct and that the page hasn't moved. ":
        'Убедитесь, что адрес указан правильно и страница не перемещена. ',
      'If you think this is a mistake,': 'Если вы считаете, что это ошибка,',
      'contact us': 'Свяжитесь с нами',
      'Take me home': 'Отвези меня домой',
      'Whoops, something went wrong!': 'Ой, что-то пошло не так!',
      'Try refreshing the page, or going back and attempting the action again. ':
        'Попробуйте обновить страницу или вернуться и повторить попытку выполнения. ',
      'If this problem persists,': 'Если проблема не устранена,',
      'Captcha Error': 'Ошибка капчи',
      'Confirm Password Error': 'Пароль подтверждения не совпадает',
      'It looks like you clicked on an invalid password reset link. Please tryagain.':
        'Похоже, вы нажали \
      по неверной ссылке для сброса пароля. Пожалуйста, попробуйте еще раз.',
      'It looks like you clicked on an invalid registration account link. Please tryagain.':
        'Похоже, вы нажали \
      по неверной ссылке на регистрационный аккаунт. Пожалуйста, попробуйте еще раз.',
      //Tenant Bill
      'Lease Contract Number': 'Номер договора аренды',
      Download: 'Загружать',
      Print: 'Печатать',
      'Payment Notice': 'Уведомление об оплате',
      'Bill To': 'Билл К',
      'Bill Number': 'Номер счета',
      'Bill Date': 'Дата выставления счета',
      'Payment Due Date': 'Срок оплаты',
      'Amount Payable': 'Сумма к оплате',
      'Billing Period Start': 'Начало расчетного периода',
      'Billing Period End': 'Окончание расчетного периода',
      Quantity: 'Количество',
      Price: 'Цена',
      Unit: 'Единица',
      Amount: 'Количество',
      Subtotal: 'Промежуточная сумма',
      'VAT Output Tax': 'Налог на добавленную стоимость',
      'Total Amount Payable': 'Общая сумма к оплате',
      'Please make sure to pay on or before the payment due date above':
        'Пожалуйста, убедитесь, что вы оплатили в установленный выше срок оплаты или раньше',
      'Send money to the following account': 'Отправьте деньги на следующий счет',
      'Account Name': 'Имя учетной записи',
      'Bank Name': 'Название банка',
      'Bank Address': 'Адрес банка',
      'RMB Account': 'Счет в юанях',
      // button
      'Toggle Navigation': 'Переключить навигацию',
      // working calendar
      'Working Days': 'Будни',
      'Non Working Days': 'Нерабочие дни',
      'Select Row': 'Пожалуйста, выберите строку данных',
      // Offline Meter Input
      'Daily Value': 'Суточная норма',
      Date: 'Дата',
      'Successfully Saved': 'Успешно сохранено',
      'Previous Data Is Empty': 'Предыдущие данные пусты',
      // API
      'API.ACCOUNT_UNLOCK_FAILED': 'Account unlock failed',
      'API.ADMINISTRATOR_SESSION_NOT_FOUND': 'Administrator session not found',
      'API.ADMINISTRATOR_SESSION_TIMEOUT': 'Administrator session timeout',
      'API.ADVANCED_REPORT_NOT_FOUND': 'Advanced report not found',
      'API.API_KEY_HAS_EXPIRED': 'Key has expired',
      'API.API_KEY_NAME_IS_ALREADY_IN_USE': 'Key name is already in use',
      'API.API_KEY_NOT_FOUND': 'Key not found',
      'API.BAD_REQUEST': 'Bad request',
      'API.BALANCING_PRICE_POINT_NOT_FOUND': 'Balancing price point not found',
      'API.BATTERY_STATE_POINT_NOT_FOUND': 'Battery state point not found',
      'API.BUY_METER_NOT_FOUND': 'Buy meter not found',
      'API.CANNOT_SET_EXISTING_SUBMETER_AS_MASTER_METER': 'Cannot set existing submeter as master meter',
      'API.CHARGE_METER_NOT_FOUND': 'Charge meter not found',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_EXISTS': 'Combined equipment command relation exists',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Combined equipment command relation not found',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_EXISTS': 'Combined equipment equipment relation exists',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_NOT_FOUND': 'Combined equipment equipment relation not found',
      'API.COMBINED_EQUIPMENT_METER_RELATION_EXISTS': 'Combined equipment meter relation exists',
      'API.COMBINED_EQUIPMENT_METER_RELATION_NOT_FOUND': 'Combined equipment meter relation not found',
      'API.COMBINED_EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Combined equipment name is already in use',
      'API.COMBINED_EQUIPMENT_NOT_FOUND': 'Combined equipment not found',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Combined equipment offline meter relation exists',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Combined equipment offline meter relation not found',
      'API.COMBINED_EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Combined equipment parameter name is already in use',
      'API.COMBINED_EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Combined equipment parameter not found or not match',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Combined equipment virtual meter relation exists',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Combined equipment virtual meter relation not found',
      'API.COMMAND_NAME_IS_ALREADY_IN_USE': 'Command name is already in use',
      'API.COMMAND_NOT_FOUND': 'Command not  found',
      'API.CONTACT_NAME_IS_ALREADY_IN_USE': 'Contact name is already in use',
      'API.CONTACT_NOT_FOUND': 'Contact not found',
      'API.COOLING_METER_NOT_FOUND': 'Cooling meter not found',
      'API.COST_CENTER_EXTERNAL_ID_EXISTS': 'Cost center external id exists',
      'API.COST_CENTER_NAME_EXISTS': 'Cost center name exists',
      'API.COST_CENTER_NOT_FOUND': 'Cost center not found',
      'API.COST_FILE_NOT_FOUND': 'Cost file not found',
      'API.DATA_REPAIR_FILE_NOT_FOUND': 'Data repair file not found',
      'API.DATA_SOURCE_NAME_IS_ALREADY_IN_USE': 'Data source name is already in use',
      'API.DATA_SOURCE_NOT_FOUND': 'Data source not found',
      'API.DATABASE_ERROR': 'Database error',
      'API.DATE_IS_ALREADY_IN_WORKING_CALENDAR': 'Date Is Already In Working Calendar',
      'API.DATE_LOCAL_NOT_FOUND': 'Date local not found',
      'API.DISCHARGE_METER_NOT_FOUND': 'Discharge meter not found',
      'API.DISTRIBUTION_CIRCUIT_NAME_IS_ALREADY_IN_USE': 'Distribution circuit name is already in use',
      'API.DISTRIBUTION_CIRCUIT_NOT_FOUND': 'Distribution circuit not found',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_EXISTS': 'Distribution circuit point relation exists',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_NOT_FOUND': 'Distribution circuit point relation not found',
      'API.DISTRIBUTION_SYSTEM_NAME_IS_ALREADY_IN_USE': 'Distribution system name is already in use',
      'API.DISTRIBUTION_SYSTEM_NOT_FOUND': 'Distribution system not found',
      'API.ELECTRICITY_METER_NOT_FOUND': 'Electricity meter not found',
      'API.EMAIL_IS_ALREADY_IN_USE': 'Email is already in use',
      'API.EMAIL_MESSAGE_NOT_FOUND': 'Email message not found',
      'API.EMAIL_NOT_FOUND': 'Email not found',
      'API.EMAIL_SERVER_HOST_IS_ALREADY_IN_USE': 'Email server host is already in use',
      'API.EMAIL_SERVER_NOT_FOUND': 'Email server not found',
      'API.EMPTY_VARIABLES_ARRAY': 'Empty variables array',
      'API.ENERGY_CATEGORY_NAME_IS_ALREADY_IN_USE': 'Energy category name is already in use',
      'API.ENERGY_CATEGORY_NOT_FOUND': 'Energy category not found',
      'API.ENERGY_CATEGORY_USED_IN_ENERGY_ITEMS': 'Energy category used in energy items',
      'API.ENERGY_CATEGORY_USED_IN_METER': 'Energy category used in meter',
      'API.ENERGY_CATEGORY_USED_IN_OFFLINE_METER': 'Energy category used in offline meter',
      'API.ENERGY_CATEGORY_USED_IN_TARIFFS': 'Energy category used in tariffs',
      'API.ENERGY_CATEGORY_USED_IN_VIRTUAL_METER': 'Energy category used in virtual meter',
      'API.ENERGY_FLOW_DIAGRAM_LINK_IS_ALREADY_IN_USE': 'Energy flow diagram link is already in use',
      'API.ENERGY_FLOW_DIAGRAM_LINK_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram link not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram node name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram node not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NOT_FOUND': 'Energy flow diagram not found',
      'API.ENERGY_ITEM_DOES_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item does not belong to energy category',
      'API.ENERGY_ITEM_IS_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item is not belong to energy category',
      'API.ENERGY_ITEM_NAME_IS_ALREADY_IN_USE': 'Energy item name is already in use',
      'API.ENERGY_ITEM_NOT_FOUND': 'Energy item not found',
      'API.ENERGY_ITEM_USED_IN_METER': 'Energy item used in meter',
      'API.ENERGY_ITEM_USED_IN_OFFLINE_METER': 'Energy item used in offline meter',
      'API.ENERGY_ITEM_USED_IN_VIRTUAL_METER': 'Energy item used in virtual meter',
      'API.ENERGY_STORAGE_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Energy storage power station name is already in use',
      'API.ENERGY_STORAGE_POWER_STATION_NOT_FOUND': 'Energy storage power station not found',
      'API.EQUIPMENT_COMMAND_RELATION_EXISTS': 'Equipment command relation exists',
      'API.EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Equipment command relation not found',
      'API.EQUIPMENT_METER_RELATION_EXISTS': 'Equipment meter relation exists',
      'API.EQUIPMENT_METER_RELATION_NOT_FOUND': 'Equipment meter relation not found',
      'API.EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Equipment name is already in use',
      'API.EQUIPMENT_NOT_FOUND': 'Equipment not found',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Equipment offline meter relation exists',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Equipment offline meter relation not found',
      'API.EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Equipment parameter name is already in use',
      'API.EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Equipment parameter not found or not match',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Equipment virtual meter relation exists',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Equipment virtual meter relation not found',
      'API.ERROR': 'Error',
      'API.EXCEPTION': 'Exception',
      'API.FAILED_TO_READ_REQUEST_STREAM': 'Failed to read request stream',
      'API.FAILED_TO_RESTORE_COST_FILE': 'Failed to restore cost file',
      'API.FAILED_TO_RESTORE_DATA_REPAIR_FILE': 'Failed to restore data repair file',
      'API.FAILED_TO_RESTORE_KNOWLEDGE_FILE': 'Failed to restore knowledge file',
      'API.FAILED_TO_RESTORE_OFFLINE_METER_FILE': 'Failed to restore offline meter file',
      'API.FAILED_TO_SAVE_OFFLINE_METER_FILE': 'Failed to save offline meter file',
      'API.FAILED_TO_UPLOAD_ATTACHMENT_FILE': 'Failed to upload attachment file',
      'API.FAILED_TO_UPLOAD_COST_FILE': 'Failed to upload cost file',
      'API.FAILED_TO_UPLOAD_DATA_REPAIR_FILE': 'Failed to upload data repair file',
      'API.FAILED_TO_UPLOAD_KNOWLEDGE_FILE': 'Failed to upload knowledge file',
      'API.FAILED_TO_UPLOAD_OFFLINE_METER_FILE': 'Failed to upload offline meter file',
      'API.GATEWAY_NAME_IS_ALREADY_IN_USE': 'Gateway name is already in use',
      'API.GATEWAY_NOT_FOUND': 'Gateway not found',
      'API.HEAT_METER_NOT_FOUND': 'Heat meter not found',
      'API.INVALID_': 'Invalid ',
      'API.INVALID_ACKNOWLEDGE_CODE': 'Invalid acknowledge code',
      'API.INVALID_ADDRESS': 'Invalid address',
      'API.INVALID_ADDRESS_VALUE': 'Invalid address value',
      'API.INVALID_ADVANCED_REPORT_ID': 'Invalid advanced report id',
      'API.INVALID_API_KEY_ID': 'Invalid api key id',
      'API.INVALID_API_KEY_NAME': 'Invalid api key name',
      'API.INVALID_AREA_VALUE': 'Invalid area value',
      'API.INVALID_BALANCING_PRICE_POINT_ID': 'Invalid balancing price point ID',
      'API.INVALID_BASE_PERIOD_END_DATETIME': 'Invalid base period end datetime',
      'API.INVALID_BASE_PERIOD_START_DATETIME': 'Invalid base period start datetime',
      'API.INVALID_BATTERY_STATE_POINT_ID': 'Invalid battery state point ID',
      'API.INVALID_BAUD_RATE': 'Invalid baud rate',
      'API.INVALID_BUILDINGS_VALUE': 'Invalid buildings value',
      'API.INVALID_BUY_METER_ID': 'Invalid buy meter id',
      'API.INVALID_CAPACITY': 'Invalid capacity',
      'API.INVALID_CATEGORY': 'Invalid category',
      'API.INVALID_CHANNEL': 'Invalid channel',
      'API.INVALID_CHARGE_METER_ID': 'Invalid charge meter id',
      'API.INVALID_COMBINED_EQUIPMENT_ID': 'Invalid combined equipment id',
      'API.INVALID_COMBINED_EQUIPMENT_NAME': 'Invalid combined equipment name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_ID': 'Invalid combined equipment parameter id',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_NAME': 'Invalid combined equipment parameter name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_TYPE': 'Invalid combined equipment parameter type',
      'API.INVALID_COMBINED_EQUIPMENT_UUID': 'Invalid combined equipment uuid',
      'API.INVALID_COMMAND_ID': 'Invalid command id',
      'API.INVALID_COMMAND_NAME': 'Invalid command name',
      'API.INVALID_CONNECTION': 'Invalid connection',
      'API.INVALID_CONSTANT_VALUE': 'Invalid constant value',
      'API.INVALID_CONTACT_ID': 'Invalid contact id',
      'API.INVALID_CONTACT_NAME': 'Invalid contact name',
      'API.INVALID_COOLING_METER_ID': 'Invalid cooling meter id',
      'API.INVALID_COST_CENTER_ID': 'Invalid cost center id',
      'API.INVALID_COST_FILE_ID': 'Invalid cost file id',
      'API.INVALID_CREATED_DATETIME': 'Invalid created datetime',
      'API.INVALID_DATA_REPAIR_FILE_ID': 'Invalid data repair file id',
      'API.INVALID_DATA_SOURCE_ID': 'Invalid data source id',
      'API.INVALID_DATA_SOURCE_NAME': 'Invalid data source name',
      'API.INVALID_DATA_SOURCE_PROTOCOL': 'Invalid data source protocol',
      'API.INVALID_DATE_LOCAL': 'Invalid date local',
      'API.INVALID_DENOMINATOR_METER_UUID': 'Invalid denominator meter uuid',
      'API.INVALID_DISCHARGE_METER_ID': 'Invalid discharge meter id',
      'API.INVALID_DISPLAY_NAME': 'Invalid display name',
      'API.INVALID_DISTRIBUTION_CIRCUIT_ID': 'Invalid distribution circuit id',
      'API.INVALID_DISTRIBUTION_CIRCUIT_NAME': 'Invalid distribution circuit name',
      'API.INVALID_DISTRIBUTION_ROOM': 'Invalid distribution room',
      'API.INVALID_DISTRIBUTION_SYSTEM_ID': 'Invalid distribution system id',
      'API.INVALID_DISTRIBUTION_SYSTEM_NAME': 'Invalid distribution system name',
      'API.INVALID_ELECTRICITY_METER_ID': 'Invalid Electricity meter id',
      'API.INVALID_EMAIL': 'Invalid email',
      'API.INVALID_EMAIL_MESSAGE_ID': 'Invalid email message id',
      'API.INVALID_EMAIL_SERVER_HOST': 'Invalid email server host',
      'API.INVALID_EMAIL_SERVER_ID': 'Invalid email server id',
      'API.INVALID_END_DATE_FORMAT': 'Invalid end date format',
      'API.INVALID_END_DATETIME': 'Invalid end datetime',
      'API.INVALID_ENERGY_CATEGORY_ID': 'Invalid energy category id',
      'API.INVALID_ENERGY_CATEGORY_NAME': 'Invalid energy category name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_ID': 'Invalid energy flow diagram id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_LINK_ID': 'Invalid energy flow diagram link id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NAME': 'Invalid energy flow diagram name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_ID': 'Invalid energy flow diagram node id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_NAME': 'Invalid energy flow diagram node name',
      'API.INVALID_ENERGY_ITEM_ID': 'Invalid energy item id',
      'API.INVALID_ENERGY_ITEM_NAME': 'Invalid energy item name',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_ID': 'Invalid energy storage power station ID',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_NAME': 'Invalid energy storage power station name',
      'API.INVALID_EQUATION_IN_EXPRESSION': 'Invalid equation in expression',
      'API.INVALID_EQUIPMENT_ID': 'Invalid equipment id',
      'API.INVALID_EQUIPMENT_NAME': 'Invalid equipment name',
      'API.INVALID_EQUIPMENT_PARAMETER_ID': 'Invalid equipment parameter id',
      'API.INVALID_EQUIPMENT_PARAMETER_NAME': 'Invalid equipment parameter name',
      'API.INVALID_EQUIPMENT_PARAMETER_TYPE': 'Invalid equipment parameter type',
      'API.INVALID_EQUIPMENT_UUID': 'Invalid equipment uuid',
      'API.INVALID_EXPIRES_DATETIME': 'Invalid expires datetime',
      'API.INVALID_EXPRESSION': 'Invalid expression',
      'API.INVALID_EXPRESSION_OBJECT': 'Invalid expression object',
      'API.INVALID_FDD_CODE': 'Invalid fdd code',
      'API.INVALID_FLOORS_VALUE': 'Invalid floors value',
      'API.INVALID_FROM_ADDR': 'Invalid from addr',
      'API.INVALID_GATEWAY_ID': 'Invalid gateway id',
      'API.INVALID_GATEWAY_NAME': 'Invalid gateway name',
      'API.INVALID_HEAT_METER_ID': 'Invalid heat meter id',
      'API.INVALID_HIGH_LIMIT_VALUE': 'Invalid high limit value',
      'API.INVALID_HOURLY_HIGH_LIMIT_VALUE': 'Invalid hourly high limit value',
      'API.INVALID_HOURLY_LOW_LIMIT_VALUE': 'Invalid hourly low limit value',
      'API.INVALID_Id': 'Invalid id',
      'API.INVALID_IS_ADMIN_VALUE': 'Invalid is admin value',
      'API.INVALID_IS_COST_DATA_DISPLAYED': 'Invalid is cost data displayed',
      'API.INVALID_IS_COUNTED_VALUE': 'Invalid is counted value',
      'API.INVALID_IS_ENABLED': 'Invalid is enabled',
      'API.INVALID_IS_HIDDEN': 'Invalid is hidden',
      'API.INVALID_IS_IN_LEASE_VALUE': 'Invalid is in lease value',
      'API.INVALID_IS_INPUT_COUNTED_VALUE': 'Invalid is input counted value',
      'API.INVALID_IS_KEY_TENANT_VALUE': 'Invalid is key tenant value',
      'API.INVALID_IS_OUTPUT_COUNTED_VALUE': 'Invalid is output counted value',
      'API.INVALID_IS_OUTPUT_VALUE': 'Invalid is output value',
      'API.INVALID_IS_READ_ONLY_VALUE': 'Invalid is read only value',
      'API.INVALID_IS_TREND_VALUE': 'Invalid is trend value',
      'API.INVALID_IS_VIRTUAL_VALUE': 'Invalid is virtual value',
      'API.INVALID_KGCE': 'Invalid kgce',
      'API.INVALID_KGCO2E': 'Invalid kgco2e',
      'API.INVALID_KNOWLEDGE_FILE_ID': 'Invalid knowledge file id',
      'API.INVALID_LATITUDE_VALUE': 'Invalid latitude value',
      'API.INVALID_LEASE_NUMBER_VALUE': 'Invalid lease number value',
      'API.INVALID_LONGITUDE_VALUE': 'Invalid longitude value',
      'API.INVALID_LOW_LIMIT_VALUE': 'Invalid low limit value',
      'API.INVALID_MASTER_METER_ID': 'Invalid master meter id',
      'API.INVALID_MENU_ID': 'Invalid menu id',
      'API.INVALID_MESSAGE_DATA': 'Invalid message data',
      'API.INVALID_MESSAGE_TEMPLATE': 'Invalid message template',
      'API.INVALID_MESSAGE_TEMPLATE_ID': 'Invalid message template id',
      'API.INVALID_MESSAGE_VALUE': 'Invalid message value',
      'API.INVALID_METER_ID': 'Invalid meter id',
      'API.INVALID_METER_NAME': 'Invalid meter name',
      'API.INVALID_METER_UUID': 'Invalid meter uuid',
      'API.INVALID_MICROGRID_BATTERY_ID': 'Invalid microgrid battery id',
      'API.INVALID_MICROGRID_BATTERY_NAME': 'Invalid microgrid battery name',
      'API.INVALID_MICROGRID_EVCHARGER_ID': 'Invalid microgrid evcharger id',
      'API.INVALID_MICROGRID_EVCHARGER_NAME': 'Invalid microgrid evcharger name',
      'API.INVALID_MICROGRID_GENERATOR_ID': 'Invalid microgrid generator id',
      'API.INVALID_MICROGRID_GENERATOR_NAME': 'Invalid microgrid generator name',
      'API.INVALID_MICROGRID_GRID_ID': 'Invalid microgrid grid id',
      'API.INVALID_MICROGRID_GRID_NAME': 'Invalid microgrid grid name',
      'API.INVALID_MICROGRID_HEATPUMP_ID': 'Invalid microgrid heatpump id',
      'API.INVALID_MICROGRID_HEATPUMP_NAME': 'Invalid microgrid heatpump name',
      'API.INVALID_MICROGRID_ID': 'Invalid microgrid id',
      'API.INVALID_MICROGRID_LOAD_ID': 'Invalid microgrid load id',
      'API.INVALID_MICROGRID_LOAD_NAME': 'Invalid microgrid load name',
      'API.INVALID_MICROGRID_NAME': 'Invalid microgrid name',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_ID': 'Invalid microgrid photovoltaic id',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_NAME': 'Invalid microgrid photovoltaic name',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_ID': 'Invalid microgrid power conversion system ID',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_NAME': 'Invalid microgrid microgrid power conversion system name',
      'API.INVALID_MICROGRID_UUID': 'Invalid microgrid UUID',
      'API.INVALID_NAME_VALUE': 'Invalid name value',
      'API.INVALID_NEW_PASSWORD': 'Invalid new password',
      'API.INVALID_NON_WORKING_DAY_ID': 'Invalid non working day ID',
      'API.INVALID_NOTIFICATION_ID': 'Invalid notification id',
      'API.INVALID_NUMERATOR_METER_UUID': 'Invalid numerator meter uuid',
      'API.INVALID_OBJECT_TYPE': 'Invalid object type',
      'API.INVALID_OFFLINE_METER_DATA': 'Invalid offline meter data',
      'API.INVALID_OFFLINE_METER_FILE_ID': 'Invalid offline meter file id',
      'API.INVALID_OFFLINE_METER_ID': 'Invalid offline meter id',
      'API.INVALID_OFFLINE_METER_NAME': 'Invalid offline meter name',
      'API.INVALID_OFFLINE_METER_UUID': 'Invalid offline meter uuid',
      'API.INVALID_OFFSET_CONSTANT_VALUE': 'Invalid offset constant value',
      'API.INVALID_OLD_PASSWORD': 'Invalid old password',
      'API.INVALID_OPENID': 'Invalid openid',
      'API.INVALID_PARENT_SPACE_ID': 'Invalid parent space id',
      'API.INVALID_PASSWORD': 'Invalid password',
      'API.INVALID_PAYLOAD': 'Invalid payload',
      'API.INVALID_PEAK_CURRENT': 'Invalid peak current',
      'API.INVALID_PEAK_LOAD': 'Invalid peak load',
      'API.INVALID_PERIOD_TYPE': 'Invalid period type',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_ID': 'Invalid photovoltaic power station ID',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_NAME': 'Invalid photovoltaic power station name',
      'API.INVALID_POINT_ID': 'Invalid point id',
      'API.INVALID_POINT_NAME': 'Invalid point name',
      'API.INVALID_PORT': 'Invalid port',
      'API.INVALID_POSTAL_CODE_VALUE': 'Invalid postal code value',
      'API.INVALID_POWER_POINT_ID': 'Invalid power point id',
      'API.INVALID_PRIORITY': 'Invalid priority',
      'API.INVALID_PRIVILEGE': 'Invalid privilege',
      'API.INVALID_PRIVILEGE_DATA': 'Invalid privilege data',
      'API.INVALID_PRIVILEGE_ID': 'Invalid privilege id',
      'API.INVALID_PRIVILEGE_NAME': 'Invalid privilege name',
      'API.INVALID_RATIO_VALUE': 'Invalid ratio value',
      'API.INVALID_RECIPIENT_EMAIL': 'Invalid recipient email',
      'API.INVALID_RECIPIENT_MOBILE': 'Invalid recipient mobile',
      'API.INVALID_RECIPIENT_NAME': 'Invalid recipient name',
      'API.INVALID_RECIPIENT_OPENID': 'Invalid recipient openid',
      'API.INVALID_REPLY': 'Invalid reply',
      'API.INVALID_REPORTING_PERIOD_END_DATETIME': 'Invalid reporting period end datetime',
      'API.INVALID_REPORTING_PERIOD_START_DATETIME': 'Invalid reporting period start datetime',
      'API.INVALID_REQUIRES_AUTHENTICATION': 'Invalid requires authentication',
      'API.INVALID_ROOMS_VALUE': 'Invalid rooms value',
      'API.INVALID_RULE_ID': 'Invalid rule id',
      'API.INVALID_RULE_NAME': 'Invalid rule name',
      'API.INVALID_RUN_STATE_POINT_ID': 'Invalid run state point ID',
      'API.INVALID_SCHEDULED_DATETIME': 'Invalid scheduled datetime',
      'API.INVALID_SELL_METER_ID': 'Invalid sell meter id',
      'API.INVALID_SENSOR_ID': 'Invalid sensor id',
      'API.INVALID_SENSOR_NAME': 'Invalid sensor name',
      'API.INVALID_SENSOR_UUID': 'Invalid sensor uuid',
      'API.INVALID_SERIAL_NUMBER': 'Invalid serial number',
      'API.INVALID_SERIAL_PORT': 'Invalid serial port',
      'API.INVALID_SESSION_PLEASE_RE_LOGIN': 'Invalid session please re-login',
      'API.INVALID_SET_VALUE': 'Invalid set value',
      'API.INVALID_SHOPFLOOR_ID': 'Invalid shopfloor id',
      'API.INVALID_SHOPFLOOR_NAME': 'Invalid shopfloor name',
      'API.INVALID_SHOPFLOOR_UUID': 'Invalid shopfloor uuid',
      'API.INVALID_SOURCE_NODE_ID': 'Invalid source node id',
      'API.INVALID_SPACE_ID': 'Invalid space id',
      'API.INVALID_SPACE_NAME': 'Invalid space name',
      'API.INVALID_SPACE_UUID': 'Invalid space uuid',
      'API.INVALID_START_DATE_FORMAT': 'Invalid start date format',
      'API.INVALID_START_DATETIME': 'Invalid start datetime',
      'API.INVALID_STATUS': 'Invalid status',
      'API.INVALID_STORE_ID': 'Invalid store id',
      'API.INVALID_STORE_NAME': 'Invalid store name',
      'API.INVALID_STORE_TYPE_DESCRIPTION': 'Invalid store type description',
      'API.INVALID_STORE_TYPE_ID': 'Invalid store type id',
      'API.INVALID_STORE_TYPE_NAME': 'Invalid store type name',
      'API.INVALID_STORE_TYPE_SIMPLIFIED_CODE': 'Invalid store type simplified code',
      'API.INVALID_STORE_UUID': 'Invalid store uuid',
      'API.INVALID_SUBJECT_VALUE': 'Invalid subject value',
      'API.INVALID_SVG': 'Invalid SVG',
      'API.INVALID_SWITCHGEAR': 'Invalid switchgear',
      'API.INVALID_TARGET_NODE_ID': 'Invalid target node id',
      'API.INVALID_TARIFF_BLOCK_PRICING': 'Invalid tariff block pricing',
      'API.INVALID_TARIFF_ID': 'Invalid tariff id',
      'API.INVALID_TARIFF_TIME_OF_USE_PRICING': 'Invalid tariff time of use pricing',
      'API.INVALID_TARIFF_TYPE': 'Invalid tariff type',
      'API.INVALID_TENANT_ID': 'Invalid tenant id',
      'API.INVALID_TENANT_NAME': 'Invalid tenant name',
      'API.INVALID_TENANT_TYPE_DESCRIPTION': 'Invalid tenant type description',
      'API.INVALID_TENANT_TYPE_ID': 'Invalid tenant type id',
      'API.INVALID_TENANT_TYPE_NAME': 'Invalid tenant type name',
      'API.INVALID_TENANT_TYPE_SIMPLIFIED_CODE': 'Invalid tenant type simplified code',
      'API.INVALID_TENANT_UUID': 'Invalid tenant uuid',
      'API.INVALID_TEXT_MESSAGE_ID': 'Invalid text message id',
      'API.INVALID_TIMEZONE_ID': 'Invalid timezone id',
      'API.INVALID_TOKEN': 'Invalid token',
      'API.INVALID_TOPIC': 'Invalid topic',
      'API.INVALID_UNIT_OF_MEASURE': 'Invalid unit of measure',
      'API.INVALID_UNIT_OF_PRICE': 'Invalid unit of price',
      'API.INVALID_UNITS': 'Invalid units',
      'API.INVALID_USER_ID': 'Invalid user id',
      'API.INVALID_USER_NAME': 'Invalid user name',
      'API.INVALID_USER_NAME_OR_EMAIL': 'Invalid user name or email',
      'API.INVALID_USER_PHONE': 'Invalid user phone',
      'API.INVALID_USER_PLEASE_RE_LOGIN': 'Invalid user please re-login',
      'API.INVALID_USER_UUID': 'Invalid user uuid',
      'API.INVALID_USERNAME': 'Invalid userName',
      'API.INVALID_VARIABLE_METER_ID': 'Invalid variable meter id',
      'API.INVALID_VARIABLE_METER_TYPE': 'Invalid variable meter type',
      'API.INVALID_VARIABLE_NAME': 'Invalid variable name',
      'API.INVALID_VERIFICATION_CODE': 'Invalid verification code',
      'API.INVALID_VIRTUAL_METER_ID': 'Invalid virtual meter id',
      'API.INVALID_VIRTUAL_METER_NAME': 'Invalid virtual meter name',
      'API.INVALID_VIRTUAL_METER_UUID': 'Invalid virtual meter uuid',
      'API.INVALID_WEB_MESSAGE_ID': 'Invalid web message id',
      'API.INVALID_WECHAT_MESSAGE_ID': 'Invalid wechat message id',
      'API.INVALID_WIND_FARM_ID': 'Invalid wind farm ID',
      'API.INVALID_WIND_FARM_NAME': 'Invalid wind farm name',
      'API.INVALID_WORKING_CALENDAR_ID': 'Invalid working calendar id ',
      'API.INVALID_WORKING_CALENDAR_NAME': 'Invalid working calendar name',
      'API.KNOWLEDGE_FILE_CANNOT_BE_REMOVED_FROM_DISK': 'Knowledge File Cannot be Removed from Disk',
      'API.KNOWLEDGE_FILE_NOT_FOUND': 'Knowledge file not found',
      'API.MASTER_METER_DOES_NOT_BELONG_TO_SAME_ENERGY_CATEGORY':
        'Master meter does not belong to same energy category',
      'API.MASTER_METER_NOT_FOUND': 'Master meter not found',
      'API.MENU_NOT_FOUND': 'Menu not found',
      'API.METER_CANNOT_HAVE_MORE_THAN_ONE_ENERGY_VALUE_POINTS': 'Meter cannot have more than one energy value points',
      'API.METER_COMMAND_RELATION_EXISTS': 'Meter command relation exists',
      'API.METER_COMMAND_RELATION_NOT_FOUND': 'Meter command relation not found',
      'API.METER_NAME_IS_ALREADY_IN_USE': 'Meter name is already in use',
      'API.METER_NOT_FOUND': 'Meter not found',
      'API.METER_OF_VARIABLE_NOT_FOUND': 'Meter of variable not found',
      'API.METER_POINT_RELATION_EXISTS': 'Meter point relation exists',
      'API.METER_POINT_RELATION_NOT_FOUND': 'Meter point relation not found',
      'API.MICROGRID_BATTERY_NAME_IS_ALREADY_IN_USE': 'Microgrid battery name is already in use',
      'API.MICROGRID_BATTERY_NOT_FOUND': 'Microgrid battery not found',
      'API.MICROGRID_EVCHARGER_NAME_IS_ALREADY_IN_USE': 'Microgrid evcharger name is already in use',
      'API.MICROGRID_EVCHARGER_NOT_FOUND': 'Microgrid evcharger not fund',
      'API.MICROGRID_GENERATOR_NAME_IS_ALREADY_IN_USE': 'Microgrid generator name is already in use',
      'API.MICROGRID_GENERATOR_NOT_FOUND': 'Microgrid generator not found',
      'API.MICROGRID_GRID_NAME_IS_ALREADY_IN_USE': 'Microgrid grid name is already in use',
      'API.MICROGRID_GRID_NOT_FOUND': 'Microgrid grid not found',
      'API.MICROGRID_HEATPUMP_NAME_IS_ALREADY_IN_USE': 'Microgrid heatpump name is already in use',
      'API.MICROGRID_HEATPUMP_NOT_FOUND': 'Microgrid heatpump not found',
      'API.MICROGRID_LOAD_NAME_IS_ALREADY_IN_USE': 'Microgrid load name is already',
      'API.MICROGRID_LOAD_NOT_FOUND': 'Microgrid load not found',
      'API.MICROGRID_NAME_IS_ALREADY_IN_USE': 'Microgrid name is already in use',
      'API.MICROGRID_NOT_FOUND': 'Microgrid not found',
      'API.MICROGRID_PHOTOVOLTAIC_NAME_IS_ALREADY_IN_USE': 'Microgrid photovoltaic name is already in use',
      'API.MICROGRID_PHOTOVOLTAIC_NOT_FOUND': 'Microgrid photovoltaic not found',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NAME_IS_ALREADY_IN_USE':
        'Microgrid power conversion system name is already in use',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NOT_FOUND': 'Microgrid power conversion system not found',
      'API.MICROGRID_SENSOR_RELATION_EXISTS': 'Microgrid sensor relation exists',
      'API.MICROGRID_SENSOR_RELATION_NOT_FOUND': 'Microgrid sensor not found',
      'API.MQTT_CONNECTION_ERROR': 'Mqtt connection error',
      'API.MQTT_PUBLISH_ERROR': 'Mqtt publish error',
      'API.NEW_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'New password length cannot exceed 100 characters',
      'API.NEW_USER_SESSION_NOT_FOUND': 'New user session not found',
      'API.NEW_USER_SESSION_TIMEOUT': 'New user session timeout',
      'API.NON_WORKING_DAY_NOT_FOUND': 'Non working day non found',
      'API.NOT_FOUND': 'Not found',
      'API.NOTIFICATION_NOT_FOUND': 'Notification not found',
      'API.OFFLINE_METER_FILE_NOT_FOUND': 'Offline meter file not found',
      'API.OFFLINE_METER_NAME_IS_ALREADY_IN_USE': 'Offline meter name is already in use',
      'API.OFFLINE_METER_NOT_FOUND': 'Offline meter not found',
      'API.OFFLINE_METER_OF_VARIABLE_NOT_FOUND': 'Offline meter of variable not found',
      'API.OLD_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Old password length cannot exceed 100 characters',
      'API.PARENT_SPACE_NOT_FOUND': 'Parent space not found',
      'API.PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Password length cannot exceed 100 characters',
      'API.PASSWORDS_MATCH': 'New password is the same as the old password',
      'API.PHOTOVOLTAIC_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Photovoltaic power station name is already in use',
      'API.PHOTOVOLTAIC_POWER_STATION_NOT_FOUND': 'Photovoltaic power station not found',
      'API.POINT_NAME_IS_ALREADY_IN_USE': 'Point name is already in use',
      'API.POINT_NOT_FOUND': 'Point not found',
      'API.POWER_POINT_NOT_FOUND': 'Power point not found',
      'API.PRIVILEGE_NAME_IS_ALREADY_IN_USE': 'Privilege name is already in use',
      'API.PRIVILEGE_NOT_FOUND': 'Privilege not found',
      'API.RULE_NAME_IS_ALREADY_IN_USE': 'Rule name is already in use',
      'API.RULE_NOT_FOUND': 'Rule not found',
      'API.SELL_METER_NOT_FOUND': 'Sell meter not found',
      'API.SENSOR_NAME_IS_ALREADY_IN_USE': 'Sensor name is already in use',
      'API.SENSOR_NOT_FOUND': 'Sensor not found',
      'API.SENSOR_POINT_RELATION_EXISTS': 'Sensor point relation exists',
      'API.SENSOR_POINT_RELATION_NOT_FOUND': 'Sensor point relation not found',
      'API.SHOPFLOOR_COMMAND_RELATION_EXISTS': 'Shopfloor command relation exists',
      'API.SHOPFLOOR_COMMAND_RELATION_NOT_FOUND': 'Shopfloor command relation not found',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_EXISTS': 'Shopfloor equipment relation exists',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_NOT_FOUND': 'Shopfloor equipment relation not found',
      'API.SHOPFLOOR_METER_RELATION_EXISTS': 'Shopfloor meter relation exists',
      'API.SHOPFLOOR_METER_RELATION_NOT_FOUND': 'Shopfloor meter relation not found',
      'API.SHOPFLOOR_NAME_IS_ALREADY_IN_USE': 'Shopfloor name is already in use',
      'API.SHOPFLOOR_NOT_FOUND': 'Shopfloor not found',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_EXISTS': 'Shopfloor offline meter relation exists',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_NOT_FOUND': 'Shopfloor offline meter relation not found',
      'API.SHOPFLOOR_POINT_RELATION_EXISTS': 'Shopfloor point relation exists',
      'API.SHOPFLOOR_POINT_RELATION_NOT_FOUND': 'Shopfloor point relation not found',
      'API.SHOPFLOOR_SENSOR_RELATION_EXISTS': 'Shopfloor sensor relation exists',
      'API.SHOPFLOOR_SENSOR_RELATION_NOT_FOUND': 'Shopfloor sensor relation not found',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_EXISTS': 'Shopfloor virtual meter relation exists',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_NOT_FOUND': 'Shopfloor virtual meter relation not found',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_EXISTS': 'Shopfloor working calendar relation exists',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Shopfloor working calendar relation not found',
      'API.SOC_POINT_NOT_FOUND': 'SOC point not found',
      'API.SOURCE_NODE_NOT_FOUND': 'Source node not found',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_EXISTS': 'Space combined equipment relation exists',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_NOT_FOUND': 'Space combined equipment relation not found',
      'API.SPACE_COMMAND_RELATION_EXISTS': 'Space command relation exists',
      'API.SPACE_COMMAND_RELATION_NOT_FOUND': 'Space command relation not found',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_EXISTS': 'Space Energy Storage Power Station Relation Exists',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_NOT_FOUND': 'Space Energy Storage Power Station Relation Not Found',
      'API.SPACE_EQUIPMENT_RELATION_EXISTS': 'Space equipment relation exists',
      'API.SPACE_EQUIPMENT_RELATION_NOT_FOUND': 'Space equipment relation not found',
      'API.SPACE_METER_RELATION_EXISTS': 'Space meter relation exists',
      'API.SPACE_METER_RELATION_NOT_FOUND': 'Space meter relation not found',
      'API.SPACE_NAME_IS_ALREADY_IN_USE': 'Space name is already in use',
      'API.SPACE_NOT_FOUND': 'Space not found',
      'API.SPACE_NOT_FOUND_IN_PRIVILEGE': 'Space not found in privilege',
      'API.SPACE_OFFLINE_METER_RELATION_EXISTS': 'Space offline meter relation exists',
      'API.SPACE_OFFLINE_METER_RELATION_NOT_FOUND': 'Space offline meter relation not found',
      'API.SPACE_POINT_RELATION_EXISTS': 'Space point relation exists',
      'API.SPACE_POINT_RELATION_NOT_FOUND': 'Space point relation not found',
      'API.SPACE_SENSOR_RELATION_EXISTS': 'Space sensor relation exists',
      'API.SPACE_SENSOR_RELATION_NOT_FOUND': 'Space sensor relation not found',
      'API.SPACE_SHOPFLOOR_RELATION_EXISTS': 'Space shopfloor relation exists',
      'API.SPACE_SHOPFLOOR_RELATION_NOT_FOUND': 'Space shopfloor relation not found',
      'API.SPACE_STORE_RELATION_EXISTS': 'Space store relation exists',
      'API.SPACE_STORE_RELATION_NOT_FOUND': 'Space store relation not found',
      'API.SPACE_TENANT_RELATION_EXISTS': 'Space tenant relation exists',
      'API.SPACE_TENANT_RELATION_NOT_FOUND': 'Space tenant relation not found',
      'API.SPACE_VIRTUAL_METER_RELATION_EXISTS': 'Space virtual meter relation exists',
      'API.SPACE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Space virtual meter relation not found',
      'API.SPACE_WORKING_CALENDAR_RELATION_EXISTS': 'Space working calendar relation exists',
      'API.SPACE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Space working calendar relation not found',
      'API.START_DATETIME_MUST_BE_EARLIER_THAN_END_DATETIME': 'Start datetime must be early than end datetime',
      'API.STORE_COMMAND_RELATION_EXISTS': 'Store command relation exists',
      'API.STORE_COMMAND_RELATION_NOT_FOUND': 'Store command relation not found',
      'API.STORE_METER_RELATION_EXISTS': 'Store meter relation exists',
      'API.STORE_METER_RELATION_NOT_FOUND': 'Store meter relation not found',
      'API.STORE_NAME_IS_ALREADY_IN_USE': 'Store name is already in use',
      'API.STORE_NOT_FOUND': 'Store not found',
      'API.STORE_OFFLINE_METER_RELATION_EXISTS': 'Store offline meter relation exists',
      'API.STORE_OFFLINE_METER_RELATION_NOT_FOUND': 'Store offline meter relation not found',
      'API.STORE_POINT_RELATION_EXISTS': 'Store point relation exists',
      'API.STORE_POINT_RELATION_NOT_FOUND': 'Store point relation not found',
      'API.STORE_SENSOR_RELATION_EXISTS': 'Store sensor relation exists',
      'API.STORE_SENSOR_RELATION_NOT_FOUND': 'Store sensor relation not found',
      'API.STORE_TYPE_NAME_IS_ALREADY_IN_USE': 'Store type name is already in use',
      'API.STORE_TYPE_NOT_FOUND': 'Store type not found',
      'API.STORE_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Store type simplified code is already in use',
      'API.STORE_TYPE_USED_IN_STORE': 'Store type used in store',
      'API.STORE_VIRTUAL_METER_RELATION_EXISTS': 'Store virtual meter relation exists',
      'API.STORE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Store virtual meter relation not found',
      'API.STORE_WORKING_CALENDAR_RELATION_EXISTS': 'Store working calendar relation exists',
      'API.STORE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Store working calendar relation not found',
      'API.TARGET_NODE_NOT_FOUND': 'Target node not found',
      'API.TARIFF_IN_USE': 'Tariff in use',
      'API.TARIFF_IS_ALREADY_ASSOCIATED_WITH_COST_CENTER': 'Tariff is already associated with cost center',
      'API.TARIFF_IS_NOT_ASSOCIATED_WITH_COST_CENTER': 'Tariff is not associated with cost center',
      'API.TARIFF_NAME_IS_ALREADY_IN_USE': 'Tariff name is already in use',
      'API.TARIFF_NOT_FOUND': 'Tariff not found',
      'API.TENANT_COMMAND_RELATION_EXISTS': 'Tenant command relation exists',
      'API.TENANT_COMMAND_RELATION_NOT_FOUND': 'Tenant command relation  not found',
      'API.TENANT_METER_RELATION_EXISTS': 'Tenant meter relation exists',
      'API.TENANT_METER_RELATION_NOT_FOUND': 'Tenant meter relation not found',
      'API.TENANT_NAME_IS_ALREADY_IN_USE': 'Tenant name is already in use',
      'API.TENANT_NOT_FOUND': 'Tenant not found',
      'API.TENANT_OFFLINE_METER_RELATION_EXISTS': 'Tenant offline meter relation exists',
      'API.TENANT_OFFLINE_METER_RELATION_NOT_FOUND': 'Tenant offline meter relation not found',
      'API.TENANT_POINT_RELATION_EXISTS': 'Tenant point relation exists',
      'API.TENANT_POINT_RELATION_NOT_FOUND': 'Tenant point relation not found',
      'API.TENANT_SENSOR_RELATION_EXISTS': 'Tenant sensor relation exists',
      'API.TENANT_SENSOR_RELATION_NOT_FOUND': 'Tenant sensor relation not found',
      'API.TENANT_TYPE_NAME_IS_ALREADY_IN_USE': 'Tenant type name is already in use',
      'API.TENANT_TYPE_NOT_FOUND': 'Tenant type not found',
      'API.TENANT_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Tenant type simplified code is already in use',
      'API.TENANT_TYPE_USED_IN_TENANT': 'Tenant type used in tenant',
      'API.TENANT_VIRTUAL_METER_RELATION_EXISTS': 'Tenant virtual meter relation exists',
      'API.TENANT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Tenant virtual meter relation not found',
      'API.TENANT_WORKING_CALENDAR_RELATION_EXISTS': 'Tenant working calendar relation exists',
      'API.TENANT_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Tenant working calendar relation not found',
      'API.TEXT_MESSAGE_NOT_FOUND': 'Text message not found',
      'API.THE_REPORTING_PERIOD_MUST_BE_LONGER_THAN_15_MINUTES': 'The reporting period must be longer than 15 minutes',
      'API.THERE_IS_RELATION_WITH_CHILD_METERS': 'There is relation with child meters',
      'API.THERE_IS_RELATION_WITH_CHILDREN_SPACES': 'There is relation with children spaces',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENT_PARAMETERS':
        'There is relation with combined equipment parameters',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENTS': 'There is relation with combined equipments',
      'API.THERE_IS_RELATION_WITH_DATA_SOURCES': 'There is relation with data sources',
      'API.THERE_IS_RELATION_WITH_ENERGY_FLOW_DIAGRAM_LINKS': 'There is relation with energy flow diagram links',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_BATTERIES': 'There Is Relation With Energy Storage Containers Batteries',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_GRIDS': 'There Is Relation With Energy Storage Containers Grids',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_LOADS': 'There Is Relation With Energy Storage Containers Loads',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_POWER_CONVERSION_SYSTEMS': 'There Is Relation With Energy Storage Containers Power Conversion Systems',
      'API.THERE_IS_RELATION_WITH_EQUIPMENT_PARAMETERS': 'There is relation with equipment parameters',
      'API.THERE_IS_RELATION_WITH_EQUIPMENTS': 'There is relation with equipments',
      'API.THERE_IS_RELATION_WITH_METER': 'There is relation with meter',
      'API.THERE_IS_RELATION_WITH_METERS': 'There is relation with meters',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METER': 'There is relation with offline meter',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METERS': 'There is relation with offline meters',
      'API.THERE_IS_RELATION_WITH_OTHER_VIRTUAL_METERS': 'There is relation with other virtual meters',
      'API.THERE_IS_RELATION_WITH_SENSORS': 'There is relation with sensors',
      'API.THERE_IS_RELATION_WITH_SHOPFLOORS': 'There is relation with shopfloors',
      'API.THERE_IS_RELATION_WITH_SPACES': 'There is relation with spaces',
      'API.THERE_IS_RELATION_WITH_STORES': 'There is relation with stores',
      'API.THERE_IS_RELATION_WITH_TENANTS': 'There is relation with tenants',
      'API.THERE_IS_RELATION_WITH_USERS': 'There is relation with users',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_METER': 'There is relation with virtual meter',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_POWER_PLANTS': 'Это связано с виртуальной электростанцией.',
      'API.THIS_DATA_SOURCE_IS_BEING_USED_BY_A_METER': 'This data source is being used by a meter',
      'API.THIS_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This meter is being used by a virtual meter',
      'API.THIS_OFFLINE_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This offline meter is being used by a virtual meter',
      'API.THIS_SPACE_CANNOT_BE_CLONED': 'This space cannot be cloned',
      'API.THIS_SPACE_CANNOT_BE_DELETED': 'This space cannot be deleted',
      'API.TIMEZONE_NOT_FOUND': 'Timezone not found',
      'API.TOKEN_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'Token not found in headers please login',
      'API.USER_ACCOUNT_HAS_BEEN_LOCKED': 'User Account Has Been Locked',
      'API.USER_ACCOUNT_HAS_EXPIRED': 'User Account Has Expired',
      'API.USER_ACCOUNT_IS_NOT_LOCKED': 'User account is not locked',
      'API.USER_NAME_IS_ALREADY_IN_USE': 'User name is already in use',
      'API.USER_NOT_FOUND': 'User not found',
      'API.USER_PASSWORD_HAS_EXPIRED': 'User Password Has Expired',
      'API.USER_PRIVILEGE_NOT_FOUND': 'User privilege not found',
      'API.USER_SESSION_NOT_FOUND': 'User session not found',
      'API.USER_SESSION_TIMEOUT': 'User session timeout',
      'API.USER_UUID_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'User uuid not found in headers please login',
      'API.VIRTUAL_METER_NAME_IS_ALREADY_IN_USE': 'Virtual meter name is already in use',
      'API.VIRTUAL_METER_NOT_FOUND': 'Virtual meter not found',
      'API.VIRTUAL_METER_OF_VARIABLE_NOT_FOUND': 'Virtual meter of variable not found',
      'API.VIRTUAL_POINT_SHOULD_BE_ANALOG_VALUE': 'Virtual point should be analog value',
      'API.WEB_MESSAGE_NOT_FOUND': 'Web message not found',
      'API.WECHAT_MESSAGE_NOT_FOUND': 'Wechat message not found',
      'API.WIND_FARM_NAME_IS_ALREADY_IN_USE': 'Wind farm name is already in use',
      'API.WIND_FARM_NOT_FOUND': 'Wind farm not found',
      'API.WORKING_CALENDAR_NAME_IS_ALREADY_IN_USE': 'Working calendar name is already in use',
      'API.WORKING_CALENDAR_NOT_FOUND': 'Working calendar not found',

      MasterMeter: 'Резюме таблиці',
      SubMeter: 'Підтаблиця'
    }
  },
  ar: {
    translation: {
      // routes & menus
      Dashboard: 'لوحه القياده',
      'Space Data': 'فضاء',
      'Equipment Data': 'معدات',
      'Meter Data': 'متر',
      'Tenant Data': 'مستاجر',
      'Store Data': 'دكان',
      'Shopfloor Data': 'شوب فلور',
      'Combined Equipment Data': 'المعدات المشتركة',
      'Auxiliary System': 'النظام المساعد',
      Microgrid: 'الشبكة المصغرة',
      'Energy Storage Power Station': 'محطة تخزين الطاقة',
      'Photovoltaic Power Station': 'Power Station',
      'Fault Alarm': 'إنذار الأعطال',
      Monitoring: 'رصد',
      'Advanced Reporting': 'التقارير المتقدمة',
      'Knowledge Base': 'معارف',
      'Energy Category Data': 'فئة الطاقة',
      'Energy Item Data': 'عنصر الطاقة',
      Carbon: 'انبعاثات ثاني أكسيد الكربون',
      Cost: 'كلف',
      Output: 'الناتج',
      Income: 'دخل',
      Efficiency: 'كفاءة',
      Load: 'حمل',
      Statistics: 'الاحصاءات',
      'Batch Analysis': 'تحليل الدفعات',
      Saving: 'حفظ',
      Plan: 'يخطط',
      'Production': 'Production',
      'Space Production': 'Space Production',
      'Equipment Tracking': 'تتبع المعدات',
      'Environment Monitor': 'مراقب البيئة',
      'Meter Energy': 'طاقة المتر',
      'Meter Carbon': 'متر انبعاثات ثاني أكسيد الكربون',
      'Meter Cost': 'تكلفة المتر',
      'Meter Trend': 'متر الاتجاه',
      'Meter Realtime': 'متر الوقت الحقيقي',
      'Meter Saving': 'توفير العداد',
      'Meter Plan': 'خطة العداد',
      'Master Meter Submeters Balance': 'ماستر ميتر عدادات فرعية الرصيد',
      'Meter Batch Analysis': 'تحليل دفعة العدادات',
      'Meter Comparison': 'مقارنة العدادات',
      'Meter Tracking': 'تتبع العداد',
      'Virtual Meter Saving': 'توفير العداد الافتراضي',
      'Virtual Meter Plan': 'خطة العدادات الافتراضية',
      'Virtual Meter Energy': 'طاقة العداد الافتراضي',
      'Virtual Meter Carbon': 'صك الظاهري انبعاثات ثاني أكسيد الكربون',
      'Virtual Meter Cost': 'تكلفة العداد الافتراضي',
      'Virtual Meter Batch Analysis': 'تحليل دفعة العدادات الافتراضية',
      'Offline Meter Energy': 'طاقة العداد غير المتصل بالإنترنت',
      'Offline Meter Carbon': 'انبعاثات ثاني أكسيد الكربون من خط الصك',
      'Offline Meter Cost': 'تكلفة العداد غير متصل',
      'Offline Meter Batch Analysis': 'تحليل دفعة متر غير متصل',
      'Offline Meter Saving': 'توفير عداد دون اتصال',
      'Offline Meter Plan': 'خطة متر دون اتصال',
      'Offline Meter Input': 'إدخال عداد غير متصل',
      'Tenant Bill': 'فاتورة المستأجر',
      'Energy Flow Diagram': 'مخطط تدفق الطاقة',
      'Distribution System': 'نظام التوزيع',
      'SVG System': 'نظام التصور SCADA',
      'Faults Data': 'اخطاء',
      'Space Equipments': 'معدات الفضاء',
      'Combined Equipments': 'المعدات المشتركة',
      'Energy Storage Power Station List': 'List',
      'Energy Storage Power Station Details': 'Details',
      'Energy Storage Power Station Reporting': 'Reporting',
      'Energy Storage Power Station Alarm': 'Alarm',
      'Energy Storage Power Station Maintenance': 'Maintenance',
      'Enter Production': 'Enter Production',
      'Multiple Energy Storage Power Stations': 'Multiple Power Stations',
      'Multiple Energy Storage Power Stations Dashboard': 'Dashboard',
      'Multiple Energy Storage Power Stations List': 'List',
      'Single Energy Storage Power Station': 'Single Power Station',
      'Single Energy Storage Power Station Dashboard': 'Dashboard',
      'Single Energy Storage Power Station Details': 'Details',
      'Single Energy Storage Power Station Fault Alarm': 'Fault Alarm',
      'Energy Storage Power Station Reports': 'Power Station Reports',
      'Energy Storage Power Station Reports Energy': 'Energy',
      'Energy Storage Power Station Reports Revenue': 'Revenue',
      'Energy Storage Power Station Reports Parameters': 'Parameters',
      'Photovoltaic Power Station List': 'Power Station List',
      'Photovoltaic Power Station Details': 'Details',
      'Photovoltaic Power Station Reporting': 'Reporting',
      'Photovoltaic Power Station Alarm': 'Alarm',
      'Photovoltaic Power Station Maintenance': 'Maintenance',
      'Multiple Photovoltaic Power Stations': 'Multiple Power Stations',
      'Multiple Photovoltaic Power Stations Dashboard': 'Dashboard',
      'Multiple Photovoltaic Power Stations List': 'List',
      'Single Photovoltaic Power Station': 'Single Power Station',
      'Single Photovoltaic Power Station Dashboard': 'Dashboard',
      'Single Photovoltaic Power Station Details': 'Details',
      'Single Photovoltaic Power Station Fault Alarm': 'Fault Alarm',
      'Photovoltaic Power Station Reports': 'Power Station Reports',
      'Photovoltaic Power Station Reports Energy': 'Energy',
      'Photovoltaic Power Station Reports Revenue': 'Revenue',
      'Photovoltaic Power Station Reports Parameters': 'Parameters',
      'Work Order': 'Work Order',
      'Work Order Installation': 'Installation',
      'Work Order Repair': ' Repair',
      'Work Order Inspection': 'Inspection',
      'Total Number of Work Orders': 'إجمالي عدد أوامر العمل',
      'Number of New Work Orders': 'عدد أوامر العمل الجديدة',
      'Number of Inprogress Work Orders': 'عدد أوامر العمل قيد التنفيذ',
      'Number of Done Work Orders': 'عدد أوامر العمل المنجزة',
      'Charge Energy Indicator': 'طاقة الشحن',
      'Discharge Energy Indicator': 'طاقة التفريغ',
      'Charge Cost Indicator': 'تكلفة الشحن',
      'Discharge Revenue Indicator': 'إيرادات التفريغ',
      'HVAC': 'HVAC',
      'Fire Control': 'مكافحة الحرائق',

      // Dashboard
      'Welcome to DoraEOS': 'مرحبا بكم في DoraEOS',
      'An Industry Leading Open Source Energy Management System': 'نظام إدارة طاقة مفتوح المصدر رائد في الصناعة',
      "This Year's Consumption CATEGORY VALUE UNIT": 'استهلاك هذا العام {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Year's Costs CATEGORY VALUE UNIT": 'تكاليف هذا العام {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Consumption CATEGORY VALUE UNIT": 'استهلاك هذا الشهر {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Costs CATEGORY VALUE UNIT": 'تكاليف هذا الشهر {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Consumption CATEGORY VALUE UNIT in The Same Period Last Year':
        'استهلاك {{CATEGORY}} {{VALUE}} {{UNIT}} في نفس الفترة من العام الماضي',
      'Costs CATEGORY VALUE UNIT in The Same Period Last Year':
        'تكاليف {{CATEGORY}} {{VALUE}} {{UNIT}} في نفس الفترة من العام الماضي',
      'CATEGORY Consumption UNIT': '{{CATEGORY}} استهلاك {{UNIT}}',
      'CATEGORY Costs UNIT': '{{CATEGORY}} تكاليف {{UNIT}}',
      // Entities
      Space: 'فضاء',
      Equipment: 'معدات',
      Meter: 'متر',
      'Offline Meter': 'عداد غير متصل',
      'Virtual Meter': 'العداد الافتراضي',
      Tenant: 'مستاجر',
      Store: 'دكان',
      Shopfloor: 'شوب فلور',
      'Combined Equipment': 'المعدات المشتركة',
      'Cost Center': 'مركز التكلفة',
      Name: 'اسم',
      DisplayName: 'اسم العرض',
      UserName: 'اسم المستخدم',
      Description: 'وصف',
      'Energy Category': 'فئة الطاقة',
      'Child Spaces': 'مساحات الأطفال',
      'Associated Equipment': 'المعدات المرتبطة',
      //Energy Categories
      Electricity: 'كهرباء',
      Water: 'الماء',
      'Natural Gas': 'الغاز الطبيعي',
      // SidePanelModal
      Settings: 'اعدادات',
      'Set your own customized style': 'حدد أسلوبك المخصص',
      'Color Scheme': 'نظام الألوان',
      'Choose the perfect color mode for your app': 'اختر وضع الألوان المثالي لتطبيقك',
      Light: 'ضوء',
      Dark: 'داكن',
      'RTL Mode': 'وضع RTL',
      'Switch your language direction': 'تبديل اتجاه اللغة',
      'Fluid Layout': 'تخطيط السوائل',
      'Toggle container layout system': 'تبديل نظام تخطيط الحاوية',
      'Navigation Position': 'موقف الملاحة',
      'Select a suitable navigation system for your web application': 'حدد نظام ملاحة مناسب لتطبيق الويب الخاص بك',
      'Vertical Nav': 'التنقل العمودي',
      'Top Nav': 'توب ناف',
      'Vertical Navbar Style': 'نمط شريط التنقل العمودي',
      'Switch between styles for your vertical navbar': 'التبديل بين الأنماط لشريط التنقل العمودي',
      Language: 'اللغة',
      'Switch between languages': 'التبديل بين اللغات',
      'language-zh_CN': '简体中文',
      'language-en': 'English',
      'language-de': 'Deutsch',
      'language-fr': 'Français',
      'language-es': 'Español',
      'language-ru': 'Русский',
      'language-ar': 'العربية',
      'language-vi': 'Tiếng Việt',
      'language-th': 'ภาษาไทย',
      'language-tr': 'Türkçe',
      'language-ms': 'Bahasa Melayu',
      'language-id': 'Bahasa Indonesia',
      'language-zh_TW': '繁體中文',
      'Like What You See?': 'أعجبك ما تراه?',
      'Get DoraEOS now': 'احصل على DoraEOS الآن',
      Purchase: 'ابتاع',
      // Query Panel
      'Base Period Begins': 'تبدأ فترة الأساس',
      'Base Period Ends': 'تنتهي فترة الأساس',
      'Comparison Types': 'أنواع المقارنة',
      'Year-Over-Year': 'على أساس سنوي',
      'Month-On-Month': 'على أساس شهري',
      'Free Comparison': 'مقارنة مجانية',
      'None Comparison': 'لا شيء مقارنة',
      'Reporting Period Begins': 'بدء الفترة المشمولة بالتقرير',
      'Reporting Period Ends': 'انتهاء الفترة المشمولة بالتقرير',
      '(Optional)': '(اختياري)',
      'Period Types': 'أنواع الدورة الشهرية',
      Yearly: 'السنويه',
      Monthly: 'شهري',
      Weekly: 'أسبوعي',
      Daily: 'يومي',
      Hourly: 'الساعه',
      Submit: 'إرسال',
      'Input Energy Category': 'فئة طاقة المدخلات',
      'Output Energy Category': 'فئة الطاقة الناتجة',
      'Fraction Parameter': 'معلمة الكسر',
      Search: 'بحث',
      //DateRangePicker
      sunday: 'Su',
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      ok: 'موافق',
      today: 'اليوم',
      yesterday: 'أمس',
      hours: 'الساعات',
      minutes: 'محضر',
      seconds: 'الثواني',
      last7Days: 'آخر 7 أيام',
      'Select Date Range': 'حدد النطاق الزمني',
      //Card Summaries and Line Charts
      'Base Period': 'فترة الأساس',
      'Reporting Period': 'الفترة المشمولة بالتقرير',
      'Per Unit Area': 'لكل وحدة مساحة',
      'Per Capita': 'Per Capita',
      'Per Unit Production': 'لكل وحدة إنتاج',
      Baseline: 'الاساس',
      Actual: 'حقيقي',
      'Baseline Value - Actual Value': 'خط الأساس - الفعلي',
      'Average Load': 'متوسط الحمولة',
      'Maximum Load': 'الحمولة القصوى',
      'Load Factor': 'عامل الحمولة',
      'Ratio of Average Load to Maximum Load': 'نسبة متوسط الحمولة إلى الحمولة القصوى',
      'Carbon Dioxide Emissions by Energy Category': 'انبعاثات ثاني أكسيد الكربون حسب فئة الطاقة',
      'Costs by Energy Category': 'التكاليف حسب فئة الطاقة',
      'Incomes by Energy Category': 'الدخل حسب فئة الطاقة',
      'Electricity Consumption by Time-Of-Use': 'استهلاك الكهرباء حسب وقت الاستخدام',
      'Electricity Carbon Dioxide Emissions by Time-Of-Use': 'انبعاثات ثاني أكسيد الكربون الكهربائية حسب وقت الاستخدام',
      'Electricity Cost by Time-Of-Use': 'تكلفة الكهرباء حسب وقت الاستخدام',
      'CATEGORY UNIT Consumption by Energy Items': '{{CATEGORY}} {{UNIT}} الاستهلاك حسب عناصر الطاقة',
      'Ton of Standard Coal by Energy Category': 'طن من الفحم القياسي (TCE) حسب فئة الطاقة',
      'Ton of Carbon Dioxide Emissions by Energy Category': 'طن من انبعاثات ثاني أكسيد الكربون (TCO2E) حسب فئة الطاقة',
      'Reporting Period Consumption CATEGORY UNIT': 'استهلاك الفترة المشمولة بالتقرير {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{EQUIPMENT}} استهلاك الفترة المشمولة بالتقرير {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} استهلاك الفترة المشمولة بالتقرير {{CATEGORY}} {{UNIT}}',
      'CATEGORY VALUE UNIT': '{{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption CATEGORY VALUE UNIT':
        'استهلاك الفترة المشمولة بالتقرير {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Production PRODUCT VALUE UNIT': 'Reporting Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Production PRODUCT VALUE UNIT': 'Base Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption CATEGORY VALUE UNIT': 'استهلاك فترة الأساس {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY UNIT':
        'استهلاك الفترة المشمولة بالتقرير {{ITEM}} {{CATEGORY}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY VALUE UNIT':
        'استهلاك الفترة المشمولة بالتقرير {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption ITEM CATEGORY VALUE UNIT':
        'استهلاك فترة الأساس {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Child Space Proportion CATEGORY UNIT': 'نسبة مساحة الطفل حسب فئة الطاقة {{CATEGORY}} {{UNIT}}',
      'Child Space Total Proportion': 'النسبة الإجمالية لمساحة الطفل',
      'Reporting Period Carbon Dioxide Emissions CATEGORY UNIT':
        'انبعاثات ثاني أكسيد الكربون في الفترة المشمولة بالتقرير {{CATEGORY}} {{UNIT}}',
      'Reporting Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'انبعاثات ثاني أكسيد الكربون في الفترة المشمولة بالتقرير {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'انبعاثات ثاني أكسيد الكربون في فترة الأساس {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Costs CATEGORY UNIT': 'تكاليف الفترة المشمولة بالتقرير {{CATEGORY}} {{UNIT}}',
      'Reporting Period Costs CATEGORY VALUE UNIT': 'تكاليف الفترة المشمولة بالتقرير {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Costs CATEGORY VALUE UNIT': 'تكاليف فترة الأساس {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Output CATEGORY UNIT': 'ناتج فترة التقرير {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Output CATEGORY UNIT': '{{EQUIPMENT}} ناتج فترة التقرير {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} ناتج فترة التقرير {{CATEGORY}} {{UNIT}}',
      'Reporting Period Output CATEGORY VALUE UNIT': 'ناتج فترة التقرير {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Output CATEGORY VALUE UNIT': 'ناتج فترة الأساس {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Income CATEGORY UNIT': 'إيرادات الفترة المشمولة بالتقرير {{CATEGORY}} {{UNIT}}',
      'Reporting Period Income CATEGORY VALUE UNIT': 'إيرادات الفترة المشمولة بالتقرير {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Income CATEGORY VALUE UNIT': 'إيرادات فترة الأساس {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Total Income UNIT': 'الفترة المشمولة بالتقرير إجمالي الدخل {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME UNIT':
        'الكفاءة التراكمية للفترة المشمولة بالتقرير {{NAME}} {{UNIT}}',
      'EQUIPMENT Reporting Period Cumulative Efficiency UNIT':
        '{{EQUIPMENT}} الكفاءة التراكمية للفترة المشمولة بالتقرير {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} الكفاءة الشاملة التراكمية للفترة المشمولة بالتقرير {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} الكفاءة الشاملة التراكمية للفترة المشمولة بالتقرير {{VALUE}} {{UNIT}}',
      'COMBINED_EQUIPMENT Base Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} فترة الأساس الكفاءة الشاملة التراكمية {{VALUE}} {{UNIT}}',
      'Instantaneous Efficiency UNIT': 'كفاءة فورية {{UNIT}}',
      'EQUIPMENT Instantaneous Efficiency UNIT': '{{EQUIPMENT}} كفاءة فورية {{UNIT}}',
      'COMBINED_EQUIPMENT Instantaneous Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} كفاءة شاملة فورية {{UNIT}}',
      'Reporting Period Cumulative Efficiency VALUE UNIT':
        'الكفاءة التراكمية للفترة المشمولة بالتقرير {{VALUE}} {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME VALUE UNIT':
        'الكفاءة التراكمية للفترة المشمولة بالتقرير {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency VALUE UNIT': 'الكفاءة التراكمية لفترة الأساس {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency NAME VALUE UNIT': 'الكفاءة التراكمية لفترة الأساس {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period CATEGORY Maximum Load UNIT': 'فترة الأساس {{CATEGORY}} الحمولة القصوى {{UNIT}}',
      'Reporting Period CATEGORY Maximum Load UNIT': 'الفترة المشمولة بالتقرير {{CATEGORY}} الحمولة القصوى {{UNIT}}',
      'Reporting Period CATEGORY Average Load UNIT': 'الفترة المشمولة بالتقرير {{CATEGORY}} متوسط الحمولة {{UNIT}}',
      'Reporting Period CATEGORY Load Factor': 'الفترة المشمولة بالتقرير {{CATEGORY}} عامل الحمولة',
      'Base Period CATEGORY Average Load VALUE UNIT': 'Base Period {{CATEGORY}} متوسط الحمولة {{VALUE}} {{UNIT}}',
      'Reporting Period CATEGORY Average UNIT': 'الفترة المشمولة بالتقرير {{CATEGORY}} متوسط القيمة {{UNIT}}',
      'Reporting Period CATEGORY Maximum UNIT': 'الفترة المشمولة بالتقرير {{CATEGORY}} القيمة القصوى {{UNIT}}',
      'Reporting Period CATEGORY Minimum UNIT': 'الفترة المشمولة بالتقرير {{CATEGORY}} الحد الأدنى للقيمة {{UNIT}}',
      'Reporting Period CATEGORY Mean UNIT': 'الفترة المشمولة بالتقرير {{CATEGORY}} المتوسط الحسابي {{UNIT}}',
      'Reporting Period CATEGORY Median UNIT':
        'الفترة المشمولة بالتقرير {{CATEGORY}} الوسيط (القيمة المتوسطة) {{UNIT}}',
      'Reporting Period CATEGORY Stdev UNIT': 'الفترة المشمولة بالتقرير {{CATEGORY}} عينة الانحراف المعياري {{UNIT}}',
      'Reporting Period CATEGORY Variance UNIT': 'الفترة المشمولة بالتقرير {{CATEGORY}} تباين العينة {{UNIT}}',
      'Reporting Period Saving CATEGORY (Baseline - Actual) UNIT':
        'توفير الفترة المشمولة بالتقرير {{CATEGORY}} (خط الأساس - الفعلي) {{UNIT}}',
      'Reporting Period Decreased CATEGORY (Baseline - Actual) UNIT':
        'انخفضت الفترة المشمولة بالتقرير {{CATEGORY}} (خط الأساس - الفعلي) {{UNIT}}',
      'Reporting Period Saving CATEGORY VALUE UNIT': 'توفير الفترة المشمولة بالتقرير {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Saving CATEGORY VALUE UNIT': 'توفير فترة الأساس {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY UNIT':
        'الفترة المشمولة بالتقرير استهلاك العداد الرئيسي {{CATEGORY}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY VALUE UNIT':
        'الفترة المشمولة بالتقرير استهلاك العداد الرئيسي {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY UNIT':
        'استهلاك العدادات الفرعية للفترة المشمولة بالتقرير {{CATEGORY}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY VALUE UNIT':
        'استهلاك العدادات الفرعية للفترة المشمولة بالتقرير {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Difference CATEGORY UNIT': 'فرق الفترة المشمولة بالتقرير {{CATEGORY}} {{UNIT}}',
      'Reporting Period Percentage Difference': 'فرق النسبة المئوية للفترة المشمولة بالتقرير',
      'Reporting Period Difference CATEGORY VALUE UNIT': 'فرق الفترة المشمولة بالتقرير {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Trend Values': 'قيم الاتجاه',
      'METER CATEGORY VALUE UNIT': '{{METER}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      //FDD
      'Fault List': 'قائمة الأعطال',
      'Reporting Period Number of Fault VALUE': 'الفترة المشمولة بالتقرير عدد الأعطال {{VALUE}}',
      'All Faults': 'جميع الأخطاء',
      'Space Faults': 'أخطاء الفضاء',
      'Number of All Faults': 'عدد جميع الأخطاء',
      'Number of Space Faults': 'عدد أخطاء الفضاء',
      'Total Number of Faults': 'Total Number of Faults',
      'Number of New Faults': 'Number of New Faults',
      'Number of Inprogress Faults': 'Number of Inprogress Faults',
      'Number of Done Faults': 'Number of Done Faults',
      //Data Panels
      'Ton of Standard Coal': 'طن من الفحم القياسي',
      'Ton of Carbon Dioxide Emissions': 'طن من انبعاثات ثاني أكسيد الكربون',
      'Operating Characteristic Curve': 'عمل منحنى المميزة',
      Tariff: 'تعريف',
      'Detailed Data': 'بيانات مفصلة',
      'Child Spaces Data': 'بيانات المساحات الفرعية',
      'Associated Equipment Data': 'بيانات المعدات المرتبطة',
      'This Year': 'هذا العام',
      'The Same Period Last Year': 'نفس الفترة من العام الماضي',
      'This Month': 'هذا الشهر',
      'This Day': 'هذا اليوم',
      Total: 'مجموع',
      'No data found': 'لم يتم العثور على بيانات',
      Export: 'تصدير',
      Datetime: 'التاريخ والوقت',
      'Time-Of-Use Type': 'نوع وقت الاستخدام',
      'Top-Peak': 'توب بيك',
      'On-Peak': 'في أوقات الذروة',
      'Mid-Peak': 'منتصف الذروة',
      'Off-Peak': 'خارج أوقات الذروة',
      Percentage: 'النسبه المئويه',
      //Realtime Chart
      'Trend in the last hour of Energy Value Point': 'الاتجاه في الساعة الأخيرة من نقطة قيمة الطاقة',
      Circuit: 'الدائره',
      Point: 'نقطة',
      'Realtime Value': 'القيمة في الوقت الحقيقي',
      //Meter Tracking
      'Meter List': 'قائمة العدادات',
      'Edit Meter': 'حرر',
      'Start Value': 'قيمة البداية',
      'End Value': 'القيمة النهائية',
      'Difference Value': 'قيمة الفرق',
      'Start Integrity Rate': 'معدل النزاهة المبدئي(%)',
      'End Integrity Rate': 'معدل النزاهة النهائي(%)',
      'Full Integrity Rate': 'معدل النزاهة الكامل(%)',
      //Equipment Tracking
      'Equipment List': 'قائمة المعدات',
      'Edit Equipment': 'حرر',
      //Profile Dropdown
      Feedback: 'ردود الفعل',
      'Account Settings': 'إعدادات الحساب',
      Logout: 'الخروج',
      //Authentication
      'Log in': 'تسجيل الدخول',
      'Email address': 'عنوان البريد الإلكتروني',
      Password: 'شعار',
      CaptchaCode: 'كلمة التحقق',
      Refresh: 'تحديث',
      'Remember me': 'تذكرني',
      'Logged in as ': 'تم تسجيل الدخول باسم ',
      'Forgot Password?': 'نسيت كلمة المرور?',
      'Forgot your password?': 'نسيت كلمة المرور?',
      "Enter your email and we'll send you a reset link": 'أدخل بريدك الإلكتروني وسنرسل لك رابط إعادة التعيين',
      "Enter your email and we'll send you a registration link": 'أدخل بريدك الإلكتروني وسنرسل لك رابط التسجيل',
      'Reset Password': 'إعادة تعيين كلمة المرور',
      'Thanks for using DoraEOS!': 'شكرا لاستخدام DoraEOS!',
      'You are now successfully signed out': 'لقد قمت الآن بتسجيل الخروج بنجاح',
      'Return to Login': 'العودة إلى تسجيل الدخول',
      'Please check your email!': 'يرجى التحقق من بريدك الإلكتروني!',
      'An email has been sent to ': 'تم إرسال بريد إلكتروني إلى ',
      'Please click on the included link to reset your password':
        'يرجى النقر على الرابط المرفق لإعادة تعيين كلمة المرور الخاصة بك',
      'Please click on the included link to register your account': 'يرجى النقر على الرابط المرفق لتسجيل حسابك',
      'An email with password reset link is sent to ':
        'يتم إرسال بريد إلكتروني يحتوي على رابط إعادة تعيين كلمة المرور إلى ',
      'Change Password': 'تغيير كلمة المرور',
      'Old Password': 'كلمة المرور القديمة',
      'New Password': 'كلمة مرور جديدة',
      'Confirm Password': 'تأكيد كلمة المرور',
      'Update Password': 'تحديث كلمة المرور',
      'Password has been changed!': 'تم تغيير كلمة المرور!',
      'Verification code has been sent to your inbox. \
      Please copy it to the input box below..':
        'تم إرسال رمز التحقق إلى صندوق الوارد الخاص بك. \
      يرجى نسخه إلى مربع الإدخال أدناه.',
      'New to DoraEOS': 'جديد في DoraEOS',
      'Create an account': 'إنشاء حساب',
      'EMAIL Account registration successful': '{{EMAIL}} تم تسجيل الحساب بنجاح',
      'Thanks for verifying your account!': 'شكرا للتحقق من حسابك!',
      'Your code is': 'الرمز الخاص بك هو',
      'Send verification code': 'إرسال رمز التحقق',
      'Please wait for NUMBER seconds': 'يرجى الانتظار {{NUMBER}} الثواني',
      'Password reset': 'إعادة تعيين كلمة المرور',
      'Please wait for approval': 'يرجى انتظار الموافقة',
      //notification
      Notifications: 'الاخطارات',
      'Mark all as read': 'وضع علامة مقروءة على الكل',
      'View all': 'كل',
      'Notification New': 'الجديد',
      'Notification Earlier': 'أسبق',
      'Notification List': 'قائمة الإشعارات',
      'Bulk actions': 'الإجراءات المجمعة',
      'Notification Subject': 'موضوع',
      'Notification Created Datetime': 'تاريخ الإنشاء',
      'Notification Priority': 'أولوية',
      'Notification Message': 'رسالة',
      'Notification Status': 'حالة',
      'Notification Mark As Read': 'وضع علامة كمقروءة',
      'Notification Mark As Acknowledged': 'اعترف',
      'Notification Low': 'منخفض',
      'Notification Medium': 'Medium',
      'Notification High': 'عال',
      'Notification Critical': 'حرج',
      'Notification Unread': 'غير مقروء',
      'Notification Read': 'قرأ',
      'Notification Acknowledged': 'اعترف',
      'Notification Archive': 'أرشيف',
      'Notification Delete': 'حذف',
      'Notification Apply': 'طبق',
      'Notification Start Datetime': 'بدء التاريخ والوقت',
      'Notification End Datetime': 'تاريخ الانتهاء',
      'Notification Update Datetime': 'تحديث التاريخ والوقت',
      //Monitoring
      'Run Commands': 'تشغيل الأوامر',
      'Fault Alarms': 'إنذارات الأعطال',
      'Instantaneous Efficiency VALUE UNIT': 'كفاءة فورية {{VALUE}} {{UNIT}}',
      'Communication Online': 'عبر الإنترنت',
      'Communication Offline': 'دون اتصال',
      'Equipment Status': 'حالة الجهاز',
      'Equipment Running': 'تشغيل',
      'Equipment Stopped': 'توقف',
      'Show Up to': 'احضر إلى',
      All: 'كل',
      'FROM - TO of TOTAL': '{{FROM}} - {{TO}} من {{TOTAL}} المعدات',
      //Microgrid
      'PCS Run State': 'جهاز الدولة',
      'PCS Unknown': 'غير معروف',
      'PCS Initializing': 'تهيئة',
      'PCS Standby': 'المواد الاحتياطية',
      'PCS Shutdown': 'توقف',
      'PCS Fault': 'خطأ',
      'PCS Running': 'تشغيل .',
      'Battery Power': 'طاقة البطارية',
      'Photovoltaic Power': 'الطاقة الضوئية',
      'Grid Power': 'قوة الشبكة',
      'Load Power': 'تحميل السلطة',
      Reporting: 'تقرير .',
      Maintenance: 'الرقم المتسلسل',
      'Serial Number': 'الرقم المتسلسل',
      Address: 'العنوان',
      'Postal Code': 'الرمز البريدي',
      'Rated Capacity': 'تصنيف القدرات',
      'Rated Power': 'تصنيف السلطة',
      Latitude: 'خط العرض',
      Longitude: 'خط الطول',
      'Strategy Management': 'الإدارة الاستراتيجية',
      'Charging Schedule': 'جدول الشحن',
      'Charge Start Time': 'شحن وقت البدء',
      'Charge End Time': 'شحن نهاية الوقت',
      'Discharge Start Time': 'وقت بدء التفريغ',
      'Discharge End Time': 'أداء نهاية الوقت',
      'Total Rated Power': 'إجمالي الطاقة المقدرة',
      'Total Rated Capacity': 'إجمالي السعة المقدرة',
      "Today's Charge": "مبلغ الشحن اليوم",
      "Today's Discharge": "التفريغ اليوم",
      "Today's Generation": "توليد الطاقة اليوم",
      'Total Charge': 'القيمة الاجمالية',
      'Total Discharge': 'التفريغ الكلي',
      'Total Generation': 'الجيل الإجمالي',
      'Total Revenue': 'إجمالي الإيرادات',
      'Total Efficiency': 'الكفاءة الكلية',
      'Discharge Achievement Rate': 'معدل إنجاز التفريغ',
      // Revenue Indicator
      "Today's Cost": "Today's Cost",
      "Today's Income": "Today's Income",
      "Total Cost": "Total Cost",
      "Total Income": "Total Income",
      "Today's Revenue": "Today's Revenue",

      'Revenue Ranking': 'تصنيف الإيرادات',
      'Efficiency Ranking': 'تصنيف الكفاءة',
      'Charge Ranking': 'تصنيف توليد الطاقة',
      'Discharge Ranking': 'تصنيف التفريغ',
      'Number of Microgrids': 'عدد الشبكات الصغيرة',
      'Number of Power Stations': 'عدد محطات الطاقة',
      'Microgrid List': 'قائمة ميكروغريد',
      'Previous Page': 'الصفحة السابقة',
      'Next Page': 'الصفحة التالية',
      '7 Days': '7 أيام',
      'Charge UNIT': 'تكلفة {{UNIT}}',
      'Discharge UNIT': 'تسريح {{UNIT}}',
      'Generation UNIT': 'توليد الكهرباء {{UNIT}}',
      'Energy Indicator': 'الطاقة',
      'Revenue Indicator': 'دخل',
      'Carbon Indicator': 'كربون',

      // Energy Storage Power Station
      'Battery Operating State': 'حالة البطارية',
      'Battery State': 'حالة البطارية',
      'Battery Unknown': 'غير معروف',
      'Battery Stopped': 'توقف',
      'Battery Reserved': 'Reserved', // 0
      'Battery Fault': 'خطأ', // 1
      'Battery Warning': 'حذر', // 2
      'Battery Standby': 'المواد الاحتياطية', // 3
      'Battery Prohibit DisCharging': 'تعطيل إلغاء', // 4
      'Battery Prohibit Charging': 'حظر شحن', // 5
      'Battery Normal': 'نموذجي', // 6
      'Battery Charging': 'شحن', // 7
      'Battery Discharging': 'في التفريغ', // 8
      'Battery Idle': 'Idle', // 9
      'Phase of Lifecycle': 'مراحل دورة الحياة',
      'Use Phase': 'استخدام المرحلة',
      'Commissioning Phase': 'مرحلة التصحيح',
      'Installation Phase': 'مرحلة التثبيت',
      'Device Status': 'حالة الجهاز',
      'Basic Information': 'المعلومات الأساسية',
      'Gateway': 'Gateway',
      'Grid Meter': 'Grid Meter',
      'Load Meter': 'Load Meter',
      'Total Active Power': 'Total Active Power',
      'Active Power A': 'Active Power A',
      'Active Power B': 'Active Power B',
      'Active Power C': 'Active Power C',
      'Total Reactive Power': 'Total Reactive Power',
      'Reactive Power A': 'Reactive Power A',
      'Reactive Power B': 'Reactive Power B',
      'Reactive Power C': 'Reactive Power C',
      'Total Apparent Power': 'Total Apparent Power',
      'Apparent Power A': 'Apparent Power A',
      'Apparent Power B': 'Apparent Power B',
      'Apparent Power C': 'Apparent Power C',
      'Total Power Factor': 'Total Power Factor',
      'Active Energy Import': 'Active Energy Import',
      'Active Energy Export': 'Active Energy Export',
      'Active Energy Net': 'Active Energy Net',

      // Photovoltaic Power Station
      'Invertor': 'العاكسون',
      'Invertor Run State': 'جهاز الدولة',
      'Invertor Unknown': 'غير معروف',
      'Invertor Initializing': 'تهيئة',
      'Invertor Standby': 'المواد الاحتياطية',
      'Invertor Shutdown': 'توقف',
      'Invertor Fault': 'خطأ',
      'Invertor Running': 'تشغيل .',

      //Advanced Reporting & Knowledge base
      'Created Datetime': 'تاريخ الإنشاء',
      'File Format': 'تنسيق الملف',
      'File Size': 'حجم الملف',
      Uploader: 'رافع',
      //Error
      "The page you're looking for is not found": 'لم يتم العثور على الصفحة التي تبحث عنها',
      "Make sure the address is correct and that the page hasn't moved. ": 'تأكد من صحة العنوان وعدم نقل الصفحة. ',
      'If you think this is a mistake,': 'إذا كنت تعتقد أن هذا خطأ,',
      'contact us': 'اتصل بنا',
      'Take me home': 'خذني إلى المنزل',
      'Whoops, something went wrong!': 'عذرا ، حدث خطأ ما!',
      'Try refreshing the page, or going back and attempting the action again. ':
        'حاول تحديث الصفحة، أو الرجوع ومحاولة اتخاذ الإجراء مرة أخرى. ',
      'If this problem persists,': 'إذا استمرت هذه المشكلة,',
      'Captcha Error': 'خطأ كلمة التحقق',
      'Confirm Password Error': 'تأكيد كلمة المرور غير متطابق',
      'It looks like you clicked on an invalid password reset link. Please tryagain.':
        'يبدو أنك نقرت \
      على رابط إعادة تعيين كلمة المرور غير صالح. يرجى المحاولة مرة أخرى.',
      'It looks like you clicked on an invalid registration account link. Please tryagain.':
        'يبدو أنك نقرت \
      على رابط حساب تسجيل غير صالح. يرجى المحاولة مرة أخرى.',
      //Tenant Bill
      'Lease Contract Number': 'رقم عقد الإيجار',
      Download: 'تحميل',
      Print: 'طبع',
      'Payment Notice': 'إشعار الدفع',
      'Bill To': 'بيل إلى',
      'Bill Number': 'رقم الفاتورة',
      'Bill Date': 'تاريخ الفاتورة',
      'Payment Due Date': 'تاريخ استحقاق الدفع',
      'Amount Payable': 'المبلغ المستحق الدفع',
      'Billing Period Start': 'بدء فترة الفوترة',
      'Billing Period End': 'نهاية فترة الفوترة',
      Quantity: 'كم',
      Price: 'ثمن',
      Unit: 'وحدة',
      Amount: 'مبلغ',
      Subtotal: 'المجموع الفرعي',
      'VAT Output Tax': 'ضريبة مخرجات ضريبة القيمة المضافة',
      'Total Amount Payable': 'إجمالي المبلغ المستحق الدفع',
      'Please make sure to pay on or before the payment due date above':
        'يرجى التأكد من الدفع في أو قبل تاريخ استحقاق الدفع أعلاه',
      'Send money to the following account': 'إرسال الأموال إلى الحساب التالي',
      'Account Name': 'اسم الحساب',
      'Bank Name': 'اسم البنك',
      'Bank Address': 'عنوان البنك',
      'RMB Account': 'حساب الرنمينبي',
      // button
      'Toggle Navigation': 'تبديل التنقل',
      // working calendar
      'Working Days': 'أيام العمل',
      'Non Working Days': 'أيام العطلة',
      'Select Row': 'الرجاء اختيار صف البيانات',
      // Offline Meter Input
      'Daily Value': 'القيمة اليومية',
      Date: 'تاريخ',
      'Successfully Saved': 'تم الحفظ بنجاح',
      'Previous Data Is Empty': 'البيانات السابقة فارغة',
      // API
      'API.ACCOUNT_UNLOCK_FAILED': 'Account unlock failed',
      'API.ADMINISTRATOR_SESSION_NOT_FOUND': 'Administrator session not found',
      'API.ADMINISTRATOR_SESSION_TIMEOUT': 'Administrator session timeout',
      'API.ADVANCED_REPORT_NOT_FOUND': 'Advanced report not found',
      'API.API_KEY_HAS_EXPIRED': 'Key has expired',
      'API.API_KEY_NAME_IS_ALREADY_IN_USE': 'Key name is already in use',
      'API.API_KEY_NOT_FOUND': 'Key not found',
      'API.BAD_REQUEST': 'Bad request',
      'API.BALANCING_PRICE_POINT_NOT_FOUND': 'Balancing price point not found',
      'API.BATTERY_STATE_POINT_NOT_FOUND': 'Battery state point not found',
      'API.BUY_METER_NOT_FOUND': 'Buy meter not found',
      'API.CANNOT_SET_EXISTING_SUBMETER_AS_MASTER_METER': 'Cannot set existing submeter as master meter',
      'API.CHARGE_METER_NOT_FOUND': 'Charge meter not found',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_EXISTS': 'Combined equipment command relation exists',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Combined equipment command relation not found',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_EXISTS': 'Combined equipment equipment relation exists',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_NOT_FOUND': 'Combined equipment equipment relation not found',
      'API.COMBINED_EQUIPMENT_METER_RELATION_EXISTS': 'Combined equipment meter relation exists',
      'API.COMBINED_EQUIPMENT_METER_RELATION_NOT_FOUND': 'Combined equipment meter relation not found',
      'API.COMBINED_EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Combined equipment name is already in use',
      'API.COMBINED_EQUIPMENT_NOT_FOUND': 'Combined equipment not found',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Combined equipment offline meter relation exists',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Combined equipment offline meter relation not found',
      'API.COMBINED_EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Combined equipment parameter name is already in use',
      'API.COMBINED_EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Combined equipment parameter not found or not match',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Combined equipment virtual meter relation exists',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Combined equipment virtual meter relation not found',
      'API.COMMAND_NAME_IS_ALREADY_IN_USE': 'Command name is already in use',
      'API.COMMAND_NOT_FOUND': 'Command not  found',
      'API.CONTACT_NAME_IS_ALREADY_IN_USE': 'Contact name is already in use',
      'API.CONTACT_NOT_FOUND': 'Contact not found',
      'API.COOLING_METER_NOT_FOUND': 'Cooling meter not found',
      'API.COST_CENTER_EXTERNAL_ID_EXISTS': 'Cost center external id exists',
      'API.COST_CENTER_NAME_EXISTS': 'Cost center name exists',
      'API.COST_CENTER_NOT_FOUND': 'Cost center not found',
      'API.COST_FILE_NOT_FOUND': 'Cost file not found',
      'API.DATA_REPAIR_FILE_NOT_FOUND': 'Data repair file not found',
      'API.DATA_SOURCE_NAME_IS_ALREADY_IN_USE': 'Data source name is already in use',
      'API.DATA_SOURCE_NOT_FOUND': 'Data source not found',
      'API.DATABASE_ERROR': 'Database error',
      'API.DATE_IS_ALREADY_IN_WORKING_CALENDAR': 'Date Is Already In Working Calendar',
      'API.DATE_LOCAL_NOT_FOUND': 'Date local not found',
      'API.DISCHARGE_METER_NOT_FOUND': 'Discharge meter not found',
      'API.DISTRIBUTION_CIRCUIT_NAME_IS_ALREADY_IN_USE': 'Distribution circuit name is already in use',
      'API.DISTRIBUTION_CIRCUIT_NOT_FOUND': 'Distribution circuit not found',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_EXISTS': 'Distribution circuit point relation exists',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_NOT_FOUND': 'Distribution circuit point relation not found',
      'API.DISTRIBUTION_SYSTEM_NAME_IS_ALREADY_IN_USE': 'Distribution system name is already in use',
      'API.DISTRIBUTION_SYSTEM_NOT_FOUND': 'Distribution system not found',
      'API.ELECTRICITY_METER_NOT_FOUND': 'Electricity meter not found',
      'API.EMAIL_IS_ALREADY_IN_USE': 'Email is already in use',
      'API.EMAIL_MESSAGE_NOT_FOUND': 'Email message not found',
      'API.EMAIL_NOT_FOUND': 'Email not found',
      'API.EMAIL_SERVER_HOST_IS_ALREADY_IN_USE': 'Email server host is already in use',
      'API.EMAIL_SERVER_NOT_FOUND': 'Email server not found',
      'API.EMPTY_VARIABLES_ARRAY': 'Empty variables array',
      'API.ENERGY_CATEGORY_NAME_IS_ALREADY_IN_USE': 'Energy category name is already in use',
      'API.ENERGY_CATEGORY_NOT_FOUND': 'Energy category not found',
      'API.ENERGY_CATEGORY_USED_IN_ENERGY_ITEMS': 'Energy category used in energy items',
      'API.ENERGY_CATEGORY_USED_IN_METER': 'Energy category used in meter',
      'API.ENERGY_CATEGORY_USED_IN_OFFLINE_METER': 'Energy category used in offline meter',
      'API.ENERGY_CATEGORY_USED_IN_TARIFFS': 'Energy category used in tariffs',
      'API.ENERGY_CATEGORY_USED_IN_VIRTUAL_METER': 'Energy category used in virtual meter',
      'API.ENERGY_FLOW_DIAGRAM_LINK_IS_ALREADY_IN_USE': 'Energy flow diagram link is already in use',
      'API.ENERGY_FLOW_DIAGRAM_LINK_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram link not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram node name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram node not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NOT_FOUND': 'Energy flow diagram not found',
      'API.ENERGY_ITEM_DOES_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item does not belong to energy category',
      'API.ENERGY_ITEM_IS_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item is not belong to energy category',
      'API.ENERGY_ITEM_NAME_IS_ALREADY_IN_USE': 'Energy item name is already in use',
      'API.ENERGY_ITEM_NOT_FOUND': 'Energy item not found',
      'API.ENERGY_ITEM_USED_IN_METER': 'Energy item used in meter',
      'API.ENERGY_ITEM_USED_IN_OFFLINE_METER': 'Energy item used in offline meter',
      'API.ENERGY_ITEM_USED_IN_VIRTUAL_METER': 'Energy item used in virtual meter',
      'API.ENERGY_STORAGE_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Energy storage power station name is already in use',
      'API.ENERGY_STORAGE_POWER_STATION_NOT_FOUND': 'Energy storage power station not found',
      'API.EQUIPMENT_COMMAND_RELATION_EXISTS': 'Equipment command relation exists',
      'API.EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Equipment command relation not found',
      'API.EQUIPMENT_METER_RELATION_EXISTS': 'Equipment meter relation exists',
      'API.EQUIPMENT_METER_RELATION_NOT_FOUND': 'Equipment meter relation not found',
      'API.EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Equipment name is already in use',
      'API.EQUIPMENT_NOT_FOUND': 'Equipment not found',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Equipment offline meter relation exists',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Equipment offline meter relation not found',
      'API.EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Equipment parameter name is already in use',
      'API.EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Equipment parameter not found or not match',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Equipment virtual meter relation exists',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Equipment virtual meter relation not found',
      'API.ERROR': 'Error',
      'API.EXCEPTION': 'Exception',
      'API.FAILED_TO_READ_REQUEST_STREAM': 'Failed to read request stream',
      'API.FAILED_TO_RESTORE_COST_FILE': 'Failed to restore cost file',
      'API.FAILED_TO_RESTORE_DATA_REPAIR_FILE': 'Failed to restore data repair file',
      'API.FAILED_TO_RESTORE_KNOWLEDGE_FILE': 'Failed to restore knowledge file',
      'API.FAILED_TO_RESTORE_OFFLINE_METER_FILE': 'Failed to restore offline meter file',
      'API.FAILED_TO_SAVE_OFFLINE_METER_FILE': 'Failed to save offline meter file',
      'API.FAILED_TO_UPLOAD_ATTACHMENT_FILE': 'Failed to upload attachment file',
      'API.FAILED_TO_UPLOAD_COST_FILE': 'Failed to upload cost file',
      'API.FAILED_TO_UPLOAD_DATA_REPAIR_FILE': 'Failed to upload data repair file',
      'API.FAILED_TO_UPLOAD_KNOWLEDGE_FILE': 'Failed to upload knowledge file',
      'API.FAILED_TO_UPLOAD_OFFLINE_METER_FILE': 'Failed to upload offline meter file',
      'API.GATEWAY_NAME_IS_ALREADY_IN_USE': 'Gateway name is already in use',
      'API.GATEWAY_NOT_FOUND': 'Gateway not found',
      'API.HEAT_METER_NOT_FOUND': 'Heat meter not found',
      'API.INVALID_': 'Invalid ',
      'API.INVALID_ACKNOWLEDGE_CODE': 'Invalid acknowledge code',
      'API.INVALID_ADDRESS': 'Invalid address',
      'API.INVALID_ADDRESS_VALUE': 'Invalid address value',
      'API.INVALID_ADVANCED_REPORT_ID': 'Invalid advanced report id',
      'API.INVALID_API_KEY_ID': 'Invalid api key id',
      'API.INVALID_API_KEY_NAME': 'Invalid api key name',
      'API.INVALID_AREA_VALUE': 'Invalid area value',
      'API.INVALID_BALANCING_PRICE_POINT_ID': 'Invalid balancing price point ID',
      'API.INVALID_BASE_PERIOD_END_DATETIME': 'Invalid base period end datetime',
      'API.INVALID_BASE_PERIOD_START_DATETIME': 'Invalid base period start datetime',
      'API.INVALID_BATTERY_STATE_POINT_ID': 'Invalid battery state point ID',
      'API.INVALID_BAUD_RATE': 'Invalid baud rate',
      'API.INVALID_BUILDINGS_VALUE': 'Invalid buildings value',
      'API.INVALID_BUY_METER_ID': 'Invalid buy meter id',
      'API.INVALID_CAPACITY': 'Invalid capacity',
      'API.INVALID_CATEGORY': 'Invalid category',
      'API.INVALID_CHANNEL': 'Invalid channel',
      'API.INVALID_CHARGE_METER_ID': 'Invalid charge meter id',
      'API.INVALID_COMBINED_EQUIPMENT_ID': 'Invalid combined equipment id',
      'API.INVALID_COMBINED_EQUIPMENT_NAME': 'Invalid combined equipment name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_ID': 'Invalid combined equipment parameter id',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_NAME': 'Invalid combined equipment parameter name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_TYPE': 'Invalid combined equipment parameter type',
      'API.INVALID_COMBINED_EQUIPMENT_UUID': 'Invalid combined equipment uuid',
      'API.INVALID_COMMAND_ID': 'Invalid command id',
      'API.INVALID_COMMAND_NAME': 'Invalid command name',
      'API.INVALID_CONNECTION': 'Invalid connection',
      'API.INVALID_CONSTANT_VALUE': 'Invalid constant value',
      'API.INVALID_CONTACT_ID': 'Invalid contact id',
      'API.INVALID_CONTACT_NAME': 'Invalid contact name',
      'API.INVALID_COOLING_METER_ID': 'Invalid cooling meter id',
      'API.INVALID_COST_CENTER_ID': 'Invalid cost center id',
      'API.INVALID_COST_FILE_ID': 'Invalid cost file id',
      'API.INVALID_CREATED_DATETIME': 'Invalid created datetime',
      'API.INVALID_DATA_REPAIR_FILE_ID': 'Invalid data repair file id',
      'API.INVALID_DATA_SOURCE_ID': 'Invalid data source id',
      'API.INVALID_DATA_SOURCE_NAME': 'Invalid data source name',
      'API.INVALID_DATA_SOURCE_PROTOCOL': 'Invalid data source protocol',
      'API.INVALID_DATE_LOCAL': 'Invalid date local',
      'API.INVALID_DENOMINATOR_METER_UUID': 'Invalid denominator meter uuid',
      'API.INVALID_DISCHARGE_METER_ID': 'Invalid discharge meter id',
      'API.INVALID_DISPLAY_NAME': 'Invalid display name',
      'API.INVALID_DISTRIBUTION_CIRCUIT_ID': 'Invalid distribution circuit id',
      'API.INVALID_DISTRIBUTION_CIRCUIT_NAME': 'Invalid distribution circuit name',
      'API.INVALID_DISTRIBUTION_ROOM': 'Invalid distribution room',
      'API.INVALID_DISTRIBUTION_SYSTEM_ID': 'Invalid distribution system id',
      'API.INVALID_DISTRIBUTION_SYSTEM_NAME': 'Invalid distribution system name',
      'API.INVALID_ELECTRICITY_METER_ID': 'Invalid Electricity meter id',
      'API.INVALID_EMAIL': 'Invalid email',
      'API.INVALID_EMAIL_MESSAGE_ID': 'Invalid email message id',
      'API.INVALID_EMAIL_SERVER_HOST': 'Invalid email server host',
      'API.INVALID_EMAIL_SERVER_ID': 'Invalid email server id',
      'API.INVALID_END_DATE_FORMAT': 'Invalid end date format',
      'API.INVALID_END_DATETIME': 'Invalid end datetime',
      'API.INVALID_ENERGY_CATEGORY_ID': 'Invalid energy category id',
      'API.INVALID_ENERGY_CATEGORY_NAME': 'Invalid energy category name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_ID': 'Invalid energy flow diagram id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_LINK_ID': 'Invalid energy flow diagram link id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NAME': 'Invalid energy flow diagram name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_ID': 'Invalid energy flow diagram node id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_NAME': 'Invalid energy flow diagram node name',
      'API.INVALID_ENERGY_ITEM_ID': 'Invalid energy item id',
      'API.INVALID_ENERGY_ITEM_NAME': 'Invalid energy item name',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_ID': 'Invalid energy storage power station ID',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_NAME': 'Invalid energy storage power station name',
      'API.INVALID_EQUATION_IN_EXPRESSION': 'Invalid equation in expression',
      'API.INVALID_EQUIPMENT_ID': 'Invalid equipment id',
      'API.INVALID_EQUIPMENT_NAME': 'Invalid equipment name',
      'API.INVALID_EQUIPMENT_PARAMETER_ID': 'Invalid equipment parameter id',
      'API.INVALID_EQUIPMENT_PARAMETER_NAME': 'Invalid equipment parameter name',
      'API.INVALID_EQUIPMENT_PARAMETER_TYPE': 'Invalid equipment parameter type',
      'API.INVALID_EQUIPMENT_UUID': 'Invalid equipment uuid',
      'API.INVALID_EXPIRES_DATETIME': 'Invalid expires datetime',
      'API.INVALID_EXPRESSION': 'Invalid expression',
      'API.INVALID_EXPRESSION_OBJECT': 'Invalid expression object',
      'API.INVALID_FDD_CODE': 'Invalid fdd code',
      'API.INVALID_FLOORS_VALUE': 'Invalid floors value',
      'API.INVALID_FROM_ADDR': 'Invalid from addr',
      'API.INVALID_GATEWAY_ID': 'Invalid gateway id',
      'API.INVALID_GATEWAY_NAME': 'Invalid gateway name',
      'API.INVALID_HEAT_METER_ID': 'Invalid heat meter id',
      'API.INVALID_HIGH_LIMIT_VALUE': 'Invalid high limit value',
      'API.INVALID_HOURLY_HIGH_LIMIT_VALUE': 'Invalid hourly high limit value',
      'API.INVALID_HOURLY_LOW_LIMIT_VALUE': 'Invalid hourly low limit value',
      'API.INVALID_Id': 'Invalid id',
      'API.INVALID_IS_ADMIN_VALUE': 'Invalid is admin value',
      'API.INVALID_IS_COST_DATA_DISPLAYED': 'Invalid is cost data displayed',
      'API.INVALID_IS_COUNTED_VALUE': 'Invalid is counted value',
      'API.INVALID_IS_ENABLED': 'Invalid is enabled',
      'API.INVALID_IS_HIDDEN': 'Invalid is hidden',
      'API.INVALID_IS_IN_LEASE_VALUE': 'Invalid is in lease value',
      'API.INVALID_IS_INPUT_COUNTED_VALUE': 'Invalid is input counted value',
      'API.INVALID_IS_KEY_TENANT_VALUE': 'Invalid is key tenant value',
      'API.INVALID_IS_OUTPUT_COUNTED_VALUE': 'Invalid is output counted value',
      'API.INVALID_IS_OUTPUT_VALUE': 'Invalid is output value',
      'API.INVALID_IS_READ_ONLY_VALUE': 'Invalid is read only value',
      'API.INVALID_IS_TREND_VALUE': 'Invalid is trend value',
      'API.INVALID_IS_VIRTUAL_VALUE': 'Invalid is virtual value',
      'API.INVALID_KGCE': 'Invalid kgce',
      'API.INVALID_KGCO2E': 'Invalid kgco2e',
      'API.INVALID_KNOWLEDGE_FILE_ID': 'Invalid knowledge file id',
      'API.INVALID_LATITUDE_VALUE': 'Invalid latitude value',
      'API.INVALID_LEASE_NUMBER_VALUE': 'Invalid lease number value',
      'API.INVALID_LONGITUDE_VALUE': 'Invalid longitude value',
      'API.INVALID_LOW_LIMIT_VALUE': 'Invalid low limit value',
      'API.INVALID_MASTER_METER_ID': 'Invalid master meter id',
      'API.INVALID_MENU_ID': 'Invalid menu id',
      'API.INVALID_MESSAGE_DATA': 'Invalid message data',
      'API.INVALID_MESSAGE_TEMPLATE': 'Invalid message template',
      'API.INVALID_MESSAGE_TEMPLATE_ID': 'Invalid message template id',
      'API.INVALID_MESSAGE_VALUE': 'Invalid message value',
      'API.INVALID_METER_ID': 'Invalid meter id',
      'API.INVALID_METER_NAME': 'Invalid meter name',
      'API.INVALID_METER_UUID': 'Invalid meter uuid',
      'API.INVALID_MICROGRID_BATTERY_ID': 'Invalid microgrid battery id',
      'API.INVALID_MICROGRID_BATTERY_NAME': 'Invalid microgrid battery name',
      'API.INVALID_MICROGRID_EVCHARGER_ID': 'Invalid microgrid evcharger id',
      'API.INVALID_MICROGRID_EVCHARGER_NAME': 'Invalid microgrid evcharger name',
      'API.INVALID_MICROGRID_GENERATOR_ID': 'Invalid microgrid generator id',
      'API.INVALID_MICROGRID_GENERATOR_NAME': 'Invalid microgrid generator name',
      'API.INVALID_MICROGRID_GRID_ID': 'Invalid microgrid grid id',
      'API.INVALID_MICROGRID_GRID_NAME': 'Invalid microgrid grid name',
      'API.INVALID_MICROGRID_HEATPUMP_ID': 'Invalid microgrid heatpump id',
      'API.INVALID_MICROGRID_HEATPUMP_NAME': 'Invalid microgrid heatpump name',
      'API.INVALID_MICROGRID_ID': 'Invalid microgrid id',
      'API.INVALID_MICROGRID_LOAD_ID': 'Invalid microgrid load id',
      'API.INVALID_MICROGRID_LOAD_NAME': 'Invalid microgrid load name',
      'API.INVALID_MICROGRID_NAME': 'Invalid microgrid name',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_ID': 'Invalid microgrid photovoltaic id',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_NAME': 'Invalid microgrid photovoltaic name',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_ID': 'Invalid microgrid power conversion system ID',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_NAME': 'Invalid microgrid microgrid power conversion system name',
      'API.INVALID_MICROGRID_UUID': 'Invalid microgrid UUID',
      'API.INVALID_NAME_VALUE': 'Invalid name value',
      'API.INVALID_NEW_PASSWORD': 'Invalid new password',
      'API.INVALID_NON_WORKING_DAY_ID': 'Invalid non working day ID',
      'API.INVALID_NOTIFICATION_ID': 'Invalid notification id',
      'API.INVALID_NUMERATOR_METER_UUID': 'Invalid numerator meter uuid',
      'API.INVALID_OBJECT_TYPE': 'Invalid object type',
      'API.INVALID_OFFLINE_METER_DATA': 'Invalid offline meter data',
      'API.INVALID_OFFLINE_METER_FILE_ID': 'Invalid offline meter file id',
      'API.INVALID_OFFLINE_METER_ID': 'Invalid offline meter id',
      'API.INVALID_OFFLINE_METER_NAME': 'Invalid offline meter name',
      'API.INVALID_OFFLINE_METER_UUID': 'Invalid offline meter uuid',
      'API.INVALID_OFFSET_CONSTANT_VALUE': 'Invalid offset constant value',
      'API.INVALID_OLD_PASSWORD': 'Invalid old password',
      'API.INVALID_OPENID': 'Invalid openid',
      'API.INVALID_PARENT_SPACE_ID': 'Invalid parent space id',
      'API.INVALID_PASSWORD': 'Invalid password',
      'API.INVALID_PAYLOAD': 'Invalid payload',
      'API.INVALID_PEAK_CURRENT': 'Invalid peak current',
      'API.INVALID_PEAK_LOAD': 'Invalid peak load',
      'API.INVALID_PERIOD_TYPE': 'Invalid period type',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_ID': 'Invalid photovoltaic power station ID',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_NAME': 'Invalid photovoltaic power station name',
      'API.INVALID_POINT_ID': 'Invalid point id',
      'API.INVALID_POINT_NAME': 'Invalid point name',
      'API.INVALID_PORT': 'Invalid port',
      'API.INVALID_POSTAL_CODE_VALUE': 'Invalid postal code value',
      'API.INVALID_POWER_POINT_ID': 'Invalid power point id',
      'API.INVALID_PRIORITY': 'Invalid priority',
      'API.INVALID_PRIVILEGE': 'Invalid privilege',
      'API.INVALID_PRIVILEGE_DATA': 'Invalid privilege data',
      'API.INVALID_PRIVILEGE_ID': 'Invalid privilege id',
      'API.INVALID_PRIVILEGE_NAME': 'Invalid privilege name',
      'API.INVALID_RATIO_VALUE': 'Invalid ratio value',
      'API.INVALID_RECIPIENT_EMAIL': 'Invalid recipient email',
      'API.INVALID_RECIPIENT_MOBILE': 'Invalid recipient mobile',
      'API.INVALID_RECIPIENT_NAME': 'Invalid recipient name',
      'API.INVALID_RECIPIENT_OPENID': 'Invalid recipient openid',
      'API.INVALID_REPLY': 'Invalid reply',
      'API.INVALID_REPORTING_PERIOD_END_DATETIME': 'Invalid reporting period end datetime',
      'API.INVALID_REPORTING_PERIOD_START_DATETIME': 'Invalid reporting period start datetime',
      'API.INVALID_REQUIRES_AUTHENTICATION': 'Invalid requires authentication',
      'API.INVALID_ROOMS_VALUE': 'Invalid rooms value',
      'API.INVALID_RULE_ID': 'Invalid rule id',
      'API.INVALID_RULE_NAME': 'Invalid rule name',
      'API.INVALID_RUN_STATE_POINT_ID': 'Invalid run state point ID',
      'API.INVALID_SCHEDULED_DATETIME': 'Invalid scheduled datetime',
      'API.INVALID_SELL_METER_ID': 'Invalid sell meter id',
      'API.INVALID_SENSOR_ID': 'Invalid sensor id',
      'API.INVALID_SENSOR_NAME': 'Invalid sensor name',
      'API.INVALID_SENSOR_UUID': 'Invalid sensor uuid',
      'API.INVALID_SERIAL_NUMBER': 'Invalid serial number',
      'API.INVALID_SERIAL_PORT': 'Invalid serial port',
      'API.INVALID_SESSION_PLEASE_RE_LOGIN': 'Invalid session please re-login',
      'API.INVALID_SET_VALUE': 'Invalid set value',
      'API.INVALID_SHOPFLOOR_ID': 'Invalid shopfloor id',
      'API.INVALID_SHOPFLOOR_NAME': 'Invalid shopfloor name',
      'API.INVALID_SHOPFLOOR_UUID': 'Invalid shopfloor uuid',
      'API.INVALID_SOURCE_NODE_ID': 'Invalid source node id',
      'API.INVALID_SPACE_ID': 'Invalid space id',
      'API.INVALID_SPACE_NAME': 'Invalid space name',
      'API.INVALID_SPACE_UUID': 'Invalid space uuid',
      'API.INVALID_START_DATE_FORMAT': 'Invalid start date format',
      'API.INVALID_START_DATETIME': 'Invalid start datetime',
      'API.INVALID_STATUS': 'Invalid status',
      'API.INVALID_STORE_ID': 'Invalid store id',
      'API.INVALID_STORE_NAME': 'Invalid store name',
      'API.INVALID_STORE_TYPE_DESCRIPTION': 'Invalid store type description',
      'API.INVALID_STORE_TYPE_ID': 'Invalid store type id',
      'API.INVALID_STORE_TYPE_NAME': 'Invalid store type name',
      'API.INVALID_STORE_TYPE_SIMPLIFIED_CODE': 'Invalid store type simplified code',
      'API.INVALID_STORE_UUID': 'Invalid store uuid',
      'API.INVALID_SUBJECT_VALUE': 'Invalid subject value',
      'API.INVALID_SVG': 'Invalid SVG',
      'API.INVALID_SWITCHGEAR': 'Invalid switchgear',
      'API.INVALID_TARGET_NODE_ID': 'Invalid target node id',
      'API.INVALID_TARIFF_BLOCK_PRICING': 'Invalid tariff block pricing',
      'API.INVALID_TARIFF_ID': 'Invalid tariff id',
      'API.INVALID_TARIFF_TIME_OF_USE_PRICING': 'Invalid tariff time of use pricing',
      'API.INVALID_TARIFF_TYPE': 'Invalid tariff type',
      'API.INVALID_TENANT_ID': 'Invalid tenant id',
      'API.INVALID_TENANT_NAME': 'Invalid tenant name',
      'API.INVALID_TENANT_TYPE_DESCRIPTION': 'Invalid tenant type description',
      'API.INVALID_TENANT_TYPE_ID': 'Invalid tenant type id',
      'API.INVALID_TENANT_TYPE_NAME': 'Invalid tenant type name',
      'API.INVALID_TENANT_TYPE_SIMPLIFIED_CODE': 'Invalid tenant type simplified code',
      'API.INVALID_TENANT_UUID': 'Invalid tenant uuid',
      'API.INVALID_TEXT_MESSAGE_ID': 'Invalid text message id',
      'API.INVALID_TIMEZONE_ID': 'Invalid timezone id',
      'API.INVALID_TOKEN': 'Invalid token',
      'API.INVALID_TOPIC': 'Invalid topic',
      'API.INVALID_UNIT_OF_MEASURE': 'Invalid unit of measure',
      'API.INVALID_UNIT_OF_PRICE': 'Invalid unit of price',
      'API.INVALID_UNITS': 'Invalid units',
      'API.INVALID_USER_ID': 'Invalid user id',
      'API.INVALID_USER_NAME': 'Invalid user name',
      'API.INVALID_USER_NAME_OR_EMAIL': 'Invalid user name or email',
      'API.INVALID_USER_PHONE': 'Invalid user phone',
      'API.INVALID_USER_PLEASE_RE_LOGIN': 'Invalid user please re-login',
      'API.INVALID_USER_UUID': 'Invalid user uuid',
      'API.INVALID_USERNAME': 'Invalid userName',
      'API.INVALID_VARIABLE_METER_ID': 'Invalid variable meter id',
      'API.INVALID_VARIABLE_METER_TYPE': 'Invalid variable meter type',
      'API.INVALID_VARIABLE_NAME': 'Invalid variable name',
      'API.INVALID_VERIFICATION_CODE': 'Invalid verification code',
      'API.INVALID_VIRTUAL_METER_ID': 'Invalid virtual meter id',
      'API.INVALID_VIRTUAL_METER_NAME': 'Invalid virtual meter name',
      'API.INVALID_VIRTUAL_METER_UUID': 'Invalid virtual meter uuid',
      'API.INVALID_WEB_MESSAGE_ID': 'Invalid web message id',
      'API.INVALID_WECHAT_MESSAGE_ID': 'Invalid wechat message id',
      'API.INVALID_WIND_FARM_ID': 'Invalid wind farm ID',
      'API.INVALID_WIND_FARM_NAME': 'Invalid wind farm name',
      'API.INVALID_WORKING_CALENDAR_ID': 'Invalid working calendar id ',
      'API.INVALID_WORKING_CALENDAR_NAME': 'Invalid working calendar name',
      'API.KNOWLEDGE_FILE_CANNOT_BE_REMOVED_FROM_DISK': 'Knowledge File Cannot be Removed from Disk',
      'API.KNOWLEDGE_FILE_NOT_FOUND': 'Knowledge file not found',
      'API.MASTER_METER_DOES_NOT_BELONG_TO_SAME_ENERGY_CATEGORY':
        'Master meter does not belong to same energy category',
      'API.MASTER_METER_NOT_FOUND': 'Master meter not found',
      'API.MENU_NOT_FOUND': 'Menu not found',
      'API.METER_CANNOT_HAVE_MORE_THAN_ONE_ENERGY_VALUE_POINTS': 'Meter cannot have more than one energy value points',
      'API.METER_COMMAND_RELATION_EXISTS': 'Meter command relation exists',
      'API.METER_COMMAND_RELATION_NOT_FOUND': 'Meter command relation not found',
      'API.METER_NAME_IS_ALREADY_IN_USE': 'Meter name is already in use',
      'API.METER_NOT_FOUND': 'Meter not found',
      'API.METER_OF_VARIABLE_NOT_FOUND': 'Meter of variable not found',
      'API.METER_POINT_RELATION_EXISTS': 'Meter point relation exists',
      'API.METER_POINT_RELATION_NOT_FOUND': 'Meter point relation not found',
      'API.MICROGRID_BATTERY_NAME_IS_ALREADY_IN_USE': 'Microgrid battery name is already in use',
      'API.MICROGRID_BATTERY_NOT_FOUND': 'Microgrid battery not found',
      'API.MICROGRID_EVCHARGER_NAME_IS_ALREADY_IN_USE': 'Microgrid evcharger name is already in use',
      'API.MICROGRID_EVCHARGER_NOT_FOUND': 'Microgrid evcharger not fund',
      'API.MICROGRID_GENERATOR_NAME_IS_ALREADY_IN_USE': 'Microgrid generator name is already in use',
      'API.MICROGRID_GENERATOR_NOT_FOUND': 'Microgrid generator not found',
      'API.MICROGRID_GRID_NAME_IS_ALREADY_IN_USE': 'Microgrid grid name is already in use',
      'API.MICROGRID_GRID_NOT_FOUND': 'Microgrid grid not found',
      'API.MICROGRID_HEATPUMP_NAME_IS_ALREADY_IN_USE': 'Microgrid heatpump name is already in use',
      'API.MICROGRID_HEATPUMP_NOT_FOUND': 'Microgrid heatpump not found',
      'API.MICROGRID_LOAD_NAME_IS_ALREADY_IN_USE': 'Microgrid load name is already',
      'API.MICROGRID_LOAD_NOT_FOUND': 'Microgrid load not found',
      'API.MICROGRID_NAME_IS_ALREADY_IN_USE': 'Microgrid name is already in use',
      'API.MICROGRID_NOT_FOUND': 'Microgrid not found',
      'API.MICROGRID_PHOTOVOLTAIC_NAME_IS_ALREADY_IN_USE': 'Microgrid photovoltaic name is already in use',
      'API.MICROGRID_PHOTOVOLTAIC_NOT_FOUND': 'Microgrid photovoltaic not found',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NAME_IS_ALREADY_IN_USE':
        'Microgrid power conversion system name is already in use',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NOT_FOUND': 'Microgrid power conversion system not found',
      'API.MICROGRID_SENSOR_RELATION_EXISTS': 'Microgrid sensor relation exists',
      'API.MICROGRID_SENSOR_RELATION_NOT_FOUND': 'Microgrid sensor not found',
      'API.MQTT_CONNECTION_ERROR': 'Mqtt connection error',
      'API.MQTT_PUBLISH_ERROR': 'Mqtt publish error',
      'API.NEW_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'New password length cannot exceed 100 characters',
      'API.NEW_USER_SESSION_NOT_FOUND': 'New user session not found',
      'API.NEW_USER_SESSION_TIMEOUT': 'New user session timeout',
      'API.NON_WORKING_DAY_NOT_FOUND': 'Non working day non found',
      'API.NOT_FOUND': 'Not found',
      'API.NOTIFICATION_NOT_FOUND': 'Notification not found',
      'API.OFFLINE_METER_FILE_NOT_FOUND': 'Offline meter file not found',
      'API.OFFLINE_METER_NAME_IS_ALREADY_IN_USE': 'Offline meter name is already in use',
      'API.OFFLINE_METER_NOT_FOUND': 'Offline meter not found',
      'API.OFFLINE_METER_OF_VARIABLE_NOT_FOUND': 'Offline meter of variable not found',
      'API.OLD_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Old password length cannot exceed 100 characters',
      'API.PARENT_SPACE_NOT_FOUND': 'Parent space not found',
      'API.PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Password length cannot exceed 100 characters',
      'API.PASSWORDS_MATCH': 'New password is the same as the old password',
      'API.PHOTOVOLTAIC_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Photovoltaic power station name is already in use',
      'API.PHOTOVOLTAIC_POWER_STATION_NOT_FOUND': 'Photovoltaic power station not found',
      'API.POINT_NAME_IS_ALREADY_IN_USE': 'Point name is already in use',
      'API.POINT_NOT_FOUND': 'Point not found',
      'API.POWER_POINT_NOT_FOUND': 'Power point not found',
      'API.PRIVILEGE_NAME_IS_ALREADY_IN_USE': 'Privilege name is already in use',
      'API.PRIVILEGE_NOT_FOUND': 'Privilege not found',
      'API.RULE_NAME_IS_ALREADY_IN_USE': 'Rule name is already in use',
      'API.RULE_NOT_FOUND': 'Rule not found',
      'API.SELL_METER_NOT_FOUND': 'Sell meter not found',
      'API.SENSOR_NAME_IS_ALREADY_IN_USE': 'Sensor name is already in use',
      'API.SENSOR_NOT_FOUND': 'Sensor not found',
      'API.SENSOR_POINT_RELATION_EXISTS': 'Sensor point relation exists',
      'API.SENSOR_POINT_RELATION_NOT_FOUND': 'Sensor point relation not found',
      'API.SHOPFLOOR_COMMAND_RELATION_EXISTS': 'Shopfloor command relation exists',
      'API.SHOPFLOOR_COMMAND_RELATION_NOT_FOUND': 'Shopfloor command relation not found',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_EXISTS': 'Shopfloor equipment relation exists',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_NOT_FOUND': 'Shopfloor equipment relation not found',
      'API.SHOPFLOOR_METER_RELATION_EXISTS': 'Shopfloor meter relation exists',
      'API.SHOPFLOOR_METER_RELATION_NOT_FOUND': 'Shopfloor meter relation not found',
      'API.SHOPFLOOR_NAME_IS_ALREADY_IN_USE': 'Shopfloor name is already in use',
      'API.SHOPFLOOR_NOT_FOUND': 'Shopfloor not found',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_EXISTS': 'Shopfloor offline meter relation exists',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_NOT_FOUND': 'Shopfloor offline meter relation not found',
      'API.SHOPFLOOR_POINT_RELATION_EXISTS': 'Shopfloor point relation exists',
      'API.SHOPFLOOR_POINT_RELATION_NOT_FOUND': 'Shopfloor point relation not found',
      'API.SHOPFLOOR_SENSOR_RELATION_EXISTS': 'Shopfloor sensor relation exists',
      'API.SHOPFLOOR_SENSOR_RELATION_NOT_FOUND': 'Shopfloor sensor relation not found',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_EXISTS': 'Shopfloor virtual meter relation exists',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_NOT_FOUND': 'Shopfloor virtual meter relation not found',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_EXISTS': 'Shopfloor working calendar relation exists',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Shopfloor working calendar relation not found',
      'API.SOC_POINT_NOT_FOUND': 'SOC point not found',
      'API.SOURCE_NODE_NOT_FOUND': 'Source node not found',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_EXISTS': 'Space combined equipment relation exists',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_NOT_FOUND': 'Space combined equipment relation not found',
      'API.SPACE_COMMAND_RELATION_EXISTS': 'Space command relation exists',
      'API.SPACE_COMMAND_RELATION_NOT_FOUND': 'Space command relation not found',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_EXISTS': 'Space Energy Storage Power Station Relation Exists',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_NOT_FOUND': 'Space Energy Storage Power Station Relation Not Found',
      'API.SPACE_EQUIPMENT_RELATION_EXISTS': 'Space equipment relation exists',
      'API.SPACE_EQUIPMENT_RELATION_NOT_FOUND': 'Space equipment relation not found',
      'API.SPACE_METER_RELATION_EXISTS': 'Space meter relation exists',
      'API.SPACE_METER_RELATION_NOT_FOUND': 'Space meter relation not found',
      'API.SPACE_NAME_IS_ALREADY_IN_USE': 'Space name is already in use',
      'API.SPACE_NOT_FOUND': 'Space not found',
      'API.SPACE_NOT_FOUND_IN_PRIVILEGE': 'Space not found in privilege',
      'API.SPACE_OFFLINE_METER_RELATION_EXISTS': 'Space offline meter relation exists',
      'API.SPACE_OFFLINE_METER_RELATION_NOT_FOUND': 'Space offline meter relation not found',
      'API.SPACE_POINT_RELATION_EXISTS': 'Space point relation exists',
      'API.SPACE_POINT_RELATION_NOT_FOUND': 'Space point relation not found',
      'API.SPACE_SENSOR_RELATION_EXISTS': 'Space sensor relation exists',
      'API.SPACE_SENSOR_RELATION_NOT_FOUND': 'Space sensor relation not found',
      'API.SPACE_SHOPFLOOR_RELATION_EXISTS': 'Space shopfloor relation exists',
      'API.SPACE_SHOPFLOOR_RELATION_NOT_FOUND': 'Space shopfloor relation not found',
      'API.SPACE_STORE_RELATION_EXISTS': 'Space store relation exists',
      'API.SPACE_STORE_RELATION_NOT_FOUND': 'Space store relation not found',
      'API.SPACE_TENANT_RELATION_EXISTS': 'Space tenant relation exists',
      'API.SPACE_TENANT_RELATION_NOT_FOUND': 'Space tenant relation not found',
      'API.SPACE_VIRTUAL_METER_RELATION_EXISTS': 'Space virtual meter relation exists',
      'API.SPACE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Space virtual meter relation not found',
      'API.SPACE_WORKING_CALENDAR_RELATION_EXISTS': 'Space working calendar relation exists',
      'API.SPACE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Space working calendar relation not found',
      'API.START_DATETIME_MUST_BE_EARLIER_THAN_END_DATETIME': 'Start datetime must be early than end datetime',
      'API.STORE_COMMAND_RELATION_EXISTS': 'Store command relation exists',
      'API.STORE_COMMAND_RELATION_NOT_FOUND': 'Store command relation not found',
      'API.STORE_METER_RELATION_EXISTS': 'Store meter relation exists',
      'API.STORE_METER_RELATION_NOT_FOUND': 'Store meter relation not found',
      'API.STORE_NAME_IS_ALREADY_IN_USE': 'Store name is already in use',
      'API.STORE_NOT_FOUND': 'Store not found',
      'API.STORE_OFFLINE_METER_RELATION_EXISTS': 'Store offline meter relation exists',
      'API.STORE_OFFLINE_METER_RELATION_NOT_FOUND': 'Store offline meter relation not found',
      'API.STORE_POINT_RELATION_EXISTS': 'Store point relation exists',
      'API.STORE_POINT_RELATION_NOT_FOUND': 'Store point relation not found',
      'API.STORE_SENSOR_RELATION_EXISTS': 'Store sensor relation exists',
      'API.STORE_SENSOR_RELATION_NOT_FOUND': 'Store sensor relation not found',
      'API.STORE_TYPE_NAME_IS_ALREADY_IN_USE': 'Store type name is already in use',
      'API.STORE_TYPE_NOT_FOUND': 'Store type not found',
      'API.STORE_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Store type simplified code is already in use',
      'API.STORE_TYPE_USED_IN_STORE': 'Store type used in store',
      'API.STORE_VIRTUAL_METER_RELATION_EXISTS': 'Store virtual meter relation exists',
      'API.STORE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Store virtual meter relation not found',
      'API.STORE_WORKING_CALENDAR_RELATION_EXISTS': 'Store working calendar relation exists',
      'API.STORE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Store working calendar relation not found',
      'API.TARGET_NODE_NOT_FOUND': 'Target node not found',
      'API.TARIFF_IN_USE': 'Tariff in use',
      'API.TARIFF_IS_ALREADY_ASSOCIATED_WITH_COST_CENTER': 'Tariff is already associated with cost center',
      'API.TARIFF_IS_NOT_ASSOCIATED_WITH_COST_CENTER': 'Tariff is not associated with cost center',
      'API.TARIFF_NAME_IS_ALREADY_IN_USE': 'Tariff name is already in use',
      'API.TARIFF_NOT_FOUND': 'Tariff not found',
      'API.TENANT_COMMAND_RELATION_EXISTS': 'Tenant command relation exists',
      'API.TENANT_COMMAND_RELATION_NOT_FOUND': 'Tenant command relation  not found',
      'API.TENANT_METER_RELATION_EXISTS': 'Tenant meter relation exists',
      'API.TENANT_METER_RELATION_NOT_FOUND': 'Tenant meter relation not found',
      'API.TENANT_NAME_IS_ALREADY_IN_USE': 'Tenant name is already in use',
      'API.TENANT_NOT_FOUND': 'Tenant not found',
      'API.TENANT_OFFLINE_METER_RELATION_EXISTS': 'Tenant offline meter relation exists',
      'API.TENANT_OFFLINE_METER_RELATION_NOT_FOUND': 'Tenant offline meter relation not found',
      'API.TENANT_POINT_RELATION_EXISTS': 'Tenant point relation exists',
      'API.TENANT_POINT_RELATION_NOT_FOUND': 'Tenant point relation not found',
      'API.TENANT_SENSOR_RELATION_EXISTS': 'Tenant sensor relation exists',
      'API.TENANT_SENSOR_RELATION_NOT_FOUND': 'Tenant sensor relation not found',
      'API.TENANT_TYPE_NAME_IS_ALREADY_IN_USE': 'Tenant type name is already in use',
      'API.TENANT_TYPE_NOT_FOUND': 'Tenant type not found',
      'API.TENANT_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Tenant type simplified code is already in use',
      'API.TENANT_TYPE_USED_IN_TENANT': 'Tenant type used in tenant',
      'API.TENANT_VIRTUAL_METER_RELATION_EXISTS': 'Tenant virtual meter relation exists',
      'API.TENANT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Tenant virtual meter relation not found',
      'API.TENANT_WORKING_CALENDAR_RELATION_EXISTS': 'Tenant working calendar relation exists',
      'API.TENANT_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Tenant working calendar relation not found',
      'API.TEXT_MESSAGE_NOT_FOUND': 'Text message not found',
      'API.THE_REPORTING_PERIOD_MUST_BE_LONGER_THAN_15_MINUTES': 'The reporting period must be longer than 15 minutes',
      'API.THERE_IS_RELATION_WITH_CHILD_METERS': 'There is relation with child meters',
      'API.THERE_IS_RELATION_WITH_CHILDREN_SPACES': 'There is relation with children spaces',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENT_PARAMETERS':
        'There is relation with combined equipment parameters',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENTS': 'There is relation with combined equipments',
      'API.THERE_IS_RELATION_WITH_DATA_SOURCES': 'There is relation with data sources',
      'API.THERE_IS_RELATION_WITH_ENERGY_FLOW_DIAGRAM_LINKS': 'There is relation with energy flow diagram links',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_BATTERIES': 'There Is Relation With Energy Storage Containers Batteries',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_GRIDS': 'There Is Relation With Energy Storage Containers Grids',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_LOADS': 'There Is Relation With Energy Storage Containers Loads',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_POWER_CONVERSION_SYSTEMS': 'There Is Relation With Energy Storage Containers Power Conversion Systems',
      'API.THERE_IS_RELATION_WITH_EQUIPMENT_PARAMETERS': 'There is relation with equipment parameters',
      'API.THERE_IS_RELATION_WITH_EQUIPMENTS': 'There is relation with equipments',
      'API.THERE_IS_RELATION_WITH_METER': 'There is relation with meter',
      'API.THERE_IS_RELATION_WITH_METERS': 'There is relation with meters',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METER': 'There is relation with offline meter',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METERS': 'There is relation with offline meters',
      'API.THERE_IS_RELATION_WITH_OTHER_VIRTUAL_METERS': 'There is relation with other virtual meters',
      'API.THERE_IS_RELATION_WITH_SENSORS': 'There is relation with sensors',
      'API.THERE_IS_RELATION_WITH_SHOPFLOORS': 'There is relation with shopfloors',
      'API.THERE_IS_RELATION_WITH_SPACES': 'There is relation with spaces',
      'API.THERE_IS_RELATION_WITH_STORES': 'There is relation with stores',
      'API.THERE_IS_RELATION_WITH_TENANTS': 'There is relation with tenants',
      'API.THERE_IS_RELATION_WITH_USERS': 'There is relation with users',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_METER': 'There is relation with virtual meter',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_POWER_PLANTS': 'مع الظاهري محطة توليد الكهرباء',
      'API.THIS_DATA_SOURCE_IS_BEING_USED_BY_A_METER': 'This data source is being used by a meter',
      'API.THIS_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This meter is being used by a virtual meter',
      'API.THIS_OFFLINE_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This offline meter is being used by a virtual meter',
      'API.THIS_SPACE_CANNOT_BE_CLONED': 'This space cannot be cloned',
      'API.THIS_SPACE_CANNOT_BE_DELETED': 'This space cannot be deleted',
      'API.TIMEZONE_NOT_FOUND': 'Timezone not found',
      'API.TOKEN_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'Token not found in headers please login',
      'API.USER_ACCOUNT_HAS_BEEN_LOCKED': 'User Account Has Been Locked',
      'API.USER_ACCOUNT_HAS_EXPIRED': 'User Account Has Expired',
      'API.USER_ACCOUNT_IS_NOT_LOCKED': 'User account is not locked',
      'API.USER_NAME_IS_ALREADY_IN_USE': 'User name is already in use',
      'API.USER_NOT_FOUND': 'User not found',
      'API.USER_PASSWORD_HAS_EXPIRED': 'User Password Has Expired',
      'API.USER_PRIVILEGE_NOT_FOUND': 'User privilege not found',
      'API.USER_SESSION_NOT_FOUND': 'User session not found',
      'API.USER_SESSION_TIMEOUT': 'User session timeout',
      'API.USER_UUID_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'User uuid not found in headers please login',
      'API.VIRTUAL_METER_NAME_IS_ALREADY_IN_USE': 'Virtual meter name is already in use',
      'API.VIRTUAL_METER_NOT_FOUND': 'Virtual meter not found',
      'API.VIRTUAL_METER_OF_VARIABLE_NOT_FOUND': 'Virtual meter of variable not found',
      'API.VIRTUAL_POINT_SHOULD_BE_ANALOG_VALUE': 'Virtual point should be analog value',
      'API.WEB_MESSAGE_NOT_FOUND': 'Web message not found',
      'API.WECHAT_MESSAGE_NOT_FOUND': 'Wechat message not found',
      'API.WIND_FARM_NAME_IS_ALREADY_IN_USE': 'Wind farm name is already in use',
      'API.WIND_FARM_NOT_FOUND': 'Wind farm not found',
      'API.WORKING_CALENDAR_NAME_IS_ALREADY_IN_USE': 'Working calendar name is already in use',
      'API.WORKING_CALENDAR_NOT_FOUND': 'Working calendar not found',

      MasterMeter: 'جدول موجز',
      SubMeter: 'تقسيم الجدول'
    }
  },
  vi: {
    translation: {
      // routes & menus
      Dashboard: 'Bảng điều khiển',
      'Space Data': 'Không gian',
      'Equipment Data': 'Thiết bị',
      'Meter Data': 'Mét',
      'Tenant Data': 'Thuê',
      'Store Data': 'Cửa hàng',
      'Shopfloor Data': 'Tầng cửa hàng',
      'Combined Equipment Data': 'Thiết bị kết hợp',
      'Auxiliary System': 'Hệ thống phụ trợ',
      Microgrid: 'Lưới điện siêu nhỏ',
      'Energy Storage Power Station': 'Trạm lưu trữ năng lượng',
      'Photovoltaic Power Station': 'Power Station',
      'Fault Alarm': 'Báo động lỗi',
      Monitoring: 'Giám sát',
      'Advanced Reporting': 'Báo cáo nâng cao',
      'Knowledge Base': 'Cơ sở tri thức',
      'Energy Category Data': 'Danh mục năng lượng',
      'Energy Item Data': 'Mục năng lượng',
      Carbon: 'Khí thải CO2',
      Cost: 'Chi phí',
      Output: 'Ra',
      Income: 'Thu nhập',
      Efficiency: 'Hiệu quả',
      Load: 'Tải',
      Statistics: 'Thống kê',
      'Batch Analysis': 'Phân tích hàng loạt',
      Saving: 'Tiết kiệm',
      Plan: 'Kế hoạch',
      'Production': 'Production',
      'Space Production': 'Space Production',
      'Equipment Tracking': 'Theo dõi thiết bị',
      'Environment Monitor': 'Giám sát môi trường',
      'Meter Energy': 'Năng lượng đo',
      'Meter Carbon': 'Thiết bị phát thải CO2',
      'Meter Cost': 'Chi phí đồng hồ đo',
      'Meter Trend': 'Xu hướng đồng hồ đo',
      'Meter Realtime': 'Đồng hồ thời gian thực',
      'Meter Saving': 'Tiết kiệm đồng hồ',
      'Meter Plan': 'Kế hoạch đồng hồ',
      'Master Meter Submeters Balance': 'Master Meter Submeters Balance',
      'Meter Batch Analysis': 'Phân tích hàng loạt đồng hồ',
      'Meter Comparison': 'So sánh đồng hồ',
      'Meter Tracking': 'Theo dõi đồng hồ đo',
      'Virtual Meter Saving': 'Tiết kiệm đồng hồ ảo',
      'Virtual Meter Plan': 'Kế hoạch đồng hồ ảo',
      'Virtual Meter Energy': 'Năng lượng đồng hồ ảo',
      'Virtual Meter Carbon': 'Khí thải CO2 ảo',
      'Virtual Meter Cost': 'Chi phí đồng hồ ảo',
      'Virtual Meter Batch Analysis': 'Phân tích hàng loạt đồng hồ ảo',
      'Offline Meter Energy': 'Đồng hồ đo năng lượng ngoại tuyến',
      'Offline Meter Carbon': 'Phát thải CO2 cho thiết bị ngoại tuyến',
      'Offline Meter Cost': 'Chi phí đồng hồ ngoại tuyến',
      'Offline Meter Batch Analysis': 'Phân tích hàng loạt đồng hồ ngoại tuyến',
      'Offline Meter Saving': 'Tiết kiệm đồng hồ ngoại tuyến',
      'Offline Meter Plan': 'Gói đồng hồ đo ngoại tuyến',
      'Offline Meter Input': 'Đầu vào đồng hồ ngoại tuyến',
      'Tenant Bill': 'Hóa đơn người thuê nhà',
      'Energy Flow Diagram': 'Sơ đồ dòng năng lượng',
      'Distribution System': 'Hệ thống phân phối',
      'SVG System': 'Hệ thống trực quan hóa SCADA',
      'Faults Data': 'Lỗi',
      'Space Equipments': 'Thiết bị không gian',
      'Combined Equipments': 'Thiết bị kết hợp',
      'Energy Storage Power Station List': 'List',
      'Energy Storage Power Station Details': 'Details',
      'Energy Storage Power Station Reporting': 'Reporting',
      'Energy Storage Power Station Alarm': 'Alarm',
      'Energy Storage Power Station Maintenance': 'Maintenance',
      'Enter Production': 'Enter Production',
      'Multiple Energy Storage Power Stations': 'Multiple Power Stations',
      'Multiple Energy Storage Power Stations Dashboard': 'Dashboard',
      'Multiple Energy Storage Power Stations List': 'List',
      'Single Energy Storage Power Station': 'Single Power Station',
      'Single Energy Storage Power Station Dashboard': 'Dashboard',
      'Single Energy Storage Power Station Details': 'Details',
      'Single Energy Storage Power Station Fault Alarm': 'Fault Alarm',
      'Energy Storage Power Station Reports': 'Power Station Reports',
      'Energy Storage Power Station Reports Energy': 'Energy',
      'Energy Storage Power Station Reports Revenue': 'Revenue',
      'Energy Storage Power Station Reports Parameters': 'Parameters',
      'Photovoltaic Power Station List': 'Power Station List',
      'Photovoltaic Power Station Details': 'Details',
      'Photovoltaic Power Station Reporting': 'Reporting',
      'Photovoltaic Power Station Alarm': 'Alarm',
      'Photovoltaic Power Station Maintenance': 'Maintenance',
      'Multiple Photovoltaic Power Stations': 'Multiple Power Stations',
      'Multiple Photovoltaic Power Stations Dashboard': 'Dashboard',
      'Multiple Photovoltaic Power Stations List': 'List',
      'Single Photovoltaic Power Station': 'Single Power Station',
      'Single Photovoltaic Power Station Dashboard': 'Dashboard',
      'Single Photovoltaic Power Station Details': 'Details',
      'Single Photovoltaic Power Station Fault Alarm': 'Fault Alarm',
      'Photovoltaic Power Station Reports': 'Power Station Reports',
      'Photovoltaic Power Station Reports Energy': 'Energy',
      'Photovoltaic Power Station Reports Revenue': 'Revenue',
      'Photovoltaic Power Station Reports Parameters': 'Parameters',
      'Work Order': 'Work Order',
      'Work Order Installation': 'Installation',
      'Work Order Repair': ' Repair',
      'Work Order Inspection': 'Inspection',
      'Total Number of Work Orders': 'Tổng số lệnh công việc',
      'Number of New Work Orders': 'Số lệnh công việc mới',
      'Number of Inprogress Work Orders': 'Số lệnh công việc đang thực hiện',
      'Number of Done Work Orders': 'Số lệnh công việc đã hoàn thành',
      'Charge Energy Indicator': 'Năng lượng sạc',
      'Discharge Energy Indicator': 'Năng lượng xả',
      'Charge Cost Indicator': 'Chi phí sạc',
      'Discharge Revenue Indicator': 'Doanh thu xả',
      'HVAC': 'HVAC',
      'Fire Control': 'Kiểm soát cháy',

      // Dashboard
      'Welcome to DoraEOS': 'Chào mừng bạn đến với DoraEOS',
      'An Industry Leading Open Source Energy Management System':
        'Một hệ thống quản lý năng lượng nguồn mở hàng đầu trong ngành',
      "This Year's Consumption CATEGORY VALUE UNIT": 'Tiêu thụ năm nay {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Year's Costs CATEGORY VALUE UNIT": 'Chi phí năm nay {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Consumption CATEGORY VALUE UNIT": 'Tiêu thụ tháng này {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Costs CATEGORY VALUE UNIT": 'Chi phí của tháng này {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Consumption CATEGORY VALUE UNIT in The Same Period Last Year':
        'Tiêu thụ {{CATEGORY}} {{VALUE}} {{UNIT}} cùng kỳ năm ngoái',
      'Costs CATEGORY VALUE UNIT in The Same Period Last Year':
        'Chi phí {{CATEGORY}} {{VALUE}} {{UNIT}} cùng kỳ năm ngoái',
      'CATEGORY Consumption UNIT': '{{CATEGORY}} Tiêu thụ {{UNIT}}',
      'CATEGORY Costs UNIT': '{{CATEGORY}} Chi phí {{UNIT}}',
      // Entities
      Space: 'Không gian',
      Equipment: 'Thiết bị',
      Meter: 'Mét',
      'Offline Meter': 'Đồng hồ ngoại tuyến',
      'Virtual Meter': 'Đồng hồ ảo',
      Tenant: 'Thuê',
      Store: 'Cửa hàng',
      Shopfloor: 'Tầng cửa hàng',
      'Combined Equipment': 'Thiết bị kết hợp',
      'Cost Center': 'Trung tâm chi phí',
      Name: 'Tên',
      DisplayName: 'Tên hiển thị',
      UserName: 'Tên người dùng',
      Description: 'Sự miêu tả',
      'Energy Category': 'Danh mục năng lượng',
      'Child Spaces': 'Không gian trẻ em',
      'Associated Equipment': 'Thiết bị liên quan',
      //Energy Categories
      Electricity: 'Điện',
      Water: 'Nước',
      'Natural Gas': 'Khí thiên nhiên',
      // SidePanelModal
      Settings: 'Cài đặt',
      'Set your own customized style': 'Đặt phong cách tùy chỉnh của riêng bạn',
      'Color Scheme': 'Bảng màu',
      'Choose the perfect color mode for your app': 'Chọn chế độ màu hoàn hảo cho ứng dụng của bạn',
      Light: 'Ánh sáng',
      Dark: 'Tối',
      'RTL Mode': 'Chế độ RTL',
      'Switch your language direction': 'Chuyển hướng ngôn ngữ của bạn',
      'Fluid Layout': 'Bố cục chất lỏng',
      'Toggle container layout system': 'Chuyển đổi hệ thống bố trí container',
      'Navigation Position': 'Vị trí điều hướng',
      'Select a suitable navigation system for your web application':
        'Chọn một hệ thống điều hướng phù hợp cho ứng dụng web của bạn',
      'Vertical Nav': 'Điều hướng dọc',
      'Top Nav': 'Điều hướng hàng đầu',
      'Vertical Navbar Style': 'Kiểu Navbar dọc',
      'Switch between styles for your vertical navbar': 'Chuyển đổi giữa các kiểu cho thanh điều hướng dọc của bạn',
      Language: 'Ngôn ngữ',
      'Switch between languages': 'Chuyển đổi giữa các ngôn ngữ',
      'language-zh_CN': '简体中文',
      'language-en': 'English',
      'language-de': 'Deutsch',
      'language-fr': 'Français',
      'language-es': 'Español',
      'language-ru': 'Русский',
      'language-ar': 'العربية',
      'language-vi': 'Tiếng Việt',
      'language-th': 'ภาษาไทย',
      'language-tr': 'Türkçe',
      'language-ms': 'Bahasa Melayu',
      'language-id': 'Bahasa Indonesia',
      'language-zh_TW': '繁體中文',
      'Like What You See?': 'Thích những gì bạn thấy?',
      'Get DoraEOS now': 'Tải DoraEOS ngay bây giờ',
      Purchase: 'Mua',
      // Query Panel
      'Base Period Begins': 'Thời gian cơ sở bắt đầu',
      'Base Period Ends': 'Thời gian cơ sở kết thúc',
      'Comparison Types': 'Các loại so sánh',
      'Year-Over-Year': 'So với cùng kỳ năm ngoái',
      'Month-On-Month': 'Hàng tháng',
      'Free Comparison': 'So sánh miễn phí',
      'None Comparison': 'Không so sánh',
      'Reporting Period Begins': 'Thời gian báo cáo bắt đầu',
      'Reporting Period Ends': 'Kỳ báo cáo kết thúc',
      '(Optional)': '(Tùy chọn)',
      'Period Types': 'Các loại thời gian',
      Yearly: 'Thường niên',
      Monthly: 'Nguyệt san',
      Weekly: 'Tuần',
      Daily: 'Nhật báo',
      Hourly: 'Giờ',
      Submit: 'Trình',
      'Input Energy Category': 'Danh mục năng lượng đầu vào',
      'Output Energy Category': 'Danh mục năng lượng đầu ra',
      'Fraction Parameter': 'Tham số phân số',
      Search: 'Tìm kiếm',
      //DateRangePicker
      sunday: 'Su',
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      ok: 'OK',
      today: 'Hôm nay',
      yesterday: 'Hôm qua',
      hours: 'Giờ',
      minutes: 'Biên bản',
      seconds: 'Giây',
      last7Days: '7 ngày qua',
      'Select Date Range': 'Chọn Phạm vi ngày',
      //Card Summaries and Line Charts
      'Base Period': 'Thời gian cơ sở',
      'Reporting Period': 'Kỳ báo cáo',
      'Per Unit Area': 'Trên một đơn vị diện tích',
      'Per Capita': 'Per Capita',
      'Per Unit Production': 'Mỗi đơn vị sản xuất',
      Baseline: 'Baseline',
      Actual: 'Thực tế',
      'Baseline Value - Actual Value': 'Baseline - Thực tế',
      'Average Load': 'Tải trung bình',
      'Maximum Load': 'Tải tối đa',
      'Load Factor': 'Hệ số tải',
      'Ratio of Average Load to Maximum Load': 'Tỷ lệ tải trung bình so với tải tối đa',
      'Carbon Dioxide Emissions by Energy Category': 'Phát thải carbon dioxide theo danh mục năng lượng',
      'Costs by Energy Category': 'Chi phí theo loại năng lượng',
      'Incomes by Energy Category': 'Thu nhập theo loại năng lượng',
      'Electricity Consumption by Time-Of-Use': 'Tiêu thụ điện theo thời gian sử dụng',
      'Electricity Carbon Dioxide Emissions by Time-Of-Use': 'Điện phát thải carbon dioxide theo thời gian sử dụng',
      'Electricity Cost by Time-Of-Use': 'Chi phí điện theo thời gian sử dụng',
      'CATEGORY UNIT Consumption by Energy Items': '{{CATEGORY}} {{UNIT}} Tiêu thụ theo các mặt hàng năng lượng',
      'Ton of Standard Coal by Energy Category': 'Tấn than tiêu chuẩn (TCE) theo loại năng lượng',
      'Ton of Carbon Dioxide Emissions by Energy Category': 'Tấn khí thải carbon dioxide (TCO2E) theo loại năng lượng',
      'Reporting Period Consumption CATEGORY UNIT': 'Tiêu thụ kỳ báo cáo {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Consumption CATEGORY UNIT': '{{EQUIPMENT}} Tiêu thụ kỳ báo cáo {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Tiêu thụ kỳ báo cáo {{CATEGORY}} {{UNIT}}',
      'CATEGORY VALUE UNIT': '{{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption CATEGORY VALUE UNIT': 'Tiêu thụ kỳ báo cáo {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Production PRODUCT VALUE UNIT': 'Reporting Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Production PRODUCT VALUE UNIT': 'Base Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption CATEGORY VALUE UNIT': 'Tiêu thụ thời gian cơ sở {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY UNIT': 'Tiêu thụ kỳ báo cáo {{ITEM}} {{CATEGORY}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY VALUE UNIT':
        'Tiêu thụ kỳ báo cáo {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption ITEM CATEGORY VALUE UNIT':
        'Tiêu thụ thời gian cơ sở {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Child Space Proportion CATEGORY UNIT': 'Tỷ lệ không gian trẻ em theo loại năng lượng {{CATEGORY}} {{UNIT}}',
      'Child Space Total Proportion': 'Tổng tỷ lệ không gian trẻ em',
      'Reporting Period Carbon Dioxide Emissions CATEGORY UNIT':
        'Kỳ báo cáo Phát thải Carbon Dioxide {{CATEGORY}} {{UNIT}}',
      'Reporting Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Kỳ báo cáo Phát thải Carbon Dioxide {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Thời kỳ cơ sở Phát thải carbon Dioxide {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Costs CATEGORY UNIT': 'Chi phí kỳ báo cáo {{CATEGORY}} {{UNIT}}',
      'Reporting Period Costs CATEGORY VALUE UNIT': 'Chi phí kỳ báo cáo {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Costs CATEGORY VALUE UNIT': 'Chi phí giai đoạn cơ sở {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Output CATEGORY UNIT': 'Sản lượng kỳ báo cáo {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Output CATEGORY UNIT': '{{EQUIPMENT}} Sản lượng kỳ báo cáo {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Sản lượng kỳ báo cáo {{CATEGORY}} {{UNIT}}',
      'Reporting Period Output CATEGORY VALUE UNIT': 'Sản lượng kỳ báo cáo {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Output CATEGORY VALUE UNIT': 'Sản lượng giai đoạn cơ sở {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Income CATEGORY UNIT': 'Thu nhập kỳ báo cáo {{CATEGORY}} {{UNIT}}',
      'Reporting Period Income CATEGORY VALUE UNIT': 'Thu nhập kỳ báo cáo {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Income CATEGORY VALUE UNIT': 'Thu nhập thời kỳ cơ sở {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Total Income UNIT': 'Kỳ báo cáo Tổng thu nhập {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME UNIT': 'Kỳ báo cáo Hiệu quả tích lũy {{NAME}} {{UNIT}}',
      'EQUIPMENT Reporting Period Cumulative Efficiency UNIT': '{{EQUIPMENT}} Kỳ báo cáo Hiệu quả tích lũy {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Kỳ báo cáo Tích lũy Hiệu quả toàn diện {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Kỳ báo cáo Tích lũy Hiệu quả toàn diện {{VALUE}} {{UNIT}}',
      'COMBINED_EQUIPMENT Base Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Thời gian cơ sở Tích lũy Hiệu quả toàn diện {{VALUE}} {{UNIT}}',
      'Instantaneous Efficiency UNIT': 'Hiệu quả tức thời {{UNIT}}',
      'EQUIPMENT Instantaneous Efficiency UNIT': '{{EQUIPMENT}} Hiệu quả tức thời {{UNIT}}',
      'COMBINED_EQUIPMENT Instantaneous Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Hiệu quả toàn diện tức thời {{UNIT}}',
      'Reporting Period Cumulative Efficiency VALUE UNIT': 'Kỳ báo cáo Hiệu quả tích lũy {{VALUE}} {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME VALUE UNIT':
        'Kỳ báo cáo Hiệu quả tích lũy {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency VALUE UNIT': 'Thời gian cơ sở Hiệu quả tích lũy {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency NAME VALUE UNIT':
        'Thời gian cơ sở Hiệu quả tích lũy {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period CATEGORY Maximum Load UNIT': 'Thời gian cơ sở {{CATEGORY}} Tải tối đa {{UNIT}}',
      'Reporting Period CATEGORY Maximum Load UNIT': 'Kỳ báo cáo {{CATEGORY}} Tải tối đa {{UNIT}}',
      'Reporting Period CATEGORY Average Load UNIT': 'Kỳ báo cáo {{CATEGORY}} Tải trung bình {{UNIT}}',
      'Reporting Period CATEGORY Load Factor': 'Kỳ báo cáo {{CATEGORY}} Hệ số tải',
      'Base Period CATEGORY Average Load VALUE UNIT': 'Thời gian cơ sở {{CATEGORY}} Tải trung bình {{VALUE}} {{UNIT}}',
      'Reporting Period CATEGORY Average UNIT': 'Kỳ báo cáo {{CATEGORY}} Giá trị trung bình {{UNIT}}',
      'Reporting Period CATEGORY Maximum UNIT': 'Kỳ báo cáo {{CATEGORY}} Giá trị tối đa {{UNIT}}',
      'Reporting Period CATEGORY Minimum UNIT': 'Kỳ báo cáo {{CATEGORY}} Giá trị tối thiểu {{UNIT}}',
      'Reporting Period CATEGORY Mean UNIT': 'Kỳ báo cáo {{CATEGORY}} Trung bình số học {{UNIT}}',
      'Reporting Period CATEGORY Median UNIT': 'Kỳ báo cáo {{CATEGORY}} Trung tuyến (Giá trị trung bình) {{UNIT}}',
      'Reporting Period CATEGORY Stdev UNIT': 'Kỳ báo cáo {{CATEGORY}} Độ lệch chuẩn mẫu {{UNIT}}',
      'Reporting Period CATEGORY Variance UNIT': 'Kỳ báo cáo {{CATEGORY}} Phương sai mẫu {{UNIT}}',
      'Reporting Period Saving CATEGORY (Baseline - Actual) UNIT':
        'Tiết kiệm kỳ báo cáo {{CATEGORY}} (Đường cơ sở - Thực tế) {{UNIT}}',
      'Reporting Period Decreased CATEGORY (Baseline - Actual) UNIT':
        'Kỳ báo cáo giảm {{CATEGORY}} (Đường cơ sở - Thực tế) {{UNIT}}',
      'Reporting Period Saving CATEGORY VALUE UNIT': 'Tiết kiệm kỳ báo cáo {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Saving CATEGORY VALUE UNIT': 'Tiết kiệm thời gian cơ bản {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY UNIT':
        'Kỳ báo cáo Tiêu thụ đồng hồ chính {{CATEGORY}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY VALUE UNIT':
        'Kỳ báo cáo Tiêu thụ đồng hồ chính {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY UNIT': 'Kỳ báo cáo Tiêu thụ công tơ phụ {{CATEGORY}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY VALUE UNIT':
        'Kỳ báo cáo Tiêu thụ công tơ phụ {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Difference CATEGORY UNIT': 'Chênh lệch kỳ báo cáo {{CATEGORY}} {{UNIT}}',
      'Reporting Period Percentage Difference': 'Chênh lệch phần trăm kỳ báo cáo',
      'Reporting Period Difference CATEGORY VALUE UNIT': 'Chênh lệch kỳ báo cáo {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Trend Values': 'Giá trị xu hướng',
      'METER CATEGORY VALUE UNIT': '{{METER}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      //FDD
      'Fault List': 'Danh sách lỗi',
      'Reporting Period Number of Fault VALUE': 'Kỳ báo cáo Số lỗi {{VALUE}}',
      'All Faults': 'Tất cả lỗi',
      'Space Faults': 'Lỗi không gian',
      'Number of All Faults': 'Số lượng tất cả các lỗi',
      'Number of Space Faults': 'Số lỗi không gian',
      'Total Number of Faults': 'Total Number of Faults',
      'Number of New Faults': 'Number of New Faults',
      'Number of Inprogress Faults': 'Number of Inprogress Faults',
      'Number of Done Faults': 'Number of Done Faults',
      //Data Panels
      'Ton of Standard Coal': 'Tấn than tiêu chuẩn',
      'Ton of Carbon Dioxide Emissions': 'Tấn khí thải carbon dioxide',
      'Operating Characteristic Curve': 'Đường cong đặc tính làm việc',
      Tariff: 'Thuế quan',
      'Detailed Data': 'Dữ liệu chi tiết',
      'Child Spaces Data': 'Dữ liệu không gian trẻ em',
      'Associated Equipment Data': 'Dữ liệu thiết bị liên quan',
      'This Year': 'Năm nay',
      'The Same Period Last Year': 'cùng kỳ năm ngoái',
      'This Month': 'Tháng này',
      'This Day': 'Ngày này',
      Total: 'Tất cả',
      'No data found': 'Không tìm thấy dữ liệu',
      Export: 'Xuất khẩu',
      Datetime: 'Ngày giờ',
      'Time-Of-Use Type': 'Loại thời gian sử dụng',
      'Top-Peak': 'Đỉnh cao nhất',
      'On-Peak': 'Vào giờ cao điểm',
      'Mid-Peak': 'Giữa giờ cao điểm',
      'Off-Peak': 'Ngoài giờ cao điểm',
      Percentage: 'Tỷ lệ phần trăm',
      //Realtime Chart
      'Trend in the last hour of Energy Value Point': 'Xu hướng trong giờ cuối cùng của Điểm giá trị năng lượng',
      Circuit: 'Mạch',
      Point: 'Điểm',
      'Realtime Value': 'Giá trị thời gian thực',
      //Meter Tracking
      'Meter List': 'Danh sách đồng hồ đo',
      'Edit Meter': 'Biên tập',
      'Start Value': 'Giá trị bắt đầu',
      'End Value': 'Giá trị cuối cùng',
      'Difference Value': 'Giá trị chênh lệch',
      'Start Integrity Rate': 'Tỷ lệ toàn vẹn bắt đầu(%)',
      'End Integrity Rate': 'Tỷ lệ toàn vẹn cuối cùng(%)',
      'Full Integrity Rate': 'Tỷ lệ toàn vẹn đầy đủ(%)',
      //Equipment Tracking
      'Equipment List': 'Danh sách thiết bị',
      'Edit Equipment': 'Biên tập',
      //Profile Dropdown
      Feedback: 'Phản hồi',
      'Account Settings': 'Cài đặt tài khoảntrở lại',
      Logout: 'Đăng xuất',
      //Authentication
      'Log in': 'Đăng nhập',
      'Email address': 'Địa chỉ email',
      Password: 'Mật khẩu',
      CaptchaCode: 'Captcha',
      Refresh: 'Làm tươi',
      'Remember me': 'Ghi nhớ tôi',
      'Logged in as ': 'Đăng nhập với tư cách ',
      'Forgot Password?': 'Quên mật khẩu?',
      'Forgot your password?': 'Quên Mật khẩu?',
      "Enter your email and we'll send you a reset link":
        'Nhập email của bạn và chúng tôi sẽ gửi cho bạn liên kết đặt lại',
      "Enter your email and we'll send you a registration link":
        'Nhập email của bạn và chúng tôi sẽ gửi cho bạn liên kết đăng ký',
      'Reset Password': 'Đặt lại mật khẩu',
      'Thanks for using DoraEOS!': 'Cảm ơn bạn đã sử dụng DoraEOS!',
      'You are now successfully signed out': 'Bây giờ bạn đã đăng xuất thành công',
      'Return to Login': 'Quay lại Đăng nhập',
      'Please check your email!': 'Vui lòng kiểm tra email của bạn!',
      'An email has been sent to ': 'Một email đã được gửi đến ',
      'Please click on the included link to reset your password':
        'Vui lòng nhấp vào liên kết đi kèm để đặt lại mật khẩu của bạn',
      'Please click on the included link to register your account':
        'Vui lòng nhấp vào liên kết đi kèm để đăng ký tài khoản của bạn',
      'An email with password reset link is sent to ': 'Một email có liên kết đặt lại mật khẩu được gửi đến ',
      'Change Password': 'Thay đổi mật khẩu',
      'Old Password': 'Mật khẩu cũ',
      'New Password': 'Mật khẩu mới',
      'Confirm Password': 'Xác nhận mật khẩu',
      'Update Password': 'Cập nhật mật khẩu',
      'Password has been changed!': 'Mật khẩu đã được thay đổi!',
      'Verification code has been sent to your inbox. \
      Please copy it to the input box below..':
        'Mã xác minh đã được gửi đến hộp thư đến của bạn. \
      Vui lòng sao chép nó vào hộp nhập liệu bên dưới.',
      'New to DoraEOS': 'Bạn mới sử dụng DoraEOS',
      'Create an account': 'Tạo tài khoản',
      'EMAIL Account registration successful': '{{EMAIL}} Đăng ký tài khoản thành công',
      'Thanks for verifying your account!': 'Cảm ơn bạn đã xác minh tài khoản của bạn!',
      'Your code is': 'Mã của bạn là',
      'Send verification code': 'Gửi mã xác minh',
      'Please wait for NUMBER seconds': 'Vui lòng chờ đợi {{NUMBER}} Giây',
      'Password reset': 'Đặt lại mật khẩu',
      'Please wait for approval': 'Vui lòng chờ phê duyệt',
      //notification
      Notifications: 'Thông báo',
      'Mark all as read': 'Đánh dấu tất cả là đã đọc',
      'View all': 'Tất cả',
      'Notification New': 'MỚI',
      'Notification Earlier': 'TRƯỚC',
      'Notification List': 'Danh sách thông báo',
      'Bulk actions': 'Hành động hàng loạt',
      'Notification Subject': 'Chủ đề',
      'Notification Created Datetime': 'Ngày giờ đã tạo',
      'Notification Priority': 'Ưu tiên',
      'Notification Message': 'Thông điệp',
      'Notification Status': 'Tình trạng',
      'Notification Mark As Read': 'Đánh dấu là đã đọc',
      'Notification Mark As Acknowledged': 'Thừa nhận',
      'Notification Low': 'Thấp',
      'Notification Medium': 'Đau vừa',
      'Notification High': 'Cao',
      'Notification Critical': 'Nguy kịch',
      'Notification Unread': 'Unread',
      'Notification Read': 'Đọc',
      'Notification Acknowledged': 'Thừa nhận',
      'Notification Archive': 'Lưu trữ',
      'Notification Delete': 'Xóa',
      'Notification Apply': 'Áp dụng',
      'Notification Start Datetime': 'Ngày giờ bắt đầu',
      'Notification End Datetime': 'Ngày kết thúc',
      'Notification Update Datetime': 'Cập nhật ngày giờ',
      //Monitoring
      'Run Commands': 'Chạy lệnh',
      'Fault Alarms': 'Báo động lỗi',
      'Instantaneous Efficiency VALUE UNIT': 'Hiệu quả tức thời {{VALUE}} {{UNIT}}',
      'Communication Online': 'Trực tuyến',
      'Communication Offline': 'Ngoại tuyến',
      'Equipment Status': 'Trạng thái thiết bị',
      'Equipment Running': 'Chạy',
      'Equipment Stopped': 'Dừng lại',
      'Show Up to': 'Hiển thị lên đến',
      All: 'Tất cả',
      'FROM - TO of TOTAL': '{{FROM}} - {{TO}} của {{TOTAL}} Thiết bị',
      //Microgrid
      'PCS Run State': 'Trạng thái hoạt động PCS',
      'PCS Unknown': 'Không rõ',
      'PCS Initializing': 'Đang khởi tạo',
      'PCS Standby': 'Chờ',
      'PCS Shutdown': 'Tắt máy',
      'PCS Fault': 'Lỗi',
      'PCS Running': 'Chạy',
      'Battery Power': 'Năng lượng pin',
      'Photovoltaic Power': 'Công suất PV',
      'Grid Power': 'Điện lưới',
      'Load Power': 'Tải điện',
      Reporting: 'Báo cáo',
      Maintenance: 'Sửa chữa',
      'Serial Number': 'Số sê-ri',
      Address: 'Địa chỉ',
      'Postal Code': 'Mã bưu điện',
      'Rated Capacity': 'Công suất định mức',
      'Rated Power': 'Công suất định mức',
      Latitude: 'Vĩ độ',
      Longitude: 'Kinh độ',
      'Strategy Management': 'Quản lý chiến lược',
      'Charging Schedule': 'Lịch sạc',
      'Charge Start Time': 'Thời gian bắt đầu sạc',
      'Charge End Time': 'Thời gian sạc kết thúc',
      'Discharge Start Time': 'Thời gian bắt đầu xả',
      'Discharge End Time': 'Thời gian kết thúc xả',
      'Total Rated Power': 'Tổng công suất định mức',
      'Total Rated Capacity': 'Tổng công suất định mức',
      "Today's Charge": "Số tiền tính phí hôm nay",
      "Today's Discharge": "Hôm nay xuất viện",
      "Today's Generation": "Sản xuất điện ngày nay",
      'Total Charge': 'Tổng phí',
      'Total Discharge': 'tổng lượng xả',
      'Total Generation': 'Tổng thế hệ',
      'Total Revenue': 'Tổng doanh thu',
      'Total Efficiency': 'Tổng hiệu suất',
      'Discharge Achievement Rate': 'Tỷ lệ thành tích xả',
      // Revenue Indicator
      "Today's Cost": "Today's Cost",
      "Today's Income": "Today's Income",
      "Total Cost": "Total Cost",
      "Total Income": "Total Income",
      "Today's Revenue": "Today's Revenue",

      'Revenue Ranking': 'Xếp hạng doanh thu',
      'Efficiency Ranking': 'Xếp hạng hiệu quả',
      'Charge Ranking': 'Xếp hạng công suất sạc',
      'Discharge Ranking': 'Xếp hạng xả thải',
      'Number of Microgrids': 'Số lượng lưới điện siêu nhỏ',
      'Number of Power Stations': 'Số lượng trạm điện',
      'Microgrid List': 'Danh sách lưới điện siêu nhỏ',
      'Previous Page': 'Trang trước',
      'Next Page': 'Trang tiếp theo',
      '7 Days': '7 ngày',
      'Charge UNIT': 'Thù lao {{UNIT}}',
      'Discharge UNIT': 'phóng điện {{UNIT}}',
      'Generation UNIT': 'tạo ra điện {{UNIT}}',
      'Energy Indicator': 'Năng lượng',
      'Revenue Indicator': 'Thu nhập',
      'Carbon Indicator': 'Cacbon',

      // Energy Storage Power Station
      'Battery Operating State': 'Trạng thái hoạt động của pin',
      'Battery State': 'Trạng thái pin',
      'Battery Unknown': 'Không rõ',
      'Battery Stopped': 'Đã dừng',
      'Battery Reserved': 'Reserved', // 0
      'Battery Fault': 'Lỗi', // 1
      'Battery Warning': 'Cảnh báo', // 2
      'Battery Standby': 'Phụ tùng', // 3
      'Battery Prohibit DisCharging': 'Không gỡ cài đặt', // 4
      'Battery Prohibit Charging': 'Không sạc', // 5
      'Battery Normal': 'Đặc trưng', // 6
      'Battery Charging': 'Đang sạc', // 7
      'Battery Discharging': 'Đang xả', // 8
      'Battery Idle': 'Idle', // 9
      'Phase of Lifecycle': 'Giai đoạn vòng đời',
      'Use Phase': 'Giai đoạn sử dụng',
      'Commissioning Phase': 'Giai đoạn gỡ lỗi',
      'Installation Phase': 'Giai đoạn cài đặt',
      'Device Status': 'Trạng thái thiết bị',
      'Basic Information': 'Thông tin cơ bản',
      'Gateway': 'Gateway',
      'Grid Meter': 'Grid Meter',
      'Load Meter': 'Load Meter',
      'Total Active Power': 'Total Active Power',
      'Active Power A': 'Active Power A',
      'Active Power B': 'Active Power B',
      'Active Power C': 'Active Power C',
      'Total Reactive Power': 'Total Reactive Power',
      'Reactive Power A': 'Reactive Power A',
      'Reactive Power B': 'Reactive Power B',
      'Reactive Power C': 'Reactive Power C',
      'Total Apparent Power': 'Total Apparent Power',
      'Apparent Power A': 'Apparent Power A',
      'Apparent Power B': 'Apparent Power B',
      'Apparent Power C': 'Apparent Power C',
      'Total Power Factor': 'Total Power Factor',
      'Active Energy Import': 'Active Energy Import',
      'Active Energy Export': 'Active Energy Export',
      'Active Energy Net': 'Active Energy Net',

      // Photovoltaic Power Station
      'Invertor': 'biến tần',
      'Invertor Run State': 'Trạng thái hoạt động',
      'Invertor Unknown': 'Không rõ',
      'Invertor Initializing': 'Đang khởi tạo',
      'Invertor Standby': 'Chờ',
      'Invertor Shutdown': 'Tắt máy',
      'Invertor Fault': 'Lỗi',
      'Invertor Running': 'Chạy',

      //Advanced Reporting & Knowledge base
      'Created Datetime': 'Đã tạo Datetime',
      'File Format': 'Định dạng tệp',
      'File Size': 'Kích thước tệp',
      Uploader: 'Trình tải lên',
      //Error
      "The page you're looking for is not found": 'Không tìm thấy trang bạn đang tìm kiếm',
      "Make sure the address is correct and that the page hasn't moved. ":
        'Đảm bảo địa chỉ chính xác và trang chưa di chuyển. ',
      'If you think this is a mistake,': 'Nếu bạn nghĩ rằng đây là một sai lầm,',
      'contact us': 'Liên hệ với chúng tôi',
      'Take me home': 'Đưa tôi về nhà',
      'Whoops, something went wrong!': 'Rất tiếc, đã xảy ra sự cố!',
      'Try refreshing the page, or going back and attempting the action again. ':
        'Thử làm mới trang hoặc quay lại và thử lại hành động. ',
      'If this problem persists,': 'Nếu vấn đề này vẫn tiếp diễn,',
      'Captcha Error': 'Lỗi Captcha',
      'Confirm Password Error': 'Mật khẩu xác nhận không khớp',
      'It looks like you clicked on an invalid password reset link. Please tryagain.':
        'Có vẻ như bạn đã nhấp vào \
      liên kết đặt lại mật khẩu không hợp lệ. Vui lòng thử lại.',
      'It looks like you clicked on an invalid registration account link. Please tryagain.':
        'Có vẻ như bạn đã nhấp vào \
      liên kết tài khoản đăng ký không hợp lệ. Vui lòng thử lại.',
      //Tenant Bill
      'Lease Contract Number': 'Số hợp đồng thuê',
      Download: 'Tải xuống',
      Print: 'In',
      'Payment Notice': 'Thông báo thanh toán',
      'Bill To': 'Lập hóa đơn đến',
      'Bill Number': 'Số hóa đơn',
      'Bill Date': 'Ngày lập hóa đơn',
      'Payment Due Date': 'Ngày đến hạn thanh toán',
      'Amount Payable': 'Số tiền phải trả',
      'Billing Period Start': 'Thời hạn thanh toán Bắt đầu',
      'Billing Period End': 'Kết thúc thời hạn thanh toán',
      Quantity: 'Số lượng',
      Price: 'Giá',
      Unit: 'Đơn vị',
      Amount: 'Lượng',
      Subtotal: 'Tổng phụ',
      'VAT Output Tax': 'Thuế đầu ra VAT',
      'Total Amount Payable': 'Tổng số tiền phải trả',
      'Please make sure to pay on or before the payment due date above':
        'Vui lòng đảm bảo thanh toán vào hoặc trước ngày đến hạn thanh toán ở trên',
      'Send money to the following account': 'Gửi tiền vào tài khoản sau',
      'Account Name': 'Tên tài khoản',
      'Bank Name': 'Tên ngân hàng',
      'Bank Address': 'Địa chỉ ngân hàng',
      'RMB Account': 'Tài khoản RMB',
      // button
      'Toggle Navigation': 'Chuyển đổi điều hướng',
      // working calendar
      'Working Days': 'Ngày làm việc',
      'Non Working Days': 'Ngày không làm việc',
      'Select Row': 'Vui lòng chọn Hàng dữ liệu',
      // Offline Meter Input
      'Daily Value': 'Giá trị hàng ngày',
      Date: 'Ngày',
      'Successfully Saved': 'Đã lưu thành công',
      'Previous Data Is Empty': 'Dữ liệu trước đó trống',
      // API
      'API.ACCOUNT_UNLOCK_FAILED': 'Account unlock failed',
      'API.ADMINISTRATOR_SESSION_NOT_FOUND': 'Administrator session not found',
      'API.ADMINISTRATOR_SESSION_TIMEOUT': 'Administrator session timeout',
      'API.ADVANCED_REPORT_NOT_FOUND': 'Advanced report not found',
      'API.API_KEY_HAS_EXPIRED': 'Key has expired',
      'API.API_KEY_NAME_IS_ALREADY_IN_USE': 'Key name is already in use',
      'API.API_KEY_NOT_FOUND': 'Key not found',
      'API.BAD_REQUEST': 'Bad request',
      'API.BALANCING_PRICE_POINT_NOT_FOUND': 'Balancing price point not found',
      'API.BATTERY_STATE_POINT_NOT_FOUND': 'Battery state point not found',
      'API.BUY_METER_NOT_FOUND': 'Buy meter not found',
      'API.CANNOT_SET_EXISTING_SUBMETER_AS_MASTER_METER': 'Cannot set existing submeter as master meter',
      'API.CHARGE_METER_NOT_FOUND': 'Charge meter not found',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_EXISTS': 'Combined equipment command relation exists',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Combined equipment command relation not found',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_EXISTS': 'Combined equipment equipment relation exists',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_NOT_FOUND': 'Combined equipment equipment relation not found',
      'API.COMBINED_EQUIPMENT_METER_RELATION_EXISTS': 'Combined equipment meter relation exists',
      'API.COMBINED_EQUIPMENT_METER_RELATION_NOT_FOUND': 'Combined equipment meter relation not found',
      'API.COMBINED_EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Combined equipment name is already in use',
      'API.COMBINED_EQUIPMENT_NOT_FOUND': 'Combined equipment not found',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Combined equipment offline meter relation exists',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Combined equipment offline meter relation not found',
      'API.COMBINED_EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Combined equipment parameter name is already in use',
      'API.COMBINED_EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Combined equipment parameter not found or not match',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Combined equipment virtual meter relation exists',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Combined equipment virtual meter relation not found',
      'API.COMMAND_NAME_IS_ALREADY_IN_USE': 'Command name is already in use',
      'API.COMMAND_NOT_FOUND': 'Command not  found',
      'API.CONTACT_NAME_IS_ALREADY_IN_USE': 'Contact name is already in use',
      'API.CONTACT_NOT_FOUND': 'Contact not found',
      'API.COOLING_METER_NOT_FOUND': 'Cooling meter not found',
      'API.COST_CENTER_EXTERNAL_ID_EXISTS': 'Cost center external id exists',
      'API.COST_CENTER_NAME_EXISTS': 'Cost center name exists',
      'API.COST_CENTER_NOT_FOUND': 'Cost center not found',
      'API.COST_FILE_NOT_FOUND': 'Cost file not found',
      'API.DATA_REPAIR_FILE_NOT_FOUND': 'Data repair file not found',
      'API.DATA_SOURCE_NAME_IS_ALREADY_IN_USE': 'Data source name is already in use',
      'API.DATA_SOURCE_NOT_FOUND': 'Data source not found',
      'API.DATABASE_ERROR': 'Database error',
      'API.DATE_IS_ALREADY_IN_WORKING_CALENDAR': 'Date Is Already In Working Calendar',
      'API.DATE_LOCAL_NOT_FOUND': 'Date local not found',
      'API.DISCHARGE_METER_NOT_FOUND': 'Discharge meter not found',
      'API.DISTRIBUTION_CIRCUIT_NAME_IS_ALREADY_IN_USE': 'Distribution circuit name is already in use',
      'API.DISTRIBUTION_CIRCUIT_NOT_FOUND': 'Distribution circuit not found',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_EXISTS': 'Distribution circuit point relation exists',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_NOT_FOUND': 'Distribution circuit point relation not found',
      'API.DISTRIBUTION_SYSTEM_NAME_IS_ALREADY_IN_USE': 'Distribution system name is already in use',
      'API.DISTRIBUTION_SYSTEM_NOT_FOUND': 'Distribution system not found',
      'API.ELECTRICITY_METER_NOT_FOUND': 'Electricity meter not found',
      'API.EMAIL_IS_ALREADY_IN_USE': 'Email is already in use',
      'API.EMAIL_MESSAGE_NOT_FOUND': 'Email message not found',
      'API.EMAIL_NOT_FOUND': 'Email not found',
      'API.EMAIL_SERVER_HOST_IS_ALREADY_IN_USE': 'Email server host is already in use',
      'API.EMAIL_SERVER_NOT_FOUND': 'Email server not found',
      'API.EMPTY_VARIABLES_ARRAY': 'Empty variables array',
      'API.ENERGY_CATEGORY_NAME_IS_ALREADY_IN_USE': 'Energy category name is already in use',
      'API.ENERGY_CATEGORY_NOT_FOUND': 'Energy category not found',
      'API.ENERGY_CATEGORY_USED_IN_ENERGY_ITEMS': 'Energy category used in energy items',
      'API.ENERGY_CATEGORY_USED_IN_METER': 'Energy category used in meter',
      'API.ENERGY_CATEGORY_USED_IN_OFFLINE_METER': 'Energy category used in offline meter',
      'API.ENERGY_CATEGORY_USED_IN_TARIFFS': 'Energy category used in tariffs',
      'API.ENERGY_CATEGORY_USED_IN_VIRTUAL_METER': 'Energy category used in virtual meter',
      'API.ENERGY_FLOW_DIAGRAM_LINK_IS_ALREADY_IN_USE': 'Energy flow diagram link is already in use',
      'API.ENERGY_FLOW_DIAGRAM_LINK_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram link not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram node name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram node not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NOT_FOUND': 'Energy flow diagram not found',
      'API.ENERGY_ITEM_DOES_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item does not belong to energy category',
      'API.ENERGY_ITEM_IS_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item is not belong to energy category',
      'API.ENERGY_ITEM_NAME_IS_ALREADY_IN_USE': 'Energy item name is already in use',
      'API.ENERGY_ITEM_NOT_FOUND': 'Energy item not found',
      'API.ENERGY_ITEM_USED_IN_METER': 'Energy item used in meter',
      'API.ENERGY_ITEM_USED_IN_OFFLINE_METER': 'Energy item used in offline meter',
      'API.ENERGY_ITEM_USED_IN_VIRTUAL_METER': 'Energy item used in virtual meter',
      'API.ENERGY_STORAGE_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Energy storage power station name is already in use',
      'API.ENERGY_STORAGE_POWER_STATION_NOT_FOUND': 'Energy storage power station not found',
      'API.EQUIPMENT_COMMAND_RELATION_EXISTS': 'Equipment command relation exists',
      'API.EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Equipment command relation not found',
      'API.EQUIPMENT_METER_RELATION_EXISTS': 'Equipment meter relation exists',
      'API.EQUIPMENT_METER_RELATION_NOT_FOUND': 'Equipment meter relation not found',
      'API.EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Equipment name is already in use',
      'API.EQUIPMENT_NOT_FOUND': 'Equipment not found',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Equipment offline meter relation exists',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Equipment offline meter relation not found',
      'API.EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Equipment parameter name is already in use',
      'API.EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Equipment parameter not found or not match',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Equipment virtual meter relation exists',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Equipment virtual meter relation not found',
      'API.ERROR': 'Error',
      'API.EXCEPTION': 'Exception',
      'API.FAILED_TO_READ_REQUEST_STREAM': 'Failed to read request stream',
      'API.FAILED_TO_RESTORE_COST_FILE': 'Failed to restore cost file',
      'API.FAILED_TO_RESTORE_DATA_REPAIR_FILE': 'Failed to restore data repair file',
      'API.FAILED_TO_RESTORE_KNOWLEDGE_FILE': 'Failed to restore knowledge file',
      'API.FAILED_TO_RESTORE_OFFLINE_METER_FILE': 'Failed to restore offline meter file',
      'API.FAILED_TO_SAVE_OFFLINE_METER_FILE': 'Failed to save offline meter file',
      'API.FAILED_TO_UPLOAD_ATTACHMENT_FILE': 'Failed to upload attachment file',
      'API.FAILED_TO_UPLOAD_COST_FILE': 'Failed to upload cost file',
      'API.FAILED_TO_UPLOAD_DATA_REPAIR_FILE': 'Failed to upload data repair file',
      'API.FAILED_TO_UPLOAD_KNOWLEDGE_FILE': 'Failed to upload knowledge file',
      'API.FAILED_TO_UPLOAD_OFFLINE_METER_FILE': 'Failed to upload offline meter file',
      'API.GATEWAY_NAME_IS_ALREADY_IN_USE': 'Gateway name is already in use',
      'API.GATEWAY_NOT_FOUND': 'Gateway not found',
      'API.HEAT_METER_NOT_FOUND': 'Heat meter not found',
      'API.INVALID_': 'Invalid ',
      'API.INVALID_ACKNOWLEDGE_CODE': 'Invalid acknowledge code',
      'API.INVALID_ADDRESS': 'Invalid address',
      'API.INVALID_ADDRESS_VALUE': 'Invalid address value',
      'API.INVALID_ADVANCED_REPORT_ID': 'Invalid advanced report id',
      'API.INVALID_API_KEY_ID': 'Invalid api key id',
      'API.INVALID_API_KEY_NAME': 'Invalid api key name',
      'API.INVALID_AREA_VALUE': 'Invalid area value',
      'API.INVALID_BALANCING_PRICE_POINT_ID': 'Invalid balancing price point ID',
      'API.INVALID_BASE_PERIOD_END_DATETIME': 'Invalid base period end datetime',
      'API.INVALID_BASE_PERIOD_START_DATETIME': 'Invalid base period start datetime',
      'API.INVALID_BATTERY_STATE_POINT_ID': 'Invalid battery state point ID',
      'API.INVALID_BAUD_RATE': 'Invalid baud rate',
      'API.INVALID_BUILDINGS_VALUE': 'Invalid buildings value',
      'API.INVALID_BUY_METER_ID': 'Invalid buy meter id',
      'API.INVALID_CAPACITY': 'Invalid capacity',
      'API.INVALID_CATEGORY': 'Invalid category',
      'API.INVALID_CHANNEL': 'Invalid channel',
      'API.INVALID_CHARGE_METER_ID': 'Invalid charge meter id',
      'API.INVALID_COMBINED_EQUIPMENT_ID': 'Invalid combined equipment id',
      'API.INVALID_COMBINED_EQUIPMENT_NAME': 'Invalid combined equipment name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_ID': 'Invalid combined equipment parameter id',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_NAME': 'Invalid combined equipment parameter name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_TYPE': 'Invalid combined equipment parameter type',
      'API.INVALID_COMBINED_EQUIPMENT_UUID': 'Invalid combined equipment uuid',
      'API.INVALID_COMMAND_ID': 'Invalid command id',
      'API.INVALID_COMMAND_NAME': 'Invalid command name',
      'API.INVALID_CONNECTION': 'Invalid connection',
      'API.INVALID_CONSTANT_VALUE': 'Invalid constant value',
      'API.INVALID_CONTACT_ID': 'Invalid contact id',
      'API.INVALID_CONTACT_NAME': 'Invalid contact name',
      'API.INVALID_COOLING_METER_ID': 'Invalid cooling meter id',
      'API.INVALID_COST_CENTER_ID': 'Invalid cost center id',
      'API.INVALID_COST_FILE_ID': 'Invalid cost file id',
      'API.INVALID_CREATED_DATETIME': 'Invalid created datetime',
      'API.INVALID_DATA_REPAIR_FILE_ID': 'Invalid data repair file id',
      'API.INVALID_DATA_SOURCE_ID': 'Invalid data source id',
      'API.INVALID_DATA_SOURCE_NAME': 'Invalid data source name',
      'API.INVALID_DATA_SOURCE_PROTOCOL': 'Invalid data source protocol',
      'API.INVALID_DATE_LOCAL': 'Invalid date local',
      'API.INVALID_DENOMINATOR_METER_UUID': 'Invalid denominator meter uuid',
      'API.INVALID_DISCHARGE_METER_ID': 'Invalid discharge meter id',
      'API.INVALID_DISPLAY_NAME': 'Invalid display name',
      'API.INVALID_DISTRIBUTION_CIRCUIT_ID': 'Invalid distribution circuit id',
      'API.INVALID_DISTRIBUTION_CIRCUIT_NAME': 'Invalid distribution circuit name',
      'API.INVALID_DISTRIBUTION_ROOM': 'Invalid distribution room',
      'API.INVALID_DISTRIBUTION_SYSTEM_ID': 'Invalid distribution system id',
      'API.INVALID_DISTRIBUTION_SYSTEM_NAME': 'Invalid distribution system name',
      'API.INVALID_ELECTRICITY_METER_ID': 'Invalid Electricity meter id',
      'API.INVALID_EMAIL': 'Invalid email',
      'API.INVALID_EMAIL_MESSAGE_ID': 'Invalid email message id',
      'API.INVALID_EMAIL_SERVER_HOST': 'Invalid email server host',
      'API.INVALID_EMAIL_SERVER_ID': 'Invalid email server id',
      'API.INVALID_END_DATE_FORMAT': 'Invalid end date format',
      'API.INVALID_END_DATETIME': 'Invalid end datetime',
      'API.INVALID_ENERGY_CATEGORY_ID': 'Invalid energy category id',
      'API.INVALID_ENERGY_CATEGORY_NAME': 'Invalid energy category name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_ID': 'Invalid energy flow diagram id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_LINK_ID': 'Invalid energy flow diagram link id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NAME': 'Invalid energy flow diagram name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_ID': 'Invalid energy flow diagram node id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_NAME': 'Invalid energy flow diagram node name',
      'API.INVALID_ENERGY_ITEM_ID': 'Invalid energy item id',
      'API.INVALID_ENERGY_ITEM_NAME': 'Invalid energy item name',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_ID': 'Invalid energy storage power station ID',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_NAME': 'Invalid energy storage power station name',
      'API.INVALID_EQUATION_IN_EXPRESSION': 'Invalid equation in expression',
      'API.INVALID_EQUIPMENT_ID': 'Invalid equipment id',
      'API.INVALID_EQUIPMENT_NAME': 'Invalid equipment name',
      'API.INVALID_EQUIPMENT_PARAMETER_ID': 'Invalid equipment parameter id',
      'API.INVALID_EQUIPMENT_PARAMETER_NAME': 'Invalid equipment parameter name',
      'API.INVALID_EQUIPMENT_PARAMETER_TYPE': 'Invalid equipment parameter type',
      'API.INVALID_EQUIPMENT_UUID': 'Invalid equipment uuid',
      'API.INVALID_EXPIRES_DATETIME': 'Invalid expires datetime',
      'API.INVALID_EXPRESSION': 'Invalid expression',
      'API.INVALID_EXPRESSION_OBJECT': 'Invalid expression object',
      'API.INVALID_FDD_CODE': 'Invalid fdd code',
      'API.INVALID_FLOORS_VALUE': 'Invalid floors value',
      'API.INVALID_FROM_ADDR': 'Invalid from addr',
      'API.INVALID_GATEWAY_ID': 'Invalid gateway id',
      'API.INVALID_GATEWAY_NAME': 'Invalid gateway name',
      'API.INVALID_HEAT_METER_ID': 'Invalid heat meter id',
      'API.INVALID_HIGH_LIMIT_VALUE': 'Invalid high limit value',
      'API.INVALID_HOURLY_HIGH_LIMIT_VALUE': 'Invalid hourly high limit value',
      'API.INVALID_HOURLY_LOW_LIMIT_VALUE': 'Invalid hourly low limit value',
      'API.INVALID_Id': 'Invalid id',
      'API.INVALID_IS_ADMIN_VALUE': 'Invalid is admin value',
      'API.INVALID_IS_COST_DATA_DISPLAYED': 'Invalid is cost data displayed',
      'API.INVALID_IS_COUNTED_VALUE': 'Invalid is counted value',
      'API.INVALID_IS_ENABLED': 'Invalid is enabled',
      'API.INVALID_IS_HIDDEN': 'Invalid is hidden',
      'API.INVALID_IS_IN_LEASE_VALUE': 'Invalid is in lease value',
      'API.INVALID_IS_INPUT_COUNTED_VALUE': 'Invalid is input counted value',
      'API.INVALID_IS_KEY_TENANT_VALUE': 'Invalid is key tenant value',
      'API.INVALID_IS_OUTPUT_COUNTED_VALUE': 'Invalid is output counted value',
      'API.INVALID_IS_OUTPUT_VALUE': 'Invalid is output value',
      'API.INVALID_IS_READ_ONLY_VALUE': 'Invalid is read only value',
      'API.INVALID_IS_TREND_VALUE': 'Invalid is trend value',
      'API.INVALID_IS_VIRTUAL_VALUE': 'Invalid is virtual value',
      'API.INVALID_KGCE': 'Invalid kgce',
      'API.INVALID_KGCO2E': 'Invalid kgco2e',
      'API.INVALID_KNOWLEDGE_FILE_ID': 'Invalid knowledge file id',
      'API.INVALID_LATITUDE_VALUE': 'Invalid latitude value',
      'API.INVALID_LEASE_NUMBER_VALUE': 'Invalid lease number value',
      'API.INVALID_LONGITUDE_VALUE': 'Invalid longitude value',
      'API.INVALID_LOW_LIMIT_VALUE': 'Invalid low limit value',
      'API.INVALID_MASTER_METER_ID': 'Invalid master meter id',
      'API.INVALID_MENU_ID': 'Invalid menu id',
      'API.INVALID_MESSAGE_DATA': 'Invalid message data',
      'API.INVALID_MESSAGE_TEMPLATE': 'Invalid message template',
      'API.INVALID_MESSAGE_TEMPLATE_ID': 'Invalid message template id',
      'API.INVALID_MESSAGE_VALUE': 'Invalid message value',
      'API.INVALID_METER_ID': 'Invalid meter id',
      'API.INVALID_METER_NAME': 'Invalid meter name',
      'API.INVALID_METER_UUID': 'Invalid meter uuid',
      'API.INVALID_MICROGRID_BATTERY_ID': 'Invalid microgrid battery id',
      'API.INVALID_MICROGRID_BATTERY_NAME': 'Invalid microgrid battery name',
      'API.INVALID_MICROGRID_EVCHARGER_ID': 'Invalid microgrid evcharger id',
      'API.INVALID_MICROGRID_EVCHARGER_NAME': 'Invalid microgrid evcharger name',
      'API.INVALID_MICROGRID_GENERATOR_ID': 'Invalid microgrid generator id',
      'API.INVALID_MICROGRID_GENERATOR_NAME': 'Invalid microgrid generator name',
      'API.INVALID_MICROGRID_GRID_ID': 'Invalid microgrid grid id',
      'API.INVALID_MICROGRID_GRID_NAME': 'Invalid microgrid grid name',
      'API.INVALID_MICROGRID_HEATPUMP_ID': 'Invalid microgrid heatpump id',
      'API.INVALID_MICROGRID_HEATPUMP_NAME': 'Invalid microgrid heatpump name',
      'API.INVALID_MICROGRID_ID': 'Invalid microgrid id',
      'API.INVALID_MICROGRID_LOAD_ID': 'Invalid microgrid load id',
      'API.INVALID_MICROGRID_LOAD_NAME': 'Invalid microgrid load name',
      'API.INVALID_MICROGRID_NAME': 'Invalid microgrid name',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_ID': 'Invalid microgrid photovoltaic ID',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_NAME': 'Invalid microgrid photovoltaic name',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_ID': 'Invalid microgrid power conversion system ID',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_NAME': 'Invalid microgrid microgrid power conversion system name',
      'API.INVALID_MICROGRID_UUID': 'Invalid microgrid UUID',
      'API.INVALID_NAME_VALUE': 'Invalid name value',
      'API.INVALID_NEW_PASSWORD': 'Invalid new password',
      'API.INVALID_NON_WORKING_DAY_ID': 'Invalid non working day ID',
      'API.INVALID_NOTIFICATION_ID': 'Invalid notification id',
      'API.INVALID_NUMERATOR_METER_UUID': 'Invalid numerator meter uuid',
      'API.INVALID_OBJECT_TYPE': 'Invalid object type',
      'API.INVALID_OFFLINE_METER_DATA': 'Invalid offline meter data',
      'API.INVALID_OFFLINE_METER_FILE_ID': 'Invalid offline meter file id',
      'API.INVALID_OFFLINE_METER_ID': 'Invalid offline meter id',
      'API.INVALID_OFFLINE_METER_NAME': 'Invalid offline meter name',
      'API.INVALID_OFFLINE_METER_UUID': 'Invalid offline meter uuid',
      'API.INVALID_OFFSET_CONSTANT_VALUE': 'Invalid offset constant value',
      'API.INVALID_OLD_PASSWORD': 'Invalid old password',
      'API.INVALID_OPENID': 'Invalid openid',
      'API.INVALID_PARENT_SPACE_ID': 'Invalid parent space id',
      'API.INVALID_PASSWORD': 'Invalid password',
      'API.INVALID_PAYLOAD': 'Invalid payload',
      'API.INVALID_PEAK_CURRENT': 'Invalid peak current',
      'API.INVALID_PEAK_LOAD': 'Invalid peak load',
      'API.INVALID_PERIOD_TYPE': 'Invalid period type',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_ID': 'Invalid photovoltaic power station ID',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_NAME': 'Invalid photovoltaic power station name',
      'API.INVALID_POINT_ID': 'Invalid point id',
      'API.INVALID_POINT_NAME': 'Invalid point name',
      'API.INVALID_PORT': 'Invalid port',
      'API.INVALID_POSTAL_CODE_VALUE': 'Invalid postal code value',
      'API.INVALID_POWER_POINT_ID': 'Invalid power point id',
      'API.INVALID_PRIORITY': 'Invalid priority',
      'API.INVALID_PRIVILEGE': 'Invalid privilege',
      'API.INVALID_PRIVILEGE_DATA': 'Invalid privilege data',
      'API.INVALID_PRIVILEGE_ID': 'Invalid privilege id',
      'API.INVALID_PRIVILEGE_NAME': 'Invalid privilege name',
      'API.INVALID_RATIO_VALUE': 'Invalid ratio value',
      'API.INVALID_RECIPIENT_EMAIL': 'Invalid recipient email',
      'API.INVALID_RECIPIENT_MOBILE': 'Invalid recipient mobile',
      'API.INVALID_RECIPIENT_NAME': 'Invalid recipient name',
      'API.INVALID_RECIPIENT_OPENID': 'Invalid recipient openid',
      'API.INVALID_REPLY': 'Invalid reply',
      'API.INVALID_REPORTING_PERIOD_END_DATETIME': 'Invalid reporting period end datetime',
      'API.INVALID_REPORTING_PERIOD_START_DATETIME': 'Invalid reporting period start datetime',
      'API.INVALID_REQUIRES_AUTHENTICATION': 'Invalid requires authentication',
      'API.INVALID_ROOMS_VALUE': 'Invalid rooms value',
      'API.INVALID_RULE_ID': 'Invalid rule id',
      'API.INVALID_RULE_NAME': 'Invalid rule name',
      'API.INVALID_RUN_STATE_POINT_ID': 'Invalid run state point ID',
      'API.INVALID_SCHEDULED_DATETIME': 'Invalid scheduled datetime',
      'API.INVALID_SELL_METER_ID': 'Invalid sell meter id',
      'API.INVALID_SENSOR_ID': 'Invalid sensor id',
      'API.INVALID_SENSOR_NAME': 'Invalid sensor name',
      'API.INVALID_SENSOR_UUID': 'Invalid sensor uuid',
      'API.INVALID_SERIAL_NUMBER': 'Invalid serial number',
      'API.INVALID_SERIAL_PORT': 'Invalid serial port',
      'API.INVALID_SESSION_PLEASE_RE_LOGIN': 'Invalid session please re-login',
      'API.INVALID_SET_VALUE': 'Invalid set value',
      'API.INVALID_SHOPFLOOR_ID': 'Invalid shopfloor id',
      'API.INVALID_SHOPFLOOR_NAME': 'Invalid shopfloor name',
      'API.INVALID_SHOPFLOOR_UUID': 'Invalid shopfloor uuid',
      'API.INVALID_SOURCE_NODE_ID': 'Invalid source node id',
      'API.INVALID_SPACE_ID': 'Invalid space id',
      'API.INVALID_SPACE_NAME': 'Invalid space name',
      'API.INVALID_SPACE_UUID': 'Invalid space uuid',
      'API.INVALID_START_DATE_FORMAT': 'Invalid start date format',
      'API.INVALID_START_DATETIME': 'Invalid start datetime',
      'API.INVALID_STATUS': 'Invalid status',
      'API.INVALID_STORE_ID': 'Invalid store id',
      'API.INVALID_STORE_NAME': 'Invalid store name',
      'API.INVALID_STORE_TYPE_DESCRIPTION': 'Invalid store type description',
      'API.INVALID_STORE_TYPE_ID': 'Invalid store type id',
      'API.INVALID_STORE_TYPE_NAME': 'Invalid store type name',
      'API.INVALID_STORE_TYPE_SIMPLIFIED_CODE': 'Invalid store type simplified code',
      'API.INVALID_STORE_UUID': 'Invalid store uuid',
      'API.INVALID_SUBJECT_VALUE': 'Invalid subject value',
      'API.INVALID_SVG': 'Invalid SVG',
      'API.INVALID_SWITCHGEAR': 'Invalid switchgear',
      'API.INVALID_TARGET_NODE_ID': 'Invalid target node id',
      'API.INVALID_TARIFF_BLOCK_PRICING': 'Invalid tariff block pricing',
      'API.INVALID_TARIFF_ID': 'Invalid tariff id',
      'API.INVALID_TARIFF_TIME_OF_USE_PRICING': 'Invalid tariff time of use pricing',
      'API.INVALID_TARIFF_TYPE': 'Invalid tariff type',
      'API.INVALID_TENANT_ID': 'Invalid tenant id',
      'API.INVALID_TENANT_NAME': 'Invalid tenant name',
      'API.INVALID_TENANT_TYPE_DESCRIPTION': 'Invalid tenant type description',
      'API.INVALID_TENANT_TYPE_ID': 'Invalid tenant type id',
      'API.INVALID_TENANT_TYPE_NAME': 'Invalid tenant type name',
      'API.INVALID_TENANT_TYPE_SIMPLIFIED_CODE': 'Invalid tenant type simplified code',
      'API.INVALID_TENANT_UUID': 'Invalid tenant uuid',
      'API.INVALID_TEXT_MESSAGE_ID': 'Invalid text message id',
      'API.INVALID_TIMEZONE_ID': 'Invalid timezone id',
      'API.INVALID_TOKEN': 'Invalid token',
      'API.INVALID_TOPIC': 'Invalid topic',
      'API.INVALID_UNIT_OF_MEASURE': 'Invalid unit of measure',
      'API.INVALID_UNIT_OF_PRICE': 'Invalid unit of price',
      'API.INVALID_UNITS': 'Invalid units',
      'API.INVALID_USER_ID': 'Invalid user id',
      'API.INVALID_USER_NAME': 'Invalid user name',
      'API.INVALID_USER_NAME_OR_EMAIL': 'Invalid user name or email',
      'API.INVALID_USER_PHONE': 'Invalid user phone',
      'API.INVALID_USER_PLEASE_RE_LOGIN': 'Invalid user please re-login',
      'API.INVALID_USER_UUID': 'Invalid user uuid',
      'API.INVALID_USERNAME': 'Invalid userName',
      'API.INVALID_VARIABLE_METER_ID': 'Invalid variable meter id',
      'API.INVALID_VARIABLE_METER_TYPE': 'Invalid variable meter type',
      'API.INVALID_VARIABLE_NAME': 'Invalid variable name',
      'API.INVALID_VERIFICATION_CODE': 'Invalid verification code',
      'API.INVALID_VIRTUAL_METER_ID': 'Invalid virtual meter id',
      'API.INVALID_VIRTUAL_METER_NAME': 'Invalid virtual meter name',
      'API.INVALID_VIRTUAL_METER_UUID': 'Invalid virtual meter uuid',
      'API.INVALID_WEB_MESSAGE_ID': 'Invalid web message id',
      'API.INVALID_WECHAT_MESSAGE_ID': 'Invalid wechat message id',
      'API.INVALID_WIND_FARM_ID': 'Invalid wind farm ID',
      'API.INVALID_WIND_FARM_NAME': 'Invalid wind farm name',
      'API.INVALID_WORKING_CALENDAR_ID': 'Invalid working calendar id ',
      'API.INVALID_WORKING_CALENDAR_NAME': 'Invalid working calendar name',
      'API.KNOWLEDGE_FILE_CANNOT_BE_REMOVED_FROM_DISK': 'Knowledge File Cannot be Removed from Disk',
      'API.KNOWLEDGE_FILE_NOT_FOUND': 'Knowledge file not found',
      'API.MASTER_METER_DOES_NOT_BELONG_TO_SAME_ENERGY_CATEGORY':
        'Master meter does not belong to same energy category',
      'API.MASTER_METER_NOT_FOUND': 'Master meter not found',
      'API.MENU_NOT_FOUND': 'Menu not found',
      'API.METER_CANNOT_HAVE_MORE_THAN_ONE_ENERGY_VALUE_POINTS': 'Meter cannot have more than one energy value points',
      'API.METER_COMMAND_RELATION_EXISTS': 'Meter command relation exists',
      'API.METER_COMMAND_RELATION_NOT_FOUND': 'Meter command relation not found',
      'API.METER_NAME_IS_ALREADY_IN_USE': 'Meter name is already in use',
      'API.METER_NOT_FOUND': 'Meter not found',
      'API.METER_OF_VARIABLE_NOT_FOUND': 'Meter of variable not found',
      'API.METER_POINT_RELATION_EXISTS': 'Meter point relation exists',
      'API.METER_POINT_RELATION_NOT_FOUND': 'Meter point relation not found',
      'API.MICROGRID_BATTERY_NAME_IS_ALREADY_IN_USE': 'Microgrid battery name is already in use',
      'API.MICROGRID_BATTERY_NOT_FOUND': 'Microgrid battery not found',
      'API.MICROGRID_EVCHARGER_NAME_IS_ALREADY_IN_USE': 'Microgrid evcharger name is already in use',
      'API.MICROGRID_EVCHARGER_NOT_FOUND': 'Microgrid evcharger not fund',
      'API.MICROGRID_GENERATOR_NAME_IS_ALREADY_IN_USE': 'Microgrid generator name is already in use',
      'API.MICROGRID_GENERATOR_NOT_FOUND': 'Microgrid generator not found',
      'API.MICROGRID_GRID_NAME_IS_ALREADY_IN_USE': 'Microgrid grid name is already in use',
      'API.MICROGRID_GRID_NOT_FOUND': 'Microgrid grid not found',
      'API.MICROGRID_HEATPUMP_NAME_IS_ALREADY_IN_USE': 'Microgrid heatpump name is already in use',
      'API.MICROGRID_HEATPUMP_NOT_FOUND': 'Microgrid heatpump not found',
      'API.MICROGRID_LOAD_NAME_IS_ALREADY_IN_USE': 'Microgrid load name is already',
      'API.MICROGRID_LOAD_NOT_FOUND': 'Microgrid load not found',
      'API.MICROGRID_NAME_IS_ALREADY_IN_USE': 'Microgrid name is already in use',
      'API.MICROGRID_NOT_FOUND': 'Microgrid not found',
      'API.MICROGRID_PHOTOVOLTAIC_NAME_IS_ALREADY_IN_USE': 'Microgrid photovoltaic name is already in use',
      'API.MICROGRID_PHOTOVOLTAIC_NOT_FOUND': 'Microgrid photovoltaic not found',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NAME_IS_ALREADY_IN_USE':
        'Microgrid power conversion system name is already in use',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NOT_FOUND': 'Microgrid power conversion system not found',
      'API.MICROGRID_SENSOR_RELATION_EXISTS': 'Microgrid sensor relation exists',
      'API.MICROGRID_SENSOR_RELATION_NOT_FOUND': 'Microgrid sensor not found',
      'API.MQTT_CONNECTION_ERROR': 'Mqtt connection error',
      'API.MQTT_PUBLISH_ERROR': 'Mqtt publish error',
      'API.NEW_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'New password length cannot exceed 100 characters',
      'API.NEW_USER_SESSION_NOT_FOUND': 'New user session not found',
      'API.NEW_USER_SESSION_TIMEOUT': 'New user session timeout',
      'API.NON_WORKING_DAY_NOT_FOUND': 'Non working day non found',
      'API.NOT_FOUND': 'Not found',
      'API.NOTIFICATION_NOT_FOUND': 'Notification not found',
      'API.OFFLINE_METER_FILE_NOT_FOUND': 'Offline meter file not found',
      'API.OFFLINE_METER_NAME_IS_ALREADY_IN_USE': 'Offline meter name is already in use',
      'API.OFFLINE_METER_NOT_FOUND': 'Offline meter not found',
      'API.OFFLINE_METER_OF_VARIABLE_NOT_FOUND': 'Offline meter of variable not found',
      'API.OLD_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Old password length cannot exceed 100 characters',
      'API.PARENT_SPACE_NOT_FOUND': 'Parent space not found',
      'API.PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Password length cannot exceed 100 characters',
      'API.PASSWORDS_MATCH': 'New password is the same as the old password',
      'API.PHOTOVOLTAIC_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Photovoltaic power station name is already in use',
      'API.PHOTOVOLTAIC_POWER_STATION_NOT_FOUND': 'Photovoltaic power station not found',
      'API.POINT_NAME_IS_ALREADY_IN_USE': 'Point name is already in use',
      'API.POINT_NOT_FOUND': 'Point not found',
      'API.POWER_POINT_NOT_FOUND': 'Power point not found',
      'API.PRIVILEGE_NAME_IS_ALREADY_IN_USE': 'Privilege name is already in use',
      'API.PRIVILEGE_NOT_FOUND': 'Privilege not found',
      'API.RULE_NAME_IS_ALREADY_IN_USE': 'Rule name is already in use',
      'API.RULE_NOT_FOUND': 'Rule not found',
      'API.SELL_METER_NOT_FOUND': 'Sell meter not found',
      'API.SENSOR_NAME_IS_ALREADY_IN_USE': 'Sensor name is already in use',
      'API.SENSOR_NOT_FOUND': 'Sensor not found',
      'API.SENSOR_POINT_RELATION_EXISTS': 'Sensor point relation exists',
      'API.SENSOR_POINT_RELATION_NOT_FOUND': 'Sensor point relation not found',
      'API.SHOPFLOOR_COMMAND_RELATION_EXISTS': 'Shopfloor command relation exists',
      'API.SHOPFLOOR_COMMAND_RELATION_NOT_FOUND': 'Shopfloor command relation not found',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_EXISTS': 'Shopfloor equipment relation exists',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_NOT_FOUND': 'Shopfloor equipment relation not found',
      'API.SHOPFLOOR_METER_RELATION_EXISTS': 'Shopfloor meter relation exists',
      'API.SHOPFLOOR_METER_RELATION_NOT_FOUND': 'Shopfloor meter relation not found',
      'API.SHOPFLOOR_NAME_IS_ALREADY_IN_USE': 'Shopfloor name is already in use',
      'API.SHOPFLOOR_NOT_FOUND': 'Shopfloor not found',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_EXISTS': 'Shopfloor offline meter relation exists',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_NOT_FOUND': 'Shopfloor offline meter relation not found',
      'API.SHOPFLOOR_POINT_RELATION_EXISTS': 'Shopfloor point relation exists',
      'API.SHOPFLOOR_POINT_RELATION_NOT_FOUND': 'Shopfloor point relation not found',
      'API.SHOPFLOOR_SENSOR_RELATION_EXISTS': 'Shopfloor sensor relation exists',
      'API.SHOPFLOOR_SENSOR_RELATION_NOT_FOUND': 'Shopfloor sensor relation not found',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_EXISTS': 'Shopfloor virtual meter relation exists',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_NOT_FOUND': 'Shopfloor virtual meter relation not found',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_EXISTS': 'Shopfloor working calendar relation exists',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Shopfloor working calendar relation not found',
      'API.SOC_POINT_NOT_FOUND': 'SOC point not found',
      'API.SOURCE_NODE_NOT_FOUND': 'Source node not found',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_EXISTS': 'Space combined equipment relation exists',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_NOT_FOUND': 'Space combined equipment relation not found',
      'API.SPACE_COMMAND_RELATION_EXISTS': 'Space command relation exists',
      'API.SPACE_COMMAND_RELATION_NOT_FOUND': 'Space command relation not found',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_EXISTS': 'Space Energy Storage Power Station Relation Exists',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_NOT_FOUND': 'Space Energy Storage Power Station Relation Not Found',
      'API.SPACE_EQUIPMENT_RELATION_EXISTS': 'Space equipment relation exists',
      'API.SPACE_EQUIPMENT_RELATION_NOT_FOUND': 'Space equipment relation not found',
      'API.SPACE_METER_RELATION_EXISTS': 'Space meter relation exists',
      'API.SPACE_METER_RELATION_NOT_FOUND': 'Space meter relation not found',
      'API.SPACE_NAME_IS_ALREADY_IN_USE': 'Space name is already in use',
      'API.SPACE_NOT_FOUND': 'Space not found',
      'API.SPACE_NOT_FOUND_IN_PRIVILEGE': 'Space not found in privilege',
      'API.SPACE_OFFLINE_METER_RELATION_EXISTS': 'Space offline meter relation exists',
      'API.SPACE_OFFLINE_METER_RELATION_NOT_FOUND': 'Space offline meter relation not found',
      'API.SPACE_POINT_RELATION_EXISTS': 'Space point relation exists',
      'API.SPACE_POINT_RELATION_NOT_FOUND': 'Space point relation not found',
      'API.SPACE_SENSOR_RELATION_EXISTS': 'Space sensor relation exists',
      'API.SPACE_SENSOR_RELATION_NOT_FOUND': 'Space sensor relation not found',
      'API.SPACE_SHOPFLOOR_RELATION_EXISTS': 'Space shopfloor relation exists',
      'API.SPACE_SHOPFLOOR_RELATION_NOT_FOUND': 'Space shopfloor relation not found',
      'API.SPACE_STORE_RELATION_EXISTS': 'Space store relation exists',
      'API.SPACE_STORE_RELATION_NOT_FOUND': 'Space store relation not found',
      'API.SPACE_TENANT_RELATION_EXISTS': 'Space tenant relation exists',
      'API.SPACE_TENANT_RELATION_NOT_FOUND': 'Space tenant relation not found',
      'API.SPACE_VIRTUAL_METER_RELATION_EXISTS': 'Space virtual meter relation exists',
      'API.SPACE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Space virtual meter relation not found',
      'API.SPACE_WORKING_CALENDAR_RELATION_EXISTS': 'Space working calendar relation exists',
      'API.SPACE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Space working calendar relation not found',
      'API.START_DATETIME_MUST_BE_EARLIER_THAN_END_DATETIME': 'Start datetime must be early than end datetime',
      'API.STORE_COMMAND_RELATION_EXISTS': 'Store command relation exists',
      'API.STORE_COMMAND_RELATION_NOT_FOUND': 'Store command relation not found',
      'API.STORE_METER_RELATION_EXISTS': 'Store meter relation exists',
      'API.STORE_METER_RELATION_NOT_FOUND': 'Store meter relation not found',
      'API.STORE_NAME_IS_ALREADY_IN_USE': 'Store name is already in use',
      'API.STORE_NOT_FOUND': 'Store not found',
      'API.STORE_OFFLINE_METER_RELATION_EXISTS': 'Store offline meter relation exists',
      'API.STORE_OFFLINE_METER_RELATION_NOT_FOUND': 'Store offline meter relation not found',
      'API.STORE_POINT_RELATION_EXISTS': 'Store point relation exists',
      'API.STORE_POINT_RELATION_NOT_FOUND': 'Store point relation not found',
      'API.STORE_SENSOR_RELATION_EXISTS': 'Store sensor relation exists',
      'API.STORE_SENSOR_RELATION_NOT_FOUND': 'Store sensor relation not found',
      'API.STORE_TYPE_NAME_IS_ALREADY_IN_USE': 'Store type name is already in use',
      'API.STORE_TYPE_NOT_FOUND': 'Store type not found',
      'API.STORE_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Store type simplified code is already in use',
      'API.STORE_TYPE_USED_IN_STORE': 'Store type used in store',
      'API.STORE_VIRTUAL_METER_RELATION_EXISTS': 'Store virtual meter relation exists',
      'API.STORE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Store virtual meter relation not found',
      'API.STORE_WORKING_CALENDAR_RELATION_EXISTS': 'Store working calendar relation exists',
      'API.STORE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Store working calendar relation not found',
      'API.TARGET_NODE_NOT_FOUND': 'Target node not found',
      'API.TARIFF_IN_USE': 'Tariff in use',
      'API.TARIFF_IS_ALREADY_ASSOCIATED_WITH_COST_CENTER': 'Tariff is already associated with cost center',
      'API.TARIFF_IS_NOT_ASSOCIATED_WITH_COST_CENTER': 'Tariff is not associated with cost center',
      'API.TARIFF_NAME_IS_ALREADY_IN_USE': 'Tariff name is already in use',
      'API.TARIFF_NOT_FOUND': 'Tariff not found',
      'API.TENANT_COMMAND_RELATION_EXISTS': 'Tenant command relation exists',
      'API.TENANT_COMMAND_RELATION_NOT_FOUND': 'Tenant command relation  not found',
      'API.TENANT_METER_RELATION_EXISTS': 'Tenant meter relation exists',
      'API.TENANT_METER_RELATION_NOT_FOUND': 'Tenant meter relation not found',
      'API.TENANT_NAME_IS_ALREADY_IN_USE': 'Tenant name is already in use',
      'API.TENANT_NOT_FOUND': 'Tenant not found',
      'API.TENANT_OFFLINE_METER_RELATION_EXISTS': 'Tenant offline meter relation exists',
      'API.TENANT_OFFLINE_METER_RELATION_NOT_FOUND': 'Tenant offline meter relation not found',
      'API.TENANT_POINT_RELATION_EXISTS': 'Tenant point relation exists',
      'API.TENANT_POINT_RELATION_NOT_FOUND': 'Tenant point relation not found',
      'API.TENANT_SENSOR_RELATION_EXISTS': 'Tenant sensor relation exists',
      'API.TENANT_SENSOR_RELATION_NOT_FOUND': 'Tenant sensor relation not found',
      'API.TENANT_TYPE_NAME_IS_ALREADY_IN_USE': 'Tenant type name is already in use',
      'API.TENANT_TYPE_NOT_FOUND': 'Tenant type not found',
      'API.TENANT_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Tenant type simplified code is already in use',
      'API.TENANT_TYPE_USED_IN_TENANT': 'Tenant type used in tenant',
      'API.TENANT_VIRTUAL_METER_RELATION_EXISTS': 'Tenant virtual meter relation exists',
      'API.TENANT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Tenant virtual meter relation not found',
      'API.TENANT_WORKING_CALENDAR_RELATION_EXISTS': 'Tenant working calendar relation exists',
      'API.TENANT_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Tenant working calendar relation not found',
      'API.TEXT_MESSAGE_NOT_FOUND': 'Text message not found',
      'API.THE_REPORTING_PERIOD_MUST_BE_LONGER_THAN_15_MINUTES': 'The reporting period must be longer than 15 minutes',
      'API.THERE_IS_RELATION_WITH_CHILD_METERS': 'There is relation with child meters',
      'API.THERE_IS_RELATION_WITH_CHILDREN_SPACES': 'There is relation with children spaces',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENT_PARAMETERS':
        'There is relation with combined equipment parameters',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENTS': 'There is relation with combined equipments',
      'API.THERE_IS_RELATION_WITH_DATA_SOURCES': 'There is relation with data sources',
      'API.THERE_IS_RELATION_WITH_ENERGY_FLOW_DIAGRAM_LINKS': 'There is relation with energy flow diagram links',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_BATTERIES': 'There Is Relation With Energy Storage Containers Batteries',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_GRIDS': 'There Is Relation With Energy Storage Containers Grids',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_LOADS': 'There Is Relation With Energy Storage Containers Loads',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_POWER_CONVERSION_SYSTEMS': 'There Is Relation With Energy Storage Containers Power Conversion Systems',
      'API.THERE_IS_RELATION_WITH_EQUIPMENT_PARAMETERS': 'There is relation with equipment parameters',
      'API.THERE_IS_RELATION_WITH_EQUIPMENTS': 'There is relation with equipments',
      'API.THERE_IS_RELATION_WITH_METER': 'There is relation with meter',
      'API.THERE_IS_RELATION_WITH_METERS': 'There is relation with meters',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METER': 'There is relation with offline meter',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METERS': 'There is relation with offline meters',
      'API.THERE_IS_RELATION_WITH_OTHER_VIRTUAL_METERS': 'There is relation with other virtual meters',
      'API.THERE_IS_RELATION_WITH_SENSORS': 'There is relation with sensors',
      'API.THERE_IS_RELATION_WITH_SHOPFLOORS': 'There is relation with shopfloors',
      'API.THERE_IS_RELATION_WITH_SPACES': 'There is relation with spaces',
      'API.THERE_IS_RELATION_WITH_STORES': 'There is relation with stores',
      'API.THERE_IS_RELATION_WITH_TENANTS': 'There is relation with tenants',
      'API.THERE_IS_RELATION_WITH_USERS': 'There is relation with users',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_METER': 'There is relation with virtual meter',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_POWER_PLANTS': 'Mối quan hệ với Virtual Power Plant',
      'API.THIS_DATA_SOURCE_IS_BEING_USED_BY_A_METER': 'This data source is being used by a meter',
      'API.THIS_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This meter is being used by a virtual meter',
      'API.THIS_OFFLINE_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This offline meter is being used by a virtual meter',
      'API.THIS_SPACE_CANNOT_BE_CLONED': 'This space cannot be cloned',
      'API.THIS_SPACE_CANNOT_BE_DELETED': 'This space cannot be deleted',
      'API.TIMEZONE_NOT_FOUND': 'Timezone not found',
      'API.TOKEN_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'Token not found in headers please login',
      'API.USER_ACCOUNT_HAS_BEEN_LOCKED': 'User Account Has Been Locked',
      'API.USER_ACCOUNT_HAS_EXPIRED': 'User Account Has Expired',
      'API.USER_ACCOUNT_IS_NOT_LOCKED': 'User account is not locked',
      'API.USER_NAME_IS_ALREADY_IN_USE': 'User name is already in use',
      'API.USER_NOT_FOUND': 'User not found',
      'API.USER_PASSWORD_HAS_EXPIRED': 'User Password Has Expired',
      'API.USER_PRIVILEGE_NOT_FOUND': 'User privilege not found',
      'API.USER_SESSION_NOT_FOUND': 'User session not found',
      'API.USER_SESSION_TIMEOUT': 'User session timeout',
      'API.USER_UUID_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'User uuid not found in headers please login',
      'API.VIRTUAL_METER_NAME_IS_ALREADY_IN_USE': 'Virtual meter name is already in use',
      'API.VIRTUAL_METER_NOT_FOUND': 'Virtual meter not found',
      'API.VIRTUAL_METER_OF_VARIABLE_NOT_FOUND': 'Virtual meter of variable not found',
      'API.VIRTUAL_POINT_SHOULD_BE_ANALOG_VALUE': 'Virtual point should be analog value',
      'API.WEB_MESSAGE_NOT_FOUND': 'Web message not found',
      'API.WECHAT_MESSAGE_NOT_FOUND': 'Wechat message not found',
      'API.WIND_FARM_NAME_IS_ALREADY_IN_USE': 'Wind farm name is already in use',
      'API.WIND_FARM_NOT_FOUND': 'Wind farm not found',
      'API.WORKING_CALENDAR_NAME_IS_ALREADY_IN_USE': 'Working calendar name is already in use',
      'API.WORKING_CALENDAR_NOT_FOUND': 'Working calendar not found',

      MasterMeter: 'Bảng tổng hợp',
      SubMeter: 'Bảng phụ'
    }
  },
  th: {
    translation: {
      // routes & menus
      Dashboard: 'แผงหน้าปัด',
      'Space Data': 'อวกาศ',
      'Equipment Data': 'อุปกรณ์',
      'Meter Data': 'เมตร',
      'Tenant Data': 'ผู้เช่า',
      'Store Data': 'ร้าน',
      'Shopfloor Data': 'ชั้นร้านค้า',
      'Combined Equipment Data': 'อุปกรณ์รวม',
      'Auxiliary System': 'ระบบเสริม',
      Microgrid: 'ไมโครกริด',
      'Energy Storage Power Station': 'สถานีเก็บพลังงาน',
      'Photovoltaic Power Station': 'Power Station',
      'Fault Alarm': 'สัญญาณเตือนความผิดพลาด',
      Monitoring: 'ตรวจ สอบ',
      'Advanced Reporting': 'การรายงานขั้นสูง',
      'Knowledge Base': 'ฐานความรู้',
      'Energy Category Data': 'ประเภทพลังงาน',
      'Energy Item Data': 'รายการพลังงาน',
      Carbon: 'การปล่อยก๊าซคาร์บอนไดออกไซด์',
      Cost: 'ค่า',
      Output: 'ผลิตภัณฑ์',
      Income: 'รายได้',
      Efficiency: 'ประสิทธิภาพ',
      Load: 'ภาระ',
      Statistics: 'สถิติ',
      'Batch Analysis': 'การวิเคราะห์แบทช์',
      Saving: 'การประหยัด',
      Plan: 'วางแผน',
      'Production': 'Production',
      'Space Production': 'Space Production',
      'Equipment Tracking': 'การติดตามอุปกรณ์',
      'Environment Monitor': 'การตรวจสอบสภาพแวดล้อม',
      'Meter Energy': 'เครื่องวัดพลังงาน',
      'Meter Carbon': 'มาตรวัดการปล่อย CO2',
      'Meter Cost': 'ค่ามิเตอร์',
      'Meter Trend': 'เทรนด์มิเตอร์',
      'Meter Realtime': 'มิเตอร์เรียลไทม์',
      'Meter Saving': 'ประหยัดมิเตอร์',
      'Meter Plan': 'แผนมิเตอร์',
      'Master Meter Submeters Balance': 'Master Meter Submeters สมดุล',
      'Meter Batch Analysis': 'การวิเคราะห์แบทช์มิเตอร์',
      'Meter Comparison': 'การเปรียบเทียบมิเตอร์',
      'Meter Tracking': 'การติดตามมิเตอร์',
      'Virtual Meter Saving': 'การบันทึกมิเตอร์เสมือน',
      'Virtual Meter Plan': 'แผนมิเตอร์เสมือน',
      'Virtual Meter Energy': 'เครื่องวัดพลังงานเสมือน',
      'Virtual Meter Carbon': 'เสมือนมิเตอร์ปล่อย CO2',
      'Virtual Meter Cost': 'ค่ามิเตอร์เสมือน',
      'Virtual Meter Batch Analysis': 'การวิเคราะห์แบทช์มิเตอร์เสมือน',
      'Offline Meter Energy': 'เครื่องวัดพลังงานออฟไลน์',
      'Offline Meter Carbon': 'ออฟไลน์มิเตอร์ปล่อย CO2',
      'Offline Meter Cost': 'ค่ามิเตอร์ออฟไลน์',
      'Offline Meter Batch Analysis': 'การวิเคราะห์แบทช์มิเตอร์ออฟไลน์',
      'Offline Meter Saving': 'ประหยัดมิเตอร์ออฟไลน์',
      'Offline Meter Plan': 'แผนมิเตอร์ออฟไลน์',
      'Offline Meter Input': 'อินพุตมิเตอร์ออฟไลน์',
      'Tenant Bill': 'บิลผู้เช่า',
      'Energy Flow Diagram': 'แผนภาพการไหลของพลังงาน',
      'Distribution System': 'ระบบจําหน่าย',
      'SVG System': 'ระบบการแสดงภาพ SCADA',
      'Faults Data': 'ข้อ บกพร่อง',
      'Space Equipments': 'อุปกรณ์อวกาศ',
      'Combined Equipments': 'อุปกรณ์รวม',
      'Energy Storage Power Station List': 'List',
      'Energy Storage Power Station Details': 'Details',
      'Energy Storage Power Station Reporting': 'Reporting',
      'Energy Storage Power Station Alarm': 'Alarm',
      'Energy Storage Power Station Maintenance': 'Maintenance',
      'Enter Production': 'Enter Production',
      'Multiple Energy Storage Power Stations': 'Multiple Power Stations',
      'Multiple Energy Storage Power Stations Dashboard': 'Dashboard',
      'Multiple Energy Storage Power Stations List': 'List',
      'Single Energy Storage Power Station': 'Single Power Station',
      'Single Energy Storage Power Station Dashboard': 'Dashboard',
      'Single Energy Storage Power Station Details': 'Details',
      'Single Energy Storage Power Station Fault Alarm': 'Fault Alarm',
      'Energy Storage Power Station Reports': 'Power Station Reports',
      'Energy Storage Power Station Reports Energy': 'Energy',
      'Energy Storage Power Station Reports Revenue': 'Revenue',
      'Energy Storage Power Station Reports Parameters': 'Parameters',
      'Photovoltaic Power Station List': 'Power Station List',
      'Photovoltaic Power Station Details': 'Details',
      'Photovoltaic Power Station Reporting': 'Reporting',
      'Photovoltaic Power Station Alarm': 'Alarm',
      'Photovoltaic Power Station Maintenance': 'Maintenance',
      'Enter Production': 'Enter Production',
      'Multiple Photovoltaic Power Stations': 'Multiple Power Stations',
      'Multiple Photovoltaic Power Stations Dashboard': 'Dashboard',
      'Multiple Photovoltaic Power Stations List': 'List',
      'Single Photovoltaic Power Station': 'Single Power Station',
      'Single Photovoltaic Power Station Dashboard': 'Dashboard',
      'Single Photovoltaic Power Station Details': 'Details',
      'Single Photovoltaic Power Station Fault Alarm': 'Fault Alarm',
      'Photovoltaic Power Station Reports': 'Power Station Reports',
      'Photovoltaic Power Station Reports Energy': 'Energy',
      'Photovoltaic Power Station Reports Revenue': 'Revenue',
      'Photovoltaic Power Station Reports Parameters': 'Parameters',
      'Work Order': 'Work Order',
      'Work Order Installation': 'Installation',
      'Work Order Repair': ' Repair',
      'Work Order Inspection': 'Inspection',
      'Total Number of Work Orders': 'จำนวนใบสั่งงานทั้งหมด',
      'Number of New Work Orders': 'จำนวนใบสั่งงานใหม่',
      'Number of Inprogress Work Orders': 'จำนวนใบสั่งงานที่อยู่ระหว่างดำเนินการ',
      'Number of Done Work Orders': 'จำนวนใบสั่งงานที่เสร็จสิ้นแล้ว',
      'Charge Energy Indicator': 'ค่าพลังงานที่เรียกเก็บ',
      'Discharge Energy Indicator': 'ค่าพลังงานที่ปล่อยทิ้ง',
      'Charge Cost Indicator': 'ต้นทุนค่าธรรมเนียม',
      'Discharge Revenue Indicator': 'รายได้จากการปล่อยทิ้ง',
      'HVAC': 'HVAC',
      'Fire Control': 'ระบบควบคุมอัคคีภัย',

      // Dashboard
      'Welcome to DoraEOS': 'ยินดีต้อนรับสู่ DoraEOS',
      'An Industry Leading Open Source Energy Management System': 'ระบบการจัดการพลังงานโอเพ่นซอร์สชั้นนําของอุตสาหกรรม',
      "This Year's Consumption CATEGORY VALUE UNIT": 'การบริโภคในปีนี้ {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Year's Costs CATEGORY VALUE UNIT": 'ค่าใช้จ่ายในปีนี้ {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Consumption CATEGORY VALUE UNIT": 'การบริโภคในเดือนนี้ {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Costs CATEGORY VALUE UNIT": 'ค่าใช้จ่ายของเดือนนี้ {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Consumption CATEGORY VALUE UNIT in The Same Period Last Year':
        'การบริโภค {{CATEGORY}} {{VALUE}} {{UNIT}} ในช่วงเดียวกันของปีก่อน',
      'Costs CATEGORY VALUE UNIT in The Same Period Last Year':
        'ต้น ทุน {{CATEGORY}} {{VALUE}} {{UNIT}} ในช่วงเดียวกันของปีก่อน',
      'CATEGORY Consumption UNIT': '{{CATEGORY}} การบริโภค {{UNIT}}',
      'CATEGORY Costs UNIT': '{{CATEGORY}} ต้น ทุน {{UNIT}}',
      // Entities
      Space: 'อวกาศ',
      Equipment: 'อุปกรณ์',
      Meter: 'เมตร',
      'Offline Meter': 'เครื่องวัดออฟไลน์',
      'Virtual Meter': 'เครื่องวัดเสมือน',
      Tenant: 'ผู้เช่า',
      Store: 'ร้าน',
      Shopfloor: 'ชั้นร้านค้า',
      'Combined Equipment': 'อุปกรณ์รวม',
      'Cost Center': 'ศูนย์ต้นทุน',
      Name: 'ชื่อ',
      DisplayName: 'ชื่อที่ใช้แสดง',
      UserName: 'ชื่อผู้ใช้',
      Description: 'การบรรยาย',
      'Energy Category': 'ประเภทพลังงาน',
      'Child Spaces': 'พื้นที่สําหรับเด็ก',
      'Associated Equipment': 'อุปกรณ์ที่เกี่ยวข้อง',
      //Energy Categories
      Electricity: 'ไฟฟ้า',
      Water: 'น้ำ',
      'Natural Gas': 'ก๊าซธรรมชาติ',
      // SidePanelModal
      Settings: 'การตั้งค่า',
      'Set your own customized style': 'กําหนดสไตล์ของคุณเอง',
      'Color Scheme': 'โทนสี',
      'Choose the perfect color mode for your app': 'เลือกโหมดสีที่สมบูรณ์แบบสําหรับแอปของคุณ',
      Light: 'แสง',
      Dark: 'มืด',
      'RTL Mode': 'โหมด RTL',
      'Switch your language direction': 'เปลี่ยนทิศทางภาษาของคุณ',
      'Fluid Layout': 'เค้าโครงของไหล',
      'Toggle container layout system': 'สลับระบบเค้าโครงคอนเทนเนอร์',
      'Navigation Position': 'ตําแหน่งการนําทาง',
      'Select a suitable navigation system for your web application':
        'เลือกระบบนําทางที่เหมาะสมสําหรับเว็บแอปพลิเคชันของคุณ',
      'Vertical Nav': 'การนําทางแนวตั้ง',
      'Top Nav': 'มูลค่านําเที่ยวสูงสุด',
      'Vertical Navbar Style': 'แนวตั้ง Navbar Style',
      'Switch between styles for your vertical navbar': 'สลับระหว่างสไตล์สําหรับ navbar แนวตั้งของคุณ',
      Language: 'ภาษา',
      'Switch between languages': 'สลับระหว่างภาษาต่างๆ',
      'language-zh_CN': '简体中文',
      'language-en': 'English',
      'language-de': 'Deutsch',
      'language-fr': 'Français',
      'language-es': 'Español',
      'language-ru': 'Русский',
      'language-ar': 'العربية',
      'language-vi': 'Tiếng Việt',
      'language-th': 'ภาษาไทย',
      'language-tr': 'Türkçe',
      'language-ms': 'Bahasa Melayu',
      'language-id': 'Bahasa Indonesia',
      'language-zh_TW': '繁體中文',
      'Like What You See?': 'ชอบสิ่งที่คุณเห็น?',
      'Get DoraEOS now': 'รับ DoraEOS ทันที',
      Purchase: 'ซื้อ',
      // Query Panel
      'Base Period Begins': 'ระยะเวลาฐานเริ่มต้น',
      'Base Period Ends': 'ระยะเวลาฐานเริ่มต้น',
      'Comparison Types': 'ประเภทการเปรียบเทียบ',
      'Year-Over-Year': 'ปีต่อปี',
      'Month-On-Month': 'เดือนต่อเดือน',
      'Free Comparison': 'เปรียบเทียบฟรี',
      'None Comparison': 'ไม่มีการเปรียบเทียบ',
      'Reporting Period Begins': 'รอบระยะเวลารายงานเริ่มต้น',
      'Reporting Period Ends': 'สิ้นสุดรอบระยะเวลารายงาน',
      '(Optional)': '(เสริม)',
      'Period Types': 'ประเภทระยะเวลา',
      Yearly: 'รายปี',
      Monthly: 'รายเดือน',
      Weekly: 'รายสัปดาห์',
      Daily: 'ทุกวัน',
      Hourly: 'รายชั่วโมง',
      Submit: 'ส่ง',
      'Input Energy Category': 'ประเภทพลังงานอินพุต',
      'Output Energy Category': 'ประเภทพลังงานขาออก',
      'Fraction Parameter': 'พารามิเตอร์เศษส่วน',
      Search: 'ค้น',
      //DateRangePicker
      sunday: 'ซู',
      monday: 'โม',
      tuesday: 'ตู่',
      wednesday: 'เรา',
      thursday: 'สธ.',
      friday: 'FR',
      saturday: 'ซา',
      ok: 'ตกลง, ได้',
      today: 'วันนี้',
      yesterday: 'เมื่อวานนี้',
      hours: 'ชั่วโมง',
      minutes: 'รายงานการประชุม',
      seconds: 'วินาที',
      last7Days: '7 วันที่ผ่านมา',
      'Select Date Range': 'เลือกช่วงวันที่',
      //Card Summaries and Line Charts
      'Base Period': 'ระยะเวลาฐาน',
      'Reporting Period': 'รอบระยะเวลารายงาน',
      'Per Unit Area': 'ต่อหน่วยพื้นที่',
      'Per Capita': 'Per Capita',
      'Per Unit Production': 'ต่อหน่วยการผลิต',
      Baseline: 'พื้นฐาน',
      Actual: 'จริง',
      'Baseline Value - Actual Value': 'พื้นฐาน - จริง',
      'Average Load': 'โหลดเฉลี่ย',
      'Maximum Load': 'โหลดสูงสุด',
      'Load Factor': 'โหลดแฟกเตอร์',
      'Ratio of Average Load to Maximum Load': 'อัตราส่วนของโหลดเฉลี่ยต่อโหลดสูงสุด',
      'Carbon Dioxide Emissions by Energy Category': 'การปล่อยก๊าซคาร์บอนไดออกไซด์ตามประเภทพลังงาน',
      'Costs by Energy Category': 'ค่าใช้จ่ายตามหมวดพลังงาน',
      'Incomes by Energy Category': 'รายได้ตามหมวดพลังงาน',
      'Electricity Consumption by Time-Of-Use': 'ปริมาณการใช้ไฟฟ้าตามเวลาการใช้งาน',
      'Electricity Carbon Dioxide Emissions by Time-Of-Use': 'การปล่อยก๊าซคาร์บอนไดออกไซด์จากไฟฟ้าตามเวลาการใช้งาน',
      'Electricity Cost by Time-Of-Use': 'ค่าไฟฟ้าตามเวลาที่ใช้',
      'CATEGORY UNIT Consumption by Energy Items': '{{CATEGORY}} {{UNIT}} Comsumption โดยรายการพลังงาน',
      'Ton of Standard Coal by Energy Category': 'ตันของถ่านหินมาตรฐาน (TCE) ตามประเภทพลังงาน',
      'Ton of Carbon Dioxide Emissions by Energy Category':
        'ตันของการปล่อยก๊าซคาร์บอนไดออกไซด์ (TCO2E) ตามประเภทพลังงาน',
      'Reporting Period Consumption CATEGORY UNIT': 'ปริมาณการใช้ในรอบระยะเวลารายงาน {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{EQUIPMENT}} ปริมาณการใช้ในรอบระยะเวลารายงาน {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} ปริมาณการใช้ในรอบระยะเวลารายงาน {{CATEGORY}} {{UNIT}}',
      'CATEGORY VALUE UNIT': '{{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption CATEGORY VALUE UNIT':
        'ปริมาณการใช้ในรอบระยะเวลารายงาน {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Production PRODUCT VALUE UNIT': 'Reporting Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Production PRODUCT VALUE UNIT': 'Base Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption CATEGORY VALUE UNIT': 'การบริโภคช่วงฐาน {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY UNIT':
        'ปริมาณการใช้ในรอบระยะเวลารายงาน {{ITEM}} {{CATEGORY}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY VALUE UNIT':
        'ปริมาณการใช้ในรอบระยะเวลารายงาน {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption ITEM CATEGORY VALUE UNIT': 'การบริโภคช่วงฐาน {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Child Space Proportion CATEGORY UNIT': 'สัดส่วนพื้นที่เด็กแบ่งตามประเภทพลังงาน {{CATEGORY}} {{UNIT}}',
      'Child Space Total Proportion': 'พื้นที่เด็กสัดส่วนทั้งหมด',
      'Reporting Period Carbon Dioxide Emissions CATEGORY UNIT':
        'รอบระยะเวลารายงานการปล่อยก๊าซคาร์บอนไดออกไซด์ {{CATEGORY}} {{UNIT}}',
      'Reporting Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'รอบระยะเวลารายงานการปล่อยก๊าซคาร์บอนไดออกไซด์ {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'การปล่อยก๊าซคาร์บอนไดออกไซด์ในช่วงเวลาพื้นฐาน {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Costs CATEGORY UNIT': 'ค่าใช้จ่ายรอบระยะเวลารายงาน {{CATEGORY}} {{UNIT}}',
      'Reporting Period Costs CATEGORY VALUE UNIT': 'ค่าใช้จ่ายรอบระยะเวลารายงาน {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Costs CATEGORY VALUE UNIT': 'ค่าใช้จ่ายรอบระยะเวลาพื้นฐาน {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Output CATEGORY UNIT': 'ผลผลิตรอบระยะเวลารายงาน {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Output CATEGORY UNIT': '{{EQUIPMENT}} ผลผลิตรอบระยะเวลารายงาน {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} ผลผลิตรอบระยะเวลารายงาน {{CATEGORY}} {{UNIT}}',
      'Reporting Period Output CATEGORY VALUE UNIT': 'ผลผลิตรอบระยะเวลารายงาน {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Output CATEGORY VALUE UNIT': 'เอาต์พุตระยะเวลาฐาน {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Income CATEGORY UNIT': 'รายได้รอบระยะเวลารายงาน {{CATEGORY}} {{UNIT}}',
      'Reporting Period Income CATEGORY VALUE UNIT': 'รายได้รอบระยะเวลารายงาน {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Income CATEGORY VALUE UNIT': 'รายได้งวดฐาน {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Total Income UNIT': 'รอบระยะเวลารายงาน รายได้รวม {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME UNIT': 'รอบระยะเวลารายงาน ประสิทธิภาพสะสม {{NAME}} {{UNIT}}',
      'EQUIPMENT Reporting Period Cumulative Efficiency UNIT':
        '{{EQUIPMENT}} รอบระยะเวลารายงาน ประสิทธิภาพสะสม {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} รอบระยะเวลารายงาน ประสิทธิภาพที่ครอบคลุมสะสม {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} รอบระยะเวลารายงาน ประสิทธิภาพที่ครอบคลุมสะสม {{VALUE}} {{UNIT}}',
      'COMBINED_EQUIPMENT Base Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} ระยะเวลาฐานประสิทธิภาพที่ครอบคลุมสะสม {{VALUE}} {{UNIT}}',
      'Instantaneous Efficiency UNIT': 'ประสิทธิภาพทันที {{UNIT}}',
      'EQUIPMENT Instantaneous Efficiency UNIT': '{{EQUIPMENT}} ประสิทธิภาพทันที {{UNIT}}',
      'COMBINED_EQUIPMENT Instantaneous Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} ประสิทธิภาพที่ครอบคลุมทันที {{UNIT}}',
      'Reporting Period Cumulative Efficiency VALUE UNIT': 'รอบระยะเวลารายงาน ประสิทธิภาพสะสม {{VALUE}} {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME VALUE UNIT':
        'รอบระยะเวลารายงาน ประสิทธิภาพสะสม {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency VALUE UNIT': 'ประสิทธิภาพการสะสมของระยะเวลาฐาน {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency NAME VALUE UNIT':
        'ประสิทธิภาพการสะสมของระยะเวลาฐาน {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period CATEGORY Maximum Load UNIT': 'ระยะเวลาฐาน {{CATEGORY}} โหลดสูงสุด {{UNIT}}',
      'Reporting Period CATEGORY Maximum Load UNIT': 'รอบระยะเวลารายงาน {{CATEGORY}} โหลดสูงสุด {{UNIT}}',
      'Reporting Period CATEGORY Average Load UNIT': 'รอบระยะเวลารายงาน {{CATEGORY}} โหลดเฉลี่ย {{UNIT}}',
      'Reporting Period CATEGORY Load Factor': 'รอบระยะเวลารายงาน {{CATEGORY}} โหลดแฟกเตอร์',
      'Base Period CATEGORY Average Load VALUE UNIT': 'ระยะเวลาฐาน {{CATEGORY}} โหลดเฉลี่ย {{VALUE}} {{UNIT}}',
      'Reporting Period CATEGORY Average UNIT': 'รอบระยะเวลารายงาน {{CATEGORY}} ค่าเฉลี่ย {{UNIT}}',
      'Reporting Period CATEGORY Maximum UNIT': 'รอบระยะเวลารายงาน {{CATEGORY}} ค่าสูงสุด {{UNIT}}',
      'Reporting Period CATEGORY Minimum UNIT': 'รอบระยะเวลารายงาน {{CATEGORY}} มูลค่าขั้นต่ํา {{UNIT}}',
      'Reporting Period CATEGORY Mean UNIT': 'รอบระยะเวลารายงาน {{CATEGORY}} ค่าเฉลี่ยเลขคณิต {{UNIT}}',
      'Reporting Period CATEGORY Median UNIT': 'รอบระยะเวลารายงาน {{CATEGORY}} ค่ามัธยฐาน (ค่ากลาง) {{UNIT}}',
      'Reporting Period CATEGORY Stdev UNIT': 'รอบระยะเวลารายงาน {{CATEGORY}} ตัวอย่างค่าเบี่ยงเบนมาตรฐาน {{UNIT}}',
      'Reporting Period CATEGORY Variance UNIT': 'รอบระยะเวลารายงาน {{CATEGORY}} ความแปรปรวนของตัวอย่าง {{UNIT}}',
      'Reporting Period Saving CATEGORY (Baseline - Actual) UNIT':
        'การบันทึกรอบระยะเวลารายงาน {{CATEGORY}} (พื้นฐาน - จริง) {{UNIT}}',
      'Reporting Period Decreased CATEGORY (Baseline - Actual) UNIT':
        'รอบระยะเวลารายงานลดลง {{CATEGORY}} (พื้นฐาน - จริง) {{UNIT}}',
      'Reporting Period Saving CATEGORY VALUE UNIT': 'การบันทึกรอบระยะเวลารายงาน {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Saving CATEGORY VALUE UNIT': 'การออมระยะเวลาพื้นฐาน {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY UNIT':
        'ระยะเวลาการรายงานการใช้มิเตอร์หลัก {{CATEGORY}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY VALUE UNIT':
        'ระยะเวลาการรายงานการใช้มิเตอร์หลัก {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY UNIT':
        'ระยะเวลาการรายงาน Submeters การบริโภค {{CATEGORY}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY VALUE UNIT':
        'ระยะเวลาการรายงาน Submeters การบริโภค {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Difference CATEGORY UNIT': 'ความแตกต่างของรอบระยะเวลารายงาน {{CATEGORY}} {{UNIT}}',
      'Reporting Period Percentage Difference': 'ความแตกต่างของเปอร์เซ็นต์รอบระยะเวลารายงาน',
      'Reporting Period Difference CATEGORY VALUE UNIT':
        'ความแตกต่างของรอบระยะเวลารายงาน {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Trend Values': 'ค่าแนวโน้ม',
      'METER CATEGORY VALUE UNIT': '{{METER}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      //FDD
      'Fault List': 'รายการข้อบกพร่อง',
      'Reporting Period Number of Fault VALUE': 'จํานวนรอบระยะเวลารายงานความผิดพลาด {{VALUE}}',
      'All Faults': 'ความผิดพลาดทั้งหมด',
      'Space Faults': 'ความผิดพลาดของพื้นที่',
      'Number of All Faults': 'จํานวนความผิดพลาดทั้งหมด',
      'Number of Space Faults': 'จํานวนความผิดพลาดของพื้นที่',
      'Total Number of Faults': 'Total Number of Faults',
      'Number of New Faults': 'Number of New Faults',
      'Number of Inprogress Faults': 'Number of Inprogress Faults',
      'Number of Done Faults': 'Number of Done Faults',
      //Data Panels
      'Ton of Standard Coal': 'ตันของถ่านหินมาตรฐาน',
      'Ton of Carbon Dioxide Emissions': 'ตันของการปล่อยก๊าซคาร์บอนไดออกไซด์',
      'Operating Characteristic Curve': 'เส้นโค้งลักษณะการทำงาน',
      Tariff: 'ภาษี',
      'Detailed Data': 'ข้อมูลรายละเอียด',
      'Child Spaces Data': 'ข้อมูลพื้นที่ย่อย',
      'Associated Equipment Data': 'ข้อมูลอุปกรณ์ที่เกี่ยวข้อง',
      'This Year': 'ในปีนี้',
      'The Same Period Last Year': 'ช่วงเดียวกันของปีก่อน',
      'This Month': 'เดือนนี้',
      'This Day': 'วันนี้',
      Total: 'ทั้งหมด',
      'No data found': 'ไม่พบข้อมูล',
      Export: 'ส่งออก',
      Datetime: 'วันที่เวลา',
      'Time-Of-Use Type': 'ประเภทเวลาใช้งาน',
      'Top-Peak': 'ท็อปพีค',
      'On-Peak': 'ออนพีค',
      'Mid-Peak': 'มิดพีค',
      'Off-Peak': 'ออฟพีค',
      Percentage: 'อัตราร้อยละ',
      //Realtime Chart
      'Trend in the last hour of Energy Value Point': 'แนวโน้มในชั่วโมงสุดท้ายของ Energy Value Point',
      Circuit: 'วงจร',
      Point: 'จุด',
      'Realtime Value': 'มูลค่าเรียลไทม์',
      //Meter Tracking
      'Meter List': 'รายการมิเตอร์',
      'Edit Meter': 'แก้ไข',
      'Start Value': 'มูลค่าเริ่มต้น',
      'End Value': 'มูลค่าสิ้นสุด',
      'Difference Value': 'ค่าความแตกต่าง',
      'Start Integrity Rate': 'เริ่ม Integrity Rate(%)',
      'End Integrity Rate': 'สิ้นสุดอัตราความสมบูรณ์(%)',
      'Full Integrity Rate': 'อัตราความซื่อสัตย์เต็มที่(%)',
      //Equipment Tracking
      'Equipment List': 'รายการอุปกรณ์',
      'Edit Equipment': 'แก้ไข',
      //Profile Dropdown
      Feedback: 'การตอบสนอง',
      'Account Settings': 'การตั้งค่าบัญชี',
      Logout: 'ออกจากระบบ',
      //Authentication
      'Log in': 'เข้าสู่ระบบ',
      'Email address': 'ที่อยู่อีเมล',
      Password: 'รหัสผ่าน',
      CaptchaCode: 'แคปต์ชา',
      Refresh: 'ฟื้นฟู',
      'Remember me': 'จําฉันไว้',
      'Logged in as ': 'เข้าสู่ระบบเป็น ',
      'Forgot Password?': 'ลืมรหัสผ่าน?',
      'Forgot your password?': 'ลืมรหัสผ่าน?',
      "Enter your email and we'll send you a reset link": 'ป้อนอีเมลของคุณแล้วเราจะส่งลิงก์รีเซ็ตให้คุณ',
      "Enter your email and we'll send you a registration link": 'ป้อนอีเมลของคุณแล้วเราจะส่งลิงก์ลงทะเบียนให้คุณ',
      'Reset Password': 'รีเซ็ตรหัสผ่าน',
      'Thanks for using DoraEOS!': 'ขอบคุณที่ใช้ DoraEOS!',
      'You are now successfully signed out': 'ตอนนี้คุณออกจากระบบเรียบร้อยแล้ว',
      'Return to Login': 'กลับไปที่เข้าสู่ระบบ',
      'Please check your email!': 'โปรดตรวจสอบอีเมลของคุณ!',
      'An email has been sent to ': 'อีเมลถูกส่งไปยัง ',
      'Please click on the included link to reset your password': 'โปรดคลิกที่ลิงค์ที่ให้มาเพื่อรีเซ็ตรหัสผ่านของคุณ',
      'Please click on the included link to register your account': 'โปรดคลิกที่ลิงค์ที่ให้มาเพื่อลงทะเบียนบัญชีของคุณ',
      'An email with password reset link is sent to ': 'อีเมลที่มีลิงก์รีเซ็ตรหัสผ่านจะถูกส่งไปยัง ',
      'Change Password': 'เปลี่ยนรหัสผ่าน',
      'Old Password': 'รหัสผ่านเก่า',
      'New Password': 'รหัสผ่านใหม่',
      'Confirm Password': 'ยืนยันรหัสผ่าน',
      'Update Password': 'อัปเดตรหัสผ่าน',
      'Password has been changed!': 'เปลี่ยนรหัสผ่านแล้ว!',
      'Verification code has been sent to your inbox. \
      Please copy it to the input box below..':
        'รหัสยืนยันถูกส่งไปยังกล่องจดหมายของคุณแล้ว. \
      โปรดคัดลอกไปยังช่องป้อนข้อมูลด้านล่าง.',
      'New to DoraEOS': 'เพิ่งเริ่มใช้ DoraEOS',
      'Create an account': 'สร้างบัญชี',
      'EMAIL Account registration successful': '{{EMAIL}} การลงทะเบียนบัญชีสําเร็จ',
      'Thanks for verifying your account!': 'ขอบคุณสําหรับการยืนยันบัญชีของคุณ!',
      'Your code is': 'รหัสของคุณคือ',
      'Send verification code': 'ส่งรหัสยืนยัน',
      'Please wait for NUMBER seconds': 'กรุณารอสักครู่ {{NUMBER}} วินาที',
      'Password reset': 'รีเซ็ตรหัสผ่าน',
      'Please wait for approval': 'กรุณารอการอนุมัติ',
      //notification
      Notifications: 'แจ้ง เตือน',
      'Mark all as read': 'ทําเครื่องหมายทั้งหมดว่าอ่านแล้ว',
      'View all': 'ทั้งหมด',
      'Notification New': 'ใหม่',
      'Notification Earlier': 'เมื่อก่อน',
      'Notification List': 'รายการการแจ้งเตือน',
      'Bulk actions': 'การดําเนินการหลายรายการในครั้งเดียว',
      'Notification Subject': 'ชื่อเรื่อง',
      'Notification Created Datetime': 'วันที่และเวลาที่สร้าง',
      'Notification Priority': 'สำคัญ',
      'Notification Message': 'ข้อความ',
      'Notification Status': 'สถานะ',
      'Notification Mark As Read': 'ทําเครื่องหมายว่าอ่านแล้ว',
      'Notification Mark As Acknowledged': 'ยอมรับ',
      'Notification Low': 'ต่ํา',
      'Notification Medium': 'ปานกลาง',
      'Notification High': 'สูง',
      'Notification Critical': 'เกี่ยวกับการวิจารณ์',
      'Notification Unread': 'ยังไม่ได้อ่าน',
      'Notification Read': 'อ่าน',
      'Notification Acknowledged': 'ยอม รับ',
      'Notification Archive': 'หอจดหมายเหตุ',
      'Notification Delete': 'ลบ',
      'Notification Apply': 'ใช้',
      'Notification Start Datetime': 'เริ่มวันที่และเวลา',
      'Notification End Datetime': 'สิ้นสุดวันที่และเวลา',
      'Notification Update Datetime': 'อัปเดตวันที่และเวลา',
      //Monitoring
      'Run Commands': 'เรียกใช้คําสั่ง',
      'Fault Alarms': 'สัญญาณเตือนความผิดพลาด',
      'Instantaneous Efficiency VALUE UNIT': 'ประสิทธิภาพทันที {{VALUE}} {{UNIT}}',
      'Communication Online': 'ออนไลน์',
      'Communication Offline': 'ออฟไลน์',
      'Equipment Status': 'สถานะของอุปกรณ์',
      'Equipment Running': 'กำลัง เรียก ใช้',
      'Equipment Stopped': 'หยุด',
      'Show Up to': 'แสดงถึง',
      All: 'ทั้งหมด',
      'FROM - TO of TOTAL': '{{FROM}} - {{TO}} ของ {{TOTAL}} อุปกรณ์',
      //Microgrid
      'PCS Run State': 'PCS สถานะการทำงาน',
      'PCS Unknown': 'ไม่ทราบ',
      'PCS Initializing': 'กำลังเริ่มต้น',
      'PCS Standby': 'รายการสำรอง',
      'PCS Shutdown': 'ปิดเครื่อง',
      'PCS Fault': 'ความผิด',
      'PCS Running': 'วิ่ง',
      'Battery Power': 'พลังงานแบตเตอรี่',
      'Photovoltaic Power': 'Fotovoltaik gücü',
      'Grid Power': 'พลังงานกริด',
      'Load Power': 'กำลังโหลด',
      Reporting: 'รายงาน',
      Maintenance: 'การซ่อมแซม',
      'Serial Number': 'หมายเลขผลิตภัณฑ์',
      Address: 'ที่อยู่',
      'Postal Code': 'รหัสไปรษณีย์',
      'Rated Capacity': 'ความจุสูงสุด',
      'Rated Power': 'กำลังไฟสูงสุด',
      Latitude: 'ละติจูด',
      Longitude: 'ลองจิจูด',
      'Strategy Management': 'การจัดการนโยบาย',
      'Charging Schedule': 'กำหนดการชาร์จ',
      'Charge Start Time': 'เวลาเริ่มต้นการชาร์จ',
      'Charge End Time': 'เวลาสิ้นสุดการชาร์จ',
      'Discharge Start Time': 'เวลาเริ่มต้นของการปลดปล่อย',
      'Discharge End Time': 'เวลาสิ้นสุดการปลดปล่อย',
      'Total Rated Power': 'กำลังไฟรวม',
      'Total Rated Capacity': 'ความจุสูงสุดรวม',
      "Today's Charge": "จำนวนเงินที่เรียกเก็บวันนี้",
      "Today's Discharge": "ปลดประจำการวันนี้",
      "Today's Generation": "การผลิตกระแสไฟฟ้าในปัจจุบัน",
      'Total Charge': 'ค่าใช้จ่ายทั้งหมด',
      'Total Discharge': 'การปลดปล่อยทั้งหมด',
      'Total Generation': 'รุ่นทั้งหมด',
      'Total Revenue': 'รายได้รวม',
      'Total Efficiency': 'ประสิทธิภาพโดยรวม',
      'Discharge Achievement Rate': 'อัตราความสำเร็จในการระบาย',
      // Revenue Indicator
      "Today's Cost": "Today's Cost",
      "Today's Income": "Today's Income",
      "Total Cost": "Total Cost",
      "Total Income": "Total Income",
      "Today's Revenue": "Today's Revenue",

      'Revenue Ranking': 'การจัดอันดับรายได้',
      'Efficiency Ranking': 'การจัดอันดับประสิทธิภาพ',
      'Charge Ranking': 'การจัดอันดับความจุการชาร์จ',
      'Discharge Ranking': 'อันดับการปลดปล่อย',
      'Number of Microgrids': 'จำนวนไมโครกริด',
      'Number of Power Stations': 'จำนวนสถานีไฟฟ้า',
      'Microgrid List': 'รายการไมโครกริด',
      'Previous Page': 'หน้าก่อนหน้า',
      'Next Page': 'หน้าต่อไป',
      '7 Days': '7 วัน',
      'Charge UNIT': 'ค่าใช้จ่าย {{UNIT}}',
      'Discharge UNIT': 'ปล่อย {{UNIT}}',
      'Generation UNIT': 'ผลิตกระแสไฟฟ้า {{UNIT}}',
      'Energy Indicator': 'ดัชนีพลังงาน',
      'Revenue Indicator': 'ตัวบ่งชี้ผลผลิต',
      'Carbon Indicator': 'ดัชนีคาร์บอน',

      // Energy Storage Power Station
      'Battery Operating State': 'สถานะการทำงานของแบตเตอรี่',
      'Battery State': 'สถานะแบตเตอรี่',
      'Battery Unknown': 'ไม่ทราบ',
      'Battery Stopped': 'หยุดแล้ว',
      'Battery Reserved': 'Reserved', // 0
      'Battery Fault': 'ความผิด', // 1
      'Battery Warning': 'คำเตือน', // 2
      'Battery Standby': 'รายการสำรอง', // 3
      'Battery Prohibit DisCharging': 'ห้ามขนถ่าย', // 4
      'Battery Prohibit Charging': 'ห้ามชาร์จ', // 5
      'Battery Normal': 'ทั่วไป', // 6
      'Battery Charging': 'ในการชาร์จ', // 7
      'Battery Discharging': 'ในการปลดปล่อย', // 8
      'Battery Idle': 'Idle', // 9
      'Phase of Lifecycle': 'ระยะวงจรชีวิต',
      'Use Phase': 'ขั้นตอนการใช้งาน',
      'Commissioning Phase': 'ขั้นตอนการว่าจ้าง',
      'Installation Phase': 'ขั้นตอนการติดตั้ง',
      'Device Status': 'สถานะอุปกรณ์',
      'Basic Information': 'ข้อมูลพื้นฐาน',
      'Gateway': 'Gateway',
      'Grid Meter': 'Grid Meter',
      'Load Meter': 'Load Meter',
      'Total Active Power': 'Total Active Power',
      'Active Power A': 'Active Power A',
      'Active Power B': 'Active Power B',
      'Active Power C': 'Active Power C',
      'Total Reactive Power': 'Total Reactive Power',
      'Reactive Power A': 'Reactive Power A',
      'Reactive Power B': 'Reactive Power B',
      'Reactive Power C': 'Reactive Power C',
      'Total Apparent Power': 'Total Apparent Power',
      'Apparent Power A': 'Apparent Power A',
      'Apparent Power B': 'Apparent Power B',
      'Apparent Power C': 'Apparent Power C',
      'Total Power Factor': 'Total Power Factor',
      'Active Energy Import': 'Active Energy Import',
      'Active Energy Export': 'Active Energy Export',
      'Active Energy Net': 'Active Energy Net',

      // Photovoltaic Power Station
      'Invertor': 'อินเวอร์เตอร์',
      'Invertor Run State': 'สถานะการทำงาน',
      'Invertor Unknown': 'ไม่ทราบ',
      'Invertor Initializing': 'กำลังเริ่มต้น',
      'Invertor Standby': 'รายการสำรอง',
      'Invertor Shutdown': 'ปิดเครื่อง',
      'Invertor Fault': 'ความผิด',
      'Invertor Running': 'วิ่ง',

      //Advanced Reporting & Knowledge base
      'Created Datetime': 'วันที่สร้าง',
      'File Format': 'รูปแบบไฟล์',
      'File Size': 'ขนาดไฟล์',
      Uploader: 'ผู้อัปโหลด',
      //Error
      "The page you're looking for is not found": 'ไม่พบหน้าเว็บที่คุณกําลังมองหา',
      "Make sure the address is correct and that the page hasn't moved. ":
        'ตรวจสอบให้แน่ใจว่าที่อยู่ถูกต้องและหน้าเว็บไม่ได้ย้าย. ',
      'If you think this is a mistake,': 'หากคุณคิดว่านี่เป็นความผิดพลาด,',
      'contact us': 'ติดต่อเรา',
      'Take me home': 'พาฉันกลับบ้าน',
      'Whoops, something went wrong!': 'โอ้โหมีบางอย่างผิดพลาด!',
      'Try refreshing the page, or going back and attempting the action again. ':
        'ลองรีเฟรชหน้าเว็บ หรือย้อนกลับไปลองดําเนินการอีกครั้ง. ',
      'If this problem persists,': 'หากปัญหานี้ยังคงมีอยู่,',
      'Captcha Error': 'ข้อผิดพลาด Captcha',
      'Confirm Password Error': 'รหัสผ่านยืนยันไม่ตรงกัน',
      'It looks like you clicked on an invalid password reset link. Please tryagain.':
        'ดูเหมือนว่าคุณคลิกที่ลิงก์รีเซ็ตรหัสผ่านไม่ถูกต้อง กรุณาลองอีกครั้ง',
      'It looks like you clicked on an invalid registration account link. Please tryagain.':
        'ดูเหมือนว่าคุณคลิกที่ลิงค์บัญชีการลงทะเบียนที่ไม่ถูกต้อง กรุณาลองอีกครั้ง',
      //Tenant Bill
      'Lease Contract Number': 'เลขที่สัญญาเช่า',
      Download: 'ดาวน์โหลด',
      Print: 'พิมพ์',
      'Payment Notice': 'แจ้งการชําระเงิน',
      'Bill To': 'เรียกเก็บเงินไปยัง',
      'Bill Number': 'หมายเลขบิล',
      'Bill Date': 'วันที่เรียกเก็บเงิน',
      'Payment Due Date': 'วันครบกําหนดชําระเงิน',
      'Amount Payable': 'จํานวนเงินที่ต้องชําระ',
      'Billing Period Start': 'เริ่มรอบการเรียกเก็บเงิน',
      'Billing Period End': 'สิ้นสุดรอบการเรียกเก็บเงิน',
      Quantity: 'ปริมาณ',
      Price: 'ราคา',
      Unit: 'หน่วย',
      Amount: 'ปริมาณ',
      Subtotal: 'ยอดรวม',
      'VAT Output Tax': 'ภาษีส่งออก VAT',
      'Total Amount Payable': 'ยอดรวมที่ต้องชําระ',
      'Please make sure to pay on or before the payment due date above':
        'โปรดตรวจสอบให้แน่ใจว่าได้ชําระเงินก่อนหรือก่อนวันครบกําหนดชําระเงินข้างต้น',
      'Send money to the following account': 'ส่งเงินเข้าบัญชีต่อไปนี้',
      'Account Name': 'ชื่อบัญชี',
      'Bank Name': 'ชื่อธนาคาร',
      'Bank Address': 'ที่อยู่ธนาคาร',
      'RMB Account': 'บัญชี RMB',
      // button
      'Toggle Navigation': 'สลับการนําทาง',
      // working calendar
      'Working Days': 'วันทําการ',
      'Non Working Days': 'วันที่ไม่ได้ทํางาน',
      'Select Row': 'กรุณาเลือกแถวข้อมูล',
      // Offline Meter Input
      'Daily Value': 'ปริมาณที่แนะนําต่อวัน',
      Date: 'วันที่',
      'Successfully Saved': 'บันทึกสําเร็จ',
      'Previous Data Is Empty': 'ข้อมูลก่อนหน้าว่างเปล่า',
      // API
      'API.ACCOUNT_UNLOCK_FAILED': 'Account unlock failed',
      'API.ADMINISTRATOR_SESSION_NOT_FOUND': 'Administrator session not found',
      'API.ADMINISTRATOR_SESSION_TIMEOUT': 'Administrator session timeout',
      'API.ADVANCED_REPORT_NOT_FOUND': 'Advanced report not found',
      'API.API_KEY_HAS_EXPIRED': 'Key has expired',
      'API.API_KEY_NAME_IS_ALREADY_IN_USE': 'Key name is already in use',
      'API.API_KEY_NOT_FOUND': 'Key not found',
      'API.BAD_REQUEST': 'Bad request',
      'API.BALANCING_PRICE_POINT_NOT_FOUND': 'Balancing price point not found',
      'API.BATTERY_STATE_POINT_NOT_FOUND': 'Battery state point not found',
      'API.BUY_METER_NOT_FOUND': 'Buy meter not found',
      'API.CANNOT_SET_EXISTING_SUBMETER_AS_MASTER_METER': 'Cannot set existing submeter as master meter',
      'API.CHARGE_METER_NOT_FOUND': 'Charge meter not found',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_EXISTS': 'Combined equipment command relation exists',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Combined equipment command relation not found',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_EXISTS': 'Combined equipment equipment relation exists',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_NOT_FOUND': 'Combined equipment equipment relation not found',
      'API.COMBINED_EQUIPMENT_METER_RELATION_EXISTS': 'Combined equipment meter relation exists',
      'API.COMBINED_EQUIPMENT_METER_RELATION_NOT_FOUND': 'Combined equipment meter relation not found',
      'API.COMBINED_EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Combined equipment name is already in use',
      'API.COMBINED_EQUIPMENT_NOT_FOUND': 'Combined equipment not found',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Combined equipment offline meter relation exists',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Combined equipment offline meter relation not found',
      'API.COMBINED_EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Combined equipment parameter name is already in use',
      'API.COMBINED_EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Combined equipment parameter not found or not match',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Combined equipment virtual meter relation exists',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Combined equipment virtual meter relation not found',
      'API.COMMAND_NAME_IS_ALREADY_IN_USE': 'Command name is already in use',
      'API.COMMAND_NOT_FOUND': 'Command not  found',
      'API.CONTACT_NAME_IS_ALREADY_IN_USE': 'Contact name is already in use',
      'API.CONTACT_NOT_FOUND': 'Contact not found',
      'API.COOLING_METER_NOT_FOUND': 'Cooling meter not found',
      'API.COST_CENTER_EXTERNAL_ID_EXISTS': 'Cost center external id exists',
      'API.COST_CENTER_NAME_EXISTS': 'Cost center name exists',
      'API.COST_CENTER_NOT_FOUND': 'Cost center not found',
      'API.COST_FILE_NOT_FOUND': 'Cost file not found',
      'API.DATA_REPAIR_FILE_NOT_FOUND': 'Data repair file not found',
      'API.DATA_SOURCE_NAME_IS_ALREADY_IN_USE': 'Data source name is already in use',
      'API.DATA_SOURCE_NOT_FOUND': 'Data source not found',
      'API.DATABASE_ERROR': 'Database error',
      'API.DATE_IS_ALREADY_IN_WORKING_CALENDAR': 'Date Is Already In Working Calendar',
      'API.DATE_LOCAL_NOT_FOUND': 'Date local not found',
      'API.DISCHARGE_METER_NOT_FOUND': 'Discharge meter not found',
      'API.DISTRIBUTION_CIRCUIT_NAME_IS_ALREADY_IN_USE': 'Distribution circuit name is already in use',
      'API.DISTRIBUTION_CIRCUIT_NOT_FOUND': 'Distribution circuit not found',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_EXISTS': 'Distribution circuit point relation exists',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_NOT_FOUND': 'Distribution circuit point relation not found',
      'API.DISTRIBUTION_SYSTEM_NAME_IS_ALREADY_IN_USE': 'Distribution system name is already in use',
      'API.DISTRIBUTION_SYSTEM_NOT_FOUND': 'Distribution system not found',
      'API.ELECTRICITY_METER_NOT_FOUND': 'Electricity meter not found',
      'API.EMAIL_IS_ALREADY_IN_USE': 'Email is already in use',
      'API.EMAIL_MESSAGE_NOT_FOUND': 'Email message not found',
      'API.EMAIL_NOT_FOUND': 'Email not found',
      'API.EMAIL_SERVER_HOST_IS_ALREADY_IN_USE': 'Email server host is already in use',
      'API.EMAIL_SERVER_NOT_FOUND': 'Email server not found',
      'API.EMPTY_VARIABLES_ARRAY': 'Empty variables array',
      'API.ENERGY_CATEGORY_NAME_IS_ALREADY_IN_USE': 'Energy category name is already in use',
      'API.ENERGY_CATEGORY_NOT_FOUND': 'Energy category not found',
      'API.ENERGY_CATEGORY_USED_IN_ENERGY_ITEMS': 'Energy category used in energy items',
      'API.ENERGY_CATEGORY_USED_IN_METER': 'Energy category used in meter',
      'API.ENERGY_CATEGORY_USED_IN_OFFLINE_METER': 'Energy category used in offline meter',
      'API.ENERGY_CATEGORY_USED_IN_TARIFFS': 'Energy category used in tariffs',
      'API.ENERGY_CATEGORY_USED_IN_VIRTUAL_METER': 'Energy category used in virtual meter',
      'API.ENERGY_FLOW_DIAGRAM_LINK_IS_ALREADY_IN_USE': 'Energy flow diagram link is already in use',
      'API.ENERGY_FLOW_DIAGRAM_LINK_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram link not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram node name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram node not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NOT_FOUND': 'Energy flow diagram not found',
      'API.ENERGY_ITEM_DOES_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item does not belong to energy category',
      'API.ENERGY_ITEM_IS_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item is not belong to energy category',
      'API.ENERGY_ITEM_NAME_IS_ALREADY_IN_USE': 'Energy item name is already in use',
      'API.ENERGY_ITEM_NOT_FOUND': 'Energy item not found',
      'API.ENERGY_ITEM_USED_IN_METER': 'Energy item used in meter',
      'API.ENERGY_ITEM_USED_IN_OFFLINE_METER': 'Energy item used in offline meter',
      'API.ENERGY_ITEM_USED_IN_VIRTUAL_METER': 'Energy item used in virtual meter',
      'API.ENERGY_STORAGE_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Energy storage power station name is already in use',
      'API.ENERGY_STORAGE_POWER_STATION_NOT_FOUND': 'Energy storage power station not found',
      'API.EQUIPMENT_COMMAND_RELATION_EXISTS': 'Equipment command relation exists',
      'API.EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Equipment command relation not found',
      'API.EQUIPMENT_METER_RELATION_EXISTS': 'Equipment meter relation exists',
      'API.EQUIPMENT_METER_RELATION_NOT_FOUND': 'Equipment meter relation not found',
      'API.EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Equipment name is already in use',
      'API.EQUIPMENT_NOT_FOUND': 'Equipment not found',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Equipment offline meter relation exists',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Equipment offline meter relation not found',
      'API.EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Equipment parameter name is already in use',
      'API.EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Equipment parameter not found or not match',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Equipment virtual meter relation exists',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Equipment virtual meter relation not found',
      'API.ERROR': 'Error',
      'API.EXCEPTION': 'Exception',
      'API.FAILED_TO_READ_REQUEST_STREAM': 'Failed to read request stream',
      'API.FAILED_TO_RESTORE_COST_FILE': 'Failed to restore cost file',
      'API.FAILED_TO_RESTORE_DATA_REPAIR_FILE': 'Failed to restore data repair file',
      'API.FAILED_TO_RESTORE_KNOWLEDGE_FILE': 'Failed to restore knowledge file',
      'API.FAILED_TO_RESTORE_OFFLINE_METER_FILE': 'Failed to restore offline meter file',
      'API.FAILED_TO_SAVE_OFFLINE_METER_FILE': 'Failed to save offline meter file',
      'API.FAILED_TO_UPLOAD_ATTACHMENT_FILE': 'Failed to upload attachment file',
      'API.FAILED_TO_UPLOAD_COST_FILE': 'Failed to upload cost file',
      'API.FAILED_TO_UPLOAD_DATA_REPAIR_FILE': 'Failed to upload data repair file',
      'API.FAILED_TO_UPLOAD_KNOWLEDGE_FILE': 'Failed to upload knowledge file',
      'API.FAILED_TO_UPLOAD_OFFLINE_METER_FILE': 'Failed to upload offline meter file',
      'API.GATEWAY_NAME_IS_ALREADY_IN_USE': 'Gateway name is already in use',
      'API.GATEWAY_NOT_FOUND': 'Gateway not found',
      'API.HEAT_METER_NOT_FOUND': 'Heat meter not found',
      'API.INVALID_': 'Invalid ',
      'API.INVALID_ACKNOWLEDGE_CODE': 'Invalid acknowledge code',
      'API.INVALID_ADDRESS': 'Invalid address',
      'API.INVALID_ADDRESS_VALUE': 'Invalid address value',
      'API.INVALID_ADVANCED_REPORT_ID': 'Invalid advanced report id',
      'API.INVALID_API_KEY_ID': 'Invalid api key id',
      'API.INVALID_API_KEY_NAME': 'Invalid api key name',
      'API.INVALID_AREA_VALUE': 'Invalid area value',
      'API.INVALID_BALANCING_PRICE_POINT_ID': 'Invalid balancing price point ID',
      'API.INVALID_BASE_PERIOD_END_DATETIME': 'Invalid base period end datetime',
      'API.INVALID_BASE_PERIOD_START_DATETIME': 'Invalid base period start datetime',
      'API.INVALID_BATTERY_STATE_POINT_ID': 'Invalid battery state point ID',
      'API.INVALID_BAUD_RATE': 'Invalid baud rate',
      'API.INVALID_BUILDINGS_VALUE': 'Invalid buildings value',
      'API.INVALID_BUY_METER_ID': 'Invalid buy meter id',
      'API.INVALID_CAPACITY': 'Invalid capacity',
      'API.INVALID_CATEGORY': 'Invalid category',
      'API.INVALID_CHANNEL': 'Invalid channel',
      'API.INVALID_CHARGE_METER_ID': 'Invalid charge meter id',
      'API.INVALID_COMBINED_EQUIPMENT_ID': 'Invalid combined equipment id',
      'API.INVALID_COMBINED_EQUIPMENT_NAME': 'Invalid combined equipment name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_ID': 'Invalid combined equipment parameter id',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_NAME': 'Invalid combined equipment parameter name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_TYPE': 'Invalid combined equipment parameter type',
      'API.INVALID_COMBINED_EQUIPMENT_UUID': 'Invalid combined equipment uuid',
      'API.INVALID_COMMAND_ID': 'Invalid command id',
      'API.INVALID_COMMAND_NAME': 'Invalid command name',
      'API.INVALID_CONNECTION': 'Invalid connection',
      'API.INVALID_CONSTANT_VALUE': 'Invalid constant value',
      'API.INVALID_CONTACT_ID': 'Invalid contact id',
      'API.INVALID_CONTACT_NAME': 'Invalid contact name',
      'API.INVALID_COOLING_METER_ID': 'Invalid cooling meter id',
      'API.INVALID_COST_CENTER_ID': 'Invalid cost center id',
      'API.INVALID_COST_FILE_ID': 'Invalid cost file id',
      'API.INVALID_CREATED_DATETIME': 'Invalid created datetime',
      'API.INVALID_DATA_REPAIR_FILE_ID': 'Invalid data repair file id',
      'API.INVALID_DATA_SOURCE_ID': 'Invalid data source id',
      'API.INVALID_DATA_SOURCE_NAME': 'Invalid data source name',
      'API.INVALID_DATA_SOURCE_PROTOCOL': 'Invalid data source protocol',
      'API.INVALID_DATE_LOCAL': 'Invalid date local',
      'API.INVALID_DENOMINATOR_METER_UUID': 'Invalid denominator meter uuid',
      'API.INVALID_DISCHARGE_METER_ID': 'Invalid discharge meter id',
      'API.INVALID_DISPLAY_NAME': 'Invalid display name',
      'API.INVALID_DISTRIBUTION_CIRCUIT_ID': 'Invalid distribution circuit id',
      'API.INVALID_DISTRIBUTION_CIRCUIT_NAME': 'Invalid distribution circuit name',
      'API.INVALID_DISTRIBUTION_ROOM': 'Invalid distribution room',
      'API.INVALID_DISTRIBUTION_SYSTEM_ID': 'Invalid distribution system id',
      'API.INVALID_DISTRIBUTION_SYSTEM_NAME': 'Invalid distribution system name',
      'API.INVALID_ELECTRICITY_METER_ID': 'Invalid Electricity meter id',
      'API.INVALID_EMAIL': 'Invalid email',
      'API.INVALID_EMAIL_MESSAGE_ID': 'Invalid email message id',
      'API.INVALID_EMAIL_SERVER_HOST': 'Invalid email server host',
      'API.INVALID_EMAIL_SERVER_ID': 'Invalid email server id',
      'API.INVALID_END_DATE_FORMAT': 'Invalid end date format',
      'API.INVALID_END_DATETIME': 'Invalid end datetime',
      'API.INVALID_ENERGY_CATEGORY_ID': 'Invalid energy category id',
      'API.INVALID_ENERGY_CATEGORY_NAME': 'Invalid energy category name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_ID': 'Invalid energy flow diagram id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_LINK_ID': 'Invalid energy flow diagram link id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NAME': 'Invalid energy flow diagram name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_ID': 'Invalid energy flow diagram node id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_NAME': 'Invalid energy flow diagram node name',
      'API.INVALID_ENERGY_ITEM_ID': 'Invalid energy item id',
      'API.INVALID_ENERGY_ITEM_NAME': 'Invalid energy item name',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_ID': 'Invalid energy storage power station ID',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_NAME': 'Invalid energy storage power station name',
      'API.INVALID_EQUATION_IN_EXPRESSION': 'Invalid equation in expression',
      'API.INVALID_EQUIPMENT_ID': 'Invalid equipment id',
      'API.INVALID_EQUIPMENT_NAME': 'Invalid equipment name',
      'API.INVALID_EQUIPMENT_PARAMETER_ID': 'Invalid equipment parameter id',
      'API.INVALID_EQUIPMENT_PARAMETER_NAME': 'Invalid equipment parameter name',
      'API.INVALID_EQUIPMENT_PARAMETER_TYPE': 'Invalid equipment parameter type',
      'API.INVALID_EQUIPMENT_UUID': 'Invalid equipment uuid',
      'API.INVALID_EXPIRES_DATETIME': 'Invalid expires datetime',
      'API.INVALID_EXPRESSION': 'Invalid expression',
      'API.INVALID_EXPRESSION_OBJECT': 'Invalid expression object',
      'API.INVALID_FDD_CODE': 'Invalid fdd code',
      'API.INVALID_FLOORS_VALUE': 'Invalid floors value',
      'API.INVALID_FROM_ADDR': 'Invalid from addr',
      'API.INVALID_GATEWAY_ID': 'Invalid gateway id',
      'API.INVALID_GATEWAY_NAME': 'Invalid gateway name',
      'API.INVALID_HEAT_METER_ID': 'Invalid heat meter id',
      'API.INVALID_HIGH_LIMIT_VALUE': 'Invalid high limit value',
      'API.INVALID_HOURLY_HIGH_LIMIT_VALUE': 'Invalid hourly high limit value',
      'API.INVALID_HOURLY_LOW_LIMIT_VALUE': 'Invalid hourly low limit value',
      'API.INVALID_Id': 'Invalid id',
      'API.INVALID_IS_ADMIN_VALUE': 'Invalid is admin value',
      'API.INVALID_IS_COST_DATA_DISPLAYED': 'Invalid is cost data displayed',
      'API.INVALID_IS_COUNTED_VALUE': 'Invalid is counted value',
      'API.INVALID_IS_ENABLED': 'Invalid is enabled',
      'API.INVALID_IS_HIDDEN': 'Invalid is hidden',
      'API.INVALID_IS_IN_LEASE_VALUE': 'Invalid is in lease value',
      'API.INVALID_IS_INPUT_COUNTED_VALUE': 'Invalid is input counted value',
      'API.INVALID_IS_KEY_TENANT_VALUE': 'Invalid is key tenant value',
      'API.INVALID_IS_OUTPUT_COUNTED_VALUE': 'Invalid is output counted value',
      'API.INVALID_IS_OUTPUT_VALUE': 'Invalid is output value',
      'API.INVALID_IS_READ_ONLY_VALUE': 'Invalid is read only value',
      'API.INVALID_IS_TREND_VALUE': 'Invalid is trend value',
      'API.INVALID_IS_VIRTUAL_VALUE': 'Invalid is virtual value',
      'API.INVALID_KGCE': 'Invalid kgce',
      'API.INVALID_KGCO2E': 'Invalid kgco2e',
      'API.INVALID_KNOWLEDGE_FILE_ID': 'Invalid knowledge file id',
      'API.INVALID_LATITUDE_VALUE': 'Invalid latitude value',
      'API.INVALID_LEASE_NUMBER_VALUE': 'Invalid lease number value',
      'API.INVALID_LONGITUDE_VALUE': 'Invalid longitude value',
      'API.INVALID_LOW_LIMIT_VALUE': 'Invalid low limit value',
      'API.INVALID_MASTER_METER_ID': 'Invalid master meter id',
      'API.INVALID_MENU_ID': 'Invalid menu id',
      'API.INVALID_MESSAGE_DATA': 'Invalid message data',
      'API.INVALID_MESSAGE_TEMPLATE': 'Invalid message template',
      'API.INVALID_MESSAGE_TEMPLATE_ID': 'Invalid message template id',
      'API.INVALID_MESSAGE_VALUE': 'Invalid message value',
      'API.INVALID_METER_ID': 'Invalid meter id',
      'API.INVALID_METER_NAME': 'Invalid meter name',
      'API.INVALID_METER_UUID': 'Invalid meter uuid',
      'API.INVALID_MICROGRID_BATTERY_ID': 'Invalid microgrid battery id',
      'API.INVALID_MICROGRID_BATTERY_NAME': 'Invalid microgrid battery name',
      'API.INVALID_MICROGRID_EVCHARGER_ID': 'Invalid microgrid evcharger id',
      'API.INVALID_MICROGRID_EVCHARGER_NAME': 'Invalid microgrid evcharger name',
      'API.INVALID_MICROGRID_GENERATOR_ID': 'Invalid microgrid generator id',
      'API.INVALID_MICROGRID_GENERATOR_NAME': 'Invalid microgrid generator name',
      'API.INVALID_MICROGRID_GRID_ID': 'Invalid microgrid grid id',
      'API.INVALID_MICROGRID_GRID_NAME': 'Invalid microgrid grid name',
      'API.INVALID_MICROGRID_HEATPUMP_ID': 'Invalid microgrid heatpump id',
      'API.INVALID_MICROGRID_HEATPUMP_NAME': 'Invalid microgrid heatpump name',
      'API.INVALID_MICROGRID_ID': 'Invalid microgrid id',
      'API.INVALID_MICROGRID_LOAD_ID': 'Invalid microgrid load id',
      'API.INVALID_MICROGRID_LOAD_NAME': 'Invalid microgrid load name',
      'API.INVALID_MICROGRID_NAME': 'Invalid microgrid name',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_ID': 'Invalid microgrid photovoltaic ID',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_NAME': 'Invalid microgrid photovoltaic name',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_ID': 'Invalid microgrid power conversion system ID',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_NAME': 'Invalid microgrid microgrid power conversion system name',
      'API.INVALID_MICROGRID_UUID': 'Invalid microgrid UUID',
      'API.INVALID_NAME_VALUE': 'Invalid name value',
      'API.INVALID_NEW_PASSWORD': 'Invalid new password',
      'API.INVALID_NON_WORKING_DAY_ID': 'Invalid non working day ID',
      'API.INVALID_NOTIFICATION_ID': 'Invalid notification id',
      'API.INVALID_NUMERATOR_METER_UUID': 'Invalid numerator meter uuid',
      'API.INVALID_OBJECT_TYPE': 'Invalid object type',
      'API.INVALID_OFFLINE_METER_DATA': 'Invalid offline meter data',
      'API.INVALID_OFFLINE_METER_FILE_ID': 'Invalid offline meter file id',
      'API.INVALID_OFFLINE_METER_ID': 'Invalid offline meter id',
      'API.INVALID_OFFLINE_METER_NAME': 'Invalid offline meter name',
      'API.INVALID_OFFLINE_METER_UUID': 'Invalid offline meter uuid',
      'API.INVALID_OFFSET_CONSTANT_VALUE': 'Invalid offset constant value',
      'API.INVALID_OLD_PASSWORD': 'Invalid old password',
      'API.INVALID_OPENID': 'Invalid openid',
      'API.INVALID_PARENT_SPACE_ID': 'Invalid parent space id',
      'API.INVALID_PASSWORD': 'Invalid password',
      'API.INVALID_PAYLOAD': 'Invalid payload',
      'API.INVALID_PEAK_CURRENT': 'Invalid peak current',
      'API.INVALID_PEAK_LOAD': 'Invalid peak load',
      'API.INVALID_PERIOD_TYPE': 'Invalid period type',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_ID': 'Invalid photovoltaic power station ID',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_NAME': 'Invalid photovoltaic power station name',
      'API.INVALID_POINT_ID': 'Invalid point id',
      'API.INVALID_POINT_NAME': 'Invalid point name',
      'API.INVALID_PORT': 'Invalid port',
      'API.INVALID_POSTAL_CODE_VALUE': 'Invalid postal code value',
      'API.INVALID_POWER_POINT_ID': 'Invalid power point id',
      'API.INVALID_PRIORITY': 'Invalid priority',
      'API.INVALID_PRIVILEGE': 'Invalid privilege',
      'API.INVALID_PRIVILEGE_DATA': 'Invalid privilege data',
      'API.INVALID_PRIVILEGE_ID': 'Invalid privilege id',
      'API.INVALID_PRIVILEGE_NAME': 'Invalid privilege name',
      'API.INVALID_RATIO_VALUE': 'Invalid ratio value',
      'API.INVALID_RECIPIENT_EMAIL': 'Invalid recipient email',
      'API.INVALID_RECIPIENT_MOBILE': 'Invalid recipient mobile',
      'API.INVALID_RECIPIENT_NAME': 'Invalid recipient name',
      'API.INVALID_RECIPIENT_OPENID': 'Invalid recipient openid',
      'API.INVALID_REPLY': 'Invalid reply',
      'API.INVALID_REPORTING_PERIOD_END_DATETIME': 'Invalid reporting period end datetime',
      'API.INVALID_REPORTING_PERIOD_START_DATETIME': 'Invalid reporting period start datetime',
      'API.INVALID_REQUIRES_AUTHENTICATION': 'Invalid requires authentication',
      'API.INVALID_ROOMS_VALUE': 'Invalid rooms value',
      'API.INVALID_RULE_ID': 'Invalid rule id',
      'API.INVALID_RULE_NAME': 'Invalid rule name',
      'API.INVALID_RUN_STATE_POINT_ID': 'Invalid run state point ID',
      'API.INVALID_SCHEDULED_DATETIME': 'Invalid scheduled datetime',
      'API.INVALID_SELL_METER_ID': 'Invalid sell meter id',
      'API.INVALID_SENSOR_ID': 'Invalid sensor id',
      'API.INVALID_SENSOR_NAME': 'Invalid sensor name',
      'API.INVALID_SENSOR_UUID': 'Invalid sensor uuid',
      'API.INVALID_SERIAL_NUMBER': 'Invalid serial number',
      'API.INVALID_SERIAL_PORT': 'Invalid serial port',
      'API.INVALID_SESSION_PLEASE_RE_LOGIN': 'Invalid session please re-login',
      'API.INVALID_SET_VALUE': 'Invalid set value',
      'API.INVALID_SHOPFLOOR_ID': 'Invalid shopfloor id',
      'API.INVALID_SHOPFLOOR_NAME': 'Invalid shopfloor name',
      'API.INVALID_SHOPFLOOR_UUID': 'Invalid shopfloor uuid',
      'API.INVALID_SOURCE_NODE_ID': 'Invalid source node id',
      'API.INVALID_SPACE_ID': 'Invalid space id',
      'API.INVALID_SPACE_NAME': 'Invalid space name',
      'API.INVALID_SPACE_UUID': 'Invalid space uuid',
      'API.INVALID_START_DATE_FORMAT': 'Invalid start date format',
      'API.INVALID_START_DATETIME': 'Invalid start datetime',
      'API.INVALID_STATUS': 'Invalid status',
      'API.INVALID_STORE_ID': 'Invalid store id',
      'API.INVALID_STORE_NAME': 'Invalid store name',
      'API.INVALID_STORE_TYPE_DESCRIPTION': 'Invalid store type description',
      'API.INVALID_STORE_TYPE_ID': 'Invalid store type id',
      'API.INVALID_STORE_TYPE_NAME': 'Invalid store type name',
      'API.INVALID_STORE_TYPE_SIMPLIFIED_CODE': 'Invalid store type simplified code',
      'API.INVALID_STORE_UUID': 'Invalid store uuid',
      'API.INVALID_SUBJECT_VALUE': 'Invalid subject value',
      'API.INVALID_SVG': 'Invalid SVG',
      'API.INVALID_SWITCHGEAR': 'Invalid switchgear',
      'API.INVALID_TARGET_NODE_ID': 'Invalid target node id',
      'API.INVALID_TARIFF_BLOCK_PRICING': 'Invalid tariff block pricing',
      'API.INVALID_TARIFF_ID': 'Invalid tariff id',
      'API.INVALID_TARIFF_TIME_OF_USE_PRICING': 'Invalid tariff time of use pricing',
      'API.INVALID_TARIFF_TYPE': 'Invalid tariff type',
      'API.INVALID_TENANT_ID': 'Invalid tenant id',
      'API.INVALID_TENANT_NAME': 'Invalid tenant name',
      'API.INVALID_TENANT_TYPE_DESCRIPTION': 'Invalid tenant type description',
      'API.INVALID_TENANT_TYPE_ID': 'Invalid tenant type id',
      'API.INVALID_TENANT_TYPE_NAME': 'Invalid tenant type name',
      'API.INVALID_TENANT_TYPE_SIMPLIFIED_CODE': 'Invalid tenant type simplified code',
      'API.INVALID_TENANT_UUID': 'Invalid tenant uuid',
      'API.INVALID_TEXT_MESSAGE_ID': 'Invalid text message id',
      'API.INVALID_TIMEZONE_ID': 'Invalid timezone id',
      'API.INVALID_TOKEN': 'Invalid token',
      'API.INVALID_TOPIC': 'Invalid topic',
      'API.INVALID_UNIT_OF_MEASURE': 'Invalid unit of measure',
      'API.INVALID_UNIT_OF_PRICE': 'Invalid unit of price',
      'API.INVALID_UNITS': 'Invalid units',
      'API.INVALID_USER_ID': 'Invalid user id',
      'API.INVALID_USER_NAME': 'Invalid user name',
      'API.INVALID_USER_NAME_OR_EMAIL': 'Invalid user name or email',
      'API.INVALID_USER_PHONE': 'Invalid user phone',
      'API.INVALID_USER_PLEASE_RE_LOGIN': 'Invalid user please re-login',
      'API.INVALID_USER_UUID': 'Invalid user uuid',
      'API.INVALID_USERNAME': 'Invalid userName',
      'API.INVALID_VARIABLE_METER_ID': 'Invalid variable meter id',
      'API.INVALID_VARIABLE_METER_TYPE': 'Invalid variable meter type',
      'API.INVALID_VARIABLE_NAME': 'Invalid variable name',
      'API.INVALID_VERIFICATION_CODE': 'Invalid verification code',
      'API.INVALID_VIRTUAL_METER_ID': 'Invalid virtual meter id',
      'API.INVALID_VIRTUAL_METER_NAME': 'Invalid virtual meter name',
      'API.INVALID_VIRTUAL_METER_UUID': 'Invalid virtual meter uuid',
      'API.INVALID_WEB_MESSAGE_ID': 'Invalid web message id',
      'API.INVALID_WECHAT_MESSAGE_ID': 'Invalid wechat message id',
      'API.INVALID_WIND_FARM_ID': 'Invalid wind farm ID',
      'API.INVALID_WIND_FARM_NAME': 'Invalid wind farm name',
      'API.INVALID_WORKING_CALENDAR_ID': 'Invalid working calendar id ',
      'API.INVALID_WORKING_CALENDAR_NAME': 'Invalid working calendar name',
      'API.KNOWLEDGE_FILE_CANNOT_BE_REMOVED_FROM_DISK': 'Knowledge File Cannot be Removed from Disk',
      'API.KNOWLEDGE_FILE_NOT_FOUND': 'Knowledge file not found',
      'API.MASTER_METER_DOES_NOT_BELONG_TO_SAME_ENERGY_CATEGORY':
        'Master meter does not belong to same energy category',
      'API.MASTER_METER_NOT_FOUND': 'Master meter not found',
      'API.MENU_NOT_FOUND': 'Menu not found',
      'API.METER_CANNOT_HAVE_MORE_THAN_ONE_ENERGY_VALUE_POINTS': 'Meter cannot have more than one energy value points',
      'API.METER_COMMAND_RELATION_EXISTS': 'Meter command relation exists',
      'API.METER_COMMAND_RELATION_NOT_FOUND': 'Meter command relation not found',
      'API.METER_NAME_IS_ALREADY_IN_USE': 'Meter name is already in use',
      'API.METER_NOT_FOUND': 'Meter not found',
      'API.METER_OF_VARIABLE_NOT_FOUND': 'Meter of variable not found',
      'API.METER_POINT_RELATION_EXISTS': 'Meter point relation exists',
      'API.METER_POINT_RELATION_NOT_FOUND': 'Meter point relation not found',
      'API.MICROGRID_BATTERY_NAME_IS_ALREADY_IN_USE': 'Microgrid battery name is already in use',
      'API.MICROGRID_BATTERY_NOT_FOUND': 'Microgrid battery not found',
      'API.MICROGRID_EVCHARGER_NAME_IS_ALREADY_IN_USE': 'Microgrid evcharger name is already in use',
      'API.MICROGRID_EVCHARGER_NOT_FOUND': 'Microgrid evcharger not fund',
      'API.MICROGRID_GENERATOR_NAME_IS_ALREADY_IN_USE': 'Microgrid generator name is already in use',
      'API.MICROGRID_GENERATOR_NOT_FOUND': 'Microgrid generator not found',
      'API.MICROGRID_GRID_NAME_IS_ALREADY_IN_USE': 'Microgrid grid name is already in use',
      'API.MICROGRID_GRID_NOT_FOUND': 'Microgrid grid not found',
      'API.MICROGRID_HEATPUMP_NAME_IS_ALREADY_IN_USE': 'Microgrid heatpump name is already in use',
      'API.MICROGRID_HEATPUMP_NOT_FOUND': 'Microgrid heatpump not found',
      'API.MICROGRID_LOAD_NAME_IS_ALREADY_IN_USE': 'Microgrid load name is already',
      'API.MICROGRID_LOAD_NOT_FOUND': 'Microgrid load not found',
      'API.MICROGRID_NAME_IS_ALREADY_IN_USE': 'Microgrid name is already in use',
      'API.MICROGRID_NOT_FOUND': 'Microgrid not found',
      'API.MICROGRID_PHOTOVOLTAIC_NAME_IS_ALREADY_IN_USE': 'Microgrid photovoltaic name is already in use',
      'API.MICROGRID_PHOTOVOLTAIC_NOT_FOUND': 'Microgrid photovoltaic not found',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NAME_IS_ALREADY_IN_USE':
        'Microgrid power conversion system name is already in use',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NOT_FOUND': 'Microgrid power conversion system not found',
      'API.MICROGRID_SENSOR_RELATION_EXISTS': 'Microgrid sensor relation exists',
      'API.MICROGRID_SENSOR_RELATION_NOT_FOUND': 'Microgrid sensor not found',
      'API.MQTT_CONNECTION_ERROR': 'Mqtt connection error',
      'API.MQTT_PUBLISH_ERROR': 'Mqtt publish error',
      'API.NEW_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'New password length cannot exceed 100 characters',
      'API.NEW_USER_SESSION_NOT_FOUND': 'New user session not found',
      'API.NEW_USER_SESSION_TIMEOUT': 'New user session timeout',
      'API.NON_WORKING_DAY_NOT_FOUND': 'Non working day non found',
      'API.NOT_FOUND': 'Not found',
      'API.NOTIFICATION_NOT_FOUND': 'Notification not found',
      'API.OFFLINE_METER_FILE_NOT_FOUND': 'Offline meter file not found',
      'API.OFFLINE_METER_NAME_IS_ALREADY_IN_USE': 'Offline meter name is already in use',
      'API.OFFLINE_METER_NOT_FOUND': 'Offline meter not found',
      'API.OFFLINE_METER_OF_VARIABLE_NOT_FOUND': 'Offline meter of variable not found',
      'API.OLD_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Old password length cannot exceed 100 characters',
      'API.PARENT_SPACE_NOT_FOUND': 'Parent space not found',
      'API.PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Password length cannot exceed 100 characters',
      'API.PASSWORDS_MATCH': 'New password is the same as the old password',
      'API.PHOTOVOLTAIC_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Photovoltaic power station name is already in use',
      'API.PHOTOVOLTAIC_POWER_STATION_NOT_FOUND': 'Photovoltaic power station not found',
      'API.POINT_NAME_IS_ALREADY_IN_USE': 'Point name is already in use',
      'API.POINT_NOT_FOUND': 'Point not found',
      'API.POWER_POINT_NOT_FOUND': 'Power point not found',
      'API.PRIVILEGE_NAME_IS_ALREADY_IN_USE': 'Privilege name is already in use',
      'API.PRIVILEGE_NOT_FOUND': 'Privilege not found',
      'API.RULE_NAME_IS_ALREADY_IN_USE': 'Rule name is already in use',
      'API.RULE_NOT_FOUND': 'Rule not found',
      'API.SELL_METER_NOT_FOUND': 'Sell meter not found',
      'API.SENSOR_NAME_IS_ALREADY_IN_USE': 'Sensor name is already in use',
      'API.SENSOR_NOT_FOUND': 'Sensor not found',
      'API.SENSOR_POINT_RELATION_EXISTS': 'Sensor point relation exists',
      'API.SENSOR_POINT_RELATION_NOT_FOUND': 'Sensor point relation not found',
      'API.SHOPFLOOR_COMMAND_RELATION_EXISTS': 'Shopfloor command relation exists',
      'API.SHOPFLOOR_COMMAND_RELATION_NOT_FOUND': 'Shopfloor command relation not found',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_EXISTS': 'Shopfloor equipment relation exists',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_NOT_FOUND': 'Shopfloor equipment relation not found',
      'API.SHOPFLOOR_METER_RELATION_EXISTS': 'Shopfloor meter relation exists',
      'API.SHOPFLOOR_METER_RELATION_NOT_FOUND': 'Shopfloor meter relation not found',
      'API.SHOPFLOOR_NAME_IS_ALREADY_IN_USE': 'Shopfloor name is already in use',
      'API.SHOPFLOOR_NOT_FOUND': 'Shopfloor not found',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_EXISTS': 'Shopfloor offline meter relation exists',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_NOT_FOUND': 'Shopfloor offline meter relation not found',
      'API.SHOPFLOOR_POINT_RELATION_EXISTS': 'Shopfloor point relation exists',
      'API.SHOPFLOOR_POINT_RELATION_NOT_FOUND': 'Shopfloor point relation not found',
      'API.SHOPFLOOR_SENSOR_RELATION_EXISTS': 'Shopfloor sensor relation exists',
      'API.SHOPFLOOR_SENSOR_RELATION_NOT_FOUND': 'Shopfloor sensor relation not found',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_EXISTS': 'Shopfloor virtual meter relation exists',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_NOT_FOUND': 'Shopfloor virtual meter relation not found',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_EXISTS': 'Shopfloor working calendar relation exists',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Shopfloor working calendar relation not found',
      'API.SOC_POINT_NOT_FOUND': 'SOC point not found',
      'API.SOURCE_NODE_NOT_FOUND': 'Source node not found',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_EXISTS': 'Space combined equipment relation exists',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_NOT_FOUND': 'Space combined equipment relation not found',
      'API.SPACE_COMMAND_RELATION_EXISTS': 'Space command relation exists',
      'API.SPACE_COMMAND_RELATION_NOT_FOUND': 'Space command relation not found',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_EXISTS': 'Space Energy Storage Power Station Relation Exists',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_NOT_FOUND': 'Space Energy Storage Power Station Relation Not Found',
      'API.SPACE_EQUIPMENT_RELATION_EXISTS': 'Space equipment relation exists',
      'API.SPACE_EQUIPMENT_RELATION_NOT_FOUND': 'Space equipment relation not found',
      'API.SPACE_METER_RELATION_EXISTS': 'Space meter relation exists',
      'API.SPACE_METER_RELATION_NOT_FOUND': 'Space meter relation not found',
      'API.SPACE_NAME_IS_ALREADY_IN_USE': 'Space name is already in use',
      'API.SPACE_NOT_FOUND': 'Space not found',
      'API.SPACE_NOT_FOUND_IN_PRIVILEGE': 'Space not found in privilege',
      'API.SPACE_OFFLINE_METER_RELATION_EXISTS': 'Space offline meter relation exists',
      'API.SPACE_OFFLINE_METER_RELATION_NOT_FOUND': 'Space offline meter relation not found',
      'API.SPACE_POINT_RELATION_EXISTS': 'Space point relation exists',
      'API.SPACE_POINT_RELATION_NOT_FOUND': 'Space point relation not found',
      'API.SPACE_SENSOR_RELATION_EXISTS': 'Space sensor relation exists',
      'API.SPACE_SENSOR_RELATION_NOT_FOUND': 'Space sensor relation not found',
      'API.SPACE_SHOPFLOOR_RELATION_EXISTS': 'Space shopfloor relation exists',
      'API.SPACE_SHOPFLOOR_RELATION_NOT_FOUND': 'Space shopfloor relation not found',
      'API.SPACE_STORE_RELATION_EXISTS': 'Space store relation exists',
      'API.SPACE_STORE_RELATION_NOT_FOUND': 'Space store relation not found',
      'API.SPACE_TENANT_RELATION_EXISTS': 'Space tenant relation exists',
      'API.SPACE_TENANT_RELATION_NOT_FOUND': 'Space tenant relation not found',
      'API.SPACE_VIRTUAL_METER_RELATION_EXISTS': 'Space virtual meter relation exists',
      'API.SPACE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Space virtual meter relation not found',
      'API.SPACE_WORKING_CALENDAR_RELATION_EXISTS': 'Space working calendar relation exists',
      'API.SPACE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Space working calendar relation not found',
      'API.START_DATETIME_MUST_BE_EARLIER_THAN_END_DATETIME': 'Start datetime must be early than end datetime',
      'API.STORE_COMMAND_RELATION_EXISTS': 'Store command relation exists',
      'API.STORE_COMMAND_RELATION_NOT_FOUND': 'Store command relation not found',
      'API.STORE_METER_RELATION_EXISTS': 'Store meter relation exists',
      'API.STORE_METER_RELATION_NOT_FOUND': 'Store meter relation not found',
      'API.STORE_NAME_IS_ALREADY_IN_USE': 'Store name is already in use',
      'API.STORE_NOT_FOUND': 'Store not found',
      'API.STORE_OFFLINE_METER_RELATION_EXISTS': 'Store offline meter relation exists',
      'API.STORE_OFFLINE_METER_RELATION_NOT_FOUND': 'Store offline meter relation not found',
      'API.STORE_POINT_RELATION_EXISTS': 'Store point relation exists',
      'API.STORE_POINT_RELATION_NOT_FOUND': 'Store point relation not found',
      'API.STORE_SENSOR_RELATION_EXISTS': 'Store sensor relation exists',
      'API.STORE_SENSOR_RELATION_NOT_FOUND': 'Store sensor relation not found',
      'API.STORE_TYPE_NAME_IS_ALREADY_IN_USE': 'Store type name is already in use',
      'API.STORE_TYPE_NOT_FOUND': 'Store type not found',
      'API.STORE_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Store type simplified code is already in use',
      'API.STORE_TYPE_USED_IN_STORE': 'Store type used in store',
      'API.STORE_VIRTUAL_METER_RELATION_EXISTS': 'Store virtual meter relation exists',
      'API.STORE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Store virtual meter relation not found',
      'API.STORE_WORKING_CALENDAR_RELATION_EXISTS': 'Store working calendar relation exists',
      'API.STORE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Store working calendar relation not found',
      'API.TARGET_NODE_NOT_FOUND': 'Target node not found',
      'API.TARIFF_IN_USE': 'Tariff in use',
      'API.TARIFF_IS_ALREADY_ASSOCIATED_WITH_COST_CENTER': 'Tariff is already associated with cost center',
      'API.TARIFF_IS_NOT_ASSOCIATED_WITH_COST_CENTER': 'Tariff is not associated with cost center',
      'API.TARIFF_NAME_IS_ALREADY_IN_USE': 'Tariff name is already in use',
      'API.TARIFF_NOT_FOUND': 'Tariff not found',
      'API.TENANT_COMMAND_RELATION_EXISTS': 'Tenant command relation exists',
      'API.TENANT_COMMAND_RELATION_NOT_FOUND': 'Tenant command relation  not found',
      'API.TENANT_METER_RELATION_EXISTS': 'Tenant meter relation exists',
      'API.TENANT_METER_RELATION_NOT_FOUND': 'Tenant meter relation not found',
      'API.TENANT_NAME_IS_ALREADY_IN_USE': 'Tenant name is already in use',
      'API.TENANT_NOT_FOUND': 'Tenant not found',
      'API.TENANT_OFFLINE_METER_RELATION_EXISTS': 'Tenant offline meter relation exists',
      'API.TENANT_OFFLINE_METER_RELATION_NOT_FOUND': 'Tenant offline meter relation not found',
      'API.TENANT_POINT_RELATION_EXISTS': 'Tenant point relation exists',
      'API.TENANT_POINT_RELATION_NOT_FOUND': 'Tenant point relation not found',
      'API.TENANT_SENSOR_RELATION_EXISTS': 'Tenant sensor relation exists',
      'API.TENANT_SENSOR_RELATION_NOT_FOUND': 'Tenant sensor relation not found',
      'API.TENANT_TYPE_NAME_IS_ALREADY_IN_USE': 'Tenant type name is already in use',
      'API.TENANT_TYPE_NOT_FOUND': 'Tenant type not found',
      'API.TENANT_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Tenant type simplified code is already in use',
      'API.TENANT_TYPE_USED_IN_TENANT': 'Tenant type used in tenant',
      'API.TENANT_VIRTUAL_METER_RELATION_EXISTS': 'Tenant virtual meter relation exists',
      'API.TENANT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Tenant virtual meter relation not found',
      'API.TENANT_WORKING_CALENDAR_RELATION_EXISTS': 'Tenant working calendar relation exists',
      'API.TENANT_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Tenant working calendar relation not found',
      'API.TEXT_MESSAGE_NOT_FOUND': 'Text message not found',
      'API.THE_REPORTING_PERIOD_MUST_BE_LONGER_THAN_15_MINUTES': 'The reporting period must be longer than 15 minutes',
      'API.THERE_IS_RELATION_WITH_CHILD_METERS': 'There is relation with child meters',
      'API.THERE_IS_RELATION_WITH_CHILDREN_SPACES': 'There is relation with children spaces',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENT_PARAMETERS':
        'There is relation with combined equipment parameters',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENTS': 'There is relation with combined equipments',
      'API.THERE_IS_RELATION_WITH_DATA_SOURCES': 'There is relation with data sources',
      'API.THERE_IS_RELATION_WITH_ENERGY_FLOW_DIAGRAM_LINKS': 'There is relation with energy flow diagram links',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_BATTERIES': 'There Is Relation With Energy Storage Containers Batteries',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_GRIDS': 'There Is Relation With Energy Storage Containers Grids',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_LOADS': 'There Is Relation With Energy Storage Containers Loads',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_POWER_CONVERSION_SYSTEMS': 'There Is Relation With Energy Storage Containers Power Conversion Systems',
      'API.THERE_IS_RELATION_WITH_EQUIPMENT_PARAMETERS': 'There is relation with equipment parameters',
      'API.THERE_IS_RELATION_WITH_EQUIPMENTS': 'There is relation with equipments',
      'API.THERE_IS_RELATION_WITH_METER': 'There is relation with meter',
      'API.THERE_IS_RELATION_WITH_METERS': 'There is relation with meters',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METER': 'There is relation with offline meter',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METERS': 'There is relation with offline meters',
      'API.THERE_IS_RELATION_WITH_OTHER_VIRTUAL_METERS': 'There is relation with other virtual meters',
      'API.THERE_IS_RELATION_WITH_SENSORS': 'There is relation with sensors',
      'API.THERE_IS_RELATION_WITH_SHOPFLOORS': 'There is relation with shopfloors',
      'API.THERE_IS_RELATION_WITH_SPACES': 'There is relation with spaces',
      'API.THERE_IS_RELATION_WITH_STORES': 'There is relation with stores',
      'API.THERE_IS_RELATION_WITH_TENANTS': 'There is relation with tenants',
      'API.THERE_IS_RELATION_WITH_USERS': 'There is relation with users',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_METER': 'There is relation with virtual meter',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_POWER_PLANTS': 'มีความสัมพันธ์กับโรงไฟฟ้าเสมือนจริง',
      'API.THIS_DATA_SOURCE_IS_BEING_USED_BY_A_METER': 'This data source is being used by a meter',
      'API.THIS_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This meter is being used by a virtual meter',
      'API.THIS_OFFLINE_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This offline meter is being used by a virtual meter',
      'API.THIS_SPACE_CANNOT_BE_CLONED': 'This space cannot be cloned',
      'API.THIS_SPACE_CANNOT_BE_DELETED': 'This space cannot be deleted',
      'API.TIMEZONE_NOT_FOUND': 'Timezone not found',
      'API.TOKEN_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'Token not found in headers please login',
      'API.USER_ACCOUNT_HAS_BEEN_LOCKED': 'User Account Has Been Locked',
      'API.USER_ACCOUNT_HAS_EXPIRED': 'User Account Has Expired',
      'API.USER_ACCOUNT_IS_NOT_LOCKED': 'User account is not locked',
      'API.USER_NAME_IS_ALREADY_IN_USE': 'User name is already in use',
      'API.USER_NOT_FOUND': 'User not found',
      'API.USER_PASSWORD_HAS_EXPIRED': 'User Password Has Expired',
      'API.USER_PRIVILEGE_NOT_FOUND': 'User privilege not found',
      'API.USER_SESSION_NOT_FOUND': 'User session not found',
      'API.USER_SESSION_TIMEOUT': 'User session timeout',
      'API.USER_UUID_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'User uuid not found in headers please login',
      'API.VIRTUAL_METER_NAME_IS_ALREADY_IN_USE': 'Virtual meter name is already in use',
      'API.VIRTUAL_METER_NOT_FOUND': 'Virtual meter not found',
      'API.VIRTUAL_METER_OF_VARIABLE_NOT_FOUND': 'Virtual meter of variable not found',
      'API.VIRTUAL_POINT_SHOULD_BE_ANALOG_VALUE': 'Virtual point should be analog value',
      'API.WEB_MESSAGE_NOT_FOUND': 'Web message not found',
      'API.WECHAT_MESSAGE_NOT_FOUND': 'Wechat message not found',
      'API.WIND_FARM_NAME_IS_ALREADY_IN_USE': 'Wind farm name is already in use',
      'API.WIND_FARM_NOT_FOUND': 'Wind farm not found',
      'API.WORKING_CALENDAR_NAME_IS_ALREADY_IN_USE': 'Working calendar name is already in use',
      'API.WORKING_CALENDAR_NOT_FOUND': 'Working calendar not found',

      MasterMeter: 'ตารางรวม',
      SubMeter: 'ตารางแยก'
    }
  },
  tr: {
    translation: {
      // routes & menus
      Dashboard: 'Tablo',
      'Space Data': 'Uzay',
      'Equipment Data': 'Ekipman',
      'Meter Data': 'Metre',
      'Tenant Data': 'Kiracı',
      'Store Data': 'Mağaza',
      'Shopfloor Data': 'Üretim bölümü',
      'Combined Equipment Data': 'Kombine Ekipmanlar',
      'Auxiliary System': 'Yardımcı Sistem',
      Microgrid: 'Mikro şebeke',
      'Energy Storage Power Station': 'Enerji depolama güç istasyonu',
      'Photovoltaic Power Station': 'Power Station',
      'Fault Alarm': 'Arıza Alarmı',
      Monitoring: 'Izleme',
      'Advanced Reporting': 'Gelişmiş Raporlama',
      'Knowledge Base': 'Bilgi bankası',
      'Energy Category Data': 'Enerji Kategorisi',
      'Energy Item Data': 'Enerji Öğesi',
      Carbon: 'CO2 Emisyonları',
      Cost: 'Masraf',
      Output: 'Çıktı',
      Income: 'Gelir',
      Efficiency: 'Randıman',
      Load: 'Yük',
      Statistics: 'İstatistik',
      'Batch Analysis': 'Toplu Analiz',
      Saving: 'Tasarruf',
      Plan: 'Plan',
      'Production': 'Production',
      'Space Production': 'Space Production',
      'Equipment Tracking': 'Ekipman Takibi',
      'Environment Monitor': 'Ortam İzleyicisi',
      'Meter Energy': 'Sayaç Enerjisi',
      'Meter Carbon': 'Meter CO2 Emisyonları',
      'Meter Cost': 'Sayaç Maliyeti',
      'Meter Trend': 'Ölçüm Trendi',
      'Meter Realtime': 'Gerçek Zamanlı Ölçüm Cihazı',
      'Meter Saving': 'Sayaç Tasarrufu',
      'Meter Plan': 'Sayaç Planı',
      'Master Meter Submeters Balance': 'Ana Sayaç Alt Sayaç Dengesi',
      'Meter Batch Analysis': 'Sayaç Toplu Analizi',
      'Meter Comparison': 'Sayaç Karşılaştırması',
      'Meter Tracking': 'Sayaç Takibi',
      'Virtual Meter Saving': 'Sanal Sayaç Tasarrufu',
      'Virtual Meter Plan': 'Sanal Sayaç Planı',
      'Virtual Meter Energy': 'Sanal Sayaç Enerji',
      'Virtual Meter Carbon': 'Sanal Meter CO2 Emisyonları',
      'Virtual Meter Cost': 'Sanal Sayaç Maliyeti',
      'Virtual Meter Batch Analysis': 'Sanal Ölçüm Toplu Analizi',
      'Offline Meter Energy': 'Çevrimdışı Sayaç Enerjisi',
      'Offline Meter Carbon': 'Çevrimiçi Meter CO2 Emisyonları',
      'Offline Meter Cost': 'Çevrimdışı Ölçüm Maliyeti',
      'Offline Meter Batch Analysis': 'Çevrimdışı Ölçüm Toplu Analizi',
      'Offline Meter Saving': 'Çevrimdışı Sayaç Kaydetme',
      'Offline Meter Plan': 'Çevrimdışı Sayaç Planı',
      'Offline Meter Input': 'Çevrimdışı Sayaç Girişi',
      'Tenant Bill': 'Kiracı Faturası',
      'Energy Flow Diagram': 'Enerji Akış Diyagramı',
      'Distribution System': 'Dağıtım Sistemi',
      'SVG System': 'SCADA Görselleştirme Sistemi',
      'Faults Data': 'Hata',
      'Space Equipments': 'Uzay Ekipmanları',
      'Combined Equipments': 'Kombine Ekipmanlar',
      'Energy Storage Power Station List': 'List',
      'Energy Storage Power Station Details': 'Details',
      'Energy Storage Power Station Reporting': 'Reporting',
      'Energy Storage Power Station Alarm': 'Alarm',
      'Energy Storage Power Station Maintenance': 'Maintenance',
      'Enter Production': 'Enter Production',
      'Multiple Energy Storage Power Stations': 'Multiple Power Stations',
      'Multiple Energy Storage Power Stations Dashboard': 'Dashboard',
      'Multiple Energy Storage Power Stations List': 'List',
      'Single Energy Storage Power Station': 'Single Power Station',
      'Single Energy Storage Power Station Dashboard': 'Dashboard',
      'Single Energy Storage Power Station Details': 'Details',
      'Single Energy Storage Power Station Fault Alarm': 'Fault Alarm',
      'Energy Storage Power Station Reports': 'Power Station Reports',
      'Energy Storage Power Station Reports Energy': 'Energy',
      'Energy Storage Power Station Reports Revenue': 'Revenue',
      'Energy Storage Power Station Reports Parameters': 'Parameters',
      'Photovoltaic Power Station List': 'Power Station List',
      'Photovoltaic Power Station Details': 'Details',
      'Photovoltaic Power Station Reporting': 'Reporting',
      'Photovoltaic Power Station Alarm': 'Alarm',
      'Photovoltaic Power Station Maintenance': 'Maintenance',
      'Multiple Photovoltaic Power Stations': 'Multiple Power Stations',
      'Multiple Photovoltaic Power Stations Dashboard': 'Dashboard',
      'Multiple Photovoltaic Power Stations List': 'List',
      'Single Photovoltaic Power Station': 'Single Power Station',
      'Single Photovoltaic Power Station Dashboard': 'Dashboard',
      'Single Photovoltaic Power Station Details': 'Details',
      'Single Photovoltaic Power Station Fault Alarm': 'Fault Alarm',
      'Photovoltaic Power Station Reports': 'Power Station Reports',
      'Photovoltaic Power Station Reports Energy': 'Energy',
      'Photovoltaic Power Station Reports Revenue': 'Revenue',
      'Photovoltaic Power Station Reports Parameters': 'Parameters',
      'Work Order': 'Work Order',
      'Work Order Installation': 'Installation',
      'Work Order Repair': ' Repair',
      'Work Order Inspection': 'Inspection',
      'Total Number of Work Orders': 'Toplam İş Emri Sayısı',
      'Number of New Work Orders': 'Yeni İş Emri Sayısı',
      'Number of Inprogress Work Orders': 'Devam Eden İş Emri Sayısı',
      'Number of Done Work Orders': 'Tamamlanan İş Emri Sayısı',
      'Charge Energy Indicator': 'Enerji Yükle',
      'Discharge Energy Indicator': 'Enerji Boşalt',
      'Charge Cost Indicator': 'Maliyet Yükle',
      'Discharge Revenue Indicator': 'Gelir Boşalt',
      'HVAC': 'HVAC',
      'Fire Control': 'Yangın Kontrolü',

      // Dashboard
      'Welcome to DoraEOS': "DoraEOS'ye Hoş Geldiniz",
      'An Industry Leading Open Source Energy Management System': 'Endüstri Lideri Açık Kaynak Enerji Yönetim Sistemi',
      "This Year's Consumption CATEGORY VALUE UNIT": 'Bu Yılın Tüketimi {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Year's Costs CATEGORY VALUE UNIT": 'Bu Yılın Maliyetleri {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Consumption CATEGORY VALUE UNIT": 'Bu Ayın Tüketimi {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Costs CATEGORY VALUE UNIT": 'Bu Ayın Maliyetleri {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Consumption CATEGORY VALUE UNIT in The Same Period Last Year':
        'Tüketim {{CATEGORY}} {{VALUE}} {{UNIT}} Geçen Yılın Aynı Döneminde',
      'Costs CATEGORY VALUE UNIT in The Same Period Last Year':
        'Maliyet {{CATEGORY}} {{VALUE}} {{UNIT}} Geçen Yılın Aynı Döneminde',
      'CATEGORY Consumption UNIT': '{{CATEGORY}} Tüketim {{UNIT}}',
      'CATEGORY Costs UNIT': '{{CATEGORY}} Maliyet {{UNIT}}',
      // Entities
      Space: 'Uzay',
      Equipment: 'Ekipman',
      Meter: 'Metre',
      'Offline Meter': 'Çevrimdışı Ölçüm',
      'Virtual Meter': 'Sanal Ölçüm',
      Tenant: 'Kiracı',
      Store: 'Mağaza',
      Shopfloor: 'Üretim bölümü',
      'Combined Equipment': 'Kombine Ekipmanlar',
      'Cost Center': 'Maliyet Merkezi',
      Name: 'Ad',
      DisplayName: 'Görünen ad',
      UserName: 'Kullanıcı Adı',
      Description: 'Tarif',
      'Energy Category': 'Enerji Kategorisi',
      'Child Spaces': 'Çocuk Alanları',
      'Associated Equipment': 'İlgili Ekipmanlar',
      //Energy Categories
      Electricity: 'Elektrik',
      Water: 'Suyun',
      'Natural Gas': 'Doğalgaz',
      // SidePanelModal
      Settings: 'Ayarlar',
      'Set your own customized style': 'Kendi özelleştirilmiş stilinizi belirleyin',
      'Color Scheme': 'Renk Şeması',
      'Choose the perfect color mode for your app': 'Uygulamanız için mükemmel renk modunu seçin',
      Light: 'Işık',
      Dark: 'Koyu',
      'RTL Mode': 'RTL Modu',
      'Switch your language direction': 'Dil yönünüzü değiştirme',
      'Fluid Layout': 'Değişken Düzen',
      'Toggle container layout system': 'Konteyner düzen sistemini değiştir',
      'Navigation Position': 'Navigasyon Konumu',
      'Select a suitable navigation system for your web application':
        'Web uygulamanız için uygun bir navigasyon sistemi seçin',
      'Vertical Nav': 'Dikey Navigasyon',
      'Top Nav': 'En İyi Gezinti',
      'Vertical Navbar Style': 'Dikey Gezinme Çubuğu Stili',
      'Switch between styles for your vertical navbar': 'Dikey gezinme çubuğunuz için stiller arasında geçiş yapma',
      Language: 'Dil',
      'Switch between languages': 'Diller arasında geçiş yapma',
      'language-zh_CN': '简体中文',
      'language-en': 'English',
      'language-de': 'Deutsch',
      'language-fr': 'Français',
      'language-es': 'Español',
      'language-ru': 'Русский',
      'language-ar': 'العربية',
      'language-vi': 'Tiếng Việt',
      'language-th': 'ภาษาไทย',
      'language-tr': 'Türkçe',
      'language-ms': 'Bahasa Melayu',
      'language-id': 'Bahasa Indonesia',
      'language-zh_TW': '繁體中文',
      'Like What You See?': 'Gördüklerinizi beğendiniz mi?',
      'Get DoraEOS now': "DoraEOS'yi şimdi edinin",
      Purchase: 'Satın alma',
      // Query Panel
      'Base Period Begins': 'Baz Dönemi Başlıyor',
      'Base Period Ends': 'Baz Dönem Biter',
      'Comparison Types': 'Karşılaştırma Türleri',
      'Year-Over-Year': 'Yıldan Yıla',
      'Month-On-Month': 'Aydan Aya',
      'Free Comparison': 'Ücretsiz Karşılaştırma',
      'None Comparison': 'Karşılaştırma Yok',
      'Reporting Period Begins': 'Raporlama Dönemi Başlıyor',
      'Reporting Period Ends': 'Raporlama Dönemi Sona Eriyor',
      '(Optional)': '(Opsiyonel)',
      'Period Types': 'Dönem Türleri',
      Yearly: 'Yıllık',
      Monthly: 'Aylık',
      Weekly: 'Haftalık',
      Daily: 'Günlük',
      Hourly: 'Saatlik',
      Submit: 'Gönder',
      'Input Energy Category': 'Giriş Enerji Kategorisi',
      'Output Energy Category': 'Çıkış Enerjisi Kategorisi',
      'Fraction Parameter': 'Kesir Parametresi',
      Search: 'Aramak',
      //DateRangePicker
      sunday: 'Su',
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      ok: 'TAMAM',
      today: 'Bugün',
      yesterday: 'Dün',
      hours: 'Saat',
      minutes: 'Tutanak',
      seconds: 'Saniye',
      last7Days: 'Son 7 Gün',
      'Select Date Range': 'Tarih Aralığını Seçin',
      //Card Summaries and Line Charts
      'Base Period': 'Baz Dönem',
      'Reporting Period': 'Raporlama Dönemi',
      'Per Unit Area': 'Birim Alan Başına',
      'Per Capita': 'Per Capita',
      'Per Unit Production': 'Birim Üretim Başına',
      Baseline: 'Taban çizgisi',
      Actual: 'Gerçek',
      'Baseline Value - Actual Value': 'Taban çizgisi - Gerçek',
      'Average Load': 'Ortalama Yük',
      'Maximum Load': 'Maksimum Yük',
      'Load Factor': 'Yük Faktörü',
      'Ratio of Average Load to Maximum Load': 'Ortalama Yükün Maksimum Yüke Oranı',
      'Carbon Dioxide Emissions by Energy Category': 'Enerji Kategorisine Göre Karbondioksit Emisyonları',
      'Costs by Energy Category': 'Enerji Kategorisine Göre Maliyetler',
      'Incomes by Energy Category': 'Enerji Kategorisine Göre Gelirler',
      'Electricity Consumption by Time-Of-Use': 'Kullanım Süresine Göre Elektrik Tüketimi',
      'Electricity Carbon Dioxide Emissions by Time-Of-Use':
        'Kullanım Süresine Göre Elektrik Karbondioksit Emisyonları',
      'Electricity Cost by Time-Of-Use': 'Kullanım süresine göre elektrik maliyeti',
      'CATEGORY UNIT Consumption by Energy Items': '{{CATEGORY}} {{UNIT}} Enerji Maddeleri Tarafından Tüketim',
      'Ton of Standard Coal by Energy Category': 'Enerji Kategorisine Göre Ton Standart Kömür (TCE)',
      'Ton of Carbon Dioxide Emissions by Energy Category':
        'Enerji Kategorisine Göre Tonlarca Karbondioksit Emisyonu (TCO2E)',
      'Reporting Period Consumption CATEGORY UNIT': 'Raporlama Dönemi Tüketimi {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{EQUIPMENT}} Raporlama Dönemi Tüketimi {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Raporlama Dönemi Tüketimi {{CATEGORY}} {{UNIT}}',
      'CATEGORY VALUE UNIT': '{{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption CATEGORY VALUE UNIT': 'Raporlama Dönemi Tüketimi {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Production PRODUCT VALUE UNIT': 'Reporting Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Production PRODUCT VALUE UNIT': 'Base Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption CATEGORY VALUE UNIT': 'Baz Dönem Tüketimi {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY UNIT': 'Raporlama Dönemi Tüketimi {{ITEM}} {{CATEGORY}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY VALUE UNIT':
        'Raporlama Dönemi Tüketimi {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption ITEM CATEGORY VALUE UNIT': 'Baz Dönem Tüketimi {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Child Space Proportion CATEGORY UNIT': 'Enerji Kategorisine Göre Çocuk Alanı Oranı {{CATEGORY}} {{UNIT}}',
      'Child Space Total Proportion': 'Çocuk Alanı Toplam Oranı',
      'Reporting Period Carbon Dioxide Emissions CATEGORY UNIT':
        'Raporlama Dönemi Karbondioksit Emisyonları {{CATEGORY}} {{UNIT}}',
      'Reporting Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Raporlama Dönemi Karbondioksit Emisyonları {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Baz Dönem Karbondioksit Emisyonları {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Costs CATEGORY UNIT': 'Raporlama Dönemi Maliyetleri {{CATEGORY}} {{UNIT}}',
      'Reporting Period Costs CATEGORY VALUE UNIT': 'Raporlama Dönemi Maliyetleri {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Costs CATEGORY VALUE UNIT': 'Baz Dönem Maliyetleri {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Output CATEGORY UNIT': 'Raporlama Dönemi Çıktısı {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Output CATEGORY UNIT': '{{EQUIPMENT}} Raporlama Dönemi Çıktısı {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Raporlama Dönemi Çıktısı {{CATEGORY}} {{UNIT}}',
      'Reporting Period Output CATEGORY VALUE UNIT': 'Raporlama Dönemi Çıktısı {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Output CATEGORY VALUE UNIT': 'Baz Dönem Çıkışı {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Income CATEGORY UNIT': 'Raporlama Dönemi Geliri {{CATEGORY}} {{UNIT}}',
      'Reporting Period Income CATEGORY VALUE UNIT': 'Raporlama Dönemi Geliri {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Income CATEGORY VALUE UNIT': 'Baz Dönem Geliri {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Total Income UNIT': 'Raporlama Dönemi Toplam Geliri {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME UNIT': 'Raporlama Dönemi Kümülatif Verimliliği {{NAME}} {{UNIT}}',
      'EQUIPMENT Reporting Period Cumulative Efficiency UNIT':
        '{{EQUIPMENT}} Raporlama Dönemi Kümülatif Verimliliği {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Raporlama Dönemi Kümülatif Kapsamlı Verimlilik {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Raporlama Dönemi Kümülatif Kapsamlı Verimlilik {{VALUE}} {{UNIT}}',
      'COMBINED_EQUIPMENT Base Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Baz Dönem Kümülatif Kapsamlı Verimlilik {{VALUE}} {{UNIT}}',
      'Instantaneous Efficiency UNIT': 'Anlık Verimlilik {{UNIT}}',
      'EQUIPMENT Instantaneous Efficiency UNIT': '{{EQUIPMENT}} Anlık Verimlilik {{UNIT}}',
      'COMBINED_EQUIPMENT Instantaneous Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Anlık Kapsamlı Verimlilik {{UNIT}}',
      'Reporting Period Cumulative Efficiency VALUE UNIT': 'Raporlama Dönemi Kümülatif Verimliliği {{VALUE}} {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME VALUE UNIT':
        'Raporlama Dönemi Kümülatif Verimliliği {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency VALUE UNIT': 'Baz Dönem Kümülatif Verimlilik {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency NAME VALUE UNIT': 'Baz Dönem Kümülatif Verimlilik {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period CATEGORY Maximum Load UNIT': 'Baz Dönem {{CATEGORY}} Maksimum Yük {{UNIT}}',
      'Reporting Period CATEGORY Maximum Load UNIT': 'Raporlama Dönemi {{CATEGORY}} Maksimum Yük {{UNIT}}',
      'Reporting Period CATEGORY Average Load UNIT': 'Raporlama Dönemi {{CATEGORY}} Ortalama Yük {{UNIT}}',
      'Reporting Period CATEGORY Load Factor': 'Raporlama Dönemi {{CATEGORY}} Yük Faktörü',
      'Base Period CATEGORY Average Load VALUE UNIT': 'Baz Dönem {{CATEGORY}} Ortalama Yük {{VALUE}} {{UNIT}}',
      'Reporting Period CATEGORY Average UNIT': 'Raporlama Dönemi {{CATEGORY}} Ortalama Değer {{UNIT}}',
      'Reporting Period CATEGORY Maximum UNIT': 'Raporlama Dönemi {{CATEGORY}} Maksimum Değer {{UNIT}}',
      'Reporting Period CATEGORY Minimum UNIT': 'Raporlama Dönemi {{CATEGORY}} Minimum Değer {{UNIT}}',
      'Reporting Period CATEGORY Mean UNIT': 'Raporlama Dönemi {{CATEGORY}} Aritmetik Ortalama {{UNIT}}',
      'Reporting Period CATEGORY Median UNIT': 'Raporlama Dönemi {{CATEGORY}} Medyan (Orta Değer) {{UNIT}}',
      'Reporting Period CATEGORY Stdev UNIT': 'Raporlama Dönemi {{CATEGORY}} Numune Standart Sapması {{UNIT}}',
      'Reporting Period CATEGORY Variance UNIT': 'Raporlama Dönemi {{CATEGORY}} Örnek Varyans {{UNIT}}',
      'Reporting Period Saving CATEGORY (Baseline - Actual) UNIT':
        'Raporlama Dönemi Tasarrufu {{CATEGORY}} (Taban çizgisi - Gerçek) {{UNIT}}',
      'Reporting Period Decreased CATEGORY (Baseline - Actual) UNIT':
        'Raporlama Dönemi Azaldı {{CATEGORY}} (Taban çizgisi - Gerçek) {{UNIT}}',
      'Reporting Period Saving CATEGORY VALUE UNIT': 'Raporlama Dönemi Tasarrufu {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Saving CATEGORY VALUE UNIT': 'Baz Dönem Tasarrufu {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY UNIT':
        'Raporlama Dönemi Ana Sayaç Tüketimi {{CATEGORY}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY VALUE UNIT':
        'Raporlama Dönemi Ana Sayaç Tüketimi {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY UNIT':
        'Raporlama Dönemi Alt Sayaç Tüketimi {{CATEGORY}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY VALUE UNIT':
        'Raporlama Dönemi Alt Sayaç Tüketimi {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Difference CATEGORY UNIT': 'Raporlama Dönemi Farkı {{CATEGORY}} {{UNIT}}',
      'Reporting Period Percentage Difference': 'Raporlama Dönemi Yüzde Farkı',
      'Reporting Period Difference CATEGORY VALUE UNIT': 'v {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Trend Values': 'Trend Değerleri',
      'METER CATEGORY VALUE UNIT': '{{METER}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      //FDD
      'Fault List': 'Arıza Listesi',
      'Reporting Period Number of Fault VALUE': 'Raporlama Dönemi Hata Sayısı {{VALUE}}',
      'All Faults': 'Tüm Hatalar',
      'Space Faults': 'Uzay Fayları',
      'Number of All Faults': 'Tüm Arızaların Sayısı',
      'Number of Space Faults': 'Uzay Hatalarının Sayısı',
      'Total Number of Faults': 'Total Number of Faults',
      'Number of New Faults': 'Number of New Faults',
      'Number of Inprogress Faults': 'Number of Inprogress Faults',
      'Number of Done Faults': 'Number of Done Faults',
      //Data Panels
      'Ton of Standard Coal': 'Ton Standart Kömür',
      'Ton of Carbon Dioxide Emissions': 'Tonlarca karbondioksit emisyonu',
      'Operating Characteristic Curve': 'işletim karakteristik eğri',
      Tariff: 'Tarife',
      'Detailed Data': 'Detaylı Veri',
      'Child Spaces Data': 'Alt Alanlar Verileri',
      'Associated Equipment Data': 'İlişkili Ekipman Verileri',
      'This Year': 'Bu Yıl',
      'The Same Period Last Year': 'Geçen yılın aynı döneminde',
      'This Month': 'Bu Ay',
      'This Day': 'Bu Gün',
      Total: 'Toplam',
      'No data found': 'Veri bulunamadı',
      Export: 'Ihracat',
      Datetime: 'Tarih-saat',
      'Time-Of-Use Type': 'Kullanım Süresi Türü',
      'Top-Peak': 'Üst Düzey Zirve',
      'On-Peak': 'Zirvede',
      'Mid-Peak': 'Orta Tepe Noktası',
      'Off-Peak': 'Peak',
      Percentage: 'Yüzde',
      //Realtime Chart
      'Trend in the last hour of Energy Value Point': 'Enerji Değer Noktasının son saatindeki eğilim',
      Circuit: 'Devre',
      Point: 'Nokta',
      'Realtime Value': 'Gerçek Zamanlı Değer',
      //Meter Tracking
      'Meter List': 'Sayaç Listesi',
      'Edit Meter': 'Düzenlemek',
      'Start Value': 'Başlangıç Değeri',
      'End Value': 'Bitiş Değeri',
      'Difference Value': 'Fark Değeri',
      'Start Integrity Rate': 'Başlangıç Bütünlük Oranı(%)',
      'End Integrity Rate': 'Son Bütünlük Oranı(%)',
      'Full Integrity Rate': 'Tam Bütünlük Oranı(%)',
      //Equipment Tracking
      'Equipment List': 'Ekipman Listesi',
      'Edit Equipment': 'Düzenlemek',
      //Profile Dropdown
      Feedback: 'Geri besleme',
      'Account Settings': 'Hesap Ayarları',
      Logout: 'Oturum kapatma',
      //Authentication
      'Log in': 'Oturum aç',
      'Email address': 'E-posta adresi',
      Password: 'Parola',
      CaptchaCode: 'Arjantin',
      Refresh: 'Yenilemek',
      'Remember me': 'Beni hatırla',
      'Logged in as ': 'Şu şekilde oturum açtım: ',
      'Forgot Password?': 'Şifremi Unuttum?',
      'Forgot your password?': 'Şifrenizi mi unuttunuz?',
      "Enter your email and we'll send you a reset link": 'E-postanızı girin, size bir sıfırlama bağlantısı gönderelim',
      "Enter your email and we'll send you a registration link":
        'E-posta adresinizi girin, size bir kayıt bağlantısı gönderelim',
      'Reset Password': 'Şifreyi Sıfırla',
      'Thanks for using DoraEOS!': "DoraEOS'yi kullandığınız için teşekkür ederiz!",
      'You are now successfully signed out': 'Artık oturumunuz başarıyla kapatıldı',
      'Return to Login': 'Girişe Dön',
      'Please check your email!': 'Lütfen e-postanızı kontrol edin!',
      'An email has been sent to ': 'Şu adrese bir e-posta gönderildi: ',
      'Please click on the included link to reset your password':
        'Şifrenizi sıfırlamak için lütfen birlikte verilen bağlantıya tıklayın',
      'Please click on the included link to register your account':
        'Hesabınızı kaydetmek için lütfen dahil edilen bağlantıya tıklayın',
      'An email with password reset link is sent to ':
        'Parola sıfırlama bağlantısı içeren bir e-posta şu adrese gönderilir: ',
      'Change Password': 'Şifre Değiştir',
      'Old Password': 'Eski Şifre',
      'New Password': 'Yeni Şifre',
      'Confirm Password': 'Şifreyi Onayla',
      'Update Password': 'Şifreyi Güncelle',
      'Password has been changed!': 'Şifre değiştirildi!',
      'Verification code has been sent to your inbox. \
      Please copy it to the input box below..':
        'Doğrulama kodu gelen kutunuza gönderildi. \
      Lütfen aşağıdaki giriş kutusuna kopyalayın.',
      'New to DoraEOS': "DoraEOS'de yeni",
      'Create an account': 'Bir hesap oluşturun',
      'EMAIL Account registration successful': '{{EMAIL}} Hesap kaydı başarılı',
      'Thanks for verifying your account!': 'Hesabınızı doğruladığınız için teşekkür ederiz!',
      'Your code is': 'Kodunuz',
      'Send verification code': 'Doğrulama kodu gönder',
      'Please wait for NUMBER seconds': 'Lütfen bekleyin {{NUMBER}} Saniye',
      'Password reset': 'Parola sıfırlama',
      'Please wait for approval': 'Lütfen onay için bekleyin',
      //notification
      Notifications: 'Bildirim',
      'Mark all as read': 'Tümünü okundu olarak işaretle',
      'View all': 'Tüm',
      'Notification New': 'YENİ',
      'Notification Earlier': 'ÖNCEKİ',
      'Notification List': 'Bildirim Listesi',
      'Bulk actions': 'Toplu işlemler',
      'Notification Subject': 'Konu',
      'Notification Created Datetime': 'Oluşturulma TarihiSaat',
      'Notification Priority': 'Öncelik',
      'Notification Message': 'İleti',
      'Notification Status': 'Durum',
      'Notification Mark As Read': 'Okundu Olarak İşaretle',
      'Notification Mark As Acknowledged': 'Kabul',
      'Notification Low': 'Alçak',
      'Notification Medium': 'Orta',
      'Notification High': 'Yüksek',
      'Notification Critical': 'Kritik',
      'Notification Unread': 'Okunmamış',
      'Notification Read': 'Okumak',
      'Notification Acknowledged': 'Kabul',
      'Notification Archive': 'Arşiv',
      'Notification Delete': 'Silmek',
      'Notification Apply': 'Uygulamak',
      'Notification Start Datetime': 'Tarih Saati Başlat',
      'Notification End Datetime': 'Bitiş TarihiSaat',
      'Notification Update Datetime': 'Tarih Saati Güncelle',
      //Monitoring
      'Run Commands': 'Komutları Çalıştır',
      'Fault Alarms': 'Arıza Alarmları',
      'Instantaneous Efficiency VALUE UNIT': 'Anlık Verimlilik {{VALUE}} {{UNIT}}',
      'Communication Online': 'Çevrimiçi',
      'Communication Offline': 'Çevrimdışı',
      'Equipment Status': 'Eğitim durumu',
      'Equipment Running': 'Koşarak',
      'Equipment Stopped': 'Durdu',
      'Show Up to': 'Şuraya Kadar Göster',
      All: 'Tüm',
      'FROM - TO of TOTAL': '{{FROM}} - {{TO}} in {{TOTAL}} Alet -leri',
      //Microgrid
      'PCS Run State': 'PCS Çalıştırma Durumu',
      'PCS Unknown': 'Bilinmemiş',
      'PCS Initializing': 'Initializing',
      'PCS Standby': 'Standby',
      'PCS Shutdown': 'Kapa çeneni',
      'PCS Fault': 'Hata',
      'PCS Running': 'Çıkıyor',
      'Battery Power': 'Battery power',
      'Photovoltaic Power': 'Fotovoltaik gücü',
      'Grid Power': 'Grid gücü',
      'Load Power': 'Yükleme gücü',
      Reporting: 'Rapor',
      Maintenance: 'Yedekleme',
      'Serial Number': 'Seri Numarası',
      Address: 'Adres',
      'Postal Code': 'Posta Kodu',
      'Rated Capacity': 'Değerli Kapacite',
      'Rated Power': 'Değerli Güç',
      Latitude: 'Latitude',
      Longitude: 'Yükseklik',
      'Strategy Management': 'Strateji yönetimi',
      'Charging Schedule': 'Yükleme Zamanı',
      'Charge Start Time': 'Yükleme Başlangıç Zamanı',
      'Charge End Time': 'Charge End Time',
      'Discharge Start Time': 'Başlangıç Zamanını Aç',
      'Discharge End Time': 'Açırma sonu zamanı',
      'Total Rated Power': 'Toplam Nominal Güç',
      'Total Rated Capacity': 'Toplam Nominal Kapasite',
      "Today's Charge": "Bugünün ödeme tutarı",
      "Today's Discharge": "Bugün taburcu olun",
      "Today's Generation": "Günümüzün enerji üretimi",
      'Total Charge': 'Toplam ücret',
      'Total Discharge': 'toplam deşarj',
      'Total Generation': 'Toplam Üretim',
      'Total Revenue': 'Toplam gelir',
      'Total Efficiency': 'Toplam Verimlilik',
      'Discharge Achievement Rate': 'Deşarj Başarı Oranı',
      // Revenue Indicator
      "Today's Cost": "Today's Cost",
      "Today's Income": "Today's Income",
      "Total Cost": "Total Cost",
      "Total Income": "Total Income",
      "Today's Revenue": "Today's Revenue",

      'Revenue Ranking': 'Gelir sıralaması',
      'Efficiency Ranking': 'Verimlilik sıralaması',
      'Charge Ranking': 'Şarj kapasitesi sıralaması',
      'Discharge Ranking': 'Deşarj sıralaması',
      'Number of Microgrids': 'Mikro şebeke sayısı',
      'Number of Power Stations': 'Elektrik santrallerinin sayısı',
      'Microgrid List': 'Mikro Şebeke Listesi',
      'Previous Page': 'Önceki sayfa',
      'Next Page': 'Sonraki Sayfa',
      '7 Days': '7 gün',
      'Charge UNIT': 'Şarj {{UNIT}}',
      'Discharge UNIT': 'deşarj {{UNIT}}',
      'Generation UNIT': 'elektrik üretmek {{UNIT}}',
      'Energy Indicator': 'enerji endeksi',
      'Revenue Indicator': 'Verim göstergesi',
      'Carbon Indicator': 'karbon indeksi',

      // Energy Storage Power Station
      'Battery Operating State': 'Batarya İşlemi Durumu',
      'Battery State': 'Batarya durumu',
      'Battery Unknown': 'Bilinmemiş',
      'Battery Stopped': 'Durduruldu',
      'Battery Reserved': 'Reserved', // 0
      'Battery Fault': 'Hata', // 1
      'Battery Warning': 'Uyarı', // 2
      'Battery Standby': 'Standby', // 3
      'Battery Prohibit DisCharging': 'Yüklemeyi yasakla', // 4
      'Battery Prohibit Charging': 'Yüklemeyi yasakla', // 5
      'Battery Normal': 'Normal', // 6
      'Battery Charging': 'Yüklenmek', // 7
      'Battery Discharging': 'During discharge', // 8
      'Battery Idle': 'Idle', // 9
      'Phase of Lifecycle': 'Lifecycle Fazi',
      'Use Phase': 'Use Phase',
      'Commissioning Phase': 'Komisyon Fazi',
      'Installation Phase': 'Kurulum Aşaması',
      'Device Status': 'Cihaz Durumu',
      'Basic Information': 'Temel Bilgiler',
      'Gateway': 'Gateway',
      'Grid Meter': 'Grid Meter',
      'Load Meter': 'Load Meter',
      'Total Active Power': 'Total Active Power',
      'Active Power A': 'Active Power A',
      'Active Power B': 'Active Power B',
      'Active Power C': 'Active Power C',
      'Total Reactive Power': 'Total Reactive Power',
      'Reactive Power A': 'Reactive Power A',
      'Reactive Power B': 'Reactive Power B',
      'Reactive Power C': 'Reactive Power C',
      'Total Apparent Power': 'Total Apparent Power',
      'Apparent Power A': 'Apparent Power A',
      'Apparent Power B': 'Apparent Power B',
      'Apparent Power C': 'Apparent Power C',
      'Total Power Factor': 'Total Power Factor',
      'Active Energy Import': 'Active Energy Import',
      'Active Energy Export': 'Active Energy Export',
      'Active Energy Net': 'Active Energy Net',

      // Photovoltaic Power Station
      'Invertor': 'invertörler',
      'Invertor Run State': 'Çalıştırma Durumu',
      'Invertor Unknown': 'Bilinmemiş',
      'Invertor Initializing': 'Initializing',
      'Invertor Standby': 'Standby',
      'Invertor Shutdown': 'Kapa çeneni',
      'Invertor Fault': 'Hata',
      'Invertor Running': 'Çıkıyor',

      //Advanced Reporting & Knowledge base
      'Created Datetime': 'Oluşturulma Tarihi',
      'File Format': 'Dosya Biçimi',
      'File Size': 'Dosya Boyutu',
      Uploader: 'Yükleyici',
      //Error
      "The page you're looking for is not found": 'Aradığınız sayfa bulunamadı',
      "Make sure the address is correct and that the page hasn't moved. ":
        'Adresin doğru olduğundan ve sayfanın taşınmadığından emin olun. ',
      'If you think this is a mistake,': 'Bunun bir hata olduğunu düşünüyorsanız,',
      'contact us': 'bize ulaşın',
      'Take me home': 'Beni eve götür',
      'Whoops, something went wrong!': 'Whoops, bir şeyler ters gitti!',
      'Try refreshing the page, or going back and attempting the action again. ':
        'Sayfayı yenilemeyi veya geri dönüp eylemi yeniden denemeyi deneyin. ',
      'If this problem persists,': 'Bu sorun devam ederse,',
      'Captcha Error': 'Captcha Hatası',
      'Confirm Password Error': 'Onay parolası eşleşmiyor',
      'It looks like you clicked on an invalid password reset link. Please tryagain.':
        'Geçersiz bir parola sıfırlama \
      bağlantısına tıklamış gibi görünüyorsunuz. Lütfen tekrar deneyin.',
      'It looks like you clicked on an invalid registration account link. Please tryagain.':
        'Geçersiz bir kayıt hesabı \
      bağlantısına tıklamış gibi görünüyorsunuz. Lütfen tekrar deneyin.',
      //Tenant Bill
      'Lease Contract Number': 'Kira Sözleşme Numarası',
      Download: 'İndirmek',
      Print: 'Yazdırmak',
      'Payment Notice': 'Ödeme Bildirimi',
      'Bill To': 'Fatura Gönder',
      'Bill Number': 'Fatura Numarası',
      'Bill Date': 'Fatura Tarihi',
      'Payment Due Date': 'Ödeme Son Tarihi',
      'Amount Payable': 'Ödenecek Tutar',
      'Billing Period Start': 'Fatura Dönemi Başlangıcı',
      'Billing Period End': 'Fatura Dönemi Sonu',
      Quantity: 'Miktar',
      Price: 'Fiyat',
      Unit: 'Birim',
      Amount: 'Miktar',
      Subtotal: 'Ara toplam',
      'VAT Output Tax': 'KDV Çıkış Vergisi',
      'Total Amount Payable': 'Ödenecek Toplam Tutar',
      'Please make sure to pay on or before the payment due date above':
        'Lütfen yukarıdaki son ödeme tarihinde veya öncesinde ödeme yaptığınızdan emin olun',
      'Send money to the following account': 'Aşağıdaki hesaba para gönderin',
      'Account Name': 'Hesap Adı',
      'Bank Name': 'Banka Adı',
      'Bank Address': 'Banka Adresi',
      'RMB Account': 'RMB Hesabı',
      // button
      'Toggle Navigation': 'Navigasyonu Değiştir',
      // working calendar
      'Working Days': 'Çalışma Günleri',
      'Non Working Days': 'Çalışma Dışı Günler',
      'Select Row': 'Lütfen Veri Satırı Seçiniz',
      // Offline Meter Input
      'Daily Value': 'Günlük Değer',
      Date: 'Tarih',
      'Successfully Saved': 'Başarıyla Kaydedildi',
      'Previous Data Is Empty': 'Önceki veriler boş',
      // API
      'API.ACCOUNT_UNLOCK_FAILED': 'Account unlock failed',
      'API.ADMINISTRATOR_SESSION_NOT_FOUND': 'Administrator session not found',
      'API.ADMINISTRATOR_SESSION_TIMEOUT': 'Administrator session timeout',
      'API.ADVANCED_REPORT_NOT_FOUND': 'Advanced report not found',
      'API.API_KEY_HAS_EXPIRED': 'Key has expired',
      'API.API_KEY_NAME_IS_ALREADY_IN_USE': 'Key name is already in use',
      'API.API_KEY_NOT_FOUND': 'Key not found',
      'API.BAD_REQUEST': 'Bad request',
      'API.BALANCING_PRICE_POINT_NOT_FOUND': 'Balancing price point not found',
      'API.BATTERY_STATE_POINT_NOT_FOUND': 'Battery state point not found',
      'API.BUY_METER_NOT_FOUND': 'Buy meter not found',
      'API.CANNOT_SET_EXISTING_SUBMETER_AS_MASTER_METER': 'Cannot set existing submeter as master meter',
      'API.CHARGE_METER_NOT_FOUND': 'Charge meter not found',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_EXISTS': 'Combined equipment command relation exists',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Combined equipment command relation not found',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_EXISTS': 'Combined equipment equipment relation exists',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_NOT_FOUND': 'Combined equipment equipment relation not found',
      'API.COMBINED_EQUIPMENT_METER_RELATION_EXISTS': 'Combined equipment meter relation exists',
      'API.COMBINED_EQUIPMENT_METER_RELATION_NOT_FOUND': 'Combined equipment meter relation not found',
      'API.COMBINED_EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Combined equipment name is already in use',
      'API.COMBINED_EQUIPMENT_NOT_FOUND': 'Combined equipment not found',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Combined equipment offline meter relation exists',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Combined equipment offline meter relation not found',
      'API.COMBINED_EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Combined equipment parameter name is already in use',
      'API.COMBINED_EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Combined equipment parameter not found or not match',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Combined equipment virtual meter relation exists',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Combined equipment virtual meter relation not found',
      'API.COMMAND_NAME_IS_ALREADY_IN_USE': 'Command name is already in use',
      'API.COMMAND_NOT_FOUND': 'Command not  found',
      'API.CONTACT_NAME_IS_ALREADY_IN_USE': 'Contact name is already in use',
      'API.CONTACT_NOT_FOUND': 'Contact not found',
      'API.COOLING_METER_NOT_FOUND': 'Cooling meter not found',
      'API.COST_CENTER_EXTERNAL_ID_EXISTS': 'Cost center external id exists',
      'API.COST_CENTER_NAME_EXISTS': 'Cost center name exists',
      'API.COST_CENTER_NOT_FOUND': 'Cost center not found',
      'API.COST_FILE_NOT_FOUND': 'Cost file not found',
      'API.DATA_REPAIR_FILE_NOT_FOUND': 'Data repair file not found',
      'API.DATA_SOURCE_NAME_IS_ALREADY_IN_USE': 'Data source name is already in use',
      'API.DATA_SOURCE_NOT_FOUND': 'Data source not found',
      'API.DATABASE_ERROR': 'Database error',
      'API.DATE_IS_ALREADY_IN_WORKING_CALENDAR': 'Date Is Already In Working Calendar',
      'API.DATE_LOCAL_NOT_FOUND': 'Date local not found',
      'API.DISCHARGE_METER_NOT_FOUND': 'Discharge meter not found',
      'API.DISTRIBUTION_CIRCUIT_NAME_IS_ALREADY_IN_USE': 'Distribution circuit name is already in use',
      'API.DISTRIBUTION_CIRCUIT_NOT_FOUND': 'Distribution circuit not found',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_EXISTS': 'Distribution circuit point relation exists',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_NOT_FOUND': 'Distribution circuit point relation not found',
      'API.DISTRIBUTION_SYSTEM_NAME_IS_ALREADY_IN_USE': 'Distribution system name is already in use',
      'API.DISTRIBUTION_SYSTEM_NOT_FOUND': 'Distribution system not found',
      'API.ELECTRICITY_METER_NOT_FOUND': 'Electricity meter not found',
      'API.EMAIL_IS_ALREADY_IN_USE': 'Email is already in use',
      'API.EMAIL_MESSAGE_NOT_FOUND': 'Email message not found',
      'API.EMAIL_NOT_FOUND': 'Email not found',
      'API.EMAIL_SERVER_HOST_IS_ALREADY_IN_USE': 'Email server host is already in use',
      'API.EMAIL_SERVER_NOT_FOUND': 'Email server not found',
      'API.EMPTY_VARIABLES_ARRAY': 'Empty variables array',
      'API.ENERGY_CATEGORY_NAME_IS_ALREADY_IN_USE': 'Energy category name is already in use',
      'API.ENERGY_CATEGORY_NOT_FOUND': 'Energy category not found',
      'API.ENERGY_CATEGORY_USED_IN_ENERGY_ITEMS': 'Energy category used in energy items',
      'API.ENERGY_CATEGORY_USED_IN_METER': 'Energy category used in meter',
      'API.ENERGY_CATEGORY_USED_IN_OFFLINE_METER': 'Energy category used in offline meter',
      'API.ENERGY_CATEGORY_USED_IN_TARIFFS': 'Energy category used in tariffs',
      'API.ENERGY_CATEGORY_USED_IN_VIRTUAL_METER': 'Energy category used in virtual meter',
      'API.ENERGY_FLOW_DIAGRAM_LINK_IS_ALREADY_IN_USE': 'Energy flow diagram link is already in use',
      'API.ENERGY_FLOW_DIAGRAM_LINK_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram link not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram node name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram node not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NOT_FOUND': 'Energy flow diagram not found',
      'API.ENERGY_ITEM_DOES_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item does not belong to energy category',
      'API.ENERGY_ITEM_IS_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item is not belong to energy category',
      'API.ENERGY_ITEM_NAME_IS_ALREADY_IN_USE': 'Energy item name is already in use',
      'API.ENERGY_ITEM_NOT_FOUND': 'Energy item not found',
      'API.ENERGY_ITEM_USED_IN_METER': 'Energy item used in meter',
      'API.ENERGY_ITEM_USED_IN_OFFLINE_METER': 'Energy item used in offline meter',
      'API.ENERGY_ITEM_USED_IN_VIRTUAL_METER': 'Energy item used in virtual meter',
      'API.ENERGY_STORAGE_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Energy storage power station name is already in use',
      'API.ENERGY_STORAGE_POWER_STATION_NOT_FOUND': 'Energy storage power station not found',
      'API.EQUIPMENT_COMMAND_RELATION_EXISTS': 'Equipment command relation exists',
      'API.EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Equipment command relation not found',
      'API.EQUIPMENT_METER_RELATION_EXISTS': 'Equipment meter relation exists',
      'API.EQUIPMENT_METER_RELATION_NOT_FOUND': 'Equipment meter relation not found',
      'API.EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Equipment name is already in use',
      'API.EQUIPMENT_NOT_FOUND': 'Equipment not found',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Equipment offline meter relation exists',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Equipment offline meter relation not found',
      'API.EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Equipment parameter name is already in use',
      'API.EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Equipment parameter not found or not match',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Equipment virtual meter relation exists',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Equipment virtual meter relation not found',
      'API.ERROR': 'Error',
      'API.EXCEPTION': 'Exception',
      'API.FAILED_TO_READ_REQUEST_STREAM': 'Failed to read request stream',
      'API.FAILED_TO_RESTORE_COST_FILE': 'Failed to restore cost file',
      'API.FAILED_TO_RESTORE_DATA_REPAIR_FILE': 'Failed to restore data repair file',
      'API.FAILED_TO_RESTORE_KNOWLEDGE_FILE': 'Failed to restore knowledge file',
      'API.FAILED_TO_RESTORE_OFFLINE_METER_FILE': 'Failed to restore offline meter file',
      'API.FAILED_TO_SAVE_OFFLINE_METER_FILE': 'Failed to save offline meter file',
      'API.FAILED_TO_UPLOAD_ATTACHMENT_FILE': 'Failed to upload attachment file',
      'API.FAILED_TO_UPLOAD_COST_FILE': 'Failed to upload cost file',
      'API.FAILED_TO_UPLOAD_DATA_REPAIR_FILE': 'Failed to upload data repair file',
      'API.FAILED_TO_UPLOAD_KNOWLEDGE_FILE': 'Failed to upload knowledge file',
      'API.FAILED_TO_UPLOAD_OFFLINE_METER_FILE': 'Failed to upload offline meter file',
      'API.GATEWAY_NAME_IS_ALREADY_IN_USE': 'Gateway name is already in use',
      'API.GATEWAY_NOT_FOUND': 'Gateway not found',
      'API.HEAT_METER_NOT_FOUND': 'Heat meter not found',
      'API.INVALID_': 'Invalid ',
      'API.INVALID_ACKNOWLEDGE_CODE': 'Invalid acknowledge code',
      'API.INVALID_ADDRESS': 'Invalid address',
      'API.INVALID_ADDRESS_VALUE': 'Invalid address value',
      'API.INVALID_ADVANCED_REPORT_ID': 'Invalid advanced report id',
      'API.INVALID_API_KEY_ID': 'Invalid api key id',
      'API.INVALID_API_KEY_NAME': 'Invalid api key name',
      'API.INVALID_AREA_VALUE': 'Invalid area value',
      'API.INVALID_BALANCING_PRICE_POINT_ID': 'Invalid balancing price point ID',
      'API.INVALID_BASE_PERIOD_END_DATETIME': 'Invalid base period end datetime',
      'API.INVALID_BASE_PERIOD_START_DATETIME': 'Invalid base period start datetime',
      'API.INVALID_BATTERY_STATE_POINT_ID': 'Invalid battery state point ID',
      'API.INVALID_BAUD_RATE': 'Invalid baud rate',
      'API.INVALID_BUILDINGS_VALUE': 'Invalid buildings value',
      'API.INVALID_BUY_METER_ID': 'Invalid buy meter id',
      'API.INVALID_CAPACITY': 'Invalid capacity',
      'API.INVALID_CATEGORY': 'Invalid category',
      'API.INVALID_CHANNEL': 'Invalid channel',
      'API.INVALID_CHARGE_METER_ID': 'Invalid charge meter id',
      'API.INVALID_COMBINED_EQUIPMENT_ID': 'Invalid combined equipment id',
      'API.INVALID_COMBINED_EQUIPMENT_NAME': 'Invalid combined equipment name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_ID': 'Invalid combined equipment parameter id',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_NAME': 'Invalid combined equipment parameter name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_TYPE': 'Invalid combined equipment parameter type',
      'API.INVALID_COMBINED_EQUIPMENT_UUID': 'Invalid combined equipment uuid',
      'API.INVALID_COMMAND_ID': 'Invalid command id',
      'API.INVALID_COMMAND_NAME': 'Invalid command name',
      'API.INVALID_CONNECTION': 'Invalid connection',
      'API.INVALID_CONSTANT_VALUE': 'Invalid constant value',
      'API.INVALID_CONTACT_ID': 'Invalid contact id',
      'API.INVALID_CONTACT_NAME': 'Invalid contact name',
      'API.INVALID_COOLING_METER_ID': 'Invalid cooling meter id',
      'API.INVALID_COST_CENTER_ID': 'Invalid cost center id',
      'API.INVALID_COST_FILE_ID': 'Invalid cost file id',
      'API.INVALID_CREATED_DATETIME': 'Invalid created datetime',
      'API.INVALID_DATA_REPAIR_FILE_ID': 'Invalid data repair file id',
      'API.INVALID_DATA_SOURCE_ID': 'Invalid data source id',
      'API.INVALID_DATA_SOURCE_NAME': 'Invalid data source name',
      'API.INVALID_DATA_SOURCE_PROTOCOL': 'Invalid data source protocol',
      'API.INVALID_DATE_LOCAL': 'Invalid date local',
      'API.INVALID_DENOMINATOR_METER_UUID': 'Invalid denominator meter uuid',
      'API.INVALID_DISCHARGE_METER_ID': 'Invalid discharge meter id',
      'API.INVALID_DISPLAY_NAME': 'Invalid display name',
      'API.INVALID_DISTRIBUTION_CIRCUIT_ID': 'Invalid distribution circuit id',
      'API.INVALID_DISTRIBUTION_CIRCUIT_NAME': 'Invalid distribution circuit name',
      'API.INVALID_DISTRIBUTION_ROOM': 'Invalid distribution room',
      'API.INVALID_DISTRIBUTION_SYSTEM_ID': 'Invalid distribution system id',
      'API.INVALID_DISTRIBUTION_SYSTEM_NAME': 'Invalid distribution system name',
      'API.INVALID_ELECTRICITY_METER_ID': 'Invalid Electricity meter id',
      'API.INVALID_EMAIL': 'Invalid email',
      'API.INVALID_EMAIL_MESSAGE_ID': 'Invalid email message id',
      'API.INVALID_EMAIL_SERVER_HOST': 'Invalid email server host',
      'API.INVALID_EMAIL_SERVER_ID': 'Invalid email server id',
      'API.INVALID_END_DATE_FORMAT': 'Invalid end date format',
      'API.INVALID_END_DATETIME': 'Invalid end datetime',
      'API.INVALID_ENERGY_CATEGORY_ID': 'Invalid energy category id',
      'API.INVALID_ENERGY_CATEGORY_NAME': 'Invalid energy category name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_ID': 'Invalid energy flow diagram id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_LINK_ID': 'Invalid energy flow diagram link id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NAME': 'Invalid energy flow diagram name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_ID': 'Invalid energy flow diagram node id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_NAME': 'Invalid energy flow diagram node name',
      'API.INVALID_ENERGY_ITEM_ID': 'Invalid energy item id',
      'API.INVALID_ENERGY_ITEM_NAME': 'Invalid energy item name',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_ID': 'Invalid energy storage power station ID',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_NAME': 'Invalid energy storage power station name',
      'API.INVALID_EQUATION_IN_EXPRESSION': 'Invalid equation in expression',
      'API.INVALID_EQUIPMENT_ID': 'Invalid equipment id',
      'API.INVALID_EQUIPMENT_NAME': 'Invalid equipment name',
      'API.INVALID_EQUIPMENT_PARAMETER_ID': 'Invalid equipment parameter id',
      'API.INVALID_EQUIPMENT_PARAMETER_NAME': 'Invalid equipment parameter name',
      'API.INVALID_EQUIPMENT_PARAMETER_TYPE': 'Invalid equipment parameter type',
      'API.INVALID_EQUIPMENT_UUID': 'Invalid equipment uuid',
      'API.INVALID_EXPIRES_DATETIME': 'Invalid expires datetime',
      'API.INVALID_EXPRESSION': 'Invalid expression',
      'API.INVALID_EXPRESSION_OBJECT': 'Invalid expression object',
      'API.INVALID_FDD_CODE': 'Invalid fdd code',
      'API.INVALID_FLOORS_VALUE': 'Invalid floors value',
      'API.INVALID_FROM_ADDR': 'Invalid from addr',
      'API.INVALID_GATEWAY_ID': 'Invalid gateway id',
      'API.INVALID_GATEWAY_NAME': 'Invalid gateway name',
      'API.INVALID_HEAT_METER_ID': 'Invalid heat meter id',
      'API.INVALID_HIGH_LIMIT_VALUE': 'Invalid high limit value',
      'API.INVALID_HOURLY_HIGH_LIMIT_VALUE': 'Invalid hourly high limit value',
      'API.INVALID_HOURLY_LOW_LIMIT_VALUE': 'Invalid hourly low limit value',
      'API.INVALID_Id': 'Invalid id',
      'API.INVALID_IS_ADMIN_VALUE': 'Invalid is admin value',
      'API.INVALID_IS_COST_DATA_DISPLAYED': 'Invalid is cost data displayed',
      'API.INVALID_IS_COUNTED_VALUE': 'Invalid is counted value',
      'API.INVALID_IS_ENABLED': 'Invalid is enabled',
      'API.INVALID_IS_HIDDEN': 'Invalid is hidden',
      'API.INVALID_IS_IN_LEASE_VALUE': 'Invalid is in lease value',
      'API.INVALID_IS_INPUT_COUNTED_VALUE': 'Invalid is input counted value',
      'API.INVALID_IS_KEY_TENANT_VALUE': 'Invalid is key tenant value',
      'API.INVALID_IS_OUTPUT_COUNTED_VALUE': 'Invalid is output counted value',
      'API.INVALID_IS_OUTPUT_VALUE': 'Invalid is output value',
      'API.INVALID_IS_READ_ONLY_VALUE': 'Invalid is read only value',
      'API.INVALID_IS_TREND_VALUE': 'Invalid is trend value',
      'API.INVALID_IS_VIRTUAL_VALUE': 'Invalid is virtual value',
      'API.INVALID_KGCE': 'Invalid kgce',
      'API.INVALID_KGCO2E': 'Invalid kgco2e',
      'API.INVALID_KNOWLEDGE_FILE_ID': 'Invalid knowledge file id',
      'API.INVALID_LATITUDE_VALUE': 'Invalid latitude value',
      'API.INVALID_LEASE_NUMBER_VALUE': 'Invalid lease number value',
      'API.INVALID_LONGITUDE_VALUE': 'Invalid longitude value',
      'API.INVALID_LOW_LIMIT_VALUE': 'Invalid low limit value',
      'API.INVALID_MASTER_METER_ID': 'Invalid master meter id',
      'API.INVALID_MENU_ID': 'Invalid menu id',
      'API.INVALID_MESSAGE_DATA': 'Invalid message data',
      'API.INVALID_MESSAGE_TEMPLATE': 'Invalid message template',
      'API.INVALID_MESSAGE_TEMPLATE_ID': 'Invalid message template id',
      'API.INVALID_MESSAGE_VALUE': 'Invalid message value',
      'API.INVALID_METER_ID': 'Invalid meter id',
      'API.INVALID_METER_NAME': 'Invalid meter name',
      'API.INVALID_METER_UUID': 'Invalid meter uuid',
      'API.INVALID_MICROGRID_BATTERY_ID': 'Invalid microgrid battery id',
      'API.INVALID_MICROGRID_BATTERY_NAME': 'Invalid microgrid battery name',
      'API.INVALID_MICROGRID_EVCHARGER_ID': 'Invalid microgrid evcharger id',
      'API.INVALID_MICROGRID_EVCHARGER_NAME': 'Invalid microgrid evcharger name',
      'API.INVALID_MICROGRID_GENERATOR_ID': 'Invalid microgrid generator id',
      'API.INVALID_MICROGRID_GENERATOR_NAME': 'Invalid microgrid generator name',
      'API.INVALID_MICROGRID_GRID_ID': 'Invalid microgrid grid id',
      'API.INVALID_MICROGRID_GRID_NAME': 'Invalid microgrid grid name',
      'API.INVALID_MICROGRID_HEATPUMP_ID': 'Invalid microgrid heatpump id',
      'API.INVALID_MICROGRID_HEATPUMP_NAME': 'Invalid microgrid heatpump name',
      'API.INVALID_MICROGRID_ID': 'Invalid microgrid id',
      'API.INVALID_MICROGRID_LOAD_ID': 'Invalid microgrid load id',
      'API.INVALID_MICROGRID_LOAD_NAME': 'Invalid microgrid load name',
      'API.INVALID_MICROGRID_NAME': 'Invalid microgrid name',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_ID': 'Invalid microgrid photovoltaic ID',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_NAME': 'Invalid microgrid photovoltaic name',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_ID': 'Invalid microgrid power conversion system ID',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_NAME': 'Invalid microgrid microgrid power conversion system name',
      'API.INVALID_MICROGRID_UUID': 'Invalid microgrid UUID',
      'API.INVALID_NAME_VALUE': 'Invalid name value',
      'API.INVALID_NEW_PASSWORD': 'Invalid new password',
      'API.INVALID_NON_WORKING_DAY_ID': 'Invalid non working day ID',
      'API.INVALID_NOTIFICATION_ID': 'Invalid notification id',
      'API.INVALID_NUMERATOR_METER_UUID': 'Invalid numerator meter uuid',
      'API.INVALID_OBJECT_TYPE': 'Invalid object type',
      'API.INVALID_OFFLINE_METER_DATA': 'Invalid offline meter data',
      'API.INVALID_OFFLINE_METER_FILE_ID': 'Invalid offline meter file id',
      'API.INVALID_OFFLINE_METER_ID': 'Invalid offline meter id',
      'API.INVALID_OFFLINE_METER_NAME': 'Invalid offline meter name',
      'API.INVALID_OFFLINE_METER_UUID': 'Invalid offline meter uuid',
      'API.INVALID_OFFSET_CONSTANT_VALUE': 'Invalid offset constant value',
      'API.INVALID_OLD_PASSWORD': 'Invalid old password',
      'API.INVALID_OPENID': 'Invalid openid',
      'API.INVALID_PARENT_SPACE_ID': 'Invalid parent space id',
      'API.INVALID_PASSWORD': 'Invalid password',
      'API.INVALID_PAYLOAD': 'Invalid payload',
      'API.INVALID_PEAK_CURRENT': 'Invalid peak current',
      'API.INVALID_PEAK_LOAD': 'Invalid peak load',
      'API.INVALID_PERIOD_TYPE': 'Invalid period type',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_ID': 'Invalid photovoltaic power station ID',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_NAME': 'Invalid photovoltaic power station name',
      'API.INVALID_POINT_ID': 'Invalid point id',
      'API.INVALID_POINT_NAME': 'Invalid point name',
      'API.INVALID_PORT': 'Invalid port',
      'API.INVALID_POSTAL_CODE_VALUE': 'Invalid postal code value',
      'API.INVALID_POWER_POINT_ID': 'Invalid power point id',
      'API.INVALID_PRIORITY': 'Invalid priority',
      'API.INVALID_PRIVILEGE': 'Invalid privilege',
      'API.INVALID_PRIVILEGE_DATA': 'Invalid privilege data',
      'API.INVALID_PRIVILEGE_ID': 'Invalid privilege id',
      'API.INVALID_PRIVILEGE_NAME': 'Invalid privilege name',
      'API.INVALID_RATIO_VALUE': 'Invalid ratio value',
      'API.INVALID_RECIPIENT_EMAIL': 'Invalid recipient email',
      'API.INVALID_RECIPIENT_MOBILE': 'Invalid recipient mobile',
      'API.INVALID_RECIPIENT_NAME': 'Invalid recipient name',
      'API.INVALID_RECIPIENT_OPENID': 'Invalid recipient openid',
      'API.INVALID_REPLY': 'Invalid reply',
      'API.INVALID_REPORTING_PERIOD_END_DATETIME': 'Invalid reporting period end datetime',
      'API.INVALID_REPORTING_PERIOD_START_DATETIME': 'Invalid reporting period start datetime',
      'API.INVALID_REQUIRES_AUTHENTICATION': 'Invalid requires authentication',
      'API.INVALID_ROOMS_VALUE': 'Invalid rooms value',
      'API.INVALID_RULE_ID': 'Invalid rule id',
      'API.INVALID_RULE_NAME': 'Invalid rule name',
      'API.INVALID_RUN_STATE_POINT_ID': 'Invalid run state point ID',
      'API.INVALID_SCHEDULED_DATETIME': 'Invalid scheduled datetime',
      'API.INVALID_SELL_METER_ID': 'Invalid sell meter id',
      'API.INVALID_SENSOR_ID': 'Invalid sensor id',
      'API.INVALID_SENSOR_NAME': 'Invalid sensor name',
      'API.INVALID_SENSOR_UUID': 'Invalid sensor uuid',
      'API.INVALID_SERIAL_NUMBER': 'Invalid serial number',
      'API.INVALID_SERIAL_PORT': 'Invalid serial port',
      'API.INVALID_SESSION_PLEASE_RE_LOGIN': 'Invalid session please re-login',
      'API.INVALID_SET_VALUE': 'Invalid set value',
      'API.INVALID_SHOPFLOOR_ID': 'Invalid shopfloor id',
      'API.INVALID_SHOPFLOOR_NAME': 'Invalid shopfloor name',
      'API.INVALID_SHOPFLOOR_UUID': 'Invalid shopfloor uuid',
      'API.INVALID_SOURCE_NODE_ID': 'Invalid source node id',
      'API.INVALID_SPACE_ID': 'Invalid space id',
      'API.INVALID_SPACE_NAME': 'Invalid space name',
      'API.INVALID_SPACE_UUID': 'Invalid space uuid',
      'API.INVALID_START_DATE_FORMAT': 'Invalid start date format',
      'API.INVALID_START_DATETIME': 'Invalid start datetime',
      'API.INVALID_STATUS': 'Invalid status',
      'API.INVALID_STORE_ID': 'Invalid store id',
      'API.INVALID_STORE_NAME': 'Invalid store name',
      'API.INVALID_STORE_TYPE_DESCRIPTION': 'Invalid store type description',
      'API.INVALID_STORE_TYPE_ID': 'Invalid store type id',
      'API.INVALID_STORE_TYPE_NAME': 'Invalid store type name',
      'API.INVALID_STORE_TYPE_SIMPLIFIED_CODE': 'Invalid store type simplified code',
      'API.INVALID_STORE_UUID': 'Invalid store uuid',
      'API.INVALID_SUBJECT_VALUE': 'Invalid subject value',
      'API.INVALID_SVG': 'Invalid SVG',
      'API.INVALID_SWITCHGEAR': 'Invalid switchgear',
      'API.INVALID_TARGET_NODE_ID': 'Invalid target node id',
      'API.INVALID_TARIFF_BLOCK_PRICING': 'Invalid tariff block pricing',
      'API.INVALID_TARIFF_ID': 'Invalid tariff id',
      'API.INVALID_TARIFF_TIME_OF_USE_PRICING': 'Invalid tariff time of use pricing',
      'API.INVALID_TARIFF_TYPE': 'Invalid tariff type',
      'API.INVALID_TENANT_ID': 'Invalid tenant id',
      'API.INVALID_TENANT_NAME': 'Invalid tenant name',
      'API.INVALID_TENANT_TYPE_DESCRIPTION': 'Invalid tenant type description',
      'API.INVALID_TENANT_TYPE_ID': 'Invalid tenant type id',
      'API.INVALID_TENANT_TYPE_NAME': 'Invalid tenant type name',
      'API.INVALID_TENANT_TYPE_SIMPLIFIED_CODE': 'Invalid tenant type simplified code',
      'API.INVALID_TENANT_UUID': 'Invalid tenant uuid',
      'API.INVALID_TEXT_MESSAGE_ID': 'Invalid text message id',
      'API.INVALID_TIMEZONE_ID': 'Invalid timezone id',
      'API.INVALID_TOKEN': 'Invalid token',
      'API.INVALID_TOPIC': 'Invalid topic',
      'API.INVALID_UNIT_OF_MEASURE': 'Invalid unit of measure',
      'API.INVALID_UNIT_OF_PRICE': 'Invalid unit of price',
      'API.INVALID_UNITS': 'Invalid units',
      'API.INVALID_USER_ID': 'Invalid user id',
      'API.INVALID_USER_NAME': 'Invalid user name',
      'API.INVALID_USER_NAME_OR_EMAIL': 'Invalid user name or email',
      'API.INVALID_USER_PHONE': 'Invalid user phone',
      'API.INVALID_USER_PLEASE_RE_LOGIN': 'Invalid user please re-login',
      'API.INVALID_USER_UUID': 'Invalid user uuid',
      'API.INVALID_USERNAME': 'Invalid userName',
      'API.INVALID_VARIABLE_METER_ID': 'Invalid variable meter id',
      'API.INVALID_VARIABLE_METER_TYPE': 'Invalid variable meter type',
      'API.INVALID_VARIABLE_NAME': 'Invalid variable name',
      'API.INVALID_VERIFICATION_CODE': 'Invalid verification code',
      'API.INVALID_VIRTUAL_METER_ID': 'Invalid virtual meter id',
      'API.INVALID_VIRTUAL_METER_NAME': 'Invalid virtual meter name',
      'API.INVALID_VIRTUAL_METER_UUID': 'Invalid virtual meter uuid',
      'API.INVALID_WEB_MESSAGE_ID': 'Invalid web message id',
      'API.INVALID_WECHAT_MESSAGE_ID': 'Invalid wechat message id',
      'API.INVALID_WIND_FARM_ID': 'Invalid wind farm ID',
      'API.INVALID_WIND_FARM_NAME': 'Invalid wind farm name',
      'API.INVALID_WORKING_CALENDAR_ID': 'Invalid working calendar id ',
      'API.INVALID_WORKING_CALENDAR_NAME': 'Invalid working calendar name',
      'API.KNOWLEDGE_FILE_CANNOT_BE_REMOVED_FROM_DISK': 'Knowledge File Cannot be Removed from Disk',
      'API.KNOWLEDGE_FILE_NOT_FOUND': 'Knowledge file not found',
      'API.MASTER_METER_DOES_NOT_BELONG_TO_SAME_ENERGY_CATEGORY':
        'Master meter does not belong to same energy category',
      'API.MASTER_METER_NOT_FOUND': 'Master meter not found',
      'API.MENU_NOT_FOUND': 'Menu not found',
      'API.METER_CANNOT_HAVE_MORE_THAN_ONE_ENERGY_VALUE_POINTS': 'Meter cannot have more than one energy value points',
      'API.METER_COMMAND_RELATION_EXISTS': 'Meter command relation exists',
      'API.METER_COMMAND_RELATION_NOT_FOUND': 'Meter command relation not found',
      'API.METER_NAME_IS_ALREADY_IN_USE': 'Meter name is already in use',
      'API.METER_NOT_FOUND': 'Meter not found',
      'API.METER_OF_VARIABLE_NOT_FOUND': 'Meter of variable not found',
      'API.METER_POINT_RELATION_EXISTS': 'Meter point relation exists',
      'API.METER_POINT_RELATION_NOT_FOUND': 'Meter point relation not found',
      'API.MICROGRID_BATTERY_NAME_IS_ALREADY_IN_USE': 'Microgrid battery name is already in use',
      'API.MICROGRID_BATTERY_NOT_FOUND': 'Microgrid battery not found',
      'API.MICROGRID_EVCHARGER_NAME_IS_ALREADY_IN_USE': 'Microgrid evcharger name is already in use',
      'API.MICROGRID_EVCHARGER_NOT_FOUND': 'Microgrid evcharger not fund',
      'API.MICROGRID_GENERATOR_NAME_IS_ALREADY_IN_USE': 'Microgrid generator name is already in use',
      'API.MICROGRID_GENERATOR_NOT_FOUND': 'Microgrid generator not found',
      'API.MICROGRID_GRID_NAME_IS_ALREADY_IN_USE': 'Microgrid grid name is already in use',
      'API.MICROGRID_GRID_NOT_FOUND': 'Microgrid grid not found',
      'API.MICROGRID_HEATPUMP_NAME_IS_ALREADY_IN_USE': 'Microgrid heatpump name is already in use',
      'API.MICROGRID_HEATPUMP_NOT_FOUND': 'Microgrid heatpump not found',
      'API.MICROGRID_LOAD_NAME_IS_ALREADY_IN_USE': 'Microgrid load name is already',
      'API.MICROGRID_LOAD_NOT_FOUND': 'Microgrid load not found',
      'API.MICROGRID_NAME_IS_ALREADY_IN_USE': 'Microgrid name is already in use',
      'API.MICROGRID_NOT_FOUND': 'Microgrid not found',
      'API.MICROGRID_PHOTOVOLTAIC_NAME_IS_ALREADY_IN_USE': 'Microgrid photovoltaic name is already in use',
      'API.MICROGRID_PHOTOVOLTAIC_NOT_FOUND': 'Microgrid photovoltaic not found',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NAME_IS_ALREADY_IN_USE':
        'Microgrid power conversion system name is already in use',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NOT_FOUND': 'Microgrid power conversion system not found',
      'API.MICROGRID_SENSOR_RELATION_EXISTS': 'Microgrid sensor relation exists',
      'API.MICROGRID_SENSOR_RELATION_NOT_FOUND': 'Microgrid sensor not found',
      'API.MQTT_CONNECTION_ERROR': 'Mqtt connection error',
      'API.MQTT_PUBLISH_ERROR': 'Mqtt publish error',
      'API.NEW_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'New password length cannot exceed 100 characters',
      'API.NEW_USER_SESSION_NOT_FOUND': 'New user session not found',
      'API.NEW_USER_SESSION_TIMEOUT': 'New user session timeout',
      'API.NON_WORKING_DAY_NOT_FOUND': 'Non working day non found',
      'API.NOT_FOUND': 'Not found',
      'API.NOTIFICATION_NOT_FOUND': 'Notification not found',
      'API.OFFLINE_METER_FILE_NOT_FOUND': 'Offline meter file not found',
      'API.OFFLINE_METER_NAME_IS_ALREADY_IN_USE': 'Offline meter name is already in use',
      'API.OFFLINE_METER_NOT_FOUND': 'Offline meter not found',
      'API.OFFLINE_METER_OF_VARIABLE_NOT_FOUND': 'Offline meter of variable not found',
      'API.OLD_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Old password length cannot exceed 100 characters',
      'API.PARENT_SPACE_NOT_FOUND': 'Parent space not found',
      'API.PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Password length cannot exceed 100 characters',
      'API.PASSWORDS_MATCH': 'New password is the same as the old password',
      'API.PHOTOVOLTAIC_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Photovoltaic power station name is already in use',
      'API.PHOTOVOLTAIC_POWER_STATION_NOT_FOUND': 'Photovoltaic power station not found',
      'API.POINT_NAME_IS_ALREADY_IN_USE': 'Point name is already in use',
      'API.POINT_NOT_FOUND': 'Point not found',
      'API.POWER_POINT_NOT_FOUND': 'Power point not found',
      'API.PRIVILEGE_NAME_IS_ALREADY_IN_USE': 'Privilege name is already in use',
      'API.PRIVILEGE_NOT_FOUND': 'Privilege not found',
      'API.RULE_NAME_IS_ALREADY_IN_USE': 'Rule name is already in use',
      'API.RULE_NOT_FOUND': 'Rule not found',
      'API.SELL_METER_NOT_FOUND': 'Sell meter not found',
      'API.SENSOR_NAME_IS_ALREADY_IN_USE': 'Sensor name is already in use',
      'API.SENSOR_NOT_FOUND': 'Sensor not found',
      'API.SENSOR_POINT_RELATION_EXISTS': 'Sensor point relation exists',
      'API.SENSOR_POINT_RELATION_NOT_FOUND': 'Sensor point relation not found',
      'API.SHOPFLOOR_COMMAND_RELATION_EXISTS': 'Shopfloor command relation exists',
      'API.SHOPFLOOR_COMMAND_RELATION_NOT_FOUND': 'Shopfloor command relation not found',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_EXISTS': 'Shopfloor equipment relation exists',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_NOT_FOUND': 'Shopfloor equipment relation not found',
      'API.SHOPFLOOR_METER_RELATION_EXISTS': 'Shopfloor meter relation exists',
      'API.SHOPFLOOR_METER_RELATION_NOT_FOUND': 'Shopfloor meter relation not found',
      'API.SHOPFLOOR_NAME_IS_ALREADY_IN_USE': 'Shopfloor name is already in use',
      'API.SHOPFLOOR_NOT_FOUND': 'Shopfloor not found',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_EXISTS': 'Shopfloor offline meter relation exists',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_NOT_FOUND': 'Shopfloor offline meter relation not found',
      'API.SHOPFLOOR_POINT_RELATION_EXISTS': 'Shopfloor point relation exists',
      'API.SHOPFLOOR_POINT_RELATION_NOT_FOUND': 'Shopfloor point relation not found',
      'API.SHOPFLOOR_SENSOR_RELATION_EXISTS': 'Shopfloor sensor relation exists',
      'API.SHOPFLOOR_SENSOR_RELATION_NOT_FOUND': 'Shopfloor sensor relation not found',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_EXISTS': 'Shopfloor virtual meter relation exists',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_NOT_FOUND': 'Shopfloor virtual meter relation not found',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_EXISTS': 'Shopfloor working calendar relation exists',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Shopfloor working calendar relation not found',
      'API.SOC_POINT_NOT_FOUND': 'SOC point not found',
      'API.SOURCE_NODE_NOT_FOUND': 'Source node not found',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_EXISTS': 'Space combined equipment relation exists',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_NOT_FOUND': 'Space combined equipment relation not found',
      'API.SPACE_COMMAND_RELATION_EXISTS': 'Space command relation exists',
      'API.SPACE_COMMAND_RELATION_NOT_FOUND': 'Space command relation not found',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_EXISTS': 'Space Energy Storage Power Station Relation Exists',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_NOT_FOUND': 'Space Energy Storage Power Station Relation Not Found',
      'API.SPACE_EQUIPMENT_RELATION_EXISTS': 'Space equipment relation exists',
      'API.SPACE_EQUIPMENT_RELATION_NOT_FOUND': 'Space equipment relation not found',
      'API.SPACE_METER_RELATION_EXISTS': 'Space meter relation exists',
      'API.SPACE_METER_RELATION_NOT_FOUND': 'Space meter relation not found',
      'API.SPACE_NAME_IS_ALREADY_IN_USE': 'Space name is already in use',
      'API.SPACE_NOT_FOUND': 'Space not found',
      'API.SPACE_NOT_FOUND_IN_PRIVILEGE': 'Space not found in privilege',
      'API.SPACE_OFFLINE_METER_RELATION_EXISTS': 'Space offline meter relation exists',
      'API.SPACE_OFFLINE_METER_RELATION_NOT_FOUND': 'Space offline meter relation not found',
      'API.SPACE_POINT_RELATION_EXISTS': 'Space point relation exists',
      'API.SPACE_POINT_RELATION_NOT_FOUND': 'Space point relation not found',
      'API.SPACE_SENSOR_RELATION_EXISTS': 'Space sensor relation exists',
      'API.SPACE_SENSOR_RELATION_NOT_FOUND': 'Space sensor relation not found',
      'API.SPACE_SHOPFLOOR_RELATION_EXISTS': 'Space shopfloor relation exists',
      'API.SPACE_SHOPFLOOR_RELATION_NOT_FOUND': 'Space shopfloor relation not found',
      'API.SPACE_STORE_RELATION_EXISTS': 'Space store relation exists',
      'API.SPACE_STORE_RELATION_NOT_FOUND': 'Space store relation not found',
      'API.SPACE_TENANT_RELATION_EXISTS': 'Space tenant relation exists',
      'API.SPACE_TENANT_RELATION_NOT_FOUND': 'Space tenant relation not found',
      'API.SPACE_VIRTUAL_METER_RELATION_EXISTS': 'Space virtual meter relation exists',
      'API.SPACE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Space virtual meter relation not found',
      'API.SPACE_WORKING_CALENDAR_RELATION_EXISTS': 'Space working calendar relation exists',
      'API.SPACE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Space working calendar relation not found',
      'API.START_DATETIME_MUST_BE_EARLIER_THAN_END_DATETIME': 'Start datetime must be early than end datetime',
      'API.STORE_COMMAND_RELATION_EXISTS': 'Store command relation exists',
      'API.STORE_COMMAND_RELATION_NOT_FOUND': 'Store command relation not found',
      'API.STORE_METER_RELATION_EXISTS': 'Store meter relation exists',
      'API.STORE_METER_RELATION_NOT_FOUND': 'Store meter relation not found',
      'API.STORE_NAME_IS_ALREADY_IN_USE': 'Store name is already in use',
      'API.STORE_NOT_FOUND': 'Store not found',
      'API.STORE_OFFLINE_METER_RELATION_EXISTS': 'Store offline meter relation exists',
      'API.STORE_OFFLINE_METER_RELATION_NOT_FOUND': 'Store offline meter relation not found',
      'API.STORE_POINT_RELATION_EXISTS': 'Store point relation exists',
      'API.STORE_POINT_RELATION_NOT_FOUND': 'Store point relation not found',
      'API.STORE_SENSOR_RELATION_EXISTS': 'Store sensor relation exists',
      'API.STORE_SENSOR_RELATION_NOT_FOUND': 'Store sensor relation not found',
      'API.STORE_TYPE_NAME_IS_ALREADY_IN_USE': 'Store type name is already in use',
      'API.STORE_TYPE_NOT_FOUND': 'Store type not found',
      'API.STORE_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Store type simplified code is already in use',
      'API.STORE_TYPE_USED_IN_STORE': 'Store type used in store',
      'API.STORE_VIRTUAL_METER_RELATION_EXISTS': 'Store virtual meter relation exists',
      'API.STORE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Store virtual meter relation not found',
      'API.STORE_WORKING_CALENDAR_RELATION_EXISTS': 'Store working calendar relation exists',
      'API.STORE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Store working calendar relation not found',
      'API.TARGET_NODE_NOT_FOUND': 'Target node not found',
      'API.TARIFF_IN_USE': 'Tariff in use',
      'API.TARIFF_IS_ALREADY_ASSOCIATED_WITH_COST_CENTER': 'Tariff is already associated with cost center',
      'API.TARIFF_IS_NOT_ASSOCIATED_WITH_COST_CENTER': 'Tariff is not associated with cost center',
      'API.TARIFF_NAME_IS_ALREADY_IN_USE': 'Tariff name is already in use',
      'API.TARIFF_NOT_FOUND': 'Tariff not found',
      'API.TENANT_COMMAND_RELATION_EXISTS': 'Tenant command relation exists',
      'API.TENANT_COMMAND_RELATION_NOT_FOUND': 'Tenant command relation  not found',
      'API.TENANT_METER_RELATION_EXISTS': 'Tenant meter relation exists',
      'API.TENANT_METER_RELATION_NOT_FOUND': 'Tenant meter relation not found',
      'API.TENANT_NAME_IS_ALREADY_IN_USE': 'Tenant name is already in use',
      'API.TENANT_NOT_FOUND': 'Tenant not found',
      'API.TENANT_OFFLINE_METER_RELATION_EXISTS': 'Tenant offline meter relation exists',
      'API.TENANT_OFFLINE_METER_RELATION_NOT_FOUND': 'Tenant offline meter relation not found',
      'API.TENANT_POINT_RELATION_EXISTS': 'Tenant point relation exists',
      'API.TENANT_POINT_RELATION_NOT_FOUND': 'Tenant point relation not found',
      'API.TENANT_SENSOR_RELATION_EXISTS': 'Tenant sensor relation exists',
      'API.TENANT_SENSOR_RELATION_NOT_FOUND': 'Tenant sensor relation not found',
      'API.TENANT_TYPE_NAME_IS_ALREADY_IN_USE': 'Tenant type name is already in use',
      'API.TENANT_TYPE_NOT_FOUND': 'Tenant type not found',
      'API.TENANT_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Tenant type simplified code is already in use',
      'API.TENANT_TYPE_USED_IN_TENANT': 'Tenant type used in tenant',
      'API.TENANT_VIRTUAL_METER_RELATION_EXISTS': 'Tenant virtual meter relation exists',
      'API.TENANT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Tenant virtual meter relation not found',
      'API.TENANT_WORKING_CALENDAR_RELATION_EXISTS': 'Tenant working calendar relation exists',
      'API.TENANT_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Tenant working calendar relation not found',
      'API.TEXT_MESSAGE_NOT_FOUND': 'Text message not found',
      'API.THE_REPORTING_PERIOD_MUST_BE_LONGER_THAN_15_MINUTES': 'The reporting period must be longer than 15 minutes',
      'API.THERE_IS_RELATION_WITH_CHILD_METERS': 'There is relation with child meters',
      'API.THERE_IS_RELATION_WITH_CHILDREN_SPACES': 'There is relation with children spaces',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENT_PARAMETERS':
        'There is relation with combined equipment parameters',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENTS': 'There is relation with combined equipments',
      'API.THERE_IS_RELATION_WITH_DATA_SOURCES': 'There is relation with data sources',
      'API.THERE_IS_RELATION_WITH_ENERGY_FLOW_DIAGRAM_LINKS': 'There is relation with energy flow diagram links',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_BATTERIES': 'There Is Relation With Energy Storage Containers Batteries',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_GRIDS': 'There Is Relation With Energy Storage Containers Grids',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_LOADS': 'There Is Relation With Energy Storage Containers Loads',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_POWER_CONVERSION_SYSTEMS': 'There Is Relation With Energy Storage Containers Power Conversion Systems',
      'API.THERE_IS_RELATION_WITH_EQUIPMENT_PARAMETERS': 'There is relation with equipment parameters',
      'API.THERE_IS_RELATION_WITH_EQUIPMENTS': 'There is relation with equipments',
      'API.THERE_IS_RELATION_WITH_METER': 'There is relation with meter',
      'API.THERE_IS_RELATION_WITH_METERS': 'There is relation with meters',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METER': 'There is relation with offline meter',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METERS': 'There is relation with offline meters',
      'API.THERE_IS_RELATION_WITH_OTHER_VIRTUAL_METERS': 'There is relation with other virtual meters',
      'API.THERE_IS_RELATION_WITH_SENSORS': 'There is relation with sensors',
      'API.THERE_IS_RELATION_WITH_SHOPFLOORS': 'There is relation with shopfloors',
      'API.THERE_IS_RELATION_WITH_SPACES': 'There is relation with spaces',
      'API.THERE_IS_RELATION_WITH_STORES': 'There is relation with stores',
      'API.THERE_IS_RELATION_WITH_TENANTS': 'There is relation with tenants',
      'API.THERE_IS_RELATION_WITH_USERS': 'There is relation with users',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_METER': 'There is relation with virtual meter',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_POWER_PLANTS': 'Sanal güç santralleri ile ilişkisi var',
      'API.THIS_DATA_SOURCE_IS_BEING_USED_BY_A_METER': 'This data source is being used by a meter',
      'API.THIS_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This meter is being used by a virtual meter',
      'API.THIS_OFFLINE_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This offline meter is being used by a virtual meter',
      'API.THIS_SPACE_CANNOT_BE_CLONED': 'This space cannot be cloned',
      'API.THIS_SPACE_CANNOT_BE_DELETED': 'This space cannot be deleted',
      'API.TIMEZONE_NOT_FOUND': 'Timezone not found',
      'API.TOKEN_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'Token not found in headers please login',
      'API.USER_ACCOUNT_HAS_BEEN_LOCKED': 'User Account Has Been Locked',
      'API.USER_ACCOUNT_HAS_EXPIRED': 'User Account Has Expired',
      'API.USER_ACCOUNT_IS_NOT_LOCKED': 'User account is not locked',
      'API.USER_NAME_IS_ALREADY_IN_USE': 'User name is already in use',
      'API.USER_NOT_FOUND': 'User not found',
      'API.USER_PASSWORD_HAS_EXPIRED': 'User Password Has Expired',
      'API.USER_PRIVILEGE_NOT_FOUND': 'User privilege not found',
      'API.USER_SESSION_NOT_FOUND': 'User session not found',
      'API.USER_SESSION_TIMEOUT': 'User session timeout',
      'API.USER_UUID_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'User uuid not found in headers please login',
      'API.VIRTUAL_METER_NAME_IS_ALREADY_IN_USE': 'Virtual meter name is already in use',
      'API.VIRTUAL_METER_NOT_FOUND': 'Virtual meter not found',
      'API.VIRTUAL_METER_OF_VARIABLE_NOT_FOUND': 'Virtual meter of variable not found',
      'API.VIRTUAL_POINT_SHOULD_BE_ANALOG_VALUE': 'Virtual point should be analog value',
      'API.WEB_MESSAGE_NOT_FOUND': 'Web message not found',
      'API.WECHAT_MESSAGE_NOT_FOUND': 'Wechat message not found',
      'API.WIND_FARM_NAME_IS_ALREADY_IN_USE': 'Wind farm name is already in use',
      'API.WIND_FARM_NOT_FOUND': 'Wind farm not found',
      'API.WORKING_CALENDAR_NAME_IS_ALREADY_IN_USE': 'Working calendar name is already in use',
      'API.WORKING_CALENDAR_NOT_FOUND': 'Working calendar not found',

      MasterMeter: 'Sažetak tablica',
      SubMeter: 'Podtablica'
    }
  },
  ms: {
    translation: {
      // routes & menus
      Dashboard: 'Dashboard',
      'Space Data': 'Ruang',
      'Equipment Data': 'Peralatan',
      'Meter Data': 'Meter',
      'Tenant Data': 'Penyewa',
      'Store Data': 'Kedai',
      'Shopfloor Data': 'Bengkel',
      'Combined Equipment Data': 'Peralatan Gabungan',
      'Auxiliary System': 'Sistem Bantuan',
      Microgrid: 'Microgrid',
      'Energy Storage Power Station': 'Station Kuasa Storan Energi',
      'Photovoltaic Power Station': 'Power Station',
      'Fault Alarm': 'Penggera Kesalahan',
      Monitoring: 'Pemantauan',
      'Advanced Reporting': 'Laporan Lanjutan',
      'Knowledge Base': 'Pangkalan Pengetahuan',
      'Energy Category Data': 'Kategori Tenaga',
      'Energy Item Data': 'Barangan Tenaga',
      Carbon: 'Emisi CO2',
      Cost: 'Kos',
      Output: 'Output',
      Income: 'Pendapatan',
      Efficiency: 'Kecekapan',
      Load: 'Memuatkan',
      Statistics: 'Statistik',
      'Batch Analysis': 'Analisis Kumpulan',
      Saving: 'Menyimpan',
      Plan: 'Rancang',
      'Production': 'Production',
      'Space Production': 'Space Production',
      'Equipment Tracking': 'Penjejakan Peralatan',
      'Environment Monitor': 'Monitor Alam Sekitar',
      'Meter Energy': 'Tenaga Meter',
      'Meter Carbon': 'Meter Emisi CO2',
      'Meter Cost': 'Kos Meter',
      'Meter Trend': 'Meter Trend',
      'Meter Realtime': 'Meter Masa Nyata',
      'Meter Saving': 'Penjimatan Meter',
      'Meter Plan': 'Pelan Meter',
      'Master Meter Submeters Balance': 'Baki Submeter Meter Master',
      'Meter Batch Analysis': 'Analisis Kelompok Meter',
      'Meter Comparison': 'Perbandingan Meter',
      'Meter Tracking': 'Penjejakan Meter',
      'Virtual Meter Saving': 'Penjimatan Meter Maya',
      'Virtual Meter Plan': 'Pelan Meter Maya',
      'Virtual Meter Energy': 'Tenaga Meter Maya',
      'Virtual Meter Carbon': 'Emisi CO2 Meter Maya',
      'Virtual Meter Cost': 'Kos Meter Maya',
      'Virtual Meter Batch Analysis': 'Analisis kelompok meter maya',
      'Offline Meter Energy': 'Tenaga Meter Luar Talian',
      'Offline Meter Carbon': 'Emisi Meter CO2 Offline',
      'Offline Meter Cost': 'Kos Meter Luar Talian',
      'Offline Meter Batch Analysis': 'Analisis kelompok meter luar talian',
      'Offline Meter Saving': 'Penjimatan Meter Luar Talian',
      'Offline Meter Plan': 'Pelan Meter Luar Talian',
      'Offline Meter Input': 'Input Meter Luar Talian',
      'Tenant Bill': 'Bil Penyewa',
      'Energy Flow Diagram': 'Gambar Rajah Aliran Tenaga',
      'Distribution System': 'Sistem Pengedaran',
      'SVG System': 'Sistem Visualisasi SCADA',
      'Faults Data': 'Kesilapan',
      'Space Equipments': 'Peralatan Angkasa',
      'Combined Equipments': 'Peralatan Gabungan',
      'Energy Storage Power Station List': 'List',
      'Energy Storage Power Station Details': 'Details',
      'Energy Storage Power Station Reporting': 'Reporting',
      'Energy Storage Power Station Alarm': 'Alarm',
      'Energy Storage Power Station Maintenance': 'Maintenance',
      'Enter Production': 'Enter Production',
      'Multiple Energy Storage Power Stations': 'Multiple Power Stations',
      'Multiple Energy Storage Power Stations Dashboard': 'Dashboard',
      'Multiple Energy Storage Power Stations List': 'List',
      'Single Energy Storage Power Station': 'Single Power Station',
      'Single Energy Storage Power Station Dashboard': 'Dashboard',
      'Single Energy Storage Power Station Details': 'Details',
      'Single Energy Storage Power Station Fault Alarm': 'Fault Alarm',
      'Energy Storage Power Station Reports': 'Power Station Reports',
      'Energy Storage Power Station Reports Energy': 'Energy',
      'Energy Storage Power Station Reports Revenue': 'Revenue',
      'Energy Storage Power Station Reports Parameters': 'Parameters',
      'Photovoltaic Power Station List': 'Power Station List',
      'Photovoltaic Power Station Details': 'Details',
      'Photovoltaic Power Station Reporting': 'Reporting',
      'Photovoltaic Power Station Alarm': 'Alarm',
      'Photovoltaic Power Station Maintenance': 'Maintenance',
      'Multiple Photovoltaic Power Stations': 'Multiple Power Stations',
      'Multiple Photovoltaic Power Stations Dashboard': 'Dashboard',
      'Multiple Photovoltaic Power Stations List': 'List',
      'Single Photovoltaic Power Station': 'Single Power Station',
      'Single Photovoltaic Power Station Dashboard': 'Dashboard',
      'Single Photovoltaic Power Station Details': 'Details',
      'Single Photovoltaic Power Station Fault Alarm': 'Fault Alarm',
      'Photovoltaic Power Station Reports': 'Power Station Reports',
      'Photovoltaic Power Station Reports Energy': 'Energy',
      'Photovoltaic Power Station Reports Revenue': 'Revenue',
      'Photovoltaic Power Station Reports Parameters': 'Parameters',
      'Work Order': 'Work Order',
      'Work Order Installation': 'Installation',
      'Work Order Repair': ' Repair',
      'Work Order Inspection': 'Inspection',
      'Total Number of Work Orders': 'Jumlah Bilangan Pesanan Kerja',
      'Number of New Work Orders': 'Bilangan Pesanan Kerja Baharu',
      'Number of Inprogress Work Orders': 'Bilangan Pesanan Kerja Sedang Bertindak',
      'Number of Done Work Orders': 'Bilangan Pesanan Kerja Selesai',
      'Charge Energy Indicator': 'Mengecas Tenaga',
      'Discharge Energy Indicator': 'Tenaga Pelepasan',
      'Charge Cost Indicator': 'Kos Caj',
      'Discharge Revenue Indicator': 'Hasil Pelepasan',
      'HVAC': 'HVAC',
      'Fire Control': 'Kawalan Kebakaran',

      // Dashboard
      'Welcome to DoraEOS': 'Selamat datang ke DoraEOS',
      'An Industry Leading Open Source Energy Management System':
        'Sistem Pengurusan Tenaga Sumber Terbuka Terkemuka Industri',
      "This Year's Consumption CATEGORY VALUE UNIT": 'Penggunaan Tahun Ini {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Year's Costs CATEGORY VALUE UNIT": 'Kos Tahun Ini {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Consumption CATEGORY VALUE UNIT": 'Penggunaan Bulan Ini {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Costs CATEGORY VALUE UNIT": 'Kos bulan ini {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Consumption CATEGORY VALUE UNIT in The Same Period Last Year':
        'Penggunaan {{CATEGORY}} {{VALUE}} {{UNIT}} dalam tempoh yang sama tahun lepas',
      'Costs CATEGORY VALUE UNIT in The Same Period Last Year':
        'Bayaran {{CATEGORY}} {{VALUE}} {{UNIT}} dalam tempoh yang sama tahun lepas',
      'CATEGORY Consumption UNIT': '{{CATEGORY}} Penggunaan {{UNIT}}',
      'CATEGORY Costs UNIT': '{{CATEGORY}} Bayaran {{UNIT}}',
      // Entities
      Space: 'Ruang',
      Equipment: 'Peralatan',
      Meter: 'Meter',
      'Offline Meter': 'Meter Luar Talian',
      'Virtual Meter': 'Meter Maya',
      Tenant: 'Penyewa',
      Store: 'Kedai',
      Shopfloor: 'Bengkel',
      'Combined Equipment': 'Peralatan Gabungan',
      'Cost Center': 'Pusat Kos',
      Name: 'Nama',
      DisplayName: 'Nama Paparan',
      UserName: 'Nama Pengguna',
      Description: 'Keterangan',
      'Energy Category': 'Kategori Tenaga',
      'Child Spaces': 'Ruang Kanak-kanak',
      'Associated Equipment': 'Peralatan Berkaitan',
      //Energy Categories
      Electricity: 'Elektrik',
      Water: 'Air',
      'Natural Gas': 'Gas Asli',
      // SidePanelModal
      Settings: 'Seting',
      'Set your own customized style': 'Mengesetkan gaya tersuai anda sendiri',
      'Color Scheme': 'Skema Warna',
      'Choose the perfect color mode for your app': 'Pilih mod warna yang sesuai untuk apl anda',
      Light: 'Cahaya',
      Dark: 'Gelap',
      'RTL Mode': 'Mod RTL',
      'Switch your language direction': 'Tukar arah bahasa anda',
      'Fluid Layout': 'Susun atur cecair',
      'Toggle container layout system': 'Sistem susun atur kontena togol',
      'Navigation Position': 'Kedudukan Navigasi',
      'Select a suitable navigation system for your web application':
        'Pilih sistem navigasi yang sesuai untuk aplikasi web anda',
      'Vertical Nav': 'Tentera Laut Menegak',
      'Top Nav': 'Tentera Laut Atas',
      'Vertical Navbar Style': 'Gaya Navbar Menegak',
      'Switch between styles for your vertical navbar': 'Bertukar antara gaya untuk bar navigasi menegak anda',
      Language: 'Bahasa',
      'Switch between languages': 'Bertukar antara bahasa',
      'language-zh_CN': '简体中文',
      'language-en': 'English',
      'language-de': 'Deutsch',
      'language-fr': 'Français',
      'language-es': 'Español',
      'language-ru': 'Русский',
      'language-ar': 'العربية',
      'language-vi': 'Tiếng Việt',
      'language-th': 'ภาษาไทย',
      'language-tr': 'Türkçe',
      'language-ms': 'Bahasa Melayu',
      'language-id': 'Bahasa Indonesia',
      'language-zh_TW': '繁體中文',
      'Like What You See?': 'Seperti apa yang anda lihat?',
      'Get DoraEOS now': 'Dapatkan DoraEOS sekarang',
      Purchase: 'Pembelian',
      // Query Panel
      'Base Period Begins': 'Tempoh Asas Bermula',
      'Base Period Ends': 'Tempoh Asas Berakhir',
      'Comparison Types': 'Jenis Perbandingan',
      'Year-Over-Year': 'Tahun Ke Tahun',
      'Month-On-Month': 'Bulan Ke Bulan',
      'Free Comparison': 'Perbandingan Percuma',
      'None Comparison': 'Tiada Perbandingan',
      'Reporting Period Begins': 'Tempoh pelaporan bermula',
      'Reporting Period Ends': 'Tempoh pelaporan berakhir',
      '(Optional)': '(Pilihan)',
      'Period Types': 'Jenis Tempoh',
      Yearly: 'Tahunan',
      Monthly: 'Bulanan',
      Weekly: 'Mingguan',
      Daily: 'Harian',
      Hourly: 'Jam',
      Submit: 'Hantar',
      'Input Energy Category': 'Kategori Tenaga Input',
      'Output Energy Category': 'Kategori Tenaga Output',
      'Fraction Parameter': 'Parameter pecahan',
      Search: 'Carian',
      //DateRangePicker
      sunday: 'Su',
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      ok: 'OK',
      today: 'Hari ini',
      yesterday: 'Semalam',
      hours: 'Jam',
      minutes: 'Minit',
      seconds: 'Saat',
      last7Days: '7 Hari Terakhir',
      'Select Date Range': 'Pilih Julat Tarikh',
      //Card Summaries and Line Charts
      'Base Period': 'Tempoh Asas',
      'Reporting Period': 'Tempoh Pelaporan',
      'Per Unit Area': 'Kawasan Unit',
      'Per Capita': 'Per Capita',
      'Per Unit Production': 'Pengeluaran Seunit',
      Baseline: 'Garis dasar',
      Actual: 'Sebenar',
      'Baseline Value - Actual Value': 'Garis dasar - Sebenar',
      'Average Load': 'Beban purata',
      'Maximum Load': 'Beban Maksimum',
      'Load Factor': 'Faktor Beban',
      'Ratio of Average Load to Maximum Load': 'Nisbah beban purata kepada beban maksimum',
      'Carbon Dioxide Emissions by Energy Category': 'Pelepasan karbon dioksida mengikut kategori tenaga',
      'Costs by Energy Category': 'Kos mengikut Kategori Tenaga',
      'Incomes by Energy Category': 'Pendapatan mengikut Kategori Tenaga',
      'Electricity Consumption by Time-Of-Use': 'Penggunaan elektrik mengikut masa penggunaan',
      'Electricity Carbon Dioxide Emissions by Time-Of-Use':
        'Pelepasan karbon dioksida elektrik mengikut masa penggunaan',
      'Electricity Cost by Time-Of-Use': 'Kos elektrik mengikut masa penggunaan',
      'CATEGORY UNIT Consumption by Energy Items': '{{CATEGORY}} {{UNIT}} Komperusi oleh Barangan Tenaga',
      'Ton of Standard Coal by Energy Category': 'Tan arang batu standard (TCE) mengikut kategori tenaga',
      'Ton of Carbon Dioxide Emissions by Energy Category':
        'Tan pelepasan karbon dioksida (TCO2E) mengikut kategori tenaga',
      'Reporting Period Consumption CATEGORY UNIT': 'Tempoh Pelaporan Penggunaan {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{EQUIPMENT}} Tempoh Pelaporan Penggunaan {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Tempoh Pelaporan Penggunaan {{CATEGORY}} {{UNIT}}',
      'CATEGORY VALUE UNIT': '{{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption CATEGORY VALUE UNIT': 'Tempoh Pelaporan Penggunaan {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Production PRODUCT VALUE UNIT': 'Reporting Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Production PRODUCT VALUE UNIT': 'Base Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption CATEGORY VALUE UNIT': 'Tempoh Asas Penggunaan {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY UNIT': 'Tempoh Pelaporan Penggunaan {{ITEM}} {{CATEGORY}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY VALUE UNIT':
        'Tempoh Pelaporan Penggunaan {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption ITEM CATEGORY VALUE UNIT':
        'Tempoh Asas Penggunaan {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Child Space Proportion CATEGORY UNIT':
        'Perkadaran Ruang Kanak-kanak mengikut Kategori Tenaga {{CATEGORY}} {{UNIT}}',
      'Child Space Total Proportion': 'Jumlah Perkadaran Ruang Kanak-kanak',
      'Reporting Period Carbon Dioxide Emissions CATEGORY UNIT':
        'Tempoh pelaporan pelepasan karbon dioksida {{CATEGORY}} {{UNIT}}',
      'Reporting Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Tempoh pelaporan pelepasan karbon dioksida {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Tempoh Asas Pelepasan Karbon Dioksida {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Costs CATEGORY UNIT': 'Kos Tempoh Pelaporan {{CATEGORY}} {{UNIT}}',
      'Reporting Period Costs CATEGORY VALUE UNIT': 'Kos Tempoh Pelaporan {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Costs CATEGORY VALUE UNIT': 'Kos Tempoh Asas {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Output CATEGORY UNIT': 'Output Tempoh Pelaporan {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Output CATEGORY UNIT': '{{EQUIPMENT}} Output Tempoh Pelaporan {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Output Tempoh Pelaporan {{CATEGORY}} {{UNIT}}',
      'Reporting Period Output CATEGORY VALUE UNIT': 'Output Tempoh Pelaporan {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Output CATEGORY VALUE UNIT': 'Output Tempoh Asas {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Income CATEGORY UNIT': 'Pendapatan Tempoh Pelaporan {{CATEGORY}} {{UNIT}}',
      'Reporting Period Income CATEGORY VALUE UNIT': 'Pendapatan Tempoh Pelaporan {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Income CATEGORY VALUE UNIT': 'Pendapatan Tempoh Asas {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Total Income UNIT': 'Tempoh Pelaporan Jumlah Pendapatan {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME UNIT': 'Tempoh Pelaporan Kecekapan Kumulatif {{NAME}} {{UNIT}}',
      'EQUIPMENT Reporting Period Cumulative Efficiency UNIT':
        '{{EQUIPMENT}} Tempoh Pelaporan Kecekapan Kumulatif {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Tempoh Pelaporan Kecekapan Komprehensif Kumulatif {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Tempoh Pelaporan Kecekapan Komprehensif Kumulatif {{VALUE}} {{UNIT}}',
      'COMBINED_EQUIPMENT Base Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Tempoh Asas Kecekapan Komprehensif Kumulatif {{VALUE}} {{UNIT}}',
      'Instantaneous Efficiency UNIT': 'Kecekapan Seketika {{UNIT}}',
      'EQUIPMENT Instantaneous Efficiency UNIT': '{{EQUIPMENT}} Kecekapan Seketika {{UNIT}}',
      'COMBINED_EQUIPMENT Instantaneous Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Kecekapan Komprehensif Seketika {{UNIT}}',
      'Reporting Period Cumulative Efficiency VALUE UNIT': 'Tempoh Pelaporan Kecekapan Kumulatif {{VALUE}} {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME VALUE UNIT':
        'Tempoh Pelaporan Kecekapan Kumulatif {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency VALUE UNIT': 'Tempoh Asas Kecekapan Kumulatif {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency NAME VALUE UNIT':
        'Tempoh Asas Kecekapan Kumulatif {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period CATEGORY Maximum Load UNIT': 'Tempoh Asas {{CATEGORY}} Beban Maksimum {{UNIT}}',
      'Reporting Period CATEGORY Maximum Load UNIT': 'Tempoh Pelaporan {{CATEGORY}} Beban Maksimum {{UNIT}}',
      'Reporting Period CATEGORY Average Load UNIT': 'Tempoh Pelaporan {{CATEGORY}} Beban purata {{UNIT}}',
      'Reporting Period CATEGORY Load Factor': 'Tempoh Pelaporan {{CATEGORY}} Faktor Beban',
      'Base Period CATEGORY Average Load VALUE UNIT': 'Tempoh Asas {{CATEGORY}} Beban purata {{VALUE}} {{UNIT}}',
      'Reporting Period CATEGORY Average UNIT': 'Tempoh Pelaporan {{CATEGORY}} Nilai purata {{UNIT}}',
      'Reporting Period CATEGORY Maximum UNIT': 'Tempoh Pelaporan {{CATEGORY}} Nilai Maksimum {{UNIT}}',
      'Reporting Period CATEGORY Minimum UNIT': 'Tempoh Pelaporan {{CATEGORY}} Nilai Minimum {{UNIT}}',
      'Reporting Period CATEGORY Mean UNIT': 'Tempoh Pelaporan {{CATEGORY}} Min Aritmetik {{UNIT}}',
      'Reporting Period CATEGORY Median UNIT': 'Tempoh Pelaporan {{CATEGORY}} Median (Nilai Pertengahan) {{UNIT}}',
      'Reporting Period CATEGORY Stdev UNIT': 'Tempoh Pelaporan {{CATEGORY}} Sisihan Piawai Sampel {{UNIT}}',
      'Reporting Period CATEGORY Variance UNIT': 'Tempoh Pelaporan {{CATEGORY}} Sampel Varians {{UNIT}}',
      'Reporting Period Saving CATEGORY (Baseline - Actual) UNIT':
        'Penjimatan Tempoh Pelaporan {{CATEGORY}} (Garis dasar - Sebenar) {{UNIT}}',
      'Reporting Period Decreased CATEGORY (Baseline - Actual) UNIT':
        'Tempoh Pelaporan Berkurangan {{CATEGORY}} (Garis dasar - Sebenar) {{UNIT}}',
      'Reporting Period Saving CATEGORY VALUE UNIT': 'Penjimatan Tempoh Pelaporan {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Saving CATEGORY VALUE UNIT': 'Penjimatan Tempoh Asas {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY UNIT':
        'Tempoh Pelaporan Penggunaan Meter Induk {{CATEGORY}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY VALUE UNIT':
        'Tempoh Pelaporan Penggunaan Meter Induk {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY UNIT':
        'Tempoh Pelaporan Penggunaan Submeter {{CATEGORY}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY VALUE UNIT':
        'Tempoh Pelaporan Penggunaan Submeter {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Difference CATEGORY UNIT': 'Perbezaan Tempoh Pelaporan {{CATEGORY}} {{UNIT}}',
      'Reporting Period Percentage Difference': 'Perbezaan Peratusan Tempoh Pelaporan',
      'Reporting Period Difference CATEGORY VALUE UNIT': 'Perbezaan Tempoh Pelaporan {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Trend Values': 'Nilai Trend',
      'METER CATEGORY VALUE UNIT': '{{METER}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      //FDD
      'Fault List': 'Senarai Kesalahan',
      'Reporting Period Number of Fault VALUE': 'Tempoh Pelaporan Bilangan Kesalahan {{VALUE}}',
      'All Faults': 'Semua Kesalahan',
      'Space Faults': 'Kesalahan Angkasa',
      'Number of All Faults': 'Bilangan Semua Kesalahan',
      'Number of Space Faults': 'Bilangan Kesalahan Ruang',
      'Total Number of Faults': 'Total Number of Faults',
      'Number of New Faults': 'Number of New Faults',
      'Number of Inprogress Faults': 'Number of Inprogress Faults',
      'Number of Done Faults': 'Number of Done Faults',
      //Data Panels
      'Ton of Standard Coal': 'Tan arang batu standard',
      'Ton of Carbon Dioxide Emissions': 'Tan pelepasan karbon dioksida',
      'Operating Characteristic Curve': 'Lengkung karakteristik operasi',
      Tariff: 'Tarif',
      'Detailed Data': 'Data terperinci',
      'Child Spaces Data': 'Data Ruang Kanak-kanak',
      'Associated Equipment Data': 'Data Peralatan Berkaitan',
      'This Year': 'Tahun ini',
      'The Same Period Last Year': 'Tempoh yang sama tahun lepas',
      'This Month': 'Bulan Ini',
      'This Day': 'Hari Ini',
      Total: 'Jumlah',
      'No data found': 'Tiada data ditemui',
      Export: 'Eksport',
      Datetime: 'Masa tarikh',
      'Time-Of-Use Type': 'Jenis Masa Penggunaan',
      'Top-Peak': 'Puncak Atas',
      'On-Peak': 'On-Peak',
      'Mid-Peak': 'Pertengahan Puncak',
      'Off-Peak': 'Luar Puncak',
      Percentage: 'Peratusan',
      //Realtime Chart
      'Trend in the last hour of Energy Value Point': 'Trend pada jam terakhir Titik Nilai Tenaga',
      Circuit: 'Litar',
      Point: 'Titik',
      'Realtime Value': 'Nilai Masa Nyata',
      //Meter Tracking
      'Meter List': 'Senarai Meter',
      'Edit Meter': 'Edit',
      'Start Value': 'Nilai Mula',
      'End Value': 'Nilai Akhir',
      'Difference Value': 'Perbezaan Nilai',
      'Start Integrity Rate': 'Mulakan Kadar Integriti(%)',
      'End Integrity Rate': 'Tamatkan Kadar Integriti(%)',
      'Full Integrity Rate': 'Kadar Integriti Penuh(%)',
      //Equipment Tracking
      'Equipment List': 'Senarai Peralatan',
      'Edit Equipment': 'Edit',
      //Profile Dropdown
      Feedback: 'Maklum balas',
      'Account Settings': 'Tetapan Akaun',
      Logout: 'Log keluar',
      //Authentication
      'Log in': 'Log masuk',
      'Email address': 'Alamat e-mel',
      Password: 'Kata laluan',
      CaptchaCode: 'Captcha',
      Refresh: 'Refresh',
      'Remember me': 'Ingat saya',
      'Logged in as ': 'Log masuk sebagai ',
      'Forgot Password?': 'Lupa Kata Laluan?',
      'Forgot your password?': 'Lupa kata laluan anda?',
      "Enter your email and we'll send you a reset link":
        'Masukkan e-mel anda dan kami akan menghantar pautan set semula kepada anda',
      "Enter your email and we'll send you a registration link":
        'Masukkan e-mel anda dan kami akan menghantar pautan pendaftaran kepada anda',
      'Reset Password': 'Set Semula Kata Laluan',
      'Thanks for using DoraEOS!': 'Terima kasih kerana menggunakan DoraEOS!',
      'You are now successfully signed out': 'Anda kini berjaya mendaftar keluar',
      'Return to Login': 'Kembali ke Log Masuk',
      'Please check your email!': 'Sila semak e-mel anda!',
      'An email has been sent to ': 'E-mel telah dihantar kepada ',
      'Please click on the included link to reset your password':
        'Sila klik pada pautan yang disertakan untuk menetapkan semula kata laluan anda',
      'Please click on the included link to register your account':
        'Sila klik pada pautan yang disertakan untuk mendaftarkan akaun anda',
      'An email with password reset link is sent to ': 'Pautan e-mel dengan set semula kata laluan dihantar kepada ',
      'Change Password': 'Tukar Kata Laluan',
      'Old Password': 'Kata Laluan Lama',
      'New Password': 'Kata Laluan Baru',
      'Confirm Password': 'Sahkan Kata Laluan',
      'Update Password': 'Kemas kini Kata Laluan',
      'Password has been changed!': 'Kata laluan telah ditukar!',
      'Verification code has been sent to your inbox. \
      Please copy it to the input box below..':
        'Kod pengesahan telah dihantar ke peti masuk anda. \
      Sila salin ke kotak input di bawah.',
      'New to DoraEOS': 'Baru mengenali DoraEOS',
      'Create an account': 'Buat akaun',
      'EMAIL Account registration successful': '{{EMAIL}} Pendaftaran akaun berjaya',
      'Thanks for verifying your account!': 'Terima kasih kerana mengesahkan akaun anda!',
      'Your code is': 'Kod anda ialah',
      'Send verification code': 'Hantar kod pengesahan',
      'Please wait for NUMBER seconds': 'Sila tunggu {{NUMBER}} Saat',
      'Password reset': 'Penetapan semula kata laluan',
      'Please wait for approval': 'Sila tunggu kelulusan',
      //notification
      Notifications: 'Pemberitahuan',
      'Mark all as read': 'Tandakan semua sebagai dibaca',
      'View all': 'Semua',
      'Notification New': 'BARU',
      'Notification Earlier': 'AWAL',
      'Notification List': 'Senarai Pemberitahuan',
      'Bulk actions': 'Tindakan pukal',
      'Notification Subject': 'Subjek',
      'Notification Created Datetime': 'Dibuat Datetime',
      'Notification Priority': 'Keutamaan',
      'Notification Message': 'Mesej',
      'Notification Status': 'Status',
      'Notification Mark As Read': 'Tandakan Sebagai Baca',
      'Notification Mark As Acknowledged': 'Mengakui',
      'Notification Low': 'Rendah',
      'Notification Medium': 'Sederhana',
      'Notification High': 'Tinggi',
      'Notification Critical': 'Kritikal',
      'Notification Unread': 'Belum dibaca',
      'Notification Read': 'Membaca',
      'Notification Acknowledged': 'Diiktiraf',
      'Notification Archive': 'Arkib',
      'Notification Delete': 'Menghapuskan',
      'Notification Apply': 'Memohon',
      'Notification Start Datetime': 'Tarikh Mula',
      'Notification End Datetime': 'Tarikh Tamat',
      'Notification Update Datetime': 'Kemas kini Datetime',
      //Monitoring
      'Run Commands': 'Jalankan Perintah',
      'Fault Alarms': 'Penggera Kesalahan',
      'Instantaneous Efficiency VALUE UNIT': 'Kecekapan Seketika {{VALUE}} {{UNIT}}',
      'Communication Online': 'Dalam talian',
      'Communication Offline': 'Luar talian',
      'Equipment Status': 'Status Peralatan',
      'Equipment Running': 'Menjalankan',
      'Equipment Stopped': 'Berhenti',
      'Show Up to': 'Tunjukkan kepada',
      All: 'Semua',
      'FROM - TO of TOTAL': '{{FROM}} - {{TO}} daripada {{TOTAL}} Peralatan',
      //Microgrid
      'PCS Run State': 'PCS Run State',
      'PCS Unknown': 'Tidak diketahui',
      'PCS Initializing': 'Memulakan',
      'PCS Standby': 'Bersedia',
      'PCS Shutdown': 'Matikan',
      'PCS Fault': 'Gagal',
      'PCS Running': 'Jalankan',
      'Battery Power': 'Battery power',
      'Photovoltaic Power': 'Kuasa fotovoltaik',
      'Grid Power': 'Kuasa grid',
      'Load Power': 'Muat kuasa',
      Reporting: 'Laporan',
      Maintenance: 'Kekal',
      'Serial Number': 'Nombor Siri',
      Address: 'Alamat',
      'Postal Code': 'Kod Postal',
      'Rated Capacity': 'Kemampuan Dinilai',
      'Rated Power': 'Kuasa Dinilai',
      Latitude: 'Latitud',
      Longitude: 'Longitud',
      'Strategy Management': 'Pengurusan strategi',
      'Charging Schedule': 'Memuatkan Jadual',
      'Charge Start Time': 'Muat Masa Mula',
      'Charge End Time': 'Masa Akhir Muat',
      'Discharge Start Time': 'Masa Mula Buang',
      'Discharge End Time': 'Masa Akhir Buang',
      'Total Rated Power': 'Jumlah Kuasa Dinilai',
      'Total Rated Capacity': 'Jumlah Kapasiti Dinilai',
      "Today's Charge": "Jumlah caj hari ini",
      "Today's Discharge": "Discharge hari ini",
      "Today's Generation": "Penjanaan kuasa hari ini",
      'Total Charge': 'Jumlah caj',
      'Total Discharge': 'jumlah pelepasan',
      'Total Generation': 'Jumlah Penjanaan',
      'Total Revenue': 'Jumlah hasil',
      'Total Efficiency': 'Jumlah Kecekapan',
      'Discharge Achievement Rate': 'Kadar Pencapaian Pelepasan',
      // Revenue Indicator
      "Today's Cost": "Today's Cost",
      "Today's Income": "Today's Income",
      "Total Cost": "Total Cost",
      "Total Income": "Total Income",
      "Today's Revenue": "Today's Revenue",

      'Revenue Ranking': 'Kedudukan hasil',
      'Efficiency Ranking': 'Kedudukan kecekapan',
      'Charge Ranking': 'Kedudukan kapasiti pengecasan',
      'Discharge Ranking': 'Kedudukan pelepasan',
      'Number of Microgrids': 'Bilangan mikrogrid',
      'Number of Power Stations': 'Bilangan stesen janakuasa',
      'Microgrid List': 'Senarai Microgrid',
      'Previous Page': 'Halaman sebelumnya',
      'Next Page': 'Muka surat seterusnya',
      '7 Days': '7 hari',
      'Charge UNIT': 'caj {{UNIT}}',
      'Discharge UNIT': 'pelepasan {{UNIT}}',
      'Generation UNIT': 'menjana tenaga elektrik {{UNIT}}',
      'Energy Indicator': 'indeks tenaga',
      'Revenue Indicator': 'Penunjuk hasil',
      'Carbon Indicator': 'indeks karbon',

      // Energy Storage Power Station
      'Battery Operating State': 'Keadaan Operasi Bateri',
      'Battery State': 'Status Bateri',
      'Battery Unknown': 'Tidak diketahui',
      'Battery Stopped': 'Berhenti',
      'Battery Reserved': 'Reserved', // 0
      'Battery Fault': 'Gagal', // 1
      'Battery Warning': 'Amaran', // 2
      'Battery Standby': 'Bersedia', // 3
      'Battery Prohibit DisCharging': 'Melarangkan Nyahmuat', // 4
      'Battery Prohibit Charging': 'Melarang Memuatkan', // 5
      'Battery Normal': 'Normal', // 6
      'Battery Charging': 'Memuatkan', // 7
      'Battery Discharging': 'Semasa pembuangan', // 8
      'Battery Idle': 'Idle', // 9
      'Phase of Lifecycle': 'Fasa Siklo Hidup',
      'Use Phase': 'Guna Tahap',
      'Commissioning Phase': 'Fasa Pemasangan',
      'Installation Phase': 'Fasa Pemasangan',
      'Device Status': 'Status Peranti',
      'Basic Information': 'Maklumat Asas',
      'Gateway': 'Gateway',
      'Grid Meter': 'Grid Meter',
      'Load Meter': 'Load Meter',
      'Total Active Power': 'Total Active Power',
      'Active Power A': 'Active Power A',
      'Active Power B': 'Active Power B',
      'Active Power C': 'Active Power C',
      'Total Reactive Power': 'Total Reactive Power',
      'Reactive Power A': 'Reactive Power A',
      'Reactive Power B': 'Reactive Power B',
      'Reactive Power C': 'Reactive Power C',
      'Total Apparent Power': 'Total Apparent Power',
      'Apparent Power A': 'Apparent Power A',
      'Apparent Power B': 'Apparent Power B',
      'Apparent Power C': 'Apparent Power C',
      'Total Power Factor': 'Total Power Factor',
      'Active Energy Import': 'Active Energy Import',
      'Active Energy Export': 'Active Energy Export',
      'Active Energy Net': 'Active Energy Net',

      // Photovoltaic Power Station
      'Invertor': 'penyongsang',
      'Invertor Run State': 'Run State',
      'Invertor Unknown': 'Tidak diketahui',
      'Invertor Initializing': 'Memulakan',
      'Invertor Standby': 'Bersedia',
      'Invertor Shutdown': 'Matikan',
      'Invertor Fault': 'Gagal',
      'Invertor Running': 'Jalankan',

      //Advanced Reporting & Knowledge base
      'Created Datetime': 'Dicipta Datetime',
      'File Format': 'Format Fail',
      'File Size': 'Saiz Fail',
      Uploader: 'Pemuat naik',
      //Error
      "The page you're looking for is not found": 'Halaman yang anda cari tidak dijumpai',
      "Make sure the address is correct and that the page hasn't moved. ":
        'Pastikan alamat betul dan halaman tidak dialihkan. ',
      'If you think this is a mistake,': 'Jika anda fikir ini adalah kesilapan,',
      'contact us': 'Hubungi Kami',
      'Take me home': 'Bawa saya pulang',
      'Whoops, something went wrong!': 'Whoops, ada sesuatu yang tidak kena!',
      'Try refreshing the page, or going back and attempting the action again. ':
        'Cuba segarkan semula halaman atau kembali dan cuba lakukan tindakan sekali lagi. ',
      'If this problem persists,': 'Sekiranya masalah ini berterusan,',
      'Captcha Error': 'Ralat Captcha',
      'Confirm Password Error': 'Kata laluan sahkan tidak sepadan',
      'It looks like you clicked on an invalid password reset link. Please tryagain.':
        'Nampaknya anda mengklik pautan \
      penetapan semula kata laluan yang tidak sah. Sila cubalah.',
      'It looks like you clicked on an invalid registration account link. Please tryagain.':
        'Nampaknya anda mengklik pautan \
      akaun pendaftaran tidak sah. Sila cubalah.',
      //Tenant Bill
      'Lease Contract Number': 'Nombor Kontrak Pajakan',
      Download: 'Muat turun',
      Print: 'Cetakan',
      'Payment Notice': 'Notis Pembayaran',
      'Bill To': 'Bil Kepada',
      'Bill Number': 'Nombor Bil',
      'Bill Date': 'Tarikh Bil',
      'Payment Due Date': 'Tarikh Akhir Pembayaran',
      'Amount Payable': 'Amaun Perlu Dibayar',
      'Billing Period Start': 'Tempoh Pengebilan Bermula',
      'Billing Period End': 'Tempoh Tamat Tempoh Pengebilan',
      Quantity: 'Kuantiti',
      Price: 'Harga',
      Unit: 'Unit',
      Amount: 'Jumlah',
      Subtotal: 'Subjumlah',
      'VAT Output Tax': 'Cukai Output VAT',
      'Total Amount Payable': 'Jumlah Amaun Yang Perlu Dibayar',
      'Please make sure to pay on or before the payment due date above':
        'Sila pastikan anda membayar pada atau sebelum tarikh akhir pembayaran di atas',
      'Send money to the following account': 'Hantar wang ke akaun berikut',
      'Account Name': 'Nama Akaun',
      'Bank Name': 'Nama Bank',
      'Bank Address': 'Alamat Bank',
      'RMB Account': 'Akaun RMB',
      // button
      'Toggle Navigation': 'Togol Navigasi',
      // working calendar
      'Working Days': 'Hari Bekerja',
      'Non Working Days': 'Hari Bukan Bekerja',
      'Select Row': 'Sila Pilih Baris Data',
      // Offline Meter Input
      'Daily Value': 'Nilai Harian',
      Date: 'Tarikh',
      'Successfully Saved': 'Berjaya Diselamatkan',
      'Previous Data Is Empty': 'Data Sebelumnya Kosong',
      // API
      'API.ACCOUNT_UNLOCK_FAILED': 'Account unlock failed',
      'API.ADMINISTRATOR_SESSION_NOT_FOUND': 'Administrator session not found',
      'API.ADMINISTRATOR_SESSION_TIMEOUT': 'Administrator session timeout',
      'API.ADVANCED_REPORT_NOT_FOUND': 'Advanced report not found',
      'API.API_KEY_HAS_EXPIRED': 'Key has expired',
      'API.API_KEY_NAME_IS_ALREADY_IN_USE': 'Key name is already in use',
      'API.API_KEY_NOT_FOUND': 'Key not found',
      'API.BAD_REQUEST': 'Bad request',
      'API.BALANCING_PRICE_POINT_NOT_FOUND': 'Balancing price point not found',
      'API.BATTERY_STATE_POINT_NOT_FOUND': 'Battery state point not found',
      'API.BUY_METER_NOT_FOUND': 'Buy meter not found',
      'API.CANNOT_SET_EXISTING_SUBMETER_AS_MASTER_METER': 'Cannot set existing submeter as master meter',
      'API.CHARGE_METER_NOT_FOUND': 'Charge meter not found',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_EXISTS': 'Combined equipment command relation exists',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Combined equipment command relation not found',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_EXISTS': 'Combined equipment equipment relation exists',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_NOT_FOUND': 'Combined equipment equipment relation not found',
      'API.COMBINED_EQUIPMENT_METER_RELATION_EXISTS': 'Combined equipment meter relation exists',
      'API.COMBINED_EQUIPMENT_METER_RELATION_NOT_FOUND': 'Combined equipment meter relation not found',
      'API.COMBINED_EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Combined equipment name is already in use',
      'API.COMBINED_EQUIPMENT_NOT_FOUND': 'Combined equipment not found',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Combined equipment offline meter relation exists',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Combined equipment offline meter relation not found',
      'API.COMBINED_EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Combined equipment parameter name is already in use',
      'API.COMBINED_EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Combined equipment parameter not found or not match',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Combined equipment virtual meter relation exists',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Combined equipment virtual meter relation not found',
      'API.COMMAND_NAME_IS_ALREADY_IN_USE': 'Command name is already in use',
      'API.COMMAND_NOT_FOUND': 'Command not  found',
      'API.CONTACT_NAME_IS_ALREADY_IN_USE': 'Contact name is already in use',
      'API.CONTACT_NOT_FOUND': 'Contact not found',
      'API.COOLING_METER_NOT_FOUND': 'Cooling meter not found',
      'API.COST_CENTER_EXTERNAL_ID_EXISTS': 'Cost center external id exists',
      'API.COST_CENTER_NAME_EXISTS': 'Cost center name exists',
      'API.COST_CENTER_NOT_FOUND': 'Cost center not found',
      'API.COST_FILE_NOT_FOUND': 'Cost file not found',
      'API.DATA_REPAIR_FILE_NOT_FOUND': 'Data repair file not found',
      'API.DATA_SOURCE_NAME_IS_ALREADY_IN_USE': 'Data source name is already in use',
      'API.DATA_SOURCE_NOT_FOUND': 'Data source not found',
      'API.DATABASE_ERROR': 'Database error',
      'API.DATE_IS_ALREADY_IN_WORKING_CALENDAR': 'Date Is Already In Working Calendar',
      'API.DATE_LOCAL_NOT_FOUND': 'Date local not found',
      'API.DISCHARGE_METER_NOT_FOUND': 'Discharge meter not found',
      'API.DISTRIBUTION_CIRCUIT_NAME_IS_ALREADY_IN_USE': 'Distribution circuit name is already in use',
      'API.DISTRIBUTION_CIRCUIT_NOT_FOUND': 'Distribution circuit not found',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_EXISTS': 'Distribution circuit point relation exists',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_NOT_FOUND': 'Distribution circuit point relation not found',
      'API.DISTRIBUTION_SYSTEM_NAME_IS_ALREADY_IN_USE': 'Distribution system name is already in use',
      'API.DISTRIBUTION_SYSTEM_NOT_FOUND': 'Distribution system not found',
      'API.ELECTRICITY_METER_NOT_FOUND': 'Electricity meter not found',
      'API.EMAIL_IS_ALREADY_IN_USE': 'Email is already in use',
      'API.EMAIL_MESSAGE_NOT_FOUND': 'Email message not found',
      'API.EMAIL_NOT_FOUND': 'Email not found',
      'API.EMAIL_SERVER_HOST_IS_ALREADY_IN_USE': 'Email server host is already in use',
      'API.EMAIL_SERVER_NOT_FOUND': 'Email server not found',
      'API.EMPTY_VARIABLES_ARRAY': 'Empty variables array',
      'API.ENERGY_CATEGORY_NAME_IS_ALREADY_IN_USE': 'Energy category name is already in use',
      'API.ENERGY_CATEGORY_NOT_FOUND': 'Energy category not found',
      'API.ENERGY_CATEGORY_USED_IN_ENERGY_ITEMS': 'Energy category used in energy items',
      'API.ENERGY_CATEGORY_USED_IN_METER': 'Energy category used in meter',
      'API.ENERGY_CATEGORY_USED_IN_OFFLINE_METER': 'Energy category used in offline meter',
      'API.ENERGY_CATEGORY_USED_IN_TARIFFS': 'Energy category used in tariffs',
      'API.ENERGY_CATEGORY_USED_IN_VIRTUAL_METER': 'Energy category used in virtual meter',
      'API.ENERGY_FLOW_DIAGRAM_LINK_IS_ALREADY_IN_USE': 'Energy flow diagram link is already in use',
      'API.ENERGY_FLOW_DIAGRAM_LINK_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram link not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NAME_IS_ALREADY_IN_USE': 'Energy flow diagram node name is already in use',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NOT_FOUND_OR_NOT_MATCH': 'Energy flow diagram node not found or not match',
      'API.ENERGY_FLOW_DIAGRAM_NOT_FOUND': 'Energy flow diagram not found',
      'API.ENERGY_ITEM_DOES_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item does not belong to energy category',
      'API.ENERGY_ITEM_IS_NOT_BELONG_TO_ENERGY_CATEGORY': 'Energy item is not belong to energy category',
      'API.ENERGY_ITEM_NAME_IS_ALREADY_IN_USE': 'Energy item name is already in use',
      'API.ENERGY_ITEM_NOT_FOUND': 'Energy item not found',
      'API.ENERGY_ITEM_USED_IN_METER': 'Energy item used in meter',
      'API.ENERGY_ITEM_USED_IN_OFFLINE_METER': 'Energy item used in offline meter',
      'API.ENERGY_ITEM_USED_IN_VIRTUAL_METER': 'Energy item used in virtual meter',
      'API.ENERGY_STORAGE_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Energy storage power station name is already in use',
      'API.ENERGY_STORAGE_POWER_STATION_NOT_FOUND': 'Energy storage power station not found',
      'API.EQUIPMENT_COMMAND_RELATION_EXISTS': 'Equipment command relation exists',
      'API.EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Equipment command relation not found',
      'API.EQUIPMENT_METER_RELATION_EXISTS': 'Equipment meter relation exists',
      'API.EQUIPMENT_METER_RELATION_NOT_FOUND': 'Equipment meter relation not found',
      'API.EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Equipment name is already in use',
      'API.EQUIPMENT_NOT_FOUND': 'Equipment not found',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Equipment offline meter relation exists',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Equipment offline meter relation not found',
      'API.EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Equipment parameter name is already in use',
      'API.EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Equipment parameter not found or not match',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Equipment virtual meter relation exists',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Equipment virtual meter relation not found',
      'API.ERROR': 'Error',
      'API.EXCEPTION': 'Exception',
      'API.FAILED_TO_READ_REQUEST_STREAM': 'Failed to read request stream',
      'API.FAILED_TO_RESTORE_COST_FILE': 'Failed to restore cost file',
      'API.FAILED_TO_RESTORE_DATA_REPAIR_FILE': 'Failed to restore data repair file',
      'API.FAILED_TO_RESTORE_KNOWLEDGE_FILE': 'Failed to restore knowledge file',
      'API.FAILED_TO_RESTORE_OFFLINE_METER_FILE': 'Failed to restore offline meter file',
      'API.FAILED_TO_SAVE_OFFLINE_METER_FILE': 'Failed to save offline meter file',
      'API.FAILED_TO_UPLOAD_ATTACHMENT_FILE': 'Failed to upload attachment file',
      'API.FAILED_TO_UPLOAD_COST_FILE': 'Failed to upload cost file',
      'API.FAILED_TO_UPLOAD_DATA_REPAIR_FILE': 'Failed to upload data repair file',
      'API.FAILED_TO_UPLOAD_KNOWLEDGE_FILE': 'Failed to upload knowledge file',
      'API.FAILED_TO_UPLOAD_OFFLINE_METER_FILE': 'Failed to upload offline meter file',
      'API.GATEWAY_NAME_IS_ALREADY_IN_USE': 'Gateway name is already in use',
      'API.GATEWAY_NOT_FOUND': 'Gateway not found',
      'API.HEAT_METER_NOT_FOUND': 'Heat meter not found',
      'API.INVALID_': 'Invalid ',
      'API.INVALID_ACKNOWLEDGE_CODE': 'Invalid acknowledge code',
      'API.INVALID_ADDRESS': 'Invalid address',
      'API.INVALID_ADDRESS_VALUE': 'Invalid address value',
      'API.INVALID_ADVANCED_REPORT_ID': 'Invalid advanced report id',
      'API.INVALID_API_KEY_ID': 'Invalid api key id',
      'API.INVALID_API_KEY_NAME': 'Invalid api key name',
      'API.INVALID_AREA_VALUE': 'Invalid area value',
      'API.INVALID_BALANCING_PRICE_POINT_ID': 'Invalid balancing price point ID',
      'API.INVALID_BASE_PERIOD_END_DATETIME': 'Invalid base period end datetime',
      'API.INVALID_BASE_PERIOD_START_DATETIME': 'Invalid base period start datetime',
      'API.INVALID_BATTERY_STATE_POINT_ID': 'Invalid battery state point ID',
      'API.INVALID_BAUD_RATE': 'Invalid baud rate',
      'API.INVALID_BUILDINGS_VALUE': 'Invalid buildings value',
      'API.INVALID_BUY_METER_ID': 'Invalid buy meter id',
      'API.INVALID_CAPACITY': 'Invalid capacity',
      'API.INVALID_CATEGORY': 'Invalid category',
      'API.INVALID_CHANNEL': 'Invalid channel',
      'API.INVALID_CHARGE_METER_ID': 'Invalid charge meter id',
      'API.INVALID_COMBINED_EQUIPMENT_ID': 'Invalid combined equipment id',
      'API.INVALID_COMBINED_EQUIPMENT_NAME': 'Invalid combined equipment name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_ID': 'Invalid combined equipment parameter id',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_NAME': 'Invalid combined equipment parameter name',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_TYPE': 'Invalid combined equipment parameter type',
      'API.INVALID_COMBINED_EQUIPMENT_UUID': 'Invalid combined equipment uuid',
      'API.INVALID_COMMAND_ID': 'Invalid command id',
      'API.INVALID_COMMAND_NAME': 'Invalid command name',
      'API.INVALID_CONNECTION': 'Invalid connection',
      'API.INVALID_CONSTANT_VALUE': 'Invalid constant value',
      'API.INVALID_CONTACT_ID': 'Invalid contact id',
      'API.INVALID_CONTACT_NAME': 'Invalid contact name',
      'API.INVALID_COOLING_METER_ID': 'Invalid cooling meter id',
      'API.INVALID_COST_CENTER_ID': 'Invalid cost center id',
      'API.INVALID_COST_FILE_ID': 'Invalid cost file id',
      'API.INVALID_CREATED_DATETIME': 'Invalid created datetime',
      'API.INVALID_DATA_REPAIR_FILE_ID': 'Invalid data repair file id',
      'API.INVALID_DATA_SOURCE_ID': 'Invalid data source id',
      'API.INVALID_DATA_SOURCE_NAME': 'Invalid data source name',
      'API.INVALID_DATA_SOURCE_PROTOCOL': 'Invalid data source protocol',
      'API.INVALID_DATE_LOCAL': 'Invalid date local',
      'API.INVALID_DENOMINATOR_METER_UUID': 'Invalid denominator meter uuid',
      'API.INVALID_DISCHARGE_METER_ID': 'Invalid discharge meter id',
      'API.INVALID_DISPLAY_NAME': 'Invalid display name',
      'API.INVALID_DISTRIBUTION_CIRCUIT_ID': 'Invalid distribution circuit id',
      'API.INVALID_DISTRIBUTION_CIRCUIT_NAME': 'Invalid distribution circuit name',
      'API.INVALID_DISTRIBUTION_ROOM': 'Invalid distribution room',
      'API.INVALID_DISTRIBUTION_SYSTEM_ID': 'Invalid distribution system id',
      'API.INVALID_DISTRIBUTION_SYSTEM_NAME': 'Invalid distribution system name',
      'API.INVALID_ELECTRICITY_METER_ID': 'Invalid Electricity meter id',
      'API.INVALID_EMAIL': 'Invalid email',
      'API.INVALID_EMAIL_MESSAGE_ID': 'Invalid email message id',
      'API.INVALID_EMAIL_SERVER_HOST': 'Invalid email server host',
      'API.INVALID_EMAIL_SERVER_ID': 'Invalid email server id',
      'API.INVALID_END_DATE_FORMAT': 'Invalid end date format',
      'API.INVALID_END_DATETIME': 'Invalid end datetime',
      'API.INVALID_ENERGY_CATEGORY_ID': 'Invalid energy category id',
      'API.INVALID_ENERGY_CATEGORY_NAME': 'Invalid energy category name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_ID': 'Invalid energy flow diagram id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_LINK_ID': 'Invalid energy flow diagram link id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NAME': 'Invalid energy flow diagram name',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_ID': 'Invalid energy flow diagram node id',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_NAME': 'Invalid energy flow diagram node name',
      'API.INVALID_ENERGY_ITEM_ID': 'Invalid energy item id',
      'API.INVALID_ENERGY_ITEM_NAME': 'Invalid energy item name',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_ID': 'Invalid energy storage power station ID',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_NAME': 'Invalid energy storage power station name',
      'API.INVALID_EQUATION_IN_EXPRESSION': 'Invalid equation in expression',
      'API.INVALID_EQUIPMENT_ID': 'Invalid equipment id',
      'API.INVALID_EQUIPMENT_NAME': 'Invalid equipment name',
      'API.INVALID_EQUIPMENT_PARAMETER_ID': 'Invalid equipment parameter id',
      'API.INVALID_EQUIPMENT_PARAMETER_NAME': 'Invalid equipment parameter name',
      'API.INVALID_EQUIPMENT_PARAMETER_TYPE': 'Invalid equipment parameter type',
      'API.INVALID_EQUIPMENT_UUID': 'Invalid equipment uuid',
      'API.INVALID_EXPIRES_DATETIME': 'Invalid expires datetime',
      'API.INVALID_EXPRESSION': 'Invalid expression',
      'API.INVALID_EXPRESSION_OBJECT': 'Invalid expression object',
      'API.INVALID_FDD_CODE': 'Invalid fdd code',
      'API.INVALID_FLOORS_VALUE': 'Invalid floors value',
      'API.INVALID_FROM_ADDR': 'Invalid from addr',
      'API.INVALID_GATEWAY_ID': 'Invalid gateway id',
      'API.INVALID_GATEWAY_NAME': 'Invalid gateway name',
      'API.INVALID_HEAT_METER_ID': 'Invalid heat meter id',
      'API.INVALID_HIGH_LIMIT_VALUE': 'Invalid high limit value',
      'API.INVALID_HOURLY_HIGH_LIMIT_VALUE': 'Invalid hourly high limit value',
      'API.INVALID_HOURLY_LOW_LIMIT_VALUE': 'Invalid hourly low limit value',
      'API.INVALID_Id': 'Invalid id',
      'API.INVALID_IS_ADMIN_VALUE': 'Invalid is admin value',
      'API.INVALID_IS_COST_DATA_DISPLAYED': 'Invalid is cost data displayed',
      'API.INVALID_IS_COUNTED_VALUE': 'Invalid is counted value',
      'API.INVALID_IS_ENABLED': 'Invalid is enabled',
      'API.INVALID_IS_HIDDEN': 'Invalid is hidden',
      'API.INVALID_IS_IN_LEASE_VALUE': 'Invalid is in lease value',
      'API.INVALID_IS_INPUT_COUNTED_VALUE': 'Invalid is input counted value',
      'API.INVALID_IS_KEY_TENANT_VALUE': 'Invalid is key tenant value',
      'API.INVALID_IS_OUTPUT_COUNTED_VALUE': 'Invalid is output counted value',
      'API.INVALID_IS_OUTPUT_VALUE': 'Invalid is output value',
      'API.INVALID_IS_READ_ONLY_VALUE': 'Invalid is read only value',
      'API.INVALID_IS_TREND_VALUE': 'Invalid is trend value',
      'API.INVALID_IS_VIRTUAL_VALUE': 'Invalid is virtual value',
      'API.INVALID_KGCE': 'Invalid kgce',
      'API.INVALID_KGCO2E': 'Invalid kgco2e',
      'API.INVALID_KNOWLEDGE_FILE_ID': 'Invalid knowledge file id',
      'API.INVALID_LATITUDE_VALUE': 'Invalid latitude value',
      'API.INVALID_LEASE_NUMBER_VALUE': 'Invalid lease number value',
      'API.INVALID_LONGITUDE_VALUE': 'Invalid longitude value',
      'API.INVALID_LOW_LIMIT_VALUE': 'Invalid low limit value',
      'API.INVALID_MASTER_METER_ID': 'Invalid master meter id',
      'API.INVALID_MENU_ID': 'Invalid menu id',
      'API.INVALID_MESSAGE_DATA': 'Invalid message data',
      'API.INVALID_MESSAGE_TEMPLATE': 'Invalid message template',
      'API.INVALID_MESSAGE_TEMPLATE_ID': 'Invalid message template id',
      'API.INVALID_MESSAGE_VALUE': 'Invalid message value',
      'API.INVALID_METER_ID': 'Invalid meter id',
      'API.INVALID_METER_NAME': 'Invalid meter name',
      'API.INVALID_METER_UUID': 'Invalid meter uuid',
      'API.INVALID_MICROGRID_BATTERY_ID': 'Invalid microgrid battery id',
      'API.INVALID_MICROGRID_BATTERY_NAME': 'Invalid microgrid battery name',
      'API.INVALID_MICROGRID_EVCHARGER_ID': 'Invalid microgrid evcharger id',
      'API.INVALID_MICROGRID_EVCHARGER_NAME': 'Invalid microgrid evcharger name',
      'API.INVALID_MICROGRID_GENERATOR_ID': 'Invalid microgrid generator id',
      'API.INVALID_MICROGRID_GENERATOR_NAME': 'Invalid microgrid generator name',
      'API.INVALID_MICROGRID_GRID_ID': 'Invalid microgrid grid id',
      'API.INVALID_MICROGRID_GRID_NAME': 'Invalid microgrid grid name',
      'API.INVALID_MICROGRID_HEATPUMP_ID': 'Invalid microgrid heatpump id',
      'API.INVALID_MICROGRID_HEATPUMP_NAME': 'Invalid microgrid heatpump name',
      'API.INVALID_MICROGRID_ID': 'Invalid microgrid id',
      'API.INVALID_MICROGRID_LOAD_ID': 'Invalid microgrid load id',
      'API.INVALID_MICROGRID_LOAD_NAME': 'Invalid microgrid load name',
      'API.INVALID_MICROGRID_NAME': 'Invalid microgrid name',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_ID': 'Invalid microgrid photovoltaic ID',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_NAME': 'Invalid microgrid photovoltaic name',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_ID': 'Invalid microgrid power conversion system ID',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_NAME': 'Invalid microgrid microgrid power conversion system name',
      'API.INVALID_MICROGRID_UUID': 'Invalid microgrid UUID',
      'API.INVALID_NAME_VALUE': 'Invalid name value',
      'API.INVALID_NEW_PASSWORD': 'Invalid new password',
      'API.INVALID_NON_WORKING_DAY_ID': 'Invalid non working day ID',
      'API.INVALID_NOTIFICATION_ID': 'Invalid notification id',
      'API.INVALID_NUMERATOR_METER_UUID': 'Invalid numerator meter uuid',
      'API.INVALID_OBJECT_TYPE': 'Invalid object type',
      'API.INVALID_OFFLINE_METER_DATA': 'Invalid offline meter data',
      'API.INVALID_OFFLINE_METER_FILE_ID': 'Invalid offline meter file id',
      'API.INVALID_OFFLINE_METER_ID': 'Invalid offline meter id',
      'API.INVALID_OFFLINE_METER_NAME': 'Invalid offline meter name',
      'API.INVALID_OFFLINE_METER_UUID': 'Invalid offline meter uuid',
      'API.INVALID_OFFSET_CONSTANT_VALUE': 'Invalid offset constant value',
      'API.INVALID_OLD_PASSWORD': 'Invalid old password',
      'API.INVALID_OPENID': 'Invalid openid',
      'API.INVALID_PARENT_SPACE_ID': 'Invalid parent space id',
      'API.INVALID_PASSWORD': 'Invalid password',
      'API.INVALID_PAYLOAD': 'Invalid payload',
      'API.INVALID_PEAK_CURRENT': 'Invalid peak current',
      'API.INVALID_PEAK_LOAD': 'Invalid peak load',
      'API.INVALID_PERIOD_TYPE': 'Invalid period type',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_ID': 'Invalid photovoltaic power station ID',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_NAME': 'Invalid photovoltaic power station name',
      'API.INVALID_POINT_ID': 'Invalid point id',
      'API.INVALID_POINT_NAME': 'Invalid point name',
      'API.INVALID_PORT': 'Invalid port',
      'API.INVALID_POSTAL_CODE_VALUE': 'Invalid postal code value',
      'API.INVALID_POWER_POINT_ID': 'Invalid power point id',
      'API.INVALID_PRIORITY': 'Invalid priority',
      'API.INVALID_PRIVILEGE': 'Invalid privilege',
      'API.INVALID_PRIVILEGE_DATA': 'Invalid privilege data',
      'API.INVALID_PRIVILEGE_ID': 'Invalid privilege id',
      'API.INVALID_PRIVILEGE_NAME': 'Invalid privilege name',
      'API.INVALID_RATIO_VALUE': 'Invalid ratio value',
      'API.INVALID_RECIPIENT_EMAIL': 'Invalid recipient email',
      'API.INVALID_RECIPIENT_MOBILE': 'Invalid recipient mobile',
      'API.INVALID_RECIPIENT_NAME': 'Invalid recipient name',
      'API.INVALID_RECIPIENT_OPENID': 'Invalid recipient openid',
      'API.INVALID_REPLY': 'Invalid reply',
      'API.INVALID_REPORTING_PERIOD_END_DATETIME': 'Invalid reporting period end datetime',
      'API.INVALID_REPORTING_PERIOD_START_DATETIME': 'Invalid reporting period start datetime',
      'API.INVALID_REQUIRES_AUTHENTICATION': 'Invalid requires authentication',
      'API.INVALID_ROOMS_VALUE': 'Invalid rooms value',
      'API.INVALID_RULE_ID': 'Invalid rule id',
      'API.INVALID_RULE_NAME': 'Invalid rule name',
      'API.INVALID_RUN_STATE_POINT_ID': 'Invalid run state point ID',
      'API.INVALID_SCHEDULED_DATETIME': 'Invalid scheduled datetime',
      'API.INVALID_SELL_METER_ID': 'Invalid sell meter id',
      'API.INVALID_SENSOR_ID': 'Invalid sensor id',
      'API.INVALID_SENSOR_NAME': 'Invalid sensor name',
      'API.INVALID_SENSOR_UUID': 'Invalid sensor uuid',
      'API.INVALID_SERIAL_NUMBER': 'Invalid serial number',
      'API.INVALID_SERIAL_PORT': 'Invalid serial port',
      'API.INVALID_SESSION_PLEASE_RE_LOGIN': 'Invalid session please re-login',
      'API.INVALID_SET_VALUE': 'Invalid set value',
      'API.INVALID_SHOPFLOOR_ID': 'Invalid shopfloor id',
      'API.INVALID_SHOPFLOOR_NAME': 'Invalid shopfloor name',
      'API.INVALID_SHOPFLOOR_UUID': 'Invalid shopfloor uuid',
      'API.INVALID_SOURCE_NODE_ID': 'Invalid source node id',
      'API.INVALID_SPACE_ID': 'Invalid space id',
      'API.INVALID_SPACE_NAME': 'Invalid space name',
      'API.INVALID_SPACE_UUID': 'Invalid space uuid',
      'API.INVALID_START_DATE_FORMAT': 'Invalid start date format',
      'API.INVALID_START_DATETIME': 'Invalid start datetime',
      'API.INVALID_STATUS': 'Invalid status',
      'API.INVALID_STORE_ID': 'Invalid store id',
      'API.INVALID_STORE_NAME': 'Invalid store name',
      'API.INVALID_STORE_TYPE_DESCRIPTION': 'Invalid store type description',
      'API.INVALID_STORE_TYPE_ID': 'Invalid store type id',
      'API.INVALID_STORE_TYPE_NAME': 'Invalid store type name',
      'API.INVALID_STORE_TYPE_SIMPLIFIED_CODE': 'Invalid store type simplified code',
      'API.INVALID_STORE_UUID': 'Invalid store uuid',
      'API.INVALID_SUBJECT_VALUE': 'Invalid subject value',
      'API.INVALID_SVG': 'Invalid SVG',
      'API.INVALID_SWITCHGEAR': 'Invalid switchgear',
      'API.INVALID_TARGET_NODE_ID': 'Invalid target node id',
      'API.INVALID_TARIFF_BLOCK_PRICING': 'Invalid tariff block pricing',
      'API.INVALID_TARIFF_ID': 'Invalid tariff id',
      'API.INVALID_TARIFF_TIME_OF_USE_PRICING': 'Invalid tariff time of use pricing',
      'API.INVALID_TARIFF_TYPE': 'Invalid tariff type',
      'API.INVALID_TENANT_ID': 'Invalid tenant id',
      'API.INVALID_TENANT_NAME': 'Invalid tenant name',
      'API.INVALID_TENANT_TYPE_DESCRIPTION': 'Invalid tenant type description',
      'API.INVALID_TENANT_TYPE_ID': 'Invalid tenant type id',
      'API.INVALID_TENANT_TYPE_NAME': 'Invalid tenant type name',
      'API.INVALID_TENANT_TYPE_SIMPLIFIED_CODE': 'Invalid tenant type simplified code',
      'API.INVALID_TENANT_UUID': 'Invalid tenant uuid',
      'API.INVALID_TEXT_MESSAGE_ID': 'Invalid text message id',
      'API.INVALID_TIMEZONE_ID': 'Invalid timezone id',
      'API.INVALID_TOKEN': 'Invalid token',
      'API.INVALID_TOPIC': 'Invalid topic',
      'API.INVALID_UNIT_OF_MEASURE': 'Invalid unit of measure',
      'API.INVALID_UNIT_OF_PRICE': 'Invalid unit of price',
      'API.INVALID_UNITS': 'Invalid units',
      'API.INVALID_USER_ID': 'Invalid user id',
      'API.INVALID_USER_NAME': 'Invalid user name',
      'API.INVALID_USER_NAME_OR_EMAIL': 'Invalid user name or email',
      'API.INVALID_USER_PHONE': 'Invalid user phone',
      'API.INVALID_USER_PLEASE_RE_LOGIN': 'Invalid user please re-login',
      'API.INVALID_USER_UUID': 'Invalid user uuid',
      'API.INVALID_USERNAME': 'Invalid userName',
      'API.INVALID_VARIABLE_METER_ID': 'Invalid variable meter id',
      'API.INVALID_VARIABLE_METER_TYPE': 'Invalid variable meter type',
      'API.INVALID_VARIABLE_NAME': 'Invalid variable name',
      'API.INVALID_VERIFICATION_CODE': 'Invalid verification code',
      'API.INVALID_VIRTUAL_METER_ID': 'Invalid virtual meter id',
      'API.INVALID_VIRTUAL_METER_NAME': 'Invalid virtual meter name',
      'API.INVALID_VIRTUAL_METER_UUID': 'Invalid virtual meter uuid',
      'API.INVALID_WEB_MESSAGE_ID': 'Invalid web message id',
      'API.INVALID_WECHAT_MESSAGE_ID': 'Invalid wechat message id',
      'API.INVALID_WIND_FARM_ID': 'Invalid wind farm ID',
      'API.INVALID_WIND_FARM_NAME': 'Invalid wind farm name',
      'API.INVALID_WORKING_CALENDAR_ID': 'Invalid working calendar id ',
      'API.INVALID_WORKING_CALENDAR_NAME': 'Invalid working calendar name',
      'API.KNOWLEDGE_FILE_CANNOT_BE_REMOVED_FROM_DISK': 'Knowledge File Cannot be Removed from Disk',
      'API.KNOWLEDGE_FILE_NOT_FOUND': 'Knowledge file not found',
      'API.MASTER_METER_DOES_NOT_BELONG_TO_SAME_ENERGY_CATEGORY':
        'Master meter does not belong to same energy category',
      'API.MASTER_METER_NOT_FOUND': 'Master meter not found',
      'API.MENU_NOT_FOUND': 'Menu not found',
      'API.METER_CANNOT_HAVE_MORE_THAN_ONE_ENERGY_VALUE_POINTS': 'Meter cannot have more than one energy value points',
      'API.METER_COMMAND_RELATION_EXISTS': 'Meter command relation exists',
      'API.METER_COMMAND_RELATION_NOT_FOUND': 'Meter command relation not found',
      'API.METER_NAME_IS_ALREADY_IN_USE': 'Meter name is already in use',
      'API.METER_NOT_FOUND': 'Meter not found',
      'API.METER_OF_VARIABLE_NOT_FOUND': 'Meter of variable not found',
      'API.METER_POINT_RELATION_EXISTS': 'Meter point relation exists',
      'API.METER_POINT_RELATION_NOT_FOUND': 'Meter point relation not found',
      'API.MICROGRID_BATTERY_NAME_IS_ALREADY_IN_USE': 'Microgrid battery name is already in use',
      'API.MICROGRID_BATTERY_NOT_FOUND': 'Microgrid battery not found',
      'API.MICROGRID_EVCHARGER_NAME_IS_ALREADY_IN_USE': 'Microgrid evcharger name is already in use',
      'API.MICROGRID_EVCHARGER_NOT_FOUND': 'Microgrid evcharger not fund',
      'API.MICROGRID_GENERATOR_NAME_IS_ALREADY_IN_USE': 'Microgrid generator name is already in use',
      'API.MICROGRID_GENERATOR_NOT_FOUND': 'Microgrid generator not found',
      'API.MICROGRID_GRID_NAME_IS_ALREADY_IN_USE': 'Microgrid grid name is already in use',
      'API.MICROGRID_GRID_NOT_FOUND': 'Microgrid grid not found',
      'API.MICROGRID_HEATPUMP_NAME_IS_ALREADY_IN_USE': 'Microgrid heatpump name is already in use',
      'API.MICROGRID_HEATPUMP_NOT_FOUND': 'Microgrid heatpump not found',
      'API.MICROGRID_LOAD_NAME_IS_ALREADY_IN_USE': 'Microgrid load name is already',
      'API.MICROGRID_LOAD_NOT_FOUND': 'Microgrid load not found',
      'API.MICROGRID_NAME_IS_ALREADY_IN_USE': 'Microgrid name is already in use',
      'API.MICROGRID_NOT_FOUND': 'Microgrid not found',
      'API.MICROGRID_PHOTOVOLTAIC_NAME_IS_ALREADY_IN_USE': 'Microgrid photovoltaic name is already in use',
      'API.MICROGRID_PHOTOVOLTAIC_NOT_FOUND': 'Microgrid photovoltaic not found',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NAME_IS_ALREADY_IN_USE':
        'Microgrid power conversion system name is already in use',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NOT_FOUND': 'Microgrid power conversion system not found',
      'API.MICROGRID_SENSOR_RELATION_EXISTS': 'Microgrid sensor relation exists',
      'API.MICROGRID_SENSOR_RELATION_NOT_FOUND': 'Microgrid sensor not found',
      'API.MQTT_CONNECTION_ERROR': 'Mqtt connection error',
      'API.MQTT_PUBLISH_ERROR': 'Mqtt publish error',
      'API.NEW_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'New password length cannot exceed 100 characters',
      'API.NEW_USER_SESSION_NOT_FOUND': 'New user session not found',
      'API.NEW_USER_SESSION_TIMEOUT': 'New user session timeout',
      'API.NON_WORKING_DAY_NOT_FOUND': 'Non working day non found',
      'API.NOT_FOUND': 'Not found',
      'API.NOTIFICATION_NOT_FOUND': 'Notification not found',
      'API.OFFLINE_METER_FILE_NOT_FOUND': 'Offline meter file not found',
      'API.OFFLINE_METER_NAME_IS_ALREADY_IN_USE': 'Offline meter name is already in use',
      'API.OFFLINE_METER_NOT_FOUND': 'Offline meter not found',
      'API.OFFLINE_METER_OF_VARIABLE_NOT_FOUND': 'Offline meter of variable not found',
      'API.OLD_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Old password length cannot exceed 100 characters',
      'API.PARENT_SPACE_NOT_FOUND': 'Parent space not found',
      'API.PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Password length cannot exceed 100 characters',
      'API.PASSWORDS_MATCH': 'New password is the same as the old password',
      'API.PHOTOVOLTAIC_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Photovoltaic power station name is already in use',
      'API.PHOTOVOLTAIC_POWER_STATION_NOT_FOUND': 'Photovoltaic power station not found',
      'API.POINT_NAME_IS_ALREADY_IN_USE': 'Point name is already in use',
      'API.POINT_NOT_FOUND': 'Point not found',
      'API.POWER_POINT_NOT_FOUND': 'Power point not found',
      'API.PRIVILEGE_NAME_IS_ALREADY_IN_USE': 'Privilege name is already in use',
      'API.PRIVILEGE_NOT_FOUND': 'Privilege not found',
      'API.RULE_NAME_IS_ALREADY_IN_USE': 'Rule name is already in use',
      'API.RULE_NOT_FOUND': 'Rule not found',
      'API.SELL_METER_NOT_FOUND': 'Sell meter not found',
      'API.SENSOR_NAME_IS_ALREADY_IN_USE': 'Sensor name is already in use',
      'API.SENSOR_NOT_FOUND': 'Sensor not found',
      'API.SENSOR_POINT_RELATION_EXISTS': 'Sensor point relation exists',
      'API.SENSOR_POINT_RELATION_NOT_FOUND': 'Sensor point relation not found',
      'API.SHOPFLOOR_COMMAND_RELATION_EXISTS': 'Shopfloor command relation exists',
      'API.SHOPFLOOR_COMMAND_RELATION_NOT_FOUND': 'Shopfloor command relation not found',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_EXISTS': 'Shopfloor equipment relation exists',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_NOT_FOUND': 'Shopfloor equipment relation not found',
      'API.SHOPFLOOR_METER_RELATION_EXISTS': 'Shopfloor meter relation exists',
      'API.SHOPFLOOR_METER_RELATION_NOT_FOUND': 'Shopfloor meter relation not found',
      'API.SHOPFLOOR_NAME_IS_ALREADY_IN_USE': 'Shopfloor name is already in use',
      'API.SHOPFLOOR_NOT_FOUND': 'Shopfloor not found',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_EXISTS': 'Shopfloor offline meter relation exists',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_NOT_FOUND': 'Shopfloor offline meter relation not found',
      'API.SHOPFLOOR_POINT_RELATION_EXISTS': 'Shopfloor point relation exists',
      'API.SHOPFLOOR_POINT_RELATION_NOT_FOUND': 'Shopfloor point relation not found',
      'API.SHOPFLOOR_SENSOR_RELATION_EXISTS': 'Shopfloor sensor relation exists',
      'API.SHOPFLOOR_SENSOR_RELATION_NOT_FOUND': 'Shopfloor sensor relation not found',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_EXISTS': 'Shopfloor virtual meter relation exists',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_NOT_FOUND': 'Shopfloor virtual meter relation not found',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_EXISTS': 'Shopfloor working calendar relation exists',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Shopfloor working calendar relation not found',
      'API.SOC_POINT_NOT_FOUND': 'SOC point not found',
      'API.SOURCE_NODE_NOT_FOUND': 'Source node not found',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_EXISTS': 'Space combined equipment relation exists',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_NOT_FOUND': 'Space combined equipment relation not found',
      'API.SPACE_COMMAND_RELATION_EXISTS': 'Space command relation exists',
      'API.SPACE_COMMAND_RELATION_NOT_FOUND': 'Space command relation not found',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_EXISTS': 'Space Energy Storage Power Station Relation Exists',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_NOT_FOUND': 'Space Energy Storage Power Station Relation Not Found',
      'API.SPACE_EQUIPMENT_RELATION_EXISTS': 'Space equipment relation exists',
      'API.SPACE_EQUIPMENT_RELATION_NOT_FOUND': 'Space equipment relation not found',
      'API.SPACE_METER_RELATION_EXISTS': 'Space meter relation exists',
      'API.SPACE_METER_RELATION_NOT_FOUND': 'Space meter relation not found',
      'API.SPACE_NAME_IS_ALREADY_IN_USE': 'Space name is already in use',
      'API.SPACE_NOT_FOUND': 'Space not found',
      'API.SPACE_NOT_FOUND_IN_PRIVILEGE': 'Space not found in privilege',
      'API.SPACE_OFFLINE_METER_RELATION_EXISTS': 'Space offline meter relation exists',
      'API.SPACE_OFFLINE_METER_RELATION_NOT_FOUND': 'Space offline meter relation not found',
      'API.SPACE_POINT_RELATION_EXISTS': 'Space point relation exists',
      'API.SPACE_POINT_RELATION_NOT_FOUND': 'Space point relation not found',
      'API.SPACE_SENSOR_RELATION_EXISTS': 'Space sensor relation exists',
      'API.SPACE_SENSOR_RELATION_NOT_FOUND': 'Space sensor relation not found',
      'API.SPACE_SHOPFLOOR_RELATION_EXISTS': 'Space shopfloor relation exists',
      'API.SPACE_SHOPFLOOR_RELATION_NOT_FOUND': 'Space shopfloor relation not found',
      'API.SPACE_STORE_RELATION_EXISTS': 'Space store relation exists',
      'API.SPACE_STORE_RELATION_NOT_FOUND': 'Space store relation not found',
      'API.SPACE_TENANT_RELATION_EXISTS': 'Space tenant relation exists',
      'API.SPACE_TENANT_RELATION_NOT_FOUND': 'Space tenant relation not found',
      'API.SPACE_VIRTUAL_METER_RELATION_EXISTS': 'Space virtual meter relation exists',
      'API.SPACE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Space virtual meter relation not found',
      'API.SPACE_WORKING_CALENDAR_RELATION_EXISTS': 'Space working calendar relation exists',
      'API.SPACE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Space working calendar relation not found',
      'API.START_DATETIME_MUST_BE_EARLIER_THAN_END_DATETIME': 'Start datetime must be early than end datetime',
      'API.STORE_COMMAND_RELATION_EXISTS': 'Store command relation exists',
      'API.STORE_COMMAND_RELATION_NOT_FOUND': 'Store command relation not found',
      'API.STORE_METER_RELATION_EXISTS': 'Store meter relation exists',
      'API.STORE_METER_RELATION_NOT_FOUND': 'Store meter relation not found',
      'API.STORE_NAME_IS_ALREADY_IN_USE': 'Store name is already in use',
      'API.STORE_NOT_FOUND': 'Store not found',
      'API.STORE_OFFLINE_METER_RELATION_EXISTS': 'Store offline meter relation exists',
      'API.STORE_OFFLINE_METER_RELATION_NOT_FOUND': 'Store offline meter relation not found',
      'API.STORE_POINT_RELATION_EXISTS': 'Store point relation exists',
      'API.STORE_POINT_RELATION_NOT_FOUND': 'Store point relation not found',
      'API.STORE_SENSOR_RELATION_EXISTS': 'Store sensor relation exists',
      'API.STORE_SENSOR_RELATION_NOT_FOUND': 'Store sensor relation not found',
      'API.STORE_TYPE_NAME_IS_ALREADY_IN_USE': 'Store type name is already in use',
      'API.STORE_TYPE_NOT_FOUND': 'Store type not found',
      'API.STORE_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Store type simplified code is already in use',
      'API.STORE_TYPE_USED_IN_STORE': 'Store type used in store',
      'API.STORE_VIRTUAL_METER_RELATION_EXISTS': 'Store virtual meter relation exists',
      'API.STORE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Store virtual meter relation not found',
      'API.STORE_WORKING_CALENDAR_RELATION_EXISTS': 'Store working calendar relation exists',
      'API.STORE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Store working calendar relation not found',
      'API.TARGET_NODE_NOT_FOUND': 'Target node not found',
      'API.TARIFF_IN_USE': 'Tariff in use',
      'API.TARIFF_IS_ALREADY_ASSOCIATED_WITH_COST_CENTER': 'Tariff is already associated with cost center',
      'API.TARIFF_IS_NOT_ASSOCIATED_WITH_COST_CENTER': 'Tariff is not associated with cost center',
      'API.TARIFF_NAME_IS_ALREADY_IN_USE': 'Tariff name is already in use',
      'API.TARIFF_NOT_FOUND': 'Tariff not found',
      'API.TENANT_COMMAND_RELATION_EXISTS': 'Tenant command relation exists',
      'API.TENANT_COMMAND_RELATION_NOT_FOUND': 'Tenant command relation  not found',
      'API.TENANT_METER_RELATION_EXISTS': 'Tenant meter relation exists',
      'API.TENANT_METER_RELATION_NOT_FOUND': 'Tenant meter relation not found',
      'API.TENANT_NAME_IS_ALREADY_IN_USE': 'Tenant name is already in use',
      'API.TENANT_NOT_FOUND': 'Tenant not found',
      'API.TENANT_OFFLINE_METER_RELATION_EXISTS': 'Tenant offline meter relation exists',
      'API.TENANT_OFFLINE_METER_RELATION_NOT_FOUND': 'Tenant offline meter relation not found',
      'API.TENANT_POINT_RELATION_EXISTS': 'Tenant point relation exists',
      'API.TENANT_POINT_RELATION_NOT_FOUND': 'Tenant point relation not found',
      'API.TENANT_SENSOR_RELATION_EXISTS': 'Tenant sensor relation exists',
      'API.TENANT_SENSOR_RELATION_NOT_FOUND': 'Tenant sensor relation not found',
      'API.TENANT_TYPE_NAME_IS_ALREADY_IN_USE': 'Tenant type name is already in use',
      'API.TENANT_TYPE_NOT_FOUND': 'Tenant type not found',
      'API.TENANT_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Tenant type simplified code is already in use',
      'API.TENANT_TYPE_USED_IN_TENANT': 'Tenant type used in tenant',
      'API.TENANT_VIRTUAL_METER_RELATION_EXISTS': 'Tenant virtual meter relation exists',
      'API.TENANT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Tenant virtual meter relation not found',
      'API.TENANT_WORKING_CALENDAR_RELATION_EXISTS': 'Tenant working calendar relation exists',
      'API.TENANT_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Tenant working calendar relation not found',
      'API.TEXT_MESSAGE_NOT_FOUND': 'Text message not found',
      'API.THE_REPORTING_PERIOD_MUST_BE_LONGER_THAN_15_MINUTES': 'The reporting period must be longer than 15 minutes',
      'API.THERE_IS_RELATION_WITH_CHILD_METERS': 'There is relation with child meters',
      'API.THERE_IS_RELATION_WITH_CHILDREN_SPACES': 'There is relation with children spaces',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENT_PARAMETERS':
        'There is relation with combined equipment parameters',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENTS': 'There is relation with combined equipments',
      'API.THERE_IS_RELATION_WITH_DATA_SOURCES': 'There is relation with data sources',
      'API.THERE_IS_RELATION_WITH_ENERGY_FLOW_DIAGRAM_LINKS': 'There is relation with energy flow diagram links',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_BATTERIES': 'There Is Relation With Energy Storage Containers Batteries',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_GRIDS': 'There Is Relation With Energy Storage Containers Grids',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_LOADS': 'There Is Relation With Energy Storage Containers Loads',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_POWER_CONVERSION_SYSTEMS': 'There Is Relation With Energy Storage Containers Power Conversion Systems',
      'API.THERE_IS_RELATION_WITH_EQUIPMENT_PARAMETERS': 'There is relation with equipment parameters',
      'API.THERE_IS_RELATION_WITH_EQUIPMENTS': 'There is relation with equipments',
      'API.THERE_IS_RELATION_WITH_METER': 'There is relation with meter',
      'API.THERE_IS_RELATION_WITH_METERS': 'There is relation with meters',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METER': 'There is relation with offline meter',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METERS': 'There is relation with offline meters',
      'API.THERE_IS_RELATION_WITH_OTHER_VIRTUAL_METERS': 'There is relation with other virtual meters',
      'API.THERE_IS_RELATION_WITH_SENSORS': 'There is relation with sensors',
      'API.THERE_IS_RELATION_WITH_SHOPFLOORS': 'There is relation with shopfloors',
      'API.THERE_IS_RELATION_WITH_SPACES': 'There is relation with spaces',
      'API.THERE_IS_RELATION_WITH_STORES': 'There is relation with stores',
      'API.THERE_IS_RELATION_WITH_TENANTS': 'There is relation with tenants',
      'API.THERE_IS_RELATION_WITH_USERS': 'There is relation with users',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_METER': 'There is relation with virtual meter',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_POWER_PLANTS': 'Ada hubungan dengan Pjantan Kuasa Maya',
      'API.THIS_DATA_SOURCE_IS_BEING_USED_BY_A_METER': 'This data source is being used by a meter',
      'API.THIS_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This meter is being used by a virtual meter',
      'API.THIS_OFFLINE_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'This offline meter is being used by a virtual meter',
      'API.THIS_SPACE_CANNOT_BE_CLONED': 'This space cannot be cloned',
      'API.THIS_SPACE_CANNOT_BE_DELETED': 'This space cannot be deleted',
      'API.TIMEZONE_NOT_FOUND': 'Timezone not found',
      'API.TOKEN_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'Token not found in headers please login',
      'API.USER_ACCOUNT_HAS_BEEN_LOCKED': 'User Account Has Been Locked',
      'API.USER_ACCOUNT_HAS_EXPIRED': 'User Account Has Expired',
      'API.USER_ACCOUNT_IS_NOT_LOCKED': 'User account is not locked',
      'API.USER_NAME_IS_ALREADY_IN_USE': 'User name is already in use',
      'API.USER_NOT_FOUND': 'User not found',
      'API.USER_PASSWORD_HAS_EXPIRED': 'User Password Has Expired',
      'API.USER_PRIVILEGE_NOT_FOUND': 'User privilege not found',
      'API.USER_SESSION_NOT_FOUND': 'User session not found',
      'API.USER_SESSION_TIMEOUT': 'User session timeout',
      'API.USER_UUID_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'User uuid not found in headers please login',
      'API.VIRTUAL_METER_NAME_IS_ALREADY_IN_USE': 'Virtual meter name is already in use',
      'API.VIRTUAL_METER_NOT_FOUND': 'Virtual meter not found',
      'API.VIRTUAL_METER_OF_VARIABLE_NOT_FOUND': 'Virtual meter of variable not found',
      'API.VIRTUAL_POINT_SHOULD_BE_ANALOG_VALUE': 'Virtual point should be analog value',
      'API.WEB_MESSAGE_NOT_FOUND': 'Web message not found',
      'API.WECHAT_MESSAGE_NOT_FOUND': 'Wechat message not found',
      'API.WIND_FARM_NAME_IS_ALREADY_IN_USE': 'Wind farm name is already in use',
      'API.WIND_FARM_NOT_FOUND': 'Wind farm not found',
      'API.WORKING_CALENDAR_NAME_IS_ALREADY_IN_USE': 'Working calendar name is already in use',
      'API.WORKING_CALENDAR_NOT_FOUND': 'Working calendar not found',

      MasterMeter: 'Jadual Ringkasan',
      SubMeter: 'Subjadual'
    }
  },
  id: {
    translation: {
      // routes & menus
      Dashboard: 'Overview',
      'Space Data': 'Data Ruang',
      'Equipment Data': 'Data Perangkat',
      'Meter Data': 'Data Meter',
      'Tenant Data': 'Data Tenant',
      'Store Data': 'Simpan Data',
      'Shopfloor Data': 'Data Workshop',
      'Combined Equipment Data': 'Data Perangkat Kombinasi',
      'Auxiliary System': 'Sistem Bantuan',
      Microgrid: 'Microgrid',
      'Energy Storage Power Station': 'Stasiun Power Storage Energi',
      'Photovoltaic Power Station': 'Power Station',
      'Fault Alarm': 'Alarm Cacat',
      Monitoring: 'Monitor Peralatan',
      'Advanced Reporting': 'Laporan Lanjutan',
      'Knowledge Base': 'Dasar Pengetahuan',
      'Energy Category Data': 'Analis Klasifikasi Konsumsi Energi',
      'Energy Item Data': 'Analis Pembatasan Konsumsi Energi',
      Carbon: 'Emisi CO2',
      Cost: 'Analisis Biaya',
      Output: 'Analis Keluaran',
      Income: 'Analisi Pendapatan',
      Efficiency: 'Analis Efisiensi',
      Load: 'Analisi Muat',
      Statistics: 'Analisis Statistik',
      'Batch Analysis': 'Analisi Seri',
      Saving: 'Analis Penyimpanan Energi',
      Plan: 'Rencana',
      'Production': 'Production',
      'Space Production': 'Space Production',
      'Equipment Tracking': 'Buku Peralatan',
      'Environment Monitor': 'Monitoring Lingkungan',
      'Meter Energy': 'Analis Konsumsi Energi Dari Meter',
      'Meter Carbon': 'Meter Emisi CO2',
      'Meter Cost': 'Analisi Biaya Tabel Pengukuran',
      'Meter Trend': 'Analisi Trend Meters',
      'Meter Realtime': 'Analisis Waktu Nyata Dari Meter Pengukuran',
      'Meter Saving': 'Analisis Penyimpanan Energi Dari Ukuran Meter',
      'Meter Plan': 'Rencana Meteran',
      'Master Meter Submeters Balance': 'Analisi Bilansi Tabel Skor Total',
      'Meter Batch Analysis': 'Analisis Seri Dari Meter Pengukuran',
      'Meter Comparison': 'Analisi Komparatif Dari Meter Ukuran',
      'Meter Tracking': 'Pemberi Tabel Keukuran',
      'Virtual Meter Saving': 'Analis Menyimpan Energi Tabel Virtual',
      'Virtual Meter Plan': 'Paket Meteran Virtual',
      'Virtual Meter Energy': 'Analis Konsum Energi Tabel Virtual',
      'Virtual Meter Carbon': 'Emisi CO2 Meter Virtual',
      'Virtual Meter Cost': 'Analisi Biaya Tabel Virtual',
      'Virtual Meter Batch Analysis': 'Analisi Batch Tabel Virtual',
      'Offline Meter Energy': 'Analis Konsum Energi Jadual Offline',
      'Offline Meter Carbon': 'Emisi Meter CO2 Offline',
      'Offline Meter Cost': 'Analisi Biaya Tabel Offline',
      'Offline Meter Batch Analysis': 'Analisi Batch Tabel Offline',
      'Offline Meter Saving': 'Analis Penyimpanan Energi Dari Tabel Offline',
      'Offline Meter Plan': 'Paket Pengukur Offline',
      'Offline Meter Input': 'Entri Data Tabel Offline',
      'Tenant Bill': 'Pembayaran Sengaja',
      'Energy Flow Diagram': 'Diagram Aliran Energi',
      'Distribution System': 'Sistem Distribusi Listrik',
      'SVG System': 'Sistem Visualisasi Konfigurasi',
      'Faults Data': 'Analisis Kesalahan',
      'Space Equipments': 'Peralatan Ruang',
      'Combined Equipments': 'Peralatan Kombinasi',
      'Energy Storage Power Station List': 'List',
      'Energy Storage Power Station Details': 'Details',
      'Energy Storage Power Station Reporting': 'Reporting',
      'Energy Storage Power Station Alarm': 'Alarm',
      'Energy Storage Power Station Maintenance': 'Maintenance',
      'Enter Production': 'Enter Production',
      'Multiple Energy Storage Power Stations': 'Multiple Power Stations',
      'Multiple Energy Storage Power Stations Dashboard': 'Dashboard',
      'Multiple Energy Storage Power Stations List': 'List',
      'Single Energy Storage Power Station': 'Single Power Station',
      'Single Energy Storage Power Station Dashboard': 'Dashboard',
      'Single Energy Storage Power Station Details': 'Details',
      'Single Energy Storage Power Station Fault Alarm': 'Fault Alarm',
      'Energy Storage Power Station Reports': 'Power Station Reports',
      'Energy Storage Power Station Reports Energy': 'Energy',
      'Energy Storage Power Station Reports Revenue': 'Revenue',
      'Energy Storage Power Station Reports Parameters': 'Parameters',
      'Photovoltaic Power Station List': 'Power Station List',
      'Photovoltaic Power Station Details': 'Details',
      'Photovoltaic Power Station Reporting': 'Reporting',
      'Photovoltaic Power Station Alarm': 'Alarm',
      'Photovoltaic Power Station Maintenance': 'Maintenance',
      'Multiple Photovoltaic Power Stations': 'Multiple Power Stations',
      'Multiple Photovoltaic Power Stations Dashboard': 'Dashboard',
      'Multiple Photovoltaic Power Stations List': 'List',
      'Single Photovoltaic Power Station': 'Single Power Station',
      'Single Photovoltaic Power Station Dashboard': 'Dashboard',
      'Single Photovoltaic Power Station Details': 'Details',
      'Single Photovoltaic Power Station Fault Alarm': 'Fault Alarm',
      'Photovoltaic Power Station Reports': 'Power Station Reports',
      'Photovoltaic Power Station Reports Energy': 'Energy',
      'Photovoltaic Power Station Reports Revenue': 'Revenue',
      'Photovoltaic Power Station Reports Parameters': 'Parameters',
      'Work Order': 'Work Order',
      'Work Order Installation': 'Installation',
      'Work Order Repair': ' Repair',
      'Work Order Inspection': 'Inspection',
      'Total Number of Work Orders': 'Jumlah Total Perintah Kerja',
      'Number of New Work Orders': 'Jumlah Perintah Kerja Baru',
      'Number of Inprogress Work Orders': 'Jumlah Perintah Kerja yang Sedang Diproses',
      'Number of Done Work Orders': 'Jumlah Perintah Kerja yang Selesai',
      'Charge Energy Indicator': 'Energi Pengisian Daya',
      'Discharge Energy Indicator': 'Energi Pembuangan Daya',
      'Charge Cost Indicator': 'Biaya Pengisian Daya',
      'Discharge Revenue Indicator': 'Pendapatan Pembuangan Daya',
      'HVAC': 'HVAC',
      'Fire Control': 'Pengendalian Kebakaran',

      // Dashboard
      'Welcome to DoraEOS': 'Selamat Datang Di DoraEOS',
      'An Industry Leading Open Source Energy Management System':
        'Industri Memimpin Sistem Manajemen Energi Sumber Terbuka',
      "This Year's Consumption CATEGORY VALUE UNIT": 'Konsum Tahun Ini {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Year's Costs CATEGORY VALUE UNIT": 'Biaya Tahun Ini {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Consumption CATEGORY VALUE UNIT": 'Konsum Bulan Ini {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Costs CATEGORY VALUE UNIT": 'Biaya Bulan Ini {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Consumption CATEGORY VALUE UNIT in The Same Period Last Year':
        'Konsum Selama Periode Yang Sama Tahun Lalu {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Costs CATEGORY VALUE UNIT in The Same Period Last Year':
        'Biaya Untuk Periode Yang Sama Tahun Lalu {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'CATEGORY Consumption UNIT': '{{CATEGORY}} Konsum {{UNIT}}',
      'CATEGORY Costs UNIT': '{{CATEGORY}} Biaya {{UNIT}}',
      // Entities
      Space: 'Ruang',
      Equipment: 'Peralatan',
      Meter: 'Gauges',
      'Offline Meter': 'Jadual Offline',
      'Virtual Meter': 'Tabel Virtual',
      Tenant: 'Penyewa',
      Store: 'Toko',
      Shopfloor: 'Workshop',
      'Combined Equipment': 'Peralatan Kombinasi',
      'Cost Center': 'Pusat Biaya',
      Name: 'Nama',
      DisplayName: 'Tampilkan Nama',
      UserName: 'Nama Pengguna',
      Description: 'Deskripsikan',
      'Energy Category': 'Klasifikasi Energi',
      'Child Spaces': 'Subspace',
      'Associated Equipment': 'Peralatan Yang Berhubungan',
      //Energy Categories
      Electricity: 'Listrik',
      Water: 'Water Tap',
      'Natural Gas': 'Gas Alami',
      // SidePanelModal
      Settings: 'Mengatur',
      'Set your own customized style': 'Tetapkan Gaya Suai Anda',
      'Color Scheme': 'Skema Warna',
      'Choose the perfect color mode for your app': 'Pilih Mode Warna Ideal Untuk Aplikasi Anda',
      Light: 'Warna Cerah',
      Dark: 'Gelap',
      'RTL Mode': 'Mod RTL',
      'Switch your language direction': 'Ubah Arah Bahasa Anda',
      'Fluid Layout': 'Bentangan Aliran',
      'Toggle container layout system': 'Tukar Sistem Bentangan Kontainer',
      'Navigation Position': 'Lokasi Bar Navigasi',
      'Select a suitable navigation system for your web application':
        'Pilih Sistem Bar Navigasi Yang Sesuai Untuk Aplikasi Web Anda',
      'Vertical Nav': 'Bar Navigasi Vertikal',
      'Top Nav': 'Bar Navigasi Atas',
      'Vertical Navbar Style': 'Gaya Bar Navigasi Vertikal',
      'Switch between styles for your vertical navbar': 'Tukar Gaya Bar Navigasi Vertikal',
      Language: 'Bahasa',
      'Switch between languages': 'Tukar Bahasa',
      'language-zh_CN': '简体中文',
      'language-en': 'English',
      'language-de': 'Deutsch',
      'language-fr': 'Français',
      'language-es': 'Español',
      'language-ru': 'Русский',
      'language-ar': 'العربية',
      'language-vi': 'Tiếng Việt',
      'language-th': 'ไทย',
      'language-tr': 'Türkçe',
      'language-ms': 'Bahasa Melayu',
      'language-id': 'Bahasa Indonesia',
      'language-zh_TW': '繁體中文',
      'Like What You See?': 'Saya Suka Sistem Ini?',
      'Get DoraEOS now': 'Dapatkan DoraEOS Sekarang',
      Purchase: 'Membeli',
      // Query Panels
      'Base Period Begins': 'Mulai Periode Benchmark',
      'Base Period Ends': 'Akhir Periode Benchmark',
      'Comparison Types': 'Compare Types',
      'Year-Over-Year': 'Tahun lalu-tahun',
      'Month-On-Month': 'Bulan Pada Bulan',
      'Free Comparison': 'Rasio Kebebasan',
      'None Comparison': 'Tidak Dibandingkan Dengan',
      'Reporting Period Begins': 'Mulai Periode Laporan',
      'Reporting Period Ends': 'Akhir Periode Laporan',
      '(Optional)': '(Opsional)',
      'Period Types': 'Skala Waktu',
      Yearly: 'Tahun',
      Monthly: 'Bulan',
      Weekly: 'Minggu',
      Daily: 'Hari',
      Hourly: 'Jam',
      Submit: 'Kirim',
      'Input Energy Category': 'Klasifikasi Konsumsi Energi',
      'Output Energy Category': 'Klasifikasi Energi Keluaran',
      'Fraction Parameter': 'Parameter Fraksi',
      Search: 'Cari',
      //DateRangePicker
      sunday: 'Minggu',
      monday: 'Senin',
      tuesday: 'Selasa',
      wednesday: 'Rabu',
      thursday: 'Kamis',
      friday: 'Jumat',
      saturday: 'Sabtu',
      ok: 'Konfirmasi',
      today: 'Hari Ini',
      yesterday: 'Kemarin',
      hours: 'Jam',
      minutes: 'Cabang',
      seconds: 'Detik',
      last7Days: 'Tujuh Hari Terakhir',
      'Select Date Range': 'Pilih Jangkauan Tanggal',
      //Card Summaries and Line Charts
      'Base Period': 'Periode Dasar',
      'Reporting Period': 'Periode Laporan',
      'Per Unit Area': 'Nilai Area Unit',
      'Per Capita': 'Per Capita',
      'Per Unit Production': 'Nilai Produk Unit',
      Baseline: 'Nilai Garis Dasar',
      Actual: 'Nilai Sebenarnya',
      'Baseline Value - Actual Value': 'Nilai Garis Dasar - Nilai Sebenarnya',
      'Average Load': 'Bekas Rata-rata',
      'Maximum Load': 'Muat Maksimum',
      'Load Factor': 'Faktor Muat',
      'Ratio of Average Load to Maximum Load': 'Rasio Dari Beban Rata-rata Ke Beban Maksimum',
      'Carbon Dioxide Emissions by Energy Category': 'Proporci Emisi Dioksida Karbon',
      'Costs by Energy Category': 'Proporci Biaya',
      'Incomes by Energy Category': 'Proporci Pendapatan',
      'Electricity Consumption by Time-Of-Use': 'Konsum Daya Berbagi Waktu',
      'Electricity Carbon Dioxide Emissions by Time-Of-Use': 'Waktu Berbagi Emisi Listrik Karbon Dioksida',
      'Electricity Cost by Time-Of-Use': 'Waktu Berbagi Biaya Listrik',
      'CATEGORY UNIT Consumption by Energy Items': '{{CATEGORY}} {{UNIT}} Proporci Konsumsi Yang Ditentukan',
      'Ton of Standard Coal by Energy Category': 'Proporci Ton Coal Standard (TCE)',
      'Ton of Carbon Dioxide Emissions by Energy Category': 'Proporci Ton Emisi Carbon Dioxide (TCO2E)',
      'Reporting Period Consumption CATEGORY UNIT': 'Konsum Selama Periode Laporan {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{EQUIPMENT}} Konsum Selama Periode Laporan {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Konsum Selama Periode Laporan {{CATEGORY}} {{UNIT}}',
      'CATEGORY VALUE UNIT': '{{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption CATEGORY VALUE UNIT':
        'Konsum Selama Periode Laporan {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Production PRODUCT VALUE UNIT': 'Reporting Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Production PRODUCT VALUE UNIT': 'Base Period Production {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption CATEGORY VALUE UNIT': 'Konsum Periode Dasar {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY UNIT': 'Konsum Selama Periode Laporan {{ITEM}} {{CATEGORY}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY VALUE UNIT':
        'Konsum Selama Periode Laporan {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption ITEM CATEGORY VALUE UNIT':
        'Konsum Periode Dasar {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Child Space Proportion CATEGORY UNIT': 'Proporci Klasifikasi Subspace {{CATEGORY}} {{UNIT}}',
      'Child Space Total Proportion': 'Proporci Total Subspace',
      'Reporting Period Carbon Dioxide Emissions CATEGORY UNIT':
        'Emisi Dioksida Karbon Selama Periode Laporan {{CATEGORY}} {{UNIT}}',
      'Reporting Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Emisi Dioksida Karbon Selama Periode Laporan {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        'Emisi Dioksida Karbon Selama Periode Dasar {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Costs CATEGORY UNIT': 'Biaya Selama Periode Laporan {{CATEGORY}} {{UNIT}}',
      'Reporting Period Costs CATEGORY VALUE UNIT': 'Biaya Selama Periode Laporan {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Costs CATEGORY VALUE UNIT': 'Biaya Peristiwa Dasar {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Output CATEGORY UNIT': 'Output Selama Periode Laporan {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{EQUIPMENT}} Output Selama Periode Laporan {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} Output Selama Periode Laporan {{CATEGORY}} {{UNIT}}',
      'Reporting Period Output CATEGORY VALUE UNIT': 'Output Selama Periode Laporan {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Output CATEGORY VALUE UNIT': 'Output Periode Benchmark {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Income CATEGORY UNIT': 'Daftar Selama Periode Laporan {{CATEGORY}} {{UNIT}}',
      'Reporting Period Income CATEGORY VALUE UNIT': 'Daftar Selama Periode Laporan {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Income CATEGORY VALUE UNIT': 'Daftar Periode Dasar {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Total Income UNIT': 'Total Pendapatan Selama Periode Laporan {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME UNIT':
        'Efisiensi Berkumpul Selama Periode Laporan {{NAME}} {{UNIT}}',
      'EQUIPMENT Reporting Period Cumulative Efficiency UNIT':
        '{{EQUIPMENT}} Efisiensi Berkumpul Selama Periode Laporan {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Efisiensi Komprensif Berkumpul Selama Periode Laporan {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Efisiensi Komprensif Berkumpul Selama Periode Laporan {{VALUE}} {{UNIT}}',
      'COMBINED_EQUIPMENT Base Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} Efisiensi Komprensif Berkumpul Selama Periode Benchmark {{VALUE}} {{UNIT}}',
      'Instantaneous Efficiency UNIT': 'Efisiensi Segera {{UNIT}}',
      'EQUIPMENT Instantaneous Efficiency UNIT': '{{EQUIPMENT}} Efisiensi Segera {{UNIT}}',
      'COMBINED_EQUIPMENT Instantaneous Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} Efisiensi Komprensif Instan {{UNIT}}',
      'Reporting Period Cumulative Efficiency VALUE UNIT':
        'Efisiensi Berkumpul Selama Periode Laporan {{VALUE}} {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME VALUE UNIT':
        'Efisiensi Berkumpul Selama Periode Laporan {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency VALUE UNIT': 'Efisiensi Kumulatif Periode Dasar {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency NAME VALUE UNIT':
        'Efisiensi Kumulatif Periode Dasar {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period CATEGORY Maximum Load UNIT': 'Periode Dasar {{CATEGORY}} Muat Maksimum {{UNIT}}',
      'Reporting Period CATEGORY Maximum Load UNIT': 'Periode Laporan {{CATEGORY}} Muat Maksimum {{UNIT}}',
      'Reporting Period CATEGORY Average Load UNIT': 'Periode Laporan {{CATEGORY}} Bekas Rata-rata {{UNIT}}',
      'Reporting Period CATEGORY Load Factor': 'Periode Laporan {{CATEGORY}} Faktor Muat',
      'Base Period CATEGORY Average Load VALUE UNIT': 'Periode Dasar {{CATEGORY}} Bekas Rata-rata {{VALUE}} {{UNIT}}',
      'Reporting Period CATEGORY Average UNIT': 'Periode Laporan {{CATEGORY}} Nilai Rata-rata {{UNIT}}',
      'Reporting Period CATEGORY Maximum UNIT': 'Periode Laporan {{CATEGORY}} Nilai Maksimum {{UNIT}}',
      'Reporting Period CATEGORY Minimum UNIT': 'Periode Laporan {{CATEGORY}} Nilai Minimum {{UNIT}}',
      'Reporting Period CATEGORY Mean UNIT': 'Periode Laporan {{CATEGORY}}  Maksudnya Aritmetik {{UNIT}}',
      'Reporting Period CATEGORY Median UNIT': 'Periode Laporan {{CATEGORY}} Median {{UNIT}}',
      'Reporting Period CATEGORY Stdev UNIT': 'Periode Laporan {{CATEGORY}} Abaikan Standar Sampel {{UNIT}}',
      'Reporting Period CATEGORY Variance UNIT': 'Periode Laporan {{CATEGORY}} Variasi Sampel {{UNIT}}',
      'Reporting Period Saving CATEGORY (Baseline - Actual) UNIT':
        'Penyimpanan Selama Periode Laporan {{CATEGORY}} (Baseline-Sebenar) {{UNIT}}',
      'Reporting Period Decreased CATEGORY (Baseline - Actual) UNIT':
        'Pengurangan Dalam Periode Laporan {{CATEGORY}} (Baseline-Sebenar) {{UNIT}}',
      'Reporting Period Saving CATEGORY VALUE UNIT':
        'Penyimpanan Selama Periode Laporan {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Saving CATEGORY VALUE UNIT': 'Penyelamatan Peristiwa Benchmark {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY UNIT':
        'Total Konsum Tabel Selama Period Laporan {{CATEGORY}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY VALUE UNIT':
        'Total Konsum Tabel Selama Period Laporan {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY UNIT':
        'Konsum Menurut Tabel Selama Periode Laporan {{CATEGORY}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY VALUE UNIT':
        'Konsum Menurut Tabel Selama Periode Laporan {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Difference CATEGORY UNIT': 'Perbedaan Periode Laporan {{CATEGORY}} {{UNIT}}',
      'Reporting Period Percentage Difference': 'Percentage Perbedaan Selama Periode Laporan',
      'Reporting Period Difference CATEGORY VALUE UNIT': 'Perbedaan Periode Laporan {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Trend Values': 'Nilai Perkembangan',
      'METER CATEGORY VALUE UNIT': '{{METER}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      //FDD
      'Fault List': 'Daftar Gagal',
      'Reporting Period Number of Fault VALUE': 'Jumlah Kesalahan Selama Periode Laporan: {{VALUE}}',
      'All Faults': 'Semua Kesalahan',
      'Space Faults': 'Kesalahan Ruang',
      'Number of All Faults': 'Jumlah Total Kesalahan',
      'Number of Space Faults': 'Jumlah Kesalahan Ruang',
      'Total Number of Faults': 'Total Number of Faults',
      'Number of New Faults': 'Number of New Faults',
      'Number of Inprogress Faults': 'Number of Inprogress Faults',
      'Number of Done Faults': 'Number of Done Faults',
      //Data Panels
      'Ton of Standard Coal': 'Ton Batubara Standar',
      'Ton of Carbon Dioxide Emissions': 'Ton Emisi Dioksida Karbon',
      'Operating Characteristic Curve': 'kurva karakteristik operasi',
      Tariff: 'Kadar',
      'Detailed Data': 'Data Rincian',
      'Child Spaces Data': 'Data Subspace',
      'Associated Equipment Data': 'Data Peralatan Terhubung',
      'This Year': 'Tahun Ini',
      'The Same Period Last Year': 'Periode Yang Sama Tahun Lalu',
      'This Month': 'Bulan Ini',
      'This Day': 'Hari Ini',
      Total: 'Total',
      'No data found': 'Tidak Ada Data',
      Export: 'Eksport',
      Datetime: 'Waktu Tarikh',
      'Time-Of-Use Type': 'Jenis Berbagi Waktu',
      'Top-Peak': 'Tip',
      'On-Peak': 'Puncak',
      'Mid-Peak': 'Flat',
      'Off-Peak': 'Lembah',
      Percentage: 'Persentase',
      //Realtime Chart
      'Trend in the last hour of Energy Value Point': 'Tenderasi Titik Konsumsi Energi Dalam Jam Terakhir',
      Circuit: 'Loop',
      Point: 'Titik Data',
      'Realtime Value': 'Nilai Waktu Nyata',
      //Meter Tracking
      'Meter List': 'Daftar Meter Ukuran',
      'Edit Meter': 'Edit',
      'Start Value': 'Nilai Awal',
      'End Value': 'Nilai Akhir',
      'Difference Value': 'Perbedaan',
      'Start Integrity Rate': 'Memulai Tingkat Integritas Data(%)',
      'End Integrity Rate': 'Tingkat Integritas Data Akhir(%)',
      'Full Integrity Rate': 'Tingkat Integritas Data Umum(%)',
      //Equipment Tracking
      'Equipment List': 'Daftar Peranti',
      'Edit Equipment': 'Edit',
      //Profile Dropdown
      Feedback: 'Feedback',
      'Account Settings': 'Pengaturan Akaun',
      Logout: 'Keluar',
      //Authentication
      'Log in': 'Login',
      'Email address': 'Alamat Surel',
      Password: 'Sandi',
      CaptchaCode: 'Kode Verifikasi',
      Refresh: 'Segar',
      'Remember me': 'Ingat Aku',
      'Logged in as ': 'Daftar Masuk ',
      'Forgot Password?': 'Lupakan Kata Sandi?',
      'Forgot your password?': 'Lupakan Kata Sandimu?',
      "Enter your email and we'll send you a reset link":
        'Masukkan Alamat Email Dan Kami Akan Mengirimkan Anda Link Reset',
      "Enter your email and we'll send you a registration link":
        'Masukkan Alamat Email Dan Kami Akan Mengirimkan Link Pendaftaran',
      'Reset Password': 'Reset Kata Sandi',
      'Thanks for using DoraEOS!': 'Terima Kasih Telah Menggunakan DoraEOS!',
      'You are now successfully signed out': 'Anda Berhasil Keluar',
      'Return to Login': 'Kembali Ke Daftar Masuk',
      'Please check your email!': 'Silakan Periksa Email Anda!',
      'An email has been sent to ': 'Email Telah Dikirim Ke ',
      'Please click on the included link to reset your password':
        'Silakan Klik Pada Link Di Dalam untuk Reset Kata Sandi Anda',
      'Please click on the included link to register your account':
        'Silakan Klik Pada Link Di Dalam Untuk Mendaftar Rekening Anda',
      'An email with password reset link is sent to ':
        'Sebuah Email Yang Mengandung Sambungan Reset Kata Sandi Telah Dikirim Ke ',
      'Change Password': 'Ubah Kata Sandi',
      'Old Password': 'Kata Sandi Asal',
      'New Password': 'Kata Sandi Baru',
      'Confirm Password': 'Ulangi Kata Sandi Baru',
      'Update Password': 'Kemaskini Kata Sandi',
      'Password has been changed!': 'Kata Sandi Telah Diubah!',
      'Verification code has been sent to your inbox. \
      Please copy it to the input box below.':
        'Kode Verifikasi Telah Dikirim Ke Kotak Masuk Anda. Silakan Salin Ke Kotak Masukan Di Bawah。',
      'New to DoraEOS': 'Penggunaan Awal DoraEOS',
      'Create an account': 'Daftar Rekening',
      'EMAIL Account registration successful': '{{EMAIL}}Reġistrasi Rekening Berhasil',
      'Thanks for verifying your account!': 'Terima Kasih Sudah Memeriksa Rekening Anda！',
      'Your code is': 'Kode Anda Adalah',
      'Send verification code': 'Kirim Kode Verifikasi',
      'Please wait for NUMBER seconds': 'Silakan Berdiri{{NUMBER}}Detik',
      'Password reset': 'Reset Kata Sandi',
      'Please wait for approval': 'Silakan Tunggu Ulang',
      //notification
      Notifications: 'Notis',
      'Mark all as read': 'Set Semua Sebagai Baca',
      'View all': 'Seluruh',
      'Notification New': 'BaruNew',
      'Notification Earlier': 'Sebelumnya',
      'Notification List': 'Senarai Notifikasi',
      'Bulk actions': 'Operasi Seri',
      'Notification Subject': 'Tema',
      'Notification Created Datetime': 'Tanggal Waktu Dibuat',
      'Notification Message': 'Isi',
      'Notification Priority': 'Prioritas',
      'Notification Status': 'Keadaan',
      'Notification Mark As Read': 'Tanda Sebagai Baca',
      'Notification Mark As Acknowledged': 'Konfirmasi',
      'Notification Low': 'Rendah',
      'Notification Medium': 'Dalam',
      'Notification High': 'Tinggi',
      'Notification Critical': 'Serius',
      'Notification Unread': 'Belum Dibaca',
      'Notification Read': 'Baca',
      'Notification Acknowledged': 'Dikonfirmasi',
      'Notification Archive': 'Arkib',
      'Notification Delete': 'Hapus',
      'Notification Apply': 'Aplikasi',
      'Notification Start Datetime': 'Mulai Tanggal Waktu',
      'Notification End Datetime': 'Tanggal Waktu Berakhir',
      'Notification Update Datetime': 'Perbarui Tanggal Waktu',
      //Monitoring
      'Run Commands': 'Jalankan Perintah',
      'Fault Alarms': 'Alarm Cacat',
      'Instantaneous Efficiency VALUE UNIT': 'Efisiensi Segera {{VALUE}} {{UNIT}}',
      'Communication Status': 'Status Komunikasi',
      'Communication Online': 'Di Talian',
      'Communication Offline': 'Off Line',
      'Equipment Status': 'Status Perangkat',
      'Equipment Running': 'Bekerja',
      'Equipment Stopped': 'Matikan',
      'Show Up to': 'Tampilkan Kuantitas Per Halaman',
      All: 'Seluruh',
      'FROM - TO of TOTAL': 'Tampilan Saat Ini{{FROM}} - {{TO}} Total{{TOTAL}}Peranti',
      //Microgrid
      'PCS Run State': 'PCS Run State',
      'PCS Unknown': 'Tidak diketahui',
      'PCS Initializing': 'Menginisialisasi',
      'PCS Standby': 'Bersiap',
      'PCS Shutdown': 'Matikan',
      'PCS Fault': 'Gagal',
      'PCS Running': 'Running',
      'Battery Power': 'Battery power',
      'Photovoltaic Power': 'Kekuatan fotovoltaik',
      'Grid Power': 'Kuasa jaringan',
      'Load Power': 'Kuat muat',
      Reporting: 'Laporan',
      Maintenance: 'Penjagaan',
      'Serial Number': 'Nomor Seri',
      Address: 'Alamat',
      'Postal Code': 'Kode Postal',
      'Rated Capacity': 'Kapasitas Dinilai',
      'Rated Power': 'Kuasa Dinilai',
      Latitude: 'Latitude',
      Longitude: 'Longitude',
      'Strategy Management': 'Manajemen strategi',
      'Charging Schedule': 'Memuatkan Schedule',
      'Charge Start Time': 'Muat Waktu Mula',
      'Charge End Time': 'Waktu Akhir Muat',
      'Discharge Start Time': 'Buang Waktu Mula',
      'Discharge End Time': 'Waktu Akhir Buan',
      'Total Rated Power': 'Nilai Daya Total',
      'Total Rated Capacity': 'Kapasitas Nilai Total',
      "Today's Charge": "Jumlah penagihan hari ini",
      "Today's Discharge": "Keluar hari ini",
      "Today's Generation": "Pembangkit listrik saat ini",
      'Total Charge': 'Total biaya',
      'Total Discharge': 'debit total',
      'Total Generation': 'Generasi Total',
      'Total Revenue': 'Total pendapatan',
      'Total Efficiency': 'Efisiensi Total',
      'Discharge Achievement Rate': 'Tingkat Pencapaian Pelepasan',
      // Revenue Indicator
      "Today's Cost": "Today's Cost",
      "Today's Income": "Today's Income",
      "Total Cost": "Total Cost",
      "Total Income": "Total Income",
      "Today's Revenue": "Today's Revenue",

      'Revenue Ranking': 'Peringkat pendapatan',
      'Efficiency Ranking': 'Peringkat efisiensi',
      'Charge Ranking': 'Peringkat kapasitas pengisian daya',
      'Discharge Ranking': 'Peringkat pelepasan',
      'Number of Microgrids': 'Jumlah microgrid',
      'Number of Power Stations': 'Jumlah pembangkit listrik',
      'Microgrid List': 'Daftar Mikrogrid',
      'Previous Page': 'Halaman sebelumnya',
      'Next Page': 'Halaman selanjutnya',
      '7 Days': '7 hari',
      'Charge UNIT': 'Mengenakan biaya {{UNIT}}',
      'Discharge UNIT': 'memulangkan {{UNIT}}',
      'Generation UNIT': 'menghasilkan listrik {{UNIT}}',
      'Energy Indicator': 'indeks energi',
      'Revenue Indicator': 'Indikator hasil',
      'Carbon Indicator': 'indeks karbon',

      // Energy Storage Power Station
      'Battery Operating State': 'Status Operasi Bateri',
      'Battery State': 'Status Bateri',
      'Battery Unknown': 'Tidak diketahui',
      'Battery Stopped': 'Berhenti',
      'Battery Reserved': 'Reserved', // 0
      'Battery Fault': 'Gagal', // 1
      'Battery Warning': 'Peringatan', // 2
      'Battery Standby': 'Bersiap', // 3
      'Battery Prohibit DisCharging': 'Melarangkan Memotong', // 4
      'Battery Prohibit Charging': 'Melarangkan Memuatkan', // 5
      'Battery Normal': 'Normal', // 6
      'Battery Charging': 'Memuatkan', // 7
      'Battery Discharging': 'Selama pembebasan', // 8
      'Battery Idle': 'Idle', // 9
      'Phase of Lifecycle': 'Tahap Sepeda Hidup',
      'Use Phase': 'Gunakan Tahap',
      'Commissioning Phase': 'Fase Komisionasi',
      'Installation Phase': 'Tahap Instalasi',
      'Device Status': 'Status Perangkat',
      'Basic Information': 'Informasi Dasar',
      'Gateway': 'Gateway',
      'Grid Meter': 'Grid Meter',
      'Load Meter': 'Load Meter',
      'Total Active Power': 'Total Active Power',
      'Active Power A': 'Active Power A',
      'Active Power B': 'Active Power B',
      'Active Power C': 'Active Power C',
      'Total Reactive Power': 'Total Reactive Power',
      'Reactive Power A': 'Reactive Power A',
      'Reactive Power B': 'Reactive Power B',
      'Reactive Power C': 'Reactive Power C',
      'Total Apparent Power': 'Total Apparent Power',
      'Apparent Power A': 'Apparent Power A',
      'Apparent Power B': 'Apparent Power B',
      'Apparent Power C': 'Apparent Power C',
      'Total Power Factor': 'Total Power Factor',
      'Active Energy Import': 'Active Energy Import',
      'Active Energy Export': 'Active Energy Export',
      'Active Energy Net': 'Active Energy Net',

      // Photovoltaic Power Station
      'Invertor': 'inverter',
      'Invertor Run State': 'Run State',
      'Invertor Unknown': 'Tidak diketahui',
      'Invertor Initializing': 'Menginisialisasi',
      'Invertor Standby': 'Bersiap',
      'Invertor Shutdown': 'Matikan',
      'Invertor Fault': 'Gagal',
      'Invertor Running': 'Running',

      //Advanced Reporting & Knowledge Base
      'Created Datetime': 'Waktu Generasi',
      'File Format': 'Format Berkas',
      'File Size': 'Ukuran Berkas',
      Uploader: 'Penguasa',
      'Upload Datetime': 'Waktu Pengiriman',
      //Error
      "The page you're looking for is not found": 'Halaman Yang Anda Cari Tidak Ada',
      "Make sure the address is correct and that the page hasn't moved":
        'Silakan Konfirmasi Bahwa Alamat Benar Dan Halaman Belum Dipindahkan',
      'If you think this is a mistake,': 'Jika Anda Berpikir Ini Adalah Kesalahan,',
      'contact us': 'Hubungi Kami',
      'Take me home': 'Bawa Aku Kembali Ke Halaman Rumah',
      'Whoops, something went wrong!': 'Oh, Ada Kesalahan!',
      'Try refreshing the page, or going back and attempting the action again':
        'Silakan Coba Menyegarkan Halaman, Atau Kembali Dan Coba Melakukan Operasi Ini Lagi',
      'If this problem persists,': 'Jika Masalah Terus Berlangsung,',
      'Captcha Error': 'Galat Kode Verifikasi',
      'Confirm Password Error': 'Dua Kata Sandi Tidak Cocok',
      'It looks like you clicked on an invalid password reset link. Please tryagain.':
        'Sepertinya Anda Mengklik Pada Sambungan Sandi Reset Tidak Valid. Silakan Coba Lagi。',
      'It looks like you clicked on an invalid registration account link. Please tryagain.':
        'Sepertinya Kau Mengklik Pada Link Pendaftaran Rekening Yang Tidak Valid. Silakan Coba Lagi。',
      //Tenant Bill
      'Lease Contract Number': 'Nomor Kontrak Sewa',
      Download: 'Download',
      Print: 'Mencetak',
      'Payment Notice': 'Pengumuman Pembayaran',
      'Bill To': 'Ke',
      'Bill Number': 'Nomor Faktur',
      'Bill Date': 'Tanggal Pernyataan',
      'Payment Due Date': 'Tarikh Tarikh Pembayaran',
      'Amount Payable': 'Jumlah Yang Dapat Dibayar',
      'Billing Period Start': 'Tanggal Awal Periode Penyelesaian',
      'Billing Period End': 'Tanggal Akhir Periode Penyelesaian',
      Quantity: 'Jumlah',
      Price: 'Harga',
      Unit: 'Unit',
      Amount: 'Uang',
      Subtotal: 'Subtotal',
      'VAT Output Tax': 'Pajak Output Pajak Nilai Tambahan',
      'Total Amount Payable': 'Jumlah Yang Harus Dibayar',
      'Please make sure to pay on or before the payment due date above':
        'Silakan Pastikan Pembayaran Dilakukan Pada Atau Sebelum Tanggal Pembayaran Yang Disebutkan Di Atas',
      'Send money to the following account': 'Pengiriman Ke Akun Berikutnya',
      'Account Name': 'Title Of Account',
      'Bank Name': 'Bank Membuka',
      'Bank Address': 'Alamat Bank',
      'RMB Account': 'Akaun RMB',
      // button
      'Toggle Navigation': 'Tukar Navigasi',
      // working calendar
      'Working Days': 'Minggu',
      'Non Working Days': 'Hari Tidak Bekerja',
      'Select Row': 'Silakan Pilih Baris Data Untuk Beroperasi',
      // Offline Meter Input
      'Daily Value': 'Data Sehari',
      Date: 'Tanggal',
      'Successfully Saved': 'Successfully Saved',
      'Previous Data Is Empty': 'Data Sebelumnya Kosong',
      // API
      'API.ACCOUNT_UNLOCK_FAILED': 'Account unlock failed',
      'API.ADMINISTRATOR_SESSION_NOT_FOUND': 'Sesi Administrator Tidak Ada',
      'API.ADMINISTRATOR_SESSION_TIMEOUT': 'Sesi Administrator Telah Tamat Waktu',
      'API.ADVANCED_REPORT_NOT_FOUND': 'Laporan Lanjutan Tidak Ada',
      'API.API_KEY_HAS_EXPIRED': 'Key has expired',
      'API.API_KEY_NAME_IS_ALREADY_IN_USE': 'Key name is already in use',
      'API.API_KEY_NOT_FOUND': 'Key not found',
      'API.BAD_REQUEST': 'Permintaan Galat',
      'API.BALANCING_PRICE_POINT_NOT_FOUND': 'Balancing price point not found',
      'API.BATTERY_STATE_POINT_NOT_FOUND': 'Battery state point not found',
      'API.BUY_METER_NOT_FOUND': 'Buy meter not found',
      'API.CANNOT_SET_EXISTING_SUBMETER_AS_MASTER_METER':
        'Tidak Dapat Menetapkan Tabel Ukuran Yang Sudah Ditetapkan Sebagai Tabel Bawah Sebagai Tabel Total',
      'API.CHARGE_METER_NOT_FOUND': 'Charge meter not found',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_EXISTS': 'Combined equipment command relation exists',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Combined equipment command relation not found',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_EXISTS': 'Perangkat Kombinasi Dan Hubungan Perangkat Sudah Ada',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_NOT_FOUND': 'Perangkat Kombinasi Dan Hubungan Perangkat Tidak Ada',
      'API.COMBINED_EQUIPMENT_METER_RELATION_EXISTS': 'Hubungan Antara Peralatan Kombinasi Dan Meter Sudah Ada',
      'API.COMBINED_EQUIPMENT_METER_RELATION_NOT_FOUND': 'Hubungan Antara Peralatan Kombinasi Dan Meter Tidak Ada',
      'API.COMBINED_EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Nama Perangkat Kombinasi Sudah Digunakan',
      'API.COMBINED_EQUIPMENT_NOT_FOUND': 'Perangkat Kombinasi Tidak Ada',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_EXISTS':
        'Perangkat Kombinasi Dan Hubungan Meja Luar Talian Sudah Ada',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND':
        'Hubungan Antara Perangkat Kombinasi Dan Tabel Offline Tidak Ada',
      'API.COMBINED_EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE':
        'Nama Parameter Yang Berhubungan Dengan Perangkat Kombinasi Sudah Digunakan',
      'API.COMBINED_EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH':
        'Parameter Yang Berhubungan Dengan Perangkat Kombinasi Tidak Ada Atau Tidak Cocok',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Perangkat Kombinasi Dan Hubungan Meja Virtual Sudah Ada',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND':
        'Perangkat Kombinasi Dan Hubungan Meja Virtual Tidak Ada',
      'API.COMMAND_NAME_IS_ALREADY_IN_USE': 'Command name is already in use',
      'API.COMMAND_NOT_FOUND': 'Command not  found',
      'API.CONTACT_NAME_IS_ALREADY_IN_USE': 'Nama Kontak Sudah Digunakan',
      'API.CONTACT_NOT_FOUND': 'Kontak Tidak Ada',
      'API.COOLING_METER_NOT_FOUND': 'Cooling meter not found',
      'API.COST_CENTER_EXTERNAL_ID_EXISTS': 'ID Luar Pusat Biaya Sudah Ada',
      'API.COST_CENTER_NAME_EXISTS': 'Nama Pusat Biaya Sudah Ada',
      'API.COST_CENTER_NOT_FOUND': 'Pusat Biaya Tidak Ada',
      'API.COST_FILE_NOT_FOUND': 'Berkas Biaya Tidak Ada',
      'API.DATA_REPAIR_FILE_NOT_FOUND': 'Data repair file not found',
      'API.DATA_SOURCE_NAME_IS_ALREADY_IN_USE': 'Nama Sumber Data Sudah Digunakan',
      'API.DATA_SOURCE_NOT_FOUND': 'Sumber Data Tidak Ada',
      'API.DATABASE_ERROR': 'Galat Pangkalan Data',
      'API.DATE_IS_ALREADY_IN_WORKING_CALENDAR': 'Tanggal Sudah Ada Dalam Kalender Kerja',
      'API.DATE_LOCAL_NOT_FOUND': 'Tanggal Lokal Tidak Ditemukan',
      'API.DISCHARGE_METER_NOT_FOUND': 'Discharge meter not found',
      'API.DISTRIBUTION_CIRCUIT_NAME_IS_ALREADY_IN_USE': 'Nama Sirkuit Distribusi Sudah Digunakan',
      'API.DISTRIBUTION_CIRCUIT_NOT_FOUND': 'Sirkuit Distribusi Tidak Ada',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_EXISTS': 'Sirkuit Distribusi Dan Hubungan Titik Sudah Ada',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_NOT_FOUND': 'Hubungan Antara Sirkuit Distribusi Dan Titik Tidak Ada',
      'API.DISTRIBUTION_SYSTEM_NAME_IS_ALREADY_IN_USE': 'Nama Sistem Distribusi Sudah Digunakan',
      'API.DISTRIBUTION_SYSTEM_NOT_FOUND': 'Sistem Distribusi Tidak Ada',
      'API.ELECTRICITY_METER_NOT_FOUND': 'Electricity meter not found',
      'API.EMAIL_IS_ALREADY_IN_USE': 'Email Sudah Digunakan',
      'API.EMAIL_MESSAGE_NOT_FOUND': 'Pesan Surel Tidak Ada',
      'API.EMAIL_NOT_FOUND': 'Email Tidak Ada',
      'API.EMAIL_SERVER_HOST_IS_ALREADY_IN_USE': 'Hos Server Surat Sudah Digunakan',
      'API.EMAIL_SERVER_NOT_FOUND': 'Hos Server Surat Tidak Ada',
      'API.EMPTY_VARIABLES_ARRAY': 'Array Variabel Kosong',
      'API.ENERGY_CATEGORY_NAME_IS_ALREADY_IN_USE': 'Nama Klasifikasi Energi Sudah Digunakan',
      'API.ENERGY_CATEGORY_NOT_FOUND': 'Klasifikasi Energi Tidak Ada',
      'API.ENERGY_CATEGORY_USED_IN_ENERGY_ITEMS': 'Klasifikasi Energi Telah Digunakan Dalam Sub Kategori Energi',
      'API.ENERGY_CATEGORY_USED_IN_METER': 'Klasifikasi Energi Telah Digunakan Dalam Tabel Pengukuran',
      'API.ENERGY_CATEGORY_USED_IN_OFFLINE_METER': 'Klasifikasi Energi Yang Digunakan Dalam Tabel Offline',
      'API.ENERGY_CATEGORY_USED_IN_TARIFFS': 'Klasifikasi Energi Telah Digunakan Dalam Tarif',
      'API.ENERGY_CATEGORY_USED_IN_VIRTUAL_METER': 'Klasifikasi Energi Sudah Digunakan Dalam Tabel Virtual',
      'API.ENERGY_FLOW_DIAGRAM_LINK_IS_ALREADY_IN_USE': 'Link Grafik Aliran Energi Telah Digunakan',
      'API.ENERGY_FLOW_DIAGRAM_LINK_NOT_FOUND_OR_NOT_MATCH': 'Link Grafik Aliran Energi Tidak Ada Atau Tidak Cocok',
      'API.ENERGY_FLOW_DIAGRAM_NAME_IS_ALREADY_IN_USE': 'Nama grafik aliran energi sudah digunakan',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NAME_IS_ALREADY_IN_USE': 'Nod grafik aliran energi sudah digunakan',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NOT_FOUND_OR_NOT_MATCH': 'Nod grafik aliran energi tidak ada atau tidak cocok',
      'API.ENERGY_FLOW_DIAGRAM_NOT_FOUND': 'Diagram aliran energi tidak ada',
      'API.ENERGY_ITEM_DOES_NOT_BELONG_TO_ENERGY_CATEGORY': 'Subitem energi tidak termasuk kategori energi',
      'API.ENERGY_ITEM_IS_NOT_BELONG_TO_ENERGY_CATEGORY': 'Subitem energi tidak termasuk kategori energi',
      'API.ENERGY_ITEM_NAME_IS_ALREADY_IN_USE': 'Subitem energi digunakan',
      'API.ENERGY_ITEM_NOT_FOUND': 'Subitem energi tidak ada',
      'API.ENERGY_ITEM_USED_IN_METER': 'Subitem energi telah digunakan dalam tabel pengukuran',
      'API.ENERGY_ITEM_USED_IN_OFFLINE_METER': 'Subitem energi telah digunakan dalam tabel offline',
      'API.ENERGY_ITEM_USED_IN_VIRTUAL_METER': 'Subitem energi sudah digunakan dalam tabel virtual',
      'API.ENERGY_STORAGE_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Energy storage power station name is already in use',
      'API.ENERGY_STORAGE_POWER_STATION_NOT_FOUND': 'Energy storage power station not found',
      'API.EQUIPMENT_COMMAND_RELATION_EXISTS': 'Equipment command relation exists',
      'API.EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Equipment command relation not found',
      'API.EQUIPMENT_METER_RELATION_EXISTS': 'Hubungan antara peralatan dan meter sudah ada',
      'API.EQUIPMENT_METER_RELATION_NOT_FOUND': 'Hubungan antara peralatan dan meter tidak ada',
      'API.EQUIPMENT_NAME_IS_ALREADY_IN_USE': 'Nama perangkat sudah digunakan',
      'API.EQUIPMENT_NOT_FOUND': 'Peranti tidak ada',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': 'Hubungan antara perangkat dan tabel luar talian sudah ada',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': 'Hubungan antara perangkat dan tabel luar talian tidak ada',
      'API.EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': 'Nama parameter terkait perangkat telah digunakan',
      'API.EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': 'Parameter terkait perangkat tidak ada atau tidak cocok',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': 'Hubungan antara perangkat dan meja virtual sudah ada',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Hubungan antara perangkat dan meja virtual tidak ada',
      'API.ERROR': 'Kesalahan',
      'API.EXCEPTION': 'Abnormal',
      'API.FAILED_TO_READ_REQUEST_STREAM': 'Failed to read request stream',
      'API.FAILED_TO_RESTORE_COST_FILE': 'Fail biaya pemulihan gagal',
      'API.FAILED_TO_RESTORE_DATA_REPAIR_FILE': 'Failed to restore data repair file',
      'API.FAILED_TO_RESTORE_KNOWLEDGE_FILE': 'Gagal memulihkan berkas pengetahuan',
      'API.FAILED_TO_RESTORE_OFFLINE_METER_FILE': 'Gagal memulihkan berkas tabel luar talian',
      'API.FAILED_TO_SAVE_OFFLINE_METER_FILE': 'Gagal menyimpan berkas tabel offline',
      'API.FAILED_TO_UPLOAD_ATTACHMENT_FILE': 'Failed to upload attachment file',
      'API.FAILED_TO_UPLOAD_COST_FILE': 'Gagal mengunggah berkas biaya',
      'API.FAILED_TO_UPLOAD_DATA_REPAIR_FILE': 'Failed to upload data repair file',
      'API.FAILED_TO_UPLOAD_KNOWLEDGE_FILE': 'Gagal mengunggah berkas pengetahuan',
      'API.FAILED_TO_UPLOAD_OFFLINE_METER_FILE': 'Gagal mengunggah berkas tabel luar talian',
      'API.GATEWAY_NAME_IS_ALREADY_IN_USE': 'Nama gerbang sudah digunakan',
      'API.GATEWAY_NOT_FOUND': 'Gateway tidak ada',
      'API.HEAT_METER_NOT_FOUND': 'Heat meter not found',
      'API.INVALID_': 'Tidak valid ',
      'API.INVALID_ACKNOWLEDGE_CODE': 'Invalid acknowledge code',
      'API.INVALID_ADDRESS': 'Alamat tidak valid',
      'API.INVALID_ADDRESS_VALUE': 'Nilai alamat tidak valid',
      'API.INVALID_ADVANCED_REPORT_ID': 'ID laporan maju tidak valid',
      'API.INVALID_API_KEY_ID': 'Invalid api key id',
      'API.INVALID_API_KEY_NAME': 'Invalid api key name',
      'API.INVALID_AREA_VALUE': 'Nilai area tidak valid',
      'API.INVALID_BALANCING_PRICE_POINT_ID': 'Invalid balancing price point ID',
      'API.INVALID_BASE_PERIOD_END_DATETIME': 'Waktu akhir periode dasar tidak valid',
      'API.INVALID_BASE_PERIOD_START_DATETIME': 'Waktu awal periode dasar tidak valid',
      'API.INVALID_BATTERY_STATE_POINT_ID': 'Invalid battery state point ID',
      'API.INVALID_BAUD_RATE': 'Rata Baud tidak valid',
      'API.INVALID_BUILDINGS_VALUE': 'Nilai bangunan tidak valid',
      'API.INVALID_BUY_METER_ID': 'Invalid buy meter id',
      'API.INVALID_CAPACITY': 'Invalid capacity',
      'API.INVALID_CATEGORY': 'Jenis tidak valid',
      'API.INVALID_CHANNEL': 'Saluran tidak valid',
      'API.INVALID_CHARGE_METER_ID': 'Invalid charge meter id',
      'API.INVALID_COMBINED_EQUIPMENT_ID': 'ID perangkat kombinasi tidak valid',
      'API.INVALID_COMBINED_EQUIPMENT_NAME': 'Nama perangkat kombinasi tidak valid',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_ID': 'ID parameter berkaitan dengan perangkat kombinasi tidak valid',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_NAME':
        'Nama parameter berkaitan dengan perangkat kombinasi tidak valid',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_TYPE':
        'Tipe parameter berkaitan dengan perangkat kombinasi tidak valid',
      'API.INVALID_COMBINED_EQUIPMENT_UUID': 'UUID perangkat kombinasi tidak valid',
      'API.INVALID_COMMAND_ID': 'Invalid command id',
      'API.INVALID_COMMAND_NAME': 'Invalid command name',
      'API.INVALID_CONNECTION': 'Koneksi tidak valid',
      'API.INVALID_CONSTANT_VALUE': 'Nilai konstan tidak valid',
      'API.INVALID_CONTACT_ID': 'ID kontak tidak valid',
      'API.INVALID_CONTACT_NAME': 'Nama kontak tidak valid',
      'API.INVALID_COOLING_METER_ID': 'Invalid cooling meter id',
      'API.INVALID_COST_CENTER_ID': 'ID pusat biaya tidak valid',
      'API.INVALID_COST_FILE_ID': 'ID berkas biaya tidak valid',
      'API.INVALID_CREATED_DATETIME': 'Invalid created datetime',
      'API.INVALID_DATA_REPAIR_FILE_ID': 'Invalid data repair file id',
      'API.INVALID_DATA_SOURCE_ID': 'ID sumber data tidak valid',
      'API.INVALID_DATA_SOURCE_NAME': 'Nama sumber data tidak valid',
      'API.INVALID_DATA_SOURCE_PROTOCOL': 'Protokol sumber data tidak valid',
      'API.INVALID_DATE_LOCAL': 'Tarikh lokal tidak valid',
      'API.INVALID_DENOMINATOR_METER_UUID': 'UUID ukuran standar tidak valid',
      'API.INVALID_DISCHARGE_METER_ID': 'Invalid discharge meter id',
      'API.INVALID_DISPLAY_NAME': 'Nama paparan tidak valid',
      'API.INVALID_DISTRIBUTION_CIRCUIT_ID': 'ID sirkuit distribusi tidak valid',
      'API.INVALID_DISTRIBUTION_CIRCUIT_NAME': 'Nama sirkuit distribusi tidak valid',
      'API.INVALID_DISTRIBUTION_ROOM': 'Ruangan distribusi tidak valid',
      'API.INVALID_DISTRIBUTION_SYSTEM_ID': 'ID sistem distribusi tidak valid',
      'API.INVALID_DISTRIBUTION_SYSTEM_NAME': 'Nama sistem distribusi tidak valid',
      'API.INVALID_ELECTRICITY_METER_ID': 'Invalid Electricity meter id',
      'API.INVALID_EMAIL': 'Kotak surat tidak valid',
      'API.INVALID_EMAIL_MESSAGE_ID': 'ID email tidak valid',
      'API.INVALID_EMAIL_SERVER_HOST': 'Hos server email tidak valid',
      'API.INVALID_EMAIL_SERVER_ID': 'ID server email tidak valid',
      'API.INVALID_END_DATE_FORMAT': 'Format tanggal akhir tidak valid',
      'API.INVALID_END_DATETIME': 'Tarikh akhir tidak valid',
      'API.INVALID_ENERGY_CATEGORY_ID': 'ID klasifikasi energi tidak valid',
      'API.INVALID_ENERGY_CATEGORY_NAME': 'Nama klasifikasi energi tidak valid',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_ID': 'ID Graf Aliran Energi tidak valid',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_LINK_ID': 'ID Link Graf Aliran Energi tidak valid',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NAME': 'Nama diagram aliran energi tidak valid',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_ID': 'ID Node Graf Aliran Energi tidak valid',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_NAME': 'Nama Node Graf Aliran Energi tidak valid',
      'API.INVALID_ENERGY_ITEM_ID': 'ID sub item energi tidak valid',
      'API.INVALID_ENERGY_ITEM_NAME': 'Nama sub item energi tidak valid',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_ID': 'Invalid energy storage power station ID',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_NAME': 'Invalid energy storage power station name',
      'API.INVALID_EQUATION_IN_EXPRESSION': 'Ekspresi tidak valid dalam ekspresi',
      'API.INVALID_EQUIPMENT_ID': 'ID perangkat tidak valid',
      'API.INVALID_EQUIPMENT_NAME': 'Nama perangkat tidak valid',
      'API.INVALID_EQUIPMENT_PARAMETER_ID': 'ID parameter berkaitan perangkat tidak valid',
      'API.INVALID_EQUIPMENT_PARAMETER_NAME': 'Nama parameter terkait perangkat tidak valid',
      'API.INVALID_EQUIPMENT_PARAMETER_TYPE': 'Tipe parameter terkait perangkat tidak valid',
      'API.INVALID_EQUIPMENT_UUID': 'UUID perangkat tidak valid',
      'API.INVALID_EXPIRES_DATETIME': 'Invalid expires datetime',
      'API.INVALID_EXPRESSION': 'Ekspresi tidak valid',
      'API.INVALID_EXPRESSION_OBJECT': 'Objek ekspresi tidak valid',
      'API.INVALID_FDD_CODE': 'Kode kesalahan tidak valid',
      'API.INVALID_FLOORS_VALUE': 'Nilai lantai tidak valid',
      'API.INVALID_FROM_ADDR': 'Alamat tidak valid',
      'API.INVALID_GATEWAY_ID': 'ID gateway tidak valid',
      'API.INVALID_GATEWAY_NAME': 'Nama gerbang tidak valid',
      'API.INVALID_HEAT_METER_ID': 'Invalid heat meter id',
      'API.INVALID_HIGH_LIMIT_VALUE': 'Invalid high limit value',
      'API.INVALID_HOURLY_HIGH_LIMIT_VALUE': 'Nilai batas jam tidak valid',
      'API.INVALID_HOURLY_LOW_LIMIT_VALUE': 'Nilai batas jam bawah tidak valid',
      'API.INVALID_Id': 'Invalid id',
      'API.INVALID_IS_ADMIN_VALUE': 'Nilai manajemen tidak valid',
      'API.INVALID_IS_COST_DATA_DISPLAYED': 'Invalid is cost data displayed',
      'API.INVALID_IS_COUNTED_VALUE': 'Nilai hitungan tidak valid',
      'API.INVALID_IS_ENABLED': 'Tidak valid diaktifkan',
      'API.INVALID_IS_HIDDEN': 'Apakah menyembunyikan yang tidak valid',
      'API.INVALID_IS_IN_LEASE_VALUE': 'Apakah nilai sewa tidak valid',
      'API.INVALID_IS_INPUT_COUNTED_VALUE': 'Apakah input tidak valid agregat',
      'API.INVALID_IS_KEY_TENANT_VALUE': 'Nilai penyewa utama tidak valid',
      'API.INVALID_IS_OUTPUT_COUNTED_VALUE': 'Apakah output tidak valid agregat',
      'API.INVALID_IS_OUTPUT_VALUE': 'Apakah output tidak valid agregat',
      'API.INVALID_IS_READ_ONLY_VALUE': 'Invalid is read only value',
      'API.INVALID_IS_TREND_VALUE': 'Apakah menyimpan nilai trend yang tidak valid',
      'API.INVALID_IS_VIRTUAL_VALUE': 'Nilai titik virtual tidak valid',
      'API.INVALID_KGCE': 'Koefisien batubara standar kilogram tidak valid',
      'API.INVALID_KGCO2E': 'Koefisien emisi dioksida karbon kilogram tidak valid',
      'API.INVALID_KNOWLEDGE_FILE_ID': 'ID berkas pengetahuan tidak valid',
      'API.INVALID_LATITUDE_VALUE': 'Nilai latitud tidak valid',
      'API.INVALID_LEASE_NUMBER_VALUE': 'Nomor sewa tidak valid',
      'API.INVALID_LONGITUDE_VALUE': 'Nilai longitud tidak valid',
      'API.INVALID_LOW_LIMIT_VALUE': 'Nilai batas bawah tidak valid',
      'API.INVALID_MASTER_METER_ID': 'ID meter total tidak valid',
      'API.INVALID_MENU_ID': 'ID menu tidak valid',
      'API.INVALID_MESSAGE_DATA': 'Invalid message data',
      'API.INVALID_MESSAGE_TEMPLATE': 'Templat pesan tidak valid',
      'API.INVALID_MESSAGE_TEMPLATE_ID': 'Invalid message template id',
      'API.INVALID_MESSAGE_VALUE': 'Invalid message value',
      'API.INVALID_METER_ID': 'ID meter tidak valid',
      'API.INVALID_METER_NAME': 'Nama meter tidak valid',
      'API.INVALID_METER_UUID': 'UUID ukuran tidak valid',
      'API.INVALID_MICROGRID_BATTERY_ID': 'Invalid microgrid battery id',
      'API.INVALID_MICROGRID_BATTERY_NAME': 'Invalid microgrid battery name',
      'API.INVALID_MICROGRID_EVCHARGER_ID': 'Invalid microgrid evcharger id',
      'API.INVALID_MICROGRID_EVCHARGER_NAME': 'Invalid microgrid evcharger name',
      'API.INVALID_MICROGRID_GENERATOR_ID': 'Invalid microgrid generator id',
      'API.INVALID_MICROGRID_GENERATOR_NAME': 'Invalid microgrid generator name',
      'API.INVALID_MICROGRID_GRID_ID': 'Invalid microgrid grid id',
      'API.INVALID_MICROGRID_GRID_NAME': 'Invalid microgrid grid name',
      'API.INVALID_MICROGRID_HEATPUMP_ID': 'Invalid microgrid heatpump id',
      'API.INVALID_MICROGRID_HEATPUMP_NAME': 'Invalid microgrid heatpump name',
      'API.INVALID_MICROGRID_ID': 'Invalid microgrid id',
      'API.INVALID_MICROGRID_LOAD_ID': 'Invalid microgrid load id',
      'API.INVALID_MICROGRID_LOAD_NAME': 'Invalid microgrid load name',
      'API.INVALID_MICROGRID_NAME': 'Invalid microgrid name',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_ID': 'Invalid microgrid photovoltaic id',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_NAME': 'Invalid microgrid photovoltaic name',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_ID': 'Invalid microgrid power conversion system ID',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_NAME': 'Invalid microgrid microgrid power conversion system name',
      'API.INVALID_MICROGRID_UUID': 'Invalid microgrid UUID',
      'API.INVALID_NAME_VALUE': 'Nilai nama tidak valid',
      'API.INVALID_NEW_PASSWORD': 'Kata sandi baru tidak valid',
      'API.INVALID_NON_WORKING_DAY_ID': 'ID hari tidak bekerja tidak valid',
      'API.INVALID_NOTIFICATION_ID': 'ID pemberitahuan tidak valid',
      'API.INVALID_NUMERATOR_METER_UUID': 'UUID tabel numerator tidak valid',
      'API.INVALID_OBJECT_TYPE': 'Jenis objek tidak valid',
      'API.INVALID_OFFLINE_METER_DATA': 'Data tabel offline tidak valid',
      'API.INVALID_OFFLINE_METER_FILE_ID': 'ID berkas tabel luar talian tidak valid',
      'API.INVALID_OFFLINE_METER_ID': 'ID tabel luar talian tidak valid',
      'API.INVALID_OFFLINE_METER_NAME': 'Nama tabel luar talian tidak valid',
      'API.INVALID_OFFLINE_METER_UUID': 'UUID tabel luar talian tidak valid',
      'API.INVALID_OFFSET_CONSTANT_VALUE': 'Invalid offset constant value',
      'API.INVALID_OLD_PASSWORD': 'Kata sandi lama tidak valid',
      'API.INVALID_OPENID': 'Invalid openid',
      'API.INVALID_PARENT_SPACE_ID': 'ID ruang induk tidak valid',
      'API.INVALID_PASSWORD': 'Kata sandi tidak valid',
      'API.INVALID_PAYLOAD': 'Invalid payload',
      'API.INVALID_PEAK_CURRENT': 'Maksimum saat ini tidak valid',
      'API.INVALID_PEAK_LOAD': 'Muatan puncak tidak valid',
      'API.INVALID_PERIOD_TYPE': 'Tipe siklus tidak valid',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_ID': 'Invalid photovoltaic power station ID',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_NAME': 'Invalid photovoltaic power station name',
      'API.INVALID_POINT_ID': 'ID titik data tidak valid',
      'API.INVALID_POINT_NAME': 'Nama titik data tidak valid',
      'API.INVALID_PORT': 'Port tidak valid',
      'API.INVALID_POSTAL_CODE_VALUE': 'Invalid postal code value',
      'API.INVALID_POWER_POINT_ID': 'Invalid power point id',
      'API.INVALID_PRIORITY': 'Prioritas tidak valid',
      'API.INVALID_PRIVILEGE': 'Keizinan tidak valid',
      'API.INVALID_PRIVILEGE_DATA': 'Data izin tidak valid',
      'API.INVALID_PRIVILEGE_ID': 'ID ijin tidak valid',
      'API.INVALID_PRIVILEGE_NAME': 'Nama ijin tidak valid',
      'API.INVALID_RATIO_VALUE': 'Nisbah variabel tidak valid',
      'API.INVALID_RECIPIENT_EMAIL': 'Invalid recipient email',
      'API.INVALID_RECIPIENT_MOBILE': 'Invalid recipient mobile',
      'API.INVALID_RECIPIENT_NAME': 'Invalid recipient name',
      'API.INVALID_RECIPIENT_OPENID': 'Invalid recipient openid',
      'API.INVALID_REPLY': 'Jawaban tidak valid',
      'API.INVALID_REPORTING_PERIOD_END_DATETIME': 'Waktu tanggal akhir periode laporan tidak valid',
      'API.INVALID_REPORTING_PERIOD_START_DATETIME': 'Periode laporan tidak valid waktu tanggal awal',
      'API.INVALID_REQUIRES_AUTHENTICATION': 'Tidak valid membutuhkan autentikasi',
      'API.INVALID_ROOMS_VALUE': 'Nilai ruang tidak valid',
      'API.INVALID_RULE_ID': 'ID aturan tidak valid',
      'API.INVALID_RULE_NAME': 'Nama aturan tidak valid',
      'API.INVALID_RUN_STATE_POINT_ID': 'Invalid run state point ID',
      'API.INVALID_SCHEDULED_DATETIME': 'Invalid scheduled datetime',
      'API.INVALID_SELL_METER_ID': 'Invalid sell meter id',
      'API.INVALID_SENSOR_ID': 'ID sensor tidak valid',
      'API.INVALID_SENSOR_NAME': 'Nama sensor tidak valid',
      'API.INVALID_SENSOR_UUID': 'Invalid sensor uuid',
      'API.INVALID_SERIAL_PORT': 'Nomor port seri tidak valid',
      'API.INVALID_SESSION_PLEASE_RE_LOGIN': 'Sesi tidak valid, silakan log masuk lagi',
      'API.INVALID_SET_VALUE': 'Invalid set value',
      'API.INVALID_SHOPFLOOR_ID': 'ID workshop tidak valid',
      'API.INVALID_SHOPFLOOR_NAME': 'Nama workshop tidak valid',
      'API.INVALID_SHOPFLOOR_UUID': 'UUID workshop tidak valid',
      'API.INVALID_SOURCE_NODE_ID': 'ID node sumber tidak valid',
      'API.INVALID_SPACE_ID': 'ID ruang tidak valid',
      'API.INVALID_SPACE_NAME': 'Nama ruang tidak valid',
      'API.INVALID_SPACE_UUID': 'UUID ruang tidak valid',
      'API.INVALID_START_DATE_FORMAT': 'Format waktu awal tidak valid',
      'API.INVALID_START_DATETIME': 'Waktu awal tidak valid',
      'API.INVALID_STATUS': 'Keadaan tidak valid',
      'API.INVALID_STORE_ID': 'ID toko tidak valid',
      'API.INVALID_STORE_NAME': 'Nama toko tidak valid',
      'API.INVALID_STORE_TYPE_DESCRIPTION': 'Deskripsi tipe storan tidak valid',
      'API.INVALID_STORE_TYPE_ID': 'ID tipe toko tidak valid',
      'API.INVALID_STORE_TYPE_NAME': 'Nama tipe toko tidak valid',
      'API.INVALID_STORE_TYPE_SIMPLIFIED_CODE': 'Kode penyimplifikasi tipe storan tidak valid',
      'API.INVALID_STORE_UUID': 'UUID toko tidak valid',
      'API.INVALID_SERIAL_NUMBER': 'Invalid serial number',
      'API.INVALID_SUBJECT_VALUE': 'Invalid subject value',
      'API.INVALID_SVG': 'SVG tidak valid',
      'API.INVALID_SWITCHGEAR': 'Switchgear tidak valid',
      'API.INVALID_TARGET_NODE_ID': 'ID node target tidak valid',
      'API.INVALID_TARIFF_BLOCK_PRICING': 'Penghargaan tingkat tidak valid',
      'API.INVALID_TARIFF_ID': 'ID kadar tidak valid',
      'API.INVALID_TARIFF_TIME_OF_USE_PRICING': 'Penghargaan tingkat waktu-penggunaan tidak valid',
      'API.INVALID_TARIFF_TYPE': 'Jenis kadar tidak valid',
      'API.INVALID_TENANT_ID': 'ID penyewa tidak valid',
      'API.INVALID_TENANT_NAME': 'Nama penyewa tidak valid',
      'API.INVALID_TENANT_TYPE_DESCRIPTION': 'Deskripsi tipe penyewa tidak valid',
      'API.INVALID_TENANT_TYPE_ID': 'ID tipe penyewa tidak valid',
      'API.INVALID_TENANT_TYPE_NAME': 'Nama tipe penyewa tidak valid',
      'API.INVALID_TENANT_TYPE_SIMPLIFIED_CODE': 'Kode penyimplifikasi tipe penyewa tidak valid',
      'API.INVALID_TENANT_UUID': 'UUID penyewa tidak valid',
      'API.INVALID_TEXT_MESSAGE_ID': 'ID SMS tidak valid',
      'API.INVALID_TIMEZONE_ID': 'ID zona waktu tidak valid',
      'API.INVALID_TOKEN': 'Token tidak valid',
      'API.INVALID_TOPIC': 'Invalid topic',
      'API.INVALID_UNIT_OF_MEASURE': 'Unit ukuran tidak valid',
      'API.INVALID_UNIT_OF_PRICE': 'Unit harga tidak valid',
      'API.INVALID_UNITS': 'Unit tidak valid',
      'API.INVALID_USER_ID': 'ID pengguna tidak valid',
      'API.INVALID_USER_NAME': 'Nama pengguna tidak valid',
      'API.INVALID_USER_NAME_OR_EMAIL': 'Nama pengguna atau email tidak valid',
      'API.INVALID_USER_PHONE': 'Nomor telepon pengguna tidak valid',
      'API.INVALID_USER_PLEASE_RE_LOGIN': 'Pengguna tidak valid, silakan log masuk lagi',
      'API.INVALID_USER_UUID': 'UUID pengguna tidak valid',
      'API.INVALID_USERNAME': 'Invalid userName',
      'API.INVALID_VARIABLE_METER_ID': 'ID meter variabel tidak valid',
      'API.INVALID_VARIABLE_METER_TYPE': 'Tipe meter variabel tidak valid',
      'API.INVALID_VARIABLE_NAME': 'Nama variabel tidak valid',
      'API.INVALID_VERIFICATION_CODE': 'Kode verifikasi tidak valid',
      'API.INVALID_VIRTUAL_METER_ID': 'ID tabel virtual tidak valid',
      'API.INVALID_VIRTUAL_METER_NAME': 'Nama tabel virtual tidak valid',
      'API.INVALID_VIRTUAL_METER_UUID': 'UUID tabel virtual tidak valid',
      'API.INVALID_WEB_MESSAGE_ID': 'ID pesan web tidak valid',
      'API.INVALID_WECHAT_MESSAGE_ID': 'ID pesan WeChat tidak valid',
      'API.INVALID_WIND_FARM_ID': 'Invalid wind farm ID',
      'API.INVALID_WIND_FARM_NAME': 'Invalid wind farm name',
      'API.INVALID_WORKING_CALENDAR_ID': 'ID kalender kerja tidak valid',
      'API.INVALID_WORKING_CALENDAR_NAME': 'Nama kalender kerja tidak valid',
      'API.KNOWLEDGE_FILE_CANNOT_BE_REMOVED_FROM_DISK': 'Tidak dapat menghapus berkas pengetahuan dari disk',
      'API.KNOWLEDGE_FILE_NOT_FOUND': 'Berkas dasar pengetahuan tidak ada',
      'API.MASTER_METER_DOES_NOT_BELONG_TO_SAME_ENERGY_CATEGORY':
        'Tabel ringkasan tidak termasuk dalam kategori energi yang sama',
      'API.MASTER_METER_NOT_FOUND': 'Tabel ringkasan tidak ada',
      'API.MENU_NOT_FOUND': 'Menu tidak ada',
      'API.METER_CANNOT_HAVE_MORE_THAN_ONE_ENERGY_VALUE_POINTS': 'Meter cannot have more than one energy value points',
      'API.METER_COMMAND_RELATION_EXISTS': 'Meter command relation exists',
      'API.METER_COMMAND_RELATION_NOT_FOUND': 'Meter command relation not found',
      'API.METER_NAME_IS_ALREADY_IN_USE': 'Nama meter sudah digunakan',
      'API.METER_NOT_FOUND': 'Meter tidak ada',
      'API.METER_OF_VARIABLE_NOT_FOUND': 'Variabel meter tidak ada',
      'API.METER_POINT_RELATION_EXISTS': 'Hubungan antara meter dan titik data sudah ada',
      'API.METER_POINT_RELATION_NOT_FOUND': 'Hubungan antara meter dan titik data tidak ada',
      'API.MICROGRID_BATTERY_NAME_IS_ALREADY_IN_USE': 'Microgrid battery name is already in use',
      'API.MICROGRID_BATTERY_NOT_FOUND': 'Microgrid battery not found',
      'API.MICROGRID_EVCHARGER_NAME_IS_ALREADY_IN_USE': 'Microgrid evcharger name is already in use',
      'API.MICROGRID_EVCHARGER_NOT_FOUND': 'Microgrid evcharger not fund',
      'API.MICROGRID_GENERATOR_NAME_IS_ALREADY_IN_USE': 'Microgrid generator name is already in use',
      'API.MICROGRID_GENERATOR_NOT_FOUND': 'Microgrid generator not found',
      'API.MICROGRID_GRID_NAME_IS_ALREADY_IN_USE': 'Microgrid grid name is already in use',
      'API.MICROGRID_GRID_NOT_FOUND': 'Microgrid grid not found',
      'API.MICROGRID_HEATPUMP_NAME_IS_ALREADY_IN_USE': 'Microgrid heatpump name is already in use',
      'API.MICROGRID_HEATPUMP_NOT_FOUND': 'Microgrid heatpump not found',
      'API.MICROGRID_LOAD_NAME_IS_ALREADY_IN_USE': 'Microgrid load name is already',
      'API.MICROGRID_LOAD_NOT_FOUND': 'Microgrid load not found',
      'API.MICROGRID_NAME_IS_ALREADY_IN_USE': 'Microgrid name is already in use',
      'API.MICROGRID_NOT_FOUND': 'Microgrid not found',
      'API.MICROGRID_PHOTOVOLTAIC_NAME_IS_ALREADY_IN_USE': 'Microgrid photovoltaic name is already in use',
      'API.MICROGRID_PHOTOVOLTAIC_NOT_FOUND': 'Microgrid photovoltaic not found',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NAME_IS_ALREADY_IN_USE':
        'Microgrid power conversion system name is already in use',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NOT_FOUND': 'Microgrid power conversion system not found',
      'API.MICROGRID_SENSOR_RELATION_EXISTS': 'Microgrid sensor relation exists',
      'API.MICROGRID_SENSOR_RELATION_NOT_FOUND': 'Microgrid sensor not found',
      'API.MQTT_CONNECTION_ERROR': 'Mqtt connection error',
      'API.MQTT_PUBLISH_ERROR': 'Mqtt publish error',
      'API.NEW_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS':
        'Kata sandi baru tidak dapat melebihi 100 karakter dalam panjang',
      'API.NEW_USER_SESSION_NOT_FOUND': 'New user session not found',
      'API.NEW_USER_SESSION_TIMEOUT': 'New user session timeout',
      'API.NON_WORKING_DAY_NOT_FOUND': 'Hari tidak bekerja tidak ada',
      'API.NOT_FOUND': 'Tidak ada',
      'API.NOTIFICATION_NOT_FOUND': 'Notifikasi tidak ada',
      'API.OFFLINE_METER_FILE_NOT_FOUND': 'Berkas tabel luar talian tidak ada',
      'API.OFFLINE_METER_NAME_IS_ALREADY_IN_USE': 'Nama tabel luar talian sudah digunakan',
      'API.OFFLINE_METER_NOT_FOUND': 'Jadual luar talian tidak ada',
      'API.OFFLINE_METER_OF_VARIABLE_NOT_FOUND': 'Variabel tabel luar talian tidak ada',
      'API.OLD_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS':
        'Kata sandi lama tidak dapat melebihi 100 karakter dalam panjang',
      'API.PARENT_SPACE_NOT_FOUND': 'Ruang orang tua tidak ada',
      'API.PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': 'Panjang sandi tidak dapat melebihi 100 karakter',
      'API.PASSWORDS_MATCH': 'Kata sandi baru cocok dengan kata sandi lama',
      'API.PHOTOVOLTAIC_POWER_STATION_NAME_IS_ALREADY_IN_USE': 'Photovoltaic power station name is already in use',
      'API.PHOTOVOLTAIC_POWER_STATION_NOT_FOUND': 'Photovoltaic power station not found',
      'API.POINT_NAME_IS_ALREADY_IN_USE': 'Nama titik data sudah digunakan',
      'API.POINT_NOT_FOUND': 'Titik data tidak ada',
      'API.POWER_POINT_NOT_FOUND': 'Power point not found',
      'API.PRIVILEGE_NAME_IS_ALREADY_IN_USE': 'Nama izin sudah digunakan',
      'API.PRIVILEGE_NOT_FOUND': 'Keizinan tidak ada',
      'API.RULE_NAME_IS_ALREADY_IN_USE': 'Nama aturan sudah digunakan',
      'API.RULE_NOT_FOUND': 'Aturan tidak ada',
      'API.SELL_METER_NOT_FOUND': 'Sell meter not found',
      'API.SENSOR_NAME_IS_ALREADY_IN_USE': 'Nama sensor sudah digunakan',
      'API.SENSOR_NOT_FOUND': 'Sensor tidak hadir',
      'API.SENSOR_POINT_RELATION_EXISTS': 'Hubungan antara sensor dan titik data sudah ada',
      'API.SENSOR_POINT_RELATION_NOT_FOUND': 'Hubungan antara sensor dan titik data tidak ada',
      'API.SHOPFLOOR_COMMAND_RELATION_EXISTS': 'Shopfloor command relation exists',
      'API.SHOPFLOOR_COMMAND_RELATION_NOT_FOUND': 'Shopfloor command relation not found',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_EXISTS': 'Hubungan antara workshop dan peralatan sudah ada',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_NOT_FOUND': 'Hubungan antara workshop dan peralatan tidak ada',
      'API.SHOPFLOOR_METER_RELATION_EXISTS': 'Hubungan antara workshop dan meter sudah ada',
      'API.SHOPFLOOR_METER_RELATION_NOT_FOUND': 'Hubungan antara workshop dan meter tidak ada',
      'API.SHOPFLOOR_NAME_IS_ALREADY_IN_USE': 'Nama workshop sudah digunakan',
      'API.SHOPFLOOR_NOT_FOUND': 'Workshop tidak ada',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_EXISTS': 'Hubungan antara workshop dan meja offline sudah ada',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_NOT_FOUND': 'Hubungan antara workshop dan meja offline tidak ada',
      'API.SHOPFLOOR_POINT_RELATION_EXISTS': 'Hubungan antara workshop dan titik data sudah ada',
      'API.SHOPFLOOR_POINT_RELATION_NOT_FOUND': 'Hubungan antara workshop dan titik data tidak ada',
      'API.SHOPFLOOR_SENSOR_RELATION_EXISTS': 'Hubungan antara workshop dan sensor sudah ada',
      'API.SHOPFLOOR_SENSOR_RELATION_NOT_FOUND': 'Hubungan antara workshop dan sensor tidak ada',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_EXISTS': 'Hubungan antara workshop dan meja virtual sudah ada',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_NOT_FOUND': 'Hubungan antara workshop dan meja virtual tidak ada',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_EXISTS': 'Hubungan kalender pekerjaan workshop sudah ada',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Hubungan antara workshop dan kalender kerja tidak ada',
      'API.SOC_POINT_NOT_FOUND': 'SOC point not found',
      'API.SOURCE_NODE_NOT_FOUND': 'Nod sumber tidak ada',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_EXISTS': 'Hubungan antara ruang angkasa dan perangkat kombinasi sudah ada',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_NOT_FOUND':
        'Hubungan antara ruang angkasa dan perangkat kombinasi tidak ada',
      'API.SPACE_COMMAND_RELATION_EXISTS': 'Space command relation exists',
      'API.SPACE_COMMAND_RELATION_NOT_FOUND': 'Space command relation not found',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_EXISTS': 'Space Energy Storage Power Station Relation Exists',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_NOT_FOUND': 'Space Energy Storage Power Station Relation Not Found',
      'API.SPACE_EQUIPMENT_RELATION_EXISTS': 'Hubungan antara ruang dan perangkat sudah ada',
      'API.SPACE_EQUIPMENT_RELATION_NOT_FOUND': 'Hubungan antara ruang dan peralatan tidak ada',
      'API.SPACE_METER_RELATION_EXISTS': 'Hubungan antara ruang dan meter sudah ada',
      'API.SPACE_METER_RELATION_NOT_FOUND': 'Hubungan antara ruang dan meter tidak ada',
      'API.SPACE_NAME_IS_ALREADY_IN_USE': 'Nama ruang sudah digunakan',
      'API.SPACE_NOT_FOUND': 'Ruang tidak ada',
      'API.SPACE_NOT_FOUND_IN_PRIVILEGE': 'Ruang tidak ada dalam keizinan',
      'API.SPACE_OFFLINE_METER_RELATION_EXISTS': 'Hubungan antara ruang dan tabel luar talian sudah ada',
      'API.SPACE_OFFLINE_METER_RELATION_NOT_FOUND': 'Hubungan antara ruang dan tabel luar talian tidak ada',
      'API.SPACE_POINT_RELATION_EXISTS': 'Hubungan ruang dan titik data sudah ada',
      'API.SPACE_POINT_RELATION_NOT_FOUND': 'Hubungan antara ruang dan titik data tidak ada',
      'API.SPACE_SENSOR_RELATION_EXISTS': 'Hubungan antara ruang angkasa dan sensor sudah ada',
      'API.SPACE_SENSOR_RELATION_NOT_FOUND': 'Hubungan antara ruang angkasa dan sensor tidak ada',
      'API.SPACE_SHOPFLOOR_RELATION_EXISTS': 'Hubungan antara ruang angkasa dan workshop sudah ada',
      'API.SPACE_SHOPFLOOR_RELATION_NOT_FOUND': 'Hubungan antara ruang angkasa dan workshop tidak ada',
      'API.SPACE_STORE_RELATION_EXISTS': 'Hubungan antara ruang dan toko sudah ada',
      'API.SPACE_STORE_RELATION_NOT_FOUND': 'Hubungan antara ruang dan toko tidak ada',
      'API.SPACE_TENANT_RELATION_EXISTS': 'Hubungan antara ruang angkasa dan penyewa sudah ada',
      'API.SPACE_TENANT_RELATION_NOT_FOUND': 'Hubungan antara ruang angkasa dan penyewa tidak ada',
      'API.SPACE_VIRTUAL_METER_RELATION_EXISTS': 'Hubungan antara ruang dan meja virtual sudah ada',
      'API.SPACE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Hubungan antara ruang dan meja virtual tidak ada',
      'API.SPACE_WORKING_CALENDAR_RELATION_EXISTS': 'Hubungan antara ruang dan kalender kerja sudah ada',
      'API.SPACE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Hubungan antara ruang dan kalender kerja tidak ada',
      'API.START_DATETIME_MUST_BE_EARLIER_THAN_END_DATETIME':
        'Waktu tanggal awal harus lebih awal dari waktunya tanggal akhir',
      'API.STORE_COMMAND_RELATION_EXISTS': 'Store command relation exists',
      'API.STORE_COMMAND_RELATION_NOT_FOUND': 'Store command relation not found',
      'API.STORE_METER_RELATION_EXISTS': 'Hubungan antara toko dan meter sudah ada',
      'API.STORE_METER_RELATION_NOT_FOUND': 'Hubungan antara toko dan meter tidak ada',
      'API.STORE_NAME_IS_ALREADY_IN_USE': 'Simpan nama yang sudah digunakan',
      'API.STORE_NOT_FOUND': 'Simpan tidak ada',
      'API.STORE_OFFLINE_METER_RELATION_EXISTS': 'Hubungan antara toko dan meja offline sudah ada',
      'API.STORE_OFFLINE_METER_RELATION_NOT_FOUND': 'Hubungan antara toko dan meja offline tidak ada',
      'API.STORE_POINT_RELATION_EXISTS': 'Hubungan antara toko dan titik data sudah ada',
      'API.STORE_POINT_RELATION_NOT_FOUND': 'Hubungan antara toko dan titik data sudah ada',
      'API.STORE_SENSOR_RELATION_EXISTS': 'Hubungan antara toko dan sensor sudah ada',
      'API.STORE_SENSOR_RELATION_NOT_FOUND': 'Hubungan sensor toko tidak ada',
      'API.STORE_TYPE_NAME_IS_ALREADY_IN_USE': 'Simpan nama tipe yang sudah digunakan',
      'API.STORE_TYPE_NOT_FOUND': 'Tipe penyimpanan tidak ada',
      'API.STORE_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Simpan kode penyimplifikasi tipe yang digunakan',
      'API.STORE_TYPE_USED_IN_STORE': 'Tipe penyimpanan yang sudah digunakan dalam penyimpanan',
      'API.STORE_VIRTUAL_METER_RELATION_EXISTS': 'Hubungan antara toko dan meja virtual sudah ada',
      'API.STORE_VIRTUAL_METER_RELATION_NOT_FOUND': 'Hubungan antara toko dan meja virtual tidak ada',
      'API.STORE_WORKING_CALENDAR_RELATION_EXISTS': 'Hubungan antara toko dan kalender kerja sudah ada',
      'API.STORE_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Hubungan antara toko dan kalender kerja tidak ada',
      'API.TARGET_NODE_NOT_FOUND': 'Nod sasaran tidak ada',
      'API.TARIFF_IN_USE': 'Kadar dalam penggunaan',
      'API.TARIFF_IS_ALREADY_ASSOCIATED_WITH_COST_CENTER': 'Rate dan pusat biaya sudah terkait',
      'API.TARIFF_IS_NOT_ASSOCIATED_WITH_COST_CENTER': 'Rate dan pusat biaya tidak terkait',
      'API.TARIFF_NAME_IS_ALREADY_IN_USE': 'Nama kadar sudah digunakan',
      'API.TARIFF_NOT_FOUND': 'Kadar tidak ada',
      'API.TENANT_COMMAND_RELATION_EXISTS': 'Tenant command relation exists',
      'API.TENANT_COMMAND_RELATION_NOT_FOUND': 'Tenant command relation  not found',
      'API.TENANT_METER_RELATION_EXISTS': 'Hubungan Tenant dan Meter sudah ada',
      'API.TENANT_METER_RELATION_NOT_FOUND': 'Hubungan antara penyewa dan meter tidak ada',
      'API.TENANT_NAME_IS_ALREADY_IN_USE': 'Nama yang sudah digunakan',
      'API.TENANT_NOT_FOUND': 'Tenant tidak ada',
      'API.TENANT_OFFLINE_METER_RELATION_EXISTS': 'Hubungan meja Tenant dan offline sudah ada',
      'API.TENANT_OFFLINE_METER_RELATION_NOT_FOUND': 'Hubungan meja Tenant dan offline tidak ada',
      'API.TENANT_POINT_RELATION_EXISTS': 'Hubungan Tenant dan Data Point sudah ada',
      'API.TENANT_POINT_RELATION_NOT_FOUND': 'Hubungan Tenant dan Data Point tidak ada',
      'API.TENANT_SENSOR_RELATION_EXISTS': 'Hubungan sensor Tenant sudah ada',
      'API.TENANT_SENSOR_RELATION_NOT_FOUND': 'Hubungan sensor Tenant tidak ada',
      'API.TENANT_TYPE_NAME_IS_ALREADY_IN_USE': 'Nama dan tipe yang sudah digunakan',
      'API.TENANT_TYPE_NOT_FOUND': 'Nama dan tipe yang sudah digunakan',
      'API.TENANT_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': 'Kode penyimplifikasi tipe Tenant digunakan',
      'API.TENANT_TYPE_USED_IN_TENANT': 'Tipe tenant sudah digunakan dalam penyewa',
      'API.TENANT_VIRTUAL_METER_RELATION_EXISTS': 'Hubungan meja yang tegang dan virtual sudah ada',
      'API.TENANT_VIRTUAL_METER_RELATION_NOT_FOUND': 'Hubungan meja yang tegang dan virtual tidak ada',
      'API.TENANT_WORKING_CALENDAR_RELATION_EXISTS': 'Hubungan kalender tenan dan kerja sudah ada',
      'API.TENANT_WORKING_CALENDAR_RELATION_NOT_FOUND': 'Hubungan kalender tegang dan kerja tidak ada',
      'API.TEXT_MESSAGE_NOT_FOUND': 'SMS tidak ditemukan',
      'API.THE_REPORTING_PERIOD_MUST_BE_LONGER_THAN_15_MINUTES': 'Periode laporan harus lebih besar dari 15 menit',
      'API.THERE_IS_RELATION_WITH_CHILD_METERS': 'Terkait dengan meja anak',
      'API.THERE_IS_RELATION_WITH_CHILDREN_SPACES': 'Terkait dengan subspace',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENT_PARAMETERS': 'Berkait dengan parameter peralatan kombinasi',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENTS': 'Terkait dengan peralatan kombinasi',
      'API.THERE_IS_RELATION_WITH_DATA_SOURCES': 'Hubungan dengan sumber data',
      'API.THERE_IS_RELATION_WITH_ENERGY_FLOW_DIAGRAM_LINKS': 'Terkait dengan diagram aliran energi',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_BATTERIES': 'There Is Relation With Energy Storage Containers Batteries',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_GRIDS': 'There Is Relation With Energy Storage Containers Grids',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_LOADS': 'There Is Relation With Energy Storage Containers Loads',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_POWER_CONVERSION_SYSTEMS': 'There Is Relation With Energy Storage Containers Power Conversion Systems',
      'API.THERE_IS_RELATION_WITH_EQUIPMENT_PARAMETERS': 'Berkait dengan parameter terkait peralatan',
      'API.THERE_IS_RELATION_WITH_EQUIPMENTS': 'Terkait dengan peralatan',
      'API.THERE_IS_RELATION_WITH_METER': 'Terkait dengan tabel pengukuran',
      'API.THERE_IS_RELATION_WITH_METERS': 'Berkait dengan skala berbilang',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METER': 'Hubungan dengan tabel luar talian',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METERS': 'Hubungan dengan berbagai tabel luar talian',
      'API.THERE_IS_RELATION_WITH_OTHER_VIRTUAL_METERS': 'Hubungan dengan tabel virtual',
      'API.THERE_IS_RELATION_WITH_SENSORS': 'Terkait dengan sensor',
      'API.THERE_IS_RELATION_WITH_SHOPFLOORS': 'Berkait dengan workshop',
      'API.THERE_IS_RELATION_WITH_SPACES': 'Hubungan dengan ruang',
      'API.THERE_IS_RELATION_WITH_STORES': 'Berkait dengan toko',
      'API.THERE_IS_RELATION_WITH_TENANTS': 'Berkait dengan penyewa',
      'API.THERE_IS_RELATION_WITH_USERS': 'Terhubung dengan pengguna',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_METER': 'Hubungan dengan tabel virtual',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_POWER_PLANTS': 'Ada hubungan dengan Plant Daya Virtual',
      'API.THIS_DATA_SOURCE_IS_BEING_USED_BY_A_METER': 'Sumber data ini sedang digunakan oleh meter',
      'API.THIS_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'Meter ini digunakan oleh meter virtual',
      'API.THIS_OFFLINE_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': 'Meja luar talian ini digunakan oleh meja virtual',
      'API.THIS_SPACE_CANNOT_BE_CLONED': 'This space cannot be cloned',
      'API.THIS_SPACE_CANNOT_BE_DELETED': 'Ruang tidak dapat dihapus',
      'API.TIMEZONE_NOT_FOUND': 'Zona waktu tidak ada',
      'API.TOKEN_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'Token tidak ditemukan, silakan log masuk',
      'API.USER_ACCOUNT_HAS_BEEN_LOCKED': 'Akaun pengguna terkunci',
      'API.USER_ACCOUNT_HAS_EXPIRED': 'Akaun pengguna telah habis',
      'API.USER_ACCOUNT_IS_NOT_LOCKED': 'User account is not locked',
      'API.USER_NAME_IS_ALREADY_IN_USE': 'Nama pengguna sudah digunakan',
      'API.USER_NOT_FOUND': 'Pengguna tidak ada',
      'API.USER_PASSWORD_HAS_EXPIRED': 'Kata sandi pengguna telah habis',
      'API.USER_PRIVILEGE_NOT_FOUND': 'Tidak ditemukan keizinan pengguna',
      'API.USER_SESSION_NOT_FOUND': 'Tidak ditemukan sesi pengguna',
      'API.USER_SESSION_TIMEOUT': 'Sesi pengguna sudah habis',
      'API.USER_UUID_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': 'UUID pengguna tidak ada, silakan log masuk',
      'API.VIRTUAL_METER_NAME_IS_ALREADY_IN_USE': 'Nama tabel virtual sudah digunakan',
      'API.VIRTUAL_METER_NOT_FOUND': 'Tabel virtual tidak ada',
      'API.VIRTUAL_METER_OF_VARIABLE_NOT_FOUND': 'Tabel virtual untuk variabel tidak ada',
      'API.VIRTUAL_POINT_SHOULD_BE_ANALOG_VALUE': 'Titik data virtual seharusnya jumlah analog',
      'API.WEB_MESSAGE_NOT_FOUND': 'Informasi web tidak ada',
      'API.WECHAT_MESSAGE_NOT_FOUND': 'Informasi WeChat tidak ada',
      'API.WIND_FARM_NAME_IS_ALREADY_IN_USE': 'Wind farm name is already in use',
      'API.WIND_FARM_NOT_FOUND': 'Wind farm not found',
      'API.WORKING_CALENDAR_NAME_IS_ALREADY_IN_USE': 'Nama kalender kerja sudah digunakan',
      'API.WORKING_CALENDAR_NOT_FOUND': 'Kalender kerja tidak ada',

      MasterMeter: 'Tabel Ringkasan',
      SubMeter: 'Subtable'
    }
  },
  zh_TW: {
    translation: {
      // routes & menus
      Dashboard: '總覽',
      'Space Data': '空間數據',
      'Equipment Data': '設備數據',
      'Meter Data': '計量表數據',
      'Tenant Data': '租⼾數據',
      'Store Data': '⾨店數據',
      'Shopfloor Data': '⾞間數據',
      'Combined Equipment Data': '組合設備數據',
      'Auxiliary System': '輔助系統',
      Microgrid: '微電網',
      'Energy Storage Power Station': '儲能電站',
      'Photovoltaic Power Station': '電站',
      'Fault Alarm': '故障報警',
      Monitoring: '設備監控',
      'Advanced Reporting': '⾼級報表',
      'Knowledge Base': '知識庫',
      'Energy Category Data': '能耗分類分析',
      'Energy Item Data': '能耗分項分析',
      Carbon: '碳排放分析',
      Cost: '成本分析',
      Output: '產出分析',
      Income: '收入分析',
      Efficiency: '效率分析',
      Load: '負荷分析',
      Statistics: '統計分析',
      'Batch Analysis': '批量分析',
      Saving: '節能分析',
      Plan: '計劃分析',
      'Production': '產量',
      'Space Production': '產量能耗分析',
      'Equipment Tracking': '設備台賬',
      'Environment Monitor': '環境監測',
      'Meter Energy': '計量表能耗分析',
      'Meter Carbon': '計量表碳排放分析',
      'Meter Cost': '計量表成本分析',
      'Meter Trend': '計量表趨勢分析',
      'Meter Realtime': '計量表實時分析',
      'Meter Saving': '計量表節能分析',
      'Meter Plan': '計量表計劃分析',
      'Master Meter Submeters Balance': '總分表平衡分析',
      'Meter Batch Analysis': '計量表批量分析',
      'Meter Comparison': '計量表對比分析',
      'Meter Tracking': '計量表台賬',
      'Virtual Meter Saving': '虛擬表節能分析',
      'Virtual Meter Plan': '虛擬表計劃分析',
      'Virtual Meter Energy': '虛擬表能耗分析',
      'Virtual Meter Carbon': '虛擬表碳排放分析',
      'Virtual Meter Cost': '虛擬表成本分析',
      'Virtual Meter Batch Analysis': '虛擬表批量分析',
      'Offline Meter Energy': '離線表能耗分析',
      'Offline Meter Carbon': '離線表碳排放分析',
      'Offline Meter Cost': '離線表成本分析',
      'Offline Meter Batch Analysis': '離線表批量分析',
      'Offline Meter Saving': '離線表節能分析',
      'Offline Meter Plan': '離線表計劃分析',
      'Offline Meter Input': '離線表數據錄入',
      'Tenant Bill': '租⼾賬單',
      'Energy Flow Diagram': '能流圖',
      'Distribution System': '配電系統',
      'SVG System': '組態可視化系統',
      'Faults Data': '故障分析',
      'Space Equipments': '空間設備',
      'Combined Equipments': '組合設備',
      'Energy Storage Power Station List': '電站列表',
      'Energy Storage Power Station Details': '設備監控',
      'Energy Storage Power Station Reporting': '數據報表',
      'Energy Storage Power Station Alarm': '故障報警',
      'Energy Storage Power Station Maintenance': '維護保養',
      'Enter Production': '產量錄入',
      'Multiple Energy Storage Power Stations': '多站數據',
      'Multiple Energy Storage Power Stations Dashboard': '多站總覽',
      'Multiple Energy Storage Power Stations List': '電站列表',
      'Single Energy Storage Power Station': '單站數據',
      'Single Energy Storage Power Station Dashboard': '單站總覽',
      'Single Energy Storage Power Station Details': '設備數據',
      'Single Energy Storage Power Station Fault Alarm': '故障告警',
      'Energy Storage Power Station Reports': '統計報表',
      'Energy Storage Power Station Reports Energy': '電量報表',
      'Energy Storage Power Station Reports Revenue': '收益報表',
      'Energy Storage Power Station Reports Parameters': '數據査詢',
      'Photovoltaic Power Station List': '電站列表',
      'Photovoltaic Power Station Details': '設備監控',
      'Photovoltaic Power Station Reporting': '數據報表',
      'Photovoltaic Power Station Alarm': '故障報警',
      'Photovoltaic Power Station Maintenance': '維護保養',
      'Multiple Photovoltaic Power Stations': '多站數據',
      'Multiple Photovoltaic Power Stations Dashboard': '多站總覽',
      'Multiple Photovoltaic Power Stations List': '電站列表',
      'Single Photovoltaic Power Station': '單站數據',
      'Single Photovoltaic Power Station Dashboard': '單站總覽',
      'Single Photovoltaic Power Station Details': '設備數據',
      'Single Photovoltaic Power Station Fault Alarm': '故障告警',
      'Photovoltaic Power Station Reports': '統計報表',
      'Photovoltaic Power Station Reports Energy': '電量報表',
      'Photovoltaic Power Station Reports Revenue': '收益報表',
      'Photovoltaic Power Station Reports Parameters': '數據査詢',
      'Work Order': '⼯單管理',
      'Work Order Installation': '安裝記錄',
      'Work Order Repair': '維修記錄',
      'Work Order Inspection': '巡檢記錄',
      'Total Number of Work Orders': '工單總數',
      'Number of New Work Orders': '新工單數量',
      'Number of Inprogress Work Orders': '進行中的工單數量',
      'Number of Done Work Orders': '已完成工單數量',
      'Charge Energy Indicator': '充電能量',
      'Discharge Energy Indicator': '放電能量',
      'Charge Cost Indicator': '充電成本',
      'Discharge Revenue Indicator': '放電收益',
      'HVAC': '空調',
      'Fire Control': '消防',

      // Dashboard
      'Welcome to DoraEOS': '歡迎使⽤ DoraEOS',
      'An Industry Leading Open Source Energy Management System': '⾏業領先的開源能源管理系統',
      "This Year's Consumption CATEGORY VALUE UNIT": '本年消耗 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Year's Costs CATEGORY VALUE UNIT": '本年成本 {{CATEGORY}}{{VALUE}} {{UNIT}}',
      "This Month's Consumption CATEGORY VALUE UNIT": '本⽉消耗 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      "This Month's Costs CATEGORY VALUE UNIT": '本⽉成本 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Consumption CATEGORY VALUE UNIT in The Same Period Last Year':
        '去年同期消耗 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Costs CATEGORY VALUE UNIT in The Same Period Last Year': '去年同期成本 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'CATEGORY Consumption UNIT': '{{CATEGORY}} 消耗 {{UNIT}}',
      'CATEGORY Costs UNIT': '{{CATEGORY}} 成本 {{UNIT}}',
      // Entities
      Space: '空間',
      Equipment: '設備',
      Meter: '計量表',
      'Offline Meter': '離線表',
      'Virtual Meter': '虛擬表',
      Tenant: '租⼾',
      Store: '⾨店',
      Shopfloor: '⾞間',
      'Combined Equipment': '組合設備',
      'Cost Center': '成本中⼼',
      Name: '名稱',
      DisplayName: '顯⽰名',
      UserName: '⽤⼾名',
      Description: '描述',
      'Energy Category': '能源分類',
      'Child Spaces': '⼦空間',
      'Associated Equipment': '相關設備',
      //Energy Categories
      Electricity: '電',
      Water: '⾃來⽔',
      'Natural Gas': '天然氣',
      // SidePanelModal
      Settings: '設置',
      'Set your own customized style': '設置您的⾃定義樣式',
      'Color Scheme': '配⾊⽅案',
      'Choose the perfect color mode for your app': '為您的應⽤選擇理想的⾊彩模式',
      Light: '亮⾊',
      Dark: '暗⾊',
      'RTL Mode': 'RTL 模式',
      'Switch your language direction': '改變你的語⾔⽅向',
      'Fluid Layout': '流動布局',
      'Toggle container layout system': '切換容器布局系統',
      'Navigation Position': '導航欄位置',
      'Select a suitable navigation system for your web application': '為您的 web 應⽤程序選擇合適的導航欄系統',
      'Vertical Nav': '垂直導航欄',
      'Top Nav': '頂部導航欄',
      'Vertical Navbar Style': '垂直導航欄樣式',
      'Switch between styles for your vertical navbar': '切換垂直導航欄的樣式',
      Language: '語⾔',
      'Switch between languages': '切換語⾔',
      'language-zh_CN': '简体中文',
      'language-en': 'English',
      'language-de': 'Deutsch',
      'language-fr': 'Français',
      'language-es': 'Español',
      'language-ru': 'Руссĸий',
      'language-ar': 'العربية',
      'language-vi': 'Tiếng Việt',
      'language-th': 'ภาษาไทย',
      'language-tr': 'Türkçe',
      'language-ms': 'Bahasa Melayu',
      'language-id': 'Bahasa Indonesia',
      'language-zh_TW': '繁體中⽂',
      'Like What You See?': '喜歡這個系統?',
      'Get DoraEOS now': '立即獲取 DoraEOS',
      Purchase: '購買',
      // Query Panels
      'Base Period Begins': '基準期開始',
      'Base Period Ends': '基準期結束',
      'Comparison Types': '比較類型',
      'Year-Over-Year': '去年同比',
      'Month-On-Month': '上⽉環比',
      'Free Comparison': '⾃由比',
      'None Comparison': '不比',
      'Reporting Period Begins': '報告期開始',
      'Reporting Period Ends': '報告期結束',
      '(Optional)': '(可選)',
      'Period Types': '時間尺度',
      Yearly: '年',
      Monthly: '⽉',
      Weekly: '周',
      Daily: '⽇',
      Hourly: '時',
      Submit: '提交',
      'Input Energy Category': '消耗能源分類',
      'Output Energy Category': '產出能源分類',
      'Fraction Parameter': '分式參數',
      Search: '搜索',
      //DateRangePicker
      sunday: '⽇',
      monday: '⼀',
      tuesday: '⼆',
      wednesday: '三',
      thursday: '四',
      friday: '五',
      saturday: '六',
      ok: '確定',
      today: '今天',
      yesterday: '昨天',
      hours: '時',
      minutes: '分',
      seconds: '秒',
      last7Days: '最近七天',
      'Select Date Range': '選擇⽇期範圍',
      //Card Summaries and Line Charts
      'Base Period': '基準期',
      'Reporting Period': '報告期',
      'Per Unit Area': '單位⾯積值',
      'Per Capita': '人均值',
      'Per Unit Production': '單位產品值',
      Baseline: '基線值',
      Actual: '實際值',
      'Baseline Value - Actual Value': '基線值-實際值',
      'Average Load': '平均負荷',
      'Maximum Load': '最⼤負荷',
      'Load Factor': '負荷系數',
      'Ratio of Average Load to Maximum Load': '平均負荷與最⼤負荷的比值',
      'Carbon Dioxide Emissions by Energy Category': '⼆氧化碳排放占比',
      'Costs by Energy Category': '成本占比',
      'Incomes by Energy Category': '收入占比',
      'Electricity Consumption by Time-Of-Use': '分時消耗電量',
      'Electricity Carbon Dioxide Emissions by Time-Of-Use': '分時⽤電排放⼆氧化碳',
      'Electricity Cost by Time-Of-Use': '分時⽤電成本',
      'CATEGORY UNIT Consumption by Energy Items': '{{CATEGORY}}{{UNIT}} 分項消耗占比',
      'Ton of Standard Coal by Energy Category': '噸標準煤 (TCE) 占比',
      'Ton of Carbon Dioxide Emissions by Energy Category': '噸⼆氧化碳排放 (TCO2E) 占比',
      'Reporting Period Consumption CATEGORY UNIT': '報告期消耗{{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{EQUIPMENT}} 報告期消耗 {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Consumption CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} 報告期消耗 {{CATEGORY}} {{UNIT}}',
      'CATEGORY VALUE UNIT': '{{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption CATEGORY VALUE UNIT': '報告期消耗 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Production PRODUCT VALUE UNIT': '報告期產量 {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Production PRODUCT VALUE UNIT': '基準期產量 {{PRODUCT}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption CATEGORY VALUE UNIT': '基準期消耗 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY UNIT': '報告期消耗 {{ITEM}} {{CATEGORY}} {{UNIT}}',
      'Reporting Period Consumption ITEM CATEGORY VALUE UNIT': '報告期消耗 {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Consumption ITEM CATEGORY VALUE UNIT': '基準期消耗 {{ITEM}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Child Space Proportion CATEGORY UNIT': '⼦空間分類占比 {{CATEGORY}} {{UNIT}}',
      'Child Space Total Proportion': '⼦空間總計占比',
      'Reporting Period Carbon Dioxide Emissions CATEGORY UNIT': '報告期⼆氧化碳排放 {{CATEGORY}} {{UNIT}}',
      'Reporting Period Carbon Dioxide Emissions CATEGORY VALUE UNIT':
        '報告期⼆氧化碳排放 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Carbon Dioxide Emissions CATEGORY VALUE UNIT': '基準期⼆氧化碳排放 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Costs CATEGORY UNIT': '報告期成本 {{CATEGORY}}{{UNIT}}',
      'Reporting Period Costs CATEGORY VALUE UNIT': '報告期成本 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Costs CATEGORY VALUE UNIT': '基準期成本 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Output CATEGORY UNIT': '報告期產出 {{CATEGORY}} {{UNIT}}',
      'EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{EQUIPMENT}} 報告期產出 {{CATEGORY}} {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Output CATEGORY UNIT':
        '{{COMBINED_EQUIPMENT}} 報告期產出 {{CATEGORY}} {{UNIT}}',
      'Reporting Period Output CATEGORY VALUE UNIT': '報告期產出 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Output CATEGORY VALUE UNIT': '基準期產出 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Income CATEGORY UNIT': '報告期收入 {{CATEGORY}} {{UNIT}}',
      'Reporting Period Income CATEGORY VALUE UNIT': '報告期收入 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Income CATEGORY VALUE UNIT': '基準期收入 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Total Income UNIT': '報告期總收入 {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME UNIT': '報告期累積效率 {{NAME}} {{UNIT}}',
      'EQUIPMENT Reporting Period Cumulative Efficiency UNIT':
        '{{EQUIPMENT}} 報告期累積效率 {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency UNIT':
        '{{COMBINED_EQUIPMENT}} 報告期累積綜合效率 {{UNIT}}',
      'COMBINED_EQUIPMENT Reporting Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} 報告期累積綜合效率 {{VALUE}} {{UNIT}}',
      'COMBINED_EQUIPMENT Base Period Cumulative Comprehensive Efficiency VALUE UNIT':
        '{{COMBINED_EQUIPMENT}} 基準期累積綜合效率 {{VALUE}} {{UNIT}}',
      'Instantaneous Efficiency UNIT': '瞬時效率 {{UNIT}}',
      'EQUIPMENT Instantaneous Efficiency UNIT': '{{EQUIPMENT}} 瞬時效率 {{UNIT}}',
      'COMBINED_EQUIPMENT Instantaneous Comprehensive Efficiency UNIT': '{{COMBINED_EQUIPMENT}} 瞬時綜合效率 {{UNIT}}',
      'Reporting Period Cumulative Efficiency VALUE UNIT': '報告期累積效率 {{VALUE}} {{UNIT}}',
      'Reporting Period Cumulative Efficiency NAME VALUE UNIT': '報告期累積效率 {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency VALUE UNIT': '基準期累積效率 {{VALUE}} {{UNIT}}',
      'Base Period Cumulative Efficiency NAME VALUE UNIT': '基準期累積效率 {{NAME}} {{VALUE}} {{UNIT}}',
      'Base Period CATEGORY Maximum Load UNIT': '基準期 {{CATEGORY}} 最⼤負荷 {{UNIT}}',
      'Reporting Period CATEGORY Maximum Load UNIT': '報告期 {{CATEGORY}} 最⼤負荷 {{UNIT}}',
      'Reporting Period CATEGORY Average Load UNIT': '報告期 {{CATEGORY}} 平均負荷 {{UNIT}}',
      'Reporting Period CATEGORY Load Factor': '報告期 {{CATEGORY}} 負荷系數',
      'Base Period CATEGORY Average Load VALUE UNIT': '基準期 {{CATEGORY}} 平均負荷 {{VALUE}} {{UNIT}}',
      'Reporting Period CATEGORY Average UNIT': '報告期 {{CATEGORY}} 平均值 {{UNIT}}',
      'Reporting Period CATEGORY Maximum UNIT': '報告期 {{CATEGORY}} 最⼤值 {{UNIT}}',
      'Reporting Period CATEGORY Minimum UNIT': '報告期 {{CATEGORY}} 最⼩值 {{UNIT}}',
      'Reporting Period CATEGORY Mean UNIT': '報告期 {{CATEGORY}} 算術平均數 {{UNIT}}',
      'Reporting Period CATEGORY Median UNIT': '報告期 {{CATEGORY}} 中位數 {{UNIT}}',
      'Reporting Period CATEGORY Stdev UNIT': '報告期 {{CATEGORY}} 樣本標準差 {{UNIT}}',
      'Reporting Period CATEGORY Variance UNIT': '報告期 {{CATEGORY}} 樣本⽅差 {{UNIT}}',
      'Reporting Period Saving CATEGORY (Baseline - Actual) UNIT': '報告期節約 {{CATEGORY}} (基線-實際) {{UNIT}}',
      'Reporting Period Decreased CATEGORY (Baseline - Actual) UNIT': '報告期減少 {{CATEGORY}} (基線-實際) {{UNIT}}',
      'Reporting Period Saving CATEGORY VALUE UNIT': '報告期節約 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Base Period Saving CATEGORY VALUE UNIT': '基準期節約 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY UNIT': '報告期總表消耗 {{CATEGORY}} {{UNIT}}',
      'Reporting Period Master Meter Consumption CATEGORY VALUE UNIT': '報告期總表消耗 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY UNIT': '報告期分表消耗 {{CATEGORY}} {{UNIT}}',
      'Reporting Period Submeters Consumption CATEGORY VALUE UNIT':
        '報告期分表消耗 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Reporting Period Difference CATEGORY UNIT': '報告期差值 {{CATEGORY}} {{UNIT}}',
      'Reporting Period Percentage Difference': '報告期差值百分比',
      'Reporting Period Difference CATEGORY VALUE UNIT': '報告期差值 {{CATEGORY}} {{VALUE}} {{UNIT}}',
      'Trend Values': '趨勢值',
      'METER CATEGORY VALUE UNIT': '{{METER}} {{CATEGORY}} {{VALUE}} {{UNIT}}',
      //FDD
      'Fault List': '故障列表',
      'Reporting Period Number of Fault VALUE': '報告期故障數: {{VALUE}}',
      'All Faults': '全部故障',
      'Space Faults': '空間故障',
      'Number of All Faults': '全部故障數',
      'Number of Space Faults': '空間故障數',
      'Total Number of Faults': '總故障數量',
      'Number of New Faults': '新發故障數量',
      'Number of Inprogress Faults': '進⾏中故障數量',
      'Number of Done Faults': '已完成故障數量',
      //Data Panels
      'Ton of Standard Coal': '噸標準煤',
      'Ton of Carbon Dioxide Emissions': '噸⼆氧化碳排放',
      'Operating Characteristic Curve': '運⾏曲線',
      Tariff: '費率',
      'Detailed Data': '詳細數據',
      'Child Spaces Data': '⼦空間數據',
      'Associated Equipment Data': '相關設備數據',
      'This Year': '本年',
      'The Same Period Last Year': '去年同期',
      'This Month': '本⽉',
      'This Day': '本⽇',
      Total: '總計',
      'No data found': '沒有數據',
      Export: '導出',
      Datetime: '⽇期時間',
      'Time-Of-Use Type': '分時類型',
      'Top-Peak': '尖',
      'On-Peak': '峰',
      'Mid-Peak': '平',
      'Off-Peak': '⾕',
      Percentage: '百分比',
      //Realtime Chart
      'Trend in the last hour of Energy Value Point': '能耗值點最近⼀⼩時趨勢',
      Circuit: '回路',
      Point: '數據點',
      'Realtime Value': '實時值',
      //Meter Tracking
      'Meter List': '計量表列表',
      'Edit Meter': '編輯',
      'Start Value': '起始值',
      'End Value': '結束值',
      'Difference Value': '差值',
      'Start Integrity Rate': '起始數據完整率 (%)',
      'End Integrity Rate': '結束數據完整率 (%)',
      'Full Integrity Rate': '整體數據完整率 (%)',
      //Equipment Tracking
      'Equipment List': '設備列表',
      'Edit Equipment': '編輯',
      //Profile Dropdown
      Feedback: '反饋',
      'Account Settings': '賬號設置',
      Logout: '退出',
      //Authentication
      'Log in': '登錄',
      'Email address': '電⼦郵件地址',
      Password: '密碼',
      CaptchaCode: '驗證碼',
      Refresh: '刷新',
      'Remember me': '記住我',
      'Logged in as ': '已登錄 ',
      'Forgot Password?': '忘記密碼?',
      'Forgot your password?': '忘記你的密碼?',
      "Enter your email and we'll send you a reset link": '輸入電⼦郵件地址，我們會把重置鏈接發送給您',
      "Enter your email and we'll send you a registration link": '輸入電⼦郵件地址，我們會把注冊鏈接發送給您',
      'Reset Password': '重置密碼',
      'Thanks for using DoraEOS!': '感謝您使⽤ DoraEOS!',
      'You are now successfully signed out': '您已成功退出',
      'Return to Login': '返回登錄',
      'Please check your email!': '請查收您的電⼦郵件!',
      'An email has been sent to ': '⼀封電⼦郵件已經發送⾄ ',
      'Please click on the included link to reset your password': '請點擊⾥⾯的鏈接重置您的密碼',
      'Please click on the included link to register your account': '請點擊⾥⾯的鏈接注冊您的賬⼾',
      'An email with password reset link is sent to ': '⼀封包含密碼重置鏈接的電⼦郵件已經發送⾄ ',
      'Change Password': '修改密碼',
      'Old Password': '原密碼',
      'New Password': '新密碼',
      'Confirm Password': '重覆新密碼',
      'Update Password': '更新密碼',
      'Password has been changed!': '密碼已修改!',
      'Verification code has been sent to your inbox. \
    Please copy it to the input box below.':
        '驗證碼已發送到您的收件箱，請將其覆制到下⾯的輸入框中。',
      'New to DoraEOS': '初次使⽤ DoraEOS',
      'Create an account': '注冊賬⼾',
      'EMAIL Account registration successful': '{{EMAIL}} 賬號注冊成功',
      'Thanks for verifying your account!': '感謝您驗證您的賬⼾！',
      'Your code is': '您的代碼是',
      'Send verification code': '發送驗證碼',
      'Please wait for NUMBER seconds': '請等待 {{NUMBER}} 秒',
      'Password reset': '密碼重置',
      'Please wait for approval': '請等待審核',
      //notification
      Notifications: '通知',
      'Mark all as read': '全部設為已讀',
      'View all': '全部',
      'Notification New': '新的',
      'Notification Earlier': '更早的',
      'Notification List': '通知列表',
      'Bulk actions': '批量操作',
      'Notification Subject': '主題',
      'Notification Created Datetime': '創建時間',
      'Notification Message': '內容',
      'Notification Priority': '優先級',
      'Notification Status': '狀態',
      'Notification Mark As Read': '標記為已讀',
      'Notification Mark As Acknowledged': '確認',
      'Notification Low': '低',
      'Notification Medium': '中',
      'Notification High': '⾼',
      'Notification Critical': '嚴重',
      'Notification Unread': '未讀',
      'Notification Read': '已讀',
      'Notification Acknowledged': '已確認',
      'Notification Archive': '存檔',
      'Notification Delete': '刪除',
      'Notification Apply': '應⽤',
      'Notification Start Datetime': '開始時間',
      'Notification End Datetime': '結束時間',
      'Notification Update Datetime': '更新時間',
      //Monitoring
      'Run Commands': '運⾏指令',
      'Fault Alarms': '故障報警',
      'Instantaneous Efficiency VALUE UNIT': '瞬時效率 {{VALUE}} {{UNIT}}',
      'Communication Status': '通信狀態',
      'Communication Online': '在線',
      'Communication Offline': '離線',
      'Equipment Status': '設備狀態',
      'Equipment Running': '運⾏',
      'Equipment Stopped': '停機',
      'Show Up to': '每⾴顯⽰數量',
      All: '全部',
      'FROM - TO of TOTAL': '當前顯⽰ {{FROM}} - {{TO}} 總計 {{TOTAL}}',
      //Microgrid
      'PCS Run State': 'PCS 運⾏狀態',
      'PCS Unknown': '未知',
      'PCS Initializing': '初始上電',
      'PCS Standby': '待機',
      'PCS Shutdown': '關機',
      'PCS Fault': '故障',
      'PCS Running': '運⾏',
      'Battery Power': '電池功率',
      'Photovoltaic Power': '光伏功率',
      'Grid Power': '電網功率',
      'Load Power': '負載功率',
      Reporting: '數據報表',
      Maintenance: '維護保養',
      'General Information': '基本信息',
      'Serial Number': '序列號',
      Address: '地址',
      'Postal Code': '郵編',
      'Rated Capacity': '額定容量',
      'Rated Power': '額定功率',
      Latitude: '緯度',
      Longitude: '經度',
      'Strategy Management': '策略管理',
      'Charging Schedule': '充電時間表',
      'Charge Start Time': '充電開始時間',
      'Charge End Time': '充電結束時間',
      'Discharge Start Time': '放電開始時間',
      'Discharge End Time': '放電結束時間',
      'Total Rated Power': '總額定功率',
      'Total Rated Capacity': '總額定容量',
      "Today's Charge": '今日充電量',
      "Today's Discharge": '今日放電量',
      "Today's Generation": "今日發電量",
      'Total Charge': '總充電',
      'Total Discharge': '總放電',
      'Total Generation': '總發電量',
      'Total Revenue': '總收益',
      'Total Efficiency': '總效率',
      'Discharge Achievement Rate': '放電達成率',
      // Revenue Indicator
      "Today's Cost": "今日成本",
      "Today's Income": "今日收入",
      "Total Cost": "總成本",
      "Total Income": "總收入",
      "Today's Revenue": "今日利潤",

      'Revenue Ranking': '收益排名',
      'Efficiency Ranking': '效率排名',
      'Charge Ranking': '充電排名',
      'Discharge Ranking': '放電排名',
      'Number of Microgrids': '微電網數',
      'Number of Power Stations': '電站數',
      'Microgrid List': '微電網列表',
      'Previous Page': '上⼀⾴',
      'Next Page': '下⼀⾴',
      '7 Days': '7⽇',
      'Charge UNIT': '充 {{UNIT}}',
      'Discharge UNIT': '放 {{UNIT}}',
      'Generation UNIT': '發電 {{UNIT}}',
      'Energy Indicator': '能量指標',
      'Revenue Indicator': '收益指標',
      'Carbon Indicator': '碳指標',

      // Energy Storage Power Station
      'Battery Operating State': '電池運⾏狀態',
      'Battery State': '電池狀態',
      'Battery Unknown': '未知',
      'Battery Stopped': '停⽌',
      'Battery Reserved': 'Reserved', // 0
      'Battery Fault': '故障', // 1
      'Battery Warning': '預警', // 2
      'Battery Standby': '待機', // 3
      'Battery Prohibit DisCharging': '禁放', // 4
      'Battery Prohibit Charging': '禁充', // 5
      'Battery Normal': '正常', // 6
      'Battery Charging': '充電', // 7
      'Battery Discharging': '放電', // 8
      'Battery Idle': '空闲', // 9
      'Phase of Lifecycle': '生命週期階段',
      'Use Phase': '使用階段',
      'Commissioning Phase': '調試階段',
      'Installation Phase': '安裝階段',
      'Device Status': '設備狀態',
      'Basic Information': '基本訊息',
      'Gateway': 'Gateway',
      'Grid Meter': '電網電錶',
      'Load Meter': '負載電錶',
      'Total Active Power': 'Total Active Power',
      'Active Power A': 'Active Power A',
      'Active Power B': 'Active Power B',
      'Active Power C': 'Active Power C',
      'Total Reactive Power': 'Total Reactive Power',
      'Reactive Power A': 'Reactive Power A',
      'Reactive Power B': 'Reactive Power B',
      'Reactive Power C': 'Reactive Power C',
      'Total Apparent Power': 'Total Apparent Power',
      'Apparent Power A': 'Apparent Power A',
      'Apparent Power B': 'Apparent Power B',
      'Apparent Power C': 'Apparent Power C',
      'Total Power Factor': 'Total Power Factor',
      'Active Energy Import': 'Active Energy Import',
      'Active Energy Export': 'Active Energy Export',
      'Active Energy Net': 'Active Energy Net',

      // Photovoltaic Power Station
      'Invertor': '逆變器',
      'Invertor Run State': '逆變器運⾏狀態',
      'Invertor Unknown': '未知',
      'Invertor Initializing': '初始上電',
      'Invertor Standby': '待機',
      'Invertor Shutdown': '關機',
      'Invertor Fault': '故障',
      'Invertor Running': '運⾏',

      //Advanced Reporting & Knowledge Base
      'Created Datetime': '⽣成時間',
      'File Format': '⽂件格式',
      'File Size': '⽂件⼤⼩',
      Uploader: '上傳者',
      'Upload Datetime': '上傳時間',
      //Error
      "The page you're looking for is not found": '您要找的⾴⾯不存在',
      "Make sure the address is correct and that the page hasn't moved":
        '請確認地址正確且⾴⾯沒有被移動',
      'If you think this is a mistake,': '如果您認為這是⼀個錯誤,',
      'contact us': '聯系我們',
      'Take me home': '帶我回⾸⾴',
      'Whoops, something went wrong!': '哦, 出錯了！!',
      'Try refreshing the page, or going back and attempting the action again':
        '請嘗試刷新⾴⾯, 或回退並再次嘗試執⾏這個操作',
      'If this problem persists,': '如果問題依然存在,',
      'Captcha Error': '驗證碼錯誤',
      'Confirm Password Error': '兩次密碼不⼀致',
      'It looks like you clicked on an invalid password reset link. Please tryagain.':
        '看起來你點擊了⼀個無效的重置密碼鏈接。請再試⼀次。',
      'It looks like you clicked on an invalid registration account link. Please tryagain.':
        '看起來你點擊了⼀個無效的注冊賬號鏈接。請再試⼀次。',
      //Tenant Bill
      'Lease Contract Number': '租賃合同號碼',
      Download: '下載',
      Print: '打印',
      'Payment Notice': '付款通知書',
      'Bill To': '致',
      'Bill Number': '賬單號碼',
      'Bill Date': '賬單⽇期',
      'Payment Due Date': '付款到期⽇',
      'Amount Payable': '應付款⾦額',
      'Billing Period Start': '結算期起始⽇期',
      'Billing Period End': '結算期結束⽇期',
      Quantity: '數量',
      Price: '價格',
      Unit: '單位',
      Amount: '⾦額',
      Subtotal: '⼩計',
      'VAT Output Tax': '增值稅銷項稅⾦',
      'Total Amount Payable': '應付⾦額合計',
      'Please make sure to pay on or before the payment due date above':
        '請確保在上述付款到期⽇或之前付款',
      'Send money to the following account': '匯款⾄以下賬⼾',
      'Account Name': '賬⼾名稱',
      'Bank Name': '開⼾銀⾏',
      'Bank Address': '銀⾏地址',
      'RMB Account': '⼈⺠幣賬⼾',
      // button
      'Toggle Navigation': '切換導航',
      // working calendar
      'Working Days': '⼯作⽇',
      'Non Working Days': '非⼯作⽇',
      'Select Row': '請選擇要操作的數據⾏',
      // Offline Meter Input
      'Daily Value': '⽇數據',
      Date: '⽇期',
      'Successfully Saved': '保存成功',
      'Previous Data Is Empty': '之前的數據為空',
      // API
      'API.ACCOUNT_UNLOCK_FAILED': 'Account unlock failed',
      'API.ADMINISTRATOR_SESSION_NOT_FOUND': '管理員會話不存在',
      'API.ADMINISTRATOR_SESSION_TIMEOUT': '管理員會話已超時',
      'API.ADVANCED_REPORT_NOT_FOUND': '⾼級報表不存在',
      'API.API_KEY_HAS_EXPIRED': 'Key has expired',
      'API.API_KEY_NAME_IS_ALREADY_IN_USE': 'Key name is already in use',
      'API.API_KEY_NOT_FOUND': 'Key not found',
      'API.BAD_REQUEST': '錯誤請求',
      'API.BALANCING_PRICE_POINT_NOT_FOUND': 'Balancing price point not found',
      'API.BATTERY_STATE_POINT_NOT_FOUND': 'Battery state point not found',
      'API.BUY_METER_NOT_FOUND': 'Buy meter not found',
      'API.CANNOT_SET_EXISTING_SUBMETER_AS_MASTER_METER': '不能將已經設為分表的計量表設置為總表',
      'API.CHARGE_METER_NOT_FOUND': 'Charge meter not found',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_EXISTS':
        'Combined equipment command relation exists',
      'API.COMBINED_EQUIPMENT_COMMAND_RELATION_NOT_FOUND':
        'Combined equipment command relation not found',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_EXISTS': '組合設備與設備關系已存在',
      'API.COMBINED_EQUIPMENT_EQUIPMENT_RELATION_NOT_FOUND':
        '組合設備與設備關系不存在',
      'API.COMBINED_EQUIPMENT_METER_RELATION_EXISTS': '組合設備與計量表關系已存在',
      'API.COMBINED_EQUIPMENT_METER_RELATION_NOT_FOUND': '組合設備與計量表關系不存在',
      'API.COMBINED_EQUIPMENT_NAME_IS_ALREADY_IN_USE': '組合設備名稱已使⽤',
      'API.COMBINED_EQUIPMENT_NOT_FOUND': '組合設備不存在',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_EXISTS':
        '組合設備與離線表關系已存在',
      'API.COMBINED_EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND'
        : '組合設備與離線表關系不存在',
      'API.COMBINED_EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE':
        '組合設備相關參數名稱已使⽤',
      'API.COMBINED_EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': '組合設備相關參數不存在或不匹配',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS':
        '組合設備與虛擬表關系已存在',
      'API.COMBINED_EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND'
        : '組合設備與虛擬表關系不存在',
      'API.COMMAND_NAME_IS_ALREADY_IN_USE': 'Command name is already in use',
      'API.COMMAND_NOT_FOUND': 'Command not found',
      'API.CONTACT_NAME_IS_ALREADY_IN_USE': '聯系⼈姓名已使⽤',
      'API.CONTACT_NOT_FOUND': '聯系⼈不存在',
      'API.COOLING_METER_NOT_FOUND': 'Cooling meter not found',
      'API.COST_CENTER_EXTERNAL_ID_EXISTS': '成本中⼼外部 ID 已存在',
      'API.COST_CENTER_NAME_EXISTS': '成本中⼼名稱已存在',
      'API.COST_CENTER_NOT_FOUND': '成本中⼼不存在',
      'API.COST_FILE_NOT_FOUND': '成本⽂件不存在',
      'API.DATA_REPAIR_FILE_NOT_FOUND': 'Data repair file not found',
      'API.DATA_SOURCE_NAME_IS_ALREADY_IN_USE': '數據源名稱已使⽤',
      'API.DATA_SOURCE_NOT_FOUND': '數據源不存在',
      'API.DATABASE_ERROR': '數據庫錯誤',
      'API.DATE_IS_ALREADY_IN_WORKING_CALENDAR': '⽇期已在⼯作⽇歷中',
      'API.DATE_LOCAL_NOT_FOUND': '未找到本地⽇期',
      'API.DISCHARGE_METER_NOT_FOUND': 'Discharge meter not found',
      'API.DISTRIBUTION_CIRCUIT_NAME_IS_ALREADY_IN_USE': '配電回路名稱已使⽤',
      'API.DISTRIBUTION_CIRCUIT_NOT_FOUND': '配電回路不存在',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_EXISTS': '配電回路與點位關系已存在',
      'API.DISTRIBUTION_CIRCUIT_POINT_RELATION_NOT_FOUND': '配電回路與點位關系不存在',
      'API.DISTRIBUTION_SYSTEM_NAME_IS_ALREADY_IN_USE': '配電系統名稱已使⽤',
      'API.DISTRIBUTION_SYSTEM_NOT_FOUND': '配電系統不存在',
      'API.ELECTRICITY_METER_NOT_FOUND': 'Electricity meter not found',
      'API.EMAIL_IS_ALREADY_IN_USE': '電⼦郵箱已使⽤',
      'API.EMAIL_MESSAGE_NOT_FOUND': '電⼦郵件消息不存在',
      'API.EMAIL_NOT_FOUND': '電⼦郵箱不存在',
      'API.EMAIL_SERVER_HOST_IS_ALREADY_IN_USE': '郵件服務器主機已使⽤',
      'API.EMAIL_SERVER_NOT_FOUND': '郵件服務器主機不存在',
      'API.EMPTY_VARIABLES_ARRAY': '空變量數組',
      'API.ENERGY_CATEGORY_NAME_IS_ALREADY_IN_USE': '能源分類名稱已使⽤',
      'API.ENERGY_CATEGORY_NOT_FOUND': '能源分類不存在',
      'API.ENERGY_CATEGORY_USED_IN_ENERGY_ITEMS': '能源分類在能源分項中已使⽤',
      'API.ENERGY_CATEGORY_USED_IN_METER': '能源分類在計量表中已使⽤',
      'API.ENERGY_CATEGORY_USED_IN_OFFLINE_METER': '能源分類在離線表中已使⽤',
      'API.ENERGY_CATEGORY_USED_IN_TARIFFS': '能源分類在費率中已使⽤',
      'API.ENERGY_CATEGORY_USED_IN_VIRTUAL_METER': '能源分類在虛擬表中已使⽤',
      'API.ENERGY_FLOW_DIAGRAM_LINK_IS_ALREADY_IN_USE': '能流圖鏈接已使⽤',
      'API.ENERGY_FLOW_DIAGRAM_LINK_NOT_FOUND_OR_NOT_MATCH':
        '能流圖鏈接不存在或不匹配',
      'API.ENERGY_FLOW_DIAGRAM_NAME_IS_ALREADY_IN_USE': '能流圖名稱已使⽤',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NAME_IS_ALREADY_IN_USE':
        '能流圖節點已使⽤',
      'API.ENERGY_FLOW_DIAGRAM_NODE_NOT_FOUND_OR_NOT_MATCH':
        '能流圖節點不存在或不匹配',
      'API.ENERGY_FLOW_DIAGRAM_NOT_FOUND': '能流圖不存在',
      'API.ENERGY_ITEM_DOES_NOT_BELONG_TO_ENERGY_CATEGORY':
        '能源分項不屬於能源分類',
      'API.ENERGY_ITEM_IS_NOT_BELONG_TO_ENERGY_CATEGORY': '能源分項不屬於能源分類',
      'API.ENERGY_ITEM_NAME_IS_ALREADY_IN_USE': '能源分項已使⽤',
      'API.ENERGY_ITEM_NOT_FOUND': '能源分項不存在',
      'API.ENERGY_ITEM_USED_IN_METER': '能源分項在計量表中已使⽤',
      'API.ENERGY_ITEM_USED_IN_OFFLINE_METER': '能源分項在離線表中已使⽤',
      'API.ENERGY_ITEM_USED_IN_VIRTUAL_METER': '能源分項在虛擬表中已使⽤',
      'API.ENERGY_STORAGE_POWER_STATION_NAME_IS_ALREADY_IN_USE':
        'Energy storage power station name is already in use',
      'API.ENERGY_STORAGE_POWER_STATION_NOT_FOUND': 'Energy storage power station not found',
      'API.EQUIPMENT_COMMAND_RELATION_EXISTS': 'Equipment command relation exists',
      'API.EQUIPMENT_COMMAND_RELATION_NOT_FOUND': 'Equipment command relation not found',
      'API.EQUIPMENT_METER_RELATION_EXISTS': '設備與計量表關系已存在',
      'API.EQUIPMENT_METER_RELATION_NOT_FOUND': '設備與計量表關系不存在',
      'API.EQUIPMENT_NAME_IS_ALREADY_IN_USE': '設備名稱已使⽤',
      'API.EQUIPMENT_NOT_FOUND': '設備不存在',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_EXISTS': '設備與離線表關系已存在',
      'API.EQUIPMENT_OFFLINE_METER_RELATION_NOT_FOUND': '設備與離線表關系不存在',
      'API.EQUIPMENT_PARAMETER_NAME_IS_ALREADY_IN_USE': '設備相關參數名稱已使⽤',
      'API.EQUIPMENT_PARAMETER_NOT_FOUND_OR_NOT_MATCH': '設備相關參數不存在或不匹配',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_EXISTS': '設備與虛擬表關系已存在',
      'API.EQUIPMENT_VIRTUAL_METER_RELATION_NOT_FOUND': '設備與虛擬表關系不存在',
      'API.ERROR': '錯誤',
      'API.EXCEPTION': '異常',
      'API.FAILED_TO_READ_REQUEST_STREAM': 'Failed to read request stream',
      'API.FAILED_TO_RESTORE_COST_FILE': '恢覆成本⽂件失敗',
      'API.FAILED_TO_RESTORE_DATA_REPAIR_FILE': 'Failed to restore data repair file',
      'API.FAILED_TO_RESTORE_KNOWLEDGE_FILE': '恢覆知識⽂件失敗',
      'API.FAILED_TO_RESTORE_OFFLINE_METER_FILE': '恢覆離線表⽂件失敗',
      'API.FAILED_TO_SAVE_OFFLINE_METER_FILE': '保存離線表⽂件失敗',
      'API.FAILED_TO_UPLOAD_ATTACHMENT_FILE': 'Failed to upload attachment file',
      'API.FAILED_TO_UPLOAD_COST_FILE': '上傳成本⽂件失敗',
      'API.FAILED_TO_UPLOAD_DATA_REPAIR_FILE': 'Failed to upload data repair file',
      'API.FAILED_TO_UPLOAD_KNOWLEDGE_FILE': '上傳知識⽂件失敗',
      'API.FAILED_TO_UPLOAD_OFFLINE_METER_FILE': '上傳離線表⽂件失敗',
      'API.GATEWAY_NAME_IS_ALREADY_IN_USE': '網關名稱已使⽤',
      'API.GATEWAY_NOT_FOUND': '網關不存在',
      'API.HEAT_METER_NOT_FOUND': 'Heat meter not found',
      'API.INVALID_': '無效的 ',
      'API.INVALID_ACKNOWLEDGE_CODE': 'Invalid acknowledge code',
      'API.INVALID_ADDRESS': '無效地址',
      'API.INVALID_ADDRESS_VALUE': '無效地址值',
      'API.INVALID_ADVANCED_REPORT_ID': '無效的⾼級報表 ID',
      'API.INVALID_API_KEY_ID': 'Invalid api key id',
      'API.INVALID_API_KEY_NAME': 'Invalid api key name',
      'API.INVALID_AREA_VALUE': '無效⾯積值',
      'API.INVALID_BALANCING_PRICE_POINT_ID': 'Invalid balancing price point ID',
      'API.INVALID_BASE_PERIOD_END_DATETIME': '無效的基準期結束時間',
      'API.INVALID_BASE_PERIOD_START_DATETIME': '無效的基準期開始時間',
      'API.INVALID_BATTERY_STATE_POINT_ID': 'Invalid battery state point ID',
      'API.INVALID_BAUD_RATE': '無效波特率',
      'API.INVALID_BUILDINGS_VALUE': '無效的建築值',
      'API.INVALID_BUY_METER_ID': 'Invalid buy meter id',
      'API.INVALID_CAPACITY': 'Invalid capacity',
      'API.INVALID_CATEGORY': '無效類型',
      'API.INVALID_CHANNEL': '無效通道',
      'API.INVALID_CHARGE_METER_ID': 'Invalid charge meter id',
      'API.INVALID_COMBINED_EQUIPMENT_ID': '無效的組合設備 ID',
      'API.INVALID_COMBINED_EQUIPMENT_NAME': '無效的組合設備名稱',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_ID': '無效的組合設備相關參數 ID',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_NAME': '無效的組合設備相關參數名稱',
      'API.INVALID_COMBINED_EQUIPMENT_PARAMETER_TYPE': '無效的組合設備相關參數類型',
      'API.INVALID_COMBINED_EQUIPMENT_UUID': '無效的組合設備 UUIDs',
      'API.INVALID_COMMAND_ID': 'Invalid command id',
      'API.INVALID_COMMAND_NAME': 'Invalid command name',
      'API.INVALID_CONNECTION': '無效連接',
      'API.INVALID_CONSTANT_VALUE': '無效常量值',
      'API.INVALID_CONTACT_ID': '無效的聯絡⼈ ID',
      'API.INVALID_CONTACT_NAME': '無效的聯絡⼈姓名',
      'API.INVALID_COOLING_METER_ID': 'Invalid cooling meter id',
      'API.INVALID_COST_CENTER_ID': '無效的成本中⼼ ID',
      'API.INVALID_COST_FILE_ID': '無效的成本⽂件 ID',
      'API.INVALID_CREATED_DATETIME': 'Invalid created datetime',
      'API.INVALID_DATA_REPAIR_FILE_ID': 'Invalid data repair file id',
      'API.INVALID_DATA_SOURCE_ID': '無效的數據源 ID',
      'API.INVALID_DATA_SOURCE_NAME': '無效的數據源名稱',
      'API.INVALID_DATA_SOURCE_PROTOCOL': '無效的數據源協議',
      'API.INVALID_DATE_LOCAL': '無效的本地⽇期',
      'API.INVALID_DENOMINATOR_METER_UUID': '無效的標準計量表 UUID',
      'API.INVALID_DISCHARGE_METER_ID': 'Invalid discharge meter id',
      'API.INVALID_DISPLAY_NAME': '無效的顯⽰名稱',
      'API.INVALID_DISTRIBUTION_CIRCUIT_ID': '無效的配電回路 ID',
      'API.INVALID_DISTRIBUTION_CIRCUIT_NAME': '無效的配電回路名稱',
      'API.INVALID_DISTRIBUTION_ROOM': '無效的配電室',
      'API.INVALID_DISTRIBUTION_SYSTEM_ID': '無效的配電系統 ID',
      'API.INVALID_DISTRIBUTION_SYSTEM_NAME': '無效的配電系統名稱',
      'API.INVALID_ELECTRICITY_METER_ID': 'Invalid Electricity meter id',
      'API.INVALID_EMAIL': '無效的郵箱',
      'API.INVALID_EMAIL_MESSAGE_ID': '無效的電⼦郵件 ID',
      'API.INVALID_EMAIL_SERVER_HOST': '無效的電⼦郵箱服務器主機',
      'API.INVALID_EMAIL_SERVER_ID': '無效的電⼦郵箱服務器 ID',
      'API.INVALID_END_DATE_FORMAT': '無效的結束⽇期格式',
      'API.INVALID_END_DATETIME': '無效的結束⽇期',
      'API.INVALID_ENERGY_CATEGORY_ID': '無效的能源分類 ID',
      'API.INVALID_ENERGY_CATEGORY_NAME': '無效的能源分類名稱',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_ID': '無效的能流圖 ID',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_LINK_ID': '無效的能流圖鏈接 ID',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NAME': '無效的能流圖名稱',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_ID': '無效的能流圖節點 ID',
      'API.INVALID_ENERGY_FLOW_DIAGRAM_NODE_NAME': '無效的能流圖節點名稱',
      'API.INVALID_ENERGY_ITEM_ID': '無效的能源分項 ID',
      'API.INVALID_ENERGY_ITEM_NAME': '無效的能源分項名稱',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_ID': 'Invalid energy storage power station ID',
      'API.INVALID_ENERGY_STORAGE_POWER_STATION_NAME': 'Invalid energy storage power station name',
      'API.INVALID_EQUATION_IN_EXPRESSION': '表達式中無效的表達式',
      'API.INVALID_EQUIPMENT_ID': '無效的設備 ID',
      'API.INVALID_EQUIPMENT_NAME': '無效的設備名稱',
      'API.INVALID_EQUIPMENT_PARAMETER_ID': '無效的設備相關參數 ID',
      'API.INVALID_EQUIPMENT_PARAMETER_NAME': '無效的設備相關參數名稱',
      'API.INVALID_EQUIPMENT_PARAMETER_TYPE': '無效的設備相關參數類型',
      'API.INVALID_EQUIPMENT_UUID': '無效的設備 UUID',
      'API.INVALID_EXPIRES_DATETIME': 'Invalid expires datetime',
      'API.INVALID_EXPRESSION': '無效表達式',
      'API.INVALID_EXPRESSION_OBJECT': '無效的表達式對象',
      'API.INVALID_FDD_CODE': '無效的故障錯誤碼',
      'API.INVALID_FLOORS_VALUE': '無效的樓層值',
      'API.INVALID_FROM_ADDR': '無效地址',
      'API.INVALID_GATEWAY_ID': '無效的網關 ID',
      'API.INVALID_GATEWAY_NAME': '無效的網關名稱',
      'API.INVALID_HEAT_METER_ID': 'Invalid heat meter id',
      'API.INVALID_HIGH_LIMIT_VALUE': 'Invalid high limit value',
      'API.INVALID_HOURLY_HIGH_LIMIT_VALUE': '無效的⼩時上限值',
      'API.INVALID_HOURLY_LOW_LIMIT_VALUE': '無效的⼩時下限值',
      'API.INVALID_Id': 'Invalid id',
      'API.INVALID_IS_ADMIN_VALUE': '無效的管理值',
      'API.INVALID_IS_COST_DATA_DISPLAYED': '無效的是否顯⽰成本數據',
      'API.INVALID_IS_COUNTED_VALUE': '無效的計數值',
      'API.INVALID_IS_ENABLED': '無效的是否啟⽤',
      'API.INVALID_IS_HIDDEN': '無效的是否隱藏',
      'API.INVALID_IS_IN_LEASE_VALUE': '無效的是否在租值',
      'API.INVALID_IS_INPUT_COUNTED_VALUE': '無效的輸入是否匯總值',
      'API.INVALID_IS_KEY_TENANT_VALUE': '無效的是否主⼒租⼾值',
      'API.INVALID_IS_OUTPUT_COUNTED_VALUE': '無效的輸出是否匯總值',
      'API.INVALID_IS_OUTPUT_VALUE': '無效的輸出是否匯總值',
      'API.INVALID_IS_READ_ONLY_VALUE': 'Invalid is read only value',
      'API.INVALID_IS_TREND_VALUE': '無效的是否保存趨勢值',
      'API.INVALID_IS_VIRTUAL_VALUE': '無效的是否虛擬點值',
      'API.INVALID_KGCE': '無效的千克標準煤系數',
      'API.INVALID_KGCO2E': '無效的千克⼆氧化碳排放系數',
      'API.INVALID_KNOWLEDGE_FILE_ID': '無效的知識⽂件 ID',
      'API.INVALID_LATITUDE_VALUE': '無效的緯度值',
      'API.INVALID_LEASE_NUMBER_VALUE': '無效的租約編號',
      'API.INVALID_LONGITUDE_VALUE': '無效的經度值',
      'API.INVALID_LOW_LIMIT_VALUE': '無效的下限值',
      'API.INVALID_MASTER_METER_ID': '無效的總計量表 ID',
      'API.INVALID_MENU_ID': '無效的菜單 ID',
      'API.INVALID_MESSAGE_DATA': 'Invalid message data',
      'API.INVALID_MESSAGE_TEMPLATE': '無效的消息模板',
      'API.INVALID_MESSAGE_TEMPLATE_ID': 'Invalid message template id',
      'API.INVALID_MESSAGE_VALUE': 'Invalid message value',
      'API.INVALID_METER_ID': '無效的計量表 ID',
      'API.INVALID_METER_NAME': '無效的計量表名稱',
      'API.INVALID_METER_UUID': '無效的計量表 UUID',
      'API.INVALID_MICROGRID_BATTERY_ID': 'Invalid microgrid battery id',
      'API.INVALID_MICROGRID_BATTERY_NAME': 'Invalid microgrid battery name',
      'API.INVALID_MICROGRID_EVCHARGER_ID': 'Invalid microgrid evcharger id',
      'API.INVALID_MICROGRID_EVCHARGER_NAME': 'Invalid microgrid evcharger name',
      'API.INVALID_MICROGRID_GENERATOR_ID': 'Invalid microgrid generator id',
      'API.INVALID_MICROGRID_GENERATOR_NAME': 'Invalid microgrid generator name',
      'API.INVALID_MICROGRID_GRID_ID': 'Invalid microgrid grid id',
      'API.INVALID_MICROGRID_GRID_NAME': 'Invalid microgrid grid name',
      'API.INVALID_MICROGRID_HEATPUMP_ID': 'Invalid microgrid heatpump id',
      'API.INVALID_MICROGRID_HEATPUMP_NAME': 'Invalid microgrid heatpump name',
      'API.INVALID_MICROGRID_ID': 'Invalid microgrid id',
      'API.INVALID_MICROGRID_LOAD_ID': 'Invalid microgrid load id',
      'API.INVALID_MICROGRID_LOAD_NAME': 'Invalid microgrid load name',
      'API.INVALID_MICROGRID_NAME': 'Invalid microgrid name',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_ID': 'Invalid microgrid photovoltaic id',
      'API.INVALID_MICROGRID_PHOTOVOLTAIC_NAME': 'Invalid microgrid photovoltaic name',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_ID':
        'Invalid microgrid power conversion system ID',
      'API.INVALID_MICROGRID_POWER_CONVERSION_SYSTEM_NAME':
        'Invalid microgrid microgrid power conversion system name',
      'API.INVALID_MICROGRID_UUID': 'Invalid microgrid UUID',
      'API.INVALID_NAME_VALUE': '無效的名稱值',
      'API.INVALID_NEW_PASSWORD': '無效的新密碼',
      'API.INVALID_NON_WORKING_DAY_ID': '無效的非⼯作⽇ ID',
      'API.INVALID_NOTIFICATION_ID': '無效的通知 ID',
      'API.INVALID_NUMERATOR_METER_UUID': '無效的分⼦表 UUID',
      'API.INVALID_OBJECT_TYPE': '無效的對象類型',
      'API.INVALID_OFFLINE_METER_DATA': '無效離線表數據',
      'API.INVALID_OFFLINE_METER_FILE_ID': '無效的離線表⽂件 ID',
      'API.INVALID_OFFLINE_METER_ID': '無效的離線表 ID',
      'API.INVALID_OFFLINE_METER_NAME': '無效的離線表名稱',
      'API.INVALID_OFFLINE_METER_UUID': '無效的離線表 UUID',
      'API.INVALID_OFFSET_CONSTANT_VALUE': '無效的偏移常量',
      'API.INVALID_OLD_PASSWORD': '無效的舊密碼',
      'API.INVALID_OPENID': 'Invalid openid',
      'API.INVALID_PARENT_SPACE_ID': '無效的⽗空間 ID',
      'API.INVALID_PASSWORD': '無效的密碼',
      'API.INVALID_PAYLOAD': 'Invalid payload',
      'API.INVALID_PEAK_CURRENT': '無效峰值電流',
      'API.INVALID_PEAK_LOAD': '無效峰值負載',
      'API.INVALID_PERIOD_TYPE': '無效周期類型',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_ID': 'Invalid photovoltaic power station ID',
      'API.INVALID_PHOTOVOLTAIC_POWER_STATION_NAME': 'Invalid photovoltaic power station name',
      'API.INVALID_POINT_ID': '無效的數據點 ID',
      'API.INVALID_POINT_NAME': '無效的數據點名稱',
      'API.INVALID_PORT': '無效端⼝',
      'API.INVALID_POSTAL_CODE_VALUE': 'Invalid postal code value',
      'API.INVALID_POWER_POINT_ID': 'Invalid power point id',
      'API.INVALID_PRIORITY': '無效的優先級',
      'API.INVALID_PRIVILEGE': '無效的權限',
      'API.INVALID_PRIVILEGE_DATA': '無效的權限數據',
      'API.INVALID_PRIVILEGE_ID': '無效的權限 ID',
      'API.INVALID_PRIVILEGE_NAME': '無效的權限名稱',
      'API.INVALID_RATIO_VALUE': '無效的變比值',
      'API.INVALID_RECIPIENT_EMAIL': 'Invalid recipient email',
      'API.INVALID_RECIPIENT_MOBILE': 'Invalid recipient mobile',
      'API.INVALID_RECIPIENT_NAME': 'Invalid recipient name',
      'API.INVALID_RECIPIENT_OPENID': 'Invalid recipient openid',
      'API.INVALID_REPLY': '無效回覆',
      'API.INVALID_REPORTING_PERIOD_END_DATETIME': '無效的報告期結束⽇期時間',
      'API.INVALID_REPORTING_PERIOD_START_DATETIME': '無效的報告期開始⽇期時間',
      'API.INVALID_REQUIRES_AUTHENTICATION': '無效的需要⾝份驗證',
      'API.INVALID_ROOMS_VALUE': '無效的房間值',
      'API.INVALID_RULE_ID': '無效的規則 ID',
      'API.INVALID_RULE_NAME': '無效的規則名稱',
      'API.INVALID_RUN_STATE_POINT_ID': 'Invalid run state point ID',
      'API.INVALID_SCHEDULED_DATETIME': 'Invalid scheduled datetime',
      'API.INVALID_SELL_METER_ID': 'Invalid sell meter id',
      'API.INVALID_SENSOR_ID': '無效的傳感器 ID',
      'API.INVALID_SENSOR_NAME': '無效的傳感器名稱',
      'API.INVALID_SENSOR_UUID': 'Invalid sensor uuid',
      'API.INVALID_SERIAL_NUMBER': 'Invalid serial number',
      'API.INVALID_SERIAL_PORT': '無效的串⼝號',
      'API.INVALID_SESSION_PLEASE_RE_LOGIN': '會話無效，請重新登陸',
      'API.INVALID_SET_VALUE': 'Invalid set value',
      'API.INVALID_SHOPFLOOR_ID': '無效的⾞間 ID',
      'API.INVALID_SHOPFLOOR_NAME': '無效的⾞間名稱',
      'API.INVALID_SHOPFLOOR_UUID': '無效的⾞間 UUID',
      'API.INVALID_SOURCE_NODE_ID': '無效的源節點 ID',
      'API.INVALID_SPACE_ID': '無效的空間 ID',
      'API.INVALID_SPACE_NAME': '無效的空間名稱',
      'API.INVALID_SPACE_UUID': '無效的空間 UUID',
      'API.INVALID_START_DATE_FORMAT': '無效的開始時間格式',
      'API.INVALID_START_DATETIME': '無效的開始時間',
      'API.INVALID_STATUS': '無效狀態',
      'API.INVALID_STORE_ID': '無效⾨店 ID',
      'API.INVALID_STORE_NAME': '無效⾨店名稱',
      'API.INVALID_STORE_TYPE_DESCRIPTION': '無效的⾨店類型描述',
      'API.INVALID_STORE_TYPE_ID': '無效的⾨店類型 ID',
      'API.INVALID_STORE_TYPE_NAME': '無效的⾨店類型名稱',
      'API.INVALID_STORE_TYPE_SIMPLIFIED_CODE': '無效的⾨店類型簡化代碼',
      'API.INVALID_STORE_UUID': '無效⾨店 UUID',
      'API.INVALID_SUBJECT_VALUE': 'Invalid subject value',
      'API.INVALID_SVG': '無效 SVG',
      'API.INVALID_SWITCHGEAR': '無效的開關設備',
      'API.INVALID_TARGET_NODE_ID': '無效的⽬標節點 ID',
      'API.INVALID_TARIFF_BLOCK_PRICING': '無效的階梯費率定價',
      'API.INVALID_TARIFF_ID': '無效的費率 ID',
      'API.INVALID_TARIFF_TIME_OF_USE_PRICING': '無效的分時費率定價',
      'API.INVALID_TARIFF_TYPE': '無效的費率類型',
      'API.INVALID_TENANT_ID': '無效的租⼾ ID',
      'API.INVALID_TENANT_NAME': '無效的租⼾名稱',
      'API.INVALID_TENANT_TYPE_DESCRIPTION': '無效的租⼾類型描述',
      'API.INVALID_TENANT_TYPE_ID': '無效的租⼾類型 ID',
      'API.INVALID_TENANT_TYPE_NAME': '無效的租⼾類型名稱',
      'API.INVALID_TENANT_TYPE_SIMPLIFIED_CODE': '無效的租⼾類型簡化代碼',
      'API.INVALID_TENANT_UUID': '無效的租⼾ UUID',
      'API.INVALID_TEXT_MESSAGE_ID': '無效的短信 ID',
      'API.INVALID_TIMEZONE_ID': '無效的時區 ID',
      'API.INVALID_TOKEN': '無效令牌',
      'API.INVALID_TOPIC': 'Invalid topic',
      'API.INVALID_UNIT_OF_MEASURE': '無效的計量單位',
      'API.INVALID_UNIT_OF_PRICE': '無效的價格單位',
      'API.INVALID_UNITS': '無效單位',
      'API.INVALID_USER_ID': '無效的⽤⼾ ID',
      'API.INVALID_USER_NAME': '無效的⽤⼾名稱',
      'API.INVALID_USER_NAME_OR_EMAIL': '無效的⽤⼾名稱或郵箱',
      'API.INVALID_USER_PHONE': '無效的⽤⼾⼿機號',
      'API.INVALID_USER_PLEASE_RE_LOGIN': '無效的⽤⼾請重新登錄',
      'API.INVALID_USER_UUID': '無效的⽤⼾ UUID',
      'API.INVALID_USERNAME': 'Invalid userName',
      'API.INVALID_VARIABLE_METER_ID': '無效的變量計量表 ID',
      'API.INVALID_VARIABLE_METER_TYPE': '無效的變量計量表類型',
      'API.INVALID_VARIABLE_NAME': '無效的變量名稱',
      'API.INVALID_VERIFICATION_CODE': '無效的驗證碼',
      'API.INVALID_VIRTUAL_METER_ID': '無效的虛擬表 ID',
      'API.INVALID_VIRTUAL_METER_NAME': '無效的虛擬表名稱',
      'API.INVALID_VIRTUAL_METER_UUID': '無效的虛擬表 UUID',
      'API.INVALID_WEB_MESSAGE_ID': '無效的 web 消息 ID',
      'API.INVALID_WECHAT_MESSAGE_ID': '無效的微信消息 ID',
      'API.INVALID_WIND_FARM_ID': 'Invalid wind farm ID',
      'API.INVALID_WIND_FARM_NAME': 'Invalid wind farm name',
      'API.INVALID_WORKING_CALENDAR_ID': '無效的⼯作⽇歷 ID',
      'API.INVALID_WORKING_CALENDAR_NAME': '無效的⼯作⽇歷名稱',
      'API.KNOWLEDGE_FILE_CANNOT_BE_REMOVED_FROM_DISK': '無法從磁盤中刪除知識⽂件',
      'API.KNOWLEDGE_FILE_NOT_FOUND': '知識庫⽂件不存在',
      'API.MASTER_METER_DOES_NOT_BELONG_TO_SAME_ENERGY_CATEGORY': '總表不屬於同⼀能源分類',
      'API.MASTER_METER_NOT_FOUND': '總表不存在',
      'API.MENU_NOT_FOUND': '菜單不存在',
      'API.METER_CANNOT_HAVE_MORE_THAN_ONE_ENERGY_VALUE_POINTS': 'Meter cannot have more than one energy value points',
      'API.METER_COMMAND_RELATION_EXISTS': 'Meter command relation exists',
      'API.METER_COMMAND_RELATION_NOT_FOUND': 'Meter command relation not found',
      'API.METER_NAME_IS_ALREADY_IN_USE': '計量表名稱已使⽤',
      'API.METER_NOT_FOUND': '計量表不存在',
      'API.METER_OF_VARIABLE_NOT_FOUND': '計量表變量不存在',
      'API.METER_POINT_RELATION_EXISTS': '計量表與數據點關系已存在',
      'API.METER_POINT_RELATION_NOT_FOUND': '計量表與數據點關系不存在',
      'API.MICROGRID_BATTERY_NAME_IS_ALREADY_IN_USE': 'Microgrid battery name is already in use',
      'API.MICROGRID_BATTERY_NOT_FOUND': 'Microgrid battery not found',
      'API.MICROGRID_EVCHARGER_NAME_IS_ALREADY_IN_USE':
        'Microgrid evcharger name is already in use',
      'API.MICROGRID_EVCHARGER_NOT_FOUND': 'Microgrid evcharger not fund',
      'API.MICROGRID_GENERATOR_NAME_IS_ALREADY_IN_USE':
        'Microgrid generator name is already in use',
      'API.MICROGRID_GENERATOR_NOT_FOUND': 'Microgrid generator not found',
      'API.MICROGRID_GRID_NAME_IS_ALREADY_IN_USE': 'Microgrid grid name is already in use',
      'API.MICROGRID_GRID_NOT_FOUND': 'Microgrid grid not found',
      'API.MICROGRID_HEATPUMP_NAME_IS_ALREADY_IN_USE': 'Microgrid heatpump name is already in use',
      'API.MICROGRID_HEATPUMP_NOT_FOUND': 'Microgrid heatpump not found',
      'API.MICROGRID_LOAD_NAME_IS_ALREADY_IN_USE': 'Microgrid load name is already',
      'API.MICROGRID_LOAD_NOT_FOUND': 'Microgrid load not found',
      'API.MICROGRID_NAME_IS_ALREADY_IN_USE': 'Microgrid name is already in use',
      'API.MICROGRID_NOT_FOUND': 'Microgrid not found',
      'API.MICROGRID_PHOTOVOLTAIC_NAME_IS_ALREADY_IN_USE':
        'Microgrid photovoltaic name is already in use',
      'API.MICROGRID_PHOTOVOLTAIC_NOT_FOUND': 'Microgrid photovoltaic not found',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NAME_IS_ALREADY_IN_USE':
        'Microgrid power conversion system name is already in use',
      'API.MICROGRID_POWER_CONVERSION_SYSTEM_NOT_FOUND':
        'Microgrid power conversion system not found',
      'API.MICROGRID_SENSOR_RELATION_EXISTS': 'Microgrid sensor relation exists',
      'API.MICROGRID_SENSOR_RELATION_NOT_FOUND': 'Microgrid sensor not found',
      'API.MQTT_CONNECTION_ERROR': 'Mqtt connection error',
      'API.MQTT_PUBLISH_ERROR': 'Mqtt publish error',
      'API.NEW_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS':
        '新密碼長度不能超過 100 個字符',
      'API.NEW_USER_SESSION_NOT_FOUND': 'New user session not found',
      'API.NEW_USER_SESSION_TIMEOUT': 'New user session timeout',
      'API.NON_WORKING_DAY_NOT_FOUND': '非⼯作⽇不存在',
      'API.NOT_FOUND': '不存在',
      'API.NOTIFICATION_NOT_FOUND': '通知不存在',
      'API.OFFLINE_METER_FILE_NOT_FOUND': '離線表⽂件不存在',
      'API.OFFLINE_METER_NAME_IS_ALREADY_IN_USE': '離線表名稱已使⽤',
      'API.OFFLINE_METER_NOT_FOUND': '離線表不存在',
      'API.OFFLINE_METER_OF_VARIABLE_NOT_FOUND': '離線表變量不存在',
      'API.OLD_PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS':
        '舊密碼長度不能超過 100 個字符',
      'API.PARENT_SPACE_NOT_FOUND': '⽗空間不存在',
      'API.PASSWORD_LENGTH_CANNOT_EXCEED_100_CHARACTERS': '密碼長度不能超過 100 個字符',
      'API.PASSWORDS_MATCH': '新密碼與舊密碼⼀致',
      'API.PHOTOVOLTAIC_POWER_STATION_NAME_IS_ALREADY_IN_USE':
        'Photovoltaic power station name is already in use',
      'API.PHOTOVOLTAIC_POWER_STATION_NOT_FOUND': 'Photovoltaic power station not found',
      'API.POINT_NAME_IS_ALREADY_IN_USE': '數據點名稱已使⽤',
      'API.POINT_NOT_FOUND': '數據點不存在',
      'API.POWER_POINT_NOT_FOUND': 'Power point not found',
      'API.PRIVILEGE_NAME_IS_ALREADY_IN_USE': '權限名稱已使⽤',
      'API.PRIVILEGE_NOT_FOUND': '權限不存在',
      'API.RULE_NAME_IS_ALREADY_IN_USE': '規則名稱已使⽤',
      'API.RULE_NOT_FOUND': '規則不存在',
      'API.SELL_METER_NOT_FOUND': 'Sell meter not found',
      'API.SENSOR_NAME_IS_ALREADY_IN_USE': '傳感器名稱已使⽤',
      'API.SENSOR_NOT_FOUND': '傳感器不存在',
      'API.SENSOR_POINT_RELATION_EXISTS': '傳感器與數據點關系已存在',
      'API.SENSOR_POINT_RELATION_NOT_FOUND': '傳感器與數據點關系不存在',
      'API.SHOPFLOOR_COMMAND_RELATION_EXISTS': 'Shopfloor command relation exists',
      'API.SHOPFLOOR_COMMAND_RELATION_NOT_FOUND': 'Shopfloor command relation not found',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_EXISTS': '⾞間與設備關系已存在',
      'API.SHOPFLOOR_EQUIPMENT_RELATION_NOT_FOUND': '⾞間與設備關系不存在',
      'API.SHOPFLOOR_METER_RELATION_EXISTS': '⾞間與計量表關系已存在',
      'API.SHOPFLOOR_METER_RELATION_NOT_FOUND': '⾞間與計量表關系不存在',
      'API.SHOPFLOOR_NAME_IS_ALREADY_IN_USE': '⾞間名稱已使⽤',
      'API.SHOPFLOOR_NOT_FOUND': '⾞間不存在',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_EXISTS': '⾞間與離線表關系已存在',
      'API.SHOPFLOOR_OFFLINE_METER_RELATION_NOT_FOUND': '⾞間與離線表關系不存在',
      'API.SHOPFLOOR_POINT_RELATION_EXISTS': '⾞間與數據點關系已存在',
      'API.SHOPFLOOR_POINT_RELATION_NOT_FOUND': '⾞間與數據點關系不存在',
      'API.SHOPFLOOR_SENSOR_RELATION_EXISTS': '⾞間與傳感器關系已存在',
      'API.SHOPFLOOR_SENSOR_RELATION_NOT_FOUND': '⾞間與傳感器關系不存在',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_EXISTS': '⾞間與虛擬表關系已存在',
      'API.SHOPFLOOR_VIRTUAL_METER_RELATION_NOT_FOUND': '⾞間與虛擬表關系不存在',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_EXISTS': '⾞間⼯作⽇歷關系已存在',
      'API.SHOPFLOOR_WORKING_CALENDAR_RELATION_NOT_FOUND':
        '⾞間與⼯作⽇歷關系不存在',
      'API.SOC_POINT_NOT_FOUND': 'SOC point not found',
      'API.SOURCE_NODE_NOT_FOUND': '源節點不存在',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_EXISTS': '空間與組合設備關系已存在',
      'API.SPACE_COMBINED_EQUIPMENT_RELATION_NOT_FOUND': '空間與組合設備關系不存在',
      'API.SPACE_COMMAND_RELATION_EXISTS': 'Space command relation exists',
      'API.SPACE_COMMAND_RELATION_NOT_FOUND': 'Space command relation not found',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_EXISTS':
        'Space Energy Storage Power Station Relation Exists',
      'API.SPACE_ENERGY_STORAGE_POWER_STATION_RELATION_NOT_FOUND': 'Space Energy Storage Power Station Relation Not Found',
      'API.SPACE_EQUIPMENT_RELATION_EXISTS': '空間與設備關系已存在',
      'API.SPACE_EQUIPMENT_RELATION_NOT_FOUND': '空間與設備關系不存在',
      'API.SPACE_METER_RELATION_EXISTS': '空間與計量表關系已存在',
      'API.SPACE_METER_RELATION_NOT_FOUND': '空間與計量表關系不存在',
      'API.SPACE_NAME_IS_ALREADY_IN_USE': '空間名稱已使⽤',
      'API.SPACE_NOT_FOUND': '空間不存在',
      'API.SPACE_NOT_FOUND_IN_PRIVILEGE': '空間在權限中不存在',
      'API.SPACE_OFFLINE_METER_RELATION_EXISTS': '空間與離線表關系已存在',
      'API.SPACE_OFFLINE_METER_RELATION_NOT_FOUND': '空間與離線表關系不存在',
      'API.SPACE_POINT_RELATION_EXISTS': '空間與數據點關系已存在',
      'API.SPACE_POINT_RELATION_NOT_FOUND': '空間與數據點關系不存在',
      'API.SPACE_SENSOR_RELATION_EXISTS': '空間與傳感器關系已存在',
      'API.SPACE_SENSOR_RELATION_NOT_FOUND': '空間與傳感器關系不存在',
      'API.SPACE_SHOPFLOOR_RELATION_EXISTS': '空間與⾞間關系已存在',
      'API.SPACE_SHOPFLOOR_RELATION_NOT_FOUND': '空間與⾞間關系不存在',
      'API.SPACE_STORE_RELATION_EXISTS': '空間與⾨店關系已存在',
      'API.SPACE_STORE_RELATION_NOT_FOUND': '空間與⾨店關系不存在',
      'API.SPACE_TENANT_RELATION_EXISTS': '空間與租⼾關系已存在',
      'API.SPACE_TENANT_RELATION_NOT_FOUND': '空間與租⼾關系不存在',
      'API.SPACE_VIRTUAL_METER_RELATION_EXISTS': '空間與虛擬表關系已存在',
      'API.SPACE_VIRTUAL_METER_RELATION_NOT_FOUND': '空間與虛擬表關系不存在',
      'API.SPACE_WORKING_CALENDAR_RELATION_EXISTS': '空間與⼯作⽇歷關系已存在',
      'API.SPACE_WORKING_CALENDAR_RELATION_NOT_FOUND': '空間與⼯作⽇歷關系不存在',
      'API.START_DATETIME_MUST_BE_EARLIER_THAN_END_DATETIME':
        '開始⽇期時間必須早於結束⽇期時間',
      'API.STORE_COMMAND_RELATION_EXISTS': 'Store command relation exists',
      'API.STORE_COMMAND_RELATION_NOT_FOUND': 'Store command relation not found',
      'API.STORE_METER_RELATION_EXISTS': '⾨店與計量表關系已存在',
      'API.STORE_METER_RELATION_NOT_FOUND': '⾨店與計量表關系不存在',
      'API.STORE_NAME_IS_ALREADY_IN_USE': '⾨店名稱已使⽤',
      'API.STORE_NOT_FOUND': '⾨店不存在',
      'API.STORE_OFFLINE_METER_RELATION_EXISTS': '⾨店與離線表關系已存在',
      'API.STORE_OFFLINE_METER_RELATION_NOT_FOUND': '⾨店與離線表關系不存在',
      'API.STORE_POINT_RELATION_EXISTS': '⾨店與數據點關系已存在',
      'API.STORE_POINT_RELATION_NOT_FOUND': '⾨店與數據點關系不存在',
      'API.STORE_SENSOR_RELATION_EXISTS': '⾨店與傳感器關系已存在',
      'API.STORE_SENSOR_RELATION_NOT_FOUND': '⾨店傳感器關系不存在',
      'API.STORE_TYPE_NAME_IS_ALREADY_IN_USE': '⾨店類型名稱已使⽤',
      'API.STORE_TYPE_NOT_FOUND': '⾨店類型不存在',
      'API.STORE_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': '⾨店類型簡化代碼已使⽤',
      'API.STORE_TYPE_USED_IN_STORE': '⾨店類型在⾨店中已使⽤',
      'API.STORE_VIRTUAL_METER_RELATION_EXISTS': '⾨店與虛擬表關系已存在',
      'API.STORE_VIRTUAL_METER_RELATION_NOT_FOUND': '⾨店與虛擬表關系不存在',
      'API.STORE_WORKING_CALENDAR_RELATION_EXISTS': '⾨店與⼯作⽇歷關系已存在',
      'API.STORE_WORKING_CALENDAR_RELATION_NOT_FOUND': '⾨店與⼯作⽇歷關系不存在',
      'API.TARGET_NODE_NOT_FOUND': '⽬標節點不存在',
      'API.TARIFF_IN_USE': '費率正在使⽤',
      'API.TARIFF_IS_ALREADY_ASSOCIATED_WITH_COST_CENTER': '費率和成本中⼼已關聯',
      'API.TARIFF_IS_NOT_ASSOCIATED_WITH_COST_CENTER': '費率和成本中⼼未關聯',
      'API.TARIFF_NAME_IS_ALREADY_IN_USE': '費率名稱已使⽤',
      'API.TARIFF_NOT_FOUND': '費率不存在',
      'API.TENANT_COMMAND_RELATION_EXISTS': 'Tenant command relation exists',
      'API.TENANT_COMMAND_RELATION_NOT_FOUND': 'Tenant command relation not found',
      'API.TENANT_METER_RELATION_EXISTS': '租⼾與計量表關系已存在',
      'API.TENANT_METER_RELATION_NOT_FOUND': '租⼾與計量表關系不存在',
      'API.TENANT_NAME_IS_ALREADY_IN_USE': '租⼾名稱已使⽤',
      'API.TENANT_NOT_FOUND': '租⼾不存在',
      'API.TENANT_OFFLINE_METER_RELATION_EXISTS': '租⼾與離線表關系已存在',
      'API.TENANT_OFFLINE_METER_RELATION_NOT_FOUND': '租⼾與離線表關系不存在',
      'API.TENANT_POINT_RELATION_EXISTS': '租⼾與數據點關系已存在',
      'API.TENANT_POINT_RELATION_NOT_FOUND': '租⼾與數據點關系不存在',
      'API.TENANT_SENSOR_RELATION_EXISTS': '租⼾與傳感器關系已存在',
      'API.TENANT_SENSOR_RELATION_NOT_FOUND': '租⼾與傳感器關系不存在',
      'API.TENANT_TYPE_NAME_IS_ALREADY_IN_USE': '租⼾與類型名稱已使⽤',
      'API.TENANT_TYPE_NOT_FOUND': '租⼾類型不存在',
      'API.TENANT_TYPE_SIMPLIFIED_CODE_IS_ALREADY_IN_USE': '租⼾類型簡化代碼已使⽤',
      'API.TENANT_TYPE_USED_IN_TENANT': '租⼾類型在租⼾中已使⽤',
      'API.TENANT_VIRTUAL_METER_RELATION_EXISTS': '租⼾與虛擬表關系已存在',
      'API.TENANT_VIRTUAL_METER_RELATION_NOT_FOUND': '租⼾與虛擬表關系不存在',
      'API.TENANT_WORKING_CALENDAR_RELATION_EXISTS': '租⼾與⼯作⽇歷關系已存在',
      'API.TENANT_WORKING_CALENDAR_RELATION_NOT_FOUND': '租⼾與⼯作⽇歷關系不存在',
      'API.TEXT_MESSAGE_NOT_FOUND': '未找到短信',
      'API.THE_REPORTING_PERIOD_MUST_BE_LONGER_THAN_15_MINUTES':
        '報告期必須⼤於 15 分鐘',
      'API.THERE_IS_RELATION_WITH_CHILD_METERS': '與⼦表有關系',
      'API.THERE_IS_RELATION_WITH_CHILDREN_SPACES': '與⼦空間有關系',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENT_PARAMETERS'
        : '與組合設備相關參數有關系',
      'API.THERE_IS_RELATION_WITH_COMBINED_EQUIPMENTS': '與組合設備有關系',
      'API.THERE_IS_RELATION_WITH_DATA_SOURCES': '與數據源有關系',
      'API.THERE_IS_RELATION_WITH_ENERGY_FLOW_DIAGRAM_LINKS':
        '與能流圖有關系',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_BATTERIES': '與儲能集裝箱電池有關係',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_GRIDS': '與儲能集裝箱電網有關係',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_LOADS': '與儲能集裝箱負載有關係',
      'API.THERE_IS_RELATION_WITH_ENERGY_STORAGE_CONTAINERS_POWER_CONVERSION_SYSTEMS': '與儲能集裝箱功率轉換系統(PCS)有關係',
      'API.THERE_IS_RELATION_WITH_EQUIPMENT_PARAMETERS': '與設備相關參數有關系',
      'API.THERE_IS_RELATION_WITH_EQUIPMENTS': '與設備有關系',
      'API.THERE_IS_RELATION_WITH_METER': '與計量表有關系',
      'API.THERE_IS_RELATION_WITH_METERS': '與多個記量表有關系',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METER': '與離線表有關系',
      'API.THERE_IS_RELATION_WITH_OFFLINE_METERS': '與多個離線表有關系',
      'API.THERE_IS_RELATION_WITH_OTHER_VIRTUAL_METERS': '與虛擬表有關系',
      'API.THERE_IS_RELATION_WITH_SENSORS': '與傳感器有關系',
      'API.THERE_IS_RELATION_WITH_SHOPFLOORS': '與⾞間有關系',
      'API.THERE_IS_RELATION_WITH_SPACES': '與空間有關系',
      'API.THERE_IS_RELATION_WITH_STORES': '與⾨店有關系',
      'API.THERE_IS_RELATION_WITH_TENANTS': '與租⼾有關系',
      'API.THERE_IS_RELATION_WITH_USERS': '與⽤⼾有關系',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_METER': '與虛擬表有關系',
      'API.THERE_IS_RELATION_WITH_VIRTUAL_POWER_PLANTS': '與虛擬電廠有關系',
      'API.THIS_DATA_SOURCE_IS_BEING_USED_BY_A_METER': '這個數據源正在被⼀個計量表使⽤',
      'API.THIS_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': '這個計量表正在被⼀個虛擬表使⽤',
      'API.THIS_OFFLINE_METER_IS_BEING_USED_BY_A_VIRTUAL_METER': '這個離線表正在被⼀個虛擬表使⽤',
      'API.THIS_SPACE_CANNOT_BE_CLONED': '空間不能被克隆',
      'API.THIS_SPACE_CANNOT_BE_DELETED': '空間不能被刪除',
      'API.TIMEZONE_NOT_FOUND': '時區不存在',
      'API.TOKEN_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': '未找到令牌，請登錄',
      'API.USER_ACCOUNT_HAS_BEEN_LOCKED': '⽤⼾帳號已鎖定',
      'API.USER_ACCOUNT_HAS_EXPIRED': '⽤⼾帳號已過期',
      'API.USER_ACCOUNT_IS_NOT_LOCKED': 'User account is not locked',
      'API.USER_NAME_IS_ALREADY_IN_USE': '⽤⼾名已使⽤',
      'API.USER_NOT_FOUND': '⽤⼾不存在',
      'API.USER_PASSWORD_HAS_EXPIRED': '⽤⼾密碼已過期',
      'API.USER_PRIVILEGE_NOT_FOUND': '未發現⽤⼾權限',
      'API.USER_SESSION_NOT_FOUND': '未發現⽤⼾會話',
      'API.USER_SESSION_TIMEOUT': '⽤⼾會話已過期',
      'API.USER_UUID_NOT_FOUND_IN_HEADERS_PLEASE_LOGIN': '⽤⼾ UUID 不存在，請登錄',
      'API.VIRTUAL_METER_NAME_IS_ALREADY_IN_USE': '虛擬表名稱已使⽤',
      'API.VIRTUAL_METER_NOT_FOUND': '虛擬表不存在',
      'API.VIRTUAL_METER_OF_VARIABLE_NOT_FOUND': '變量的虛擬表不存在',
      'API.VIRTUAL_POINT_SHOULD_BE_ANALOG_VALUE': '虛擬數據點應該是模擬量',
      'API.WEB_MESSAGE_NOT_FOUND': 'web 信息不存在',
      'API.WECHAT_MESSAGE_NOT_FOUND': '微信信息不存在',
      'API.WIND_FARM_NAME_IS_ALREADY_IN_USE': 'Wind farm name is already in use',
      'API.WIND_FARM_NOT_FOUND': 'Wind farm not found',
      'API.WORKING_CALENDAR_NAME_IS_ALREADY_IN_USE': '⼯作⽇歷名稱已使⽤',
      'API.WORKING_CALENDAR_NOT_FOUND': '⼯作⽇歷不存在',

      MasterMeter: '總表',
      SubMeter: '分錶'
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'zh_CN',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
